<!-- 分工厂长-综合看板 -->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="nav pdt_20 pdb_10 flex justify_between align_center">
                <div class="navLeft flex">
                    <img style="width:38px;height:38px;margin:15px " src="@/assets/images/bulletinBoard/矢量智能对象.png"
                        alt="">
                    <div class="flex" style="line-height:68px">
                        <!-- <div style="margin-right:15px">接入设备：</div>
                        <div style="color: #00FFF6;" >{{this.number}} </div>
                        <div style="margin-left:10px">台</div> -->
                    </div>
                    <div style="line-height:65px;">
                        <el-button class="mg_left_20 btn1" style="width:120px;height:38px" @click="gotoDetails">
                            <img style="width:12px;height:12px" src="@/assets/images/bulletinBoard/矢量智能对象(1).png"
                                alt="">
                            查看详情</el-button>
                    </div>

                </div>
                <div class="topTitle fn_size28 color_FFFFFF" style="margin-right:150px">
                    {{this.allData.Title}}
                </div>
                <div class="fn_size22 color_00C6FF flex align_center ">
                    <img style="margin-right:10px" src="@/assets/images/bulletinBoard/矢量智能对象(2).png" alt="">
                    {{setNewDate |formatDate}}

                    <!-- <img src="../../assets/images/comprehensive/full.png" class="mg_left_20" alt=""> -->
                </div>
            </div>
            <!-- <div>

            </div> -->
            <div class="mainContent">
                <div class="diy_col_left">
                    <div class="d_left1" style="height:26%;margin:20px 10px 0 10px">
                        <div class="kbTitle flex">
                            <div>
                                <img style="margin-top:5px" src="@/assets/images/bulletinBoard/标题_icon@2x.png" alt="">
                            </div>
                            <div style="margin:0 10px">设备OEE</div>
                        </div>
                        <div style="width_200">
                            <el-progress class="elfont1" color="#2093FF" type="circle" :stroke-width="16"
                                stroke-linecap='round' :percentage="this.allData.OEE"></el-progress>
                        </div>
                        <div style="margin-top:8px">
                            设备OEE
                        </div>
                    </div>
                    <div class="d_left2" style="height:26%;margin:10px 10px 0 10px">
                        <div class="kbTitle flex">
                            <div>
                                <img style="margin-top:5px" src="@/assets/images/bulletinBoard/标题_icon@2x.png" alt="">
                            </div>
                            <div style="margin:0 10px">
                                历史报警信息

                            </div>

                        </div>
                        <div class="flex" style="">
                            <div style="margin:0 20px 0 30px">
                                <div class="alarmL2">
                                    {{this.totalAlertsQuantity}}
                                </div>
                                <div>报警总数(条)</div>
                            </div>

                            <div class="flex">
                                <div class="alarmL21">
                                    <div>低报</div>
                                    <div class="mg_top_15">高报</div>
                                    <div class="mg_top_10">高高报</div>
                                </div>
                                <div class="alarmL22" style="">
                                    <el-progress class="" :percentage="this.percentLNum" :stroke-width="12">
                                    </el-progress>
                                    <el-progress class="mg_top_20" :percentage="this.percentHNum" :stroke-width="12">
                                    </el-progress>
                                    <el-progress class="mg_top_20" :percentage="this.percentHHNum" :stroke-width="12">
                                    </el-progress>
                                </div>
                            </div>
                        </div>

                        <!-- <div id="echartsLeft1" style="width:85%;height: 75%;margin-left:30px;margin-top:30px"></div> -->
                    </div>
                    <div class="d_left3" style="height:40%;margin:10px 10px 0 10px">
                        <div class="kbTitle flex">
                            <div>
                                <img style="margin-top:5px" src="@/assets/images/bulletinBoard/标题_icon@2x.png" alt="">
                            </div>
                            <div style="margin:0 10px"> 本月历史报警内容</div>
                        </div>
                        <el-table ref="table" :data="alarmData" :header-cell-style="tableHeaderCellStyle"
                            :row-style="rowStyle" :cell-style="tableCellStyle" @mouseenter.native="enter"
                            @mouseleave.native="leave" height="100%" style="width:95%;margin:0 auto;background:#09162C">
                            <el-table-column prop="MachineName" label="机器名称" width="">
                            </el-table-column>
                            <el-table-column prop="SensorName" label="测点名称" width="">
                            </el-table-column>
                            <el-table-column prop="AlertContent" label="报警详情">
                            </el-table-column>
                            <el-table-column prop="timestamp" label="时间" :formatter="formatDate">
                            </el-table-column>
                        </el-table>


                    </div>
                </div>
                <div class="diy_col_center">
                    <div class="d_center1" style="height:26%;margin:20px 0 0 0">
                        <div class="kbTitle1 flex">
                            <div>
                                <img style="margin-top:5px" src="@/assets/images/bulletinBoard/标题_icon@2x.png" alt="">
                            </div>
                            <div style="margin:0 10px">皮带机信息</div>
                        </div>
                        <div class="flex justify_around flex_wrap">
                            <div style="margin:0 20px 0 20px">
                                <div class="alarmL2">
                                    {{this.percentRun.TotalDeviceQuantity}}
                                </div>
                                <div style="margin-top:8px">皮带机总条数(条)</div>
                            </div>

                            <div style="margin-right:20px">
                                <div>
                                    <el-progress class="elfont1" color="#FDDD60" type="circle" :stroke-width="17"
                                        stroke-linecap='round' :percentage="this.percentRun.RunningDeviceRate">
                                    </el-progress>
                                </div>
                                <div style="margin-top:8px"> 运转中</div>
                            </div>

                            <div style="margin-right:20px">
                                <div>
                                    <el-progress class="elfont1" color="#FF6E76" type="circle" :stroke-width="17"
                                        stroke-linecap='round' :percentage="this.percentRun.StopDeviceRate">
                                    </el-progress>
                                </div>
                                <div style="margin-top:8px"> 停机</div>
                            </div>
                        </div>

                        <!-- <div id="ecartsDFH1" style="width:30%;height: 100%;"></div>
                        <div id="ecartsDFH2" style="width:30%;height: 100%;"></div> -->
                    </div>
                    <div class="d_center2" style="height:26%;margin:10px 0 0 0">
                        <div class="kbTitle1 flex">
                            <div>
                                <img style="margin-top:5px" src="@/assets/images/bulletinBoard/标题_icon@2x.png" alt="">
                            </div>
                            <div style="margin:0 10px">故障次数</div>
                        </div>
                        <div style="width:100%;height:100%">
                            <div id="echartsDefault" style="width:95%;height: 100%;"></div>

                        </div>
                    </div>
                    <div class="d_center3" style="height:40%;margin:10px 0 0 0">
                        <div class="kbTitle1 flex">
                            <div>
                                <img style="margin-top:5px" src="@/assets/images/bulletinBoard/标题_icon@2x.png" alt="">
                            </div>
                            <div style="margin:0 10px">年工单情况</div>
                        </div>

                        <el-table ref="table1" :data="orderData" :header-cell-style="tableHeaderCellStyle"
                            :row-style="rowStyle" :cell-style="tableCellStyle" height="100%" @mouseenter.native="enter1"
                            @mouseleave.native="leave1" style="width:95%;margin:0 auto;background:#09162C">
                            <el-table-column prop="DeviceName" label="机器名称" width="">
                            </el-table-column>
                            <el-table-column prop="ExecPerson" label="维保人" width="">
                            </el-table-column>
                            <el-table-column prop="OrderStatus" label="工单状态">
                            </el-table-column>
                            <el-table-column prop="OrderType" label="工单类型">
                            </el-table-column>
                            <el-table-column prop="timestamp" label="处理时间" :formatter="formatDate">
                            </el-table-column>
                        </el-table>

                    </div>
                </div>
                <div class="diy_col_right">
                    <div class="d_right1" style="height:46%;margin:20px 10px 0 10px">
                        <div class="kbTitle flex">
                            <div>
                                <img style="margin-top:5px" src="@/assets/images/bulletinBoard/标题_icon@2x.png" alt="">
                            </div>
                            <div style="margin:0 10px">本月故障统计</div>
                        </div>
                        <div id="echartsCategory" style="width:100%;height:100%;"></div>
                    </div>
                    <div class="d_right2" style="height:47%;margin:13px 10px 0 10px">
                        <div class="kbTitle flex">
                            <div>
                                <img style="margin-top:5px" src="@/assets/images/bulletinBoard/标题_icon@2x.png" alt="">
                            </div>
                            <div style="margin:0 10px">本月运维完成率</div>
                        </div>
                        <div id="echartsRing" style="width:100%;height: 100%;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    // import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';
    import {
        dashBoardGetTitle, //获取看板标题
        organizationSelectOperations, //组织架构选择
        equipmentRealAlarmInfo, //各组织级设备报警信息
        equipmentUnexecutedWorkOrderInfor, //各组织级设备未执行的工单信息
        mTBF, //MTBF
        equipmentMaintenanceData, //设备维保数据
        useRatio, //各组织的开机率、故障率
        equipmentFaultInfor, //故障信息
        maintenanceWorkOrderInfor, //维修工单现状
        mTTR, //MTTR
        equipmentFaultAnalysis, //各组织设备故障分析
        getOprKanbanData,
        getKanBanDate,
    } from "@/api/api_comprehensiveShow";
import machiningCenter from '../deviceManagement/databaseCockpit/machiningCenter.vue';
    export default {
  components: { machiningCenter },
        data() {
            return {
                tableData: [{
                    time: '2016-05-02',
                    mcName: '王小虎',
                    pointerName: '王小虎1',
                    detail: '上海市普陀区金沙江路 1518 弄'
                }, {
                    time: '2016-05-04',
                    mcName: '王小虎',
                    pointerName: '王小虎2',
                    detail: '上海市普陀区金沙江路 1517 弄'
                }, {
                    time: '2016-05-01',
                    mcName: '王小虎',
                    pointerName: '王小虎3',
                    detail: '上海市普陀区金沙江路 1519 弄'
                }, {
                    time: '2016-05-03',
                    mcName: '王小虎',
                    pointerName: '王小虎4',
                    detail: '上海市普陀区金沙江路 1516 弄'
                }],
                percentage: 54,
                percentage1: 79,
                colors: [

                    'linear-gradient(0deg, #2093FF 0%, #A4CCE9 100%)',

                ],
                title: "山东矿机物联网平台车间级综合分析看板",
                number: '120',
                currentTime: new Date(),
                dashBoardTitle: '', //获取看板标题
                equiRealAlarmInfo: [{
                        OrderID: "un130031011",
                        takeTime: "2021-05-25 11:59:00",
                        execPerson: "陈达",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031012",
                        takeTime: "2021-05-26 10:34:00",
                        execPerson: "王明",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031013",
                        takeTime: "2021-05-27 09:18:00",
                        execPerson: "武雄",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031014",
                        takeTime: "2021-05-28 13:59:00",
                        execPerson: "赵泽",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031015",
                        takeTime: "2021-05-29 09:59:00",
                        execPerson: "李括",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031016",
                        takeTime: "2021-06-01 10:27:00",
                        execPerson: "张五明",
                        status: "未开始"
                    },
                ], //工序产能
                equiUnexecutedWorkOrderInfor: [{
                        OrderID: "re130010001",
                        repairTime: "2021-06-02 10:59:00",
                        execPerson: "陈明",
                        status: "完成"
                    },
                    {
                        OrderID: "re130010002",
                        repairTime: "2021-06-03 15:44:00",
                        execPerson: "王玉",
                        status: "完成"
                    },
                    {
                        OrderID: "re130010003",
                        repairTime: "2021-06-04 09:38:00",
                        execPerson: "王五",
                        status: "完成"
                    },
                    {
                        OrderID: "re130010004",
                        repairTime: "2021-06-06 13:53:00",
                        execPerson: "赵四",
                        status: "完成"
                    },
                    {
                        OrderID: "re130010005",
                        repairTime: "2021-06-07 09:53:00",
                        execPerson: "李达",
                        status: "完成"
                    },
                    {
                        OrderID: "re130010005",
                        repairTime: "2021-06-08 10:13:00",
                        execPerson: "张山",
                        status: "完成"
                    },
                ], //未执行保养计划
                dataX: [], //MTBF  x轴
                dataGX1: [], //MTBF  工序1
                dataGX2: [], //MTBF  工序2
                dataGX3: [], //MTBF  工序3
                dataGX4: [], //MTBF  工序4
                dataGXName: '', //MTBF  legend
                dataGXName2: [], //MTBF  legend
                timelyInspectionRate: [], //维保数据-巡检及时率
                maintainPlanRate: [], //维保数据-保养计划完成
                repairPlanRate: [], //维保数据-维修完成率
                accuratPlanRate: [], //维保数据-计划准确率
                useRatioX: [], //开机率、故障率 X轴
                useRatioName: [], //开机率、故障率 legend
                useRatioName2: [], //开机率、故障率 legend
                useRatioKJL: [], //开机率 value
                useRatioGZL: [], //故障率 value
                equiFaultInfor: [{
                        OrderID: "un130031011",
                        takeTime: "2021-05-25 11:59:00",
                        execPerson: "陈达",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031012",
                        takeTime: "2021-05-26 10:34:00",
                        execPerson: "王明",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031013",
                        takeTime: "2021-05-27 09:18:00",
                        execPerson: "武雄",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031014",
                        takeTime: "2021-05-28 13:59:00",
                        execPerson: "赵泽",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031015",
                        takeTime: "2021-05-29 09:59:00",
                        execPerson: "李括",
                        status: "未开始"
                    },
                    {
                        OrderID: "un130031016",
                        takeTime: "2021-06-01 10:27:00",
                        execPerson: "张五明",
                        status: "未开始"
                    },
                ], //故障信息
                maintenanceWorkOrderInforData: [{
                        OrderID: "ma130010001",
                        repairTime: "2021-06-10 10:59:00",
                        execPerson: "张叁",
                        status: "正常"
                    },
                    {
                        OrderID: "ma130010002",
                        repairTime: "2021-06-26 16:54:00",
                        execPerson: "王二",
                        status: "正常"
                    },
                    {
                        OrderID: "ma130010003",
                        repairTime: "2021-06-27 08:23:00",
                        execPerson: "李四",
                        status: "正常"
                    },
                    {
                        OrderID: "ma130010004",
                        repairTime: "2021-06-11 15:33:00",
                        execPerson: "赵七",
                        status: "正常"
                    },
                    {
                        OrderID: "ma130010005",
                        repairTime: "2021-06-12 08:13:00",
                        execPerson: "诸葛清",
                        status: "正常"
                    },
                    {
                        OrderID: "ma130010005",
                        repairTime: "2021-06-13 09:13:00",
                        execPerson: "陈明",
                        status: "正常"
                    },
                    //  {
                    //     OrderID:"re130010005",
                    //     repairTime:"2021-06-14 12:13:00",
                    //     execPerson:"王伟",
                    //     status:"正常"
                    // }, {
                    //     OrderID:"re130010005",
                    //     repairTime:"2021-06-15 14:13:00",
                    //     execPerson:"于亮",
                    //     status:"正常"
                    // }
                ], //维修工单现状
                mTTRX: [], //MTTR  x轴
                mTTRName: [], //MTTR  legend
                mTTRName2: [], //MTTR  legend
                mTTRGX1: [], //MTTR  工序1
                mTTRGX2: [], //MTTR  工序2
                mTTRGX3: [], //MTTR  工序3
                mTTRGX4: [], //MTTR  工序4
                userID: '',
                allData: {},
                totalAlertsQuantity: '',
                percentHHNum: 0,
                percentHNum: 0,
                percentLNum: 0,
                tableList: [],
                rightData: [],
                percentRun: 0,
                arrFault: [],
                faultData: [],
                countFault: [],
                finishedRate: [],
                nameList: [],
                nameList1: [],
                alarmList: [],
                timer: "", //定义一个定时器的变量
                currentTime: new Date(), // 获取当前时间
                alarmData: [],
                orderData: [],
                enterStatus: false,
                enterStatus1: false,
                jumpValue: null,
                setNewDate:null,
                showIf:false
            }
        },
        created() {
            if (this.$route.query.jumpValue) {
                this.jumpValue = this.$route.query.jumpValue;
            }
            console.log(this.$route.query.jumpValue, this.jumpValue);
            this.getKanBanDate();//获取时间
          
            this.setNewDate = this.tools.formatDate(this.setNewDate, 'yyyy-MM-dd hh:mm:ss')
        },
        mounted() {
           
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            console.log(this.$route);
            if (JSON.stringify(this.$route.query) != '{}') {
                this.value = this.$route.query.equipmentNum;
                this.currentPart = this.$route.query.title;
                this.title = this.currentPart;
                this.number = this.value;

            };
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            // this.currentTime = this.tools.formatDate(this.setNewDate, 'yyyy-MM-dd hh:mm:ss')
            this.getOprKanbanData(); //获取数据
            let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
            this.timer = setInterval(() => {
                _this.getKanBanDate();//获取时间
                // _this.currentTime = this.setNewDate; // 修改数据date
            }, 500);
             this.equipmentRealAlarmInfo();
        },

        beforeDestroy() {
            if (this.timer) {
                clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
            }
        },
        filters: {
            formatDate(date) {
                var date = new Date(date);
                var YY = date.getFullYear() + '-';
                var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
                var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                return YY + MM + DD + " " + hh + mm + ss;
            },
            appendZero(obj) {
                if (obj < 10) {
                    return "0" + obj;
                } else {
                    return obj;
                }
            },
        },
        methods: {

            //获取数据
            async getOprKanbanData() {
                console.log(this.jumpValue);
                var param = {
                    userID: this.userID,

                };
                if (this.jumpValue != null) {
                    param.jumpValue = this.jumpValue
                }
                const selectRes = await getOprKanbanData(param)
                this.allData = selectRes
                this.percentHHNum = this.allData.AlertAnalysis.HighHighAlertsRate;
                this.percentHNum = this.allData.AlertAnalysis.HighAlertsRate;
                this.percentLNum = this.allData.AlertAnalysis.LowAlertsRate;
                this.totalAlertsQuantity = this.allData.AlertAnalysis.TotalAlertsQuantity;
                if (this.allData.tMonthAlertContent.length > 0) {

                    this.alarmList = this.allData.tMonthAlertContent;
                    console.log('执行拉');
                    this.getAlarmData();
                    this.tableScroll();
                }
                if (this.allData.OrderContent.length > 0) {
                    this.tableList = this.allData.OrderContent;
                    console.log('这个也执行拉');
                    this.getOrderData();
                    this.tableScroll1();
                }
                this.rightData = this.allData.tMonthAlertContent;
                this.percentRun = this.allData.RunningDevice;
                this.arrFault = this.allData.MonthlyFailureAnalysis;
                //月故障次数
                var ftd = [];
                var nameList = [];
                for (let i = 0; i < this.arrFault.length; i++) {
                    nameList.push(this.arrFault[i].FailureName);
                    ftd[i] = {
                        name: '',
                        data: []
                    };
                    ftd[i].name = this.arrFault[i].FailureName;
                    ftd[i].data = this.arrFault[i].LineValue;
                    ftd[i].type = 'line';
                    ftd[i].smooth = true;
                    // ftd[i].stack= '次数';
                }
                // console.log(ftd);
                this.nameList = nameList
                var line = ftd
                this.drawDefault(line)

                //当月故障统计
                var cFault = this.allData.tMonthFailureAnalysis
                var countFault = [];
                var countFaultName=[];
                for (let i = 0; i < cFault.length; i++) {
                    countFaultName.push(cFault[i].FailureName);
                    countFault.push(cFault[i].PineValue);
                }
                // console.log(countFault,countFaultName,"2");
                var line1 = countFault;
                var name1 = countFaultName;
                line1[0]={
                    value:line1[0],
                    itemStyle:{
                        color:'#fe6e0e'
                    }
                }
                line1[1]={
                    value:line1[1],
                    itemStyle:{
                        color:'#ff0000'
                    }
                }
                this.drawCircle(line1,name1);

                //运维完成率
                var finishRateList = this.allData.OrderFinishedRate;
                this.nameList1 = ['维修工单完成', '维修工单未完成', '保养工单完成', '保养工单未完成']
                var frl = [{
                        name: '维修工单完成',
                        value: finishRateList.RepairOrderFinishedQuantity
                    },
                    {
                        name: '维修工单未完成',
                        value: finishRateList.RepairOrderUnFinishedQuantity
                    },
                    {
                        name: '保养工单完成',
                        value: finishRateList.MaintainOrderFinishedQuantity
                    },
                    {
                        name: '保养工单未完成',
                        value: finishRateList.MaintainOrderUnFinishedQuantity
                    },
                ];
                var line2 = frl;
                this.drawRing(line2);


            },
            async getKanBanDate() {
                // var param = {

                // }
                const res = await getKanBanDate()
                console.log(res);
                this.setNewDate = res.rows[0].result;
            },
            rowStyle({
                row,
                rowIndex
            }) {
                if (row) {
                    return {
                        background: '#09162C'
                    }
                }
            },

            getOrderData() {
                this.orderData = this.tableList;
            },
            // 设置自动滚动
            tableScroll() {
                // 拿到表格挂载后的真实DOM
                const table = this.$refs.table
                // 拿到表格中承载数据的div元素
                const divData = table.bodyWrapper
                // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
                setInterval(() => {
                    if (!this.enterStatus) {
                        // 元素自增距离顶部1像素
                        divData.scrollTop += 1
                    }
                    if (this.enterStatus) {
                        divData.scrollTop += 0
                    }

                    // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
                    if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
                        // 重置table距离顶部距离
                        divData.scrollTop = 0

                        // 滚动到最底部后执行刷新表格的方法
                        this.getAlarmData();
                    }
                }, 50)
                const that = this
                window.onresize = () => {
                    return (() => {
                        window.screenHeight = document.body.clientHeight
                        that.clientHeight = window.screenHeight
                    })()
                }
            },
            enter() {
                this.enterStatus = true;
                console.log(this.enterStatus);
            },
            leave() {
                this.enterStatus = false;
                console.log(this.enterStatus);
            },


            getAlarmData() {
                this.alarmData = this.alarmList;
                //this.equipmentRealAlarmInfo();
            },
            tableScroll1() {
                // 拿到表格挂载后的真实DOM
                const table1 = this.$refs.table1
                // 拿到表格中承载数据的div元素
                const divData = table1.bodyWrapper
                // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
                setInterval(() => {
                    if (!this.enterStatus1) {
                        // 元素自增距离顶部1像素
                        divData.scrollTop += 1
                    }
                    if (this.enterStatus1) {
                        //元素暂停
                        divData.scrollTop += 0
                    }

                    // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
                    if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
                        // 重置table距离顶部距离
                        divData.scrollTop = 0

                        // 滚动到最底部后执行刷新表格的方法
                        // this.getOrderData();
                        this.getAlarmData();
                    }
                }, 50)
                const that = this
                window.onresize = () => {
                    return (() => {
                        window.screenHeight = document.body.clientHeight
                        that.clientHeight = window.screenHeight
                    })()
                }
            },
            enter1() {
                this.enterStatus1 = true;
            },
            leave1() {
                this.enterStatus1 = false;
            },




            // 修改 table cell边框的背景色
            tableCellStyle() {
                return 'border-color: #123D66;color:white;backgroundColor:#09162C'
            },
            // 修改 table header cell的背景色
            tableHeaderCellStyle() {
                return 'border-color: #123D66; color: white;backgroundColor:#123D66'
            },
            //circle
            drawCircle(line1,name1) {
                console.log(line1,name1);
                this.echartsCategory = echarts.init(document.getElementById('echartsCategory'));
                this.echartsCategory.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    // legend: {
                    //     top: 0,
                    //     left: 'center',
                    //     right: 100,
                    //     textStyle: {
                    //         color: 'white',
                    //         fontSize: '12px'
                    //     }
                    // },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '5%',
                        top: '6%',
                        containLabel: true,
                    },
                    xAxis: [{
                        type: 'category',
                        data: name1,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }],
                    yAxis: [{
                        type: 'value',
                        minInterval: 1
                    }],
                    series: [{
                        name: '故障统计',
                        type: 'bar',
                        barWidth: '20%',
                        data: line1,
                        // [
                        //     {value: 1048, name: '机械故障'},
                        //     {value: 735, name: '电气故障'},
                        //     {value: 580, name: '程序故障'},
                        // ],
                        // emphasis: {
                        //     itemStyle: {
                        //         shadowBlur: 5,
                        //         shadowOffsetX: 0,
                        //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                        //     }
                        // },
                        // label: {
                        //     show: true,
                        //     color: 'white'
                        // },
                        // labelLine: {
                        //     show: true
                        // },
                    }]
                })
            },
            //wing
            draWing() {
                this.echartsWing = echarts.init(document.getElementById('echartsWing'));
                this.echartsWing.setOption({
                    title: {
                        text: '水果统计',
                        /*subtext: '纯属虚构',*/
                        left: 'center'
                    },
                    /* backgroundColor: '#000',*/
                    color: ['#53f1f2', '#4ebefd', '#30ed9d', '#faa234'],
                    legend: {
                        show: true,
                        orient: 'vertical',
                        left: 'left',
                        top: 'middle',
                        data: ['苹果', '香蕉', '梨', '葡萄'],
                        textStyle: {
                            /*color: '#fff',*/
                            fontSize: 16
                        }
                    },
                    series: [{
                        name: '葡萄',
                        type: 'pie',
                        clockWise: true, //顺时加载
                        hoverAnimation: false, //鼠标移入变大
                        radius: [100, 101],
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false,
                                    position: 'outside'
                                },
                                labelLine: {
                                    show: false,
                                    length: 100,
                                    smooth: 0.5
                                },
                                borderWidth: BorderWidth,
                                /*shadowBlur: 40,*/
                                borderColor: "#53f1f2"
                                /*shadowColor: 'rgba(0, 0, 0, 0)' //边框阴影*/
                            }
                        },
                        data: [{
                                value: 7,
                                name: '70%'
                            },
                            {
                                value: 3,
                                name: '',
                                itemStyle: placeHolderStyle
                            }
                        ]
                    }, {
                        name: '梨',
                        type: 'pie',
                        clockWise: true,
                        hoverAnimation: false,
                        radius: [80, 81],
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false,
                                    length: 100,
                                    smooth: 0.5
                                },
                                borderWidth: BorderWidth,
                                /* shadowBlur: 40,*/
                                borderColor: "#4ebefd",
                                /*  shadowColor: 'rgba(0, 0, 0, 0)' //边框阴影*/
                            }
                        },
                        data: [{
                                value: 6,
                                name: '60%'
                            },
                            {
                                value: 4,
                                name: '',
                                itemStyle: placeHolderStyle
                            }
                        ]
                    }, {
                        name: '香蕉',
                        type: 'pie',
                        clockWise: true,
                        hoverAnimation: false,
                        radius: [60, 61],
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false,
                                    length: 100,
                                    smooth: 0.5
                                },
                                borderWidth: BorderWidth,
                                /*shadowBlur: 40,*/
                                borderColor: "#30ed9d",
                                /*shadowColor: 'rgba(0, 0, 0, 0)' //边框阴影*/
                            }
                        },
                        data: [{
                                value: 5,
                                name: '40%'
                            },
                            {
                                value: 5,
                                name: '',
                                itemStyle: placeHolderStyle
                            }
                        ]
                    }, {
                        name: '苹果',
                        type: 'pie',
                        clockWise: true,
                        hoverAnimation: false,
                        radius: [40, 41],
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false,
                                    length: 100,
                                    smooth: 0.5
                                },
                                borderWidth: BorderWidth,
                                /* shadowBlur: 40,*/
                                borderColor: "#faa234",
                                /*shadowColor: 'rgba(0, 0, 0, 0)' //边框阴影*/
                            }
                        },
                        data: [{
                                value: 5,
                                name: '30%'
                            },
                            {
                                value: 5,
                                name: '',
                                itemStyle: placeHolderStyle
                            }
                        ]
                    }]
                })
            },
            //Ring
            drawRing(line2) {
                this.echartsRing = echarts.init(document.getElementById('echartsRing'));
                // 初始化图表标签
                this.echartsRing.setOption({
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: 0,
                        left: 0,
                        right: 100,
                        textStyle: {
                            color: 'white',
                            fontSize: '12px'
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '5%',
                        top: '6%',
                        containLabel: true,
                    },
                    textStyle: {
                        fontSize: 15,
                        color: 'white'
                    },
                    series: [{
                        name: '',
                        type: 'funnel',
                        left: '10%',
                        top: 40,
                        //x2: 80,
                        bottom: 10,
                        width: '80%',
                        // height: {totalHeight} - y - y2,
                        min: 0,
                        max: 100,
                        minSize: '0%',
                        maxSize: '100%',
                        sort: 'descending',
                        gap: 1,
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        labelLine: {
                            length: 5,
                            lineStyle: {
                                width: 1,
                                type: 'solid'
                            }
                        },
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        emphasis: {
                            label: {
                                fontSize: 18
                            }
                        },



                        // radius: ['50%', '70%'],
                        // avoidLabelOverlap: false,
                        // itemStyle: {
                        //     borderRadius: 0,
                        //     borderColor: '#000',
                        //     borderWidth: 1
                        // },
                        // label: {
                        //     show: true,
                        //     color: 'white',
                        //     position: 'outside'
                        // },
                        // labelLine: {
                        //     show: true
                        // },                         
                        // emphasis: {
                        //     itemStyle: {
                        //         shadowBlur: 5,
                        //         shadowOffsetX: 0,
                        //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                        //     }
                        // },
                        data: line2
                        // [
                        //     {value: 1048, name: '搜索引擎'},
                        //     {value: 735, name: '直接访问'},
                        //     {value: 580, name: '邮件营销'},
                        //     {value: 484, name: '联盟广告'},
                        //     {value: 300, name: '视频广告'}
                        // ]
                    }]
                })
            },

            //月故障次数
            drawDefault(line) {
                this.echartsDefault = echarts.init(document.getElementById('echartsDefault'));
                this.echartsDefault.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },

                    legend: {
                        data: this.nameList,
                        // data: ['皮带机', '起重机', '传送带', '涡轮机'],
                        textStyle: {
                            color: 'white',
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '5%',
                        top: '20%',
                        containLabel: true
                    },
                    toolbox: {},
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                    },
                    yAxis: {
                        type: 'value',
                        min :0,
                        minInterval:1,
                        splitLine: { //网格线
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.2)'
                            }
                        }
                    },
                    series: line
                    //  [
                    //     {
                    //         name: '皮带机',
                    //         type: 'line',
                    //         stack: '次数',
                    //         data: [120, 132, 101, 134, 90, 230, 210]
                    //     },
                    //     {
                    //         name: '起重机',
                    //         type: 'line',
                    //         stack: '次数',
                    //         data: [220, 182, 191, 234, 290, 330, 310]
                    //     },
                    //     {
                    //         name: '传送带',
                    //         type: 'line',
                    //         stack: '次数',
                    //         data: [150, 232, 201, 154, 190, 330, 410]
                    //     },
                    //     {
                    //         name: '涡轮机',
                    //         type: 'line',
                    //         stack: '次数',
                    //         data: [320, 332, 301, 334, 390, 330, 320]
                    //     },
                    // ]
                })
            },
            //故障分析
            drawGZFX(data, legend) {
                this.echartsGZFX = echarts.init(document.getElementById('echartsGZFX'));
                this.echartsGZFX.setOption({
                    legend: {
                        // right: '10%',
                        // top: '3%',
                        bottom: '5%',
                        data: legend,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    tooltip: {},
                    grid: {
                        left: '8%',
                        top: '10%'
                    },
                    xAxis: {
                        type: 'category',
                        // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    },

                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    },
                    series: data
                })
            },
            gotoDetails() {
                this.$router.push({
                    path: '/deviceManagement/deviceDetails',
                })
            },


            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }

    }
</script>
<style scoped>
    .nav {
        width: 100%;
        height: 78px;
        background-image: url('../../assets/images/bulletinBoard/矢量智能对象(3).png');
        padding: 0 20px;
    }

    .navLeft {
        width: 400px;
        height: 78px;
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00A6FF;
    }

    .btn1 {
        background-image: url('../../assets/images/bulletinBoard/矩形 4.png');

        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #00A6FF;
        padding: 5px;
    }










    .content_kb {
        /* background-color: #151B32; */
        height: 100%;
    }

    .board_bgd {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/bulletinBoard/bj@2x.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: Source Han Sans SC; */
        padding: 0;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        /* background-image: url('../../assets/images/comprehensive/panel-bgimg.png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .mainContent {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: center;
    }

    .d_left1 {
        width: 95%;
        /* background-image: url('../../assets/images/bulletinBoard/组 9.png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        margin: 0 10px;
    }

    .d_left2 {
        width: 95%;
        /* background-image: url('../../assets/images/bulletinBoard/组 9.png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        margin: 0 10px;
    }

    .d_left3 {
        width: 95%;
        /* background-image: url('../../assets/images/bulletinBoard/组 9(4).png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        margin: 0 10px;
    }

    .d_center1 {
        width: 100%;

        /* background-image: url('../../assets/images/bulletinBoard/组 9(5).png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        /* flex-direction: column; */
        flex-direction: column;
        /* justify-content: space-between; */
    }

    .d_center2 {
        width: 100%;
        /* background-image: url('../../assets/images/bulletinBoard/组 9(5).png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;

        /* flex-direction: column; */
        /* margin-top: 15px; */
        /* justify-content: space-between; */
    }

    .d_center3 {
        width: 100%;
        /* background-image: url('../../assets/images/bulletinBoard/组 9(3).png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;


        /* flex-direction: column; */
        /* padding-top: 15px; */
        /* justify-content: space-between; */
    }

    .d_right1 {
        width: 95%;

        /* background-image: url('../../assets/images/bulletinBoard/组 9(1).png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;

    }

    .d_right2 {
        width: 95%;

        /* background-image: url('../../assets/images/bulletinBoard/组 9(1).png'); */
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
    }

    .diy_col {
        height: 36vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_div {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        padding-top: 15px;

        /* align-items: center; */
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        /* align-items: center; */
    }

    .diy_div3 {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        padding-top: 15px;
        /* justify-content: space-between; */
    }

    .diy_div_left {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        /* align-items: center; */
    }

    .diy_col_left {
        width: 29%;
        height: 100%;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;

    }

    .diy_col_center {
        width: 44%;
        height: 100%;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col_right {
        width: 29%;
        height: 100%;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .kbTitle {
        width: 95%;
        height: 28px;
        background: url('../../assets/images/bulletinBoard/矩形 1.png') no-repeat;
        text-align: left;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 28px;
        margin: 10px;
        padding-left: 5px;
        box-sizing: border-box;

    }

    .kbTitle1 {
        width: 95%;
        height: 28px;
        background: url('../../assets/images/bulletinBoard/矩形 1(1).png') no-repeat;
        text-align: left;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 28px;
        margin: 10px;
        padding-left: 5px;
        box-sizing: border-box;

    }

    .alarmL2 {
        width: 120px;
        height: 120px;
        background: url('../../assets/images/bulletinBoard/椭圆 5.png') no-repeat;
        font-size: 25px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #7CFFB2;
        line-height: 120px;
         margin: 0px 18px;
      
    }
.alarmLA2{
     width: 120px;
        height: 120px;
         font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1DFFFF;
        line-height: 63px;
}
    .alarmL21 {
        margin-top: 3px;
        width: 48px;
        height: 120px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 40px;
    }

    .alarmL22 {
        width: 250px;
        height: 120px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-align: left;
        line-height: 40px;
        margin: 15px 0 0 20px;
    }


    /* .kbContent>div{
        width:150px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-weight: 800;
    } */
    /* .kbContent1>div{
        width:320px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        color: white;
        font-weight: 800;
    } */
    /* .kbContent1{
        width:350px;
    } */


    .elfont>>>.el-progress__text {
        width: 50%;
        height: 50%;
        margin: 0px 31px;
        font-size: 24px ! important;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1DFFFF;
        line-height: 63px;

    }

    .elfont1>>>.el-progress__text {
        width: 50%;
        height: 50%;
        margin: 0px 24px;
        font-size: 19px ! important;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1DFFFF;
        line-height: 63px;
        
    }

    /* .elfont2 >>> .el-progress__text {
        width: 250px;
        height: 25px;
        margin: 0px 30px 0 0 ;
        font-size: 18px! important;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1DFFFF;
     } */

    .el-table th {
        background: #123D66 !important;

    }

    .el-table th,
    .el-table tr {
        background: #123D66 !important;
    }

    .el-table {
        border: 1px solid #123D66 !important;
    }

    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 78%;
        margin-top: 13px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        /* font-size: 20px; */
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    .element .el-row:first-child {
        background-color: #143765;
        /* border-top: 1px #ffffff solid; */
    }

    .element .el-row {
        /* border-bottom: 1px #ffffff solid;
    border-left: 1px #ffffff solid; */
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        /* border-right: 1px #ffffff solid; */
        padding: 10px 0px;
    }

    .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;

        /* color:#73f3ff; */
    }



    /* .col_overflow { */
    /* word-break: keep-all; */
    /* 不换行 */
    /* white-space: nowrap; */
    /* 不换行 */
    /* overflow: hidden; */
    /* 内容超出宽度时隐藏超出部分的内容 */
    /* text-overflow: ellipsis; */
    /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
    /* } */
</style>
<style lang="less" scoped>
    ::v-deep .el-progress-bar__outer {
        background-color: #0C2E8C;
    }

    ::v-deep .el-progress__text {
        color: #FDDD60;
        text-align: center;
        display: block;
    }
</style>