<!-- 工段长-综合看板 -->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="pdt_20 pdb_10 flex justify_between align_center">
                <div class="fn_size22 color_00C6FF">
                    <span>接入设备：</span>
                    <span class="color_F6FF00">12 </span>
                    <span>台</span>
                </div>
                <div class="topTitle fn_size28 color_FFFFFF">
                    {{this.boardTitle}}
                </div>
                <div class="fn_size22 color_00C6FF flex align_center ">
                    {{currentTime}}
                    <img src="../../assets/images/comprehensive/full.png" class="mg_left_20" alt="">
                </div>
            </div>

            <el-row>
                <el-col :span="6">
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div">
                                <div class="">设备产能</div>
                                <div id="ecartsGX" style="width:100%;height: 100%;"></div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div">
                                <div class="">开机率/开机利用率</div>
                                <div id="ecartsKJ" style="width:100%;height: 100%;"></div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12" class="diy_col2">
                    <div class="diy_div3">
                        <el-carousel indicator-position="outside" :interval="8000" :autoplay="true" height="45vh"
                            style="width: 100%;" @change="changeLineLayoutData">
                            <el-carousel-item v-for="(item,index) in lineLayoutDataList" :key="index"
                                style="width: 100%;height: 100%;" class=" flex flex_column align_center">
                                <span>设备布局 - {{item.name}}</span>
                                <el-popover placement="right" :title="item.name" width="200" trigger="hover" offse='20'>
                                    <div class="flex flex_column ">
                                        <span class="height_30">综合率：{{item.Efficency}}</span>
                                        <span class="height_30">故障率：{{item.FaultRate}}</span>
                                        <span class="height_30">OEE：{{item.OEE}}</span>
                                        <span class="height_30">开机率：{{item.OperationRate}}</span>
                                        <span class="height_30">利用率：{{item.UseRatio}}</span>
                                    </div>
                                    <div class="" style="width: 95%;height: 100%;" slot="reference">
                                        <img src="../../assets/images/comprehensive/middle-box2-content.png" class=""
                                            alt="">
                                    </div>

                                </el-popover>



                            </el-carousel-item>
                        </el-carousel>
                        <!-- <div class="diy_div_title">设备布局</div>
                        <div id="ecartsBJ" style="width:100%;height: 100%;">
                            <img src="../../assets/images/comprehensive/middle-box2-content.png" class="" alt="">
                        </div> -->
                    </div>
                </el-col>
                <el-col :span="6">
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div">
                                <div class="">巡检情况</div>
                                <div class="element">
                                    <el-row>
                                        <el-col :span="6">设备名称</el-col>
                                        <el-col :span="6">状态</el-col>
                                        <el-col :span="6">巡检时间</el-col>
                                        <el-col :span="6">巡检人</el-col>
                                    </el-row>
                                    <el-row class="myRow" v-for="(item,index) in pointInspectioArray" :key="index"
                                        :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                        <el-col :span="6">{{item.deviceName}}</el-col>
                                        <el-col :span="6">{{item.status}}</el-col>
                                        <el-col :span="6">{{item.oprTime}}</el-col>
                                        <el-col :span="6">{{item.execPerson}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div">
                                <div class="">保养维修计划</div>
                                <div class="element">
                                    <el-row>
                                        <el-col :span="4">设备名称</el-col>
                                        <el-col :span="4">类别</el-col>
                                        <el-col :span="8">计划开始</el-col>
                                        <el-col :span="8">计划结束</el-col>
                                    </el-row>
                                    <el-row class="myRow" v-for="(item,index) in maintainPlanArray" :key="index"
                                        :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                        <el-col :span="6">{{item.deviceName}}</el-col>
                                        <el-col :span="6">{{item.maintainType}}</el-col>
                                        <el-col :span="6">{{item.maintainStartTime}}</el-col>
                                        <el-col :span="6">{{item.maintainEndTime}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">综合利用率</div>
                        <div id="echartsZHLY" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div2">
                        <div class="">节拍分析</div>
                        <div id="ecartsJP" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">能耗/产值</div>
                        <div id="echartsNH" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
            </el-row>

        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import {
        organizationSelect,
        lineDashBoardGetTitle,
        lineProductionComparison,
        lineUseRatioComparison,
        lineSyntheticalUseRatioComparison,
        // lineLayoutData,
        lineRhythmData,
        linePointInspectioData,
        lineMaintainPlanData,
        lineEnergyData,
        // equipmentLayoutData
    } from "@/api/api_comprehensiveShow";
    export default {
        data() {
            return {
                currentTime: new Date(),
                permission: {},
                boardTitle: '',
                pointInspectioArray: [],
                maintainPlanArray: [],
                lineLayoutDataList: []
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                this.permission = JSON.parse(localStorage.getItem('permission'))
            };
            this.organizationSelect();
            this.lineDashBoardGetTitle();
            // this.equipmentLayoutData();
            this.lineProductionComparison();
            this.lineUseRatioComparison();
            this.lineSyntheticalUseRatioComparison();
            this.lineLayoutData();
            this.lineRhythmData();
            this.linePointInspectioData();
            this.lineMaintainPlanData();
            this.lineEnergyData();
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss');
        },
        methods: {
            // 组织架构选择
            async organizationSelect() {
                var param = {
                    permission: this.permission
                }
                const res = await organizationSelect(param);
                console.log(res);
            },
            // 获取看板标题
            async lineDashBoardGetTitle() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await lineDashBoardGetTitle(param);
                if (res && res.data) {
                    this.boardTitle = res.data
                }
            },
            // 获取设备布局
            async equipmentLayoutData() {
                var param = {
                    equipmentID: 100001
                }
                const res = await equipmentLayoutData(param);
                console.log(res)
                // if (res && res.data) {
                //     this.boardTitle = res.data
                // }
            },
            // 产线-设备产能对比
            async lineProductionComparison() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await lineProductionComparison(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawProductionShape(line)
            },
            // 产线-设备开机率利用率对比
            async lineUseRatioComparison() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await lineUseRatioComparison(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawUseRatioShape(line)
            },
            // 产线-设备综合利用率
            async lineSyntheticalUseRatioComparison() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await lineSyntheticalUseRatioComparison(param);
                var line = {
                    data: [],
                    oeeArray: []
                };
                for (var i = 0; i < res.data.list.length; i++) {
                    let testData = {
                        name: '',
                        max: 0
                    }
                    testData.name = res.data.list[i].name;
                    testData.max = res.data.max;
                    line.data.push(testData);
                    line.oeeArray.push(res.data.list[i].OEE)
                }
                this.drawSyntheticalUseRatioComparisonShap(line);
            },
            // 产线-设备布局数据
            async lineLayoutData() {
                var param = {
                    // organization: {
                    //     "EquipmentID": ['123', '456']
                    // }
                }
                const res = await lineLayoutData(param);
                // console.log(res);
                this.lineLayoutDataList = res.data
                // if (res && res.data) {
                //     this.boardTitle = res.data
                // }
            },
            changeLineLayoutData() {

            },
            // 产线-设备节拍分析
            async lineRhythmData() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await lineRhythmData(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        type: 'line',
                        smooth: true,
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        // areaStyle: {
                        //     color: '#00FCFF',
                        //     opacity: 0.2,
                        // },
                        showSymbol: false,
                        // itemStyle: {
                        //     normal: {
                        //         color: '#00FCFF'
                        //     }
                        // },
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawRhythmShape(line)
            },
            // 产线-设备巡检情况
            async linePointInspectioData() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await linePointInspectioData(param);
                if (res && res.data) {
                    this.pointInspectioArray = res.data
                }
            },
            // 产线-设备保养维修计划
            async lineMaintainPlanData() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await lineMaintainPlanData(param);
                if (res && res.data) {
                    this.maintainPlanArray = res.data
                }
            },
            // 产线-各设备能耗数据
            async lineEnergyData() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await lineEnergyData(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawEnergyShape(line)
            },
            // 画设备产能对比图
            drawProductionShape(data) {
                this.ecartsGX = echarts.init(document.getElementById('ecartsGX'));
                this.ecartsGX.setOption({
                    color: ['#2883FF', '#00FF00'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#E6E6E6'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    dataZoom: [{
                        type: 'slider',
                        filterMode: 'weakFilter',
                        showDataShadow: false,
                        top: 400,
                        // start: 0,
                        // end: 50,
                        handleSize: 8,
                        labelFormatter: ''
                    }, {
                        type: 'inside',
                        filterMode: 'weakFilter',
                        // start: 0,
                        // end: 50
                    }],
                    yAxis: [{
                            type: 'value',
                            // min: 0,
                            // max: 250,
                            // interval: 50,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },
                        {
                            type: 'value',
                            // min: 0,
                            // max: 25,
                            // interval: 5,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[1].data
                        }
                    ]
                });
                // this.ecartsGX.hideLoading()
            },
            // 画节拍分析图
            drawRhythmShape(data) {
                this.ecartsJP = echarts.init(document.getElementById('ecartsJP'));
                this.ecartsJP.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        x: 'center',
                        y: 'bottom',
                        bottom: '5%',
                        textStyle: {
                            color: '#ffffff',
                        },
                        icon: 'rect',
                        itemWidth: 8,
                        itemHeight: 8,
                    },
                    grid: {
                        left: '4%',
                        right: '6%',
                        bottom: '10%',
                        top: "10%",
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        boundaryGap: true,
                        axisTick: {
                            alignWithLabel: true
                        },
                        data: data.xAxis.data,
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        interval: 50,
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    }],
                    series: data.series
                });
                // this.ecartsJP.hideLoading()
            },
            // 画设备开机率利用率对比图
            drawUseRatioShape(data) {
                this.ecartsKJ = echarts.init(document.getElementById('ecartsKJ'));
                this.ecartsKJ.setOption({
                    color: ['#288DFF', '#52FFFF'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        icon: 'rect',
                        itemHeight: 10, //修改icon图形大小
                        itemWidth: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[1].data
                        }
                    ]
                });
                // this.ecartsKJ.hideLoading()
            },
            // 画能耗产值对比图
            drawEnergyShape(data) {
                this.echartsNH = echarts.init(document.getElementById('echartsNH'));
                this.echartsNH.setOption({
                    color: ['#1BD260', '#F8AF21'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            // min: 0,
                            // max: 250,
                            // interval: 50,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },
                        {
                            type: 'value',
                            // min: 0,
                            // max: 25,
                            // interval: 5,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[1].data
                        }
                    ]
                });
                // this.echartsNH.hideLoading()
            },
            // 画综合利用率
            drawSyntheticalUseRatioComparisonShap(data) {
                this.echartsZHLY = echarts.init(document.getElementById('echartsZHLY'));
                this.echartsZHLY.setOption({
                    color: ['#00FF00'],
                    legend: {
                        data: ['利用率'],
                        bottom: '5%',
                        itemHeight: 4,
                        itemWidth: 20,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    radar: {
                        radius: 50,
                        shape: 'circle',
                        center: ['50%', '45%'],
                        indicator: data.data
                    },
                    series: [{
                        type: 'radar',
                        data: [{
                            value: data.oeeArray,
                            name: '利用率',
                            areaStyle: {
                                color: '#00FF00'
                            }
                        }]
                    }]
                })
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }

    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
    }

    .board_bgd {
        width: 100vw;
        height: 86vh;
        background-image: url('../../assets/images/comprehensive/bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        padding: 0 20px;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        background-image: url('../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 25vh;
        color: #FFFFFF;
        font-size: 14px;
        /* margin-bottom: 10px; */
    }

    .diy_col2 {
        height: 50vh;
        color: #FFFFFF;
        font-size: 14px;
        /* margin-bottom: 10px; */
    }

    .diy_div {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div3 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/middle-box2.png');
        background-repeat: no-repeat;
        background-size: 99% 98%;
        padding-top: 3px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        margin-left: 5px;
        margin-top: 5px;
    }

    .diy_div_title {
        padding-bottom: 3px;
    }

    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 78%;
        margin-top: 2px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 12px;
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    .element .el-row:first-child {
        background-color: #143765;
        /* border-top: 1px #ffffff solid; */
    }

    .element .el-row {
        /* border-bottom: 1px #ffffff solid;
    border-left: 1px #ffffff solid; */
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        /* border-right: 1px #ffffff solid; */
        padding: 10px 0px;
    }

    .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;
        /* color:#73f3ff; */
    }
</style>