<!-- 设备台账 -->
<template>
    <div class="page_container">
        <!-- 标题 -->
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">设备台账</div>
        </div>
        <!-- 主要页面 -->
        <div class="flex flex_column bgc_FFFFFF pdl30 pdr30">
            <!-- select下拉框 查询按钮 -->
            <div class="flex  mg_top_31 justify_between">
                <!-- select下拉框 -->
                <div class="flex">
                    <el-select v-model="value" placeholder="设备类型" class="width_150 mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value" placeholder="设备等级" class="width_150 mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input v-model="input" placeholder="请输入设备编码或名称" class="width_270 mg_right_10">
                    </el-input>
                    <el-button type="primary" class="equipmentAccountSelectButton flex align_center justify_center">查询
                    </el-button>
                </div>
                <!-- 查询按钮 -->
                <div>
                    <el-button type="primary" class="equipmentAccountSelectButton flex align_center justify_center">导出
                    </el-button>
                </div>
            </div>
            <!-- table -->
            <div class="flex flex_column equipmentAccountTable fn_size14 mg_top_31 ">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    @selection-change="handleSelectionChange" max-height="530px">
                    <el-table-column type="selection" min-width="50"></el-table-column>
                    <el-table-column prop="name" label="序号" min-width="80"></el-table-column>
                    <el-table-column prop="name" label="设备名称" min-width="120"></el-table-column>
                    <el-table-column prop="date" label="设备编码" min-width="120"></el-table-column>
                    <el-table-column prop="name" label="设备类型" min-width="120"></el-table-column>
                    <el-table-column prop="name" label="设备等级" min-width="120"></el-table-column>
                    <el-table-column prop="date" label="设备型号" min-width="120"></el-table-column>
                    <el-table-column prop="name" label="控制系统" min-width="120"></el-table-column>
                    <el-table-column prop="name" label="所属部门" min-width="120"></el-table-column>
                    <el-table-column prop="name" label="使用部门" min-width="120"></el-table-column>
                    <el-table-column prop="name" label="管理员" min-width="120"></el-table-column>
                    <el-table-column prop="name" label="安装位置" min-width="120"></el-table-column>
                    <el-table-column prop="date" label="入厂时间" min-width="150"></el-table-column>
                    <el-table-column prop="address" label="备注" min-width="160" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="资料管理" min-width="110">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                                <span class="border_bottom_1_009BFD">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_between align_start mg_top_31 ">
                <div class="color_808080 fn_size14 mg_top_19">当前1-15 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        data() {
            return {
                value: '',
                input: '',
                options: [],
                tableData: [{
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }],
                multipleSelection: []
            }
        },

        mounted() {},
        methods: {
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
        }
    }
</script>

<style scoped>
    .equipmentAccountTable {
        /* width: 1800px; */
        height: 530px;
    }
    
    .equipmentAccountSelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }

    .diy_div {
        width: 100%;
        height: 450px;
    }
</style>