<!-- -故障报修--修改报修 -->
<template>
    <div class="page_container" v-loading="loading">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">设备信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备名称：">
                    <el-input v-model="formInline.deviceName" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备类别：">                  
                    <el-input v-model="formInline.deviceType_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="规格型号：">
                    <el-input v-model="formInline.deviceModel" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="所在部门：">
                    <el-input v-model="formInline.deviceDepartment" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备位置：">
                    <el-input v-model="formInline.deviceLocation" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item>
                    <!-- <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;">
                        选择设备</el-button> -->
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">故障信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <!-- <el-form-item label="发生时间：">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.failureTime" type="datetime"
                        format="yyyy-MM-dd HH:mm" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item> 
                <el-form-item label="操作人：">
                    <el-input v-model="formInline.oprPerson" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="操作者电话：">
                    <el-input v-model="formInline.oprPhoneNumber" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>-->
                <el-form-item label="故障等级：" >
                    <el-select v-model="formInline.failureLevel"  placeholder="" class="width_180 ">
                        <el-option v-for="item in levelData" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="故障类别：">
                    <el-select v-model="formInline.failureType"  placeholder="" class="width_180"
                        size="medium " >
                        <el-option v-for="item in typeData" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="故障等级：">
                    <el-input v-model="formInline.failureLevel" placeholder="活动区域" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="故障类别：">
                    <el-input v-model="formInline.failureType" placeholder="活动区域" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="当前审批顺序：">
                    <el-input v-model="formInline.authSort" placeholder="活动区域" class="width_180"></el-input>
                </el-form-item>
                 <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item> 
                <el-form-item label="是否停机：">
                    <el-input v-model="formInline.machineStop" placeholder="活动区域" class="width_180"></el-input>
                </el-form-item> -->
                <el-form-item label="是否停机：" class=" ">
                    <el-radio-group v-model="formInline.machineStop" class="width_180">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                
                <!-- <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" type="textarea" :rows="5" resize="none" placeholder="审批人" class="width_180"></el-input>
                </el-form-item> -->
            </el-form>
            <el-form  :model="formInline" :label-width="formLabelWidth" style="width: 65%;">
                    <el-form-item label="故障描述：">
                        <el-input  type="textarea" :rows="5" resize="none" v-model="formInline.failureDesc" placeholder="" ></el-input>
                    </el-form-item>
                </el-form>
            <el-form  :model="formInline" :label-width="formLabelWidth" style="width: 65%;">
                    <el-form-item label="备注：">
                        <el-input  type="textarea" :rows="5" resize="none" v-model="formInline.remarks" placeholder="" ></el-input>
                    </el-form-item>
                </el-form>
            <!-- <el-form :model="formInline" label-width="90px" style="width:62%; ">
                <el-form-item label="故障描述：" prop="user">
                    <el-input v-model="formInline.failureDesc" type="textarea" :rows="5" resize="none"></el-input>
                </el-form-item>
            </el-form> -->

            <!-- <div class="box_head1 width_100">关联图片</div> -->
            <!-- <el-form :model="formInline" label-width="110px" style="width:65%; " class="mg_top_30"> -->
                <!-- <el-form-item label="图片上传：" prop="user"> -->
                    <!-- <el-input placeholder="请输入内容" v-model="input2">
                        <template slot="append">图片上传</template>
                    </el-input> -->
                    <!-- <el-upload action="https://jsonplaceholder.typicode.com/posts/" :on-success="handleAvatarSuccess"
                        :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple
                        :limit="3" :on-exceed="handleExceed">
                        <el-input placeholder="请输入内容" v-model="fileList[0].name" disabled slot="trigger">
                            <template slot="append">图片上传</template>
                        </el-input>
                    </el-upload>
                </el-form-item> -->
                <!--    <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
                    :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple
                    :limit="3" :on-exceed="handleExceed" :file-list="fileList">
                    
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload> -->

            <!-- </el-form> -->
            <div class="flex  mg_bottom_20">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        failureReportQuery,
        failureReportUpdate,
        faultTypeQuery,
        faultLevelQuery,
    } from '@/api/api_deviceManagement.js'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                formInline: {
                    deviceID:'',
                    deviceName:'',
                    deviceType:'',
                    deviceModel:'',
                    deviceDepartment:'',
                    deviceLocation:'',
                    failureTime:'',
                    oprPerson:'',
                    oprPhoneNumber:'',
                    failureLevel:'',
                    failureType:'',
                    authSort:'',
                    machineStop:'',
                    failureDesc:'',
                    isAuth:'',
                    remarks:'',
                    userID:'',
                    levelData:[],
                    typeData:[],
                },
                input2: '',
                fileList: [{
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }, ],
                insertResult:false,
                failureData:'',
                ID:'',
                loading:true,
                levelData:[],
                typeData:[],
                formLabelWidth:'110px'
            }
        },
       
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.faultLevelQuery()
            this.faultTypeQuery()
            this.failureReportQuery()
        },
        methods: {
            //loading 
            getData(){
            const loading = this.$loading({
                    lock: true,//lock的修改符--默认是false
                    text: 'Loading',//显示在加载图标下方的加载文案
                    spinner: 'el-icon-loading',//自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                    target: document.querySelector('#table')//loadin覆盖的dom元素节点
            });
            loading.close() //成功回调函数停止加载
            },
            sbumit() {
                this.failureReportUpdate();
                
            },
            colsePage() {
                this.$router.go(-1);
            },
            handleRemove(file, fileList) { //文件列表移除文件时的钩子
                console.log(file, fileList);
            },
            handlePreview(file) { //点击文件列表中已上传的文件时的钩子
                console.log(file);
            },
            handleExceed(files, fileList) { //文件超出个数限制时的钩子
                this.$message.warning(
                    `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) { //删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            handleAvatarSuccess(res, file) {
                // this.imageUrl = URL.createObjectURL(file.raw);
            },

            //查询单条
            async failureReportQuery() {
                this.ID=window.localStorage.getItem('editValue')
                console.log(this.ID)
                var param = {
                    ID:this.ID,
                }
                const selectRes = await failureReportQuery(param);
                this.formInline=selectRes.rows[0]
                console.log(this.formInline);
                
            },
            //修改
             async failureReportUpdate() {
                var param = {
                    ID:this.ID,
                    deviceName:this.formInline.deviceName,
                    deviceType:this.formInline.deviceType,
                    deviceModel:this.formInline.deviceModel,
                    deviceDepartment:this.formInline.deviceDepartment,
                    deviceLocation:this.formInline.deviceLocation,
                    failureTime:this.formInline.failureTime,
                    oprPhoneNumber:this.formInline.oprPhoneNumber,
                    failureLevel:this.formInline.failureLevel,
                    failureType:this.formInline.failureType,
                    machineStop:this.formInline.machineStop,
                    failureDesc:this.formInline.failureDesc,
                    remarks:this.formInline.remarks,         
                }
                const selectRes = await failureReportUpdate(param);
                // console.log(this.formInline);
                this.changeResult = selectRes.data.result
                if(this.changeResult==true){
                    this.$message({
                        message: selectRes.message,
                        type: "success",
                    })
                   this.$router.go(-1); 
                }else{
                    this.$message({
                        message: selectRes.message,
                        type: "warning",
                    })
                    this.$router.go(-1); 
                }
            },
            //类型下拉框
            async faultTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultTypeQuery(param)
                this.typeData = selectRes.data
                

            },
            //等级下拉框
            async faultLevelQuery() {
                var param = {
                    userID:this.userID
                }
                const selectRes = await faultLevelQuery(param)
                this.levelData = selectRes.data
                this.loading = false;
            },
        }
    }
</script>
<style scoped>
    
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    } 
</style>