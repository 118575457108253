<!-- a制造中心总监主页面_综合看板-->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="pdt_20 pdb_10 flex justify_between align_center">
                <div class="fn_size22 color_00C6FF">
                    <span>接入设备：</span>
                    <span class="color_F6FF00">69 </span>
                    <span>台</span>
                </div>
                <div class="topTitle fn_size28 color_FFFFFF">
                    {{this.boardTitle}}
                </div>
                <div class="fn_size22 color_00C6FF flex align_center ">
                    {{currentTime}}
                    <img src="../../assets/images/comprehensive/full.png" class="mg_left_20" alt="">
                </div>
            </div>

            <el-row :gutter="5">
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">设备构成</div>
                        <div id="ecartsGC" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div3">
                        <!-- <div class="">节拍分析</div> -->
                        <div id="ecartsDFH1" style="width:30%;height: 100%;"></div>
                        <div id="ecartsDFH2" style="width:30%;height: 100%;"></div>
                        <div id="ecartsDFH3" style="width:30%;height: 100%;"></div>
                        <div id="ecartsDFH4" style="width:30%;height: 100%;"></div>
                        <!-- <div id="ecartsJP" style="width:100%;height: 100%;"></div> -->
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">待机分析</div>
                        <div id="ecartsDJ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">开机率/开机利用率</div>
                        <div id="ecartsKJ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div2">
                        <div class="">故障分析(以设备型号统计分析)</div>
                        <div id="echartsGZ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">停机分析</div>
                        <div id="echartsTJ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">综合利用率</div>
                        <div id="echartsZHLY" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">维修产出比</div>
                        <div id="echartsWX" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">MTBF/MTTR/故障率</div>
                        <div id="echartsGMTBF" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">能耗/产值</div>
                        <div id="echartsNH" style="width:100%;height: 100%;"></div>

                    </div>
                </el-col>
            </el-row>

        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import {
        plantDashBoardGetTitle,
        plantOrganizationSelect,
        plantEquipmentConstitute,
        plantWorkShopUseRatioComparison,
        plantWorkShopSyntheticalUseRatioComparison,
        plantEquipmentStatusStatistics,
        plantFaultAnalysis,
        plantMaintainOutputProportion,
        plantMttrMtbfFaultrateComparison,
        plantStandbyAnalysis,
        plantStopAnalysis,
        plantWorkShopEnergyData
    } from "@/api/api_comprehensiveShow";
    export default {
        data() {
            return {
                currentTime: new Date(),
                permission: {},
                boardTitle: ''
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                this.permission = JSON.parse(localStorage.getItem('permission'))
            };
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss')
            this.plantOrganizationSelect();
            this.plantDashBoardGetTitle();
            this.plantEquipmentConstitute();
            this.plantStandbyAnalysis();
            this.plantWorkShopEnergyData();
            this.plantMaintainOutputProportion();
            this.plantWorkShopUseRatioComparison();
            this.plantStopAnalysis();
            this.plantMttrMtbfFaultrateComparison();
            this.plantWorkShopSyntheticalUseRatioComparison();
            this.plantEquipmentStatusStatistics();
            this.plantFaultAnalysis();
        },
        methods: {
            // 组织架构选择
            async plantOrganizationSelect() {
                var param = {
                    permission: this.permission
                }
                const res = await plantOrganizationSelect(param);
                console.log(res);
            },
            // 获取看板标题
            async plantDashBoardGetTitle() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantDashBoardGetTitle(param);
                if (res && res.data) {
                    this.boardTitle = res.data
                }
            },
            // 工厂设备构成按设备类型统计
            async plantEquipmentConstitute() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantEquipmentConstitute(param);
                if (res && res.data && res.data.dataList) {
                    this.drawConstituteShape(res.data);
                }
            },
            // 工厂待机分析
            async plantStandbyAnalysis() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantStandbyAnalysis(param);
                if (res && res.data && res.data.dataList) {
                    this.drawStandbyAnalysis(res.data);
                }
            },
            // 工厂各车间能耗数据
            async plantWorkShopEnergyData() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantWorkShopEnergyData(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawEnergyShape(line)
            },
            // 工厂各车间维修产出比
            async plantMaintainOutputProportion() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantMaintainOutputProportion(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawtMaintainOutputProportionShape(line)
            },
            // 开机率/开机利用率对比
            async plantWorkShopUseRatioComparison() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantWorkShopUseRatioComparison(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawWorkShopUseRatioComparisonShape(line)
            },
            // 工厂停机分析
            async plantStopAnalysis() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantStopAnalysis(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawStopAnalysisShape(line)
            },
            // 工厂各车间MTTR/MTBF/故障率比较
            async plantMttrMtbfFaultrateComparison() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantMttrMtbfFaultrateComparison(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawtMttrMtbfFaultrateComparisonShape(line)
            },
            // 工厂各车间综合利用率
            async plantWorkShopSyntheticalUseRatioComparison() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantWorkShopSyntheticalUseRatioComparison(param);
                var line = {
                    data: [],
                    oeeArray: []
                };
                for (var i = 0; i < res.data.list.length; i++) {
                    let testData = {
                        name: '',
                        max: 0
                    }
                    testData.name = res.data.list[i].name;
                    testData.max = res.data.max;
                    line.data.push(testData);
                    line.oeeArray.push(res.data.list[i].OEE)
                }
                this.drawWorkShopSyntheticalUseRatioComparisonShap(line);
            },
            // 工厂设备各状态统计
            async plantEquipmentStatusStatistics() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantEquipmentStatusStatistics(param);
                this.drawShap(res.data);
            },
            // 工厂设备故障分析
            async plantFaultAnalysis() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await plantFaultAnalysis(param);
                var testData = res.data;
                var line = []
                var legend = [];
                for(var i = 0; i < testData.length; i++) {
                    var dataA = testData[i];
                    var currentData = {
                        name: '',
                        data: testData[i],
                        type: 'scatter',
                        symbolSize: function (data) { //气泡的大小
                            return data[2];
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    }
                    legend.push(dataA[0][3])
                    currentData.name = dataA[0][3]
                    line.push(currentData)
                }
                this.drawShapQP(line,legend)
            },
            // 画故障分析图
            drawShapQP(data,legend) {
                this.echartsGZ = echarts.init(document.getElementById('echartsGZ'));
                this.echartsGZ.setOption({
                    legend: {
                        // right: '10%',
                        // top: '3%',
                        bottom: '5%',
                        data: legend,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    tooltip: {},
                    grid: {
                        left: '8%',
                        top: '10%'
                    },
                    xAxis: {
                        type: 'category',
                        // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    },
                    
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    },
                    series: data
                })
            },
            // 画综合利用率
            drawWorkShopSyntheticalUseRatioComparisonShap(data) {
                this.echartsZHLY = echarts.init(document.getElementById('echartsZHLY'));
                this.echartsZHLY.setOption({
                    color: ['#00FF00'],
                    legend: {
                        data: ['利用率'],
                        bottom: '5%',
                        itemHeight: 4,
                        itemWidth: 20,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    radar: {
                        radius: 50,
                        shape: 'circle',
                        center: ['50%', '45%'],
                        indicator: data.data
                    },
                    series: [{
                        type: 'radar',
                        data: [{
                                value: data.oeeArray,
                                name: '利用率',
                                areaStyle: {
                                    color: '#00FF00'
                                }
                            }
                        ]
                    }]
                })
            },
            // 画工厂各车间MTTR/MTBF/故障率比较图
            drawtMttrMtbfFaultrateComparisonShape(data) {
                this.echartsGMTBF = echarts.init(document.getElementById('echartsGMTBF'));
                this.echartsGMTBF.setOption({
                    color: ['#2883FF', '#F8AF21', '#00FF00'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },{
                            type: 'value',
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                formatter: '{value} %'
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[0].data
                        },{
                            name: data.series[1].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[1].data
                        },{
                            name: data.series[2].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[2].data
                        }
                    ]
                });
            },
            // 画工厂停机分析图
            drawStopAnalysisShape(data) {
                this.echartsTJ = echarts.init(document.getElementById('echartsTJ'));
                this.echartsTJ.setOption({
                    color: ['#28B1FF', '#1BD260'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        icon: 'rect',
                        itemHeight: 10, //修改icon图形大小
                        itemWidth: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'value',
                        show: false,
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            barWidth: '5',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'bar',
                            barWidth: '5',
                            data: data.series[1].data
                        }
                    ]
                });
            },
            // 画开机率/开机利用率对比图
            drawWorkShopUseRatioComparisonShape(data) {
                this.ecartsKJ = echarts.init(document.getElementById('ecartsKJ'));
                this.ecartsKJ.setOption({
                    color: ['#288DFF', '#52FFFF'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        icon: 'rect',
                        itemHeight: 10, //修改icon图形大小
                        itemWidth: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[1].data
                        }
                    ]
                });
            },
            // 画维修产出比图
            drawtMaintainOutputProportionShape(data) {
                this.echartsWX = echarts.init(document.getElementById('echartsWX'));
                this.echartsWX.setOption({
                    color: ['#2883FF', '#EE1C91'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            // min: 0,
                            // max: 250,
                            // interval: 50,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },
                        {
                            type: 'value',
                            // min: 0,
                            // max: 25,
                            // interval: 5,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[1].data
                        }
                    ]
                });
            },
            // 画能耗产值对比图
            drawEnergyShape(data) {
                this.echartsNH = echarts.init(document.getElementById('echartsNH'));
                this.echartsNH.setOption({
                    color: ['#1BD260', '#F8AF21'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            // min: 0,
                            // max: 250,
                            // interval: 50,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },
                        {
                            type: 'value',
                            // min: 0,
                            // max: 25,
                            // interval: 5,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[1].data
                        }
                    ]
                });
            },
            // 画待机分析图
            drawStandbyAnalysis(data) {
                this.ecartsDJ = echarts.init(document.getElementById('ecartsDJ'));
                this.ecartsDJ.setOption({
                    // color: ['#568AEF', '#50E4AC', '#2A3764', '#FFCA2B', '#FF6450', '#00C6FF'],
                    title: {
                        text: ['{name|总}', '{value|' + data.total + '}'].join('\n'),
                        top: 'center',
                        left: '39%',
                        textAlign: 'center',
                        textStyle: {
                            rich: {
                                value: {
                                    color: '#FFFFFF',
                                    fontSize: 24,
                                    lineHeight: 24,
                                },
                                name: {
                                    color: '#FFFFFF',
                                    fontSize: 14,
                                    lineHeight: 35,
                                },
                            },
                        },
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        // top: '5%',
                        // left: 'center',
                        x: 'right', //可设定图例在左、右、居中
                        y: 'center', //可设定图例在上、下、居中
                        orient: 'vertical',
                        icon: 'circle',
                        padding: [0, 40, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    series: [{
                        name: '访问来源',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['40%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            color: '#FFFFFF',
                            fontSize: 12,
                            formatter: '{d}%',
                            // formatter: '{d}%',
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: true
                        },
                        data: data.dataList
                    }]
                })
            },
            // 画设备构成图
            drawConstituteShape(data) {
                this.ecartsGC = echarts.init(document.getElementById('ecartsGC'));
                this.ecartsGC.setOption({
                    // color: ['#568AEF', '#50E4AC', '#2A3764', '#FFCA2B', '#FF6450', '#00C6FF'],
                    title: {
                        text: ['{name|总}', '{value|' + data.total + '}'].join('\n'),
                        top: 'center',
                        left: '39%',
                        textAlign: 'center',
                        textStyle: {
                            rich: {
                                value: {
                                    color: '#FFFFFF',
                                    fontSize: 24,
                                    lineHeight: 24,
                                },
                                name: {
                                    color: '#FFFFFF',
                                    fontSize: 14,
                                    lineHeight: 35,
                                },
                            },
                        },
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        // top: '5%',
                        // left: 'center',
                        x: 'right', //可设定图例在左、右、居中
                        y: 'center', //可设定图例在上、下、居中
                        orient: 'vertical',
                        icon: 'circle',
                        padding: [0, 40, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    series: [{
                        name: '访问来源',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['40%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            color: '#FFFFFF',
                            fontSize: 12,
                            formatter: '{d}%',
                            // formatter: '{d}%',
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: true
                        },
                        data: data.dataList
                    }]
                })
            },
            // 画工厂设备各状态统计图
            drawShap(data) {
                console.log(data)
                this.ecartsDFH1 = echarts.init(document.getElementById('ecartsDFH1'));
                this.ecartsDFH1.setOption({
                    color: ['#ACACAC'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '45%'],
                        radius: '50%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#F5F5F5'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: data.stopDeviceNum,
                            name: '停机', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '140%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 24,
                            color: '#CECECE', //百分比的演的
                            borderShow: false,
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 0, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                })
                this.ecartsDFH2 = echarts.init(document.getElementById('ecartsDFH2'));
                this.ecartsDFH2.setOption({
                    color: ['#00FF00'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '45%'],
                        radius: '50%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#F5F5F5'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: data.OperatingDeviceNum,
                            name: '运行', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '140%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 24,
                            color: '#CECECE', //百分比的演的
                            borderShow: false,
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 0, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                })
                this.ecartsDFH3 = echarts.init(document.getElementById('ecartsDFH3'));
                this.ecartsDFH3.setOption({
                    color: ['#FF9C00'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '45%'],
                        radius: '50%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#F5F5F5'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: data.standbyDeviceNum,
                            name: '待机', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '140%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 24,
                            color: '#CECECE', //百分比的演的
                            borderShow: false,
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 0, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                })
                this.ecartsDFH4 = echarts.init(document.getElementById('ecartsDFH4'));
                this.ecartsDFH4.setOption({
                    color: ['#FD4444'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '45%'],
                        radius: '50%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#F5F5F5'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: data.errDeviceNum,
                            name: '故障', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '140%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 24,
                            color: '#CECECE', //百分比的演的
                            borderShow: false,
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 0, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                })
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }

    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
    }

    .board_bgd {
        width: 100vw;
        height: 86vh;
        background-image: url('../../assets/images/comprehensive/bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        padding: 0 20px;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        background-image: url('../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 25vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_div {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div3 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        /* justify-content: space-between; */
    }
</style>