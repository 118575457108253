<!-- 设备管理-保养管理-保养工单-详情 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">设备信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_10 mg_top_40 details_box"> -->
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备类型：" class=" ">
                    <el-input v-model="formInline.deviceType_name" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-input v-model="formInline.deviceModel" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="所属部门：" class=" ">
                    <el-input v-model="formInline.deviceDepartment" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.deviceLocation" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="保养单号：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item> -->
                <!-- <el-form-item class=" ">
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;">
                        选择设备</el-button>
                </el-form-item> -->
            </el-form>

            <!-- <div class="box_head1 width_100">处理情况</div> -->
            <div class="box_head1 width_100">基本信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_20 mg_top_40 details_box"> -->
            <el-form :inline="true" class="mg_top_30 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="记录人：" class=" ">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="工单编号：" class=" ">
                    <el-input v-model="formInline.orderID" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <!--  -->
                <el-form-item label="保养类型：">
                    <el-input v-model="formInline.execType_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execType" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in maintainCategory" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <!-- <el-form-item label="工单编号：" class=" ">
                    <el-input v-model="formInline.orderID" placeholder="" class="width_180">
                    </el-input>
                </el-form-item> -->
                <el-form-item label="当前审批顺序：" class=" ">
                    <el-input v-model="formInline.authRoleName" type="number" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="工单状态：">
                    <el-input v-model="formInline.orderStatus_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.orderStatus" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in workOrderStatus" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="保养等级：">
                    <el-input v-model="formInline.execLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execLevel" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in maintainLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="保养预估时间：" class=" ">
                    <el-input v-model="formInline.execEstimateLen" type="number" placeholder="" class="width_180"
                        disabled>
                        <template slot="append">分钟</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="紧急程度：">
                    <el-input v-model="formInline.urgenLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.urgenLevel" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in maintainUrgenLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="保养组：">
                    <el-select v-model="formInline.execGroup" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in maintainExecGroup" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否停机：" class="">
                    <el-checkbox v-model="formInline.machineStop" class="width_180 text_align_left" disabled>
                    </el-checkbox>
                </el-form-item>
                 <el-form-item label="停机时长：" class=" ">
                    <el-input v-model="formInline.stopLen" type="number" placeholder="" class="width_180" disabled>
                        <template slot="append">分钟</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="保养人：" class=" ">
                    <el-input v-model="formInline.execPerson" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execPerson" placeholder="请选择" class="width_180" multiple
                        collapse-tags disabled>
                        <el-option v-for="(item,index) in maintenancePeople" :key="index" :label="item.userName"
                            :value="item.userID">
                        </el-option>
                    </el-select> -->
                </el-form-item>
               
                <el-form-item label="派单时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.dispatchTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="备注：" class=" ">
                    <el-input v-model="formInline.remarks" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item> -->

                <el-form-item label="指定接单时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.assignTakeTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否接单逾期：" class="">
                    <el-checkbox v-model="formInline.isTakeOverdue" class="width_180 text_align_left" disabled>
                    </el-checkbox>
                </el-form-item>

                <el-form-item label="接单时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.takeTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="保养开始时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.startTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="保养结束时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.endTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否逾期保养结束：" class="">
                    <el-checkbox v-model="formInline.isEndOverdue" class="width_180 text_align_left" disabled>
                    </el-checkbox>
                </el-form-item>
                <el-form-item label="指定保养结单日期：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.assignEndTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.verifyTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证备注：" class=" ">
                    <el-input v-model="formInline.verifyRemarks" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="验证不通过保养开始时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.execRejectStartTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证不通过保养结束时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.execRejectEndTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="保养时长：" class=" ">
                    <el-input v-model="formInline.execLenM" type="number" placeholder="" disabled class="width_180">
                        <template slot="append">分钟</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="保养花费：" class=" ">
                    <el-input v-model="formInline.execFee" type="number" placeholder="" disabled class="width_180">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="现场情况：" class=" ">
                    <el-input v-model="formInline.spotCondition" placeholder="" disabled class="width_180">
                    </el-input>
                </el-form-item> -->
                <!-- <el-form-item label="工作描述：" class=" ">
                    <el-input v-model="formInline.execDesc" placeholder="" disabled class="width_180">
                    </el-input>
                </el-form-item> -->
                <!-- <el-form-item label="评分等级：" class=" ">
                     <el-input v-model="formInline.execGrade_name" placeholder="" class="width_180" disabled>
                    </el-input> -->
                    <!-- <el-select v-model="formInline.execGrade" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in maintenanceLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                <!-- </el-form-item> -->
                <el-form-item label="是否为经验性记录：" class="">
                    <el-checkbox v-model="formInline.isEmpirical" class="width_180 text_align_left" disabled>
                    </el-checkbox>
                </el-form-item>
                <!-- <el-form-item label="用户编号：" class=" ">
                    <el-input v-model="formInline.userID" placeholder="" class="width_180">
                    </el-input>
                </el-form-item> -->
                <!-- <el-form-item label="项目编号：" class=" ">
                    <el-input v-model="formInline.projectID" type="number" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item> -->
                <el-form-item label="是否审批：" class="">
                    <el-checkbox v-model="formInline.isAuth" disabled class="width_180 text_align_left"></el-checkbox>
                </el-form-item>
                <el-form-item label="是否委外：" class="">
                    <el-checkbox v-model="formInline.isOutEntrust" disabled class="width_180 text_align_left">
                    </el-checkbox>
                </el-form-item>

                <el-form-item label="暂离时间列表：" class="">
                    <el-button type="primary" size="medium" class="mg_left_8" style="background: #03CABE; border: none;"
                        @click="showPauseTimeList">
                        查看</el-button>
                </el-form-item>
            </el-form>

            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%;" class="mg_bottom_20">
                <el-form-item label="备注：" prop="">
                    <el-input type="textarea" :rows="3" v-model="formInline.remarks" resize="none" disabled></el-input>
                </el-form-item>
                <el-form-item label="现场情况：" class=" ">
                    <el-input type="textarea" :rows="3" v-model="formInline.spotCondition" placeholder="" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="保养描述：" class=" ">
                    <el-input type="textarea" :rows="3" v-model="formInline.execDesc" placeholder="" disabled>
                    </el-input>
                </el-form-item>
            </el-form>


            <!-- <div class="box_head1 width_100">保养工作量</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                    style=" background: #03CABE;border: none;">添加人员</el-button>
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" max-height="250" border>
                    <el-table-column type="index" label="序号" width="55">
                    </el-table-column>
                    <el-table-column prop="date" label="姓名" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="保养开始时间" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="保养完成时间" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="保养用时" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="内容或步骤" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div> -->

            <!-- <div class="box_head1 width_100 mg_top_20">保养部位</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" max-height="250" border>
                    <el-table-column type="index" label="序号" width="55">
                    </el-table-column>
                    <el-table-column prop="date" label="保养部位" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="保养标准" width="">
                    </el-table-column>
                    <el-table-column label="正常" width="">
                        <template slot-scope="scope">
                            <el-radio v-model="radio" label="1">已保养</el-radio>
                            <el-radio v-model="radio" label="2">未保养</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="备注" width="">
                    </el-table-column> -->
            <!-- <el-table-column prop="name" label="实际维修工时" width="">
                    </el-table-column> -->
            <!-- <el-table-column prop="name" label="内容或步骤" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                            </el-button>
                        </template>
                    </el-table-column> -->
            <!-- </el-table>
            </div> -->

            <div class="box_head1 width_100 ">更换备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30 height_370">
                <!-- <div class="flex align_center mg_top_20">
                    <el-select v-model="value" placeholder="请选择仓库" class="width_150">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;">
                        选择备件</el-button>
                    <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;">
                        申购备件</el-button>
                </div> -->
                <el-table :data="sparePartList" style="width: 100%" class="mg_top_20" height="100%">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="unit" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="applyQuan" label="领用数量" width="">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价" width="">
                    </el-table-column>
                    <el-table-column prop="totalPrice" label="总价" width="">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="">
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex mg_top_20 mg_bottom_20">
                <!-- <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div> -->
                <el-button type="primary" class="width_140" @click="colsePage">关闭</el-button>
            </div>
        </div>

        <el-dialog title="暂离时间列表" :visible.sync="dialogTableVisible" top='20vh' width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">暂离时间列表</div> -->
            <div class="flex flex_column height_370 pdb_20 mg_top_10">
                <div class="element">
                    <el-row>
                        <el-col :span="12">暂离结束时间</el-col>
                        <el-col :span="12">暂离开始时间</el-col>
                    </el-row>
                    <el-row class="myRow" v-for="(item,index) in pauseTimeList.continueTime" :key="index"
                        >
                        <el-col :span="12">{{item}}</el-col>
                        <el-col :span="12">{{pauseTimeList.pauseTime[index]}}</el-col>
                    </el-row>
                </div>
            </div>
        </el-dialog>
    </div>
    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        getMaintainExecGroup,
        getMaintainCategory,
        maintainOrderStatusQuery,
        maintainOrderExecGradeQuery,
        getMaintainUrgenLevel,
        maintainOrderQuery,
        maintainOrderExecLevelQuery,
        // maintainOrderUpdate,
        maintainExecPersonQuery,

        maintainOrderSparePartQuery,
        // maintainOrderSparePartDelete,
        // maintainOrderSparePartUpdate,
        // maintainOrderSparePartInsert
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio: '1',
                formInline: {},
                formLabelWidth: '110px',
                tableData: [],
                detailID: '',
                sparePartList: [],
                maintainExecGroup: [],
                maintainUrgenLevel: [],
                planStatusAr: [],
                maintainCategory: [],
                loopUnitQuery: [],
                workOrderStatus: [],
                maintenanceLevel: [],
                maintainLevel: [],
                maintenancePeople: [],
                userID: '',
                readonly: true,
                dialogTableVisible: false,
                pauseTimeList: {}
            }
        },
        mounted() {
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.maintainOrderQuery();
            }
            // if (localStorage.getItem('permission') != null) {
            //     // console.log(JSON.parse(localStorage.getItem('permission')));
            //     this.permission = JSON.parse(localStorage.getItem('permission'))
            // }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
                this.userName = userInfo.userName;
            }
            this.getMaintainExecGroup();
            this.getMaintainUrgenLevel();
            this.getMaintainCategory();
            this.maintainOrderStatusQuery();
            this.maintainOrderExecGradeQuery();
            this.maintainOrderExecLevelQuery();
            // this.maintainExecPersonQuery();
        },
        methods: {
            async maintainOrderQuery() {
                var param = {
                    ID: this.detailID
                }
                const selectRes = await maintainOrderQuery(param);
                this.formInline = selectRes.rows[0];
                // if (this.formInline.execPerson) {
                //     this.formInline.execPerson = this.formInline.execPerson.split(',')
                // }
                // console.log(JSON.parse(this.formInline.pauseTimeList))
                this.pauseTimeList = JSON.parse(this.formInline.pauseTimeList)
                this.maintainOrderSparePartQuery();
            },
            // 备件
            async maintainOrderSparePartQuery() {
                var param = {
                    orderID: this.formInline.orderID
                }
                const selectRes = await maintainOrderSparePartQuery(param);
                this.sparePartList = selectRes.rows;
            },
            // 工单状态
            async maintainOrderStatusQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderStatusQuery(param);
                this.workOrderStatus = selectRes.data
            },
            // 保养等级
            async maintainOrderExecGradeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderExecGradeQuery(param);
                this.maintenanceLevel = selectRes.data
            },
            // 保养组
            async getMaintainExecGroup() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainExecGroup(param);
                this.maintainExecGroup = selectRes.data;
            },
            // 紧急程度
            async getMaintainUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainUrgenLevel(param);
                this.maintainUrgenLevel = selectRes.data;
            },
            // 保养等级
            async maintainOrderExecLevelQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderExecLevelQuery(param);
                this.maintainLevel = selectRes.data
            },
            // 保养类型
            async getMaintainCategory() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainCategory(param);
                this.maintainCategory = selectRes.data
            },
            // 保养人员
            async maintainExecPersonQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainExecPersonQuery(param);
                this.maintenancePeople = selectRes.data
            },
            showPauseTimeList() {
                this.dialogTableVisible = true;
            },
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            }
        }

    }
</script>
<style scoped>
    /* .details_box {
        width: 70%;
    }

    .details_box .el-form {
        width: 100%;
    } */

    /* .textarea .el-form-item__content {
        width: 84%
    } */
    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 100%;
        margin-top: 2px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 12px;
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    .element .el-row:first-child {
        background-color: #E2F0FE;
        border-top: 1px #E6E6E6 solid;
    }

    .element .el-row {
        border-bottom: 1px #E6E6E6 solid;
    border-left: 1px #E6E6E6 solid;
        /* color: #FFFFFF; */
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        border-right: 1px #E6E6E6 solid;
        padding: 10px 0px;
    }

    /* .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;
        color:#73f3ff;
    } */
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        /* // background-color: #FFFFFF;
        // border: none; */
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }

    ::v-deep .el-form-item__label {
        line-height: 24px;
    }
</style>