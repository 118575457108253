// 权限管理
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF flex fn_size14 height_44">
            <div v-for="item in tabArray" :key="item.id" class="tab" :class="tabIndex==item.id?'tab_active':''"
                @click="changeTab(item.id)">{{item.label}}</div>
        </div>
        <!-- 用户管理--开始  tabIndex==0 -->

        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30" v-if="tabIndex==0" style="height:92%">
            <div class="pdt_20 flex justify_between">
                <div class="flex">
                    <el-select v-model="departmentArrayValue" placeholder="请选择部门" class="mg_right_10 width_170" multiple
                        collapse-tags clearable>
                        <el-option v-for="(item,index) in departmentArray" :key="index" :label="item.departmentName"
                            :value="item.departmentID">
                        </el-option>
                    </el-select>
                    <el-button type="primary" size="medium" class="mg_left_10" @click="queryUser">查询</el-button>
                    <div style="line-height:20px">
                <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #009BFD;border: none;">导出</el-button></div>
                </div>
               
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="mg_left_10 select_btn"
                    style="background: #03CABE;border:none;" @click="add">增加</el-button>
            </div>
            <el-table :data="queryList" class="mg_top_20" height="82%" 
            @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                <el-table-column type="index" label="序号" width="55"></el-table-column>
                <el-table-column prop="userName" label="用户名" width="">
                </el-table-column>
                <el-table-column prop="userAccount" label="用户账号" width="">
                </el-table-column>
                <el-table-column prop="workNumber" label="用户工号" width="">
                </el-table-column>
                <el-table-column label="性别" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gender == 0">男</span>
                        <span v-if="scope.row.gender == 1">女</span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="用户电话" width="">
                </el-table-column>
                <el-table-column prop="email" label="邮箱">
                </el-table-column>
                <el-table-column prop="departmentName" label="用户部门" width="">
                </el-table-column>
                <el-table-column prop="jobName" label="职位" width="">
                </el-table-column>
                <el-table-column prop="createDate" label="创建时间" width="150" :formatter="formatDate">
                </el-table-column>
                <el-table-column label="是否离职" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.leaveFlag == 0">未离职</span>
                        <span v-if="scope.row.leaveFlag == 1">已离职</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lockFlag" label="是否锁定" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.lockFlag == 0">未锁定</span>
                        <span v-if="scope.row.lockFlag == 1">已锁定</span>
                    </template>
                </el-table-column>
                 <el-table-column label="是否管理员" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isAdmin == true">是</span>
                        <span v-if="scope.row.isAdmin == false">否</span>
                    </template>
                </el-table-column>
                 <el-table-column label="是否工人" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isWorker == true">是</span>
                        <span v-if="scope.row.isWorker == false">否</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="220">
                    <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0" @click="gotoModify(scope.row)">
                            编辑
                            <!-- <span style="border-bottom: 1px solid #0384D5;">追踪</span> -->
                        </el-button>
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0" @click="look(scope.row)">查看
                        </el-button>
                        <el-button type="text" class="color_999999 width_40 pdt_0 pdb_0" v-if="scope.row.lockFlag==1"
                            disabled>已锁定
                        </el-button>
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0" v-else
                            @click="lock(scope.row.userID)">锁定
                        </el-button>
                        <!-- lockFlag -->
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0"
                            @click="delTableData(scope.row.userID)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="flex justify_end  ">
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <!-- 新增用户信息弹出框 -->
        <el-dialog title="新增用户信息" :visible.sync="dialogVisible" top='18vh' width="47%">
            <div class="">
                <!-- pdl30 pdr30 -->
                <el-form :model="addFrom" ref="userInfoAdd" :label-width="labelWidth" :rules="userRules"
                    class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户名：" prop="userName">
                        <el-input v-model="addFrom.userName" placeholder="用户名" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="性别：" prop="gender">
                        <el-select v-model="addFrom.gender" placeholder="性别" class="width_170 ">
                            <el-option v-for="item in sex" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位：" prop="jobTitle">
                        <el-select v-model="addFrom.jobTitle" placeholder="职位" class="width_170 ">
                            <el-option v-for="item in jobArray" :key="item.jobID" :label="item.jobName"
                                :value="item.jobID">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="工号：" prop="workNumber">
                        <el-input v-model="addFrom.workNumber" placeholder="工号" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="账号：" prop="userAccount">
                        <el-input v-model="addFrom.userAccount" placeholder="账号" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="userPassword">
                        <el-input v-model="addFrom.userPassword" placeholder="密码" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="addFrom.email" placeholder="邮箱" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="addFrom.phone" placeholder="电话" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="密码强度">
                        <password-strength v-model="addFrom.userPassword" class="width_170" style="padding-top:10px"></password-strength>
                    </el-form-item>
                    <el-form-item label="部门：" prop="departmentID">
                        <el-select v-model="addFrom.departmentID" placeholder="部门ID" class="width_170 ">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否管理员：">
                        <el-radio-group v-model="addFrom.isAdmin" class="width_170 text_align_left">
                            <el-radio :label= true>是</el-radio>
                            <el-radio :label= false>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否工人：">
                        <el-radio-group v-model="addFrom.isWorker" class="width_170 text_align_left" >
                            <el-radio :label= true>是</el-radio>
                            <el-radio :label= false>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                     <el-form-item label="是否离职：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="addFrom.leaveFlag" class="width_170 text_align_left">
                            <el-radio label=1>是</el-radio>
                            <el-radio label=0>否</el-radio>
                        </el-radio-group>
                    </el-form-item>                   
                    <el-form-item label="是否锁定：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="addFrom.lockFlag" class="width_170 text_align_left"
                            @change="changeLockDateReadOnly">
                            <el-radio label=1>是</el-radio>
                            <el-radio label=0>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="锁定日期：" v-if="addFrom.lockFlag==1">
                        <el-date-picker v-model="addFrom.lockDate" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="width_170" :disabled="lockDateReadOnly">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="addFrom" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="addFrom.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="trueadd">添加</el-button>
            </div>
        </el-dialog>
        <!--   用户信息--编辑弹出框 -->
        <el-dialog title="编辑用户信息" :visible.sync="dialog2Visible" top='18vh' width="47%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑用户信息</div> -->
            <div class="flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="updateForm" ref="userInfoUpdate" :label-width="labelWidth" :rules="userRules"
                    class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户名：" prop="userName">
                        <el-input v-model="updateForm.userName" placeholder="用户名" class="width_170 " disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="性别：" prop="gender">
                        <el-select v-model="updateForm.gender" placeholder="性别" class="width_170 ">
                            <el-option v-for="item in sex" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位：" prop="jobTitle">
                        <el-select v-model="updateForm.jobTitle" placeholder="职位" class="width_170">
                            <el-option v-for="item in jobArray" :key="item.jobID" :label="item.jobName"
                                :value="item.jobID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工号：" prop="workNumber">
                        <el-input v-model="updateForm.workNumber" placeholder="请输入" class="width_170 " disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="账号：" prop="userAccount">
                        <el-input v-model="updateForm.userAccount " placeholder="账号" class="width_170 " disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="userPassword">
                        <el-input v-model="updateForm.userPassword" placeholder="密码" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="updateForm.email" placeholder="邮箱" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="updateForm.phone" placeholder="电话" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="部门：" prop="departmentID">
                        <el-select v-model="updateForm.departmentID" placeholder="部门ID" class="width_170 ">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否管理员：">
                        <el-radio-group v-model="updateForm.isAdmin" class="width_170 text_align_left">
                            <el-radio :label= true>是</el-radio>
                            <el-radio :label= false>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否工人：">
                        <el-radio-group v-model="updateForm.isWorker" class="width_170 text_align_left" >
                            <el-radio :label= true>是</el-radio>
                            <el-radio :label= false>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否离职：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="updateForm.leaveFlag" class="width_170 text_align_left">
                            <el-radio :label='1'>是</el-radio>
                            <el-radio :label='0'>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否锁定：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="updateForm.lockFlag" class=" text_align_left"
                            @change="changeLockDateReadOnly">
                            <el-radio :label='1'>是</el-radio>
                            <el-radio :label='0'>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <el-form :model="updateForm" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="updateForm.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
                <!-- <div>
                    <div class="border_bottom_1_CCCCCC height_40 mg_bottom_20">
                        <div class="fn_size18 color_000000 flex justify_center font_bold">用户-角色配置</div>
                    </div>
                    <el-col :span="10" class="shuttleBox">
                        <el-table :data="userNotHaveRoleArray" border ref="selection" @selection-change="checkAll"
                            :header-cell-style="tableHeaderColor" height="100%" class="width_100"
                            style="overflow-y: auto;">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column prop="name" label="系统所有角色">
                                <template slot-scope="scope">
                                    <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                        style="border: 1px solid #E6E6E6;">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="4">
                        <div class="flex flex_column justify_center align_center shuttleBox">
                            <div @click="addUserRole()" class="mg_bottom_10">
                                <el-button :disabled="userNotHaveRoleArray.length?false:true" :size="buttonSize"
                                    style="background: #EBEBEB;border:none" type="primary">
                                    <i class="">
                                        <img src="../../assets/images/systemConfiguration/user4.png">
                                    </i>
                                </el-button>
                            </div>
                            <div class="spacing" @click="removeUserRole()">
                                <el-button :disabled="userHaveRoleArray.length?false:true" :size="buttonSize"
                                    style="background: #EBEBEB;border:none" type="primary">
                                    <i class="">
                                        <img src="../../assets/images/systemConfiguration/user5.png">
                                    </i>
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10" class="shuttleBox">
                        <el-table :data="userHaveRoleArray" border ref="selection" @selection-change="checkRightAll"
                            :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                            style="overflow-y: auto;">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column prop="name" label="当前用户角色">
                                <template slot-scope="scope">
                                    <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                        style="border: 1px solid #E6E6E6;">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </div> -->
            </div>

            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="trueModify">确定</el-button>
            </div>
        </el-dialog>

        <!-- 查看用户信息弹出框 -->
        <el-dialog title="用户信息详情" :visible.sync="dialog3Visible" top='18vh' width="47%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">用户信息详情</div> -->
            <div class="flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="detailForm" :label-width="labelWidth" :rules="rulesFrom"
                    class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户名：">
                        <el-input v-model="detailForm.userName" placeholder="用户名" class="width_170 " disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="性别：">
                        <!-- <el-select v-model="detailForm.gender" placeholder="性别" class="width_170 " disabled>
                            <el-option v-for="item in sex" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select> -->
                        <!--  <el-input v-model="detailForm.gender" placeholder="性别" class="width_170 " :readonly="true"></el-input> -->
                        <el-input value="男" v-if="detailForm.gender == 0" placeholder="性别" class="width_170 "
                            disabled></el-input>
                        <el-input value="女" v-if="detailForm.gender == 1" placeholder="性别" class="width_170 "
                            disabled></el-input>
                    </el-form-item>
                    <el-form-item label="职位：">
                        <el-input v-model="detailForm.jobName" placeholder="职位" class="width_170 " disabled>
                        </el-input>
                        <!-- <el-select v-model="detailForm.jobTitle" placeholder="职位" class="width_170 " disabled>
                            <el-option v-for="item in jobArray" :key="item.jobID" :label="item.jobName"
                                :value="item.jobID">
                            </el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item label="工号：">
                        <el-input v-model="detailForm.workNumber" placeholder="工号" class="width_170 " disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="账号：">
                        <el-input v-model="detailForm.userAccount " placeholder="账号" class="width_170 "
                            disabled></el-input>
                    </el-form-item>
                    <el-form-item label="密码：">
                        <el-input v-model="detailForm.userPassword" placeholder="密码" class="width_170 "
                            disabled></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="detailForm.email" placeholder="邮箱" class="width_170 " disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="detailForm.phone" placeholder="电话" class="width_170 " disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="部门：">
                        <el-input v-model="detailForm.departmentName" placeholder="部门" class="width_170 "
                             disabled>
                        </el-input>
                        <!-- <el-select v-model="detailForm.departmentID" placeholder="职位" class="width_170 " disabled>
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item label="是否管理员：">
                        <el-radio-group v-model="detailForm.isAdmin" class="width_170 text_align_left" disabled>
                            <el-radio :label= true>是</el-radio>
                            <el-radio :label= false>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否工人：">
                        <el-radio-group v-model="detailForm.isWorker" class="width_170 text_align_left" disabled>
                            <el-radio :label= true>是</el-radio>
                            <el-radio :label= false>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否离职：">
                        <el-radio-group v-model="detailForm.leaveFlag" class="width_170 text_align_left" disabled>
                            <el-radio :label='1'>是</el-radio>
                            <el-radio :label='0'>否</el-radio>
                        </el-radio-group>
                        <!-- <el-input value="未离职" v-if="detailForm.leaveFlag == 0" placeholder="是否在职" class="width_170 "
                            :readonly="true"></el-input>
                        <el-input value="已离职" v-if="detailForm.leaveFlag == 1" placeholder="是否在职" class="width_170 "
                            :readonly="true"></el-input> -->
                    </el-form-item>
                    <el-form-item label="是否锁定：">
                        <el-radio-group v-model="detailForm.lockFlag" class="width_170 text_align_left" disabled>
                            <el-radio :label='1'>是</el-radio>
                            <el-radio :label='0'>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="锁定日期：" v-if="detailForm.lockFlag == 1">
                        <el-date-picker v-model="detailForm.lockDate" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="width_170" disabled>
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="detailForm" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="detailForm.description" resize="none"
                            placeholder="请输入介绍信息" class="" disabled></el-input>
                    </el-form-item>
                </el-form>
                <!-- <div>
                    <div class="border_bottom_1_CCCCCC height_40 mg_bottom_20">
                        <div class="fn_size18 color_000000 flex justify_center font_bold">用户-角色配置</div>
                    </div>
                    <el-col :span="10" class="shuttleBox">
                        <el-table :data="userNotHaveRoleArray" border ref="selection"
                            :header-cell-style="tableHeaderColor" height="100%" class="width_100"
                            style="overflow-y: auto;">
                            <el-table-column prop="name" label="系统所有角色">
                                <template slot-scope="scope">
                                    <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                        style="border: 1px solid #E6E6E6;">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="4">
                        <div class="flex flex_column justify_center align_center shuttleBox">
                            <div class="mg_bottom_10">
                                <el-button :disabled="userNotHaveRoleArray.length?false:true" :size="buttonSize"
                                    style="background: #EBEBEB;border:none" type="primary">
                                    <i class="">
                                        <img src="../../assets/images/systemConfiguration/user4.png">
                                    </i>
                                </el-button>
                            </div>
                            <div class="spacing">
                                <el-button :disabled="userHaveRoleArray.length?false:true" :size="buttonSize"
                                    style="background: #EBEBEB;border:none" type="primary">
                                    <i class="">
                                        <img src="../../assets/images/systemConfiguration/user5.png">
                                    </i>
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10" class="shuttleBox">
                        <el-table :data="userHaveRoleArray" border ref="selection" :header-cell-style="tableHeaderColor"
                            height="100%" class=" width_100" style="overflow-y: auto;">
                            <el-table-column prop="name" label="当前用户角色">
                                <template slot-scope="scope">
                                    <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                        style="border: 1px solid #E6E6E6;">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </div> -->
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="closedialog3">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 用户管理--结束 -->


        <!-- 用户组--开始 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" style="height:92%;" v-if="tabIndex==1">
            <!-- 左侧 用户组管理菜单 -->
            <div class=" width_450 height_100">
                <el-table ref="roleGroupRef" :header-cell-style="{textAlign: 'left'}"
                    :cell-style="{ textAlign: 'left' }" :data="roleGroupArray" style="width: 100%" height="95%"
                    highlight-current-row @current-change="showDetails">
                    <el-table-column label="用户组管理" width="">
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.userGroupName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="130">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="250" trigger="hover">
                                    <div>创建时间：{{tools.formatDate(scope.row.userGroupCreateDate, 'yyyy-MM-dd hh:mm:ss')}}
                                    </div>
                                    <div>描述：{{scope.row.userGroupDesc}}</div>
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="roleGroupEdit(scope.row)">
                                <img src="../../assets/images/systemConfiguration/user14.png" class="mg_right_16"
                                    @click="roleGroupDel(scope.row.userGroupID)">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 添加用户组按钮 -->
                <el-button type="primary" class="width_100 height_40 flex justify_center align_center"
                    @click="addRoleGroup">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加用户组</span>
                </el-button>
            </div>
            <!-- 右侧 用户组配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 用户组配置 标题 -->
                <div class="fn_size20 width_826 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="userGroupDetailsShowControl(true)"></el-button>
                    <span v-if="userGroupDetailsShow == 1" class="flex justify_center align_center"
                        style="font-weight: bold;">当前用户组与角色的关联关系</span>
                    <span v-if="userGroupDetailsShow == 2" class="flex justify_center align_center"
                        style="font-weight: bold;">当前用户组与用户的关联关系</span>
                    <span v-if="userGroupDetailsShow == 3" class="flex justify_center align_center"
                        style="font-weight: bold;"> 当前用户组与组织的关联关系</span>
                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="userGroupDetailsShowControl(false)">
                    </el-button>
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div v-if="userGroupDetailsShow == 1">
                        <el-col :span="10" class="height_500">
                            <el-table :data="departmentAndMeterRoleData" border ref="selection"
                                @selection-change="checkAll" :header-cell-style="tableHeaderColor" class=" width_100"
                                height="100%">
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_500">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="height_500">
                            <el-table :data="otherMeterRoleData" border @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_500 width_100" height="100%">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="name" label="当前用户组的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="userGroupDetailsShow == 2">
                        <el-col :span="10" class="height_500">
                            <el-table :data="departmentAndMeterUserData" border @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class=" width_100" height="100%">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="name" label="系统所有用户">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_500">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="height_500">
                            <el-table :data="otherMeterUserData" border @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class=" width_100" height="100%">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="name" label="当前用户组的用户">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="userGroupDetailsShow == 3">
                        <el-col :span="10" class="height_500">
                            <el-table :data="departmentAndMeterGroupData" border @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class=" width_100" height="100%">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="meter_name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_500">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="height_500">
                            <el-table :data="otherMeterGroupData" border @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class=" width_100" height="100%">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="meter_name" label="当前用户组的组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>

                <!-- 添加按钮 -->
                <!-- <el-button type="primary" class="width_110 height_36 mg_top_30 flex justify_center align_center">添加
                </el-button> -->
            </div>
        </div>

        <!-- 用户组--添加角色组弹框 roleGroup -->
        <el-dialog title="添加用户组" :visible.sync="dialogVisibleRoleGroupAdd" top='25vh' width="40%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加用户组</div> -->
            <div class="pdr20">
                <!-- pdl30 pdr30 -->
                <el-form :model="addRoleGroupForm" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="用户组名：" prop="userGroupName">
                        <el-input v-model="addRoleGroupForm.userGroupName" placeholder="请输入用户组名" class="width_170 ">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-form :model="addRoleGroupForm" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="userGroupDescription">
                        <el-input type="textarea" :rows="4" v-model="addRoleGroupForm.userGroupDescription"
                            resize="none" placeholder="请输入用户组描述" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 用户组-编辑 -->
        <el-dialog title="用户组信息配置" :visible.sync="dialogVisibleRoleGroupEdit" top='5vh' width="45%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">用户组信息配置</div> -->
            <div class="pdl20 pdr20 flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="updateRoleGroupForm" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户组名：" prop="userGroupName">
                        <el-input v-model="updateRoleGroupForm.userGroupName" placeholder="请输入用户组名" class="width_170 ">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-form :model="updateRoleGroupForm" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="description">
                        <el-input type="textarea" :rows="4" v-model="updateRoleGroupForm.description" resize="none"
                            placeholder="请输入用户组描述" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- 用户组-用户组配置--穿梭框 -->
                <div class="fn_size20 flex justify_between align_center mg_top_20 pdb_10 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="userGroupDetailsShowControl(true)">
                    </el-button>
                    <div v-if="userGroupDetailsShow == 1" class="fn_size18 color_000000 flex justify_center font_bold">
                        角色-用户组配置</div>
                    <div v-if="userGroupDetailsShow == 2" class="fn_size18 color_000000 flex justify_center font_bold">
                        用户-用户组配置</div>
                    <div v-if="userGroupDetailsShow == 3" class="fn_size18 color_000000 flex justify_center font_bold">
                        组织-用户组配置</div>
                    <el-button icon="el-icon-arrow-right el-icon--right"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="userGroupDetailsShowControl(false)">
                    </el-button>
                </div>
                <div class="mg_top_20">
                    <div v-if="userGroupDetailsShow == 1">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterRoleData" border ref="selection"
                                @selection-change="selectBandRoleID" :header-cell-style="tableHeaderColor" height="100%"
                                class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandRoleID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary" @click="bandRole">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandRoleID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary" @click="unbandRole">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterRoleData" border ref="selection"
                                @selection-change="selectUnbandRoleID" :header-cell-style="tableHeaderColor"
                                height="100%" class=" width_100" style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前用户组的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="userGroupDetailsShow == 2">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterUserData" border ref="selection"
                                @selection-change="selectBandUserID" :header-cell-style="tableHeaderColor" height="100%"
                                class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有用户">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandUserID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary" @click="bandUser">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandUserID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary" @click="unbandUser">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterUserData" border ref="selection"
                                @selection-change="selectUnbandUserID" :header-cell-style="tableHeaderColor"
                                height="100%" class=" width_100" style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前用户组的用户">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="userGroupDetailsShow == 3">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterGroupData" border ref="selection"
                                @selection-change="selectBandOrganizationID" :header-cell-style="tableHeaderColor"
                                height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandOrganizationID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="bandOrganization">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandOrganizationID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="unbandOrganization">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterGroupData" border ref="selection"
                                @selection-change="selectUnbandOrganizationID" :header-cell-style="tableHeaderColor"
                                height="100%" class=" width_100" style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前用户组的组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>

                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="trueEdit">更新</el-button>
            </div>
        </el-dialog>
        <!-- 用户组--结束 -->

        <!-- 角色管理--开始   tabIndex==2-->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" style="height:92%;" v-if="tabIndex==2">
            <!-- 左侧 角色管理菜单 -->
            <div class=" width_450 height_100">
                <!-- 角色列表 -->
                <!-- linxinsen -->
                <el-table ref="roleListTable" :header-cell-style="{textAlign: 'left'}"
                    :cell-style="{ textAlign: 'left' }" :data="newRoleList" style="width: 100%" height="95%"
                    highlight-current-row @current-change="handleCurrentChangeDataRole">
                    <el-table-column label="角色管理" width="">
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.copyName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="130">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="200" trigger="hover">
                                    <div>创建信息：</div>{{tools.formatDate(scope.row.createDate, 'yyyy-MM-dd hh:mm:ss')}}
                                    <div>描述：</div>{{scope.row.description}}
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="roleEdit(scope.row)">
                                <img src="../../assets/images/systemConfiguration/user14.png" class=""
                                    @click="roleDel(scope.row.roleID)"> <!-- linxinsen -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 添加角色按钮 -->
                <el-button type="primary" class="width_100 height_40 flex justify_center align_center" @click="addRole">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加角色</span>
                </el-button>
            </div>
            <!-- 右侧 角色配置 穿梭框 -->

            <div class="pdt_10 width_100 mg_left_30 flex flex_column justify_between align_center">
                <div class="flex justify_end width_100">
                    <el-button type="primary" @click="editPeizhiRule" v-if="readonlyRole">
                        <!--  -->
                        <i class="el-icon-edit"></i>
                        <span>编辑</span>
                    </el-button>
                </div>
                <!-- 角色配置 标题 -->
                <div class="fn_size20 width_826 height_100 flex flex_column ">
                    <div class="fn_size20 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                        <el-button icon="el-icon-arrow-left" @click="roleTitle"
                            class="height_36 width_36 check_btn flex justify_center align_center"></el-button>
                        <!-- <span v-if="roleTitleStatus"
                            class="flex justify_center align_center font_bold">当前角色已配置的动作权限</span> -->
                        <span class="flex justify_center align_center font_bold">当前角色已配置的页面权限</span>
                        <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                            icon="el-icon-arrow-right el-icon--right" @click="roleTitle"></el-button>
                    </div>
                    <div style="height: 540px;overflow-y: auto;">
                        <!-- <div v-if="roleTitleStatus">
                            <div v-for="(item,index) in permissionByUserRole" :key="index">
                                <el-form :model="item.permissionContent">
                                    <el-form-item :label="item.permissionName" label-width="100px" class="pdr30">
                                        <el-checkbox label="" v-model="item.permissionContent.AddAction"
                                            :disabled="readonlyRole">增加
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.permissionContent.DeleteAction"
                                            :disabled="readonlyRole">删除
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.permissionContent.EditAction"
                                            :disabled="readonlyRole">编辑
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.permissionContent.QueryAction"
                                            :disabled="readonlyRole">查询
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.permissionContent.ExportAction"
                                            :disabled="readonlyRole">导出
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.permissionContent.ImportAction"
                                            :disabled="readonlyRole">导入
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.permissionContent.ApprovalAction"
                                            :disabled="readonlyRole"> 批准
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.permissionContent.ArchivedAction"
                                            :disabled="readonlyRole">归档
                                        </el-checkbox>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="flex justify_end width_100" v-if="!readonlyRole"> -->
                                <!-- <el-button type="warning" @click="unBandingPermissionAndRole">解除绑定</el-button> -->
                                <!-- <div class="mg_left_20">
                                    <el-button type="primary" @click="bandingPermissionAndRoleCancel">取消
                                    </el-button>
                                </div>
                                <div class="mg_left_20">
                                    <el-button type="primary" @click="bandingPermissionAndRole">确定
                                    </el-button>
                                </div>

                            </div> -->
                        <!-- </div> -->

                        <div >
                            <div v-for="(item,index) in pageByUserRole" :key="index">
                                <el-form :model="item.pageContent">
                                    <el-form-item :label="item.pageName" label-width="100px" class="pdr30">
                                        <el-checkbox label="" v-model="item.pageContent.QueryButtonVisibility"
                                            :disabled="readonlyRole">查询按钮的可见性
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.pageContent.AddButtonVisibility"
                                            :disabled="readonlyRole">增加按钮的可见性
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.pageContent.EditButtonVisibility"
                                            :disabled="readonlyRole">编辑按钮的可见性
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.pageContent.DeleteButtonVisibility"
                                            :disabled="readonlyRole">删除按钮的可见性
                                        </el-checkbox>
                                        <el-checkbox label="" v-model="item.pageContent.MenuButtonVisibility"
                                            :disabled="readonlyRole">菜单按钮的可见性
                                        </el-checkbox>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="flex justify_end width_100" v-if="!readonlyRole">
                                <!-- <el-button type="warning" @click="unBandingPageAndRole">解除绑定</el-button> -->
                                <div class="mg_left_20">
                                    <el-button type="primary" @click="bandingPageAndRoleCancel">取消
                                    </el-button>
                                </div>
                                <div class="mg_left_20">
                                    <el-button type="primary" class="" @click="bandingPageAndRole">确定
                                    </el-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- 添加按钮 -->
                <!-- <el-button type="primary" class="width_110 height_36 mg_top_30 flex justify_center align_center">添加
                </el-button> -->
            </div>
        </div>

        <!-- 角色管理-弹窗 -->
        <!-- 角色管理--添加角色弹窗-linxinsen -->
        <el-dialog title="添加角色" :visible.sync="dialogVisibleRoleAdd" top='22vh' width="40%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加角色</div> -->
            <div class="pdr20">
                <!-- pdl30 pdr30 -->
                <el-form :model="addRoleFrom" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30 " ref="addRoleFrom">
                    <el-form-item label="角色名：" prop="roleName" style="text-align:left">
                        <el-input v-model="addRoleFrom.roleName" placeholder="请输入" class="width_170 " ></el-input>
                    </el-form-item>
                <!-- </el-form>
                <el-form :model="addRoleFrom" :label-width="labelWidth2" :rules="rulesFrom" class="" ref="addRoleFrom"> -->
                    <el-form-item label="描述：" prop="description">
                        <el-input type="textarea" :rows="4" v-model="addRoleFrom.description" resize="none"
                            placeholder="请输入描述" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAddRole">添加</el-button>
            </div>
        </el-dialog>
        <!-- 角色管理--编辑 -->
        <el-dialog title="编辑角色信息" :visible.sync="dialogVisibleRoleEdit" top='22vh' width="45%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑角色信息</div> -->
            <div class="pdl20 pdr20 flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="editRoleFrom" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  " ref="editRoleFrom">
                    <!-- justify_around -->
                    <el-form-item label="角色名：" prop="roleName"  style="text-align:left">
                        <el-input v-model="editRoleFrom.roleName" placeholder="请输入" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="描述：" prop="description">
                        <el-input type="textarea" :rows="4" v-model="editRoleFrom.description" resize="none"
                            placeholder="请输入描述" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- 角色-角色组配置--穿梭框 -->
                <!-- <div class="fn_size20 flex justify_between align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-left" @click="roleStause=!roleStause"></el-button>
                    <div v-if="roleStause" class="fn_size18 color_000000 flex justify_center font_bold">角色-权限配置</div>
                    <div v-if="!roleStause" class="fn_size18 color_000000 flex justify_center font_bold">角色-页面配置</div>
                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleStause=!roleStause"></el-button>
                </div> -->


            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onEditRole">确定</el-button>
            </div>
        </el-dialog>
        <!-- 角色管理--结束 -->

        <!-- -------------------组织 --开始 --------------------->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" style="height:92%;" v-if="tabIndex==3">
            <!-- 左侧 组织管理菜单 -->
            <div class=" width_450 height_100">
                <el-table ref="organizationArrayRef" :header-cell-style="{textAlign: 'left'}"
                    :cell-style="{ textAlign: 'left' }" :data="organizationArray" style="width: 100%" height="100%"
                    highlight-current-row @current-change="showOrganizationDetails">
                    <el-table-column label="组织管理" width="">
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="130">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="250" trigger="hover">
                                    <div>创建时间：{{tools.formatDate(scope.row.createDate, 'yyyy-MM-dd hh:mm:ss')}}</div>
                                    <div>描述：{{scope.row.description}}</div>
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="organizationEdit(scope.row)">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 右侧 组织配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 组织配置 标题 -->
                <div class="fn_size20 width_826 flex justify_center pdb_20 border_bottom_1_CCCCCC">
                    <span class="flex justify_center align_center" style="font-weight: bold;">当前组织与角色的关联关系
                    </span>
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div>
                        <el-col :span="10" class="height_500">
                            <el-table :data="departmentAndMeterRoleDataByOrganizationID" border ref="selection"
                                :header-cell-style="tableHeaderColor" class=" width_100" height="100%">
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_500">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="height_500">
                            <el-table :data="otherMeterRoleDataByOrganization" border ref="selection"
                                :header-cell-style="tableHeaderColor" class="width_100" height="100%">
                                <el-table-column prop="name" label="当前组织角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>

        <!-- 组织管理-弹窗 -->
        <!-- 组织管理--编辑--弹窗-->
        <el-dialog :title="'角色-组织('+titleNameDialog+')配置'" :visible.sync="dialogVisibleOrganizationEdit" top='20vh' width="45%">
            <div class="pdl20 pdr20 flex flex_column">
                <!-- --穿梭框 -->
                <!--                 <div class="fn_size20 flex justify_center align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <div class="fn_size18 color_000000 flex justify_center font_bold">角色-组织配置</div>
                </div> -->
                <div class="mg_top_20">
                    <div>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterRoleDataByOrganizationID" border ref="selection"
                                @selection-change="selectBandRoleID_Organization" :header-cell-style="tableHeaderColor"
                                height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandRoleID_Organization?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="bandRole_Organization">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandRoleID_Organization?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="unbandRole_Organization">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterRoleDataByOrganization" border ref="selection"
                                @selection-change="selectUnbandRoleID_Organization"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前组织已关联的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="dialogVisibleOrganizationEdit = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-------------------- 组织 --结束 -->

        <!-- 部门-开始 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" style="height:92%;" v-if="tabIndex==4">
            <!-- 左侧 部门管理菜单 -->
            <div class=" width_450 height_100">
                <!-- 部门列表 -->
                <el-table ref="departmentArrayRef" :header-cell-style="{textAlign: 'left'}"
                    :cell-style="{ textAlign: 'left' }" :data="departmentArray" style="width: 100%" height="100%"
                    highlight-current-row @current-change="changeCurrentDepartmentData">
                    <el-table-column label="部门管理" width="">
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.departmentName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="100">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="200" trigger="hover">
                                    <div>创建信息：</div>{{tools.formatDate(scope.row.recordTime, 'yyyy-MM-dd hh:mm:ss')}}
                                    <div>描述：</div>{{scope.row.description}}
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="departmentEdit(scope.row)">
                                <!-- <img src="../../assets/images/systemConfiguration/user14.png" class=""
                                    @click="departmentDel(scope.row.departmentID)"> -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 添加部门按钮 -->
                <!-- <el-button type="primary" class="width_100 height_40 flex justify_center align_center"
                    @click="addDepartment">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加部门</span>
                </el-button> -->
            </div>
            <!-- 右侧 部门配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 部门配置 标题 -->
                <div class="fn_size20 width_826 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="departmentStause=departmentStause"></el-button>
                    <!-- @click="departmentStause=!departmentStause" -->
                    <span v-if="departmentStause" class="flex justify_center align_center"
                        style="font-weight: bold;">当前部门与角色的关联关系</span>
                    <!-- <span v-if="!departmentStause" class="flex justify_center align_center"
                        style="font-weight: bold;">部门职位-组织配置</span> -->
                    <el-button icon="el-icon-arrow-right el-icon--right"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="departmentStause=departmentStause"></el-button>
                    <!-- @click="departmentStause=!departmentStause" -->
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div v-if="departmentStause">
                        <el-col :span="10" class="height_500">
                            <el-table :data="departmentNotHaveRoleList" border :header-cell-style="tableHeaderColor"
                                class="width_100" height="100%">
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_500">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="height_500">
                            <el-table :data="departmentHaveRoleList" border :header-cell-style="tableHeaderColor"
                                class=" width_100" height="100%">
                                <el-table-column prop="name" label="当前部门已关联的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <!-- <div v-if="!departmentStause">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_500 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_500">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_500 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前用户角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div> -->
                </div>

                <!-- 添加按钮 -->
                <!-- <el-button type="primary" class="width_110 height_36 mg_top_30 flex justify_center align_center">添加
                </el-button> -->
            </div>
        </div>
        <!-- 添加部门 -->
        <el-dialog title="添加部门" :visible.sync="dialogVisibleDepartmentAdd" top='25vh' width="40%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加部门</div> -->
            <div class="pdr20">
                <el-form :model="addDepartmentFrom" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="部门名称：" prop="departmentName">
                        <el-input v-model="addDepartmentFrom.departmentName" placeholder="请输入" class="width_170 ">
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item label="部门类别：" :label-width="labelWidth2">
                        <el-select v-model="addDepartmentFrom.departmentType" placeholder="请选择" class="">
                            <el-option v-for="item in departmentArray" :key="item.departmentType" :label="item.departmentType"
                                :value="item.departmentType">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="上级部门：" :label-width="labelWidth2">
                        <el-select v-model="addDepartmentFrom.upDepartmentID" placeholder="请选择" class="">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-form :model="addDepartmentFrom" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="备注：" prop="description">
                        <el-input type="textarea" :rows="4" v-model="addDepartmentFrom.description" resize="none"
                            placeholder="请输入备注信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAddDepartment">添加</el-button>
            </div>
        </el-dialog>
        <!-- 编辑部门 -->
        <el-dialog title="部门信息配置" :visible.sync="dialogVisibleDepartmentEdit" top='3vh' width="45%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">部门信息配置</div> -->
            <div class="pdl20 pdr20 flex flex_column">
                <el-form :model="editDepartmentFrom" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="部门名称：" prop="departmentName">
                        <el-input v-model="editDepartmentFrom.departmentName" placeholder="文档名称" class="width_170 ">
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item label="部门类别：" :label-width="labelWidth2">
                        <el-select v-model="editDepartmentFrom.departmentType" placeholder="请选择" class="">
                            <el-option v-for="item in departmentArray" :key="item.departmentType" :label="item.departmentType"
                                :value="item.departmentType">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="上级部门：" :label-width="labelWidth2">
                        <el-select v-model="editDepartmentFrom.upDepartmentID" placeholder="请选择" class="">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-form :model="editDepartmentFrom" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="备注：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="editDepartmentFrom.description" resize="none"
                            placeholder="请输入备注信息" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- 穿梭框 -->
                <div class="fn_size20 flex justify_between align_center mg_top_20 pdb_10 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="departmentStause=departmentStause"></el-button>
                    <!-- @click="departmentStause=!departmentStause" -->
                    <div v-if="departmentStause" class="fn_size18 color_000000 flex justify_center font_bold">角色-部门配置
                    </div>
                    <!-- <div v-if="!departmentStause" class="fn_size18 color_000000 flex justify_center font_bold">部门职位-组织配置</div> -->
                    <el-button icon="el-icon-arrow-right el-icon--right"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="departmentStause=departmentStause"></el-button>
                    <!-- @click="departmentStause=!departmentStause" -->
                </div>
                <div class="mg_top_20">
                    <div v-if="departmentStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentNotHaveRoleList" border ref="selection"
                                @selection-change="checkLeftDepartmentRole" :header-cell-style="tableHeaderColor"
                                height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!-- <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="leftSelectDepartmentRole.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="bandingDepartmentAndRole">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="rightSelectDepartmentRole.length?false:true"
                                        :size="buttonSize" style="background: #EBEBEB;border:none" type="primary"
                                        @click="unBandingDepartmentAndRole">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentHaveRoleList" border ref="selection"
                                @selection-change="checkRightDepartmentRole" :header-cell-style="tableHeaderColor"
                                height="100%" class=" width_100" style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前部门已关联的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!-- <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onEditDepartment">确定</el-button>
            </div>
        </el-dialog>
        <!-- 部门-结束 -->

        <!-- 职位-开始 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" style="height:92%;" v-if="tabIndex==5">
            <!-- 左侧 职位管理菜单 -->
            <div class=" width_450 height_100">
                <!-- 职位列表 -->
                <el-table ref="jobArrayRef" :header-cell-style="{textAlign: 'left'}" :cell-style="{ textAlign: 'left' }"
                    :data="jobArray" style="width: 100%" height="100%" highlight-current-row
                    @current-change="changeCurrentJobData">
                    <el-table-column label="职位管理" width="">
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.jobName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="100">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="200" trigger="hover">
                                    <div>创建信息：</div>{{tools.formatDate(scope.row.recordTime, 'yyyy-MM-dd hh:mm:ss')}}
                                    <div>描述：</div>{{scope.row.description}}
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="jobEdit(scope.row)">
                                <!-- <img src="../../assets/images/systemConfiguration/user14.png" class=""
                                    @click="jobDel(scope.row.jobID)"> -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 添加职位按钮 -->
                <!-- <el-button type="primary" class="width_100 height_40 flex justify_center align_center" @click="addJob">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加职位</span>
                </el-button> -->
            </div>
            <!-- 右侧 职位配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 职位配置 标题 -->
                <div class="fn_size20 width_826 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="jobStause=jobStause"></el-button>
                    <!-- @click="jobStause=!jobStause" -->
                    <span v-if="jobStause" class="flex justify_center align_center"
                        style="font-weight: bold;">当前职位与角色的关联关系</span>
                    <!-- <span v-if="!jobStause" class="flex justify_center align_center"
				style="font-weight: bold;">职位职位-组织配置</span> -->
                    <el-button icon="el-icon-arrow-right el-icon--right"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="jobStause=jobStause"></el-button>
                    <!-- @click="jobStause=!jobStause" -->
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div v-if="jobStause">
                        <el-col :span="10" class="height_500">
                            <el-table :data="jobNotHaveRoleList" border :header-cell-style="tableHeaderColor"
                                class=" width_100" height="100%">
                                <el-table-column prop="name" label="当前系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_500">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="height_500">
                            <el-table :data="jobHaveRoleList" border :header-cell-style="tableHeaderColor"
                                class="width_100" height="100%">
                                <el-table-column prop="name" label="当前职位已关联的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
        <!-- 添加职位 -->
        <el-dialog title="添加职位" :visible.sync="dialogVisibleJobAdd" top='25vh' width="40%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加职位</div> -->
            <div class="pdr20">
                <el-form :model="addJobFrom" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="职位名称：" prop="jobName">
                        <el-input v-model="addJobFrom.jobName" placeholder="请输入" class="width_170 ">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="部门：" :label-width="labelWidth2">
                        <el-select v-model="addJobFrom.departmentID" placeholder="请选择" class="">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上级职位：" prop="jobName">
                        <!-- <el-input v-model="addJobFrom.upJobID" placeholder="请输入" class="width_170 ">
                        </el-input> -->
                        <el-select v-model="addJobFrom.upJobID" placeholder="" class="width_170">
                            <el-option v-for="(item,index) in jobArray" :key="index" :label="item.jobName"
                                :value="item.jobID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上级部门：" prop="jobName">
                        <!-- <el-input v-model="addJobFrom.upDepartmentID" placeholder="请输入" class="width_170 ">
                        </el-input> -->
                        <el-select v-model="addJobFrom.upDepartmentID" placeholder="" class="width_170">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-form :model="addJobFrom" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="备注：" prop="description">
                        <el-input type="textarea" :rows="4" v-model="addJobFrom.description" resize="none"
                            placeholder="请输入备注信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAddJob">添加</el-button>
            </div>
        </el-dialog>
        <!-- 编辑职位 -->
        <el-dialog title="职位信息配置" :visible.sync="dialogVisibleJobEdit" top='3vh' width="40%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">职位信息配置</div> -->
            <div class="pdl20 pdr20 flex flex_column">
                <el-form :model="editJobFrom" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="职位名称：" prop="jobName">
                        <el-input v-model="editJobFrom.jobName" placeholder="文档名称" class="width_170 ">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="部门：" :label-width="labelWidth2">
                        <el-select v-model="editJobFrom.departmentID" placeholder="" class="width_170">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上级职位：" prop="jobName">
                        <!-- <el-input v-model="editJobFrom.upJobID" placeholder="请输入" class="width_170 ">
                        </el-input> -->
                        <el-select v-model="editJobFrom.upJobID" placeholder="" class="width_170">
                            <el-option v-for="(item,index) in jobArray" :key="index" :label="item.jobName"
                                :value="item.jobID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上级部门：" prop="jobName">
                        <!-- <el-input v-model="editJobFrom.upDepartmentID" placeholder="请输入" class="width_170 ">
                        </el-input> -->
                        <el-select v-model="editJobFrom.upDepartmentID" placeholder="" class="width_170">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-form :model="editJobFrom" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="备注：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="editJobFrom.description" resize="none"
                            placeholder="请输入备注信息" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- 穿梭框 -->
                <div class="fn_size20 flex justify_between align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="jobStause=jobStause"></el-button>
                    <!-- @click="jobStause=!jobStause" -->
                    <div v-if="jobStause" class="fn_size18 color_000000 flex justify_center font_bold">角色-职位配置
                    </div>
                    <!-- <div v-if="!jobStause" class="fn_size18 color_000000 flex justify_center font_bold">职位职位-组织配置</div> -->
                    <el-button icon="el-icon-arrow-right el-icon--right"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="jobStause=jobStause"></el-button>
                    <!-- @click="jobStause=!jobStause" -->
                </div>
                <div class="mg_top_20">
                    <div v-if="jobStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="jobNotHaveRoleList" border ref="selection"
                                @selection-change="checkLeftJobRole" :header-cell-style="tableHeaderColor" height="100%"
                                class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="leftSelectJobRole.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="bandingJobAndRole">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="rightSelectJobRole.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="unBandingJobAndRole">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="jobHaveRoleList" border ref="selection"
                                @selection-change="checkRightJobRole" :header-cell-style="tableHeaderColor"
                                height="100%" class=" width_100" style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前职位已关联的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onEditJob">确定</el-button>
            </div>
        </el-dialog>
        <!-- 职位-结束 -->


        <!----------------------- 部门职位--开始 --------------------------->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" style="height:92%;" v-if="tabIndex==6">
            <!-- 左侧 部门职位菜单 -->
            <div class=" width_450 height_100">
                <div class="flex align_center fn_size14">
                    <span>部门：</span>
                    <el-select v-model="departmentID_departmentJob" placeholder="请选择部门" @change="queryDepartmentJob">
                        <el-option v-for="(item,index) in departmentSelectList" :key="index"
                            :label="item.departmentName" :value="item.departmentID">
                        </el-option>
                    </el-select>
                </div>
                <el-table ref="departmentJobArrayRef" :header-cell-style="{textAlign: 'left'}"
                    :cell-style="{ textAlign: 'left' }" class="mg_top_10 width_100" :data="departmentJobArray"
                    height="94%" highlight-current-row @current-change="showDepartmentJobDetails">
                    <el-table-column label="职位">
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.jobName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="60">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <img src="../../assets/images/systemConfiguration/user12.png"
                                    @click="departmentJobEdit(scope.row)">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 右侧 部门职位 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 部门职位 标题 -->
                <div class="fn_size20 width_826 flex justify_center pdb_20 border_bottom_1_CCCCCC">
                    <span class="flex justify_center align_center" style="font-weight: bold;">当前部门职位与组织的关联关系
                    </span>
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div>
                        <el-col :span="10" class="height_500">
                            <el-table :data="departmentAndMeterOrganizationDataByDepartmentJob" border ref="selection"
                                :header-cell-style="tableHeaderColor" class=" width_100" height="100%">
                                <el-table-column prop="name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_500">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="height_500">
                            <el-table :data="otherMeterOrganizationDataByDepartmentJob" border ref="selection"
                                :header-cell-style="tableHeaderColor" class="width_100" height="100%">
                                <el-table-column prop="name" label="当前部门职位关联的组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
        <!-- 部门职位-弹窗 -->
        <!--部门职位管理--编辑--弹窗-->
        <el-dialog :title="'组织-部门职位('+titleNameDialog+')配置'" :visible.sync="dialogVisibleDepartmentJobEdit" top='22vh' width="45%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">部门职位信息配置</div> -->
            <div class="pdl20 pdr20 flex flex_column">
                <!-- --穿梭框 -->
                <!--                 <div class="fn_size20 flex justify_center align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <div class="fn_size18 color_000000 flex justify_center font_bold">组织-部门职位配置</div>
                </div> -->
                <div class="mg_top_20">
                    <div>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterOrganizationDataByDepartmentJob" border ref="selection"
                                @selection-change="selectBandOrganizationID_DepartmentJob"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandOrganizationID_DepartmentJob?false:true"
                                        :size="buttonSize" style="background: #EBEBEB;border:none" type="primary"
                                        @click="bandOrganization_DepartmentJob">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandOrganizationID_DepartmentJob?false:true"
                                        :size="buttonSize" style="background: #EBEBEB;border:none" type="primary"
                                        @click="unbandOrganization_DepartmentJob">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterOrganizationDataByDepartmentJob" border ref="selection"
                                @selection-change="selectUnbandOrganizationID_DepartmentJob"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前部门职位关联的组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="dialogVisibleDepartmentJobEdit = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 部门职位--结束 -->

    </div>
</template>
<script>
    import PasswordStrength from "@/components/progress";
    import {
        userConfigUserList,
        userConfigCreateUser,
        userConfigDeleteUser,
        userConfigUpdateUser,
        userConfigLockUser,
        userConfigQueryRole,
        userConfigQueryUserCorrelationRole,
        userConfigBandingUserAndRole,
        userConfigUnBandingUserAndRole,
        userConfigRoleSelect, // 角色列表查询-linxinsen
        roleConfigCreateRole, // 添加角色-linxinsen
        roleConfigUpdateRole, // 修改角色-linxinsen
        roleConfigDeleteRole, // 删除角色-linxinsen
        userConfigQueryPermissionByUserRoleID, // 根据角色ID查询系统权限-linxinsen
        userConfigQueryPageByUserRoleID, // 根据角色ID查询系统页面-linxinsen
        roleConfigCreateDepartment, // 添加部门-linxinsen
        roleConfigUpdateDepartment, // 更新部门-linxinsen
        roleConfigDeleteDepartment, // 删除部门-linxinsen
        userConfigQueryRoleByUserDepartmentID, // 根据部门ID查询系统角色-linxinsen
        userConfigQueryDepartmentCorrelationRole, // 查询部门关联的角色-linxinsen
        userConfigBandingDepartmentAndRole, // 绑定部门和角色-linxinsen
        userConfigUnBandingDepartmentAndRole, // 解绑部门和角色-linxinsen
        userConfigQueryRoleByUserJobID, // 根据职位ID查询系统角色-linxinsen
        userConfigQueryJobCorrelationRole, // 查询职位关联的角色-linxinsen
        userConfigBandingJobAndRole, // 绑定职位和角色-linxinsen
        userConfigUnBandingJobAndRole, // 解绑职位和角色-linxinsen
        roleConfigCreateJob, // 添加职位-linxinsen
        roleConfigUpdateJob, // 更新职位-linxinsen
        roleConfigDeleteJob, // 删除职位-linxinsen
        userConfigQueryRoleGroup,
        userConfigQueryRoleCorrelationRoleGroup,
        roleConfigBandingRoleGroupAndRole,
        roleConfigUnBandingRoleGroupAndRole,
        userConfigQueryOrganization,
        userConfigQueryRoleCorrelationOrganization,
        roleConfigBandingOrganizationAndRole,
        roleConfigUnBandingOrganizationAndRole,
        roleConfigCreateRoleGroup,
        roleConfigUpdateRoleGroup,
        roleConfigDeleteRoleGroup,
        userConfigQueryPermission,
        userConfigQueryRoleGroupCorrelationPermission,
        roleConfigBandingPermissionAndRoleGroup,
        roleConfigUnBandingPermissionAndRoleGroup,
        userConfigQueryPage,
        userConfigQueryRoleGroupCorrelationPage,
        roleConfigBandingPageAndRoleGroup,
        roleConfigUnBandingPageAndRoleGroup,
        userConfigGenderSelect,
        userConfigJobSelect,
        userConfigDepartmentSelect,


        // 角色管理
        userConfigBandingPermissionAndRole, //绑定权限-角色
        userConfigBandingPageAndRole, //绑定页面-角色
        userConfigUnBandingPermissionAndRole, //解绑权限和角色
        userConfigUnBandingPageAndRole, //解绑页面和角色

        //权限管理-用户组
        //author：刘勇文
        userConfigUserGroupSelect, //查找用户组
        roleConfigCreateUserGroup, //添加用户组接口
        roleConfigUpdateUserGroup, //更新用户组
        roleConfigDeleteUserGroup, //删除用户组
        userConfigQueryUserByUserGroupID, //根据用户组Id查询系统用户
        userConfigQueryOrganizationByUserGroupID, //根据用户组ID查询系统组织
        userConfigQueryRoleByUserGroupID, //根据用户组ID查询系统角色
        userConfigQueryUserGroupCorrelationUser, //根据用户组ID查询用户组关联的用户
        userConfigQueryUserGroupCorrelationRole, //根据用户组ID查询用户组关联的角色
        userConfigQueryUserGroupCorrelationOrganization, //根据用户组ID查询用户组关联的组织
        userConfigBandingUserGroupAndRole, //绑定用户组和角色
        userConfigUnBandingUserGroupAndRole, //解绑用户组和角色
        userConfigBandingUserGroupAndUser, //绑定用户组和用户
        userConfigUnBandingUserGroupAndUser, //解绑用户组和用户
        userConfigBandingUserGroupAndOrganization, //绑定用户组和组织
        userConfigUnBandingUserGroupAndOrganization, //解绑用户组和组织

        //权限管理-组织
        //author：刘勇文
        userConfigOrganizationSelect, //查询组织
        userConfigQueryRoleByUserOrganizationID, // 根据组织ID查询当前系统的角色
        userConfigQueryOrganizationCorrelationRole, // 根据组织ID查询当前组织关联的角色
        userConfigBandingOrganizationAndRole, // 绑定组织和角色
        userConfigUnBandingOrganizationAndRole, //解绑组织和角色

        //权限管理-部门职位
        //author：刘勇文
        userConfigDepartmentJobSelect, // 部门职位选择框
        userConfigQueryOrganizationByUserDepartmentJobID, //根据部门职位ID查询系统组织
        userConfigQueryDepartmentJobCorrelationOrganization, // 查询部门职位关联的组织
        userConfigBandingDepartmentJobAndOrganization, // 解绑部门职位和组织
        userConfigUnBandingDepartmentJobAndOrganization, // 解绑部门职位和组织

    } from "@/api/api_systemConfig";
    export default {
        components:{PasswordStrength},
        data() {
            // const generateData = _ => {
            //     const data = [];
            //     for (let i = 1; i <= 15; i++) {
            //         data.push({
            //             key: i,
            //             label: `备选项 ${ i }`,
            //             disabled: i % 4 === 0
            //         });
            //     }
            //     return data;
            // };
            return {
                // data: generateData(),
                value1: [],
                labelWidth: '100px',
                labelWidth2: '100px',
                tabIndex: 0,
                newFrom: {},
                fromRole: {}, //角色管理-from
                addFrom: {},
                updateForm: {
                    // userID: '',
                    // gender: '',
                    // jobTitle: '',
                    // phone: '',
                    // email: '',
                    // departmentID: '',
                    // leaveFlag: '',
                    // lockFlag: '',
                    // userPassword: '',
                    // description: ''
                },
                detailForm: {},
                lockDateReadOnly: false,
                dialogVisible: false, //新增用户弹出框 打开/关闭 控制标志
                dialog2Visible: false, //修改用户弹出框 打开/关闭 控制标志
                dialog3Visible: false, //查看用户弹出框 打开/关闭 控制标志
                dialogVisibleRoleAdd: false, //角色管理-新增角色弹窗
                dialogVisibleRoleEdit: false,
                dialogVisibleRoleGroupAdd: false,
                dialogVisibleRoleGroupEdit: false,
                tabArray: [{
                        id: 0,
                        label: '用户管理'
                    },
                    {
                        id: 1,
                        label: '用户组管理'
                    }, {
                        id: 2,
                        label: '角色管理'
                    },
                    //  {
                    //     id: 2,
                    //     label: '权限分配'
                    // },

                    //  {
                    //     id: 4,
                    //     label: '页面管理'
                    // },
                    {
                        id: 3,
                        label: '组织管理'
                    },
                    // {
                    //     id: 4,
                    //     label: '部门管理'
                    // },
                    // {
                    //     id: 5,
                    //     label: '职位管理'
                    // }, {
                    //     id: 6,
                    //     label: '部门职位管理'
                    // }
                ],
                sex: [],
                jobArray: [],
                departmentArray: [],
                value: '',
                tableData: [],
                multipleSelection: [],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                deleteNum: '',

                rulesFrom: {
                    userGroupName: [{
                        required: true,
                        message: '请填写用户组名',
                        trigger: 'blur'
                    }],
                    userGroupDescription: [{
                        required: true,
                        message: '请填写描述',
                        trigger: 'blur'
                    }],
                    roleName: [{
                        required: true,
                        message: '请填写角色名',
                        trigger: 'blur'
                    }],
                    description: [{
                        required: true,
                        message: '请填写描述',
                        trigger: 'blur'
                    }],
                },
                userRules: {
                    userName: [{
                        required: true,
                        message: '请填写用户名',
                        trigger: 'blur'
                    }],
                    gender: [{
                        required: true,
                        message: '请选择性别',
                        trigger: 'change'
                    }],
                    jobTitle: [{
                        required: true,
                        message: '请选择职位',
                        trigger: 'change'
                    }],
                    workNumber: [{
                        required: true,
                        message: '请填写工号',
                        trigger: 'blur'
                    }],
                    userAccount: [{
                        required: true,
                        message: '请填写账号',
                        trigger: 'blur'
                    }],
                    userPassword: [{
                        required: true,
                        message: '请填写密码',
                        trigger: 'blur'
                    }],
                    departmentID: [{
                        required: true,
                        message: '请选择部门',
                        trigger: 'change'
                    }],
                    // userName: [{
                    //     required: true,
                    //     message: '请填写活动形式',
                    //     trigger: 'blur'
                    // }],
                    // resource: [{
                    //     required: true,
                    //     message: '请选择活动资源',
                    //     trigger: 'change'
                    // }],
                },
                // 角色管理-开始
                roleArray: [], // 角色列表
                userHaveRoleArray: [], // 用户已有角色列表
                userNotHaveRoleArray: [], // 用户没有角色列表
                newRoleList: [], // linxinsen-角色列表
                addRoleFrom: {}, // linxinsen-添加角色表单
                editRoleFrom: {}, // linxinsen-修改角色表单
                roleNotHavePermission: [], // linxinsen-当前角色没有的权限列表
                roleHavePermission: [], // linxinsen-当前角色已有权限列表
                dialogVisibleRoleAddgun: false, // linxinsen-这玩意没用，别人改名之后这个可以删了
                dialogVisibleRoleEdit111: false, // linxinsen-这玩意没用，别人改名之后这个可以删了
                pageAuthority: {},
                readonlyRole: true,
                permissionByUserRole: [], //权限-角色
                pageByUserRole: [], //页面-角色
                roleTitleStatus: true, //true权限-角色配置,false页面-角色配置
                // 角色管理-结束
                // 部门-开始-linxinsen
                departmentArrayTab: [],
                editDepartmentFrom: {},
                addDepartmentFrom: {},
                dialogVisibleDepartmentEdit: false,
                dialogVisibleDepartmentAdd: false,
                departmentTypeArray: [],
                departmentStause: true,
                departmentNotHaveRoleList: [],
                departmentHaveRoleList: [],
                selectedDepartmentID: '',
                leftSelectDepartmentRole: [],
                rightSelectDepartmentRole: [],
                // 部门-结束-linxinsen
                // 职位-开始-linxinsen
                editJobFrom: {},
                addJobFrom: {},
                dialogVisibleJobEdit: false,
                dialogVisibleJobAdd: false,
                jobTypeArray: [],
                jobStause: true,
                jobNotHaveRoleList: [],
                jobHaveRoleList: [],
                selectedJobID: '',
                leftSelectJobRole: [],
                rightSelectJobRole: [],
                // 职位-结束-linxinsen
                // 角色组-结束
                // 穿梭框-开始
                buttonSize: 'large',
                nowSelectData: [], // 左边选中列表数据
                nowSelectRightData: [], // 右边选中列表数据    
                departmentAndMeterData: [], //系统所有角色（左侧）(数据格式自定义或者从后台获取)    
                otherMeterData: [], //当前用户角色（右侧）（数据格式自定义或者从后台获取）
                //穿梭框-结束
                query: {
                    "typeID": 1,
                    "text": ''
                },
                queryList: [],
                lockUserID: '',
                roleDetailsShow: true,
                roleStause: true,
                roleGroupDetailsShow: true,
                userID: '',
                fromRoleGroup: {},


                // 用户管理LIU
                departmentArrayValue: [],

                //用户组模块-使用的相关变量
                //author：刘勇文
                //功能1 查询用户组
                roleGroupArray: [], //查询用户组所用数组


                //功能2 添加用户组
                dialogVisibleRoleGroupAdd: false, //添加用户组弹框 显示/关闭 控制标识符

                addRoleGroupForm: {
                    userGroupName: '', //用户组名
                    userGroupDescription: '', //用户组描述
                }, //添加用户组所用的对象

                //功能3 删除用户组
                deleteUserGroupID: '', //要删除的用户组ID

                //功能4 更新用户组信息
                dialogVisibleRoleGroupEdit: false, //更新用户组信息弹框 显示/关闭 控制标识符

                updateRoleGroupForm: {
                    userGroupID: '', //用户组ID
                    userGroupName: '', //用户组名
                    description: '', //用户组描述
                }, //跟新用户组所用的对象

                //功能5 首页展示穿梭框循环展示控制符
                userGroupDetailsShow: 2,

                //功能6
                showDetailsuserGroupID: '', //要展示用户组配置详情的用户组id

                departmentAndMeterUserData: [], //根据用户组ID展示当前系统的用户 所用的数组
                departmentAndMeterRoleData: [], //根据用户组ID展示当前系统的角色 所用的数组
                departmentAndMeterGroupData: [], //根据用户组ID展示当前系统的组织 所用的数组

                otherMeterUserData: [], //根据用户组ID展示用户组关联的用户 所用的数组
                otherMeterRoleData: [], //根据用户组ID展示用户组关联的角色 所用的数组
                otherMeterGroupData: [], //根据用户组ID展示用户组关联的组织 所用的数组

                //功能7
                bandUserGroupID: '', //要绑定的目标用户组ID
                unbandUserGroupID: '', //要解绑的目标用户组ID

                //功能7
                bandRoleID: '', //要绑定的目标角色ID
                unbandRoleID: '', //要解绑的目标角色ID

                //功能8
                bandUserID: '', //要绑定的目标用户ID
                unbandUserID: '', //要解绑的目标用户ID

                //功能9
                bandOrganizationID: '', //要绑定的目标组织ID
                unbandOrganizationID: '', //要解绑的目标组织ID


                //--------组织模块-使用的相关变量
                //author：刘勇文
                //功能 1
                organizationArray: [], //查询组织使用的数组

                //功能 2
                showDetailOrganizationID: '', //要展示组织配置详情的组织ID

                departmentAndMeterRoleDataByOrganizationID: [], //根据组织ID查询当前系统的角色 所用的数组

                otherMeterRoleDataByOrganization: [], //根据组织ID查询当前组织关联的角色 所用的数组

                //功能3
                dialogVisibleOrganizationEdit: false, //绑定和解绑组织和角色弹框 显示/关闭 控制标识符

                bandRoleID_Organization: '', //要绑定的角色ID
                unbandRoleID_Organization: '', //要解绑的角色ID

                bandOrganizationID_Organization: '', //要绑定的组织ID
                unbandOrganizationID_Organization: '', //要解绑的组织ID


                //部门职位模块-使用的相关变量

                //功能1：部门选择下拉框
                departmentSelectList: [], //部门选择下拉框 使用的数组
                departmentID_departmentJob: '', //部门选择下拉框 保存选择的部门ID使用的变量

                //功能2：部门职位选择框
                departmentJobArray: [], //部门职位选择框 使用额数组


                //功能3: 展示部门职位-组织配置详情
                showDetailDepartmentID: '', //要展示部门职位-组织配置详情的部门ID
                showDetailJobID: '', //要展示部门职位-组织配置详情的职位ID

                departmentAndMeterOrganizationDataByDepartmentJob: [], //根据部门职位ID查询当前系统组织 使用的数组

                otherMeterOrganizationDataByDepartmentJob: [], //根据部门职位ID查询当前部门职位关联的组织 使用的数组

                //功能4：绑定和解绑部门职位-组织
                dialogVisibleDepartmentJobEdit: false, //绑定和解绑 部门职位和组织 弹框 显示/关闭 控制标识符

                bandDepartmentID_DepartmentJob: '', // 要绑定的部门ID
                bandJobID_DepartmentJob: '', // 要绑定的职位ID
                bandOrganizationID_DepartmentJob: '', // 绑定的组织ID

                unbandDepartmentID_DepartmentJob: '', // 要解绑的部门ID
                unbandJobID_DepartmentJob: '', // 要解绑的职位ID
                unbandOrganizationID_DepartmentJob: '', // 要解绑的组织ID


                titleNameDialog:'',
            };
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                // this.addFrom.userID = userInfo.userID
                this.userID = userInfo.userID
            };
            this.userConfigUserList();
            // this.userConfigBandingUserAndRole(); // 已调通 绑定用户和角色
            // this.userConfigUnBandingUserAndRole(); // 已调通 解绑用户和角色
            // this.roleConfigCreateRole(); // 已调通 创建角色
            // this.roleConfigUpdateRole(); // 已调通 更新角色
            // this.roleConfigDeleteRole(); // 已调通 删除角色

            // this.userConfigQueryRoleCorrelationRoleGroup(); // 已调通 查询角色关联的角色组
            // this.roleConfigBandingRoleGroupAndRole(); // 已调通 绑定角色和角色组
            // this.roleConfigUnBandingRoleGroupAndRole(); // 已调通 解绑角色和角色组
            // this.userConfigQueryOrganization(); // 已调通 查询系统组织
            // this.userConfigQueryRoleCorrelationOrganization(); // 已调通 查询角色关联的组织
            // this.roleConfigBandingOrganizationAndRole(); // 已调通 绑定角色和组织
            // this.roleConfigUnBandingOrganizationAndRole(); // 已调通 解绑角色和组织
            // this.roleConfigCreateRoleGroup(); // 已调通 添加角色组
            // this.roleConfigUpdateRoleGroup(); // 已调通 更新角色组
            // this.roleConfigDeleteRoleGroup(); // 已调通 删除角色组
            // this.userConfigQueryPermission(); // 已调通 查询系统权限
            // this.userConfigQueryRoleGroupCorrelationPermission(); // 已调通 查询角色组关联的权限
            // this.roleConfigBandingPermissionAndRoleGroup(); // 已调通 绑定角色组和权限
            // this.roleConfigUnBandingPermissionAndRoleGroup(); // 已调通 解绑角色组和权限
            // this.userConfigQueryPage(); // 已调通 查询系统页面
            // this.userConfigQueryRoleGroupCorrelationPage(); // 已调通 查询角色组关联的页面
            // this.roleConfigBandingPageAndRoleGroup(); // 已调通 绑定角色组和页面 接口调用成功但是实际并没有效果
            // this.roleConfigUnBandingPageAndRoleGroup(); // 已调通 解绑角色组和页面
            this.userConfigGenderSelect(); // 已调通 性别选择
            this.userConfigJobSelectUser(); // 已调通 职位选择
            this.userConfigDepartmentSelect(); // 部门选择
            // this.userConfigQueryPermissionByUserRoleID();
            // this.userConfigQueryPageByUserRoleID();
        },
        methods: {
          //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '权限管理')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },

            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },

            // 用户管理接口--开始
            // 用户管理页面，点击“查询”按钮
            queryUser() {
                this.currentPage = 1;
                this.userConfigUserList();
            },
            // 部门选择
            async userConfigDepartmentSelect() {
                var param = {}
                const res = await userConfigDepartmentSelect(param);
                this.departmentArray = res.data.rows
            },
            // 职位选择
            async userConfigJobSelectUser() {
                var param = {}
                const res = await userConfigJobSelect(param);
                this.jobArray = res.data.rows
            },
            // 用户信息清单-分页筛选
            async userConfigUserList() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (this.departmentArrayValue.length != 0) {
                    param.departmentID = this.departmentArrayValue.join(',')
                }
                const res = await userConfigUserList(param);
                this.queryList = res.data.rows;
                this.pageQuantity = res.data.pageQuantity;
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.userConfigUserList();
            },
            // 性别选择
            async userConfigGenderSelect() {
                var param = {}
                const selectRes = await userConfigGenderSelect(param);
                this.sex = selectRes.data;
            },
            //增加用户
            add() {
                //将原有数据清空，以备用来新增 用户信息
                this.addFrom = {};
                this.dialogVisible = true;
            },
            // 增加--添加按钮
            trueadd() {
                this.$refs['userInfoAdd'].validate((valid) => {
                    if (valid) {
                        this.userConfigCreateUser();
                    } else {
                        return false;
                    }
                });
            },
            async userConfigCreateUser() {
                this.addFrom.userID = this.userID;
                if(this.addFrom.isWorker == this.addFrom.isAdmin){
                    this.$message({
                        message: "工人和管理员身份不可同时选择相同",
                        type: 'warning'
                    });
                }else{
                    const res = await userConfigCreateUser(this.addFrom);
                    if (res) {
                        if (res.data && res.data.result) {
                            this.dialogVisible = false;
                            this.$message({
                                message: res.message,
                                type: 'success'
                            });
                            this.userConfigUserList();
                            this.addFrom = {}; // 新增用户成功后清空表单，否则会有缓存
                        } else {
                            this.$message.error(res.message);
                        }
                    } else {
                        this.$message.error('添加失败');
                    }
                }
            },
            // 删除
            delTableData(userID) {
                this.deleteNum = userID;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 调用删除接口
                    // todo
                    this.currentPage = this.delData.delData(this.queryList.length, this.pageQuantity, this
                        .currentPage)
                    this.userConfigDeleteUser();
                }).catch(() => {});
            },
            // 调用删除接口，实现删除
            async userConfigDeleteUser() {
                var param = {
                    userID: this.deleteNum
                }
                const res = await userConfigDeleteUser(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserList();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //编辑（修改）
            //点击‘编辑’按钮 触发方法
            gotoModify(temp) {
                this.updateForm = temp;
                // this.updateForm.userID = temp.userID;
                // this.updateForm.gender = temp.gender;
                // this.updateForm.jobTitle = temp.jobTitle;
                // this.updateForm.phone = temp.phone;
                // this.updateForm.email = temp.email;
                // this.updateForm.departmentID = temp.departmentID;
                // this.updateForm.leaveFlag = temp.leaveFlag;
                // this.updateForm.lockFlag = temp.lockFlag;
                // this.updateForm.userPassword = temp.userPassword;
                // this.updateForm.description = temp.description;

                // 打开编辑窗口
                this.dialog2Visible = true;
                // 暂时隐藏
                // this.userConfigQueryRole(this.updateForm.userID); // 已调通 查询角色列表
                // this.userConfigQueryUserCorrelationRole(this.updateForm.userID); // 已调通 查询用户关联的角色
            },
            //点击编辑窗口中的 ‘确定’ 触发方法
            trueModify() {
                //调用 修改用户方法
                this.$refs['userInfoUpdate'].validate((valid) => {
                    if (valid) {
                         this.userConfigUpdateUser();
                    } else {
                        return false;
                    }
                });
            },
            // 修改用户方法
            //调用 UserConfigUpdateUser 接口，实现修改用户功能 
            async userConfigUpdateUser() {
              if(this.updateForm.isWorker == this.updateForm.isAdmin){
                    this.$message({
                        message: "工人和管理员身份不可同时选择相同",
                        type: 'warning'
                    });
               }else{    
                //封装参数
                var param = {
                    userID: this.updateForm.userID,
                    gender: this.updateForm.gender,
                    jobTitle: this.updateForm.jobTitle,
                    phone: this.updateForm.phone,
                    email: this.updateForm.email,
                    departmentID: this.updateForm.departmentID,
                    leaveFlag: this.updateForm.leaveFlag,
                    lockFlag: this.updateForm.lockFlag,
                    userPassword: this.updateForm.userPassword,
                    description: this.updateForm.description,
                    isAdmin:this.updateForm.isAdmin,
                    isWorker:this.updateForm.isWorker
                }
                console.log(param);
                const res = await userConfigUpdateUser(param);
                console.log(param);

                if (res && res.data && res.data.result) {
                    this.dialog2Visible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserList();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
               }
            },
            //锁定
            //点击‘锁定’按钮时触发的方法
            lock(id) {
                this.lockUserID = id;
                this.$confirm('此操作为锁定用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用锁定方法
                    this.userConfigLockUser();
                }).catch(() => {});

            },
            // 锁定方法
            // 调用 锁定userConfigLockUser接口 实现锁定功能
            async userConfigLockUser() {
                // 封装参数
                var param = {
                    userID: this.lockUserID
                };
                const res = await userConfigLockUser(param);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    // 调用分页查询，刷新操作之后的页面
                    this.userConfigUserList();
                } else {
                    this.$message.error(res.message);
                }
            },
            //查看
            //点击‘查看’按钮时触发的方法
            look(temp) {
                //更新查看数据
                console.log(temp)
                this.detailForm = temp;
                //打开查看弹窗
                this.dialog3Visible = true;
                // this.userConfigQueryRole(temp.userID); // 已调通 查询角色列表
                // this.userConfigQueryUserCorrelationRole(temp.userID); // 已调通 查询用户关联的角色
            },
            //点击查看dialog中的‘确定’按钮时触发的方法
            closedialog3() {
                this.dialog3Visible = false;
            },
            changeLockDateReadOnly() {
                if (this.addFrom.lockFlag == 0) {
                    this.lockDateReadOnly = true;
                } else {
                    this.lockDateReadOnly = false;
                }
            },
            // 用户管理接口--结束


            // -------------用户组接口--开始------------

            //用户组模块-使用的相关方法
            //author：刘勇文

            //功能 1：查询用户组
            //1.1 查询用户组方法
            async userConfigUserGroupSelect() {
                //封装参数
                var param = {};
                //调用接口
                const selectRes = await userConfigUserGroupSelect(param);
                // console.log(selectRes)
                //处理返回值，讲返回值装载到查询用户组数组中
                if (selectRes) { //如果有返回值则赋值
                    this.roleGroupArray = selectRes.data;
                    // console.log(this.roleGroupArray)
                    if (this.roleGroupArray.length != 0) {
                        this.$refs['roleGroupRef'].setCurrentRow(this.roleGroupArray[0]);
                    }
                }
                //转换时间格式
                // for (var i = 0; i < this.roleGroupArray.length; i++) {
                //     let data = this.roleGroupArray[i].userGroupCreateDate;
                //     this.roleGroupArray[i].userGroupCreateDate = this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
                // }

            },

            //功能 2：添加用户组
            //2.1 点击 ‘添加用户组’ 按钮时触发的方法 
            addRoleGroup() {

                //（1）初始化添加用户组所用的数组，以备用来添加新的用户组
                this.addRoleGroupForm = {};
                //打开添加用户组弹框
                this.dialogVisibleRoleGroupAdd = true;
            },
            //2.2 点击 添加用户组弹框中的 ‘添加’ 按钮时触发的方法
            onAdd() {
                //调用添加用户组方法
                this.roleConfigCreateUserGroup();
            },
            //2.3 添加用户组的方法
            async roleConfigCreateUserGroup() {

                //封装参数
                var param = {
                    userGroupName : this.addRoleGroupForm.userGroupName,
                    description : this.addRoleGroupForm.userGroupDescription,
                };
                //调用接口
                if(this.addRoleGroupForm.userGroupName&&this.addRoleGroupForm.userGroupDescription!=undefined){
                const res = await roleConfigCreateUserGroup(param);
                    //处理返回值
                    this.dialogVisibleRoleGroupAdd = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserGroupSelect();
                } else {
                    this.$message({
                        showClose: true,
                        message: '创建失败',
                        type: 'error'
                    });
                }
            },

            //功能 3：删除用户组
            //3.1 点击左侧表中的‘删除’按钮时触发的方法
            roleGroupDel(id) {
                this.$confirm('此操作将永久删除该用户组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用删除用户组方法，进行用户组的删除
                    this.deleteUserGroupID = id;
                    this.roleConfigDeleteUserGroup();

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //3.2 删除用户组的方法
            async roleConfigDeleteUserGroup() {

                //封装参数
                var param = {
                    userGroupID: this.deleteUserGroupID,
                };

                //调用接口
                const res = await roleConfigDeleteUserGroup(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserGroupSelect();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //功能 4：更新用户组信息
            //4.1 点击左侧表中的‘更新’按钮时触发的方法
            roleGroupEdit(data) {

                //初始化更新用户组信息所使用的数组，以便用来更新用户组信息
                this.updateRoleGroupForm.userGroupID = data.userGroupID;
                this.updateRoleGroupForm.userGroupName = data.userGroupName;
                this.updateRoleGroupForm.description = data.userGroupDesc;

                //初始化更新用户组绑定信息所使用的数据
                //展示
                this.showDetailsuserGroupID = data.userGroupID;
                //调用 根据用户组ID查询系统用户 方法
                this.userConfigQueryUserByUserGroupID();
                //调用 根据用户组ID查询系统角色 方法
                this.userConfigQueryRoleByUserGroupID();
                //调用 根据用户组ID查询系统组织 方法
                this.userConfigQueryOrganizationByUserGroupID();
                //调用 根据用户组ID查询用户组关联的用户 方法
                this.userConfigQueryUserGroupCorrelationUser();
                //调用 根据用户组ID查询用户组关联的角色 方法
                this.userConfigQueryUserGroupCorrelationRole();
                //调用 根据用户组ID查询用户组关联的组织 方法
                this.userConfigQueryUserGroupCorrelationOrganization();

                //绑定和解绑
                this.bandUserGroupID = data.userGroupID;
                this.unbandUserGroupID = data.userGroupID;

                //打开更新用户组信息弹框
                this.dialogVisibleRoleGroupEdit = true;
            },
            //4.2 点击更新用户组信息弹框中的‘添加’按钮时触发的方法
            trueEdit() {

                //调用更新用户组信息方法，进行用户组信息的更新
                this.roleConfigUpdateUserGroup();
            },
            //4.3 更新用户组信息方法
            async roleConfigUpdateUserGroup() {

                //封装参数
                var param = {
                    userGroupID: this.updateRoleGroupForm.userGroupID,
                    userGroupName: this.updateRoleGroupForm.userGroupName,
                    description: this.updateRoleGroupForm.description,
                };
                
                //调用接口
                const res = await roleConfigUpdateUserGroup(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    //关闭更新用户组信息弹框
                    this.dialogVisibleRoleGroupEdit = false;
                    //调用查询用户组方法，刷新更新之后的数据
                    this.userConfigUserGroupSelect();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //功能 5：首页展示穿梭框 循环展示控制
            //5.1 点击首演展示穿梭框中 ‘左箭头’按钮 和 ‘右箭头’按钮时触发的方法
            userGroupDetailsShowControl(flag) {

                if (flag) { //flag等于true时，页面向左循环展示
                    this.userGroupDetailsShow--;
                    if (this.userGroupDetailsShow == 0)
                        this.userGroupDetailsShow = 3;
                } else { //flag等于false时，页面
                    this.userGroupDetailsShow++;
                    if (this.userGroupDetailsShow == 4)
                        this.userGroupDetailsShow = 1;
                }

            },

            //功能 6：用户组配置信息详情展示
            //6.1 点击左侧表中每一个‘用户组模块’时触发的方法
            showDetails(temp) {
                console.log(temp)
                this.showDetailsuserGroupID = temp.userGroupID;
                this.departmentAndMeterUserData = [];
                this.departmentAndMeterRoleData = [];
                this.departmentAndMeterGroupData = [];
                this.otherMeterUserData = [];
                this.otherMeterRoleData = [];
                this.otherMeterGroupData = [];

                //调用 根据用户组ID查询系统用户 方法
                this.userConfigQueryUserByUserGroupID();
                // //调用 根据用户组ID查询系统角色 方法
                this.userConfigQueryRoleByUserGroupID();
                // //调用 根据用户组ID查询系统组织 方法
                this.userConfigQueryOrganizationByUserGroupID();
                // //调用 根据用户组ID查询用户组关联的用户 方法
                this.userConfigQueryUserGroupCorrelationUser();
                // //调用 根据用户组ID查询用户组关联的角色 方法
                this.userConfigQueryUserGroupCorrelationRole();
                //调用 根据用户组ID查询用户组关联的组织 方法
                this.userConfigQueryUserGroupCorrelationOrganization();
            },
            //6.2 根据用户组ID展示当前系统用户的方法
            async userConfigQueryUserByUserGroupID() {

                //封装参数
                var param = {
                    userGroupID: this.showDetailsuserGroupID,
                };

                //调用接口
                const selectRes = await userConfigQueryUserByUserGroupID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentAndMeterUserData = selectRes.data;
                }
            },
            //6.3 根据用户组ID展示当前系统角色的方法
            async userConfigQueryRoleByUserGroupID() {

                //封装参数
                var param = {
                    userGroupID: this.showDetailsuserGroupID,
                };

                //调用接口
                const selectRes = await userConfigQueryRoleByUserGroupID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentAndMeterRoleData = selectRes.data;
                }
            },
            //6.4 根据用户组Id展示当前系统组织的方法
            async userConfigQueryOrganizationByUserGroupID() {

                //封装参数
                var param = {
                    userGroupID: this.showDetailsuserGroupID,
                };

                //调用接口
                const selectRes = await userConfigQueryOrganizationByUserGroupID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentAndMeterGroupData = selectRes.data;
                }
            },
            //6.5 根据用户组ID查询用户组关联的用户的方法
            async userConfigQueryUserGroupCorrelationUser() {

                //封装参数
                var param = {

                    userGroupID: this.showDetailsuserGroupID,
                    //userGroupID:'usergroup_a',
                };

                //调用接口
                const selectRes = await userConfigQueryUserGroupCorrelationUser(param);
                //处理返回值
                if (selectRes) { //如果有返回值
                    this.otherMeterUserData = selectRes.data;
                }
            },
            //6.6 根据用户组ID查询用户组关联的角色的方法
            async userConfigQueryUserGroupCorrelationRole() {

                //封装参数
                var param = {

                    userGroupID: this.showDetailsuserGroupID,
                    //userGroupID:'usergroup_a',
                };

                //调用接口
                const selectRes = await userConfigQueryUserGroupCorrelationRole(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.otherMeterRoleData = selectRes.data;
                }
            },
            //6.7 根据用户组ID查询用户组关联的组织的方法
            async userConfigQueryUserGroupCorrelationOrganization() {

                //封装参数
                var param = {
                    userGroupID: this.showDetailsuserGroupID,
                };

                //调用接口
                const selectRes = await userConfigQueryUserGroupCorrelationOrganization(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.otherMeterGroupData = selectRes.data;
                }
            },

            //功能 7：绑定和解绑用户组和角色
            //7.1 选择要绑定的角色ID
            selectBandRoleID(role) {

                var temp = [];
                for (var i = 0; i < role.length; i++) {
                    temp.push(role[i].id);
                }

                //console.log(temp)
                this.bandRoleID = temp.join(",");

                //console.log(this.bandRoleID) 
            },
            //7.2 点击绑定用户组和角色‘向左箭头’时触发的方法
            bandRole() {

                //调用绑定用户组和角色的方法
                this.userConfigBandingUserGroupAndRole();
            },
            //7.3 绑定用户组和角色的方法
            async userConfigBandingUserGroupAndRole() {

                //封装参数
                var param = {
                    userGroupID: this.bandUserGroupID,
                    roleID: this.bandRoleID,
                };

                //调用接口
                const res = await userConfigBandingUserGroupAndRole(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据根据用户组ID查询系统角色方法 刷新绑定之后的页面数据
                    this.userConfigQueryRoleByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的角色方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationRole();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }

            },
            //7.4 选择要解绑的角色ID
            selectUnbandRoleID(role) {

                var temp = [];
                for (var i = 0; i < role.length; i++) {
                    temp.push(role[i].id);
                }

                this.unbandRoleID = temp.join(",");

            },
            //7.5 点击解绑用户组和角色‘向右箭头’时触发的方法
            unbandRole() {

                //调用解绑用户组和角色的方法
                this.userConfigUnBandingUserGroupAndRole();
            },
            //7.6 解绑用户组和角色的方法
            async userConfigUnBandingUserGroupAndRole() {

                //封装参数
                var param = {
                    userGroupID: this.unbandUserGroupID,
                    roleID: this.unbandRoleID,
                };

                //调用接口
                const res = await userConfigUnBandingUserGroupAndRole(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据根据用户组ID查询系统角色方法 刷新绑定之后的页面数据
                    this.userConfigQueryRoleByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的角色方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationRole();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //功能 8：绑定和解绑用户组和用户
            //8.1 选择要绑定的用户ID
            selectBandUserID(user) {

                var temp = [];
                for (var i = 0; i < user.length; i++) {
                    temp.push(user[i].id);
                }

                this.bandUserID = temp.join(",");
            },
            //8.2 点击绑定用户组和用户‘向左箭头’时触发的方法
            bandUser() {

                //调用绑定用户组和用户的方法
                this.userConfigBandingUserGroupAndUser();
            },
            //8.3 绑定用户组和用户的方法
            async userConfigBandingUserGroupAndUser() {

                //封装参数
                var param = {
                    userGroupID: this.bandUserGroupID,
                    userID: this.bandUserID,
                };

                //调用接口
                const res = await userConfigBandingUserGroupAndUser(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据用户组ID查询系统用户方法 刷新绑定之后的页面数据
                    this.userConfigQueryUserByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的用户方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationUser();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }

            },
            //8.4 选择要解绑的用户ID
            selectUnbandUserID(user) {

                var temp = [];
                for (var i = 0; i < user.length; i++) {
                    temp.push(user[i].id);
                }

                this.unbandUserID = temp.join(",");
            },
            //8.5 点击解绑用户组和用户‘向左箭头’时触发的方法
            unbandUser() {

                //调用解绑用户组和用户的方法
                this.userConfigUnBandingUserGroupAndUser();
            },
            //8.6 解绑用户组和用户的方法
            async userConfigUnBandingUserGroupAndUser() {

                //封装参数
                var param = {
                    userGroupID: this.unbandUserGroupID,
                    userID: this.unbandUserID,
                };

                //调用接口
                const res = await userConfigUnBandingUserGroupAndUser(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据用户组ID查询系统用户方法 刷新绑定之后的页面数据
                    this.userConfigQueryUserByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的用户方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationUser();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },


            //功能 9：绑定和解绑用户组和组织
            //9.1 选择要绑定的组织ID
            selectBandOrganizationID(organization) {

                var temp = [];
                for (var i = 0; i < organization.length; i++) {
                    temp.push(organization[i].id);
                }

                this.bandOrganizationID = temp.join(",");
            },
            //9.2 点击绑定用户组和组织‘向左箭头’时触发方法
            bandOrganization() {

                //调用绑定用户组和组织的方法
                this.userConfigBandingUserGroupAndOrganization();
            },
            //9.3 绑定用户组和组织的方法
            async userConfigBandingUserGroupAndOrganization() {

                //封装参数
                var param = {
                    userGroupID: this.bandUserGroupID,
                    organizationID: this.bandOrganizationID,
                };

                //调用接口
                const res = await userConfigBandingUserGroupAndOrganization(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据根据用户组ID查询系统组织方法 刷新绑定之后的页面数据
                    this.userConfigQueryOrganizationByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的组织方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationOrganization();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //9.4 选择要解绑的组织ID
            selectUnbandOrganizationID(organization) {

                var temp = [];
                for (var i = 0; i < organization.length; i++) {
                    temp.push(organization[i].id);
                }

                this.unbandOrganizationID = temp.join(",");
            },
            //9.5 点击解绑用户组和组织‘向右箭头’时触发的方法
            unbandOrganization() {

                //调用解绑用户组和组织的方法
                this.userConfigUnBandingUserGroupAndOrganization();
            },
            //9.6 解绑用户组和组织的方法
            async userConfigUnBandingUserGroupAndOrganization() {

                //封装参数
                var param = {
                    userGroupID: this.unbandUserGroupID,
                    organizationID: this.unbandOrganizationID,
                };

                //调用接口
                const res = await userConfigUnBandingUserGroupAndOrganization(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据根据用户组ID查询系统组织方法 刷新绑定之后的页面数据
                    this.userConfigQueryOrganizationByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的组织方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationOrganization();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },


            //<------------用户组接口--结束-------------------------->

            // ---------------------角色管理接口--开始-linxinsen
            // 添加角色提交
            onAddRole() {
                  this.$refs.addRoleFrom.validate((result) => {
                    if (result) {
                        this.roleConfigCreateRole();
                    } else {
                        this.$message.error('内容填写有误或未填写!请检查内容!')
                    }
                })
            },
            // 修改角色提交
            onEditRole() {
                this.$refs.editRoleFrom.validate((result) => {
                    if (result) {
                        this.roleConfigUpdateRole();
                    } else {
                        this.$message.error('内容填写有误或未填写!请检查内容!')
                    }
                })
                
            },
            // 左侧角色列表选中行数据改变
            handleCurrentChangeData(val) {
                console.log(val)
            },
            // 角色列表查询
            async userConfigRoleSelect() {
                var param = {}
                const res = await userConfigRoleSelect(param);
                this.newRoleList = res.rows;
                for(let i=0;i<this.newRoleList.length;i++){
                    this.newRoleList[i].copyName=this.newRoleList[i].roleName
                }
                console.log(this.newRoleList);
                if (this.newRoleList.length != 0) {
                    this.$refs['roleListTable'].setCurrentRow(this.newRoleList[0]);
                }
                // this.userConfigQueryPermissionByUserRoleID();
            },

            // 添加角色
            addRole() {
                this.addRoleFrom = {};
                
                this.dialogVisibleRoleAdd = true;
                if (this.$refs.addRoleFrom){
                    this.$refs.addRoleFrom.resetFields();
                }
                // this.dialogVisibleRole = true
            },
            async roleConfigCreateRole() {
                var param = this.addRoleFrom;
                const res = await roleConfigCreateRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.dialogVisibleRoleAdd = false;
                    this.userConfigRoleSelect();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 更新角色
            async roleConfigUpdateRole() {
                var param = {
                    roleID: this.editRoleFrom.roleID,
                    roleName: this.editRoleFrom.roleName,
                    description: this.editRoleFrom.description
                }
                const res = await roleConfigUpdateRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.dialogVisibleRoleEdit = false;
                    this.userConfigRoleSelect();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 删除角色
            async roleConfigDeleteRole(roleID) {
                var param = {
                    roleID: roleID
                }
                const res = await roleConfigDeleteRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigRoleSelect();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 角色编辑控制弹框
            roleEdit(val) {
                this.editRoleFrom = val;
                this.dialogVisibleRoleEdit = true;
                if (this.$refs.editRoleFrom){
                    this.$refs.editRoleFrom.resetFields();
                }
            },
            // 删除角色按钮
            roleDel(roleID) {
                this.$confirm('此操作将永久删除该角色组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.roleConfigDeleteRole(roleID);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },

            // roleConfig(id) {
            //     this.userConfigQueryPermissionByUserRoleID(id);
            // },
            handleCurrentChangeDataRole(val) {
                console.log(val);
                this.currentRoleID = val.roleID;
                this.permissionByUserRole = [];
                this.pageByUserRole = [];
                this.readonlyRole = true;
                this.userConfigQueryPermissionByUserRoleID(val.roleID); //根据角色ID查询系统权限
                this.userConfigQueryPageByUserRoleID(val.roleID); // 根据角色ID查询系统页面
            },
            // 循环展示控制
            roleTitle() {
                this.roleTitleStatus = !this.roleTitleStatus;
            },
            // 根据角色ID查询系统权限
            async userConfigQueryPermissionByUserRoleID(roleID) {
                var param = {
                    // roleID: 'role_f0e3ab72-f421'
                    roleID: roleID
                }
                const res = await userConfigQueryPermissionByUserRoleID(param);
                if (res.data) {
                    this.permissionByUserRole = res.data.permission;
                }
                // this.permissionByUserRole = res.data.permission;
                // console.log(this.permissionByUserRole)
            },
            // 根据角色ID查询系统页面
            async userConfigQueryPageByUserRoleID(roleID) {
                this.pageByUserRole = [];
                var param = {
                    roleID: roleID
                }
                const res = await userConfigQueryPageByUserRoleID(param);
                if (res.data) {
                    this.pageByUserRole = res.data.pagevisibility;
                }
            },
            editPeizhiRule() {
                this.currentDataPermissionByUserRole = JSON.stringify(this.permissionByUserRole);
                this.currentDataPageByUserRole = JSON.stringify(this.pageByUserRole)

                this.readonlyRole = !this.readonlyRole;
            },
            // 绑定权限和角色
            async bandingPermissionAndRole() {
                var param = {
                    roleID: this.currentRoleID,
                    permission: {
                        permission: []
                    }
                }
                param.permission.permission = this.permissionByUserRole
                // this.permissionByUserRole
                // console.log(param) 绑定权限和角色
                const res = await userConfigBandingPermissionAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.readonlyRole = true;
                    this.userConfigQueryPermissionByUserRoleID(this.currentRoleID)
                } else {
                    this.$message.error(res.message);
                }
            },
            // 取消--权限和角色
            bandingPermissionAndRoleCancel() {
                console.log(JSON.parse(this.currentDataPermissionByUserRole));
                this.permissionByUserRole = JSON.parse(this.currentDataPermissionByUserRole);
                this.readonlyRole = true;
            },
            // // 解除绑定权限和角色---暂时没有
            // async unBandingPermissionAndRole() {
            //     console.log(JSON.parse(this.currentData))
            //     var param = {
            //         roleID: this.currentRoleID,
            //         permission: {
            //             permission: []
            //         }
            //     }
            //     param.permission.permission = JSON.parse(this.currentData);
            //     const res = await userConfigUnBandingPermissionAndRole(param);
            //     if (res && res.data && res.data.result) {
            //         this.$message({
            //             message: res.message,
            //             type: 'success'
            //         });
            //         this.readonlyRole = true;
            //         this.userConfigQueryPageByUserRoleID(this.currentRoleID);
            //     } else {
            //         this.$message.error(res.message);
            //     }
            // },
            // // 解绑页面和角色---暂时没有
            // async unBandingPageAndRole() {
            //     console.log(JSON.parse(this.currentData))
            //     var param = {
            //         roleID: this.currentRoleID,
            //         permission: {
            //             permission: []
            //         }
            //     }
            //     param.permission.permission = JSON.parse(this.currentData);
            //     const res = await userConfigUnBandingPageAndRole(param);
            //     if (res && res.data && res.data.result) {
            //         this.$message({
            //             message: res.message,
            //             type: 'success'
            //         });
            //         this.readonlyRole = true;
            //         this.userConfigQueryPageByUserRoleID(this.currentRoleID);
            //     } else {
            //         this.$message.error(res.message);
            //     }
            // },
            //  绑定页面和角色
            async bandingPageAndRole() {
                var param = {
                    roleID: this.currentRoleID,
                    pageVisibility: {
                        pagevisibility: []
                    }
                }
                param.pageVisibility.pagevisibility = this.pageByUserRole
                // console.log(param) 绑定页面和角色
                const res = await userConfigBandingPageAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.readonlyRole = true;
                    this.userConfigQueryPageByUserRoleID(this.currentRoleID);
                } else {
                    this.$message.error(res.message);
                }
            },
            // 取消-绑定页面和角色
            bandingPageAndRoleCancel() {
                console.log(JSON.parse(this.currentDataPageByUserRole));
                this.pageByUserRole = JSON.parse(this.currentDataPageByUserRole);
                this.readonlyRole = true;
            },

            // 角色管理接口--结束-linxinsen

            // =----------------组织接口--开始
            //组织模块-使用的相关方法
            //author：刘勇文

            //功能 1：查询组织
            //1.1 查询组织的方法
            async userConfigOrganizationSelect() {
                //封装参数
                var param = {};

                //调用接口
                const selectRes = await userConfigOrganizationSelect(param);

                //处理返回值，将返回值装载到查询组织所用的数组中
                if (selectRes) { //如果有返回值
                    this.organizationArray = selectRes.data;
                    // organizationArrayRef
                    if (this.organizationArray.length != 0) {
                        this.$refs['organizationArrayRef'].setCurrentRow(this.organizationArray[0]);
                    }
                }
                //转换时间格式
                // for (var i = 0; i < this.organizationArray.length; i++) {
                //     let data = this.organizationArray[i].createDate;
                //     this.organizationArray[i].createDate = this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
                // }
            },

            //功能 2：组织配置信息详情展示
            //2.1 点击左侧列表中每一个‘组织’模块时触发的方法
            showOrganizationDetails(val) {
                console.log(val)
                this.showDetailOrganizationID = val.id;
                // console.log(this.showDetailOrganizationID)
                this.departmentAndMeterRoleDataByOrganizationID = [];
                this.otherMeterRoleDataByOrganization = [];
                //调用 根据组织ID查询系统角色 方法
                this.userConfigQueryRoleByUserOrganizationID();

                //调用 根据组织ID查询当前组织关联的角色 方法
                this.userConfigQueryOrganizationCorrelationRole();
            },
            //2.2 根据组织ID查询当前系统的角色的方法
            async userConfigQueryRoleByUserOrganizationID() {

                //封装参数
                var param = {
                    organizationID: this.showDetailOrganizationID,
                };

                //调用接口
                const selectRes = await userConfigQueryRoleByUserOrganizationID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentAndMeterRoleDataByOrganizationID = selectRes.data;
                }
            },
            //根据组织ID查询当前组织关联的角色 方法
            async userConfigQueryOrganizationCorrelationRole() {

                //封装参数
                var param = {
                    organizationID: this.showDetailOrganizationID,
                };

                //调用接口
                const selectRes = await userConfigQueryOrganizationCorrelationRole(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.otherMeterRoleDataByOrganization = selectRes.data;
                }
            },

            //功能 3：绑定和解绑组织和角色
            //3.1 点击左侧‘编辑’按钮时触发的方法
            organizationEdit(data) {

                //初始化绑定和解绑组织和角色 弹框中所使用的数据
                this.showDetailOrganizationID = data.id;
                // 规定弹框的标题
                this.titleNameDialog = data.name;

                //调用 根据组织ID查询当前系统的角色方法
                this.userConfigQueryRoleByUserOrganizationID();

                //调用 根据组织ID查询当前组织关联的角色方法
                this.userConfigQueryOrganizationCorrelationRole();

                //绑定和解绑
                this.bandOrganizationID_Organization = data.id;
                this.unbandOrganizationID_Organization = data.id;

                //打开绑定和解绑组织和角色 弹框
                this.dialogVisibleOrganizationEdit = true;
            },

            //3.2 选择要绑定的角色ID
            selectBandRoleID_Organization(role) {

                var temp = [];
                for (var i = 0; i < role.length; i++) {
                    temp.push(role[i].id);
                }

                this.bandRoleID_Organization = temp.join(",");
            },

            //3.3 点击绑定组织和角色‘向右箭头’时触发的方法
            bandRole_Organization() {

                //调用绑定组织和角色的方法
                this.userConfigBandingOrganizationAndRole();
            },

            //3.4 绑定组织和角色的方法
            async userConfigBandingOrganizationAndRole() {


                //封装参数
                var param = {
                    organizationID: this.bandOrganizationID_Organization,
                    roleID: this.bandRoleID_Organization,
                };

                //调用接口
                const res = await userConfigBandingOrganizationAndRole(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据组织ID查询当前系统角色方法 刷新绑定之后的页面数据
                    this.userConfigQueryRoleByUserOrganizationID();
                    //调用根据组织ID查询当前组织关联的角色的方法 刷新绑定之后的页面数据
                    this.userConfigQueryOrganizationCorrelationRole();

                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //3.5 选择要解绑的角色ID
            selectUnbandRoleID_Organization(role) {

                var temp = [];
                for (var i = 0; i < role.length; i++) {
                    temp.push(role[i].id);
                }

                this.unbandRoleID_Organization = temp.join(",");
                console.log(this.unbandRoleID_Organization)
            },

            //3.6 点击解绑组织和角色‘向右箭头’时触发的方法
            unbandRole_Organization() {

                //调用解绑组织和角色的方法
                this.userConfigUnBandingOrganizationAndRole();
            },

            //3.7 解绑组织和角色的方法
            async userConfigUnBandingOrganizationAndRole() {

                //封装参数
                var param = {
                    organizationID: this.unbandOrganizationID_Organization,
                    roleID: this.unbandRoleID_Organization,
                };

                //调用接口
                const res = await userConfigUnBandingOrganizationAndRole(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据组织ID查询当前系统角色方法 刷新绑定之后的页面数据
                    this.userConfigQueryRoleByUserOrganizationID();
                    //调用根据组织ID查询当前组织关联的角色的方法 刷新绑定之后的页面数据
                    this.userConfigQueryOrganizationCorrelationRole();

                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },



            // ------------------组织接口--结束

            // 职位-开始-linxinsen
            // 职位列表查询
            async userConfigJobSelect() {
                var param = {}
                const res = await userConfigJobSelect(param);
                if (res && res.data && res.data.rows) {
                    this.jobArray = res.data.rows;
                    // jobArrayRef
                    if (this.jobArray.length != 0) {
                        this.$refs['jobArrayRef'].setCurrentRow(this.jobArray[0]);
                    }
                    // this.selectedJobID = res.data.rows[0].jobID; // 右侧默认展示第一个职位的职位角色配置关系
                    // this.userConfigQueryRoleByUserJobID();
                    // this.userConfigQueryJobCorrelationRole();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 左侧角色列表选中行数据改变
            changeCurrentJobData(val) {
                this.selectedJobID = val.jobID;
                this.jobNotHaveRoleList = [];
                this.jobHaveRoleList = [];
                this.userConfigQueryRoleByUserJobID();
                this.userConfigQueryJobCorrelationRole();
            },
            // 点击编辑职位按钮，打开编辑职位弹框
            jobEdit(val) {
                this.editJobFrom = val;
                this.dialogVisibleJobEdit = true;
            },
            // 在编辑职位页面点击“确定”按钮，调用更新职位接口
            onEditJob() {
                this.roleConfigUpdateJob();
            },
            // 更新职位
            async roleConfigUpdateJob() {
                var param = {
                    jobID: this.editJobFrom.jobID,
                    jobName: this.editJobFrom.jobName,
                    description: this.editJobFrom.description,
                    upJobID: this.editJobFrom.upJobID,
                    upDepartmentID: this.editJobFrom.upDepartmentID,
                    departmentID: this.editJobFrom.departmentID
                }
                const res = await roleConfigUpdateJob(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.dialogVisibleJobEdit = false;
                    this.userConfigJobSelect();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 删除职位按钮
            jobDel(jobID) {
                this.$confirm('此操作将永久删除该角色组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.roleConfigDeleteJob(jobID);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 删除职位
            async roleConfigDeleteJob(jobID) {
                var param = {
                    jobID: jobID
                }
                const res = await roleConfigDeleteJob(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigJobSelect();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 点击“添加职位”按钮，打开添加职位弹出框
            addJob() {
                this.dialogVisibleJobAdd = true;
            },
            // 在“添加职位”页面点“确定”按钮，调用添加职位接口 
            onAddJob() {
                this.roleConfigCreateJob();
            },
            // 添加职位
            async roleConfigCreateJob() {
                var param = this.addJobFrom;
                const res = await roleConfigCreateJob(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.dialogVisibleJobAdd = false;
                    this.userConfigJobSelect();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 根据职位ID查询系统角色
            async userConfigQueryRoleByUserJobID() {
                var param = {
                    jobID: this.selectedJobID
                }
                const res = await userConfigQueryRoleByUserJobID(param);
                if (res && res.data) {
                    this.jobNotHaveRoleList = res.data
                }
            },
            // 查询职位关联的角色
            async userConfigQueryJobCorrelationRole() {
                this.jobHaveRoleList = [];
                var param = {
                    jobID: this.selectedJobID
                }
                const res = await userConfigQueryJobCorrelationRole(param);
                if (res && res.data) {
                    this.jobHaveRoleList = res.data
                }
            },
            // 职位编辑页面，选中的左侧角色数据
            checkLeftJobRole(val) {
                this.leftSelectJobRole = val;
            },
            // 职位编辑页面，选中的右侧角色数据
            checkRightJobRole(val) {
                this.rightSelectJobRole = val;
            },
            // 职位编辑页面，点击“→”按钮，绑定职位和角色
            bandingJobAndRole() {
                this.userConfigBandingJobAndRole();
            },
            // 职位编辑页面，点击“←”按钮，解绑职位和角色
            unBandingJobAndRole() {
                this.userConfigUnBandingJobAndRole();
            },
            // 解绑职位和角色
            async userConfigUnBandingJobAndRole() {
                let roleIDList = [];
                this.rightSelectJobRole.forEach(element => {
                    roleIDList.push(element.id);
                });
                var param = {
                    jobID: this.selectedJobID,
                    roleID: roleIDList.join(',')
                };
                const res = await userConfigUnBandingJobAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRoleByUserJobID();
                    this.userConfigQueryJobCorrelationRole();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 绑定职位和角色
            async userConfigBandingJobAndRole() {
                let roleIDList = [];
                this.leftSelectJobRole.forEach(element => {
                    roleIDList.push(element.id);
                });
                var param = {
                    jobID: this.selectedJobID,
                    roleID: roleIDList.join(',')
                };
                const res = await userConfigBandingJobAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRoleByUserJobID();
                    this.userConfigQueryJobCorrelationRole();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 职位-结束-linxinsen

            // 部门-开始-linxinsen
            // 部门列表查询
            async userConfigDepartmentSelectTab() {
                var param = {}
                const res = await userConfigDepartmentSelect(param);
                if (res && res.data && res.data.rows) {
                    this.departmentArray = res.data.rows;
                    // departmentArrayRef
                    if (this.departmentArray.length != 0) {
                        this.$refs['departmentArrayRef'].setCurrentRow(this.departmentArray[0]);
                    }
                    // this.selectedDepartmentID = res.data.rows[0].departmentID; // 右侧默认展示第一个部门的部门角色配置关系
                    // this.userConfigQueryRoleByUserDepartmentID();
                    // this.userConfigQueryDepartmentCorrelationRole();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 左侧角色列表选中行数据改变
            changeCurrentDepartmentData(val) {
                this.selectedDepartmentID = val.departmentID;
                this.departmentNotHaveRoleList = [];
                this.departmentHaveRoleList = [];
                this.userConfigQueryRoleByUserDepartmentID();
                this.userConfigQueryDepartmentCorrelationRole();
            },
            // 点击编辑部门按钮，打开编辑部门弹框
            departmentEdit(val) {
                this.editDepartmentFrom = val;
                this.dialogVisibleDepartmentEdit = true;
            },
            // 在编辑部门页面点击“确定”按钮，调用更新部门接口
            onEditDepartment() {
                this.roleConfigUpdateDepartment();
            },
            // 更新部门
            async roleConfigUpdateDepartment() {
                var param = {
                    departmentID: this.editDepartmentFrom.departmentID,
                    departmentName: this.editDepartmentFrom.departmentName,
                    description: this.editDepartmentFrom.description,
                    upDepartmentID: this.editDepartmentFrom.upDepartmentID
                }
                const res = await roleConfigUpdateDepartment(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.dialogVisibleDepartmentEdit = false;
                    this.userConfigDepartmentSelectTab();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 删除部门按钮
            departmentDel(departmentID) {
                this.$confirm('此操作将永久删除该角色组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.roleConfigDeleteDepartment(departmentID);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 删除部门
            async roleConfigDeleteDepartment(departmentID) {
                var param = {
                    departmentID: departmentID
                }
                const res = await roleConfigDeleteDepartment(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigDepartmentSelectTab();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 点击“添加部门”按钮，打开添加部门弹出框
            addDepartment() {
                this.dialogVisibleDepartmentAdd = true;
            },
            // 在“添加部门”页面点“确定”按钮，调用添加部门接口 
            onAddDepartment() {
                this.roleConfigCreateDepartment();
            },
            // 添加部门
            async roleConfigCreateDepartment() {
                console.log(this.addDepartmentFrom)
                var param = this.addDepartmentFrom;
                const res = await roleConfigCreateDepartment(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.dialogVisibleDepartmentAdd = false;
                    this.userConfigDepartmentSelectTab();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 根据部门ID查询系统角色
            async userConfigQueryRoleByUserDepartmentID() {
                var param = {
                    departmentID: this.selectedDepartmentID
                }
                const res = await userConfigQueryRoleByUserDepartmentID(param);
                if (res && res.data) {
                    this.departmentNotHaveRoleList = res.data
                }
            },
            // 查询部门关联的角色
            async userConfigQueryDepartmentCorrelationRole() {
                this.departmentHaveRoleList = [];
                var param = {
                    departmentID: this.selectedDepartmentID
                }
                const res = await userConfigQueryDepartmentCorrelationRole(param);
                if (res && res.data) {
                    this.departmentHaveRoleList = res.data
                }
            },
            // 部门编辑页面，选中的左侧角色数据
            checkLeftDepartmentRole(val) {
                this.leftSelectDepartmentRole = val;
            },
            // 部门编辑页面，选中的右侧角色数据
            checkRightDepartmentRole(val) {
                this.rightSelectDepartmentRole = val;
            },
            // 部门编辑页面，点击“→”按钮，绑定部门和角色
            bandingDepartmentAndRole() {
                this.userConfigBandingDepartmentAndRole();
            },
            // 部门编辑页面，点击“←”按钮，解绑部门和角色
            unBandingDepartmentAndRole() {
                this.userConfigUnBandingDepartmentAndRole();
            },
            // 解绑部门和角色
            async userConfigUnBandingDepartmentAndRole() {
                let roleIDList = [];
                this.rightSelectDepartmentRole.forEach(element => {
                    roleIDList.push(element.id);
                });
                var param = {
                    departmentID: this.selectedDepartmentID,
                    roleID: roleIDList.join(',')
                };
                console.log(param)
                const res = await userConfigUnBandingDepartmentAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRoleByUserDepartmentID();
                    this.userConfigQueryDepartmentCorrelationRole();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 绑定部门和角色
            async userConfigBandingDepartmentAndRole() {
                let roleIDList = [];
                this.leftSelectDepartmentRole.forEach(element => {
                    roleIDList.push(element.id);
                });
                var param = {
                    departmentID: this.selectedDepartmentID,
                    roleID: roleIDList.join(',')
                };
                const res = await userConfigBandingDepartmentAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRoleByUserDepartmentID();
                    this.userConfigQueryDepartmentCorrelationRole();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 部门-结束-linxinsen


            // 部门职位--开始
            //部门职位模块-使用的方法
            //author：刘勇文

            //功能1：部门选择下下拉框
            //1.1 部门选择框方法
            //此方法 页面一加载就立即调用
            async userConfigDepartmentSelectTab2() {

                //将部门选择下拉框中使用的数组 初始化
                this.departmentSelectList = [];
                //封装参数
                var param = {};
                //调用接口
                const selectRes = await userConfigDepartmentSelect();
                //处理返回值
                if (selectRes) { //如果有返回值
                    //console.log('这是部门选择下拉框')
                    //console.log(selectRes)
                    this.departmentSelectList = selectRes.data.rows;
                    if (this.departmentSelectList.length != 0) {
                        this.departmentID_departmentJob = selectRes.data.rows[0].departmentID;
                        this.userConfigDepartmentJobSelect();
                    }
                }
            },

            //功能2：部门职位选择框
            //2.1 选中部门ID时触发的方法 
            queryDepartmentJob() {
                this.departmentJobArray = [];
                this.departmentAndMeterOrganizationDataByDepartmentJob = [];
                this.otherMeterOrganizationDataByDepartmentJob = [];
                //调用查询部门职位的方法 查询选中的部门的部门职位
                this.userConfigDepartmentJobSelect();
            },
            //2.2 查询部门职位的方法
            async userConfigDepartmentJobSelect() {
                //封装参数
                var param = {
                    departmentID: this.departmentID_departmentJob,
                };
                //调用接口
                const selectRes = await userConfigDepartmentJobSelect(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentJobArray = selectRes.data;
                    console.log('这是部门职位')
                    console.log(this.departmentJobArray)
                    // departmentJobArrayRef
                    if (this.departmentJobArray.length != 0) {
                        this.$refs['departmentJobArrayRef'].setCurrentRow(this.departmentJobArray[0]);
                    }
                }
            },

            //功能3：部门职位-组织配置信息详情展示
            //3.1 点击左侧表中每一个‘部门职位’模块时触发的方法
            showDepartmentJobDetails(temp) {
                this.departmentAndMeterOrganizationDataByDepartmentJob = [];
                this.otherMeterOrganizationDataByDepartmentJob = [];
                if (temp != null) {
                    console.log(temp)
                    this.showDetailDepartmentID = this.departmentID_departmentJob;
                    this.showDetailJobID = temp.jobID;

                    //调用根据部门职位ID查询系统的组织 方法
                    this.userConfigQueryOrganizationByUserDepartmentJobID();

                    //调用根据部门职位ID查询当前部门职位关联的组织 方法
                    this.userConfigQueryDepartmentJobCorrelationOrganization();

                    temp = null;
                }
            },
            //3.2 根据部门职位ID查询系统的组织 的 方法
            async userConfigQueryOrganizationByUserDepartmentJobID() {

                //封装参数
                var param = {
                    jobID: this.showDetailJobID,
                    departmentID: this.showDetailDepartmentID,
                };

                //调用接口
                const selectRes = await userConfigQueryOrganizationByUserDepartmentJobID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    //console.log(selectRes.data)
                    this.departmentAndMeterOrganizationDataByDepartmentJob = selectRes.data;
                    console.log(this.departmentAndMeterOrganizationDataByDepartmentJob)
                }
            },
            //3.3 根据部门职位ID查询当前部门职位关联的组织 的 方法
            async userConfigQueryDepartmentJobCorrelationOrganization() {

                //封装参数
                var param = {
                    jobID: this.showDetailJobID,
                    departmentID: this.showDetailDepartmentID,
                };

                //调用接口
                const selectRes = await userConfigQueryDepartmentJobCorrelationOrganization(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    console.log(selectRes)
                    this.otherMeterOrganizationDataByDepartmentJob = selectRes.data;
                }
            },

            //功能 4：绑定和解绑部门职位和组织
            //4.1 点击左侧‘编辑’按钮时触发的方法
            departmentJobEdit(data) {

                console.log(data.jobID)
                //初始化绑定和解绑 部门职位和组织 弹框中使用的数据
                this.showDetailJobID = data.jobID;

 // 规定弹框的标题
                this.titleNameDialog = data.jobName;

                //调用根据部门职位ID查询系统的组织 方法
                this.userConfigQueryOrganizationByUserDepartmentJobID();
                //调用根据部门职位ID查询当前部门职位关联的组织 方法
                this.userConfigQueryDepartmentJobCorrelationOrganization();

                //绑定和解绑
                this.bandDepartmentID_DepartmentJob = this.departmentID_departmentJob;
                this.bandJobID_DepartmentJob = data.jobID;

                this.unbandDepartmentID_DepartmentJob = this.departmentID_departmentJob;
                this.unbandJobID_DepartmentJob = data.jobID;

                //
                this.dialogVisibleDepartmentJobEdit = true;
            },
            //4.2 选择要绑定的组织ID
            selectBandOrganizationID_DepartmentJob(organization) {

                var temp = [];
                for (var i = 0; i < organization.length; i++) {
                    temp.push(organization[i].id);
                }

                this.bandOrganizationID_DepartmentJob = temp.join(",");
            },
            //4.3 点击绑定部门职位和组织‘向右箭头’时触发的方法
            bandOrganization_DepartmentJob() {

                this.userConfigBandingDepartmentJobAndOrganization();
            },
            //4.4 绑定部门职位和组织的方法
            async userConfigBandingDepartmentJobAndOrganization() {

                var param = {
                    jobID: this.bandJobID_DepartmentJob,
                    departmentID: this.bandDepartmentID_DepartmentJob,
                    organizationID: this.bandOrganizationID_DepartmentJob,
                };

                const res = await userConfigBandingDepartmentJobAndOrganization(param);

                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据部门职位ID查询系统的组织 方法
                    this.userConfigQueryOrganizationByUserDepartmentJobID();
                    //调用根据部门职位ID查询当前部门职位关联的组织 方法
                    this.userConfigQueryDepartmentJobCorrelationOrganization();

                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //4.5 选择要解绑的组织ID
            selectUnbandOrganizationID_DepartmentJob(organization) {

                var temp = [];
                for (var i = 0; i < organization.length; i++) {
                    temp.push(organization[i].id);
                }

                this.unbandOrganizationID_DepartmentJob = temp.join(",");
            },
            //4.6 点击解绑部门职位和组织‘向左箭头’时触发的方法
            unbandOrganization_DepartmentJob() {

                this.userConfigUnBandingDepartmentJobAndOrganization();
            },
            //4.7 解绑部门职位和组织的方法
            async userConfigUnBandingDepartmentJobAndOrganization() {

                var param = {
                    jobID: this.unbandJobID_DepartmentJob,
                    departmentID: this.unbandDepartmentID_DepartmentJob,
                    organizationID: this.unbandOrganizationID_DepartmentJob,
                }

                const res = await userConfigUnBandingDepartmentJobAndOrganization(param);

                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据部门职位ID查询系统的组织 方法
                    this.userConfigQueryOrganizationByUserDepartmentJobID();
                    //调用根据部门职位ID查询当前部门职位关联的组织 方法
                    this.userConfigQueryDepartmentJobCorrelationOrganization();

                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            // 部门职位--结束

            changeTab(id) {
                console.log(id)
                this.tabIndex = id
                this.currentPage = 1;
                this.departmentArrayValue = [];
                this.departmentID_departmentJob = '';
                this.readonlyRole = true;
                if (this.tabIndex == 0) { // 用户管理
                 this.queryList = [];
                    this.userConfigUserList();
                    this.userConfigDepartmentSelect();
                    this.departmentArrayValue = [];
                }
                if (this.tabIndex == 1) {
                    // //权限管理-用户组
                    //author：刘勇文
                    this.roleGroupArray  = [];
                    this.userConfigUserGroupSelect();
                }
                if (this.tabIndex == 2) { // 角色管理
                this.newRoleList = [];
                    this.userConfigRoleSelect(); // 角色列表查询
                    // this.userConfigQueryPermissionByUserRoleID()
                }
                if (this.tabIndex == 3) { // 组织
                    //权限管理-组织
                    //author：刘勇文
                     this.organizationArray = [];
                    this.userConfigOrganizationSelect();
                }
                if (this.tabIndex == 4) { // 部门
                this.departmentArray = [];
                    this.userConfigDepartmentSelectTab();
                }

                if (this.tabIndex == 5) { // 职位
                this.jobArray = [];
                    this.userConfigJobSelect();
                }
                if (this.tabIndex == 6) { // 部门职位
                    //权限管理-部门职位
                    //author：刘勇文
                    this.userConfigDepartmentSelectTab2();
                }
            },
            // handleSelectionChangeDay(val) {
            //     console.log(val)
            //     // console.log(val.join())
            //     this.multipleSelection = val;
            //     // console.log(this.multipleSelection.join(','))
            // },
            // // 用户-角色配置，绑定
            addUserRole() {
                var id = [];
                for (let i = 0; i < this.nowSelectData.length; i++) {
                    id.push(this.nowSelectData[i].id)
                    var idStr = id.join(",");
                }
                this.userConfigBandingUserAndRole(this.updateForm.userID, idStr);
            },
            // // 用户-角色配置，解绑
            removeUserRole() {
                var id = [];
                for (let i = 0; i < this.nowSelectRightData.length; i++) {
                    id.push(this.nowSelectRightData[i].id)
                    var idStr = id.join(",");
                    console.log(idStr)
                }
                this.userConfigUnBandingUserAndRole(this.updateForm.userID, idStr);
            },
            // 查询系统角色
            async userConfigQueryRole(userID) {
                var param = {
                    userID: userID,
                }
                const roleArrayRes = await userConfigQueryRole(param);
                if (roleArrayRes && roleArrayRes.data) {
                    this.userNotHaveRoleArray = roleArrayRes.data;
                } else {
                    // 若查询失败，将数组清空，页面展示“暂无数据”（防止查询失败时，展示的数据是上一个窗口的数据）
                    this.userNotHaveRoleArray = [];
                }
            },
            // 查询用户关联的角色
            async userConfigQueryUserCorrelationRole(userID) {
                var param = {
                    userID: userID,
                }
                const res = await userConfigQueryUserCorrelationRole(param);
                if (res && res.data) {
                    this.userHaveRoleArray = res.data;
                } else {
                    // 若查询失败，将数组清空，页面展示“暂无数据”（防止查询失败时，展示的数据是上一个窗口的数据）
                    this.userHaveRoleArray = [];
                }
            },
            // 绑定用户和角色
            async userConfigBandingUserAndRole(userID, id) {
                var param = {
                    userID: userID,
                    roleID: id
                }
                const res = await userConfigBandingUserAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRole(this.updateForm.userID);
                    this.userConfigQueryUserCorrelationRole(this.updateForm.userID);
                } else {
                    this.$message.error(res.message);
                }
            },
            // // 解绑用户和角色
            async userConfigUnBandingUserAndRole(userID, id) {
                var param = {
                    userID: userID,
                    roleID: id
                }
                const res = await userConfigUnBandingUserAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRole(this.updateForm.userID);
                    this.userConfigQueryUserCorrelationRole(this.updateForm.userID);
                } else {
                    this.$message.error(res.message);
                }
            },
            // // 查询系统角色组
            // async userConfigQueryRoleGroup() {
            //     var param = {
            //         roleID: 'role_zc',
            //         // userID: this.userID
            //     }
            //     const roleGroupRes = await userConfigQueryRoleGroup(param);
            //     this.roleGroupArray = roleGroupRes.data;
            // },
            // // 查询角色关联的角色组
            // async userConfigQueryRoleCorrelationRoleGroup() {
            //     var param = {
            //         roleID: 'role_zc',
            //         // userID: this.userID
            //     }
            //     const selectRes = await userConfigQueryRoleCorrelationRoleGroup(param);
            // },
            // // 绑定角色和角色组
            // async roleConfigBandingRoleGroupAndRole() {
            //     var param = {
            //         roleID: 'role_zc',
            //         roleGroupID: 'rolegroup_7971-bb9f-31e5'
            //     }
            //     const selectRes = await roleConfigBandingRoleGroupAndRole(param);
            // },
            // // 解绑角色和角色组
            // async roleConfigUnBandingRoleGroupAndRole() {
            //     var param = {
            //         roleID: 'role_zc',
            //         roleGroupID: 'rolegroup_aa'
            //     }
            //     const selectRes = await roleConfigUnBandingRoleGroupAndRole(param);
            // },
            // // 查询系统组织
            // async userConfigQueryOrganization() {
            //     var param = {
            //         roleID: 'role_zz',
            //         // userID: 'rolegroup_aa'
            //     }
            //     const selectRes = await userConfigQueryOrganization(param);
            // },
            // // 查询角色关联的组织
            // async userConfigQueryRoleCorrelationOrganization() {
            //     var param = {
            //         roleID: 'role_zz',
            //         // userID: 'rolegroup_aa'
            //     }
            //     const selectRes = await userConfigQueryRoleCorrelationOrganization(param);
            // },
            // // 绑定角色和组织
            // async roleConfigBandingOrganizationAndRole() {
            //     var param = {
            //         roleID: 'role_zz',
            //         organizationID: 'P7'
            //     }
            //     const selectRes = await roleConfigBandingOrganizationAndRole(param);
            // },
            // // 解绑角色和组织
            // async roleConfigUnBandingOrganizationAndRole() {
            //     var param = {
            //         roleID: 'role_zz',
            //         organizationID: 'WS16'
            //     }
            //     const selectRes = await roleConfigUnBandingOrganizationAndRole(param);
            // },
            // // 添加角色组
            // async roleConfigCreateRoleGroup() {
            //     var param = {
            //         roleGroupName: 'lxs测试新增角色组2',
            //         description: 'lxs测试新增角色组2',
            //         userID: this.userID
            //     }
            //     const selectRes = await roleConfigCreateRoleGroup(param);
            // },
            // // 更新角色组
            // async roleConfigUpdateRoleGroup() {
            //     var param = {
            //         roleGroupID: 'rolegroup_2fa9-dc5d-ddf2',
            //         roleGroupName: 'lxs测试新增角色组1-update',
            //         description: 'lxs测试新增角色组1-update'
            //     }
            //     const selectRes = await roleConfigUpdateRoleGroup(param);
            // },
            // // 删除角色组
            // async roleConfigDeleteRoleGroup() {
            //     var param = {
            //         roleGroupID: 'rolegroup_7bfb-91a0-c90c'
            //     }
            //     const selectRes = await roleConfigDeleteRoleGroup(param);
            // },
            // // 查询系统权限
            // async userConfigQueryPermission() {
            //     var param = {
            //         roleGroupID: 'rolegroup_zc',
            //         // userID: this.userID
            //     }
            //     const selectRes = await userConfigQueryPermission(param);
            // },
            // // 查询角色组关联的权限
            // async userConfigQueryRoleGroupCorrelationPermission() {
            //     var param = {
            //         roleGroupID: 'rolegroup_zc',
            //         // userID: this.userID
            //     }
            //     const selectRes = await userConfigQueryRoleGroupCorrelationPermission(param);
            // },
            // // 绑定角色组和权限
            // async roleConfigBandingPermissionAndRoleGroup() {
            //     var param = {
            //         roleGroupID: 'rolegroup_zc',
            //         permissionID: 'permission_724fcf22-121c'
            //     }
            //     const selectRes = await roleConfigBandingPermissionAndRoleGroup(param);
            // },
            // // 解绑角色组和权限
            // async roleConfigUnBandingPermissionAndRoleGroup() {
            //     var param = {
            //         roleGroupID: 'rolegroup_zc',
            //         permissionID: 'permission_zz'
            //     }
            //     const selectRes = await roleConfigUnBandingPermissionAndRoleGroup(param);
            // },
            // // 查询系统页面
            // async userConfigQueryPage() {
            //     var param = {
            //         roleGroupID: 'rolegroup_1',
            //         // userID: this.userID
            //     }
            //     const selectRes = await userConfigQueryPage(param);
            // },
            // // 查询角色组关联的页面
            // async userConfigQueryRoleGroupCorrelationPage() {
            //     var param = {
            //         roleGroupID: 'rolegroup_1',
            //         // userID: this.userID
            //     }
            //     const selectRes = await userConfigQueryRoleGroupCorrelationPage(param);
            // },
            // // 绑定角色组和页面
            // async roleConfigBandingPageAndRoleGroup() {
            //     var param = {
            //         roleGroupID: 'rolegroup_1',
            //         pageID: 'page_3'
            //     }
            //     const selectRes = await roleConfigBandingPageAndRoleGroup(param);
            // },
            // // 解绑角色组和页面
            // async roleConfigUnBandingPageAndRoleGroup() {
            //     var param = {
            //         roleGroupID: 'rolegroup_1',
            //         pageID: 'page_1'
            //     }
            //     const selectRes = await roleConfigUnBandingPageAndRoleGroup(param);
            // },

            // // 角色管理 -- 结束

            // //穿梭框-开始-----------
            tableHeaderColor({
                row,
                column,
                rowIndex,
                columnIndex
            }) { //改表头颜色
                if (rowIndex === 0) {
                    // return 'background-color: #F3F3F3;color: #666;'
                    return 'background-color: #E2F0FE;'
                }
            },

            // 左侧数据全选
            checkAll(val) {
                console.log(val)
                this.nowSelectData = val;
            },
            // 右侧数据全选
            checkRightAll(val) {
                console.log(val)
                this.nowSelectRightData = val;
            },
            // 移除
            handelSelect() {
                this.otherMeterData = this.handleConcatArr(this.otherMeterData, this.nowSelectData); //右侧数据=右侧数据+左侧选中的数据
                this.handleRemoveTabList(this.nowSelectData, this.departmentAndMeterData); //移除左侧选中的数据（左侧选中的数据,左侧数据）
                this.nowSelectData = []; //左侧选中数据为空
            },
            // 添加
            handleRemoveSelect() {
                this.departmentAndMeterData = this.handleConcatArr(this.departmentAndMeterData, this
                    .nowSelectRightData);
                this.handleRemoveTabList(this.nowSelectRightData, this.otherMeterData);
                this.nowSelectRightData = [];
            },
            //右侧数据=右侧数据+左侧选中的数据
            handleConcatArr(a, b) {
                let arr = [];
                arr = arr.concat(a, b);
                return arr; //arr=右侧数据+左侧选中的数据
                // console.log(arr);
            },
            //移除左侧选中的数据
            handleRemoveTabList(isNeedArr, originalArr) { //isNeedArr为左侧选中的数据，originalArr为左侧数据
                if (isNeedArr.length && originalArr.length) { //数据不为空
                    for (let i = 0; i < isNeedArr.length; i++) { //遍历左侧选中的数据
                        for (let k = 0; k < originalArr.length; k++) { //遍历左侧所有的数据
                            // if (isNeedArr[i]["id"] === originalArr[k]["id"]) {//如果左侧选中的数据=左侧其中的一个数据
                            if (isNeedArr[i]["meter_name"] === originalArr[k]["meter_name"]) {
                                originalArr.splice(k, 1); //将左侧所有数据中选中的那个数据删除
                                //splice可删除从 index 处开始的零个或多个元素,并且用参数列表中声明的一个或多个值来替换那些被删除的元素
                            }
                        }
                    }
                }
            },
            // //穿梭框-结束-------------

            // //筛选
            // //用户信息清单-分页筛选
            // // async userConfigUserList() {
            // //     //this.query.pageSize = this.pageSize;
            // //     //this.query.pageQuantity = this.pageQuantity;
            // //     //this.query.currentPage = this.currentPage;
            // //     //this.query.userID = this.userID;
            // //     var param = {
            // //         // query: this.query,
            // //         pageSize: this.pageSize,
            // //         pageNumber: this.currentPage
            // //     }
            // //     console.log(param)
            // //     const res = await userConfigUserList(param);
            // //     console.log(res)
            // //     this.queryList = res.data.rows;
            // //     this.pageQuantity = res.data.pageQuantity;
            // // },

            // roleGroupDel() {
            //     this.$confirm('此操作将永久删除该角色组, 是否继续?', '提示', {
            //         confirmButtonText: '确定',
            //         cancelButtonText: '取消',
            //         type: 'warning'
            //     }).then(() => {
            //         this.$message({
            //             type: 'success',
            //             message: '删除成功!'
            //         });
            //     }).catch(() => {
            //         this.$message({
            //             type: 'info',
            //             message: '已取消删除'
            //         });
            //     });
            // },
        }
    }
</script>
<style scoped>
    .el-icon-check:before {
        content: "\e6da";
    }
    .tab {
        width: 140px;
        background: #E6E6E6;
        color: #1A1A1A;
        margin-right: 2px;
    }

    .tab_active {
        background: #009BFD;
        color: #FFFFFF;
    }

    .select_btn {
        width: 120px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .check_btn {
        background-color: #EBEBEB;
        border-color: #EBEBEB;
    }

    .check_btn:hover {
        background-color: #009BFD;
        border-color: #009BFD;
        color: #FFFFFF;
    }

    .width_40 {
        width: 40px;
    }

    .addBtn {
        width: 114px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .shuttleBox {
        height: 320px;
    }
</style>
<style lang="less" scoped>
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 170px;
    }

    ::v-deep .el-input__suffix {
        display: flex;
        align-content: center;
    }

    ::v-deep .el-input__suffix-inner {
        display: flex;
        align-content: center;
    }

    ::v-deep .el-card__header {
        background-color: #E2F0FE;
        height: 40px;
        display: flex;
        align-items: center;
    }

    ::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label {
        display: flex;
    }

    /* // ::v-deep .el-checkbox__input {
    //     display: flex;
    // } */

    ::v-deep .el-transfer-panel {
        width: 250px;
    }

    ::v-deep .el-form-item {
        margin-bottom: 12px;
    }

    /* //  */
    ::v-deep .el-transfer {
        display: flex;
    }

    ::v-deep .el-transfer__buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    ::v-deep .el-button+.el-button {
        margin: 0px;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }

    ::v-deep .el-popover__reference-wrapper {
        display: flex;
        align-items: center;
    }
</style>