<!-- KPI-分厂查询 -->
<template>
    <div class="page_container">
        <div class="alarmAnalysisHeader pdr30 flex justify_start align_center">
            分厂KPI分析
        </div>
        <div class="flex flex_column bgc_FFFFFF pdr30">
            <!-- select下拉框 -->
            <div class="flex sectionSelect justify_between">
                <div class="flex">
                    <el-select v-model="pIDValue" placeholder="分厂选择" class="alarmQuerySelect mg_right_10">
                        <el-option v-for="(item,index) in plantList" :key="index" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="dtValue" placeholder="时间维度" class="alarmQuerySelect mg_right_10">
                        <el-option v-for="item in dataFrame" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-date-picker v-model="value2" class="mg_right_10" type="datetimerange" align="right"
                        start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00', '08:00:00']
                        ">
                    </el-date-picker>
                    <el-button type="primary" @click="queryTest" class="alarmQuerySelectButton flex align_center justify_center">查询
                    </el-button>
                </div>
                <div>
                    <el-button type="primary" class="alarmQuerySelectButton flex align_center justify_center">导出
                    </el-button>
                </div>
            </div>
            <!-- table -->
            <div class="flex flex_column alarmQueryMargin fn_size14" >
                <el-table ref="multipleTable" :data="workShopSelect.slice((currentPage - 1) * pageSize, currentPage * pageSize)" tooltip-effect="dark" class="width_100"
                    @selection-change="handleSelectionChange" max-height="240px">
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column type="index" label="序号" width=""></el-table-column>
                    <el-table-column prop="workShopName" label="车间" width=""></el-table-column>
                    <el-table-column prop="indexCycle" label="指标周期" width="200px"></el-table-column>
                    <el-table-column prop="PowerOnRate" label="开机率" width=""></el-table-column>
                    <el-table-column prop="CurrentPlanOutput" label="计划产量" width=""></el-table-column>
                    <el-table-column prop="CurrentActualOutput" label="实际产量" width=""></el-table-column>
                    <el-table-column prop="CurrentQualifiedOutput" label="合格产量" width=""></el-table-column>
                    <el-table-column prop="checkRate" label="点检达成率" width=""></el-table-column>
                    <el-table-column prop="maintainRate" label="保养达成率" width=""></el-table-column>
                    <el-table-column prop="repairRate" label="维修及时率" width=""></el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_between align_start alarmQueryMargin">
                <div></div>
                <el-pagination 
                    :page-sizes="[5,10,100]" 
                    :page-size="pageSize"
                    :total="workShopSelect.length" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange" 
                    layout="prev, pager, next" ></el-pagination>
            </div>
            <!-- select -->
            <el-select v-model="dtValue" placeholder="单选指标" class="alarmQuerySelect alarmQueryMargin">
                <el-option v-for="item in dataFrame" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <!-- echarts图 -->
            <div class="diy_div">
                <div id="chartColumn" class="width_100 height_100"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import {
        // kpiAnalysisWorkShopAnalysis,
        // kpiAnalysisWorkShopAnalysisTrend
    }
    from "@/api/api_deviceManagement";

    export default {
        data() {
            return {
                value: "",
                value1:'',
                value2:[new Date('2021-05-01'),new Date('2021-05-31')],
                plantList:[{
                    value:"1",
                    label:"分厂1",
                },{
                    value:"2",
                    label:"分厂2",
                },{
                    value:"3",
                    label:"分厂3",
                },{
                    value:"4",
                    label:"分厂4",
                },{
                    value:"5",
                    label:"分厂5",
                },{
                    value:"6",
                    label:"分厂6",
                }],
                dataFrame: [{
                    value: "1",
                    label: "日",
                },
                {
                    value: "2",
                    label: "月",
                }],
                pageSize: 10,
                currentPage: 1,
                multipleSelection: [],
                workShopSelect:[],
                workShopSelectTrend:[],
                time:'',
                workShopName:'',
                dateType:'',
                indexCycle: "",
                tkValue:"1",
                wsIDValue:"1",
                dtValue:"1",
                pIDValue:"1",
            };
        },
        mounted() {
            this.kpiAnalysisWorkShopAnalysis()
            this.kpiAnalysisWorkShopAnalysisTrend()
        },
        methods: { 
            queryTest(){
                this.kpiAnalysisWorkShopAnalysis()
            },
            async kpiAnalysisWorkShopAnalysis() {
                this.workShopSelect = [];
                var param = {
                    plantID: this.pIDValue,
                    startDate:  this.value2[0],
                    endDate: this.value2[1],
                    dateType: this.dtValue,
                };
                const selectRes = await kpiAnalysisWorkShopAnalysis(param);
                this.workShopSelect = selectRes.data;
                
            },
            async kpiAnalysisWorkShopAnalysisTrend() {
                var workShopSelectTrend=[]
                var param = {
                    plantID: this.pIDValue,
                    typeKpi: this.tkValue,
                    startDate: this.value2[0],
                    endDate: this.value2[1],
                    dateType: this.dtValue,
                };
                const selectRes = await kpiAnalysisWorkShopAnalysisTrend(param);
                var workShopSelectTrend = selectRes.data
                var line = {
                    legend: {
                        data: [],
                        bottom: '2%',
                        textStyle: {
                            color: '#808080',
                            fontSize: 12,
                            rotate: 0,
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: [],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '##E3E4E6',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#808080',
                            fontSize: 12,
                        }  
                    },
                    series:[]
                };
                // line.xAxis.data = workShopSelectTrend[0].time;
                console.log(workShopSelectTrend);
                for (var i = 0; i < workShopSelectTrend.length; i++) {
                    var curObj = {
                        type: 'bar',
                        barGap: 0,
                        barWidth: 10, //柱的宽度
                        smooth: true,
                    };
                    curObj.name = workShopSelectTrend[i].workShopName,
                    curObj.data = workShopSelectTrend[i].currentPlanOutput,
                    line.series[i] = curObj
                    line.legend.data[i]=workShopSelectTrend[i].workShopName

                }
                this.drawShape(line);
            },

            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            drawShape(line) {
                /**数据集柱状图 */
                this.chartColumn = echarts.init(document.getElementById("chartColumn"));
                this.chartColumn.setOption({
                    title: {},
                    // 提示框
                    tooltip: {},
                    // legend: line.legend,"产线17           "
                    color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
                    legend: line.legend,
                    grid: {
                        //设置中间内容距离div的距离，也就是内边距
                        left: "3%",
                        right: "3%",
                        bottom: "10%",
                        containLabel: true,
                        /* width: 'auto', height: 'auto', */
                    },
                    xAxis: line.xAxis,
                    
                    yAxis: {
                        // type
                        min: 0, //y轴的最小值
                        max: 30, //y轴最大值
                        interval: 10, //值之间的间隔
                    },
                    series: line.series,
                });
            },  

            handleCurrentChange:function(cpage){
                this.currentPage = cpage;
            },

            handleSizeChange:function(psize){
                this.pageSize = psize;
            }
        },
    };
</script>

<style scoped>
    .alarmAnalysisHeader {
        height: 40px;
        padding-left: 20px;
        background-color: #e2f0fe;
        border: 1px solid #ccdbe5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .alarmAnalysisHeader2 {
        width: 1798px;
        height: 40px;
        padding-left: 20px;
        background-color: #e2f0fe;
        border: 1px solid #ccdbe5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .alarmQuerySelect {
        width: 150px;
        height: 36px;
    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009bfd;
    }

    .alarmQueryMargin {
        margin-left: 31px;
        margin-right: 31px;
        /* margin-top: 2px; */
    }

    .sectionSelect {
        margin-left: 31px;
        margin-top: 19px;
        padding-bottom: 22px;
    }

    .diy_div {
        width: 100%;
        height: 450px;
    }
</style>