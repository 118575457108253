<template>
    <div class="page_container ">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">报警详情</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 pd_bottom_20">
            
            <div class=" flex justify_center align_center pdt_20 " style="text-align:left">
                
                <el-form :inline="true" :model="detailInline" label-width="170px" style="width: 100%;">
                    <el-form-item label="项目名称">
                        <el-input v-model="detailInline.projectID" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="机器名称">
                        <el-input v-model="detailInline.machineID" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="测点编号">
                        <el-input v-model="detailInline.sensorID" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item label="报警状态数值">
                        <el-input v-model="detailInline.alarmStatus" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item> -->
                    <el-form-item label="测点名称">
                        <el-input v-model="detailInline.sensor" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="报警状态">
                        <el-input v-model="detailInline.alarmRank" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="报警处理状态">
                        <el-input v-model="detailInline.procStatus" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="处理时间">
                        <el-input v-model="detailInline.gmtProc" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="处理结果" >
                        <el-input v-model="detailInline.procResult" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                </el-form>    
            </div>
             <div class=" flex justify_center align_center pdt_20 " style="text-align:left">
            <el-form :model="detailInline" class="" style="width: 100%;" label-width="170px" >
                     <el-form-item label="客户报警处理相关信息" >
                        <el-input style="width:93% " type="textarea" :rows="5" resize="none" v-model="detailInline.procDetail" placeholder="" class="width_180" disabled>
                        </el-input>
                         </el-form-item>
                     <el-form-item label="处理详情">
                        <el-input style="width:93% " type="textarea" :rows="5" resize="none" v-model="detailInline.procDetailImgs" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                </el-form> 
             </div>
        </div>
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40 ">
            <div class="pdl20 ">报警分析详情</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 pd_bottom_20" >
            <div class=" flex justify_center align_center ">
                <div style="width:95%">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_20" height="320"
                        style="width: 100%;" :default-sort="{prop: 'date', order: 'descending'}">
                        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                        <el-table-column type="index" label="序号" width="55"></el-table-column>
                        <el-table-column prop="featureFactor" label="特征指标" width="" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="alarmRank" label="报警状态" width="250"></el-table-column>
                        <el-table-column prop="currentValue" label="当前值" width=""></el-table-column>
                        <el-table-column prop="refValue" label="阈值" width=""></el-table-column>
                        <el-table-column prop="scale" label="超出比率" width=""></el-table-column>
                        <el-table-column prop="weightProportion" label="权重"></el-table-column>
                        <el-table-column prop="wiki" label="提醒建议"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
         <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40 ">
            <div class="pdl20 ">时序数据</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30" >
            <div class=" flex justify_center align_center ">
                <div class="" style="width:95%">
                        <el-table ref="multipleTable" :data="tabResData" tooltip-effect="dark" class="mg_top_20" height="530"
                            @row-click ="rowClick" style="width: 100%;" :default-sort="{prop: 'date', order: 'descending'}">
                            <el-table-column type="index" label="序号" width=""></el-table-column>
                            <el-table-column prop="sensorId" label="传感器ID" width="" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="algorithmIndex[1].RMS" label="有效值" width=""></el-table-column>
                            <el-table-column prop="samplingTime" label="采样时间" width=""></el-table-column>
                        </el-table>
                    
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
  
                </div> 
            </div>
        </div>
         <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40 ">
            <div class="pdl20 ">振动分析获取有效值趋势图</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30" >
             <div class="diy_div alarmQueryMargin " >
                <div id="chartColumn" class="width_100 height_75 border_b" ></div>
            </div>
        </div>
         <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40 ">
            <div class="pdl20 ">振动分析获取频域趋势图</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30" >
             <div class="diy_div alarmQueryMargin " >
                <div id="chartColumn1" class="width_100 height_75 border_b"></div>
            </div>
        </div>
         <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40 ">
            <div class="pdl20 ">振动分析获取温度趋势图</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30" >
            <div class="diy_div alarmQueryMargin " >
                <div id="chartColumn2" class="width_100 height_75 " ></div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';

    import innerTopNav from '../../../../components/innerTopNav'

    import {
        alarmDataQuery,
        advancedAnalysisToSensorDataQueryLimit,
        advancedAnalysisRMSTrend,
        advancedAnalysisFrequencyDomainTrend,
        advancedAnalysisTemperatureTrend
    } from '@/api/api_deviceManagement.js'
    export default {
        components: {
          innerTopNav
        },
        data() {
            return {
               
               tableData:[],
               ID:'',
               detailInline:{},
               dialogVisibleMaps:false,
               currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                mapsData:[],
                tabResData:[],
                tableList:[],
                detailStr:{},
                rows:[],
                RMSList:{},
                arr:[],
                timeList:[],
                arr1:[],
                timeList1:[],
                tempList:[],
                sensorID:null,
                toSensorDataID:null
            }
        },
        created(){

        },
        mounted() {
            // if (localStorage.getItem('userInfo') != null) {
            //     let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            //     this.userID = userInfo.userID;
            // }
            if (localStorage.getItem('alarmParam') != null) {
                this.rows = JSON.parse(localStorage.getItem('alarmParam'))
            };
            this.alarmDataQuery();
           
            
            // this.advancedAnalysisToSensorDataQueryLimit();
            // this.drawShape();
            // this.drawShape1();
            // this.drawShape2();
            // this.advancedAnalysisRMSTrend();
            // this.advancedAnalysisFrequencyDomainTrend();
            // this.advancedAnalysisTemperatureTrend();
        },

        methods: {
            async alarmDataQuery() {
                var param = {
                    ID: this.rows.ID
                }
                const selectRes = await alarmDataQuery(param);
                this.detailInline = selectRes.rows[0];
                if(this.detailInline.gmtProc){
                    this.detailInline.gmtProc = this.tools.formatDate(this.detailInline.gmtProc, 'yyyy-MM-dd hh:mm:ss'); 
                }
                if(this.detailInline.procResult == 0){
                    this.detailInline.procResult = ''
                }
                //处理结果为1时已处理，为2时未处理
                if(this.detailInline.procResult == 1){
                    this.detailInline.procResult = '已处理'
                }
                if(this.detailInline.procResult == 2){
                    this.detailInline.procResult = '未处理'
                }

                console.log(this.detailInline.gmtProc);
                var str = selectRes.rows[0].algorithmFactorDtos;
                this.tableData = JSON.parse(str).array;
                this.advancedAnalysisToSensorDataQueryLimit();
            },

            click(row){
                // console.log(row);
                // if(row.chartMaps){
                //     this.dialogVisibleMaps = true
                // };
    
            },

            
            //日期格式
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            
            confirm(){

            },

            //分页数据
            async advancedAnalysisToSensorDataQueryLimit(){
                // console.log(this.detailInline,'22');
                var param = {
                    sensor:this.detailInline.sensorID,
                    pageNumber:this.currentPage,
                    pageSize:this.pageSize
                }
                const res = await advancedAnalysisToSensorDataQueryLimit(param);
                this.pageQuantity = res.data.total;
                this.tabResData = res.data.list;
                for(let i = 0 ; i< this.tabResData.length ; i++){
                    this.tabResData[i].algorithmIndex = JSON.parse(this.tabResData[i].algorithmIndex);
                }
                if(this.sensorID==null){
                    this.sensorID = this.tabResData[0].sensorId
                };
                if(this.toSensorDataID==null){
                    this.toSensorDataID =this.tabResData[0].id
                }
                
                // console.log(this.sensorID,this.toSensorDataID);
                this.advancedAnalysisRMSTrend();
                this.advancedAnalysisFrequencyDomainTrend();
                this.advancedAnalysisTemperatureTrend();
                // console.log("aa",this.tabResData);
            },
            //分页点击
            handleCurrentChange(val) {
                this.currentPage = val;
                this.advancedAnalysisToSensorDataQueryLimit(); //查询table数据的方法
            },

            //有效值
            async advancedAnalysisRMSTrend(){
                var param = {
                    sensorID:this.sensorID,
                    toSensorDataID:this.toSensorDataID
                }
                const res = await advancedAnalysisRMSTrend(param);
                this.RMSList=res.data.responseGuiData.data;
                // console.log('r',this.RMSList);
                var arr =[];
                var time = [];
                // console.log(this.RMSList);
                for(let i=this.RMSList.length-1;i>=0;i--){
                    
                    arr.push(this.RMSList[i].RMS)
                    var a = this.tools.formatDate(this.RMSList[i].samplineTime,"yyyy-MM-dd hh:mm:ss") 
                    time.push(a)
                    
                }
                this.arr =arr;
                this.timeList = time;
                // console.log("rms",time);
                var line = {
                    xAxis:{
                        type: 'category',
                        data:this.timeList,
                        axisLabel: {
                            interval: 60,
                            color: '#808080',
                            fontSize: 12,
                            rotate: 60,
                        }
                    } ,
                    series: [{
                        data: this.arr,
                        type: 'line'
                    }]
                };
            //   console.log("line",line);
                this.drawShape(line)
                
            },
            drawShape(line) {
                /**数据集柱状图 */
                echarts.init(document.getElementById('chartColumn')).dispose(); //销毁echarts
                this.chartColumn = echarts.init(document.getElementById("chartColumn"));
                this.chartColumn.setOption({
                title: {},
                // 提示框
                tooltip: {},
                // legend: line.legend,"产线17           "
                color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
                // legend: line.legend,
                grid: {
                    //设置中间内容距离div的距离，也就是内边距
                    left: "1%",
                    right: "1%",
                    bottom: "5%",
                    containLabel: true,
                    /* width: 'auto', height: 'auto', */
                },
                dataZoom: [{
                    type: 'inside',
                    start: 0,
                    end: 100
                }, {
                    start: 0,
                    end: 1
                }],
                yAxis: {
                    type: 'value'
                },
                // xAxis: {
                //     type: 'category',
                //     data:line.xAxis
                // },
                
               
                
                xAxis: line.xAxis,

                // yAxis: {
                //     // type
                //     // min: 0, //y轴的最小值
                //     // max: 1000, //y轴最大值
                //     // interval: 100, //值之间的间隔
                // },
                series: line.series,
                });
            },

            //频域
            async advancedAnalysisFrequencyDomainTrend(){
                var param = {
                    sensorID:this.sensorID,
                    toSensorDataID:this.toSensorDataID
                }
                // replace(/^\"|\"$/g,'')[0]
                const res = await advancedAnalysisFrequencyDomainTrend(param);
                var array = JSON.parse(res.data.responseGuiData.PXo);
                var data = JSON.parse(res.data.responseGuiData.fo);
               
                var line1 = {
                    xAxis:{
                        type: 'category',
                        data:data,
                        axisLabel: {
                            interval: 300,
                            color: '#808080',
                            fontSize: 12,
                            rotate: 60,
                        }
                    },
                    series: [{
                        data: array,
                        type: 'line'
                    }]
                };
            //   console.log("line",line);
                this.drawShape1(line1)
                
            },
            drawShape1(line1) {
                /**数据集柱状图 */
                echarts.init(document.getElementById('chartColumn1')).dispose(); //销毁echarts
                this.chartColumn = echarts.init(document.getElementById("chartColumn1"));
                this.chartColumn.setOption({
                title: {},
                // 提示框
                tooltip: {},
                // legend: line.legend,"产线17           "
                color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
                // legend: line.legend,
                grid: {
                    //设置中间内容距离div的距离，也就是内边距
                    left: "1%",
                    right: "1%",
                    bottom: "5%",
                    containLabel: true,
                    /* width: 'auto', height: 'auto', */
                },
                dataZoom: [{
                    type: 'inside',
                    start: 0,
                    end: 100
                }, {
                    start: 0,
                    end: 1
                }],
                // xAxis: {
                //     type: 'category',
                //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                // },
                yAxis: {
                    type: 'value'
                },
                // series: [{
                //     data: [150, 230, 224, 218, 135, 147, 260],
                //     type: 'line'
                // }]
                
                xAxis: line1.xAxis,

                // yAxis: {
                //     // type
                //     // min: 0, //y轴的最小值
                //     // max: 1000, //y轴最大值
                //     // interval: 100, //值之间的间隔
                // },
                series: line1.series,
                });
            },

            //温度
            async advancedAnalysisTemperatureTrend(){
                var param = {
                    sensorID:this.sensorID,
                    toSensorDataID:this.toSensorDataID
                }
                const res = await advancedAnalysisTemperatureTrend(param);
                this.tempList=res.data.responseGuiData.data;
                // console.log(this.tempList);
                var arr =[];
                var time = [];
                for(let i =this.tempList.length-1;i>0;i--){
                    if(this.tempList[i].Temp){
                        arr.push(this.tempList[i].Temp)
                    }
                    var a = this.tools.formatDate(this.tempList[i].samplineTime,"yyyy-MM-dd hh:mm:ss") 
                    time.push(a)
                    
                }
                this.arr1 =arr;
                this.timeList1 = time;
                // console.log("rms",time);
                var line2 = {
                    xAxis:{
                        type: 'category',
                        data:this.timeList1,
                        axisLabel: {
                            interval: 300,
                            color: '#808080',
                            fontSize: 12,
                            rotate: 60,
                        }
                    } ,
                    series: [{
                        data: this.arr1,
                        type: 'line'
                    }]
                };
            //   console.log("line",line);      
                this.drawShape2(line2)
            },
            drawShape2(line2) {
                /**数据集柱状图 */
                echarts.init(document.getElementById('chartColumn2')).dispose(); //销毁echarts
                this.chartColumn = echarts.init(document.getElementById("chartColumn2"));
                this.chartColumn.setOption({
                title: {},
                // 提示框
                tooltip: {},
                // legend: line.legend,"产线17           "
                color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
                // legend: line.legend,
                grid: {
                    //设置中间内容距离div的距离，也就是内边距
                    left: "1%",
                    right: "1%",
                    bottom: "5%",
                    containLabel: true,
                    /* width: 'auto', height: 'auto', */
                },
                dataZoom: [{
                    type: 'inside',
                    start: 0,
                    end: 100
                }, {
                    start: 0,
                    end: 1
                }],
                // xAxis: {
                //     type: 'category',
                //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                // },
                yAxis: {
                    type: 'value'
                },
                // series: [{
                //     data: [150, 230, 224, 218, 135, 147, 260],
                //     type: 'line'
                // }]
                
                xAxis: line2.xAxis,

                // yAxis: {
                //     // type
                //     // min: 0, //y轴的最小值
                //     // max: 1000, //y轴最大值
                //     // interval: 100, //值之间的间隔
                // },
                series: line2.series,
                });
            },
            rowClick(row, event, column){
                this.sensorID = row.sensorID;
                this.advancedAnalysisToSensorDataQueryLimit()
            }
        }
    }
</script>

<style scoped>
    .diy_div{
        width: 1680px;
        height: 700px;
    }
    .border_b{
        border-bottom: 1px solid gray;
    }
    .pd_bottom_20{
        padding-bottom: 20px;
    }

    .mg_bottom_20{
        margin-bottom: 20px;
    }
    .fixtable{
        width: 90%;
        height: auto;
        margin-bottom: 35px;
    }
    .fixtable > div{
        height: 40px;
        border: 1px red;
    }
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }

    .alarmQueryMargin {
    margin-left: 21px;
    
  }
</style>
<style>
    .fixtable{
        border: 1px solid black;
    }
    
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>