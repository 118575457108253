<!-- A设备管理-备件管理-备件出库记录 -->
<template>
    <div class="page_container height_100">

        <!-- select -->
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>备件出库记录</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <div class="spanWidth">工单编号：</div>
                    <el-input v-model="workOrderNoInput" class="width_150" clearable></el-input>
                    <div class="mg_left_20">仓库：</div>
                    <el-select v-model="warehouseInput" clearable placeholder="请选择" class="width_150">
                        <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key"
                            :value="item.value"> </el-option>
                    </el-select>
                    <div class="mg_left_20">申请时间：</div>
                    <el-date-picker value-format="timestamp" v-model="produceTime" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-button type="primary" class=" mg_left_10" size="medium" @click="queryList">
                        查询</el-button>
                    <!-- <el-button type="primary" class=" spareButton flex justify_center align_center">导出</el-button> -->
                </div>
            </div>

            <div class="bgc_FFFFFF bgc_FFFFFF_height pdl30 pdr30 ">
                <div class="flex justify_between pdt_20 fn_size16">
                    <div class="flex">
                        <el-button type="primary" class="width_140 buttonBorder" style="background-color: #657798;"
                            @click="gotoFaultDetails3">
                            <img src="../../../../assets/images/deviceManagement/a13.png" class="mg_right_2" alt="">
                            添加出库
                        </el-button>
                    </div>
                </div>

                <!-- table -->
                <el-table ref="multipleTable" :data="sparePartOutWarehouseArray" tooltip-effect="dark"
                    style="width: 100%" class="mg_top_20" @selection-change="handleSelectionChange" height="80%"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="80"></el-table-column>

                    <!-- <el-table-column prop="ID" label="id" width="" show-overflow-tooltip></el-table-column> -->
                    <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column label="是否领用" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.isPick?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width=""></el-table-column> -->
                    <!-- <el-table-column prop="authSort" label="当前审批顺序" width=""></el-table-column>
                    <el-table-column label="是否审批" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.isAuth}}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="pickOrderID" label="领用工单编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="applyTime" label="申请时间" width="180" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="applyPerson" label="申请人" width=""></el-table-column>
                    <el-table-column prop="warehouse" label="仓库" width=""></el-table-column>
                    <el-table-column prop="department" label="部门" width=""></el-table-column>
                    <el-table-column prop="outwhUse" label="出库用途" width=""></el-table-column>
                    <el-table-column prop="orderStatus" label="工单状态" width=""></el-table-column>
                    <el-table-column prop="totalPrice" label="出库总价" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="remarks" label="备注" width="" show-overflow-tooltip></el-table-column> -->
                    <el-table-column label="详细" width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoFaultDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="updateData(scope.row.ID)">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="deleteData(scope.row.ID)">删除</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.doAuth">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="approvalOperation(scope.row.ID)">审批</span>
                            </el-button>
                            <!-- <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="!scope.row.doAuth">
                                已审批
                            </el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>

            <!-- 审批弹框 -->
            <el-dialog :visible.sync="dialogVisible" width="30%" top="30vh">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">审批</div>
                <div class="height_110 flex justify_center align_center">
                    <el-radio-group v-model="approvalListValue" class="">
                        <el-radio v-for="item in approvalList" :key="item.value" :label="item.value">
                            {{item.description}}
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confrimApproval">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 切换框 -->
            <!-- <div class="bgc_E2F0FE flex align_end mg_top_24 height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">出库明细
                </div>
            </div> -->
            <!-- table -->
            <!-- <div class="mg_top_22">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @selection-change="handleSelectionChange" max-height="500"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="date" label="备件编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="date" label="备件名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="规格型号" width=""></el-table-column>
                    <el-table-column prop="name" label="计量单位" width=""></el-table-column>
                    <el-table-column prop="name" label="之前库存" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="address" label="当前库存" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="数量" width=""></el-table-column>
                    <el-table-column prop="name" label="换算单位" width=""></el-table-column>
                    <el-table-column prop="name" label="换算数量" width=""></el-table-column>
                    <el-table-column prop="name" label="单价" width=""></el-table-column>
                    <el-table-column prop="name" label="金额" width=""></el-table-column>
                    <el-table-column prop="name" label="文本1" width=""></el-table-column>
                    <el-table-column prop="address" label="文本2" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="address" label="文本3" width="" show-overflow-tooltip></el-table-column>
                </el-table>
            </div> -->

        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        sparePartWarehouseQuery,
        sparePartOutWarehouseMultiAuthQueryLimit,
        sparePartOutWarehouseDelete,
        multiAuthorizeResultQuery1,
        sparePartOutWarehouseAuthorize
    } from "@/api/api_deviceManagement";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                // tabIndex: 0,
                value: '',
                warehouseInput: '',
                warehouseSelectList: [], //仓库下拉框 使用的数组
                workOrderNoInput: '',
                options: [],
                tableData: [],
                multipleSelection: [],
                sparePartOutWarehouseArray: [],
                produceTime: null,
                pageQuantity: 0,
                currentPage: 1,
                pageSize: 10,

                //审批所需的变量
                approvalID: '', //要审批的ID
                approvalList: [], //获取的审批结果列表内容
                approvalListValue: '', //初始时使用的值
                dialogVisible: false, //审批弹框显示控制标识符
                userID: '',
            }
        },
        // 页面初始化数据，所有一进页面就需要展示的内容
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.sparePartOutWarehouseMultiAuthQueryLimit();

            //页面一加载，就初始化仓库下拉框的数据
            this.sparePartWarehouseQuery();
        },
        methods: {
            //仓库下拉框
            async sparePartWarehouseQuery() {

                //封装参数
                var param = {
                    userID: this.userID,
                };

                //调用接口
                const selectRes = await sparePartWarehouseQuery(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    //console.log(selectRes)
                    this.warehouseSelectList = selectRes.data;
                }
            },

            // 备件出库分页查询
            async sparePartOutWarehouseMultiAuthQueryLimit() {

                /*                 if((this.workOrderNoInput != '')||(this.warehouseInput != '')||(this.produceTime != null)){
                                    this.currentPage = 1;
                                } */
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (this.workOrderNoInput != '') {
                    param.orderID = this.workOrderNoInput
                }
                if (this.warehouseInput != '') {
                    param.warehouse = this.warehouseInput
                }
                if (this.produceTime != null) {
                    param.startApplyTime = this.produceTime[0];
                    param.endApplyTime = this.produceTime[1];
                }
                const res = await sparePartOutWarehouseMultiAuthQueryLimit(param);
                this.sparePartOutWarehouseArray = res.array;
                this.pageQuantity = res.pageQuantity;
            },
            // 切换分页
            handleCurrentChange(val) {
                this.currentPage = val;
                this.sparePartOutWarehouseMultiAuthQueryLimit();
            },
            handleSelectionChange(val) {
                // console.log(val)
                this.multipleSelection = val;
            },
            // handleChange(id) {
            //     this.tabIndex = id;
            // },
            queryList() {
                this.currentPage = 1;
                this.sparePartOutWarehouseMultiAuthQueryLimit();
            },
            // 详情
            gotoFaultDetails(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartDeliveryRecord/sparePartDeliveryRecordDetails',
                    // query: {
                    //     data: data
                    // }
                })
            },
            // 添加出库
            gotoFaultDetails3() {
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartDeliveryRecord/addSparePartAccountOutStorage',
                })
            },
            // 修改出库信息
            updateData(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartDeliveryRecord/updateSparePartAccountOutStorage',
                    // query: {
                    //     data: data
                    // }
                })
            },
            // 删除备件出库记录
            deleteData(id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.sparePartOutWarehouseArray.length, this
                        .pageQuantity, this.currentPage);
                    this.sparePartOutWarehouseDelete(id);
                }).catch(() => {});
            },
            async sparePartOutWarehouseDelete(id) {
                var param = {
                    ID: id
                }
                const res = await sparePartOutWarehouseDelete(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.sparePartOutWarehouseMultiAuthQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },

            //备件出库单审批
            //点击‘审批’按钮时触发的方法
            async approvalOperation(id) {

                this.approvalID = id;

                //调用接口，查询审批结果列表的内容
                const selectRes = await multiAuthorizeResultQuery1();

                //装载审批结果列表数据
                this.approvalList = selectRes.array;
                this.approvalListValue = selectRes.array[0].value;

                //打开审批弹框
                this.dialogVisible = true;
            },
            //点击审批弹框中的‘确定’按钮时触发的方法
            async confrimApproval() {

                //封装数据
                var param = {
                    userID: this.userID,
                    ID: this.approvalID,
                    authResult: this.approvalListValue,
                };

                //调用接口
                const selectRes = await sparePartOutWarehouseAuthorize(param);

                //处理返回值
                if (selectRes.data.result) { //如果有返回值的话
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisible = false;

                    //调用分页查询接口，刷新审批操作之后的数据
                    this.sparePartOutWarehouseMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },

            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }

    .spareButton {
        width: 110px;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>