<!-- KPI-设备kpi -->
<template>
    <div class="page_container" v-loading="loading">
        <div class="box_head1  flex justify_start align_center">
            <span>设备Kpi分析</span> 
             <el-cascader size="medium" class="el_cascader_width mg_right_10 mg_left_50" ref="demoCascader1" v-model="organizationValue1"
                placeholder="设备选择" :options="organizationAr1" :props="defaultProps" @change="handleChange1"
                :show-all-levels='false' collapse-tags clearable filterable>
            </el-cascader>
            <el-select v-model="dtValue1" placeholder="选择时间维度" class="mg_right_10 width_110">
                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-date-picker value-format="timestamp" v-model="value3" class="mg_right_10" type="datetimerange"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
            </el-date-picker>
            <el-button type="primary" size="medium" @click="Querytest1">
             查询</el-button>
        </div>
        <div class="flex flex_column bgc_FFFFFF ">
            <div class="flex flex_column alarmQueryTable alarmQueryMargin fn_size14">
              <el-table :data="analysisList" class="width_100" :cell-style="cellStyle" height="490"
                tooltip-effect="dark" border>
                <el-table-column type="index" label="序号" width="70"></el-table-column>
                <el-table-column prop="equipmentID" label="设备编号" min-width=" "></el-table-column>
                <el-table-column prop="equipmentName" label="设备名称" min-width=" "></el-table-column>
                <el-table-column prop="deiveceStatus" label="设备状态" min-width=" "></el-table-column>
                <el-table-column prop="equipmentcategoryName" label="设备类型名称" min-width=" "></el-table-column>
                <el-table-column prop="OEE" label="OEE" min-width=" 139"></el-table-column>
                <el-table-column prop="ComprehensiveRate" label="综合效率（%）" min-width=" "></el-table-column>
                <el-table-column prop="PowerOnRate" label="开机率（%）" min-width=" "></el-table-column>
                <el-table-column prop="PowerOnUtilization" label="开机利用率（%）" min-width="100px"></el-table-column>
                <el-table-column prop="FaultRate" label="故障率（%）" min-width=" "></el-table-column>
                <el-table-column prop="MTTR" label="MTTR（h）" min-width=" "></el-table-column>
                <el-table-column prop="MTBF" label="MTBF（h）" min-width=" "></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" min-width="150 "></el-table-column>
              </el-table>
            </div>
            <!-- 页码 -->
          

            <div class="box_head1  flex justify_start align_center mg_top_20">
                <span>设备KPI分析趋势图</span>
                <el-select v-model="eqKpiValue" @change="selectKpiChange1" placeholder="设备Kpi指标"
                class="width_150  mg_left_30 " >
                <el-option v-for="item in kpiAnalysisEquipmentKpiSelectList" :key="item.id" :label="item.name"
                    :value="item.id">
                </el-option>
                </el-select>
            </div>
            <!-- <el-select v-model="eqKpiValue" @change="selectKpiChange1" placeholder="设备Kpi指标"
                class="alarmQuerySelect mg_right_30 mg_left_30 " >
                <el-option v-for="item in kpiAnalysisEquipmentKpiSelectList" :key="item.id" :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select> -->
            <!-- echarts图 -->
            <div class="diy_div">
                <div id="chartColumn" class="width_100 height_100"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import {
        kpiAnalysisEquipmentSelect,
        kpiAnalysisEquipmentAnalysis,
        kpiAnalysisEquipmentAnalysisTrend,
        kpiAnalysisEquipmentKpiSelect,
    } from "@/api/api_deviceManagement";
    export default {
        data() {
            return {
                value: "",
                value1: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
                value2: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
                value3: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
                activeName: "1",
                time: new Date(),
                date: "",
                visible: false,
                dialogTableVisible: false,
                collapseNames: "",
                isActive: true,
                isActive2: false,
                options: [{
                    value: '1',
                    label: '日'
                }, {
                    value: '2',
                    label: '月'
                }],
                options1: [{
                    value: '1',
                    label: '日'
                }, {
                    value: '2',
                    label: '月'
                }],
                typeAnalysisList: [],
                analysisList: [],
                kpiAnalysisEquipmentTypeKpiSelectList: [],
                eqtKpiValue: 1,
                kpiSelectData: [],
                kpiAnalysisEquipmentTypeSelectList: [],
                eqKpiValue: 1,
                kpiAnalysisEquipmentKpiSelectList: [],
                permission: {},
                defaultProps: {
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true, //多选
                    emitPath: false, //只返回选中节点的值
                    // checkStrictly: true //多选任一级
                },
                currentData1: [],
                standardData: [],
                nameList: [],
                organizationValue: [],
                organizationValue1: [],
                organizationAr: [],
                organizationAr1: [],
                orgTreeData: [],
                orgTreeData1: [],
                standardArr: [],
                standardArr1: [],
                checkData: {},
                checkData1: {},
                tkValue: "1",
                tkValue1: "1",
                eqtIDValue: [],
                dtValue: "1",
                eqIDValue: [],
                dtValue1: "1",
                nowID: "",
                nowID1: "",
                organization: {},
                loading: true,
                equipmentArr: [],
                indicatorSelectValue:[],

            };
        },
        
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.kpiAnalysisEquipmentSelect();
            this.kpiAnalysisEquipmentKpiSelect();

        },
        methods: {
            //loading 
            getData() {
                const loading = this.$loading({
                    lock: true, //lock的修改符--默认是false
                    text: 'Loading', //显示在加载图标下方的加载文案
                    spinner: 'el-icon-loading', //自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
                    target: document.querySelector('#table') //loadin覆盖的dom元素节点
                });
                loading.close() //成功回调函数停止加载
            },
            ss(){},
            ssr(){},
            //查询按钮
            Querytest1() {
                this.kpiAnalysisEquipmentAnalysis();
                this.kpiAnalysisEquipmentAnalysisTrend();

            },
            //设备
            async kpiAnalysisEquipmentAnalysis() {
                var param = {
                    organization: {
                        'EquipmentID': this.currentData1
                    },
                    // startDate: this.value2[0],
                    // endDate: this.value2[1],
                    dateType: this.dtValue1,
                    permission: this.permission,
                };
                if (this.value2 != null) {
                    param.startDate = this.value3[0]
                    param.endDate = this.value3[1]
                }
                const selectRes = await kpiAnalysisEquipmentAnalysis(param);
                this.analysisList = selectRes.data
            },
            async kpiAnalysisEquipmentAnalysisTrend() {
                echarts.init(document.getElementById('chartColumn')).dispose();
                var param = {
                    organization: {
                        "EquipmentID": this.currentData1
                    },
                    // startDate: this.value3[0],
                    // endDate: this.value3[1],
                    dateType: this.dtValue1,
                    permission: this.permission,
                    typeKpi: this.eqKpiValue
                };
                if (this.value3 != null) {
                    param.startDate = this.value3[0]
                    param.endDate = this.value3[1]
                }
                const selectRes = await kpiAnalysisEquipmentAnalysisTrend(param);
                var queryData = selectRes.data;
                var line = {
                    legend: {
                        type: "scroll",
                        data: [],
                        bottom: '2%',
                        textStyle: {
                            color: '#808080',
                            fontSize: 12,
                            rotate: 0,
                        }
                    },
                    //          dataZoom: [{
                    //     type: 'slider',
                    //     show: true,
                    //     xAxisIndex: [0],
                    //     left: '9%',
                    //     bottom: -5,
                    //     start: 10,
                    //     end: 90 //初始化滚动条
                    // }],
                    xAxis: {
                        type: 'category',
                        data: [],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '##E3E4E6',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#808080',
                            fontSize: 12,
                            rotate: 60,
                        }
                    },
                    series: []
                };
                this.chartColumn = echarts.init(document.getElementById('chartColumn'));
                this.chartColumn.showLoading({
                    text: '加载中，请稍候...',
                            // maskColor: 'rgba(3,3,8,0.5)',
                            // textColor: '#fff600'
                });
                if(queryData.length>0){
                    line.xAxis.data = queryData[0].time;
                    for (var i = 0; i < queryData.length; i++) {
                        var curObj = {
                            type: 'line',
                            symbol: "circle",
                            symbolSize: 8, //拐点圆的大小
                            smooth: true,
                        };
                        curObj.name = queryData[i].name
                        if (this.eqKpiValue == "1") {
                            curObj.data = queryData[i].OEEList
                        } else if (this.eqKpiValue == "2") {
                            curObj.data = queryData[i].OperationRateList
                        } else if (this.eqKpiValue == "3") {
                            curObj.data = queryData[i].UseRatio
                        } else if (this.eqKpiValue == "4") {
                            curObj.data = queryData[i].FaultRateList
                        } else {
                            curObj.data = queryData[i].EfficencyList
                        }
                        line.series[i] = curObj
                        line.legend.data[i] = queryData[i].name
                    }
                }
                this.drawShape(line);
            },
            drawShape(line) {
                /**数据集柱状图 */
                echarts.init(document.getElementById('chartColumn')).dispose(); //销毁echarts
                this.chartColumn = echarts.init(document.getElementById("chartColumn"));
                this.chartColumn.setOption({
                    title: {},
                    // 提示框
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        }
                    },
                    // legend: line.legend,"产线17           "
                    color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
                    legend: line.legend,
                    grid: {
                        //设置中间内容距离div的距离，也就是内边距
                        left: "3%",
                        right: "3%",
                        bottom: "10%",
                        containLabel: true,
                        /* width: 'auto', height: 'auto', */
                    },
                    xAxis: line.xAxis,

                    yAxis: {
                        // type
                        // min: 0, //y轴的最小值
                        // max: 1000, //y轴最大值
                        // interval: 100, //值之间的间隔
                    },
                    series: line.series,
                });
                this.chartColumn.hideLoading()
            },
            cellStyle(row, column, rowIndex, columnIndex) {
                /* 设备状态 */
                if (
                    row.column.label === "设备状态" &&
                    row.row.equipmentStatus === "运行"
                ) {
                    return "background:#56DC28";
                } else if (
                    row.column.label === "设备状态" &&
                    row.row.equipmentStatus === "待机"
                ) {
                    return "background:#FEFC55";
                } else if (
                    row.column.label === "设备状态" &&
                    row.row.equipmentStatus === "停机"
                ) {
                    return "background:#CCCACA";
                } else if (
                    row.column.label === "设备状态" &&
                    row.row.equipmentStatus === "故障"
                ) {
                    return "background:#EE2C3B";
                }
                /* 开机率 */
                if (row.column.label === "开机率（%）" && row.row.powerOnRate > 80) {
                    return "background:#56DC28";
                } else if (
                    row.column.label === "开机率（%）" &&
                    row.row.powerOnRate > 70 &&
                    row.row.powerOnRate <= 80
                ) {
                    return "background:#FEFC55";
                } else if (
                    row.column.label === "开机率（%）" &&
                    row.row.powerOnRate < 70
                ) {
                    return "background:#EE2C3B";
                }
                /* 开机率 */
                if (row.column.label === "移动率（%）" && row.row.movementRate > 80) {
                    return "background:#56DC28";
                } else if (
                    row.column.label === "移动率（%）" &&
                    row.row.movementRate > 70 &&
                    row.row.movementRate <= 80
                ) {
                    return "background:#FEFC55";
                } else if (
                    row.column.label === "移动率（%）" &&
                    row.row.movementRate < 70
                ) {
                    return "background:#EE2C3B";
                }
                /* 开机率 */
                if (row.column.label === "故障率（%）" && row.row.failtRate > 10) {
                    return "background:#56DC28";
                } else if (
                    row.column.label === "故障率（%）" &&
                    row.row.failtRate >= 5 &&
                    row.row.failtRate < 10
                ) {
                    return "background:#FEFC55";
                } else if (row.column.label === "故障率（%）" && row.row.failtRate < 5) {
                    return "background:#B8FB7C";
                }
            },
            handleChange1() {
                if (this.organizationValue1.length == 0) {
                    this.currentData1 = this.standardData;
                    return
                }else{
                    this.currentData1 = this.organizationValue1
                }
                
            },
            //设备选择
            async kpiAnalysisEquipmentSelect() {
                var param = {
                    permission: this.permission
                };
                const selectRes = await kpiAnalysisEquipmentSelect(param);
                this.organizationAr1 = selectRes.data.Tree
                this.equipmentArr = selectRes.data.organizationPermissionAll
                this.checkData1 = selectRes.data.organizationPermissionAll[0];
                for (var i = 0; i < this.equipmentArr.length; i++) {
                    if (this.equipmentArr[i].Type == 'E') {
                        // this.organizationValue1 = this.equipmentArr[i].ID;
                        this.currentData1 = this.equipmentArr[i].ID;
                        this.standardData = this.equipmentArr[i].ID;
                    }
                }
                // console.log('wo',this.standardData,this.currentData1);
                // this.organizationValue1 = selectRes.data.organizationPermission[0].ID;
                this.kpiAnalysisEquipmentAnalysis()
                // this.kpiAnalysisEquipmentKpiSelect()
                this.kpiAnalysisEquipmentAnalysisTrend()
                this.loading = false;
            },
            //设备KPI指标选择框
            async kpiAnalysisEquipmentKpiSelect() {
                var param = {
                    organization: {}
                };
                const selectRes = await kpiAnalysisEquipmentKpiSelect(param);
                this.kpiAnalysisEquipmentKpiSelectList = selectRes.data.Kpi

            },
            //设备kpi 指标变动
            selectKpiChange1() {
                // this.kpiAnalysisEquipmentAnalysis()
                this.kpiAnalysisEquipmentAnalysisTrend();
                
            },

            handleCurrentChange: function (cpage) {
                this.currentPage = cpage;
            },

            handleSizeChange: function (psize) {
                this.pageSize = psize;
            },
        },

    }
</script>

<style scoped>
  .alarmQueryTable {
    height: 441px;
    margin-right: 31px;
  }
    .width_190{
        width: 190px;
    }
    .alarmQueryMargin {
        margin-left: 31px;
        margin-top: 19px;
    }
    .alarmAnalysisHeader {
        height: 40px;
        padding-left: 20px;
        background-color: #e2f0fe;
        border: 1px solid #ccdbe5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .alarmAnalysisHeader2 {
        width: 1798px;
        height: 40px;
        padding-left: 20px;
        background-color: #e2f0fe;
        border: 1px solid #ccdbe5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .alarmQuerySelect {
        width: 150px;
        height: 36px;
    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009bfd;
    }

    .sectionSelect {
        margin-left: 31px;
        margin-top: 19px;
        width: 1802px;
        padding-bottom: 22px;
    }

    .diy_div {
        width: 100%;
        height: 450px;
    }
</style>