<!-- 设备管理-维修计划 -->
<template>
    <div class="page_container height_100">
        <div class=" bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>维修计划</span>
                <div class="flex align_center fn_size14 mg_left_50">
                <div>设备编号：</div>
                <el-input v-model="deviceID" placeholder="请输入设备编号" class="mg_left_8 width_150 "></el-input>
                <div class="mg_left_20">计划状态：</div>
                <el-select v-model="planStatusValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                    <el-option v-for="(item,index) in planStatus" :key="index" :label="item.description"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="mg_left_20">维修类型：</div>
                <el-select v-model="repairCategoryValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                    <el-option v-for="(item,index)  in repairCategory" :key="index" :label="item.description"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="mg_left_20">紧急程度：</div>
                <el-select v-model="repairUrgenLevelValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                    <el-option v-for="(item,index)  in maintainUrgenLevel" :key="index" :label="item.description"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="mg_left_20">计划时间：</div>
                <el-date-picker v-model="planTimeValue" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                </el-date-picker>
                <el-button type="primary" class=" mg_left_10" size="medium"
                    @click="queryTest">查询
                </el-button>
                </div>
            </div>
            
            <div class="flex justify_between pdt_20 fn_size16 pdl30 pdr30">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140"
                        style=" background: #03CABE;border: none;" @click="addPlan">添加计划</el-button>
                    <!-- <el-button type="primary" class="width_140" style=" background: #009BFD;border: none;">执行计划
                    </el-button> -->
                    <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
                </div>
                <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> -->
                  
            </div>
            <div class="pdl30 pdr30" style="height:78%">
                <el-table ref="multipleTable" :data="tableData" class="mg_top_20" height='100%'
             @row-dblclick="dblclickGotoFaultDetails" border="" @selection-change="handleSelectionChange" id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <el-table-column prop="planID" label="计划编号" width="" show-overflow-tooltip>
                    </el-table-column>
                    <!-- <el-table-column prop="projectID" label="公司编号" width="100">
                </el-table-column> -->
                    <!-- <el-table-column prop="authSort" label="当前审批顺序" width="150">
                </el-table-column> -->
                    <!-- <el-table-column label="是否审核" width="80">
                    <template slot-scope="scope">
                        <span>{{scope.row.isAuth}}</span>
                    </template>
                </el-table-column> -->
                    <!-- <el-table-column prop="planStatus_name" label="计划状态" width="">
                    </el-table-column> -->
                    <el-table-column prop="deviceID" label="设备编号" width="">
                    </el-table-column>
                    <el-table-column prop="deviceName" label="设备名称" width="">
                    </el-table-column>
                    <!-- <el-table-column prop="deviceType" label="设备类型" width="100">
                </el-table-column> -->
                    <el-table-column prop="deviceModel" label="设备型号" width="">
                    </el-table-column>
                    <!-- <el-table-column prop="deviceDepartment" label="设备所属部门" width="150">
                </el-table-column> -->
                    <!-- <el-table-column prop="deviceLocation" label="设备位置" width="100" show-overflow-tooltip>
                </el-table-column> -->
                    <!-- <el-table-column prop="oprPerson" label="记录人" width="100">
                </el-table-column> -->
                    <!-- <el-table-column prop="oprTime" label="记录时间" width="150" :formatter="formatDate">
                </el-table-column> -->
                    <!-- <el-table-column prop="planTime" label="计划时间" width="200" :formatter="formatDate">
                    </el-table-column> -->
                    <!-- <el-table-column prop="remindTime" label="提醒时间" width="150" :formatter="formatDate">
                </el-table-column> -->
                    <el-table-column prop="execType_name" label="维修类型" width="">
                    </el-table-column>
                    <el-table-column prop="urgenLevel_name" label="紧急程度" width="">
                    </el-table-column>
                     <el-table-column prop="planStatus_name" label="计划状态" width="">
                    </el-table-column>
                    <!-- <el-table-column prop="execGroup" label="维修组" width="100">
                </el-table-column> -->
                    <!-- <el-table-column prop="execPerson" label="维修人" width="100">
                </el-table-column> -->
                    <!-- <el-table-column prop="remarks" label="备注" width="150" show-overflow-tooltip>
                </el-table-column> -->
                    <el-table-column prop="authRoleName" label="审批角色" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="详细" width="80">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.doAuth"
                                @click="approvalOperation(scope.row.ID)">
                                审批</el-button>
                            <el-button type="text" v-if="scope.row.planStatus !=1&&scope.row.planStatus !=2"
                                class="color_009BFD pdt_0 pdb_0" @click="gotoModify(scope.row.ID)">
                                修改
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                删除</el-button>
                            <!-- <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="!scope.row.doAuth">
                            </el-button> -->
                            <!-- isAuth -->
                        </template>
                    </el-table-column>
                </el-table>
            

                <div class="flex justify_end">
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>
            <el-dialog :visible.sync="dialogFormVisible" width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">审批</div>
                <el-form :model="form" class="pdl30 pdr30 mg_top_24">
                    <el-form-item label="审批结果：" :label-width="formLabelWidth">
                        <el-radio-group v-model="form.authResult" class="">
                            <el-radio v-for="(item,index) in approvalList" :key="index" :label="item.value">
                                {{item.description}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="flex justify_center pdb_20 ">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmAuth">确 定</el-button>
                    <!-- -->
                </div>
            </el-dialog>

        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        repairPlanStatusQuery,
        repairTypeQuery,
        repairUrgenLevel,
        repairPlanMultiAuthQueryLimit,
        repairPlanDelete,
        repairPlanAuthorize,
        multiAuthorizeResultQuery,
    } from '@/api/api_deviceManagement.js'
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                value: '',
                planStatusValue: '',
                planStatus: [],
                repairCategoryValue: '',
                repairCategory: [],
                tableData: [],
                multipleSelection: [],
                repairUrgenLevelValue: '',
                maintainUrgenLevel: [],
                planTimeValue: null,
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                dialogFormVisible: false,
                form: {},
                formLabelWidth: '120px',
                approvalList: [],
                userID: '',
                deviceID:''
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.repairPlanStatusQuery();
            this.repairTypeQuery();
            this.repairPlanQuery();
            this.repairUrgenLevel();

        },
        methods: {
            queryTest() {
                this.currentPage = 1;
                this.repairPlanQuery();
            },
            //分页数据
            async repairPlanStatusQuery() {
                const selectRes = await repairPlanStatusQuery();
                this.planStatus = selectRes.data;
                console.log("status", this.planStatus);
            },
            async repairTypeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairTypeQuery(param);
                this.repairCategory = selectRes.data
                console.log("type", this.repairCategory);
            },
            async repairUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairUrgenLevel(param);
                this.maintainUrgenLevel = selectRes.data;
                
            },
            async repairPlanQuery() {
                var param = {
                    userID: this.userID,
                    // permission: permission
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                if(this.deviceID){
                    param.deviceID = this.deviceID;
                }
                if (this.planStatusValue.toString() != '' && this.planStatusValue != null) {
                    console.log(this.planStatusValue)
                    param.planStatus = this.planStatusValue
                }
                if (this.repairCategoryValue != '' && this.repairCategoryValue != null) {
                    param.execType = this.repairCategoryValue
                }
                if (this.repairUrgenLevelValue != '' && this.repairUrgenLevelValue != null) {
                    param.urgenLevel = this.repairUrgenLevelValue
                }
                if (this.planTimeValue != null) {
                    param.startPlanTime = this.planTimeValue[0];
                    param.endPlanTime = this.planTimeValue[1];
                }
                const selectRes = await repairPlanMultiAuthQueryLimit(param);
                this.tableData = selectRes.array;
                console.log('list', this.tableData);
                for (let i = 0; i < this.tableData.length; i++) {
                    this.tableData[i].deviceID = parseInt(this.tableData[i].deviceID)
                }
                this.pageQuantity = selectRes.pageQuantity;
                
            },
            // query() {
            //     this.repairPlanQuery()
            // },
            handleCurrentChange(val) {
                this.pageNumber = val;
                this.repairPlanQuery(); //查询table数据的方法
            },
            //删除
            delTableData(id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.equipmentDocumentDelete();
                    // console.log(this.multipleSelection[0])

                    this.repairPlanDelete(id)


                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            async repairPlanDelete(id) {
                var param = {
                    ID: id
                };
                const selectRes = await repairPlanDelete(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.currentPage = this.delData.delData(this.tableData.length, this.pageQuantity, this
                        .currentPage)
                    this.repairPlanQuery();
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    });
                }
            },
            // 审批
            approvalOperation(id) {
                this.form = {};
                this.form.ID = id;
                console.log(id)
                this.dialogFormVisible = true;
                this.multiAuthorizeResultQuery();
                //this.repairPlanUpdate();
                 this.repairPlanMultiAuthQueryLimit();//+1
                  this.repairPlanQuery();
            },
            async multiAuthorizeResultQuery() {
                const selectRes = await multiAuthorizeResultQuery();
                this.approvalList = selectRes.array;
                 this.repairPlanMultiAuthQueryLimit();//
                 
            },
            async confirmAuth() {
             if(this.form.authResult == undefined){
                   this.$message.error('请选择是否通过！');             
             }else{                  
                this.form.userID = this.userID; //登录获取
                const selectRes = await repairPlanAuthorize(this.form);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    //审批结束后刷新页面
                this.repairPlanQuery();
                    this.dialogFormVisible = false;
                    this.form = {};
                    this.repairPlanMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.massage);
                }
             }
                
            },
            // // 审批--结束
            //详情
            gotoDetails(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                    // query: {
                    //     id: id
                    // },
                    // query:{setid:111222}
                    // params:{setid:111222}//地址栏中看不到参数的内容
                })
            
               //审批结束刷新页面
               this.repairPlanQuery();

            //     this.repairPlanMultiAuthQueryLimit();
            },
            gotoModify(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    // query: {
                    //     id: id
                    // },
                    path: '/deviceManagement/maintenanceManagement/repairPlan/modifyRepairPlan',
                    // query:{setid:111222}
                    // params:{setid:111222}//地址栏中看不到参数的内容
                })
            },
            //添加计划
            addPlan() {
                this.$router.push({
                    path: '/deviceManagement/maintenanceManagement/repairPlan/addRepairPlan',
                })

            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '维修计划')
            },
         //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
        //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
        }
    }
</script>

<style scoped>
    .tabel_height {
        height: 72%
    }
</style>