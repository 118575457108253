<template>
    <div class="content_kb">

        <!-- <baidu-map class="map map_div" :center="{lng:118.872086, lat: 36.743596}" :zoom="zoom" :mapStyle="mapStyle"
                :scroll-wheel-zoom='true' @ready="handler" >
            </baidu-map> -->
        <!--  style="height:500px;width:500px" -->
        <!-- id="mymap" -->
        <div id="mymap">
            <div class="data_box">
                <div class="title_box">
                    <img src="../../assets/images/comprehensive/_icon@2x.png" alt="">
                    <span>运保情况</span>
                </div>
                <div style="width:100%">
                    <div class="flex justify_around" style="width:100%;">
                        <div class="wH">
                            <div id="echarts1" style="width:100%;height:100%"></div>
                        </div>
                    </div>
                    <!-- <div class="flex justify_around fn_size16">
                        <div>开机率</div>
                    </div> -->
                    <div class="flex justify_around " style="width:100%;">
                        <div class="cricle_div"><span class="fn_size28"
                            style="color:#7CFFB2;font-weight: bold;">{{companyQuantity}}</span>
                        </div>
                        <div class="cricle_div"><span class="fn_size28"
                            style="color:#7CFFB2;font-weight: bold;">{{deviceQuantity}}</span>
                        </div>
                        <div class="cricle_div"><span class="fn_size28"
                            style="color:#FF6E76;font-weight: bold;">{{totalAlertsQuantity}}</span>
                        </div>
                    </div>
                    <div class="flex justify_around fn_size16">
                        
                        <div>客户数量(家)</div>
                        <div>接入皮带机(台)</div>
                        <div>报警总数(条)</div>
                    </div>
                    <div class="" style="cursor:pointer">
                        <div class="mg_top_10">
                            <span class="flex">低报</span>
                            <el-progress :percentage="lowAlertsRate" color="green" class="mg_top_10"></el-progress>
                        </div>
                        <div class="mg_top_10">
                            <span class="flex">高报</span>
                            <el-progress :percentage="highAlertsRate" color="yellow" class="mg_top_10"></el-progress>
                        </div>
                        <div class="mg_top_10">
                            <span class="flex">高高报</span>
                            <el-progress :percentage="highHighAlertsRate" color="red" class="mg_top_10"></el-progress>
                        </div>
                    </div>

                    <div class="fn_size18 mg_top_20">报警处理情况({{totalAlertsQuantity}})</div>
                    <div class="flex justify_around fn_size28 mg_top_10">
                        <div style="color: #7CFFB2; font-weight: bold;">{{totalAckAlertsQuantity}}</div>
                        <div style="color: #FDDD60; font-weight: bold;">{{totalUnAckAlertsQuantity}}</div>
                    </div>

                    <div class="flex justify_around mg_top_10" style="width:100%;">
                        <div class="wH120">
                            <div id="echarts2" style="width:100%;height:100%"></div>
                        </div>
                        <div class="wH120">
                            <div id="echarts3" style="width:100%;height:100%"></div>
                        </div>
                    </div>
                    <div class="flex justify_around fn_size16">
                        <div>已处理</div>
                        <div>待处理</div>
                    </div>
                </div>
            </div>
        </div>


    </div>

</template>
<script>
    // import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';

    // import AMapLoader from "@amap/amap-jsapi-loader";
    import {
        getMapKanbanData,
        getMainMapKanbanData
    } from "@/api/api_comprehensiveShow";
    export default {
        // components: {
        //     innerTopNav
        // },
        data() {
            return {
                currentTime: new Date(),
                value1: [],
                value2: [],
                multipleValue: true,
                multipleValue2: true,
                props: {
                    multiple: true,
                    checkStrictly: true
                },
                list: null,
                mapStyle: {
                    styleJson: [{
                            "featureType": "all",
                            "elementType": "geometry",
                            "stylers": {
                                "hue": "#007fff",
                                "saturation": 89
                            }
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": {
                                "color": "blue"
                            }
                        }
                    ]
                },
                zoom: 4,
                companyQuantity: 0, //客户数量
                deviceQuantity: 0, //接入皮带机(台)
                totalAlertsQuantity: 0, //当前总报警数

                totalAckAlertsQuantity: 0, //当前已确认报警数
                totalUnAckAlertsQuantity: 0, //待处理报警数量

                executingOrderQuantity: 0, //工单处理中数量
                executingOrderRatio: 0, //工单处理中数量占比
                finishedOrderQuantity: 0, //工单结单数量
                finishedOrderRatio: 0, // /工单结单数量占比

                runningDeviceRate: 0, //开机率
                lowAlertsRate: 0, //低保
                highAlertsRate: 0, //高
                highHighAlertsRate: 0, //高高

                totalAckAlertsRate: 0, //已处理报警率


                map: null,

                mapMark: {},
                userID: ''
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            // this.initMap();
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss')

            // this.getMapKanbanData();
            this.getMainMapKanbanData();
        },
        methods: {
            async getMainMapKanbanData() {
               // console.log(localStorage.getItem('isRouter'))
                if(localStorage.getItem('isRouter')=='true'){
                   console.log(111)
                     localStorage.setItem('isRouter', false);
                      this.$router.go(0)
                }else {
                    console.log(222)
                      localStorage.setItem('isRouter', true);//appKey
                }
                var param = {
                    userID: this.userID
                }
                const selectRes = await getMainMapKanbanData(param);
                this.mapMark = selectRes.MapMark;

                this.companyQuantity = selectRes.CompanyQuantity;
                this.deviceQuantity = selectRes.DeviceQuantity;

                this.runningDeviceRate = selectRes.RunningDeviceRate;
                this.totalAlertsQuantity = selectRes.TotalAlertsQuantity;

                this.lowAlertsRate = selectRes.LowAlertsRate;
                this.highAlertsRate = selectRes.HighAlertsRate;
               
                this.highHighAlertsRate = selectRes.HighHighAlertsRate;
                // HighAlertsRate 高报
                // HighHighAlertsRate 高高报
                // TotalAckAlertsQuantity 已处理报警数量
                // TotalAckAlertsRate 已处理报警率
                // TotalUnAckAlertsQuantity 待处理报警数量

                this.totalAckAlertsQuantity = selectRes.TotalAckAlertsQuantity;
                this.totalAckAlertsRate = selectRes.TotalAckAlertsRate;
                this.totalUnAckAlertsQuantity = selectRes.TotalUnAckAlertsQuantity;
                this.totalUnAckAlertsRate = selectRes.TotalUnAckAlertsRate;

                

                 this.drawShape();
                this.initMap();

            },
            initMap() {
                var source = [this.mapMark.HomeMark.longitude, this.mapMark.HomeMark.Latitude];
                var sourceName = this.mapMark.HomeMark.MarkName;
                var sourceJumpValue = this.mapMark.HomeMark.JumpValue;
                var endSource = this.mapMark.CompanyMark
                console.log("source:" + source);
                console.log("sourceName:" + sourceName);
                console.log("sourceJumpValue:" + sourceJumpValue);
                console.log("endSource:" + endSource);
                var map = new AMap.Map("mymap", {
                    zoom: 5.0,
                    pitch: 32,//视角高度
                    showLabel: true,
                    viewMode: "3D",
                    center: [95.0, 35.0],
                    // mapStyle: 'amap://styles/dark', //地图样式颜色 normal标准  dark幻影黑 light月光银 whitesmoke远山黛
                    // mapStyle: 'amap://styles/45311ae996a8bea0da10ad5151f72979',
                    // mapStyle: 'amap://styles/57994c871bb604a4c79184f5f65d8782',
                    mapStyle: "amap://styles/ec6b62128353cdc3bb4f97a9f3f1df28", //黑
                });
                // AMapLoader.load({
                //         // key: "3749a679cb93ed0a225a8174b8c70bfc", // 申请好的Web端开发者Key，首次调用 load 时必填
                //         key: "80cf3d1447144455fc0e24acff57ab93", // 申请好的Web端开发者Key，首次调用 load 时必填
                //         version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                //         plugins: [], //插件列表
                //         viewMode: '3D',
                //     })
                //     .then(AMap => {
                //         // this.map = new AMap.Map("mymap");
                //         // var map = new AMap.Map('mymap', {
                //         var map = new AMap.Map('mymap', {
                //             zoom: 5.0,
                //             pitch: 32,
                //             showLabel: true,
                //             viewMode: '3D',
                //             center: [95.0, 35.0],
                //             // center: source,
                //             // mapStyle: 'amap://styles/dark', //地图北京颜色 normal标准  dark幻影黑 light月光银 whitesmoke远山黛 
                //             // mapStyle: 'amap://styles/45311ae996a8bea0da10ad5151f72979',
                //             // mapStyle: 'amap://styles/57994c871bb604a4c79184f5f65d8782',
                //             mapStyle: 'amap://styles/ec6b62128353cdc3bb4f97a9f3f1df28',//黑

                //         });

                        // 文字图层
                        var labelLayer = new AMap.LabelsLayer({
                            rejectMapMask: true,
                            collision: true,
                            animation: true,
                        });
                        map.add(labelLayer);
                        var loca;
                        loca = new Loca.Container({
                            // map,
                            map: map
                        });

                        var linkLayer = new Loca.LinkLayer({
                            zIndex: 20,
                            opacity: 1,
                            visible: true,
                            zooms: [2, 22],
                        });

                        var scatterLayer1 = new Loca.ScatterLayer({
                            zIndex: 10,
                            opacity: 1,
                            visible: true,
                            zooms: [2, 22],
                        });
                        var scatterLayer2 = new Loca.ScatterLayer({
                            zIndex: 10,
                            opacity: 0.8,
                            visible: true,
                            zooms: [2, 22],
                        });
                        var scatterLayer3 = new Loca.ScatterLayer({
                            zIndex: 10,
                            opacity: 0.8,
                            visible: true,
                            zooms: [2, 22],
                        });
                        var centerPoint = new Loca.GeoJSONSource({
                            data: {
                                'type': 'FeatureCollection',
                                'features': [{
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': source, //北京，发射源头
                                    },
                                }, ],
                            },
                        });
                        // 3为源头
                        scatterLayer3.setSource(centerPoint);
                        scatterLayer3.setStyle({
                            size: [20000, 20000],
                            unit: 'meter',
                            duration: 1000,
                            // texture: 'https://a.amap.com/Loca/static/static/center-point.png',
                            texture:'https://a.amap.com/Loca/static/static/orange.png',
                        });

                        loca.add(scatterLayer3);

                        var lineGeoMap;
                        var scatterGeoMap;

                        var filterGeoJSON = (json, type) => {
                            console.log('点', json)
                            var newJSON = {
                                type: 'FeatureCollection',
                                features: [],
                            };
                            for (var i = 0; i < json.length; i++) {
                                var currentData = {
                                    type: "Feature",
                                    geometry: {
                                        coordinates: [],
                                        type: "Point"
                                    },
                                    properties: {
                                        country: "中国",
                                        flagName: "",
                                        time: "1949年10月6日",
                                        type: ''
                                    }
                                }
                                currentData.geometry.coordinates[0] = json[i].longitude
                                currentData.geometry.coordinates[1] = json[i].Latitude;
                                currentData.properties.type = type;
                                currentData.properties.flagName = json[i].MarkName;
                                // newJSON.features.push(currentData);
                                newJSON.features[i] = currentData;
                            }
                            console.log(newJSON)
                            return new Loca.GeoJSONSource({
                                data: newJSON,
                            });
                        };

                        var setLabelsLayer = (data) => {
                            labelLayer.clear();
                            data.forEach((item) => {
                                // console.log('item', item)
                                var labelsMarker = new AMap.LabelMarker({
                                    name: item.MarkName,
                                    position: [item.longitude, item.Latitude],
                                    zooms: [2, 22],
                                    opacity: 1,
                                    zIndex: 10,
                                    text: {
                                        content: item.MarkName,
                                        direction: 'bottom',
                                        offset: [0, -5],
                                        style: {
                                            fontSize: 13,
                                            fontWeight: 'normal',
                                            fillColor: '#00C6FF',
                                        },
                                    },
                                });
                                // console.log(labelsMarker)
                                labelLayer.add(labelsMarker);
                                var infoWindow
                                var that = this
                                labelsMarker.on('mouseover', openInfo)
                                labelsMarker.on('mouseout', closeInfo)
                                labelsMarker.on('click', clickMarker)

                                function clickMarker() {
                                    // console.log(item.JumpValue)
                                    that.$router.push({
                                        path: '/comprehensive/operationShowFactory',
                                        query: {
                                            jumpValue: item.JumpValue,
                                            // title: name
                                        }
                                    })
                                }
                                //在指定位置打开信息窗体
                                function openInfo(e) {
                                    // console.log(e)
                                    //构建信息窗体中显示的内容
                                    var info = [];
                                    info.push(
                                        "<div>"
                                    );
                                    info.push("<div style=\"padding:0px 0px 0px 4px;\"><b>" + e.data
                                        .data.name + "</b>");
                                    info.push("</div></div>");
                                    infoWindow = new AMap.InfoWindow({
                                        content: info.join("<br/>") //使用默认信息窗体框样式，显示信息内容
                                    });
                                    infoWindow.open(map, e.lnglat);
                                }

                                function closeInfo() {
                                    infoWindow.close();
                                }
                            });
                            var souceLabelsMarker = new AMap.LabelMarker({
                                name: 'china',
                                position: source,
                                zooms: [2, 22],
                                opacity: 1,
                                zIndex: 10,
                                rank: 100,
                                text: {
                                    content: sourceName,
                                    direction: 'bottom',
                                    offset: [0, -5],
                                    style: {
                                        fontSize: 13,
                                        fontWeight: 'normal',
                                        fillColor: '#FF9014',
                                    },
                                },
                            });
                            labelLayer.add(souceLabelsMarker);
                            var infoWindow1
                            var that = this
                            souceLabelsMarker.on('mouseover', openInfoSoures)
                            souceLabelsMarker.on('mouseout', closeInfoSoures)
                            souceLabelsMarker.on('click', clickMarkerSoures)

                            function clickMarkerSoures() {
                                // console.log('000000000')
                                that.$router.push({
                                    path: '/comprehensive/operationShowFactory',
                                    query: {
                                        jumpValue: sourceJumpValue,
                                    }
                                })
                            }
                            //在指定位置打开信息窗体
                            function openInfoSoures(e) {
                                //构建信息窗体中显示的内容
                                var info = [];
                                info.push(
                                    "<div>"
                                );
                                info.push("<div style=\"padding:0px 0px 0px 4px;\"><b>" + e.data
                                    .data.name + "</b>");
                                info.push("</div></div>");
                                infoWindow1 = new AMap.InfoWindow({
                                    content: info.join("<br/>") //使用默认信息窗体框样式，显示信息内容
                                });
                                infoWindow1.open(map, e.lnglat);
                            }

                            function closeInfoSoures() {
                                infoWindow1.close();
                            }



                        };

                        scatterGeoMap = endSource;
                        setLabelsLayer(scatterGeoMap);
                        console.log('endSource', endSource)

                        var source1 = filterGeoJSON(endSource, 0);
                        // console.log(source1)
                        // var source2 = filterGeoJSON(endSource[1], 1);
                        scatterLayer1.setSource(source1);
                        // scatterLayer2.setSource(source2);
                        // console.log(source1)
                        // console.log(source2)
                        scatterLayer1.setStyle({
                            size: [30000, 30000],
                            unit: 'miter',
                            animate: true,
                            duration: 1000,
                            texture: 'https://a.amap.com/Loca/static/static/green.png',
                        });
                        // scatterLayer2.setStyle({
                        //     size: [30000, 30000],
                        //     unit: 'miter',
                        //     animate: true,
                        //     duration: 1000,
                        //     texture: 'https://a.amap.com/Loca/static/static/orange.png',
                        // });
                        loca.add(scatterLayer1);
                        // loca.add(scatterLayer2);
                        loca.animate.start();
                        // });

                        // 划线
                        // fetch('https://a.amap.com/Loca/static/static/diplomacy-line.json')
                        //     .then((res) => res.json())
                        //     .then((data) => {
                        //         console.log(data)
                        //         lineGeoMap = Object.entries(data).reduce((accu, curr) => {
                        //             var [key, geo] = curr;
                        //             // console.log([key, geo])
                        //             accu[key] = new Loca.GeoJSONSource({
                        //                 data: geo,
                        //             });
                        //             return accu;
                        //         }, {});
                        lineGeoMap = drawLine(endSource);

                        function drawLine(json) {
                            // console.log(json)
                            var newJSON = {
                                type: 'FeatureCollection',
                                features: [],
                            };
                            for (var i = 0; i < json.length; i++) {
                                var currentData = {
                                    type: "Feature",
                                    geometry: {
                                        coordinates: [],
                                        type: "LineString"
                                    },
                                    properties: {
                                        country: "中国",
                                        flagName: "",
                                        time: "1949年10月6日",
                                        type: ''
                                    }
                                }
                                var end = [json[i].longitude, json[i].Latitude]
                                currentData.geometry.coordinates[0] = source; //源头--发射点
                                currentData.geometry.coordinates[1] = end //接收点
                                currentData.properties.type = i
                                currentData.properties.flagName = json[i].MarkName
                                newJSON.features[i] = currentData
                            }
                            return new Loca.GeoJSONSource({
                                data: newJSON,
                            });
                        }

                        linkLayer.setSource(lineGeoMap);
                        // console.log(linkLayer)


                        linkLayer.setStyle({
                            lineColors: ['#25CDEA', '#12BFBF'],
                            // lineColors: function (index, item) {
                            //     console.log(item)
                            //     return item.link.properties.type === 0 ? ['#25CDEA',
                            //         '#12BFBF'
                            //     ] : ['#FFD87B', '#FF4F00'];
                            // },
                            height: function (index, item) {
                                return item.distance / 3;
                            },
                            smoothSteps: function (index, item) {
                                return 200;
                            },
                        });
                        loca.add(linkLayer);
                    // });

            },
            goOperationShowFactory(num, name) {
                this.$router.push({
                    path: '/comprehensive/operationShowFactory',
                    query: {
                        equipmentNum: num,
                        title: name
                    }
                })
                // // 接收页面传过来的参数
                // console.log(this.$route);
                // if (JSON.stringify(this.$route.query) != '{}') {
                // this.value = this.$route.query.equipmentNum;
                // this.currentPart = this.$route.query.title;
                // };
            },
            drawShape() {
                this.echarts1 = echarts.init(document.getElementById('echarts1'));
                this.echarts1.setOption({
                    series: [{
                        type: 'gauge',
                        radius:'90%',
                        title : {               //设置仪表盘中间显示文字样式
                            textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                                fontWeight: 'normal',
                                fontSize: 14,
                                fontStyle: '',
                                color:"white"
                            }
                        },
                        progress: {
                            show: true,
                             width: 7
                        },
                        splitLine: {
                            length: 4,
                            lineStyle: {
                                width: 1,
                                color: '#999'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 7
                            }
                        },
                        axisTick: {            // 坐标轴小标记
                            show: true,        // 属性show控制显示与否，默认不显示
                            splitNumber: 2,    // 每份split细分多少段
                            length :3,         // 属性length控制线长
                            lineStyle: {       // 属性lineStyle控制线条样式
                                color: '#eee',
                                width: 0.5,
                                type: 'solid'
                            }
                        },
                        axisLabel: {
                            distance: 7,
                            color: '#999',
                            fontSize: 12
                        },
                        data: [{
                            value: this.runningDeviceRate,
                            name: '开机率',
                            
                        }],
                        detail: {
                            width: 100,
                            height: 16,
                            fontSize: 16,
                            color: 'lightgreen',
                            borderColor: 'auto',
                            borderRadius: 80,
                            borderWidth: 0,
                            formatter: '{value}%',
                            offsetCenter: [0, '50%']
                        },
                        
                        
                        // data: [{
                        //     value: this.runningDeviceRate,
                        //     name: '开机率'
                        // }]
                    }]
                })


                this.echarts2 = echarts.init(document.getElementById('echarts2'));
                this.echarts2.setOption({
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '50%'],
                        radius: '100%', //设置仪表盘的大小
                        pointer: {
                            show: false
                        },
                        progress: {
                            show: true,
                            overlap: false,
                            roundCap: true,
                            clip: false,
                            itemStyle: {
                                borderWidth: 0,
                                borderColor: '#464646'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 8
                            }
                        },
                        splitLine: {
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: this.totalAckAlertsRate,
                            name: '',
                            title: {
                                show: false,
                                offsetCenter: ['0%', '-20%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }, ],
                        title: {
                            fontSize: 20,
                            color: '#FFFFFF'
                        },
                        detail: {
                            width: 50,
                            height: 14,
                            fontSize:20,
                            color: 'auto',
                            borderColor: 'auto',
                            borderRadius: 20,
                            borderWidth: 0,
                            formatter: '{value}%'
                        }
                    }]
                })

                this.echarts3 = echarts.init(document.getElementById('echarts3'));
                this.echarts3.setOption({
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '50%'],
                        radius: '100%', //设置仪表盘的大小
                        pointer: {
                            show: false
                        },
                        progress: {
                            show: true,
                            overlap: false,
                            roundCap: true,
                            clip: false,
                            itemStyle: {
                                borderWidth: 0,
                                borderColor: '#464646',
                                // linear-gradient(0deg, #2093FF 0%, #A4CCE9 100%)
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 8
                            }
                        },
                        splitLine: {
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: this.totalUnAckAlertsRate,
                            name: 'name',
                            title: {
                                show: false,
                                offsetCenter: ['0%', '-20%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }, ],
                        title: {
                            fontSize: 20,
                            color: '#FFFFFF'
                        },
                        detail: {
                            width: 50,
                            height: 14,
                            fontSize: 20,
                            color: 'auto',
                            borderColor: 'auto',
                            borderRadius: 20,
                            borderWidth: 0,
                            formatter: "{value}%"
                        }
                    }]
                })


            },

        }
    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
        font-size: 14px;
    }

    .board_bgd {
        width: 100vw;
        height: 85vh;
        background-image: url('../../assets/images/comprehensive/Map.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        /* padding: 0 20px; */
    }

    .topTitle {
        width: 634px;
        height: 66px;
        background-image: url('../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 23vh;
        color: #FFFFFF;
        font-size: 14px;
        /* margin-bottom: 10px; */
    }

    .diy_col2 {
        height: 80vh;
        color: #FFFFFF;
        font-size: 14px;
        /* margin-bottom: 10px; */
    }

    .diy_div {
        /* width: 100%; */
        width: 300px;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-top: 12px; */
        padding: 12px;
        opacity: 0.8;
        /* display: flex;
    flex-direction: column; */
        /* align-items: center; */
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        /* background-image: url('../../assets/images/comprehensive/middle-box1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; */
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .map_div {
        height: 100%;
        width: 100%;
        /* margin-top: 10px; */
        /* margin-left: 70px; */
        /* padding: 0px 20px; */
    }

    .box1 {
        width: 350px;
        height: 60%;
        background: rgba(96, 96, 96, .3);
        border: 0 solid #6c6c6c;
        box-shadow: 0 1rem 2rem 0 rgb(0 0 0 / 83%);
        /* padding-top: 20px; */
        padding: 20px;
        color: #FFFFFF;
        /* opacity: 0.3; */
    }

    .box1_title {
        flex: 1;
        /* padding-left: .43rem; */
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        background: linear-gradient(90deg, #737373, hsla(0, 0%, 45.1%, 0))
    }

    .coordinate {
        position: absolute;
        top: 490px;
        right: 455px;
    }


    /* 地图css--开始 */

    #mymap {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    body {
        font-weight: 500;
        color: #fff;
        background-color: #000;
        position: relative;
    }

    .demo-title {
        position: absolute;
        top: 25px;
        left: 25px;
        z-index: 1;
    }

    h1 {
        font-size: 18px;
        margin: 0;
        color: rgb(180, 180, 190);
    }

    h3 {
        font-size: 12px;
        font-weight: normal;
        margin-top: 5px;
        color: rgb(150, 150, 150);
    }

    .time-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 80vw;
        height: 100px;
        max-width: 800px;
        position: absolute;
        z-index: 0;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(38, 38, 38, 0.4);
    }

    .item {
        width: 20px;
        height: 20px;
        background-color: #12bfbf;
        position: relative;
        border-radius: 50%;
    }

    .item:hover {
        cursor: pointer;
    }

    .item::before,
    .item::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        background-color: #12bfbf;
        width: 13.3333333333vw;
        height: 2px;
        max-width: 133.3333333333px;
    }

    .item::before {
        left: calc(-13.3333333333vw + 10px);
    }

    .item::after {
        right: calc(-13.3333333333vw + 10px);
    }

    .item:first-child::before {
        width: 0;
    }

    .item:last-child::after {
        width: 0;
    }

    .item.active {
        background-color: #12bfbf;
    }

    .item.active span {
        background-color: #12bfbf;
        font-weight: 700;
    }

    .item.active span::before {
        font-size: 15px;
    }

    .item.active span::after {
        font-size: 15px;
    }

    .item span {
        width: 14px;
        height: 14px;
        background-color: #262626;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .item span::after {
        visibility: visible;
        position: absolute;
        left: 50%;
        content: attr(data-info);
        top: 25px;
        transform: translateX(-50%);
        font-size: 14px;
        width: 70px;
        text-align: center;
    }

    @media (min-width: 1000px) {
        .item::before {
            left: -123.3333333333px;
        }

        .item::after {
            right: -123.3333333333px;
        }
    }

    @media (max-width: 850px) {
        .item {
            width: 10px;
            height: 10px;
        }

        .item::before,
        .item::after {
            height: 3px;
        }

        .item::before {
            left: calc(-13.3333333333vw + 5px);
        }

        .item::after {
            right: calc(-13.3333333333vw + 5px);
        }
    }

    @media (max-width: 600px) {
        .flex-parent {
            justify-content: initial;
        }

        .time-container {
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            height: auto;
            margin-top: 15vh;
        }

        .item {
            width: 60px;
            height: 60px;
            margin: 0 10px 50px;
        }

        .item::before,
        .item::after {
            content: none;
        }

        .item span {
            width: 100%;
            height: 100%;
            display: block;
        }

        .item span::after {
            top: 50%;
            transform: translate(-50%, -50%);
            color: #12bfbf;
        }

        .item.active span::after {
            color: #fff;
        }
    }

    /* 地图css--结束 */

    .data_box {
        position: absolute;
        z-index: 888;
        width: 400px;
        height: 94%;
        /* background-color: #12bfbf; */
        /* E:\sdkj\sdkj7.5-1\src\assets\images\comprehensive */
        background-image: url('../../assets/images/comprehensive/bgi9.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-left: 20px;
        padding: 10px;
        color: #FFFFFF;
    }

    .title_box {
        width: 380px;
        height: 38px;
        background-image: url('../../assets/images/comprehensive/title1.png');
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        align-content: center;
        padding: 10px;
        font-size: 18px;

    }

    .title_box img {
        width: 18px;
        height: 18px;
    }

    .title_box span {
        margin-left: 15px;
    }

    .cricle_div {
        /* width: 36%;
        height: 82%; */
        width: 100px;
        height: 100px;
        border: 12px solid #00A0E9;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin: 10px 20px; */
    }
    .wH {
        width: 200px;
        height: 170px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        /* margin: 10px 20px; */
    }
    .wH120 {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin: 10px 20px; */
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-progress-bar__outer {
        background-color: #0C2E8C;
    }

    ::v-deep .el-progress__text {
        color: #FDDD60;
        font-size: 26px;
        font-weight: 700;
        display: block;
    }
</style>