<!-- 工艺管理页面 -->
<template>
    <div class="fn_size16 " v-loading="loading">
        <el-row>
            <!-- 左侧 -->
            <el-col :span="3">
                <div class="pd_20 bgc_FFFFFF tree_height scroll-x" style="overflow-y:auto">
                    <el-tree ref="tree" :data="treeData" :props="defaultProps" node-key="ID"
                        :default-expanded-keys="defaultExpandKeys" accordion @node-click="handleNodeClick">
                    </el-tree>
                </div>
            </el-col>
            <!-- 右侧 -->
            <el-col :span="21" class="pdl20 pdr10 tree_height" style="overflow-y:auto">
                <div class="bgc_FFFFFF ">
                    <div class="box_head1 mg_top_20">加工节拍分析</div>
                    <div class="flex fn_size14">
                        <!-- pdl20 -->
                        <div class="flex flex_column pdl20 pdt_30 pdb_20 pdr20"
                            style="width:28%;border-right: 1px solid #CCDBE5;">
                            <div class="flex  flex_column  align_center">
                                <div class="flex  flex_column align_start">
                                    <div class="">
                                        <!-- 开始时间：2020-11-11 ~ 2020-11-19 -->
                                        <el-date-picker v-model="rhythmTime" style="width:370px" type="datetimerange"
                                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                            @change="changeRhythmProgramNumber">
                                        </el-date-picker>
                                    </div>
                                    <div class="height_40 flex mg_top_10 mg_bottom_10">
                                        <div> 程序号：</div>
                                        <el-select v-model="rhythmProgramNumberValue" multiple collapse-tags
                                            placeholder="请选择" class="mg_left_8 width_160">
                                            <el-option v-for="item in rhythmProgramNumber" :key="item.value"
                                                :label="item.name" :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-button type="primary" size="mini" class="bgc_009BFD mg_left_10 btn"
                                            @click="rhythmSearch">确认
                                        </el-button>
                                    </div>
                                </div>

                            </div>
                            <div class="flex align_center mg_top_10  justify_between">
                                <!-- leftStuse:false,   rightStuse:true -->
                                <!-- <img src="../../assets/images/deviceManagement/s2_blue.png" v-if="leftStuse" alt=""
                                    @click="leftBtn">
                                <img src="../../assets/images/deviceManagement/s_grey.png" v-if="!leftStuse" alt=""> -->
                                <!-- <el-button style="width:36px;height:36px" @click="leftBtn"></el-button> -->
                                <!-- <div class="bgc_F5F5F5 fn_size12" style="width:75%">
                                    <div v-for="(item,index) in testData" :key="index"
                                        :class="index==tabIndex?'':'disNone'">
                                        <div class="height_40">
                                            CNC今日平均节拍：5件/小时{{item}}
                                        </div>
                                        <div class="height_40">
                                            1#工件平均节拍：1.7件/小时
                                        </div>
                                        <div class="height_40">
                                            1#工件最小周期：22min
                                        </div>
                                        <div class="height_40 ">
                                            1#工件最大周期：29min
                                        </div>
                                    </div>
                                </div> -->
                                <div style="width:100%">
                                    <el-carousel :autoplay="false" arrow="always">
                                        <el-carousel-item v-for="(item,index) in rhythmList.list" :key="index">
                                            <div style="display:flex;justify-content:center;align-items:center">
                                                <div class="bgc_F5F5F5 fn_size12" style="width:75%">
                                                    <div class="height_40">
                                                        {{currentTreeName}}今日平均节拍：{{rhythmList.totalAvg}}件/小时
                                                    </div>
                                                    <div class="height_40">
                                                        {{item.name}}平均节拍：{{item.avgCycle}}件/小时
                                                    </div>
                                                    <div class="height_40">
                                                        {{item.name}}最小周期：{{item.minCycle}}min
                                                    </div>
                                                    <div class="height_40 ">
                                                        {{item.name}}最大周期：{{item.maxCycle}}min
                                                    </div>
                                                </div>
                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                                <!-- <img src="../../assets/images/deviceManagement/s_blue.png" v-if="rightStuse" alt=""
                                    @click="rightBtn">
                                <img src="../../assets/images/deviceManagement/s2_grey.png" v-if="!rightStuse" alt=""> -->
                            </div>
                        </div>
                        <div class="" style="width: 72%;">
                            <div id="timeSlice2" style="width: 100%;height: 100%;"></div>
                            <!-- <div id="echartsLine1" style="width:100%;height: 100%;"></div> -->
                        </div>
                    </div>
                    <!-- mg_top_20 -->
                    <div class="box_head1 ">加工工艺参数</div>
                    <div class=" flex fn_size14">
                        <div class="flex flex_column pdl20 pdt_30 pdb_20 pdr20"
                            style="width:28%;border-right: 1px solid #CCDBE5;">
                            <div class="flex  flex_column  align_center">
                                <div class="flex  flex_column align_start">
                                    <div class="">
                                        <el-date-picker v-model="processTime" style="width:370px" type="datetimerange"
                                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                        </el-date-picker>
                                    </div>
                                    <div class="height_40 flex mg_top_10 mg_bottom_10">
                                        <div class="width_70"> 程序号：</div>
                                        <el-cascader v-model="processProgramNumberValue" :options="processProgramNumber"
                                            ref="demoCascader" :show-all-levels="false" :props="propsCascader"
                                            collapse-tags @change="changeProcessProgram">
                                        </el-cascader>
                                        <!-- <el-select v-model="processProgramNumberValue" multiple collapse-tags
                                            placeholder="请选择" class="mg_left_8 width_160">
                                            <el-option v-for="item in processProgramNumber" :key="item.ID"
                                                :label="item.Name" :value="item.ID">
                                            </el-option>
                                        </el-select> -->
                                    </div>
                                    <div class="height_40 flex  mg_bottom_10">
                                        <div> 选择属性：</div>
                                        <el-select v-model="processAttributeValue" placeholder="请选择" multiple
                                            collapse-tags class="mg_left_8 width_160">
                                            <el-option v-for="item in processAttribute" :key="item.value"
                                                :label="item.name" :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-button type="primary" size="mini" class="bgc_009BFD mg_left_10 btn"
                                            @click="processSearch">确认
                                        </el-button>
                                    </div>
                                </div>

                            </div>
                            <div class="flex align_center mg_top_10 justify_between">
                                <!-- leftStuse:false,   rightStuse:true -->
                                <div style="width:100%;">
                                    <el-carousel :autoplay="false" arrow="always">
                                        <el-carousel-item v-for="(item,index) in processList" :key="index">
                                            <div style="display:flex;justify-content:center;align-items:center">
                                                <div class="bgc_F5F5F5 fn_size12" style="width:75%">
                                                    <div class="flex mg_left_10">
                                                        <div class="">
                                                            <div class="height_40 text_align_left">
                                                                切削速度：{{item.cuttingSpeed}}mm/min
                                                            </div>
                                                            <div class="height_40 text_align_left">
                                                                主轴负载：{{item.spindleLoad}}N</div>
                                                            <div class="height_40 text_align_left">
                                                                主轴负载率：{{item.spindleLoadRate}}%</div>
                                                            <div class="height_40 text_align_left">
                                                                切削液PH：{{item.cuttingFluid}}</div>
                                                        </div>
                                                        <div class="mg_left_30">
                                                            <div class="height_40 text_align_left">
                                                                进给速度倍率：{{item.feedRateRatio}}</div>
                                                            <div class="height_40 text_align_left">
                                                                主轴温度：{{item.spindleTemp}}℃</div>
                                                            <div class="height_40 text_align_left">
                                                                主轴转速：{{item.spindleSpeed}}rpm/min
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>

                                <!-- <img src="../../assets/images/deviceManagement/s2_blue.png" v-if="leftStuse" alt=""
                                    @click="leftBtn">
                                <img src="../../assets/images/deviceManagement/s_grey.png" v-if="!leftStuse" alt="">
                                <div class="bgc_F5F5F5 fn_size12" style="width:78%">
                                    <div v-for="(item,index) in testData" :key="index"
                                        :class="index==tabIndex?'':'disNone'">
                                        <div class="flex mg_left_10">
                                            <div class="">
                                                <div class="height_40 text_align_left">切削速度：30mm/min{{item}}</div>
                                                <div class="height_40 text_align_left">主轴负载：2000N</div>
                                                <div class="height_40 text_align_left">主轴负载率：35%</div>
                                            </div>
                                            <div class="mg_left_30">
                                                <div class="height_40 text_align_left">进给速度倍率：150</div>
                                                <div class="height_40 text_align_left">主轴温度：40℃</div>
                                                <div class="height_40 text_align_left">主轴转速：2100rpm/min</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img src="../../assets/images/deviceManagement/s_blue.png" v-if="rightStuse" alt=""
                                    @click="rightBtn">
                                <img src="../../assets/images/deviceManagement/s2_grey.png" v-if="!rightStuse" alt=""> -->
                            </div>
                        </div>
                        <!-- <div class="flex flex_column align_start pdt_30 pdb_20 "
                            style="width:24%;border-right: 1px solid #CCDBE5;">
                            <div class="flex ">
                                <div class="">
                                    <div class="height_40 text_align_left">切削速度：30mm/min</div>
                                    <div class="height_40 text_align_left">主轴负载：2000N</div>
                                    <div class="height_40 text_align_left">主轴负载率：35%</div>
                                </div>
                                <div class="mg_left_30">
                                    <div class="height_40 text_align_left">进给速度倍率：150</div>
                                    <div class="height_40 text_align_left">主轴温度：40℃</div>
                                    <div class="height_40 text_align_left">主轴转速：2100rpm/min</div>
                                </div>
                            </div>
                            <div class="height_40">
                                切削液PH：6.0
                            </div>
                            <div class="height_40">
                                开始时间：2020-11-11 ~ 2020-11-19
                            </div>
                            <div class="height_40 flex mg_top_10 mg_bottom_10">
                                <div> 程序号：</div>
                                <el-select v-model="value" placeholder="全部" class="mg_left_8 width_110">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-button type="primary" class="bgc_009BFD mg_left_10 btn">确认</el-button>
                            </div>
                        </div> -->
                        <div class="" style="width: 72%;">
                            <div id="echartsLine2" style="width:100%;height: 100%;"></div>
                        </div>
                    </div>
                    <div class="  mg_top_20">
                        <div class="height_510">
                            <div class="box_head1 ">加工时间分析</div>
                            <div class="flex align_center mg_top_22 pdl20 ">
                                <div>开始时间：
                                    <el-date-picker v-model="timeAnalysisTime" style="width:370px" type="datetimerange"
                                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                        @change="analysisTimeChange">
                                    </el-date-picker>
                                </div>
                                <div class="mg_left_20">程序号：</div>
                                <el-select v-model="timeAnalysisValue" placeholder="请选择" class="mg_left_8 width_160"
                                    multiple collapse-tags>
                                    <el-option v-for="item in timeAnalysis" :key="item.value" :label="item.name"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-button type="primary" class="bgc_009BFD mg_left_10 btn" @click="timeAnalysisSearch">
                                    确认</el-button>
                            </div>
                            <div id="echartsBar" style="width:100%;height: 80%;"></div>
                        </div>
                    </div>

                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    //   import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';
    import {
        craftManagementWorkRhythmAnalysisProgramNumberSelect,
        craftManagementOrganizationSelect,
        craftManagementWorkRhythmAnalysisTData,
        craftManagementWorkRhythmAnalysisTrend,

        craftManagementCraftParameterAnalysisProgramNumberSelect,
        craftManagementCraftParameterAnalysisEquipmentPropertySelect,
        craftManagementCraftParameterAnalysisTrend,
        craftManagementCraftParameterAnalysisData,

        craftManagementCraftTimeAnalysisProgramNumberSelect,
        craftManagementCraftTimeAnalysis
    } from '@/api/api_productionProcess'

    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                value1: null,
                defaultProps: {
                    children: 'children',
                    label: 'Name'
                },
                testData: [1, 2, 3],
                leftStuse: false,
                rightStuse: true,
                treeData: [],
                permission: {},
                loading: true,
                defaultExpandKeys: [],
                currentTreeId: '',
                currentTreeName: '',
                rhythmTime: [new Date(new Date().toLocaleDateString()), new Date()],
                rhythmProgramNumberValue: [],
                rhythmProgramNumber: [],

                processTime: [new Date(new Date().toLocaleDateString()), new Date()],
                propsCascader: {
                    children: 'children',
                    label: 'Name',
                    value: 'ID',
                    multiple: true,
                    emitPath: false //只返回选中节点的值
                },
                processProgramNumberValue: [],
                processProgramNumber: [],
                rhythmList: {},

                processAttributeValue: [],
                processAttribute: [],

                timeAnalysisTime: [new Date(new Date().toLocaleDateString()), new Date()],
                currentDataProcess: [],
                processList: [],

                timeAnalysisValue: [],
                timeAnalysis: []


            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.getTreeData();
            // this.drawShape();
            // this.aaa();
        },
        methods: {
            async getTreeData() {
                var param = {
                    permission: this.permission,
                    // treeType:'1'
                };
                const selectRes = await craftManagementOrganizationSelect(param);
                this.treeData = selectRes.data.Tree;
                var textData = selectRes.data.DefautEquipmentID; //默认展开的树的id;
                this.currentTreeId = selectRes.data.DefautEquipmentID;
                //  this.currentTreeName = node.Name;
                this.$nextTick(() => {
                    if (this.$refs['tree']) {
                        // this.$refs['leftNavigation'].open(data[a].TreeID); // 打开子菜单
                        this.$refs['tree'].setCurrentKey(textData);
                        this.defaultExpandKeys = [];
                        this.defaultExpandKeys.push(textData)
                    }
                    const obj = this.$refs['tree'].getCurrentNode() //获取当前被选中节点的 data
                    this.currentTreeName = obj.Name
                    // console.log(obj)
                });


                this.craftManagementWorkRhythmAnalysisProgramNumberSelect();
                this.craftManagementCraftParameterAnalysisProgramNumberSelect();
                // this.craftManagementCraftParameterAnalysisEquipmentPropertySelect();
                // this.craftManagementWorkRhythmAnalysisTData()

                this.craftManagementCraftTimeAnalysisProgramNumberSelect();
                this.loading = false;
            },
            // 加工节拍分析--开始
            async craftManagementWorkRhythmAnalysisProgramNumberSelect() {
                this.rhythmProgramNumberValue = [];
                var param = {
                    equipmentID: this.currentTreeId,
                    startDate: this.rhythmTime[0],
                    endDate: this.rhythmTime[1],
                };
                const selectRes = await craftManagementWorkRhythmAnalysisProgramNumberSelect(param);
                this.rhythmProgramNumber = selectRes.data;
                for (var i = 0; i < this.rhythmProgramNumber.length; i++) {
                    this.rhythmProgramNumberValue.push(this.rhythmProgramNumber[i].value)
                }
                console.log(this.rhythmProgramNumberValue)
                // this.rhythmProgramNumberValue[0] = this.rhythmProgramNumber[0].value;
                this.craftManagementWorkRhythmAnalysisTData();
                this.craftManagementWorkRhythmAnalysisTrend();
            },
            changeRhythmProgramNumber() {
                this.craftManagementWorkRhythmAnalysisProgramNumberSelect()
            },
            rhythmSearch() {
                this.craftManagementWorkRhythmAnalysisTData();
                this.craftManagementWorkRhythmAnalysisTrend();
            },

            async craftManagementWorkRhythmAnalysisTData() {
                var param = {
                    equipmentID: this.currentTreeId,
                    startDate: this.rhythmTime[0],
                    endDate: this.rhythmTime[1],
                    // programNumber:this.rhythmProgramNumberValue.join(',')
                };
                if (this.rhythmProgramNumberValue.length != 0) {
                    param.programNumber = this.rhythmProgramNumberValue.join(',')
                }
                const selectRes = await craftManagementWorkRhythmAnalysisTData(param);
                this.rhythmList = selectRes.data
            },
            async craftManagementWorkRhythmAnalysisTrend() {
                var param = {
                    equipmentID: this.currentTreeId,
                    startDate: this.rhythmTime[0],
                    endDate: this.rhythmTime[1],
                };
                if (this.rhythmProgramNumberValue.length != 0) {
                    param.programNumber = this.rhythmProgramNumberValue.join(',')
                }
                const selectRes = await craftManagementWorkRhythmAnalysisTrend(param);
                // this.timeSliceTitle = selectRes.data[selectRes.data.length - 1].name;
                var testData = selectRes.data;
                this.drawTimeSlice2(testData)
                // 时间片

            },
            // 加工节拍分析--结束

            //  加工工艺参数--开始
            // 程序号
            async craftManagementCraftParameterAnalysisProgramNumberSelect() {
                this.processProgramNumberValue = [];
                var param = {
                    equipmentID: this.currentTreeId,
                    startDate: this.processTime[0],
                    endDate: this.processTime[1]
                };
                const selectRes = await craftManagementCraftParameterAnalysisProgramNumberSelect(param);
                this.processProgramNumber = selectRes.data;
                for (var i = 0; i < this.processProgramNumber.length; i++) {
                    var children = this.processProgramNumber[i].children
                    for (var j = 0; j < children.length; j++) {
                        this.processProgramNumberValue.push(children[j].ID)
                    }
                    break
                }
                // this.craftManagementCraftParameterAnalysisTrend();
                // this.craftManagementCraftParameterAnalysisData();
                this.craftManagementCraftParameterAnalysisEquipmentPropertySelect();

            },
            changeProcessProgram() {
                console.log(this.processProgramNumberValue)
                // const obj = this.$refs['demoCascader'].getCheckedNodes()
                // // console.log(obj);
                // this.currentDataProcess = []
                // for (var i = 0; i < obj.length; i++) {
                //     if (!obj[i].hasChildren) {
                //         this.currentDataProcess.push(obj[i].value)
                //     }
                // }
                // console.log(this.currentDataProcess)
            },
            //  设备属性选择框
            async craftManagementCraftParameterAnalysisEquipmentPropertySelect() {
                this.processAttributeValue = [];
                var param = {
                    equipmentID: this.currentTreeId,
                };
                const selectRes = await craftManagementCraftParameterAnalysisEquipmentPropertySelect(param);
                this.processAttribute = selectRes.data;
                this.processAttributeValue.push(selectRes.data[0].value);
                console.log(this.processAttributeValue)
                this.craftManagementCraftParameterAnalysisTrend();
                this.craftManagementCraftParameterAnalysisData();
            },
            async craftManagementCraftParameterAnalysisTrend() {
                this.echartsLine2 = echarts.init(document.getElementById('echartsLine2'));
                echarts.init(document.getElementById('echartsLine2')).dispose(); //销毁echarts
                // console.log(this.currentDataProcess)
                var param = {
                    equipmentID: this.currentTreeId,
                    workpieceID: this.processProgramNumberValue.join(','),
                    propertyName: this.processAttributeValue.join(','),
                    equipmentCategory: 'CNC'
                };
                const selectRes = await craftManagementCraftParameterAnalysisTrend(param);
                // selectRes.data
                var line = {
                    legend: [],
                    xAxis: [],
                    series: []
                }
                var testDate = selectRes.data;
                if (!testDate.time) {
                    return
                }
                line.xAxis = testDate.time;
                for (var i = 0; i < testDate.value.length; i++) {
                    var currentDate = {
                        name: '',
                        type: 'line',
                        // stack: '总量',
                        data: [],
                    }
                    currentDate.name = testDate.value[i].name;
                    currentDate.data = testDate.value[i].value;
                    line.series.push(currentDate);
                    line.legend.push(testDate.value[i].name)
                }
                console.log(line)

                this.drawechartsLine2(line);
            },
            processSearch() {
                this.craftManagementCraftParameterAnalysisTrend();
                this.craftManagementCraftParameterAnalysisData();
            },
            async craftManagementCraftParameterAnalysisData() {
                var param = {
                    equipmentID: this.currentTreeId,
                    workpieceID: this.processProgramNumberValue.join(','),
                    propertyName: this.processAttributeValue.join(',')
                };
                const selectRes = await craftManagementCraftParameterAnalysisData(param);
                this.processList = selectRes.data
            },

            // 加工工艺参数--结束

            // 加工时间分析--开始
            async craftManagementCraftTimeAnalysisProgramNumberSelect() {
                this.timeAnalysisValue = [];
                var param = {
                    equipmentID: this.currentTreeId,
                    startDate: this.timeAnalysisTime[0],
                    endDate: this.timeAnalysisTime[1]
                };
                const selectRes = await craftManagementCraftTimeAnalysisProgramNumberSelect(param);
                this.timeAnalysis = selectRes.data;
                // if(){}
                for (var i = 0; i < this.timeAnalysis.length; i++) {
                    this.timeAnalysisValue.push(this.timeAnalysis[i].value)
                    break
                }
                // this.timeAnalysisValue[0] = selectRes.data[0].value;
                this.craftManagementCraftTimeAnalysis();
            },
            analysisTimeChange() {
                this.craftManagementCraftTimeAnalysisProgramNumberSelect()
            },
            async craftManagementCraftTimeAnalysis() {
                var param = {
                    equipmentID: this.currentTreeId,
                    startDate: this.timeAnalysisTime[0],
                    endDate: this.timeAnalysisTime[1]
                };
                if (this.timeAnalysisValue.length != 0) {
                    param.programNumber = this.timeAnalysisValue.join(',')
                }
                const selectRes = await craftManagementCraftTimeAnalysis(param);
                // 图例：avg、max、min
                // x  name:程序1、程序2
                var testDate = selectRes.data;
                var line2 = {
                    xAxis: [],
                    series: [{
                        name: testDate.lenged[0],
                        data: testDate.maxList,
                        type: 'bar',
                        // stack: 1,
                        barGap: 0,
                        barWidth: 24, //柱的宽度
                        smooth: true
                    }, {
                        name: testDate.lenged[1],
                        data: testDate.minList,
                        type: 'bar',
                        // stack: 1,
                        barGap: 0,
                        barWidth: 24, //柱的宽度
                        smooth: true
                    }, {
                        name: testDate.lenged[2],
                        data: testDate.avgList,
                        type: 'bar',
                        // stack: 1,
                        barGap: 0,
                        barWidth: 24, //柱的宽度
                        smooth: true
                    }]
                }
                line2.lenged = testDate.lenged;
                line2.xAxis = testDate.nameList;

                // console.log(line2)
                this.drawShape(line2);


            },
            timeAnalysisSearch() {
                this.craftManagementCraftTimeAnalysis();
            },
            // 加工时间分析--结束
            leftBtn() {
                console.log(this.tabIndex)
                if (this.tabIndex > 0) {
                    this.tabIndex -= 1;
                    this.leftStuse = true;
                    this.rightStuse = true;
                    if (this.tabIndex == 0) {
                        this.leftStuse = false;
                    }
                } else {
                    this.leftStuse = false;
                }
            },
            rightBtn() {
                // console.log(this.tabIndex)
                if (this.tabIndex < this.testData.length - 1) {
                    this.tabIndex += 1;
                    console.log(this.tabIndex)
                    this.rightStuse = true;
                    this.leftStuse = true;
                    if (this.tabIndex == this.testData.length - 1) {
                        this.rightStuse = false;
                    }
                } else {
                    this.rightStuse = false;
                }
            },
            // leftTabChange(id) {
            //     this.leftTabIndex = id;
            // },
            handleNodeClick(node) {
                // console.log(node);
                this.currentTreeId = node.ID;
                this.currentTreeName = node.Name;

                this.craftManagementWorkRhythmAnalysisProgramNumberSelect();
                this.craftManagementCraftParameterAnalysisProgramNumberSelect();
                // this.craftManagementCraftParameterAnalysisEquipmentPropertySelect();
                // this.craftManagementWorkRhythmAnalysisTData()

                this.craftManagementCraftTimeAnalysisProgramNumberSelect();


            },
            drawTimeSlice2(data) {
                var that = this;
                var startTime = that.rhythmTime[0].getTime()
                var categories = ['状态时间']

                function renderItem(params, api) {
                    // console.log(params, api)
                    // console.log(api.value(0), api.value(1), api.value(2))
                    var categoryIndex = api.value(0) //对应的三类中的一类
                    var start = api.coord([api.value(1), categoryIndex]) //起始点坐标
                    var end = api.coord([api.value(2), categoryIndex]) //结束点坐标
                    var height = api.size([0, 1])[1] * 0.6
                    // console.log(api.value(2) - api.value(1))
                    var rectShape = echarts.graphic.clipRectByRect({
                        x: start[0],
                        y: start[1] - height / 2,
                        width: end[0] - start[0],
                        height: height
                    }, {
                        x: params.coordSys.x,
                        y: params.coordSys.y,
                        width: params.coordSys.width,
                        height: params.coordSys.height
                    });

                    return rectShape && {
                        type: 'rect',
                        transition: ['shape'],
                        shape: rectShape,
                        style: api.style()
                    };
                }
                that.timeSlice3 = echarts.init(document.getElementById('timeSlice2'));
                that.timeSlice3.setOption({
                    tooltip: {
                        formatter: function (params) {
                            // console.log(params)
                            // that.tools.formatDate(this.sliceTime[0], 'yyyy-MM-ddThh:mm:ss'),
                            return params.marker + params.name + ': ' + params.value[3] + ' s' + '<br/>' +
                                '开始时间：' + that.tools.formatDate(params.value[1], 'yyyy-MM-dd hh:mm:ss') +
                                '<br/>' + '结束时间：' + that.tools.formatDate(params.value[2],
                                    'yyyy-MM-dd hh:mm:ss')
                        },
                    },
                    dataZoom: [{
                        type: 'slider',
                        filterMode: 'weakFilter',
                        showDataShadow: false,
                        top: 400,
                        // start: 0,
                        // end: 50,
                        handleSize: 8,
                        labelFormatter: ''
                    }, {
                        type: 'inside',
                        filterMode: 'weakFilter',
                        // start: 0,
                        // end: 50
                    }],
                    // title: {
                    //     text: 'Profile',
                    //     left: 'center',
                    // },
                    grid: {
                        height: 200,
                    },
                    // x轴会动态将日期格式化
                    xAxis: {
                        min: startTime,
                        scale: true,
                        axisLabel: {
                            formatter: function (val) {
                                // console.log(val)
                                // console.log(startTime/1000, val/1000)
                                // 将日期进行整合处理
                                // console.log(Math.max(0, val - startTime) / 1000)
                                // console.log(new Date(val))
                                // return val/1000 + ' s'
                                return Math.max(0, val - startTime) / 1000 + ' s'
                            },
                        },
                    },
                    yAxis: {
                        data: categories,
                    },
                    series: [{
                        type: 'custom',
                        renderItem: renderItem,
                        //指定x映射的是data数组中每一个元素中value的第几个值
                        //将值收集起来并且进行统一范围处理  比如上面的data中有30个值就会把30个值收集然后进行10s的一个间隔进行区间处理生成x轴
                        encode: {
                            x: [1, 2],
                            y: 0,
                        },
                        data: data,
                    }],
                })
            },
            drawechartsLine2(data) {
                this.echartsLine2 = echarts.init(document.getElementById('echartsLine2'));
                this.echartsLine2.setOption({
                    title: {
                        // text: '折线图堆叠'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: data.legend,
                        bottom: '3%',
                        type: 'scroll',
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '15%',
                        containLabel: true
                    },
                    toolbox: {
                        // feature: {
                        //     saveAsImage: {}
                        // }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data.xAxis,
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: data.series
                });
            },

            drawShape(data) {
                // 柱状图开始
                echarts.init(document.getElementById('echartsBar')).dispose(); //销毁echarts
                this.echartsBar = echarts.init(document.getElementById('echartsBar'));
                this.echartsBar.setOption({
                    title: {
                        // text: '月度报警总数',
                        // textStyle: {
                        // 	color: '#FFFFFF',
                        // 	fontSize: 12,
                        // },
                        // padding: [2, 0, 0, 15]
                    },
                    // 提示框
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: data.legend,
                        // top:'0%',
                        bottom: '3%',
                        textStyle: {
                            color: '#808080',
                            fontSize: 12,

                        }
                    },
                    // color: ['#6395F9'],
                    grid: {
                        left: '4%',
                        right: '6%',
                        bottom: '15%',
                        // top: "10%",
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: data.xAxis,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#869da7',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#808080',
                            fontSize: 12,
                            rotate: 0,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        nameRotate: '0.1',
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: ['#E3E4E6']
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#869da7',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#869da7',
                            fontSize: 12,
                            //  rotate:40  
                        }
                    },
                    series: data.series
                });
            },

        }
    }
</script>

<style scoped>
    .tree_height {
        /* height: 1270px; */
        height: 790px;
    }

    .btn {
        width: 80px;
        height: 36px;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .height_510 {
        height: 510px;
    }

    .disNone {
        display: none;
    }
</style>

<style lang="less" scoped>
    ::v-deep .el-tree-node.is-current>.el-tree-node__content {
        color: #0071FD;
    }

    ::v-deep .el-date-editor .el-range-separator {
        width: 9%;
    }

    /* ::v-deep .el-textarea__inner {
        background-color: #F5F5F5;
    } */
    ::v-deep .el-carousel__container {
        height: 180px;
    }

    ::v-deep .el-carousel__indicators--horizontal {
        display: none;
    }

    ::v-deep .el-carousel__arrow {
        height: 36px;
        width: 36px;
        border-radius: 0px;
        background-color: #009BFD;
    }
</style>