<!--  设备管理-备件管理-备件购置-添加申请 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">申请信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="申请仓库:" class=" ">
                    <el-select v-model="formInline.warehouse" clearable placeholder="请选择仓库">
                        <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="申请部门:">
                     <el-select v-model="formInline.department" clearable placeholder="请选择部门">
                        <el-option v-for="item in workshopArray" :key="item.value" :label="item.name" :value="item.name"> </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="申请部门:">
                    <el-input v-model="formInline.department" placeholder="请输入申请部门" class="" ></el-input>
                </el-form-item> -->

                <el-form-item label="领用总价:">
                    <el-input v-model="formInline.totalPrice" placeholder="总价" class="width_180" disabled></el-input>
                </el-form-item>

                <el-form-item label="是否审批:">
                    <el-radio-group v-model="formInline.isAuth" class="width_180">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="3" v-model="formInline.remarks" resize="none" placeholder="请填写备注"></el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100 mg_top_20">选择备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30 height_370">
                <div class="flex align_center mg_top_20">
                    <el-button type="primary" class="width_140" style=" background: #03CABE;border: none;"
                        @click="selectSpare">
                        选择备件</el-button>

                </div>
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" height="100%">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="unit" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价" width="">
                    </el-table-column>
                    <el-table-column prop="applyQuan" label="领用数量" width="">
                    </el-table-column>
                    <el-table-column prop="totalPrice" label="总价" width="">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delTable(scope.$index)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <el-dialog title="新增备件" :visible.sync="dialogTableVisibleSpare" top='10vh' width="60%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增备件</div> -->
                <!-- pdl30 pdr30  height_370 -->
                <!-- <div class="flex flex_column "> -->
                <div class="flex flex_column mg_top_20 height_444">
                    <el-form :model="formData" ref="formData" class="mg_top_15 height_300">
                        <el-table :data="formData.tableData" border stripe style="width: 100%;" height="100%"
                            @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="55">
                            </el-table-column>
                            <el-table-column type="index" label="序号" width="70">
                            </el-table-column>
                            <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="referPrice" label="单价" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="applyQuan" label="购置数量" width="">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.' + scope.$index + '.applyQuan'"
                                        style="margin-bottom: 0px;">
                                        <el-input v-model="scope.row.applyQuan" placeholder="请输入"
                                            @input="changeInput(scope.$index)"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="totalPrice" label="总价" width="">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.' + scope.$index + '.totalPrice'"
                                        style="margin-bottom: 0px;">
                                        <el-input v-model="scope.row.totalPrice" placeholder="请输入" readonly></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Remarks" label="备注" width="">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Remarks'"
                                        style="margin-bottom: 0px;">
                                        <el-input v-model="scope.row.Remarks" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <!-- <el-input v-model="scope.row.Remarks"></el-input> -->
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                    <el-pagination layout="prev, pager, next" :total="pageQuantitySapre*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPageSpare" @current-change="handleCurrentChangeSpare">
                    </el-pagination>
                    <div class="flex justify_center pdb_20">
                        <el-button @click="dialogTableVisibleSpare = false">取 消</el-button>
                        <el-button type="primary" @click="confirmChoices">确 定</el-button>
                    </div>
                </div>
            </el-dialog>


            <div class="flex mg_top_30 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>

        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        sparePartPurchaseInsert,
        sparePartStockQueryLimit,
        sparePartWarehouseQuery,// 仓库选择下拉框
        // sparePartPickDelete,
        // sparePartPickUpdate,
        // sparePartPickQuery,
        // multiAuthorizeResultQuery1,
        // sparePartPickMultiAuthQueryLimit,
        // sparePartPickAuthorize,
        // sparePartPickDetailInsert,
        // sparePartPickDetailDelete,
        // sparePartPickDetailUpdate,
        // sparePartPickDetailQuery,
        // sparePartPickDetailQueryLimit,
    } from '@/api/api_deviceManagement.js';
     import {
        equipmentLedgerDepartment, //部门选择下拉框
    } from "@/api/api_systemConfig";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                formInline: {},
                value: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                formLabelWidth: '110px',
                tableData: [],
                dialogTableVisible: false,
                addResult: '',
                orderDetailJSON: {

                },
                formSpare: {},
                userID: '',//用户ID
                userName:'',//用户姓名
                dialogTableVisibleSpare: false,


                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0,
                currentPageSpare: 1,
                pageQuantitySapre: 0,
                formData: {
                    tableData: []
                },
                warehouseSelectList:[],//仓库下拉框 使用的数组
                workshopArray:[],//部门选择框
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
                this.formInline.userID = this.userID
                this.userName = userInfo.userName
            }
            this.equipmentLedgerDepartment(); //部门选择框
           

            //页面一加载，就初始化仓库下拉框的数据
            this.sparePartWarehouseQuery();
        },
        methods: {
             // 部门选择下拉框
            async equipmentLedgerDepartment() {
                // var param = {
                //     organization: this.checkData
                // }
                  var param = {
                    userID:this.userID,
                };
                //调用接口
                const selectRes = await equipmentLedgerDepartment(param);
                this.workshopArray = selectRes.data
            },
            //仓库下拉框
            async sparePartWarehouseQuery(){

                //封装参数
                var param = {
                    userID:this.userID,
                };

                //调用接口
                const selectRes = await sparePartWarehouseQuery(param);

                //处理返回值
                if(selectRes){//如果有返回值
                    this.warehouseSelectList = selectRes.data;
                }
            },

            delTable(row) {
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(row)
                    this.tableData.splice(row, 1)
                    console.log(this.tableData)
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            //增加
            chooseSpare() {
                this.dialogTableVisible = true
            },
            // 选择备件
            selectSpare() {
                this.formSpare = {};
                this.dialogTableVisibleSpare = true;
            },
            async sparePartPurchaseInsert() {
                // var table =[]
                // table.push(this.formSpare)
                console.log('a', this.tableData);
                this.formInline.applyPerson = this.userName;
                this.formInline.orderDetailJSON = {
                    array: this.tableData
                }
                var param = this.formInline
                const selectRes = await sparePartPurchaseInsert(param)
                if (selectRes.messageEnable) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    })
                    this.$router.go(-1);
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: "warning"
                    })
                    this.$router.go(-1);
                }
            },
            handleChangeCascader() {
                // console.log(this.organizationValue)
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)

            },
            // 选择备件--开始
            selectSpare() {
                this.formSpare = {};
                this.sparePartStockQueryLimit();
                this.dialogTableVisibleSpare = true;
            },
            // 备件台账-筛选分页数据
            async sparePartStockQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageNumber: this.currentPageSpare,
                    pageSize: this.pageSize,
                };
                const selectRes = await sparePartStockQueryLimit(param);
                // this.spareList = selectRes.rows;
                this.formData.tableData = selectRes.rows;
                this.pageQuantitySapre = selectRes.pageQuantity
            },
            handleCurrentChangeSpare() {
                this.sparePartStockQueryLimit()
            },
            handleSelectionChange(val) {
                console.log(val);
                this.currentSpareData = val;
            },
            confirmChoices() {
                let price = 0;
                let testTableData = [];
                for (var i = 0; i < this.currentSpareData.length; i++) {
                    if (!this.currentSpareData[i].applyQuan) {
                        this.$message({
                            message: '警告哦，“' + this.currentSpareData[i].sparePartName + '”请填写领用数量',
                            type: 'warning'
                        });
                        return
                    }
                    var testData = {
                        sparePartID: this.currentSpareData[i].sparePartID,
                        sparePartName: this.currentSpareData[i].sparePartName,
                        sparePartModel: this.currentSpareData[i].sparePartModel,
                        unit: this.currentSpareData[i].unit,
                        unitPrice: this.currentSpareData[i].referPrice,
                        applyQuan: this.currentSpareData[i].applyQuan,
                        totalPrice: this.currentSpareData[i].totalPrice,
                        remarks: this.currentSpareData[i].Remarks,
                    }
                    testTableData.push(testData)
                    price += Number(this.currentSpareData[i].totalPrice)
                }

                for (var i = 0; i < this.tableData.length; i++) {
                    // console.log(this.tableData[i].totalPrice)

                }
                this.formInline.totalPrice = price
                this.tableData = [];
                this.tableData = testTableData
                // console.log(this.tableData)
                // var dataList = [];
                // for (var i = 0; i < this.currentSpareData.length; i++) {
                //     if (this.formData.tableData.length != 0) {
                //         var authorityadmin = this.formData.tableData.filter(itmer => itmer.sparePartID != this
                //             .currentSpareData[i].sparePartID);
                //         // console.log(authorityadmin)
                //         this.formData.tableData = authorityadmin;
                //     }
                //     var testData = {
                //         sparePartID: this.currentSpareData[i].sparePartID,
                //         sparePartName: this.currentSpareData[i].sparePartName,
                //         sparePartModel: this.currentSpareData[i].sparePartModel,
                //         unit: this.currentSpareData[i].unit,
                //         unitPrice: this.currentSpareData[i].referPrice,
                //         applyQuan: 0,
                //         totalPrice: 0,
                //         remarks: ''
                //     }
                //     // dataList.push(testData)
                //     this.formData.tableData.push(testData);
                // }
                this.dialogTableVisibleSpare = false;
            },
            // 选择备件--结束
            changeInput(index) {
                // console.log(this.formData)
                // console.log(this.formData.tableData[index])
                this.formData.tableData[index].totalPrice = this.formData.tableData[index].referPrice * this.formData
                    .tableData[index].applyQuan;
            },
            delTable(row) {
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(row)
                    this.tableData.splice(row, 1)
                    console.log(this.tableData)
                    let price = 0;
                    for (var i = 0; i < this.tableData.length; i++) {
                        // console.log(this.tableData[i].totalPrice)
                        price += Number(this.tableData[i].totalPrice)
                    }
                    this.formInline.totalPrice = price
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            sbumit() {
                this.sparePartPurchaseInsert()
                this.formInline = {}
            },
            colsePage() {
                this.$router.go(-1);
            },
        }

    }
</script>
<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }
</style>