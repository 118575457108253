<!-- 设备经理 -->
<template>
    <div class="page_container" v-loading="loading">
        <!-- 上半部分 -->
        <div class="bgc_FFFFFF flex flex_column pdb_20 pdt_20 pdl40 pdr40">
            <div class="flex align_center">
                <div class="fn_size18 flex">维修指标</div>
                <div class="flex mg_left_30">
                    <el-select v-model="dateTypeValue" placeholder="选择时间维度" @change="changeBranchFactorySelect"
                        class="width_90">
                        <el-option v-for="item in  dataFrame" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-date-picker v-model="dataTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" class="mg_left_10 datePickerWidth" @change="changeBranchFactoryDateTime"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </div>
            </div>

            <!-- div 数据显示 -->
            <div class="flex justify_between mg_top_20 color_FFFFFF">
                <div class="bgc_03CABE box1" @click="getDeviceTotal(indicatorDataInfo.equipmentTotal)">
                    <div class="fn_size16">设备总数</div>
                    <!-- 1  -->
                    <div class="fn_size28 mg_top_17">
                        {{indicatorDataInfo.equipmentTotal&&indicatorDataInfo.equipmentTotal.equipmentNum}}</div>
                </div>
                <div class="bgc_2891FC box1" @click="getUnusualDevice(indicatorDataInfo.errTotal)">
                    <div class="fn_size16">连接异常设备</div>
                    <!-- 2 -->
                    <div class="fn_size28 mg_top_17">{{indicatorDataInfo.errTotal&&indicatorDataInfo.errTotal.errNum}}
                    </div>
                </div>
                <div class="bgc_FFA847 box1" @click="getRepair(indicatorDataInfo.failureReportTotal)">
                    <div class="fn_size16"> 本月报修</div>
                    <!-- 3 -->
                    <div class="fn_size28 mg_top_17">
                        {{indicatorDataInfo.failureReportTotal&&indicatorDataInfo.failureReportTotal.failureReportNum}}
                    </div>
                </div>
                <div class="bgc_657180 box1" @click="checkList(indicatorDataInfo.agentPointTotal)">
                    <div class="fn_size16"> 点检待办单</div>
                    <!-- 7 -->
                    <div class="fn_size28 mg_top_17">
                        {{indicatorDataInfo.agentPointTotal&&indicatorDataInfo.agentPointTotal.agentPointNum}}</div>
                </div>
                <div class="bgc_F75753 box1" @click="abnormalList(indicatorDataInfo.errPointTotal)">
                    <div class="fn_size16"> 点检异常单</div>
                    <!-- 8 -->
                    <div class="fn_size28 mg_top_17">
                        {{indicatorDataInfo.errPointTotal&&indicatorDataInfo.errPointTotal.errPointNum}}</div>
                </div>
            </div>
        </div>
        <!-- 下半部分 -->
        <!-- tab -->
        <div class="bgc_FFFFFF flex flex_column mg_top_10  pdb_10 pdl40 pdr40">
            <el-tabs v-model="tabName" @tab-click="handleClick" class="tabsHeight">
                <el-tab-pane name="first">
                    <el-badge slot="label" :value="waitDoneCount.agentNum" class="badgeitem"><span>待办</span></el-badge>
                    <div class="flex align_center">
                        <div class="color_999999 fn_size16">待办总数：</div>
                        <div class="fn_size30">{{waitDoneCount.agentNum}}</div>
                        <div class="color_999999 fn_size16 mg_left_50">今日已办：</div>
                        <div class="fn_size30">{{waitDoneCount.doneNum}}</div>
                        <!-- doneNum -->
                    </div>
                    <!-- 下拉框  -->
                    <div class="flex align_center mg_top_15">
                        <span class="fn_size14">执行人：</span>
                        <el-select v-model="option_peopleValue" placeholder="请选择" class="mg_right_10 width_150" multiple
                            collapse-tags>
                            <el-option v-for="(item,index) in option_people" :key="index" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                        <el-select v-model="waitDoneTableArValue" placeholder="请选择" class="mg_right_10 width_150">
                            <el-option v-for="item in waitDoneTableAr" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                        <el-date-picker v-model="waitDoneTime" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10" :picker-options="pickerOptions">
                        </el-date-picker>
                        <el-button type="primary" size="mini" @click="queryWaitDone"><i class="select_icon"></i>
                        </el-button>
                    </div>
                    <!-- 折叠面板 -->
                    <div class="mg_top_10 collapseStyle">
                        <!-- 维修 -->
                        <el-table :data="waitDoneList" style="width: 100%" height="90%" v-if="currentTable==1">
                            <el-table-column type="index" label="序号" width="80">
                            </el-table-column>
                            <el-table-column prop="orderID" label="工单编号" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="deviceID" label="设备编号">
                            </el-table-column>
                            <el-table-column prop="deviceName" label="设备名称">
                            </el-table-column>
                            <el-table-column prop="deviceType" label="设备类型">
                            </el-table-column>
                            <el-table-column prop="deviceModel" label="设备型号">
                            </el-table-column>
                            <el-table-column prop="orderStatus" label="工单状态">
                            </el-table-column>
                            <el-table-column prop="deviceDepartment" label="设备所在部门">
                            </el-table-column>
                            <el-table-column prop="deviceLocation" label="设备所在地点">
                            </el-table-column>
                            <el-table-column prop="oprPerson" label="记录人">
                            </el-table-column>

                            <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execGroup" label="维修组">
                            </el-table-column>
                            <el-table-column prop="execPerson" label="维修人">
                            </el-table-column>
                            <el-table-column prop="execLevel" label="维修等级">
                            </el-table-column>
                            <el-table-column prop="execType" label="维修类型">
                            </el-table-column>
                            <el-table-column prop="execEstimateLen" label="维修预估花费时间（分钟）">
                            </el-table-column>
                            <el-table-column prop="urgenLevel" label="紧急程度">
                            </el-table-column>
                            <el-table-column label="是否停机">
                                <template slot-scope="scope">
                                    <span>{{scope.row.machineStop}}</span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="stopLen" label="停机时长（分钟）">
                            </el-table-column>
                            <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column label="是否逾期接单">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isTakeOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="startTime" label="维修开始时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column label="是否逾期保养结束">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEndOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execRejectStartTime" label="验证不通过维修开始时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execRejectEndTime" label="验证不通过维修结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execLenM" label="维修时长（分钟）">
                            </el-table-column>
                            <el-table-column prop="execFee" label="维修费用（元）">
                            </el-table-column>
                            <el-table-column prop="spotCondition" label="现场状况">
                            </el-table-column>

                            <el-table-column prop="execDesc" label="工作描述">
                            </el-table-column>
                            <el-table-column prop="execGrade" label="工单评分等级">
                            </el-table-column>
                            <el-table-column prop="isEmpirical" label="是否为经验性记录">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEmpirical}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="是否委外">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isOutEntrust}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remarks" label="备注">
                            </el-table-column>
                            <el-table-column prop="execPersonNick" label="维修人员昵称名单">
                            </el-table-column>
                            <el-table-column prop="assignEndTime" label="指定维修结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="verifyRemarks" label="验证备注">
                            </el-table-column>
                            <el-table-column prop="verifyTimeBak" label="验证时间（备用）" :formatter="formatDate">
                            </el-table-column>
                        </el-table>
                        <!-- 保养 -->
                        <el-table :data="waitDoneList" style="width: 100%" height="90%" v-if="currentTable==2">
                            <el-table-column type="index" label="序号" width="80">
                            </el-table-column>
                            <el-table-column prop="orderID" label="工单编号" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="deviceID" label="设备编号">
                            </el-table-column>
                            <el-table-column prop="deviceName" label="设备名称">
                            </el-table-column>
                            <el-table-column prop="deviceType" label="设备类型">
                            </el-table-column>
                            <el-table-column prop="deviceModel" label="设备型号">
                            </el-table-column>
                            <el-table-column prop="orderStatus" label="工单状态">
                            </el-table-column>
                            <el-table-column prop="deviceDepartment" label="设备所在部门">
                            </el-table-column>
                            <el-table-column prop="deviceLocation" label="设备所在地点">
                            </el-table-column>
                            <el-table-column prop="oprPerson" label="记录人">
                            </el-table-column>

                            <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execGroup" label="保养组">
                            </el-table-column>
                            <el-table-column prop="execPerson" label="保养人">
                            </el-table-column>
                            <el-table-column prop="execLevel" label="保养等级">
                            </el-table-column>
                            <el-table-column prop="execType" label="保养类型">
                            </el-table-column>
                            <el-table-column prop="execEstimateLen" label="保养预估花费时间（分钟）">
                            </el-table-column>
                            <el-table-column prop="urgenLevel" label="紧急程度">
                            </el-table-column>
                            <el-table-column label="是否停机">
                                <template slot-scope="scope">
                                    <span>{{scope.row.machineStop}}</span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="stopLen" label="停机时长（分钟）">
                            </el-table-column>
                            <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column label="是否逾期接单">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isTakeOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="startTime" label="保养开始时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column label="是否逾期保养结束">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEndOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execRejectStartTime" label="验证不通过保养开始时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execRejectEndTime" label="验证不通过保养结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execLenM" label="保养时长（分钟）">
                            </el-table-column>
                            <el-table-column prop="execFee" label="保养费用（元）">
                            </el-table-column>
                            <el-table-column prop="spotCondition" label="现场状况">
                            </el-table-column>

                            <el-table-column prop="execDesc" label="工作描述">
                            </el-table-column>
                            <el-table-column prop="execGrade" label="工单评分等级">
                            </el-table-column>
                            <el-table-column prop="isEmpirical" label="是否为经验性记录">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEmpirical}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="是否委外">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isOutEntrust}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remarks" label="备注">
                            </el-table-column>
                            <el-table-column prop="execPersonNick" label="保养人员昵称名单">
                            </el-table-column>
                            <el-table-column prop="assignEndTime" label="指定保养结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="verifyRemarks" label="验证备注">
                            </el-table-column>
                            <el-table-column prop="verifyTimeBak" label="验证时间（备用）" :formatter="formatDate">
                            </el-table-column>
                        </el-table>
                        <!--点检 -->
                        <el-table :data="waitDoneList" style="width: 100%" height="90%" v-if="currentTable==3">
                            <el-table-column type="index" label="序号"></el-table-column>
                            <el-table-column prop="orderID" label="点检工单编号"></el-table-column>
                            <el-table-column prop="orderType" label="点检类型"></el-table-column>
                            <el-table-column prop="orderName" label="点检工单名"></el-table-column>
                            <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                            <el-table-column label="是否异常">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isAbnormal}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="execLenM" label="执行时间长度（分钟）"></el-table-column>
                            <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                            <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                            <el-table-column prop="startTime" label="点检开始时间" :formatter="formatDate"></el-table-column>
                            <el-table-column prop="endTime" label="点检结束时间" :formatter="formatDate"></el-table-column>
                            <el-table-column label="是否逾期点检结束">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEndOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="overdueReason" label="逾期理由"></el-table-column>
                            <!-- <el-table-column prop="assignEndTime" label="指定点检结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="pause_time_list" label="暂离时间列表"></el-table-column>
                            <el-table-column prop="execGroup" label="点检班组"></el-table-column>
                            <el-table-column prop="execGroupPerson" label="点检班组成员"></el-table-column>
                            <el-table-column prop="execPerson" label="点检人"></el-table-column> -->
                            <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                            <el-table-column prop="orderLocScope" label="点检位置范围"></el-table-column>
                            <el-table-column prop="remarks" label="备注"></el-table-column>
                            <el-table-column width="80" label="点检结果列表">
                                <template slot-scope="props">
                                    <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                        <span style="border-bottom: 1px solid #009BFD;"
                                            @click="lookResult(props.row)">查看</span>
                                    </el-button>
                                </template>
                            </el-table-column>
                            <!-- 点检结果列表   orderRecordList -->
                        </el-table>
                        <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                            :current-page.sync="currentPage" @current-change="handleCurrentChangeWaitDone">
                        </el-pagination>
                    </div>

                </el-tab-pane>
                <el-tab-pane name="second">
                    <el-badge slot="label" class="badgeitem"><span>点检</span></el-badge>
                    <!-- 下拉框  -->
                    <div class="flex align_center mg_top_10">
                        <span class="fn_size14">执行人：</span>
                        <el-select v-model="djPeopleArValue" placeholder="请选择" class="mg_right_10 width_150" multiple
                            collapse-tags>
                            <el-option v-for="(item,index) in djPeopleAr" :key="index" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                        <span class="fn_size14">工单状态：</span>
                        <el-select v-model="spotOrderStatusValue" placeholder="请选择" class="mg_right_10 "
                            multiple collapse-tags>
                            <el-option v-for="item in spotOrderStatus" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                        <el-date-picker v-model="djTime" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10" :picker-options="pickerOptions">
                        </el-date-picker>
                        <el-button type="primary" size="mini" @click="queryWDJ"><i class="select_icon"></i>
                        </el-button>
                    </div>
                    <div class="mg_top_10 collapseStyle1">
                        <el-table :data="djList" style="width: 100%" height="90%">
                            <el-table-column type="index" label="序号"></el-table-column>
                            <el-table-column prop="orderID" label="点检工单编号"></el-table-column>
                            <el-table-column prop="orderType" label="点检类型"></el-table-column>
                            <el-table-column prop="orderName" label="点检工单名"></el-table-column>
                            <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                            <el-table-column label="是否异常">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isAbnormal}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="execLenM" label="执行时间长度（分钟）"></el-table-column>
                            <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                            <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                            <el-table-column prop="startTime" label="点检开始时间" :formatter="formatDate"></el-table-column>
                            <el-table-column prop="endTime" label="点检结束时间" :formatter="formatDate"></el-table-column>
                            <el-table-column label="是否逾期点检结束">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEndOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="overdueReason" label="逾期理由"></el-table-column>
                            <el-table-column prop="assignEndTime" label="指定点检结束时间" :formatter="formatDate">
                            </el-table-column>
                            <!-- <el-table-column prop="pause_time_list" label="暂离时间列表"></el-table-column> -->
                            <el-table-column prop="execGroup" label="点检班组"></el-table-column>
                            <el-table-column prop="execGroupPerson" label="点检班组成员"></el-table-column>
                            <el-table-column prop="execPerson" label="点检人"></el-table-column>
                            <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                            <el-table-column prop="orderLocScope" label="点检位置范围"></el-table-column>
                            <el-table-column prop="remarks" label="备注"></el-table-column>
                            <el-table-column width="80" label="点检结果列表">
                                <template slot-scope="props">
                                    <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                        <span style="border-bottom: 1px solid #009BFD;"
                                            @click="lookResult(props.row)">查看</span>
                                    </el-button>
                                </template>
                            </el-table-column>
                            <!-- 点检结果列表   orderRecordList -->
                        </el-table>
                        <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                            :current-page.sync="currentPage" @current-change="handleCurrentChangeDJ"></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="third">
                    <el-badge slot="label" class="badgeitem"><span>保养</span></el-badge>
                    <!-- 下拉框  -->
                    <div class="flex align_center mg_top_10">
                        <span class="fn_size14">执行人：</span>
                        <el-select v-model="maintainPeopleValue" placeholder="请选择" class="mg_right_10 width_150"
                            multiple collapse-tags>
                            <el-option v-for="(item,index) in maintainPeople" :key="index" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                        <span class="fn_size14">工单状态：</span>
                        <el-select v-model="maintainStatusValue" placeholder="请选择" class="mg_right_10 "
                            multiple collapse-tags>
                            <el-option v-for="item in maintainStatus" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                        <el-date-picker v-model="maintainTime" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10" :picker-options="pickerOptions">
                        </el-date-picker>
                        <el-button type="primary" size="mini" @click="queryMaintain"><i class="select_icon"></i>
                        </el-button>
                    </div>

                    <div class="mg_top_10 collapseStyle1">
                        <el-table :data="maintainList" style="width: 100%" height="90%">
                            <el-table-column type="index" label="序号" width="80">
                            </el-table-column>
                            <el-table-column prop="orderID" label="工单编号" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="deviceID" label="设备编号">
                            </el-table-column>
                            <el-table-column prop="deviceName" label="设备名称">
                            </el-table-column>
                            <el-table-column prop="deviceType" label="设备类型">
                            </el-table-column>
                            <el-table-column prop="deviceModel" label="设备型号">
                            </el-table-column>
                            <el-table-column prop="orderStatus" label="工单状态">
                            </el-table-column>
                            <el-table-column prop="deviceDepartment" label="设备所在部门">
                            </el-table-column>
                            <el-table-column prop="deviceLocation" label="设备所在地点">
                            </el-table-column>
                            <el-table-column prop="oprPerson" label="记录人">
                            </el-table-column>

                            <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execGroup" label="保养组">
                            </el-table-column>
                            <el-table-column prop="execPerson" label="保养人">
                            </el-table-column>
                            <el-table-column prop="execLevel" label="保养等级">
                            </el-table-column>
                            <el-table-column prop="execType" label="保养类型">
                            </el-table-column>
                            <el-table-column prop="execEstimateLen" label="保养预估花费时间（分钟）">
                            </el-table-column>
                            <el-table-column prop="urgenLevel" label="紧急程度">
                            </el-table-column>
                            <el-table-column label="是否停机">
                                <template slot-scope="scope">
                                    <span>{{scope.row.machineStop}}</span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="stopLen" label="停机时长（分钟）">
                            </el-table-column>
                            <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column label="是否逾期接单">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isTakeOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="startTime" label="保养开始时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column label="是否逾期保养结束">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEndOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execRejectStartTime" label="验证不通过保养开始时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execRejectEndTime" label="验证不通过保养结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execLenM" label="保养时长（分钟）">
                            </el-table-column>
                            <el-table-column prop="execFee" label="保养费用（元）">
                            </el-table-column>
                            <el-table-column prop="spotCondition" label="现场状况">
                            </el-table-column>

                            <el-table-column prop="execDesc" label="工作描述">
                            </el-table-column>
                            <el-table-column prop="execGrade" label="工单评分等级">
                            </el-table-column>
                            <el-table-column prop="isEmpirical" label="是否为经验性记录">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEmpirical}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="是否委外">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isOutEntrust}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remarks" label="备注">
                            </el-table-column>
                            <el-table-column prop="execPersonNick" label="保养人员昵称名单">
                            </el-table-column>
                            <el-table-column prop="assignEndTime" label="指定保养结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="verifyRemarks" label="验证备注">
                            </el-table-column>
                            <el-table-column prop="verifyTimeBak" label="验证时间（备用）" :formatter="formatDate">
                            </el-table-column>
                        </el-table>
                        <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                            :current-page.sync="currentPage" @current-change="handleCurrentChangeMaintain">
                        </el-pagination>
                    </div>

                </el-tab-pane>
                <el-tab-pane name="fourth">
                    <el-badge slot="label" class="badgeitem"><span>维修</span></el-badge>
                    <!-- 下拉框  -->
                    <div class="flex align_center mg_top_10">
                        <span class="fn_size14">执行人：</span>
                        <el-select v-model="repairPeopleValue" placeholder="请选择" class="mg_right_10 width_150" multiple
                            collapse-tags>
                            <el-option v-for="(item,index) in repairPeople" :key="index" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                        <span class="fn_size14">工单状态：</span>
                        <el-select v-model="repairStatusValue" placeholder="请选择" class="mg_right_10 " multiple
                            collapse-tags>
                            <el-option v-for="item in repairStatus" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                        <el-date-picker v-model="repairTime" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10" :picker-options="pickerOptions">
                        </el-date-picker>
                        <el-button type="primary" size="mini" @click="queryRepair"><i class="select_icon"></i>
                        </el-button>
                    </div>
                    <div class="mg_top_10 collapseStyle1">
                        <el-table :data="repairList" style="width: 100%" height="90%">
                            <el-table-column type="index" label="序号" width="80">
                            </el-table-column>
                            <el-table-column prop="orderID" label="维修工单编号" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="deviceID" label="设备编号">
                            </el-table-column>
                            <el-table-column prop="deviceName" label="设备名称">
                            </el-table-column>
                            <el-table-column prop="deviceType" label="设备类型">
                            </el-table-column>
                            <el-table-column prop="deviceModel" label="设备型号">
                            </el-table-column>
                            <!-- <el-table-column prop="orderStatus" label="工单状态">
                            </el-table-column> -->
                            <el-table-column prop="deviceDepartment" label="设备所在部门">
                            </el-table-column>
                            <el-table-column prop="deviceLocation" label="设备所在地点">
                            </el-table-column>
                            <el-table-column prop="failureTime" label="故障时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="oprPerson" label="记录人">
                            </el-table-column>
                            <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="failureCode" label="故障代码">
                            </el-table-column>

                            <el-table-column prop="failureDevPart" label="故障设备部位">
                            </el-table-column>
                            <el-table-column prop="failureLevel" label="故障等级">
                            </el-table-column>
                            <el-table-column prop="failureType" label="故障类型">
                            </el-table-column>

                            <el-table-column prop="failureDesc" label="故障描述">
                            </el-table-column>
                            <el-table-column prop="failureReason" label="故障原因">
                            </el-table-column>
                            <el-table-column prop="execGroup" label="维修组">
                            </el-table-column>

                            <el-table-column prop="execPerson" label="维修人">
                            </el-table-column>
                            <el-table-column prop="orderStatus" label="维修工单状态">
                            </el-table-column>
                            <el-table-column prop="execLevel" label="维修等级">
                            </el-table-column>
                            <el-table-column prop="execType" label="维修类型">
                            </el-table-column>
                            <el-table-column prop="execEstimateLen" label="维修预估花费时间（分钟）">
                            </el-table-column>
                            <el-table-column prop="urgenLevel" label="紧急程度">
                            </el-table-column>
                            <el-table-column label="是否停机">
                                <template slot-scope="scope">
                                    <span>{{scope.row.machineStop}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="stopLen" label="停机时长（分钟）">
                            </el-table-column>


                            <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column label="是否逾期接单">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isTakeOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="startTime" label="维修开始时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="endTime" label="维修结束时间" :formatter="formatDate">
                            </el-table-column>

                            <el-table-column label="是否逾期维修结束">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEndOverdue}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execRejectStartTime" label="验证不通过维修开始时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execRejectEndTime" label="验证不通过维修结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="execLenM" label="维修时长（分钟）">
                            </el-table-column>
                            <el-table-column prop="execFee" label="维修费用（元）">
                            </el-table-column>
                            <el-table-column prop="spotCondition" label="现场状况">
                            </el-table-column>

                            <el-table-column prop="execDesc" label="工作描述">
                            </el-table-column>
                            <el-table-column prop="execGrade" label="工单评分等级">
                            </el-table-column>
                            <el-table-column label="是否为经验性记录">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isEmpirical}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="是否委外">
                                <template slot-scope="scope">
                                    <span>{{scope.row.isOutEntrust}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remarks" label="备注">
                            </el-table-column>
                            <!-- <el-table-column prop="execPersonNick" label="保养人员昵称名单">
                            </el-table-column>
                            <el-table-column prop="assignEndTime" label="指定保养结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="verifyRemarks" label="验证备注">
                            </el-table-column>
                            <el-table-column prop="verifyTimeBak" label="验证时间（备用）" :formatter="formatDate">
                            </el-table-column> -->
                        </el-table>
                        <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                            :current-page.sync="currentPage" @current-change="handleCurrentChangeRepair">
                        </el-pagination>
                    </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="报警" name="fifth"></el-tab-pane>
                <el-tab-pane label="预留" name="sixth"></el-tab-pane>
                <el-tab-pane label="预留" name="seventh"></el-tab-pane> -->
            </el-tabs>
        </div>

        <!-- 设备总数 dialog -->
        <el-dialog :visible.sync="dialogDeviceTotal" width="70%">
            <div slot="title" class="fn_size18 flex justify_center color_black font_bold">{{dialogDeviceTitile}}</div>
            <div class="dialog__body pdb_20">
                <el-table :data="deviceData" style="width:100%;" height="100%">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="Administrators" label="设备管理员"></el-table-column>
                    <el-table-column prop="ControlSystem" label="控制系统"></el-table-column>
                    <el-table-column prop="Department" label="所属部门"></el-table-column>
                    <el-table-column prop="EquipmentCode" label="设备编号"></el-table-column>
                    <el-table-column prop="EquipmentLevel" label="设备等级"></el-table-column>
                    <el-table-column prop="EquipmentModel" label="设备型号"></el-table-column>
                    <el-table-column prop="EquipmentName" label="设备名称"></el-table-column>
                    <el-table-column prop="EquipmentType" label="设备类型"></el-table-column>
                    <el-table-column prop="InstallationPosition" label="安装位置"></el-table-column>
                    <el-table-column prop="Purchasingdate" label="购买日期"></el-table-column>
                    <el-table-column prop="UserDepartment" label="使用部门"></el-table-column>
                    <el-table-column prop="Remark" label="备注"></el-table-column>
                </el-table>
            </div>
            <!-- <div class="flex justify_between align_center">
                <div class="color_808080">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->
        </el-dialog>
        <!-- 本月报修 -->
        <el-dialog :visible.sync="dialogDeviceRepair" width="70%">
            <div slot="title" class="fn_size18 flex justify_center color_black font_bold">{{dialogDeviceTitile}}</div>
            <div class="dialog__body pdb_20">
                <el-table :data="deviceData" style="width:100%;" height="100%">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="authSort" label="审批顺序"></el-table-column>
                    <el-table-column prop="deviceDepartment" label="设备所在部门"></el-table-column>
                    <el-table-column prop="deviceID" label="设备编号"></el-table-column>
                    <el-table-column prop="deviceLocation" label="设备所在地点"></el-table-column>
                    <el-table-column prop="deviceModel" label="设备型号"></el-table-column>
                    <el-table-column prop="deviceName" label="设备名称"></el-table-column>
                    <el-table-column prop="deviceType" label="设备类型"></el-table-column>
                    <el-table-column prop="failureDesc" label="故障描述"></el-table-column>
                    <el-table-column prop="failureLevel" label="故障等级"></el-table-column>
                    <el-table-column prop="failureRptID" label="故障报修编号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="failureTime" label="故障时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="failureType" label="故障类型"></el-table-column>
                    <el-table-column label="是否需要审批">
                        <template slot-scope="scope">
                            <span>{{scope.row.isAuth}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否停机">
                        <template slot-scope="scope">
                            <span>{{scope.row.machineStop}}</span>
                            <!-- <span v-if="scope.row.machineStop == false">不停机</span>
                            <span v-if="scope.row.machineStop == true">停机</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                    <el-table-column prop="oprPhoneNumber" label="记录人电话"></el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="orderStatus" label="审批状态"></el-table-column>
                    <el-table-column prop="remarks" label="备注"></el-table-column>
                </el-table>
            </div>
            <!-- <div class="flex justify_between align_center">
                <div class="color_808080">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->
        </el-dialog>


        <!-- 点检待办单 -->
        <el-dialog :visible.sync="dialogTableVisibleAgentPointTotal" width="70%">
            <div slot="title" class="fn_size18 flex justify_center color_black font_bold">{{dialogTitile}}</div>
            <div class="dialog__body pdb_20">
                <el-table :data="agentPointDataList" height="100%">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="orderID" label="点检工单编号"></el-table-column>
                    <el-table-column prop="orderType" label="点检类型"></el-table-column>
                    <el-table-column prop="orderName" label="点检工单名"></el-table-column>
                    <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                    <el-table-column label="是否异常">
                        <template slot-scope="scope">
                            <span>{{scope.row.isAbnormal}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="execLenM" label="执行时间长度（分钟）"></el-table-column>
                    <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="startTime" label="点检开始时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="endTime" label="点检结束时间" :formatter="formatDate"></el-table-column>
                    <el-table-column label="是否逾期点检结束">
                        <template slot-scope="scope">
                            <span>{{scope.row.isEndOverdue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueReason" label="逾期理由"></el-table-column>
                    <el-table-column prop="assignEndTime" label="指定点检结束时间" :formatter="formatDate"></el-table-column>
                    <!-- <el-table-column prop="pause_time_list" label="暂离时间列表"></el-table-column> -->
                    <el-table-column prop="execGroup" label="点检班组"></el-table-column>
                    <el-table-column prop="execGroupPerson" label="点检班组成员"></el-table-column>
                    <el-table-column prop="execPerson" label="点检人"></el-table-column>
                    <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="orderLocScope" label="点检位置范围"></el-table-column>
                    <el-table-column prop="remarks" label="备注"></el-table-column>
                    <el-table-column width="80" label="点检结果列表">
                        <template slot-scope="props">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="lookResult(props.row)">查看</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <!-- 点检结果列表   orderRecordList -->
                </el-table>
            </div>
        </el-dialog>
        <!-- 点检结果列表 -->
        <el-dialog :visible.sync="dialogTableVisibleResult">
            <div slot="title" class="fn_size18 flex justify_center color_black font_bold">点检结果列表</div>
            <div class="height_580 pdb_20">
                <el-table :data="orderRecordList" max-height="580">
                    <el-table-column type="index" label="序号" width="150"></el-table-column>
                    <el-table-column property="inspDeviceID" label="设备编号" width="200"></el-table-column>
                    <el-table-column property="remarks" label="备注"></el-table-column>
                    <el-table-column type="expand" label="点检内容" width="80">
                        <template slot-scope="props">
                            <el-collapse accordion>
                                <el-collapse-item v-for="(item,index) in props.row.inspDeviceContent" :key="index">
                                    <template slot="title">
                                        <span class="font_bold"> 点检ID：{{item.inspStandardID}}</span>
                                    </template>
                                    <el-card shadow="always" v-for="(items,index1) in item.inspStandardDetail"
                                        class="mg_top_10" :key="index1">
                                        <div class="flex flex_column align_start">
                                            <div class="height_30">点检详情ID：{{items.inspStandardDetailID}}</div>
                                            <div class="height_30">点检详情类型：{{items.inspStandardDetailType}}</div>
                                            <div class="height_30">点检标准详情记录：{{items.inspStandardDetailRecord}}</div>
                                            <div class="height_30">点检详情内容：{{items.inspStandardDetailContent}}</div>
                                            <div class="height_30">点检标准详情：{{items.inspStandardDetailStandard}}</div>
                                            <div class="height_30">点检详情记录状态：{{items.inspStandardDetailRecordStatus}}
                                            </div>
                                        </div>
                                    </el-card>
                                </el-collapse-item>
                            </el-collapse>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    //   import innerTopNav from './../../components/innerTopNav'
    import {
        repairIndicatorData,
        repairIndicatorDetailList,

        // 待办
        repairWaitDoneCount,
        repairWaitDoneExecutorSelect,
        repairWaitDoneTableSelect,
        repairWaitDoneWorkOrderList,

        // 点检
        repairPointInspectioExecutorSelect,
        repairPointInspectioWorkOrderExecutorSelect,
        repairPointInspectioWorkOrderList,

        // 保养
        repairMaintainExecutorSelect,
        repairMaintainWorkOrderExecutorSelect,
        repairMaintainWorkOrderList,

        // 维修
        repairRepairExecutorSelect,
        repairRepairWorkOrderExecutorSelect,
        repairRepairWorkOrderList
    } from "@/api/api_branchFactory";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabName: 'first', //tab序号
                // activeNames: ['1'],
                time: '',
                date: '',
                value: '',
                tab_items: [{
                        label: '待办',
                        name: 'first'
                    }, {
                        label: '点检',
                        name: 'second'
                    },
                    {
                        label: '保养',
                        name: 'third'
                    },
                    {
                        label: '维修',
                        name: 'fourth'
                    },
                    {
                        label: '报警',
                        name: 'fifth'
                    },
                ],
                gridData: [],

                dateTypeValue: 1,
                dataFrame: [{ //统计数据-下拉框
                    value: 1,
                    label: '日'
                }, {
                    value: 2,
                    label: '月'
                }],
                // dataTime: [new Date(new Date().toLocaleDateString()), new Date()],
                dataTime: [new Date().getTime() - 1000 * 60 * 60 * 24 * 37, new Date().getTime() - 1000 * 60 * 60 * 24 *
                    35
                ],

                // 
                permission: {},
                indicatorDataInfo: {},

                deviceData: [],
                dialogDeviceTitile: '',
                dialogDeviceTotal: false,

                dialogDeviceRepair: false,

                dialogTitile: '',
                dialogTableVisibleAgentPointTotal: false,
                agentPointDataList: [],


                dialogTableVisibleResult: false,
                orderRecordList: [],

                loading: true,

                waitDoneCount: {},
                option_peopleValue: [],
                option_people: [],
                waitDoneTableArValue: '',
                waitDoneTableAr: [],
                waitDoneTime: [new Date().getTime() - 720 * 60 * 60 * 1000, new Date()],
                waitDoneList: [],
                currentTable: 1,
                // 时 分 秒 毫秒

                djPeopleArValue: [],
                djPeopleAr: [],
                spotOrderStatusValue: [],
                spotOrderStatus: [],
                djTime: [new Date().getTime() - 720 * 60 * 60 * 1000, new Date()],
                djList: [],

                maintainPeopleValue: [],
                maintainPeople: [],
                maintainStatusValue: [],
                maintainStatus: [],
                maintainTime: [new Date().getTime() - 720 * 60 * 60 * 1000, new Date()],
                maintainList: [],

                repairPeopleValue: [],
                repairPeople: [],
                repairStatusValue: [],
                repairStatus: [],
                repairTime: [new Date().getTime() - 720 * 60 * 60 * 1000, new Date()],
                repairList: [],

                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.repairIndicatorData();

            this.repairWaitDoneCount();
            this.repairWaitDoneExecutorSelect();
        },
        methods: {
            // 指标数据
            async repairIndicatorData() {
                var param = {
                    permission: this.permission,
                    dateType: this.dateTypeValue,
                    startDate: this.dataTime[0],
                    endDate: this.dataTime[1]
                }
                const selectRes = await repairIndicatorData(param);
                this.indicatorDataInfo = selectRes.data;
                this.loading = false;
            },
            changeBranchFactorySelect() {},
            changeBranchFactoryDateTime() {
                this.repairIndicatorData();
            },
            // 设备总数
            getDeviceTotal(row) {
                console.log(row)
                this.deviceData = [];
                this.dialogDeviceTitile = '设备总数';
                this.dialogDeviceTotal = true;
                this.repairIndicatorDetailList(row.indicatorType, row.equipmentIDs)
            },
            // 异常设备
            getUnusualDevice(row) {
                console.log(row)
                this.deviceData = [];
                this.dialogDeviceTitile = '异常设备';
                this.dialogDeviceTotal = true;
                this.repairIndicatorDetailList(row.indicatorType, row.errNumIDs)
            },
            // 本月报修
            getRepair(row) {
                console.log(row)
                if (row.failureReportNum == 0) {
                    this.$message({
                        message: '本月暂无报修',
                        type: 'warning'
                    });
                    return
                }
                this.deviceData = [];
                this.dialogDeviceTitile = '本月报修';
                this.dialogDeviceRepair = true;
                this.repairIndicatorDetailList(row.indicatorType, row.failureReportIDs);
            },
            async repairIndicatorDetailList(type, ids) {
                var param = {
                    permission: this.permission,
                    dateType: this.dateTypeValue,
                    startDate: this.dataTime[0],
                    endDate: this.dataTime[1],
                    indicatorType: type,
                    IDs: ids
                }
                const selectRes = await repairIndicatorDetailList(param);
                this.deviceData = selectRes.data;
            },
            // 点检待办单
            checkList(row) {
                console.log(row);
                this.agentPointDataList = [];
                this.dialogTitile = '点检待办单';
                this.agentPointDataList = row.agentPointDataList;
                this.dialogTableVisibleAgentPointTotal = true

            },
            lookResult(row) {
                console.log(row)
                this.orderRecordList = [];
                this.orderRecordList = row.orderRecordList;
                this.dialogTableVisibleResult = true;
            },
            // 点检异常单
            abnormalList(row) {
                console.log(row)
                this.agentPointDataList = [];
                this.dialogTitile = '点检异常单';
                this.agentPointDataList = row.errPointList;
                this.dialogTableVisibleAgentPointTotal = true;
            },


            // 待办--开始
            async repairWaitDoneCount() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairWaitDoneCount(param);
                this.waitDoneCount = selectRes.data
            },

            async repairWaitDoneExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairWaitDoneExecutorSelect(param);
                this.option_people = selectRes.data;
                // this.option_peopleValue = selectRes.data[0].value;
                this.repairWaitDoneTableSelect();
            },
            async repairWaitDoneTableSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairWaitDoneTableSelect(param);
                this.waitDoneTableAr = selectRes.data;
                this.waitDoneTableArValue = selectRes.data[0].value;
                this.repairWaitDoneWorkOrderList();
            },
            async repairWaitDoneWorkOrderList() {
                var param = {
                    permission: this.permission,
                    // execPersonStr: this.option_peopleValue,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    startDate: this.tools.formatDate(this.waitDoneTime[0], 'yyyy-MM-ddThh:mm:ss'), //时间传值格式更改
                    endDate: this.waitDoneTime[1],
                    type: this.waitDoneTableArValue
                }
                if (this.option_peopleValue.length != 0) {
                    param.execPersonStr = this.option_peopleValue.join(',')
                }
                const selectRes = await repairWaitDoneWorkOrderList(param);
                this.waitDoneList = selectRes.data.list.rows;
                this.pageQuantity = selectRes.data.list.pageQuantity;
                this.currentTable = this.waitDoneTableArValue;
            },
            handleCurrentChangeWaitDone() {
                this.repairWaitDoneWorkOrderList(); //查询table数据的方法
            },
            queryWaitDone() {
                this.currentPage = 1;
                this.repairWaitDoneWorkOrderList();
            },
            // // 待办--结束

            // 点检--开始
            async repairPointInspectioExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairPointInspectioExecutorSelect(param);
                this.djPeopleAr = selectRes.data;
                // this.djPeopleArValue = selectRes.data[0].value;
                // this.repairPointInspectioWorkOrderExecutorSelect()
            },
            async repairPointInspectioWorkOrderExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairPointInspectioWorkOrderExecutorSelect(param);
                this.spotOrderStatus = selectRes.data;
                // this.spotOrderStatusValue = selectRes.data[0].value;
                // this.repairPointInspectioWorkOrderList();
            },
            async repairPointInspectioWorkOrderList() {
                var param = {
                    permission: this.permission,
                    startDate: this.tools.formatDate(this.djTime[0], 'yyyy-MM-ddThh:mm:ss'), //时间传值格式更改
                    endDate: this.djTime[1],
                    // execPersonStr: this.djPeopleArValue,
                    // orderStatus: this.spotOrderStatusValue,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (this.djPeopleArValue.length != 0) {
                    param.execPersonStr = this.djPeopleArValue.join(',')
                }
                if (this.spotOrderStatusValue.length != 0) {
                    param.orderStatus = this.spotOrderStatusValue.join(',')
                }
                const selectRes = await repairPointInspectioWorkOrderList(param);
                this.djList = selectRes.data.rows;
                this.pageQuantity = selectRes.data.pageQuantity;
            },
            queryWDJ() {
                this.currentPage = 1;
                this.repairPointInspectioWorkOrderList();
            },
            handleCurrentChangeDJ(val) {
                this.repairPointInspectioWorkOrderList(); //查询table数据的方法
            },
            // 点检--结束


            // 保养--开始
            async repairMaintainExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairMaintainExecutorSelect(param);
                this.maintainPeople = selectRes.data;
                // this.maintainPeopleValue = selectRes.data[0].value;

                // this.repairMaintainWorkOrderExecutorSelect();
            },
            async repairMaintainWorkOrderExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairMaintainWorkOrderExecutorSelect(param);
                this.maintainStatus = selectRes.data;
                // this.maintainStatusValue = selectRes.data[0].value;
                // this.repairMaintainWorkOrderList();
            },
            async repairMaintainWorkOrderList() {
                var param = {
                    permission: this.permission,
                    startDate: this.tools.formatDate(this.maintainTime[0], 'yyyy-MM-ddThh:mm:ss'), //时间传值格式更改
                    endDate: this.maintainTime[1],
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    // execPersonStr: this.maintainPeopleValue,
                    // orderStatus: this.maintainStatusValue
                }
                if (this.maintainPeopleValue.length != 0) {
                    param.execPersonStr = this.maintainPeopleValue.join(',')
                }
                if (this.maintainStatusValue.length != 0) {
                    param.orderStatus = this.maintainStatusValue.join(',')
                }
                const selectRes = await repairMaintainWorkOrderList(param);
                this.maintainList = selectRes.data.rows;
                this.pageQuantity = selectRes.data.pageQuantity;

            },
            queryMaintain() {
                this.currentPage = 1;
                this.repairMaintainWorkOrderList();
            },
            handleCurrentChangeMaintain() {
                this.repairMaintainWorkOrderList();
            },


            // 维修--开始
            async repairRepairExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairRepairExecutorSelect(param);
                this.repairPeople = selectRes.data;
                // this.repairPeopleValue = selectRes.data[0].value;
                // this.repairRepairWorkOrderExecutorSelect();
            },
            async repairRepairWorkOrderExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairRepairWorkOrderExecutorSelect(param);
                this.repairStatus = selectRes.data;
                // this.repairStatusValue = selectRes.data[0].value;
                // this.repairRepairWorkOrderList();
            },
            async repairRepairWorkOrderList() {
                var param = {
                    permission: this.permission,
                    startDate: this.tools.formatDate(this.repairTime[0], 'yyyy-MM-ddThh:mm:ss'), //时间传值格式更改
                    endDate: this.repairTime[1],
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    // execPersonStr: this.repairPeopleValue,
                    // orderStatus: this.repairStatusValue
                }
                if (this.repairPeopleValue.length != 0) {
                    param.execPersonStr = this.repairPeopleValue.join(',')
                }
                if (this.repairStatusValue.length != 0) {
                    param.orderStatus = this.repairStatusValue.join(',')
                }
                const selectRes = await repairRepairWorkOrderList(param);
                this.repairList = selectRes.data.rows;
                this.pageQuantity = selectRes.data.pageQuantity;
            },
            queryRepair() {
                this.currentPage = 1;
                this.repairRepairWorkOrderList();
            },
            handleCurrentChangeRepair() {
                this.repairRepairWorkOrderList(); //查询table数据的方法
            },


            // repairTime

            // 维修--结束



            handleClick(tab) {
                this.currentPage = 1;
                console.log(tab.index);
                if (tab.index == 0) { //待办
                    this.repairWaitDoneCount();
                    this.repairWaitDoneExecutorSelect();
                } else if (tab.index == 1) { //点检
                    this.repairPointInspectioExecutorSelect();
                    this.repairPointInspectioWorkOrderExecutorSelect();
                    this.repairPointInspectioWorkOrderList();
                } else if (tab.index == 2) { //保养
                    this.repairMaintainExecutorSelect();
                    this.repairMaintainWorkOrderExecutorSelect();
                    this.repairMaintainWorkOrderList();
                } else if (tab.index == 3) { //维修
                    this.repairRepairExecutorSelect();
                    this.repairRepairWorkOrderExecutorSelect();
                    this.repairRepairWorkOrderList();
                }
            },
            handleChange(val) {
                console.log(val);
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style scoped>
    .el-tabs__item.is-active {
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        /* color: #999999; */
        color: #54a2eb;
    }

    .dialog__body {
        height: 600px;
    }

    .demo-table-expand {
        font-size: 0;
    }

    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }

    .collapseStyle {
        border: 0px;
        height: 400px;
        /* height: 398px; */
        /* overflow-y: auto; */
    }

    .collapseStyle1 {
        border: 0px;
        height: 450px;
    }

    .collapseItemMargin1 {
        margin-left: 554px;
    }

    .collapseItemMargin2 {
        margin-left: 400px;
    }

    .box1 {
        /* width_270 height_110 flex flex_column justify_center */
        width: 260px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .tabsHeight {
        height: 550px;
    }
</style>

<style lang="less" scoped>
    ::v-deep .el-badge__content.is-fixed {
        top: 8px;
        right: 8px;
    }

    ::v-deep .el-tabs__item {
        font-size: 16px !important;
    }

    ::v-deep .el-table th {
        /* background-color: #20B6DA; */
        background-color: #DBDBDB;
        color: #000000;
        text-align: center;
    }

    ::v-deep .el-table__expanded-cell {
        padding: 10px 40px;
    }
</style>