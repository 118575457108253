<!-- 系统配置-设备台账管理--设备台账管理 -->
<template>
    <div class="page_container height_100" v-loading="loading">
        <div class="box_head1">
            <span>设备台账管理</span>
            <div class="flex align_center fn_size14 mg_left_50">
                <div>组织架构：</div>
                <el-cascader class="mg_right_10 el_cascader_width" size="medium" ref="demoCascader" v-model="organizationValue"
                    :options="organizationAr" :props="demoCascaderProps" @change="orgChange" :show-all-levels="false"
                    collapse-tags clearable filterable>
                </el-cascader>
                <div>设备类型：</div>
                <el-select v-model="eqCValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                    <el-option v-for="item in selectList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <div class="mg_left_20">设备等级：</div>
                <el-select v-model="eqlValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                    <el-option v-for="item in selectList1" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <el-button @click="queryTest" type="primary" size="medium" class="mg_left_20"
                    style=" background: #009BFD;border: none;">查询
                </el-button>
            </div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30" style="height: 95%;">
            <div class="flex pdt_20 fn_size16">
                <div class="flex justify_between width_100">
                    <div class="flex">
                        <el-button type="primary" class="width_120" style=" background: #03CABE;border: none;"
                            icon="el-icon-circle-plus-outline" v-if="AddButton == true" @click="addData">新增
                        </el-button>
                        <!-- <el-button type="primary" class="width_120" style=" background: #F75753;border: none;"
                            icon="el-icon-delete" @click="deleteData">批量删除
                        </el-button> -->
                          <el-button type="primary" size="medium" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #009bfd;">导出</el-button>
                    </div>
                    <!-- <div class="flex">
                        <el-button type="primary" class="width_160" style="border: none;"
                            @click="builder" v-if="this.builderButton">机器学习建立器
                        </el-button>
                        <el-button type="primary" class="width_160" style="border: none;"
                            @click="analysis" v-if="this.analyserButton">机器学习预测分析
                        </el-button>
                        <el-button type="primary" class="width_160" style="border: none;"
                            @click="manager" v-if="this.managerButton">机器学习模块管理器
                        </el-button>
                        <el-button type="primary" class="width_160" style="border: none;"
                            @click="learning" v-if="this.inOrDeButton">添加/删除机器学习
                        </el-button>
                    </div> -->
                </div>
               
            </div>
            <!--table-->
            <el-table ref="multipleTable" :data="queryLimitList" tooltip-effect="dark" style="width: 100%"
                class="mg_top_20" @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable"
                :default-sort="{prop: 'date', order: 'descending'}" height="83%">
                <!-- <el-table-column type="selection" width="50">
                </el-table-column> -->
                <el-table-column type="index" label="序号" width="60">
                </el-table-column>
                <el-table-column prop="EquipmentName" label="设备名称" width="">
                </el-table-column>
                <el-table-column prop="EquipmentCode" label="设备编码" width="">
                </el-table-column>
                <el-table-column prop="EquipmentTypeName" label="设备类型" width="">
                </el-table-column>
                <el-table-column prop="EquipmentModel" label="设备型号" width="">
                </el-table-column>
                <el-table-column prop="ControlSystem" label="控制系统" width="">
                </el-table-column>
                <el-table-column prop="WorkshopSection" label="所属部门" width="" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="UserDepartment" label="使用部门" width="" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="Administrators" label="管理员" width="" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="InstallationPosition" label="安装部位" width="" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="Purchasingdate" label="采购日期" width="" :formatter="formatDate"
                    show-overflow-tooltip>
                </el-table-column>
                <!--  -->
                <el-table-column prop="EquipmentBrand" label="设备品牌" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="InspectionPerson" label="点检人">
                </el-table-column>
                <el-table-column prop="MaintainerPerson" label="保养人">
                </el-table-column>
                <el-table-column prop="EquipmentLevelName" label="设备等级" width="">
                </el-table-column>
                <!-- <el-table-column prop="Company" label="公司" width="">
                </el-table-column>
               -->
                <!-- <el-table-column prop="Line" label="工段" width="">
                </el-table-column> -->

                <!-- <el-table-column prop="UnitName" label="单元" width="">
                </el-table-column>

                <el-table-column prop="WorkshopSection" label="车间" width="">
                </el-table-column>
                <el-table-column prop="Remark" show-overflow-tooltip label="备注" width="">
                </el-table-column> -->
                <el-table-column label="资料管理" width="">
                    <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;" @click="linkTo(scope.row)">查看</span>
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;" @click="check(scope.row)">查看</span>
                        </el-button>
                        <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;" @click="changeData(scope.row)">修改</span>
                        </el-button>
                        <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;"
                                @click="singleDelete(scope.row.EquipmentID)">删除</span>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="flex justify_end">
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
            <!-- dialog 编辑 -->
            <el-dialog title="修改设备基本信息" :visible.sync="dialogTableVisibleEdit" top='4vh' width="54%">
                <div class="flex justify_between pdl30 pdr30">
                    <!-- 左  flex align_center-->
                    <el-form :model="formInlineEdit" ref="ruleForm2" class="width_270 mg_top_20" :rules="rulesFrom">
                        <el-form-item label="" prop="">
                            <el-upload class="avatar-uploader" action="" :show-file-list="false"
                                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                                :disabled="imgDisabled">
                                <img v-if="editImgUrl" :src="editImgUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <el-button type="primary" class="width_270" @click="editUpLoadImg">选择图片</el-button>
                            </el-upload>
                        </el-form-item>
                        <!-- <el-form-item label="二维码：" :label-width="labelWidth2" class="">
                            <div class="mg_top_10 QRCode">此处是二维码</div>
                        </el-form-item> -->
                        <el-form-item label="二维码：" :label-width="labelWidth2" class="">
                            <!-- <div class="mg_top_10 QRCode">此处是二维码</div> -->
                            <div id="qrCode2" ref="qrCodeDiv2" class="bgc_FFFFFF QRCode"></div>
                        </el-form-item>
                    </el-form>
                    <!-- 中 -->
                    <el-form :inline="true" :model="formInlineEdit" ref="ruleForm2" :label-width="labelWidth1"
                        :rules="rulesFrom" class="mg_top_20 flex  align_center flex_wrap">
                        <el-form-item label="设备ID：" prop="EquipmentID">
                            <el-input v-model="formInlineEdit.EquipmentID" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属部门：">
                            <!-- <el-input v-model="formInlineEdit.WorkshopSection" placeholder="请输入" class="width_180">
                            </el-input> -->
                            <el-select v-model="formInlineEdit.WorkshopSection" placeholder="请选择" class="width_180" :disabled="imgDisabled">
                                <el-option v-for="(item,index) in workshopArray" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备名称：">
                            <el-input v-model="formInlineEdit.EquipmentName" placeholder="请输入" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备编码：">
                            <el-input v-model="formInlineEdit.EquipmentCode" placeholder="请输入" class="width_180"
                                :disabled="imgDisabled" 
                                @change="changeQrcodeDemo">
                                <!--  generateCode2-->
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属公司：">
                            <el-select v-model="formInlineEdit.Company" placeholder="请选择" class="width_180"
                                :disabled="imgDisabled" 
                                @change="selectWorkshop">
                                <el-option v-for="(item,index) in selectListCompany" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属车间：" prop="Workshop">
                            <el-select v-model="formInlineEdit.Workshop" placeholder="请选择" class="width_180"
                                :disabled="imgDisabled" 
                                @change="selectUnit">
                                <el-option v-for="(item,index) in selectListWorkshop" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属产线：" prop="Unit">
                            <el-select v-model="formInlineEdit.Unit" placeholder="请选择" class="width_180" :disabled="imgDisabled">
                                <el-option v-for="(item,index) in selectListProductionline" :key="index"
                                    :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            <!-- <el-input v-model="formInline.Unit" placeholder="单元" class="width_180"></el-input> -->
                        </el-form-item>
                        <el-form-item label="采购时间：" prop="Purchasingdate">
                            <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInlineEdit.Purchasingdate"
                                type="datetime" :clearable="false" placeholder="选择日期时间" :disabled="imgDisabled">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="控制系统：" prop="ControlSystem">
                            <el-input v-model="formInlineEdit.ControlSystem" placeholder="请输入" class="width_180" :disabled="imgDisabled">
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="设备厂家：">
                            <el-select v-model="formInlineEdit.EquipmentBrand" filterable placeholder="请选择"
                                class="width_180">
                                <el-option v-for="(item,index) in selectListEquipManufacturer" :key="index"
                                    :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="设备厂家：">
                             <el-input v-model="formInlineEdit.EquipmentBrand" placeholder="" class="width_180" :disabled="imgDisabled">
                            </el-input>
                            <!-- <el-select v-model="formInlineEdit.EquipmentBrand" filterable placeholder="请选择"
                                class="width_180">
                                <el-option v-for="(item,index) in selectListEquipManufacturer" :key="index"
                                    :label="item.name" :value="item.value">
                                </el-option>
                            </el-select> -->
                        </el-form-item>
                        <el-form-item label="设备型号：">
                            <el-input v-model="formInlineEdit.EquipmentModel" :disabled="imgDisabled" placeholder="" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="使用部门：">
                            <!-- <el-input v-model="formInlineEdit.UserDepartment" placeholder="请输入" class="width_180">
                            </el-input> -->
                            <el-select v-model="formInlineEdit.UserDepartment" placeholder="请选择" class="width_180" :disabled="imgDisabled">
                                <el-option v-for="(item,index) in workshopArray" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备类型：" prop="EquipmentType">
                            <el-select v-model="formInlineEdit.EquipmentType" :disabled="imgDisabled" placeholder="请选择" class="width_180">
                                <el-option v-for="item in selectList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <!-- <el-input v-model="formInline.EquipmentType" placeholder="请输入" disabled class="width_180">
                            </el-input> -->
                        </el-form-item>
                        <el-form-item label="管理员：">
                            <el-input v-model="formInline.Administrators" :disabled="imgDisabled" placeholder="" class="width_180" >
                            </el-input>
                            <!-- <el-cascader v-model="formInlineEdit.Administrators" placeholder="请选择" class="width_180"
                                :options="optionsLIU" :props="propsPerson" :show-all-levels="false" collapse-tags>
                            </el-cascader> -->
                        </el-form-item>
                        <el-form-item label="安装位置：">
                            <el-input v-model="formInlineEdit.InstallationPosition" :disabled="imgDisabled" placeholder="" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备等级：" prop="EquipmentLevel">
                            <el-select v-model="formInlineEdit.EquipmentLevel" :disabled="imgDisabled" placeholder="请选择" class="width_180">
                                <el-option v-for="(item,index) in selectList1" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="保养人：">
                            <el-input v-model="formInlineEdit.MaintainerPerson" placeholder="" class="width_180">
                            </el-input>
                            <!-- <el-cascader v-model="formInlineEdit.MaintainerPerson" placeholder="请选择" class="width_180"
                                :options="optionsMaintainer" :props="propsPerson" :show-all-levels="false"
                                collapse-tags>
                            </el-cascader> -->
                        </el-form-item>
                        <el-form-item label="点检人：">
                            <el-input v-model="formInlineEdit.InspectionPerson" placeholder="" class="width_180">
                            </el-input>
                            <!-- <el-cascader v-model="formInlineEdit.InspectionPerson" placeholder="请选择" class="width_180"
                                :options="optionsCheckperson" :props="propsPerson" :show-all-levels="false"
                                collapse-tags>
                            </el-cascader> -->
                        </el-form-item>
                        <el-form-item label="是否连接：">
                            <el-checkbox v-model="formInlineEdit.ConnectStatus" :disabled="imgDisabled"></el-checkbox>
                        </el-form-item>
                        <!-- 右 -->
                        <!-- </el-form>
                   
                    <el-form :inline="true" :model="formInlineEdit" ref="ruleForm2" :label-width="labelWidth1"
                        :rules="rulesFrom" class="mg_top_30 flex justify_between flex_column align_center"> -->
                    </el-form>
                </div>
                <el-form :model="formInlineEdit" ref="ruleForm2" :label-width="labelWidth1" class=" pdl30 pdr30">
                    <el-form-item label="备注：">
                        <el-input type="textarea" :rows="5" v-model="formInlineEdit.Remark" :disabled="imgDisabled" resize="none"
                            placeholder="请输入介绍信息"></el-input>
                    </el-form-item>
                </el-form>
                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button type="primary" @click="cancel">取消</el-button>
                    <el-button type="primary" @click="changeTest">确定</el-button>
                </div>
            </el-dialog>
            <!-- dialog2 查看 -->
            <el-dialog title="查看设备基本信息" :visible.sync="dialogTableVisible2" top='4vh' width="54%">
                <div class="flex justify_between pdl30 pdr30">
                    <!-- 左 -->
                    <el-form :model="formInline" class="width_270 mg_top_20">
                        <el-form-item label="">
                            <el-image :src="formInline.imageUrl" class="avatar-uploader-icon imgUrl_look" style="">
                            </el-image>
                        </el-form-item>
                        <el-form-item label="二维码：" :label-width="labelWidth2" class="">
                            <div id="qrCode3" ref="qrCodeDiv3" class="bgc_FFFFFF QRCode"></div>
                        </el-form-item>
                    </el-form>
                    <!-- 中 -->
                    <el-form :inline="true" :model="formInline" :label-width="labelWidth1"
                        class="mg_top_20 flex  align_center flex_wrap">
                        <el-form-item label="设备ID：">
                            <el-input v-model="formInline.EquipmentID" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属部门：">
                            <el-select v-model="formInline.WorkshopSection" placeholder="请选择" class="width_180"
                                disabled>
                                <el-option v-for="(item,index) in workshopArray" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备名称：">
                            <el-input v-model="formInline.EquipmentName" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备编码：">
                            <el-input v-model="formInline.EquipmentCode" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属公司：">
                            <!-- <el-select v-model="formInline.Company" placeholder="请选择" class="width_180" disabled>
                                    <el-option v-for="(item,index) in selectListCompany" :key="index" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select> -->
                            <el-input v-model="formInline.CompanyName" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属车间：">
                            <el-input v-model="formInline.WorkshopName" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                            <!-- <el-select v-model="formInline.Workshop" placeholder="所属车间" class="width_180" disabled>
                                    <el-option v-for="item in selectListWorkshop" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select> -->
                        </el-form-item>
                        <el-form-item label="所属产线：">
                            <!-- <el-select v-model="formInline.Unit" placeholder="请选择" class="width_180" disabled>
                                    <el-option v-for="item in selectListProductionline" :key="item.id"
                                        :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select> -->
                            <el-input v-model="formInline.UnitName" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="采购时间：">
                            <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.Purchasingdate"
                                type="datetime" :clearable="false" placeholder="选择日期时间" disabled>
                            </el-date-picker>
                            <!-- <el-input v-model="formInline.Purchasingdate" placeholder="请输入" class="width_180"
                                        disabled></el-input> -->
                        </el-form-item>
                        <el-form-item label="控制系统：">
                            <el-input v-model="formInline.ControlSystem" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备厂家：">
                            <!-- <el-input v-model="formInline.EquipmentBrand" placeholder="请输入" class="width_180"
                                        disabled></el-input> -->
                            <el-select v-model="formInline.EquipmentBrand" placeholder="请选择" class="width_180" disabled>
                                <el-option v-for="(item,index) in selectListEquipManufacturer" :key="index"
                                    :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备型号：">
                            <el-input v-model="formInline.EquipmentModel" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="使用部门：">
                            <!-- <el-input v-model="formInline.UserDepartment" placeholder="请输入" class="width_180"
                                        disabled> </el-input> -->
                            <el-select v-model="formInline.UserDepartment" placeholder="请选择" class="width_180" disabled>
                                <el-option v-for="(item,index) in workshopArray" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备类型：">
                            <!-- <el-input v-model="formInline.EquipmentType" placeholder="请输入" class="width_180"
                                        disabled></el-input> -->
                            <el-select v-model="formInline.EquipmentType" placeholder="请选择" class="width_180" disabled>
                                <el-option v-for="item in selectList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <!-- <el-select v-model="formInline.equipmentType" placeholder="A类型" class="width_180">
                                        <el-option label="SN001" value="1"></el-option>
                                        <el-option label="SN002" value="2"></el-option>
                                    </el-select> -->
                        </el-form-item>
                        <el-form-item label="管理员：">
                            <el-input v-model="formInline.Administrators" placeholder="请输入" class="width_180" disabled>
                            </el-input>
                            <!-- <el-select v-model="formInline.Administrators" placeholder="张三" class="width_180"
                                        disabled>
                                        <el-option label="张三" value="zs"></el-option>
                                        <el-option label="李四" value="ls"></el-option>
                                    </el-select> -->
                        </el-form-item>
                        <el-form-item label="安装位置：">
                            <el-input v-model="formInline.InstallationPosition" placeholder="请输入" class="width_180"
                                disabled></el-input>
                        </el-form-item>
                        <el-form-item label="设备等级：">
                            <el-select v-model="formInline.EquipmentLevel" placeholder="请选择" class="width_180" disabled>
                                <el-option v-for="item in selectList1" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="保养人：">
                            <el-input v-model="formInline.MaintainerPerson" placeholder="请输入" class="width_180"
                                disabled></el-input>
                        </el-form-item>
                        <el-form-item label="点检人：">
                            <el-input v-model="formInline.InspectionPerson" placeholder="请输入" class="width_180"
                                disabled> </el-input>
                        </el-form-item>
                        <el-form-item label="是否连接：">
                            <el-checkbox v-model="formInline.ConnectStatus" disabled></el-checkbox>
                        </el-form-item>
                        <!-- <el-form-item label="所属工段：">
                                    <el-input v-model="formInline.WorkshopSection" placeholder="1#工段" class="width_180"
                                        disabled></el-input>
                                </el-form-item> -->
                    </el-form>
                    <!-- <div class="flex flex_column justify_start">
                            <el-form :inline="true" :model="formInline" label-width="90px">
                                <el-form-item label="OEE公式：" class="flex">
                                    <el-input v-model="formInline.OEE" placeholder="请输入" class="width_460" disabled>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="二维码：">
                                    <el-image style="width: 150px;height: 150px;" :src="imageUrl"></el-image>
                                </el-form-item>
                            </el-form>
                        </div> -->
                    <!-- </div> -->
                </div>
                <el-form :model="formInline" :label-width="labelWidth1" class=" pdr30 pdb_20">
                    <el-form-item label="备注：">
                        <el-input type="textarea" :rows="5" v-model="formInline.Remark" resize="none" placeholder="请输入"
                            disabled></el-input>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <!-- dialog3 新加 -->
            <el-dialog title="新增设备基本信息" :visible.sync="dialogTableVisibleAdd" top='4vh' width="54%">
                <div class="flex justify_between pdl30 pdr30">
                    <!-- 左 -->
                    <el-form :model="formInline" class="width_270 mg_top_20">
                        <el-form-item label="" prop="imageUrl">
                            <el-upload class="avatar-uploader" action="" :show-file-list="false"
                                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                <img v-if="formInline.imageUrl" :src="formInline.imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <el-button type="primary" class="width_270">选择图片</el-button>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="二维码：" :label-width="labelWidth2" class="">
                            <!-- <div class="mg_top_10 QRCode">此处是二维码</div> -->
                            <div id="qrCode" ref="qrCodeDiv" class="bgc_FFFFFF QRCode"></div>
                        </el-form-item>
                    </el-form>
                    <!-- 中 -->
                    <el-form :inline="true" ref="ruleForm" :model="formInline" :label-width="labelWidth1"
                        :rules="rulesFrom" class="mg_top_20 flex  align_center flex_wrap">
                        <el-form-item label="设备ID：" prop="EquipmentID">
                            <el-input v-model="formInline.EquipmentID" placeholder="请输入" class="width_180"
                                type="number" oninput="if(value<1)value=0">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属部门：">
                            <!-- <el-input v-model="formInline.WorkshopSection" placeholder="请输入" class="width_180">
                            </el-input> -->
                            <el-select v-model="formInline.WorkshopSection" placeholder="请选择" class="width_180"
                                clearable>
                                <el-option v-for="(item,index) in workshopArray" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备名称：">
                            <el-input v-model="formInline.EquipmentName" placeholder="请输入" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备编码：">
                            <el-input v-model="formInline.EquipmentCode" placeholder="请输入" class="width_180"
                                @change="generateCodeAdd">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属公司：" prop="Company">
                            <el-select v-model="formInline.Company" placeholder="请选择" class="width_180"
                                @change="selectWorkshop">
                                <el-option v-for="item in selectListCompany" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属车间：" prop="Workshop">
                            <el-select v-model="formInline.Workshop" placeholder="请选择" class="width_180"
                                @change="selectUnit">
                                <el-option v-for="item in selectListWorkshop" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属产线：" prop="Unit">
                            <el-select v-model="formInline.Unit" placeholder="请选择" class="width_180">
                                <el-option v-for="item in selectListProductionline" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="采购时间：">
                            <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.Purchasingdate"
                                type="datetime" :clearable="false" placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="控制系统：">
                            <el-input v-model="formInline.ControlSystem" placeholder="" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备厂家：">
                            <el-input v-model="formInline.EquipmentBrand" placeholder="" class="width_180">
                            </el-input>
                            <!-- <el-select v-model="formInline.EquipmentBrand" filterable placeholder="请选择"
                                class="width_180">
                                <el-option v-for="(item,index) in selectListEquipManufacturer" :key="index"
                                    :label="item.name" :value="item.value">
                                </el-option>
                            </el-select> -->
                        </el-form-item>
                        <el-form-item label="设备型号：">
                            <el-input v-model="formInline.EquipmentModel" placeholder="" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="使用部门：">
                            <el-select v-model="formInline.UserDepartment" placeholder="请选择" class="width_180"
                                clearable>
                                <el-option v-for="(item,index) in workshopArray" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备类型：" prop="EquipmentType">
                            <el-select v-model="formInline.EquipmentType" placeholder="请选择" class="width_180" clearable>
                                <el-option v-for="item in selectList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="管理员：">
                            <el-input v-model="formInline.Administrators" placeholder="" class="width_180">
                            </el-input>
                            <!-- <el-cascader v-model="formInline.Administrators" placeholder="请选择" class="width_180"
                                :options="optionsLIU" :props="propsPerson" :show-all-levels="false" collapse-tags
                                clearable>
                            </el-cascader> -->
                        </el-form-item>
                        <el-form-item label="安装位置：">
                            <el-input v-model="formInline.InstallationPosition" placeholder="" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备等级：" prop="EquipmentLevel">
                            <el-select v-model="formInline.EquipmentLevel" placeholder="请选择" class="width_180"
                                clearable>
                                <el-option v-for="item in selectList1" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="保养人：">
                            <el-input v-model="formInline.MaintainerPerson" placeholder="" class="width_180">
                            </el-input>
                            <!-- <el-cascader v-model="formInline.MaintainerPerson" placeholder="请选择" class="width_180"
                                :options="optionsMaintainer" :props="propsPerson" :show-all-levels="false" collapse-tags
                                clearable>
                            </el-cascader> -->
                        </el-form-item>
                        <el-form-item label="点检人：">
                            <el-input v-model="formInline.InspectionPerson" placeholder="" class="width_180">
                            </el-input>
                            <!-- <el-cascader v-model="formInline.InspectionPerson" placeholder="请选择" class="width_180"
                                :options="optionsCheckperson" :props="propsPerson" :show-all-levels="false"
                                collapse-tags clearable>
                            </el-cascader> -->
                        </el-form-item>
                        <el-form-item label="是否连接：">
                            <el-checkbox v-model="formInline.ConnectStatus"></el-checkbox>
                        </el-form-item>
                    </el-form>
                </div>
                <el-form :model="formInline" label-width="90px" class=" pdl30 pdr30">
                    <el-form-item label="备注：">
                        <el-input type="textarea" :rows="4" v-model="formInline.Remark" resize="none" placeholder="">
                        </el-input>
                    </el-form-item>
                </el-form>
                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_10">
                    <el-button type="primary" @click="dialogTableVisibleAdd = false">取消</el-button>
                    <el-button type="primary" @click="addTest">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import QRCode from 'qrcodejs2';
    import {
        equipmentLedgerQueryLimit,
        equipmentLedgerAlarmTypeSelect,
        equipmentLedgerAlarmLevelSelect,
        equipmentLedgerOrganizationSelect,
        equipmentLedgerAdd,
        equipmentLedgerQuery,
        equipmentLedgerModification,
        equipmentLedgerDelete,
        equipmentLedgerWorkShopSelect, //所属车间下拉框
        equipmentLedgerCompanySelect, //所属公司下拉框
        equipmentLedgerLineSelect, //所属产线下拉框
        equipmentLedgerManufacturersSelect, //设备厂家下拉框
        equipmentLedgerMaintenancerSelect, //设备保养人下拉框
        equipmentLedgerAdminiSelect, //设备管理员下拉框
        equipmentLedgerInspectionSelect, //设备点检人下拉框
        equipmentLedgerDepartment, //部门选择下拉框
        equipmentIDverificationQuery, //校验设备ID是否存在

    } from "@/api/api_systemConfig";
    var validateEquipmentID = (rule, value, callback) => {
        // ref="ruleForm" :model="formInline"
        console.log(value, 1)
        if (value === '') {
            console.log(value)
            callback(new Error('请输入设备ID'));
        } 
        // else {
        //     // if (this.ruleForm.confirmNewPassword !== '') {
        //     //     this.$refs.ruleForm.validateField('confirmNewPassword');
        //     // }
        //     callback();
        // }
    };
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                AddButton:true,
                tabIndex: 0,
                value: '',
                dialogTableVisibleEdit: false,
                dialogTableVisible2: false,
                dialogTableVisibleAdd: false,
                input: '',
                multipleSelection: [],
                equAccountManagementList: [],
                newForm: {
                    imageUrl: '',
                },
                pt: '',
                // formInline : {
                //     imageUrl: '',
                //     Administrators: [],
                //     MaintainerPerson: [],
                //     InspectionPerson: []
                // },
                formInline: {
                    imageUrl: ''
                },
                currentRowIndex: [],
                gridData: [],
                //上传图片----------
                queryList: [],
                eqCList: [],
                eqlmList: [],
                form: {},
                newForm: {},
                standardArr: {},
                labelWidth1: '120px',
                labelWidth2: '80px',
                rulesFrom: {
                    EquipmentType: [{
                        required: true,
                        message: '请选择设备类型',
                        trigger: 'blur'
                    }],
                    EquipmentLevel: [{
                        required: true,
                        message: '请选择设备等级',
                        trigger: 'blur'
                    }],
                    Company: [{
                        required: true,
                        message: '请选择所属公司',
                        trigger: 'blur'
                    }],
                    Workshop: [{
                        required: true,
                        message: '请选择所属车间',
                        trigger: 'blur'
                    }],
                    Unit: [{
                        required: true,
                        message: '请选择所属产线',
                        trigger: 'blur'
                    }],
                    EquipmentID: [{
                        required: true,
                        message: '请输入设备ID',
                        // validator: validateEquipmentID,
                        trigger: 'blur',
                    }, {
                        min: 1,
                        max: 11,
                        message: '长度在 1 到 10 个数字',
                        trigger: 'blur'
                    }],
                },

                propsPerson: {
                    value: 'name', //指定选项的值为选项对象的某个属性值	
                    label: 'name', //指定选项标签为选项对象的某个属性值	
                    children: 'children', //指定选项的子选项为选项对象的某个属性值
                    multiple: true, //多选
                    expandTrigger: 'hover', //触发方式
                    emitPath: false, //在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
                },
                optionsLIU: [],
                optionsMaintainer: [],
                optionsCheckperson: [],
                userID: '',
                inputValue: '',
                eqIDValue: '',
                eqCValue: '',
                eqlValue: '',
                changeResult: '',
                selectList: [],
                selectListID: '',
                selectList1: [],
                selectListWorkshop: [], //车间
                selectListProductionline: [], //产线
                selectListCompany: [],
                selectListEquipManufacturer: [],
                selectList1ID: '',
                permission: {},
                equipmentID: '',
                deleteNum: '',
                addEqID: '',
                changeEqID: '',
                checkData: {},
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                organizationAr: [],
                orgSelect: [],
                queryLimitList: [],
                equInfo: [],
                organizationValue: [],
                loading: true,
                rowData: {},
                checkEqID: [],
                rowEqID: [],
                changeID: '',
                fileNameTest: '',
                blobFile: '',
                EquipmentID: '',
                imgUUrl: '',
                editImgUrl: '',
                workShopValue: '',

                formInlineEdit: {},

                demoCascaderProps: {
                    multiple: true,
                    checkStrictly: true,
                    value: 'ID',
                    label: 'Name',
                    children: 'children',
                    expandTrigger: 'hover',
                },
                workshopArray: [],
                qrcodeDemo: null,
                qrcodeDemoEdit: null,
                qrcodeDemoLook: null,
                builderButton:true,
                analyserButton:true,
                managerButton:true,
                inOrDeButton:true,

                imgDisabled:true,//禁用
            }
        },
        created() {
            this.getData();
        },

        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
                this.builderButton = userInfo.builderButton;
                this.analyserButton = userInfo.analyserButton;
                this.managerButton = userInfo.managerButton;
                this.inOrDeButton = userInfo.inOrDeButton;
                this.AddButton = userInfo.deviceManageCreate;
            }
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.equipmentLedgerOrganizationSelect();//组织
            this.equipmentLedgerWorkShopSelect()//所属车间下拉框
            this.equipmentLedgerLineSelect()//产线()
            this.equipmentLedgerCompanySelect()//公司
            this.equipmentLedgerManufacturersSelect()//设备厂家下拉框
            this.equipmentLedgerAdminiSelect()//设备管理员二级菜单下拉框
            this.equipmentLedgerMaintenancerSelect()//设备保养人二级菜单下拉框
            this.equipmentLedgerInspectionSelect();//设备点检人二级菜单下拉框
            this.equipmentLedgerDepartment(); //部门选择框
            // this.checkEquipmentID();

        },
        methods: {

             //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '设备台账管理')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },

            //loading 
            getData() {
                const loading = this.$loading({
                    lock: true, //lock的修改符--默认是false
                    text: 'Loading', //显示在加载图标下方的加载文案
                    spinner: 'el-icon-loading', //自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
                    target: document.querySelector('#table') //loadin覆盖的dom元素节点
                });
                loading.close() //成功回调函数停止加载
            },

            //查询按钮
            queryTest() {
                this.currentPage = 1;
                this.equipmentLedgerQueryLimit()
                this.equipmentLedgerAlarmTypeSelect();
                this.equipmentLedgerAlarmLevelSelect();
            },

            //选中checkbox的
            // handleSelectionChange(val) {
            //     // console.log(val)
            //     this.multipleSelection = val;
            //     //要批量删除的数组
            //     // this.deleteNum = []; //数组赋值前，需要清空一下数组
            //     this.deleteNum = "";
            //     for (var i = 0; i < val.length; i++) {
            //         this.deleteNum += val[i].EquipmentID + ",";
            //     }
            //     //去掉最后一个逗号
            //     if (this.deleteNum.length > 0) {
            //         this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
            //     }
            //     console.log("checkbox", this.deleteNum);
            // },
            linkTo(row) {
                console.log(row);
                console.log("xxxx");
                localStorage.setItem('RowEqID', row.EquipmentID)
                localStorage.setItem('RowEqName', row.EquipmentName)
                this.$router.push({
                    path: '/systemConfiguration/deviceDocumentManagement',
                })
            },

            //组织框变动
            orgChange() {
                if (this.organizationValue.length == 0) {
                    this.checkData = this.standardArr[0];
                    console.log('sssssss',this.checkData);
                    return
                }
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }

                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                // if (this.organizationValue.length) {
                //     this.checkData = this.standardArr[0]
                // }
                this.checkData = testData;


                console.log('sssssss',this.checkData);
                // this.equipmentLedgerOrganizationSelect();
                this.equipmentLedgerAlarmTypeSelect();
                this.equipmentLedgerAlarmLevelSelect();
                //this.equipmentLedgerQueryLimit()//刚刚隐藏被打开的
            },

            //组织架构选择
            async equipmentLedgerOrganizationSelect() {
                var param = {
                    permission: this.permission
                }
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organizationAr = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];
                this.equipmentLedgerAlarmTypeSelect()
                this.equipmentLedgerAlarmLevelSelect()
                this.equipmentLedgerQueryLimit()
                this.loading = false;
            },

            //设备类型下拉框
            async equipmentLedgerAlarmTypeSelect() {
                this.selectList = [];
                var param = {
                    organization: this.checkData
                }
                const selectRes = await equipmentLedgerAlarmTypeSelect(param);
                this.selectList = selectRes.data;
            },
            selectWorkshop(val) {
                // 车间根据所选的公司进行查询
                // this.formInline.Workshop = ''
                console.log(val,'val');
                this.$set(this.formInlineEdit, 'Workshop', '')
                this.$set(this.formInlineEdit, 'Unit', '')
                this.$set(this.formInline, 'Workshop', '')
                this.$set(this.formInline, 'Unit', '')
                this.selectListWorkshop = [];
                this.selectListProductionline = [];
                this.equipmentLedgerWorkShopSelect(val)
            },
            //所属车间下拉框
            async equipmentLedgerWorkShopSelect(id) {

                var param = {
                    companyID: id
                }
                const selectRes = await equipmentLedgerWorkShopSelect(param);
                this.selectListWorkshop = selectRes.data;
            },
            selectUnit(val) {
                // 产线根据所选的车间进行查询
                console.log(val,'val1');
                this.$set(this.formInline, 'Unit', '')
                this.$set(this.formInlineEdit, 'Unit', '')
                this.selectListProductionline = [];
                this.equipmentLedgerLineSelect(val);
            },
            //所属产线下拉框
            async equipmentLedgerLineSelect(id) {
                var param = {
                    workShopID: id
                }
                const selectRes = await equipmentLedgerLineSelect(param);
                this.selectListProductionline = selectRes.data;
                //console.log(this.equipmentLedgerLineSelect);
            },
            //所属公司下拉框
            async equipmentLedgerCompanySelect() {
                this.selectListCompany = [];
                var param = {
                    userID:this.userID
                }
                const selectRes = await equipmentLedgerCompanySelect(param);
                this.selectListCompany = selectRes.data;
            },
            //设备厂家下拉框
            async equipmentLedgerManufacturersSelect() {
                this.selectListEquipManufacturer = [];
                var param = {}
                const selectRes = await equipmentLedgerManufacturersSelect(param);
                this.selectListEquipManufacturer = selectRes.data;
                //console.log(this.equipmentLedgerManufacturersSelect);
            },
            //设备管理员二级菜单下拉框
            async equipmentLedgerAdminiSelect() {
                this.optionsLIU = [];
                var param = {}
                const selectRes = await equipmentLedgerAdminiSelect(param);
                this.optionsLIU = selectRes.data;
                //console.log(this.equipmentLedgerAdminiSelect);
            },
            //设备保养人二级菜单下拉框
            async equipmentLedgerMaintenancerSelect() {
                this.optionsMaintainer = [];
                var param = {}
                const selectRes = await equipmentLedgerMaintenancerSelect(param);
                this.optionsMaintainer = selectRes.data;
                //console.log(this.equipmentLedgerMaintenancerSelect);
            },

            //设备点检人二级菜单下拉框
            async equipmentLedgerInspectionSelect() {
                this.optionsCheckperson = [];
                var param = {}
                const selectRes = await equipmentLedgerInspectionSelect(param);
                this.optionsCheckperson = selectRes.data;
                //console.log(this.equipmentLedgerInspectionSelect);
            },

            //设备等级下拉框
            async equipmentLedgerAlarmLevelSelect() {
                this.selectList1 = [];
                var param = {
                    organization: this.checkData
                }
                const selectRes = await equipmentLedgerAlarmLevelSelect(param);
                this.selectList1 = selectRes.data;
            },
            // 部门选择下拉框
            async equipmentLedgerDepartment() {
                var param = {
                    userID:this.userID
                }
                const selectRes = await equipmentLedgerDepartment(param);
                this.workshopArray = selectRes.data
            },

            //条件筛选获取数据
            async equipmentLedgerQuery(id) {
                this.formInline = {}
                var param = {
                    equipmentID: id
                }
                const selectRes = await equipmentLedgerQuery(param);
                this.formInline = selectRes.data[0];
                // var api = 'http://121.37.181.242:8080';
                // var kpi = '?appKey=e814dc0d-b4b1-4c15-ae72-a749413b1d93';
                this.formInline.imageUrl = this.httpUrl + this.formInline.Url + this.appKey;

                this.editImgUrl = this.formInline.imageUrl;
                console.log("form", this.formInline.imageUrl);
                this.formInline.InspectionPerson = this.formInline.InspectionPerson.split(',');
                this.formInline.MaintainerPerson = this.formInline.MaintainerPerson.split(',');
                this.formInline.Administrators = this.formInline.Administrators.split(',');
                console.log(this.formInline)
            },
            //全部数据
            async equipmentLedgerQueryLimit() {
                var param = {
                    // equipmentCategoryID:1,
                    // equipmentLevelID:1,
                    organization: {
                        Type: this.checkData.Type,
                        ID: this.checkData.ID
                    },
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    permission: this.permission,
                }
                if (this.eqCValue) {
                    param.equipmentCategoryID = this.eqCValue
                }
                if (this.eqlValue) {
                    param.equipmentLevelID = this.eqlValue
                }
               
                // var api = 'http://121.37.181.242:8080';
                // var kpi = '?appKey=e814dc0d-b4b1-4c15-ae72-a749413b1d93';
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.pageQuantity = selectRes.pageQuantity
                // this.equInfo = selectRes.data[0];
                // if (this.equInfo) {
                //     this.equInfo.ImageUrl = api + this.equInfo.ImageUrl + kpi;
                // }
                // console.log(this.equInfo);
                //this.EquipmentLedgerAdd();//新添加的
                // this.queryLimitList = selectRes.data.filter(item => item)
                 this.queryLimitList = selectRes.data;
            },


            //查看
            check(row) {
                console.log(row);
                // this.equipmentLedgerQuery(id)
                this.formInline = row;
                
                // var api = 'http://121.37.181.242:8080';
                var key = '?appKey=23d7ee74-563a-4407-ba7d-24449064040e';
                var http = "https://iot.sdkjiot.com"
                this.formInline.imageUrl = http + this.formInline.Url + key;
                // this.formInline.imageUrl = "https://iot.sdkjiot.com/Thingworx/FileRepositories/EMS.Thing.FileRepository.EquipmentFile/600001/Avatar/600001.png?appKey=23d7ee74-563a-4407-ba7d-24449064040e"
                console.log(this.formInline.imageUrl);
                // this.editImgUrl = this.formInline.imageUrl;
                // console.log("form", this.formInline.imageUrl);
                if (typeof this.formInline.Administrators == 'object') {
                    this.formInline.Administrators = this.formInline.Administrators.join(','); //管理员
                    this.formInline.MaintainerPerson = this.formInline.MaintainerPerson.join(','); //保养人
                    this.formInline.InspectionPerson = this.formInline.InspectionPerson.join(','); //点检人
                }
                // this.formInline.InspectionPerson = this.formInline.InspectionPerson.split(',');
                // this.formInline.MaintainerPerson = this.formInline.MaintainerPerson.split(',');
                // this.formInline.Administrators = this.formInline.Administrators.split(',');
                console.log(this.formInline)
                this.dialogTableVisible2 = true;
                // this.qrCodeDiv3
                setTimeout(() => {
                    this.lookQrCode(this.formInline.EquipmentCode);
                }, 100)

            },
            lookQrCode(val) {
                if (this.qrcodeDemoLook != null) {
                    document.getElementById('qrCode3').innerHTML = '';
                }
                // document.getElementById("qrcode").innerHTML = "";
                console.log(this.qrcodeDemo)
                this.qrcodeDemoLook = new QRCode(this.$refs.qrCodeDiv3, {
                    text: val,
                    width: 170,
                    height: 170,
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
                })
            },

            //删除
            async equipmentLedgerDelete(id) {
                console.log("要删除的设备ID", id);
                var param = {
                    equipmentID: id
                }
                const selectRes = await equipmentLedgerDelete(param);
                //判断是否删除成功
                if (selectRes.data) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.equipmentLedgerQueryLimit()
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    });
                }
            },

            //批量删除
            deleteData() {
                //获取用户权限
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                if(userInfo.deviceManageModify){
                    //判断是否选中数据
                    if (this.multipleSelection.length == 0) {
                        this.$message({
                            message: '您未选中数据',
                            type: 'warning'
                        });
                    } else {
                        this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            // todo
                            // console.log(this.currentPage)
                            this.currentPage = this.delData.delData(this.queryLimitList.length, this.pageQuantity,
                                this.currentPage, this.multipleSelection.length)
                            // console.log(this.currentPage)
                            // console.log(this.pageQuantity)
                            // console.log(this.queryLimitList.length)
                            var delArr = this.deleteNum
                            this.equipmentLedgerDelete(delArr)
                        }).catch(() => {

                        });
                    }
                }else{
                    this.$message({
                        message: '警告哦，您没有权限选择此项',
                        type: 'warning'
                    });
                }
                
            },
            //单个删除
            singleDelete(id) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                //获取用户权限
                if(userInfo.deviceManageModify){
                    this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.currentPage = this.delData.delData(this.queryLimitList.length, this.pageQuantity, this
                            .currentPage)
                        this.equipmentLedgerDelete(id)
                    }).catch(() => {

                    });
                }else{
                    this.$message({
                        message: '警告哦，您没有权限选择此项',
                        type: 'warning'
                    });
                }
                
            },

            //新增
            addData() {
                //清空
                console.log("add");
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                //获取用户权限
                 if(userInfo.deviceManageModify){
                    this.EquipmentID = '';
                    this.formInline = {
                        imageUrl: '',
                        Administrators: "",
                        MaintainerPerson: "",
                        InspectionPerson: ""
                    };
                    if (this.qrcodeDemo != null) {
                        document.getElementById('qrCode').innerHTML = '';
                    }
                    //打开新增页面dialog3
                    this.dialogTableVisibleAdd = true;
                }else{
                    this.$message({
                        message: '警告哦，您没有权限选择此项',
                        type: 'warning'
                    });
                }
                
            },
            // 生成二维码
            generateCodeAdd(val) {
                console.log(val)
                if (this.qrcodeDemo != null) {
                    document.getElementById('qrCode').innerHTML = '';
                }
                // console.log(this.qrcodeDemo)
                this.qrcodeDemo = new QRCode(this.$refs.qrCodeDiv, {
                    text: val,
                    width: 170,
                    height: 170,
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
                })
            },
            createPicture() {
                html2canvas(this.$refs.qrCodeDiv, {
                    backgroundColor: null,
                    width: 200,
                    height: 200
                }).then(canvas => {
                    var imgData = canvas.toDataURL('image/jpeg')
                    this.imgData = imgData
                })
            },

            //点击dialog(新加)按钮的方法
            addTest() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) { //验证通过
                        this.checkEquipmentID()
                        if(!check){}

                         this.equipmentLedgerAdd()//刚刚隐藏被打开的

                         
                    } else {
                        // console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 新增操作-校验设备ID是否存在
            async checkEquipmentID() {
                var param = {
                    equipmentID: this.formInline.EquipmentID
                }
                const selectRes = await equipmentIDverificationQuery(param);
                if (selectRes.data.result) {
                    this.equipmentLedgerAdd()
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    });
                }
            },
            async equipmentLedgerAdd() {
                ///this.formInline.Administrators = this.formInline.Administrators.join(','); //管理员
                // this.formInline.MaintainerPerson = this.formInline.MaintainerPerson.join(','); //保养人
                // this.formInline.InspectionPerson = this.formInline.InspectionPerson.join(','); //点检人
                // console.log(this.formInline.Purchasingdate)
                // if (this.formInline.Purchasingdate != undefined) {
                //     this.formInline.Purchasingdate = this.formInline.Purchasingdate.getTime();
                // }
                // this.formInline = {};
                var param = {
                    equipmentID:this.EquipmentID,
                    basicInfor:this.formInline,
                }
                param.content = this.blobFile.split(',').slice(-1)[0]
                console.log(param)
                // if (this.EquipmentID) {
                param.equipmentID = this.formInline.EquipmentID
                // }
                // delete this.formInline.EquipmentID;
                console.log(param)
                // if (this.formInline) {
                //     param.basicInfor = this.formInline
                // }
                // console.log(param)
                 if(this.blobFile){param.content= this.blobFile}
                const selectRes = await equipmentLedgerAdd(param);
                // //判断是否添加成功
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleAdd = false
                    this.formInline = {}
                    this.equipmentLedgerQueryLimit();
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning',
                    });
                }
                this.dialogTableVisibleAdd = false;
            },


            //编辑 点击(修改)按钮的方法
            changeData(row) {
                console.log('changeData',row)
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                if(userInfo.deviceManageModify){
                    this.imgDisabled = false;
                }
                this.editImgUrl = '';
                this.formInlineEdit = {};
                this.formInlineEdit = {
                    imageUrl: '',
                    InspectionPerson: "",
                    MaintainerPerson: "",
                    Administrators: "",
                    WorkshopSection:" ",
                    EquipmentName:" ",
                    EquipmentCode:" ",
                    Company:" ",
                    Workshop:" ",
                    Unit:" ",
                   ControlSystem:"",
                    Purchasingdate:" ",

                }
                // this.equipmentLedgerQuery(id)
                // this.changeID = id
                this.dialogTableVisibleEdit = true;
                this.formInlineEdit = row;
                var http = "https://iot.sdkjiot.com"
                var key = '?appKey=23d7ee74-563a-4407-ba7d-24449064040e';
                 // var api = 'http://121.37.181.242:8080';
                this.formInlineEdit.imageUrl = http + this.formInlineEdit.Url + key;
                this.editImgUrl = this.formInlineEdit.imageUrl;
                console.log("图片"+this.editImgUrl );
                // console.log("form", this.formInline.imageUrl);
                console.log(typeof this.formInlineEdit.InspectionPerson)
                // if (typeof this.formInlineEdit.InspectionPerson == 'string') {
                //     this.formInlineEdit.InspectionPerson = this.formInlineEdit.InspectionPerson.split(',');
                // }
                // if (typeof this.formInlineEdit.MaintainerPerson == 'string') {
                //     this.formInlineEdit.MaintainerPerson = this.formInlineEdit.MaintainerPerson.split(',');
                // }
                // if (typeof this.formInlineEdit.Administrators == 'string') {
                //     this.formInlineEdit.Administrators = this.formInlineEdit.Administrators.split(',');
                // }
                console.log("form", this.formInlineEdit);
                this.equipmentLedgerWorkShopSelectEdit()
                this.equipmentLedgerLineSelectEdit();
                setTimeout(() => {
                    this.changeQrcodeDemo(this.formInlineEdit.EquipmentCode);
                }, 100)
            },
            changeQrcodeDemo(val) {
                console.log(val)

                if (this.qrcodeDemoEdit != null) {
                    document.getElementById('qrCode2').innerHTML = '';
                }
                this.qrcodeDemoEdit = new QRCode(this.$refs.qrCodeDiv2, {
                    text: val,
                    width: 170,
                    height: 170,
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
                })
            },

            async equipmentLedgerWorkShopSelectEdit() {
                console.log('a',this.formInlineEdit.Company);

                this.selectListWorkshop = [];
                var param = {
                    companyID: this.formInlineEdit.Company
                }
                const selectRes = await equipmentLedgerWorkShopSelect(param);
                this.selectListWorkshop = selectRes.data;
            },
            async equipmentLedgerLineSelectEdit() {
                this.selectListProductionline = [];
                var param = {
                    workShopID: this.formInlineEdit.Workshop
                }
                const selectRes = await equipmentLedgerLineSelect(param);
                this.selectListProductionline = selectRes.data;
            },
            changeTest() {
                this.$refs['ruleForm2'].validate((valid) => {
                    if (valid) { //验证通过
                        // console.log(this.formInlineEdit)
                        this.equipmentLedgerModification();
                    } else {
                        // console.log('error submit!!');
                        return false;
                    }
                });
                this.dialogTableVisibleEdit = false
                // this.formInline = {
                //     imageUrl: ''
                // }
            },
            cancel(){
                this.dialogTableVisibleEdit = false;
                this.equipmentLedgerQueryLimit();
            },
            async equipmentLedgerModification() {
                // console.log('修改的设备ID', this.formInlineEdit);
                // if (typeof this.formInlineEdit.Administrators == 'object') {
                //     this.formInlineEdit.Administrators = this.formInlineEdit.Administrators.join(','); //管理员
                // }
                // this.formInlineEdit.MaintainerPerson = this.formInlineEdit.MaintainerPerson.join(','); //保养人
                // this.formInlineEdit.InspectionPerson = this.formInlineEdit.InspectionPerson.join(','); //点检人
                // this.newForm.Url = this.blobFile
                var param = {
                    equipmentID: this.formInlineEdit.EquipmentID,
                    basicInfor: this.formInlineEdit
                }
                param.content = this.blobFile.split(',').slice(-1)[0]
                const selectRes = await equipmentLedgerModification(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleEdit = false;
                    this.formInlineEdit = {};
                    this.equipmentLedgerQueryLimit();
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    });
                     //this.equipmentLedgerQueryLimit();//刚刚隐藏被打开的
                    // this.formInline = {}
                }

            },

            //页码跳转
            handleCurrentChange(val) {
                this.currentPage = val;
                this.equipmentLedgerQueryLimit(); //查询table数据的方法
            },

            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                // console.log(this.imageUrl,file);
            },
            beforeAvatarUpload(file) {
                
                this.editImgUrl = '';
                const isJPG = file.type === 'image/jpeg';

                const isLt2M = file.size / 1024 / 1024 < 10;

                let that = this;
                that.file = file;
                that.fileNameTest = file.name;
                var fileType = file.name.split('.').slice(-1)
                console.log('type', fileType);
                that.form.format = fileType[0];
                if (fileType[0] == 'png' || fileType[0] == 'jpg' || fileType[0] == 'jpeg' || fileType[0] ==
                    'image') {
                    var fileSize = file.size / 1024 / 1024;
                    if (fileSize > 10) {
                        that.$confirm('文件大小须小于10M。', '提示', {
                            confirmButtonText: "确认",
                            cancelButtonText: "取消",
                            type: 'warning'
                        }).then().catch()
                        that.fileNameTest = '';
                        that.blobFile = '';
                    }
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        that.blobFile = reader.result;
                        that.formInline.imageUrl = that.blobFile;
                        that.editImgUrl = that.blobFile;
                        // that.newForm.imageUrl = that.blobFile
                        console.log("imgURL", that.formInline.imageUrl)
                    };
                    return false
                } else {
                    that.$confirm('请上传png/jpg/jpeg/image文件', '提示', {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }), then().catch()
                    that.fileNameTest = '';
                    that.blobFile = '';
                    return false
                }

                
            },
            editUpLoadImg(){
                let userInfo =JSON.parse(localStorage.getItem('userInfo'));
                if(!userInfo.deviceManageModify){
                    this.$message({
                        message: '警告哦，您没有权限选择此项',
                        type: 'warning'
                    });
                }
            },
            // dateFormatter(row){
            //     if(row.date){
            //         // return  row.date //在这里对row.date进行处理
            //         return this.getDate(new Date(Date.parse(new Date(row.date))+8*3600*1000));
            //     }
            // },
                builder(){
                    window.open('http://47.104.99.213:8080/Thingworx/Runtime/index.html#master=SDKJ_ML_Master_v1&mashup=SDKJ_ML_Landing_Page_v1&appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true')
                },
                analysis(){
                    window.open('http://47.104.99.213:8080/Thingworx/Runtime/index.html#master=SDKJ_ML_Prediction_Master&mashup=SDKJ_ML_MachineService&appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true')
                },
                learning(){
                    window.open('http://47.104.99.213:8080/Thingworx/Runtime/index.html#master=TW.Analytics.AddRemoveTiles.Master&mashup=TW.Analytics.AddorRemoveTiles&appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true')
                },
                manager(){
                    window.open('http://47.104.99.213:8080/Thingworx/Runtime/index.html#mashup=SDKJ.AnalysisServices.AnalysisMainMashup&appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true')
                },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },

        }
    }
</script>

<style scoped>
    .avatar-uploader {
        border: 1px solid #DBDBDB;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 254px;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 254px;
        height: 254px;
        line-height: 254px;
        text-align: center;
    }

    .avatar {
        width: 254px;
        height: 254px;
        display: block;
    }

    .QRCode {
        width: 170px;
        height: 170px;
        background: #FFFFFF;
        border: 1px solid #999999;
    }

    .imgUrl_look {
        border: 1px solid rgb(219, 219, 219);
        border-radius: 6px;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form--inline .el-form-item {
        display: flex;
        justify-content: start;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }

    ::v-deep .el-form-item {
        margin-bottom: 16px;
    }

    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }
</style>