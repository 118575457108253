<!-- KPI-工段查询 -->
<template>
    <div class="page_container" v-loading="loading">
        <div class="alarmAnalysisHeader pdr30 flex justify_start align_center">
            组织KPI分析
        </div>
        <div class="flex flex_column bgc_FFFFFF pdr30">
            <!-- select下拉框 -->
            <div class="flex sectionSelect justify_between">
                <div class="flex">
                    <!-- <el-cascader class="mg_right_10 width_190" ref="demoCascader1" v-model="organizationValue" placeholder="设备选择"
                :options="orgTreeData" :props="defaultProps" @change="orgChange" :show-all-levels='false' collapse-tags
                clearable>
            </el-cascader> -->
                    <el-cascader class="mg_right_10 width_190 " ref="demoCascader1" v-model="organizationValue"
                        placeholder="设备选择" :options="orgTreeData"
                        :props="{multiple: true,checkStrictly:true,value:'ID',label:'Name',children:'children',expandTrigger: 'hover'}"
                        @change="orgChange" :show-all-levels='false' collapse-tags clearable>
                    </el-cascader>
                    <el-select v-model="dtValue" placeholder="时间维度" class="alarmQuerySelect mg_right_10">
                        <el-option v-for="item in dataFrame" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-select v-model="wpValue" placeholder="工段" class="alarmQuerySelect mg_right_10" multiple
                        collapse-tags clearable style="width:150px">
                        <el-option v-for="(item,index) in wpList " :key="index" :label="item.workpieceProgramName"
                            :value="item.workpieceProgramNumber">
                        </el-option>
                    </el-select>

                    <el-date-picker v-model="value2" class="mg_right_10" type="datetimerange" align="right"
                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="changeValue2">
                    </el-date-picker>

                    <el-button type="primary" @click="testQuery"
                        class="alarmQuerySelectButton flex align_center justify_center">查询
                    </el-button>
                </div>
                <!-- <div>
                    <el-button type="primary" class="alarmQuerySelectButton flex align_center justify_center">导出
                    </el-button>
                </div> -->
            </div>
            <!-- table -->
            <div class="mg_right_30 mg_left_30 fn_size14">
                <el-table ref="multipleTable" :data="shopSelect" tooltip-effect="dark" class="width_100"
                    @selection-change="handleSelectionChange" height="490" border>
                    <!-- <el-table-column type="selection" width="60"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="60"></el-table-column>
                    <!-- <el-table-column prop="companyName" label="公司名" width=""></el-table-column> -->
                    <el-table-column prop="name" label="设备名" width=""></el-table-column>
                    <el-table-column prop="indexCycle" label="指标周期" width="190"></el-table-column>
                    <el-table-column prop="PowerOnRate" label="开机率" width=""></el-table-column>
                    <el-table-column prop="CurrentPlanOutput" label="计划产量" width=""></el-table-column>
                    <el-table-column prop="CurrentActualOutput" label="实际产量" width=""></el-table-column>
                    <el-table-column prop="CurrentQualifiedOutput" label="合格产量" width=""></el-table-column>
                    <el-table-column prop="checkRate" label="点检达成率" width=""></el-table-column>
                    <el-table-column prop="maintain" label="保养达成率" width=""></el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_between align_start mg_right_30 mg_left_30">
                <div> </div>
                <!-- <el-pagination :page-sizes="[5, 10, 100]" :page-size="pageSize" :total="workShopSelect.length"
                    @current-change="handleCurrentChange" @size-change="handleSizeChange" layout="prev, pager, next">
                </el-pagination> -->
            </div>
            <!-- select -->
            <el-select v-model="indicatorSelectValue" @change="indicatorChange" placeholder="单选指标"
                class="alarmQuerySelect mg_right_30 mg_left_30 mg_top_30">
                <el-option v-for="item in indicatorData" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
            </el-select>
            <!-- echarts图 -->
            <div class="diy_div">
                <div id="chartColumn" class="width_100 height_100"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import {
        kpiAnalysisOrganizationWorkpieceSelect,
        kpiAnalysisOrganizationSelect,
        kpiAnalysisOrganizationIndicatorSelect,
        kpiAnalysisOrganizationAnalysis,
        kpiAnalysisOrganizationAnalysisTrend,
    } from "@/api/api_deviceManagement";
    export default {
        data() {
            return {
                defaultProps: {
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true, //多选
                    emitPath: false, //只返回选中节点的值
                    // checkStrictly: true //多选任一级
                },
                value2: [new Date("2021-06-01"), new Date(new Date().toLocaleDateString())],
                value1: "",
                value: "",
                workShopName: "",
                lineName: "",
                indexCycle: "",
                workShopSelect: [],
                dataFrame: [{
                        value: "1",
                        label: "日",
                    },
                    {
                        value: "2",
                        label: "月",
                    },
                ],
                pageSize: 10,
                currentPage: 1,
                multipleSelection: [],
                time: [new Date("2021-06-01"), new Date(new Date().toLocaleDateString())],
                shopSelect: [],
                tkValue: "1",
                wsIDValue: "1",
                dtValue: "1",
                orgTreeData: [],
                permission: {},
                orgSelect: [],
                nowData: [],
                checkData: [],
                indicatorData: 1,
                indicatorSelectValue: 1,
                wpList: [],
                wpValue: [],
                organizationValue: [],
                currentData: [],
                loading: true,

            };
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.kpiAnalysisOrganizationSelect();
            // this.kpiAnalysisOrganizationAnalysis();
            // this.kpiAnalysisOrganizationAnalysisTrend();
            this.kpiAnalysisOrganizationIndicatorSelect();
            // this.kpiAnalysisOrganizationWorkpieceSelect();
        },
        methods: {
            //loading 
            getData() {
                const loading = this.$loading({
                    lock: true, //lock的修改符--默认是false
                    text: 'Loading', //显示在加载图标下方的加载文案
                    spinner: 'el-icon-loading', //自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
                    target: document.querySelector('#table') //loadin覆盖的dom元素节点
                });
                loading.close() //成功回调函数停止加载
            },
            testQuery() {
                this.kpiAnalysisOrganizationAnalysis();
                this.kpiAnalysisOrganizationAnalysisTrend();

            },

            //组织选择框变化反馈
            orgChange() {
                const obj = this.$refs['demoCascader1'].getCheckedNodes()
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }

                var testData = {
                    Type: '',
                    ID: []
                }
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                this.checkData = testData;
                console.log(this.checkData);
                //   this.currentData = this.organizationValue

            },

            //工段选择
            async kpiAnalysisOrganizationWorkpieceSelect() {
                // this.orgTreeData = [];
                this.wpList = [];
                this.wpValue = [];
                var param = {
                    organization: {
                        "EquipmentID": this.currentData
                    },
                    // startDate:this.time[0],
                    // endDate:this.time[1],
                    permission: this.permission,
                    dateType: this.dtValue,
                    // KpiType:1
                };
                if (this.time != null) {
                    param.startDate = this.value2[0]
                    param.endDate = this.value2[1]
                }
                const selectRes = await kpiAnalysisOrganizationWorkpieceSelect(param);
                this.wpList = selectRes.data;
                if(this.wpList.length!=0) {
                    this.wpValue.push(this.wpList[0].workpieceProgramNumber)
                }
            },
            changeValue2() {
                this.kpiAnalysisOrganizationWorkpieceSelect()
            },

            //组织选择框
            async kpiAnalysisOrganizationSelect() {

                var param = {
                    permission: this.permission,
                };
                const selectRes = await kpiAnalysisOrganizationSelect(param);
                console.log(selectRes);
                this.orgTreeData = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];
                this.organizationValue = selectRes.data.organizationPermissionAll[0];
                for (var i = 0; i < this.standardArr.length; i++) {
                    if (this.standardArr[i].Type == 'E') {
                        // this.organizationValue1 = this.equipmentArr[i].ID;
                        this.currentData = this.standardArr[i].ID;
                        this.standardData = this.standardArr[i].ID;
                    }
                }
                this.kpiAnalysisOrganizationAnalysis()
                this.kpiAnalysisOrganizationWorkpieceSelect()
                // this.kpiAnalysisOrganizationIndicatorSelect()
                this.kpiAnalysisOrganizationAnalysisTrend()


            },

            //table
            async kpiAnalysisOrganizationAnalysis() {
                this.shopSelect = [];
                var param = {
                    organization: this.checkData,
                    // startDate: this.time[0],
                    // endDate: this.time[1],
                    dateType: this.dtValue,
                    permission: this.permission
                };
                if (this.value2 != null) {
                    param.startDate = this.value2[0]
                    param.endDate = this.value2[1]
                }
                const selectRes = await kpiAnalysisOrganizationAnalysis(param);
                this.shopSelect = selectRes.data;
                this.loading = false;
            },

            //图
            async kpiAnalysisOrganizationAnalysisTrend() {
                var param = {
                    organization: {
                        "Type": "E",
                        "ID": this.currentData,
                    },
                    // startDate: this.value2[0],
                    // endDate: this.value2[1],
                    typeKpi: this.indicatorSelectValue,
                    dateType: this.dtValue,
                    perimission: this.permission,
                    programNumber: this.wpValue
                };
                if (this.value2 != null) {
                    param.startDate = this.value2[0]
                    param.endDate = this.value2[1]
                }
                if (this.wpValue) {
                    param.programNumber = this.wpValue
                } else {
                    param.programNumber = 1
                }
                // if (this.alarmTypeValue != null && this.alarmTypeValue != "") {
                //     param.alarmType = this.alarmTypeValue;
                // }
                // if (this.alarmLevelValue != null && this.alarmLevelValue != "") {
                //     param.alarmLevel = this.alarmLevelValue;
                // }
                const selectRes = await kpiAnalysisOrganizationAnalysisTrend(param);
                var testData = selectRes.data;

                var line = {
                    legend: {
                        type: "scroll",
                        data: [],
                        bottom: "2%",
                        textStyle: {
                            color: "#808080",
                            fontSize: 12,
                            rotate: 0,
                        },
                    },
                    xAxis: {
                        type: "category",
                        data: [],
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "##E3E4E6",
                            },
                        },
                        axisLabel: {
                            interval: 0,
                            color: "#808080",
                            fontSize: 12,
                            rotate: 60
                        },
                    },
                    series: [],
                };
                console.log("test", testData);
                line.xAxis.data = testData[0].time;
                for (var i = 0; i < testData.length; i++) {
                    var curObj = {
                        type: "line",
                        barGap: 0,
                        barWidth: 10, //柱的宽度
                        smooth: true,
                    };
                    curObj.name = testData[i].name
                    curObj.data = testData[i].value
                    line.series[i] = curObj;
                    line.legend.data[i] = testData[i].name;
                }

                this.drawShape(line);
            },


            //指标选择框
            async kpiAnalysisOrganizationIndicatorSelect() {
                this.indicatorData = [];
                var param = {
                    organization: this.checkData,
                    typeKpi: this.indicatorSelectValue
                };
                const selectRes = await kpiAnalysisOrganizationIndicatorSelect(param);
                this.indicatorData = selectRes.data;
            },

            //指标选择框反馈
            indicatorChange() {
                this.kpiAnalysisOrganizationAnalysisTrend()
            },

            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            drawShape(line) {
                /**数据集柱状图 */
                this.chartColumn = echarts.init(document.getElementById("chartColumn"));
                this.chartColumn.setOption({
                    title: {},
                    // 提示框
                    tooltip: {},
                    // legend: line.legend,"产线17           "
                    color: ["#6395F9", "#62DAAB", "#657798", "#F6C022"],
                    legend: line.legend,
                    grid: {
                        //设置中间内容距离div的距离，也就是内边距
                        left: "3%",
                        right: "3%",
                        bottom: "10%",
                        containLabel: true,
                        /* width: 'auto', height: 'auto', */
                    },
                    xAxis: line.xAxis,

                    yAxis: {
                        type:'value',
                        // min: 0, //y轴的最小值
                        // max: 150, //y轴最大值
                        // interval: 10, //值之间的间隔
                    },
                    series: line.series,
                });
            },

            handleCurrentChange: function (cpage) {
                this.currentPage = cpage;
            },

            handleSizeChange: function (psize) {
                this.pageSize = psize;
            },
        },
    };
</script>

<style scoped>
    .alarmAnalysisHeader {
        height: 40px;
        padding-left: 20px;
        background-color: #e2f0fe;
        border: 1px solid #ccdbe5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .alarmAnalysisHeader2 {
        width: 1798px;
        height: 40px;
        padding-left: 20px;
        background-color: #e2f0fe;
        border: 1px solid #ccdbe5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .alarmQuerySelect {
        width: 150px;
        height: 36px;
    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009bfd;
    }

    .sectionSelect {
        margin-left: 31px;
        margin-top: 19px;
        width: 1802px;
        padding-bottom: 22px;
    }

    .diy_div {
        width: 100%;
        height: 450px;
    }
</style>