/**
 * 工艺管理
 */
 import req from './index.js'

export const craftManagementOrganizationSelect = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementOrganizationSelect',param) }; //保养工单 工单评分等级
export const craftManagementWorkRhythmAnalysisProgramNumberSelect = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementWorkRhythmAnalysisProgramNumberSelect',param) }; //加个节拍分析 程序号选择
export const craftManagementWorkRhythmAnalysisTrend = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementWorkRhythmAnalysisTrend',param) }; //加个节拍分析趋势接口
export const craftManagementWorkRhythmAnalysisTData = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementWorkRhythmAnalysisTData',param) }; //加个节拍分析数据接口
export const craftManagementCraftParameterAnalysisProgramNumberSelect = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementCraftParameterAnalysisProgramNumberSelect',param) }; //加工工艺参数分析 程序号选择
export const craftManagementCraftParameterAnalysisEquipmentPropertySelect = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementCraftParameterAnalysisEquipmentPropertySelect',param) }; //加工工艺参数分析 设备属性选择框
export const craftManagementCraftParameterAnalysisTrend = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementCraftParameterAnalysisTrend',param) }; //加工工艺参数分析接口
export const craftManagementCraftParameterAnalysisData = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementCraftParameterAnalysisData',param) }; //加工工艺参数分析数据接口
export const craftManagementCraftTimeAnalysisProgramNumberSelect = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementCraftTimeAnalysisProgramNumberSelect',param) }; //加工时间分析程序号选择
export const craftManagementCraftTimeAnalysis = param => { return req.post('EMS.Thing.Api.CraftManagement/Services/CraftManagementCraftTimeAnalysis',param) }; //加工时间分析


