// 维保配置-班组配置
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>班组配置</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <div>班组分类选择：</div>
                    <el-select v-model="value" placeholder="请选择" class="width_150 " clearable>
                        <el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- <div class="mg_left_20">XXX：</div>
                    <el-input v-model="value" placeholder="" class="width_160 mg_left_8"></el-input> -->
                    <el-button type="primary" class=" mg_left_10" size="medium" style=" background: #009BFD;border: none;"
                        @click="select">查询
                    </el-button>
                </div>
            </div>
            <!-- mg_top_20 -->
            <div class="flex  pdl30 pdr30 pdt_20">
                <el-button type="primary" class="width_110 mg_left_10" icon="el-icon-circle-plus-outline"
                    style=" background: #03CABE;border: none;" @click="add">新增
                </el-button>
                <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
            </div>
            <div class="pdl30 pdr30 hei80V">
                <el-table :data="queryList" style="width: 100%" class=" mg_top_20" height="100%"
                @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width="180">
                    </el-table-column> -->
                    <el-table-column prop="groupSettingsCategory" label="班组分类" width="180">
                    </el-table-column>
                    <el-table-column prop="value" label="班组名" width="180">
                    </el-table-column>
                    <el-table-column prop="groupPerson" label="班组人员">
                    </el-table-column>
                    <el-table-column prop="remarks" label="描述">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="gotoModify(scope.row)">
                                修改
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row)">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <!-- 新增信息弹出框 -->
        <el-dialog title="新增" :visible.sync="dialogFormVisible" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="form">
                    <!-- <el-form-item label="用户编号：" :label-width="formLabelWidth">
                        <el-input v-model="form.userID" autocomplete="off" class=""></el-input>
                    </el-form-item> -->
                    <el-form-item label="班组分类：" :label-width="formLabelWidth">
                        <el-select v-model="form.groupSettingsCategory" placeholder="请选择" class="">
                            <el-option v-for="(item,index) in options" :key="index" :label="item.description"
                                :value="item.description"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="班组名：" :label-width="formLabelWidth">
                        <el-input v-model="form.groupName" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    <el-form-item label="班组人员：" :label-width="formLabelWidth">
                        <el-select multiple v-model="form.groupPerson" placeholder="请选择" class="" >
                            <el-option v-for="(item,index) in options2" :key="index" :label="item.userName"
                                :value="item.userName"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述：" :label-width="formLabelWidth">
                        <el-input v-model="form.remarks" type="textarea" :rows="6" autocomplete="off" class="">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialogFormVisible=false">取 消</el-button>
                <el-button type="primary" @click="addtest">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改信息页弹出框-->
        <el-dialog title="编辑" :visible.sync="dialogFormVisible1" width="30%" :before-close="handleClose">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="table">
                    <!-- <el-form-item label="ID：" :label-width="formLabelWidth">
                        <el-input v-model="table.ID" autocomplete="off" class="" :disabled="true"></el-input>
                    </el-form-item> -->
                    <el-form-item label="班组分类：" :label-width="formLabelWidth">
                        <el-select v-model="table.groupSettingsCategory" placeholder="请选择" class="">
                            <el-option v-for="(item,index) in options" :key="index" :label="item.description"
                                :value="item.description"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="班组名：" :label-width="formLabelWidth">
                        <el-input v-model="table.value" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    <el-form-item label="班组人员：" :label-width="formLabelWidth">
                        <el-select multiple v-model="table.groupPerson" placeholder="请选择" class="" collapse-tags>
                            <el-option v-for="(item,index) in options2" :key="index" :label="item.userName"
                                :value="item.userName"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述：" :label-width="formLabelWidth">
                        <el-input v-model="table.remarks" type="textarea" :rows="6" autocomplete="off" class="">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center mg_right_50 pdb_20 mg_top_10">
                <el-button type="primary" @click="onEdit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {
        rMIGroupSettingsInsert,
        rMIGroupSettingsDelete,
        rMIGroupSettingsUpdate,
        rMIGroupSettingsQuery,
        rMIGroupSettingsQueryLimit,
        rMIGroupCategoryQuery,
        rMIGroupSettingsExecPersonQuery,
    } from "@/api/api_systemConfig";
    export default {
        data() {
            return {
                value: '',
                options: [],
                options2: [],
                queryList: [],
                groupPerson: '',
                form: {
                    userID: '',
                    groupSettingsCategory: '',
                    groupName: '',
                    groupPerson: '',
                    remarks: ''
                },
                table: {
                    ID: '',
                    groupSettingsCategory: '',
                    groupName: '',
                    groupPerson: '',
                    remarks: ''
                },
                groupSettingsCategory: [],
                dialogFormVisible: false,
                dialogFormVisible1: false,
                userID: '',
                userName: '',
                formLabelWidth: '120px',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            };
            // this.rMIGroupSettingsQuery();
            this.rMIGroupSettingsQueryLimit();
            this.rMIGroupCategoryQuery();
            this.rMIGroupSettingsExecPersonQuery();
        },
        methods: {
            //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '班组配置表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            //筛选查询
            select() {
                this.currentPage = 1;
                this.rMIGroupSettingsQueryLimit();
            },
            //分页筛选查询
            async rMIGroupSettingsQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (!this.value != '') {} else {
                    var param = {
                        userID: this.userID,
                        pageSize: this.pageSize,
                        pageNumber: this.currentPage,
                        groupSettingsCategory: this.value,
                    }
                }
                const res = await rMIGroupSettingsQueryLimit(param);
                this.queryList = res.rows;
                this.pageQuantity = res.pageQuantity
            },
            handleCurrentChange(val) {
                // this.PageNumber = val;
                // this.selectOnDuty(); //查询table数据的方法
                this.currentPage = val;
                this.rMIGroupSettingsQueryLimit();
            },
            //查询所有
            async rMIGroupSettingsQuery() {
                var param = {
                    userID: this.userID,
                };
                const res = await rMIGroupSettingsQuery(param);
                console.log(res);
                this.queryList = res.rows;
            },
            //班组分类查询
            async rMIGroupCategoryQuery() {
                var param = {};
                const res = await rMIGroupCategoryQuery(param);
                console.log(res);
                this.options = res.array;
            },
            //班组人员查询
            async rMIGroupSettingsExecPersonQuery() {
                var param = {
                    userID: this.userID,
                    //    userName:this.userName,
                };
                const res = await rMIGroupSettingsExecPersonQuery(param);
                console.log(res);
                this.options2 = res.array;

            },
            //新增按钮
            add() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            //新增--确定按钮
            addtest() {
                this.rMIGroupSettingsInsert();
            },
            //插入
            async rMIGroupSettingsInsert() {
                this.form.userID = this.userID
                var param = {
                    userID: this.form.userID,
                    groupPerson: this.form.groupPerson.join(','),
                    groupName: this.form.groupName,
                    groupSettingsCategory: this.form.groupSettingsCategory,
                    remarks: this.form.remarks
                };
                console.log(param);
                const res = await rMIGroupSettingsInsert(param);
                console.log(res);
                if (res && res.data && res.data.result) {
                    this.dialogFormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMIGroupSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error',
                    });
                }
            },
            handleClose() {
                this.dialogFormVisible1 = false;
                this.rMIGroupSettingsQueryLimit();
            },
            //修改按钮
            gotoModify(val) {
                this.dialogFormVisible1 = true;
                this.table = val;
                this.currentInfo = val;
                if (typeof this.table.groupPerson == 'string') {
                    this.table.groupPerson = this.table.groupPerson.split(',')
                }
                // this.table.groupName = val.value;
            },
            //修改--确认按钮
            onEdit() {
                this.rMIGroupSettingsUpdate();
                this.dialogFormVisible1 = false;
            },
            //修改
            async rMIGroupSettingsUpdate() {
                var param = {
                    ID: this.table.ID,
                    groupPerson: this.table.groupPerson.join(','),
                    groupName: this.table.value,
                    groupSettingsCategory: this.table.groupSettingsCategory,
                    remarks: this.table.remarks,
                };
                const res = await rMIGroupSettingsUpdate(param);
                if (res && res.data && res.data.result) {
                    this.dialogTableVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMIGroupSettingsQueryLimit();
                } else {
                    this.$message({
                        message: res,
                        message,
                        type: 'error'
                    });
                }
                // this.dialogTableVisible = false;
                // console.log(res.data)
            },
            //删除按钮
            delTableData(val) {
                const delNum = val.ID;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.queryList.length, this.pageQuantity, this
                        .currentPage)
                    this.rMIGroupSettingsDelete(delNum);
                }).catch(() => {

                });
            },
            //删除
            async rMIGroupSettingsDelete(delNum) {
                var param = {
                    ID: delNum,
                }
                console.log(param);
                const res = await rMIGroupSettingsDelete(param);
                console.log(res);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMIGroupSettingsQueryLimit();
                } else {
                    this.$message({
                        message: res.message,
                        type: 'error'
                    });
                }
            },


        }
    }
</script>
<style scoped>
    .hei80V {
        height: 78%;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 14px
    }
</style>