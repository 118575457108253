<!-- 视频管理 -->
<template>
 <div class="page_container " >
        <button type="primary" style="border:1px solid black;width:200px;line-height:auto;color:black;background-color:#eeeeee;cursor:pointer" 
        @click="click">
            <a 
            style="font-size:25px;">海康云眸</a> 
            <!-- <span>海康云眸</span> -->
        </button>
        </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        data() {
            return {
               
            }
        },

        mounted() {
        },
        methods: {
           click(){
               window.open('https://www.hik-cloud.com/safe-center/index.html#/login/retail?type=true&direction=%2Fvideo%2Fscene', "_blank")
           }
        }
    }
</script>

<style scoped>
    .equipmentAccountHeader {
        
        height: 40px;
        padding-left: 20px;
        background-color: #E2F0FE;
        border: 1px solid #CCDBE5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .equipmentAccountTable {
        width: 1800px;
        height: 530px;
    }

    .equipmentAccountSelect {
        width: 150px;
        height: 36px;
    }

    .equipmentAccountSelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }

    .sectionSelect {
        width: 1802px;
        /* padding-bottom: 22px; */
    }

    .diy_div {
        width: 100%;
        height: 450px;
    }

    .equipmentAccountInput {
        width: 220px;
        height: 36px;
    }
</style>