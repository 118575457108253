<!-- 维修计划-修改计划 -->
<template>
    <div class="page_container height_100">
        <inner-top-nav></inner-top-nav>

        <div class="bgc_FFFFFF flex flex_column align_center" style="height: 95%;">
            <div class="box_head1 width_100">设备信息</div>
            <el-form :inline="true" :model="detailInfo" label-width="110px" class="mg_top_40 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="detailInfo.deviceID" placeholder="设备编号" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备名称：">
                    <el-input v-model="detailInfo.deviceName" placeholder="设备名称" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备类别：">
                    <el-input v-model="detailInfo.deviceType_name" placeholder="设备类别" class="width_180" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item label="规格型号">
                    <el-select v-model="detailInfo.execType" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="所属部门：">
                    <el-input v-model="detailInfo.deviceDepartment" placeholder="设备部门" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="操作人：">
                    <el-input v-model="detailInfo.oprPerson" placeholder="操作人" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备位置：">
                    <el-input v-model="detailInfo.deviceLocation" placeholder="设备位置" class="width_180" disabled></el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100 mg_top_30">维修信息</div>
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;" >
                <el-form-item label="维修类别：" class=" ">
                    <!-- <el-input v-model="detailInfo.execType" placeholder="审批人" class="width_180"></el-input> -->
                    <el-select v-model="detailInfo.execType" placeholder="请选择维修类别" class="width_180">
                        <el-option v-for="(item,index) in repairCategory" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="紧急程度：" class=" ">
                    <el-select v-model="detailInfo.urgenLevel" placeholder="请选择紧急程度" class="width_180">
                        <el-option v-for="(item,index) in repairUrgenLevel" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维修班组：" class="">
                   <el-select v-model="detailInfo.execGroup" placeholder="请选择维修班组" class="width_180" disabled>
                        <el-option v-for="(item,index) in repairExecGroup" :key="index" :label="item.description" :value="item.value" >
                        </el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="维修人员：" class="">
                    <el-input v-model="detailInfo.execPerson" placeholder="维修人员" class="width_180" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item label="维修人员：" class="">
                    <el-select v-model="detailInfo.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label="用户编号：" class=" ">
                    <el-input v-model="detailInfo.userID" placeholder="用户编号" class="width_180" disabled>
                    </el-input>
                </el-form-item> -->
            
            <!-- <el-form-item label="计划时间：" class=" "> -->
                    <!-- <el-input v-model="detailInfo.planTime" placeholder="审批人" class="width_180"></el-input> -->
                <!-- <el-date-picker value-format="timestamp" v-model="detailInfo.planTime" prefix-icon="el-icon-date" clear-icon="el-input-icon"
                    type="datetime" placeholder="选择日期时间">
                </el-date-picker> -->
            <!-- </el-form-item> -->
            <!-- <el-form-item label="提醒时间：" class=" ">
                <el-date-picker value-format="timestamp" v-model="detailInfo.remindTime" prefix-icon="el-icon-date"
                    clear-icon="el-input-icon" type="datetime" placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item> -->
            <el-form-item label="是否审批：" class=" ">
                <el-radio-group v-model="detailInfo.isAuth" class="width_180" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-form>
            <el-form :model="detailInfo" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="detailInfo.remarks" resize="none" ></el-input>
                </el-form-item>
            </el-form>
            <!-- </div> -->
            <div class="flex mg_top_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>

    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
        import {
        repairPlanQuery,
        repairPlanUpdate,
        repairUrgenLevel,
        repairGroupQuery,
        repairTypeQuery,
        repairPlanStatusQuery,

    } from '@/api/api_deviceManagement.js'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                value: '',
                formLabelWidth: '110px',
                detailID: '',
                detailInfo: {},
                repairExecGroup: [],
                repairUrgenLevel: [],
                planStatusAr: [],
                repairCategory: [],
                organizationValue: '',
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                dialogTableVisible: false,
                checkData: {},
                equInfoList: [],
                currentEqu: [],
                UrgenLevel:[],

                currentPage: 1,
                pageSize: 2,
                pageQuantity: 0
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.repairPlanQuery();
            }
            this.repairPlanQuery()
            this.repairGroupQuery()
            this.getrepairUrgenLevel()
            this.repairTypeQuery()
        },
        methods: {
             //查询单条
             async repairPlanQuery() {
                var param = {
                    ID: this.detailID
                }
                const selectRes = await repairPlanQuery(param);
                this.detailInfo = selectRes.data[0];
                  this.detailInfo.deviceID=parseInt(this.detailInfo.deviceID)
                this.currentEqu.push(this.detailInfo)
            },
             //班组下拉框
            async repairGroupQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairGroupQuery(param);
                this.repairExecGroup = selectRes.data;
            },
            async getrepairUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairUrgenLevel(param);
                this.repairUrgenLevel = selectRes.data;
            },
            async repairPlanStatusQuery() {
                const selectRes = await repairPlanStatusQuery();
                this.planStatusAr = selectRes.data;
            },
            async repairTypeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairTypeQuery(param);
                this.repairCategory = selectRes.data
            },
            delCurrentEqu() {
                this.$confirm('此操作将删除该设备, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentEqu = [];
                }).catch(() => {});
            },
            changeDevice() {
                this.equipmentLedgerOrganizationSelect();
                this.dialogTableVisible = true;
            },
            async equipmentLedgerOrganizationSelect() {
                var param = {
                    permission: this.permission
                };
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organization = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];
                this.equipmentLedgerQuery();
            },
            queryDevice() {
                // console.log(this.checkData)
                this.equipmentLedgerQuery();
            },
            async equipmentLedgerQuery() {
                var param = {
                    organization: this.checkData,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.equInfoList = selectRes.data;
                this.pageQuantity = selectRes.pageQuantity;
            },
            handleCurrentChange(val) {
                this.equipmentLedgerQuery(); //查询table数据的方法
            },
            choiceEquipment(row) {
                this.$confirm('确认选择此设备', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentEqu = [];
                    this.dialogTableVisible = false;
                    var currentInfo = {
                        deviceID: row.equipmentID, 
                        deviceName: row.EquipmentName, 
                        deviceType: row.EquipmentType, 
                        deviceModel: row.EquipmentModel, 
                        deviceDepartment: row.Department, 
                        deviceLocation: row.InstallationPosition 
                    }
                    this.currentEqu.push(currentInfo);
                }).catch(() => {

                });
            },
            async repairPlanUpdate() {
                const selectRes = await repairPlanUpdate(this.detailInfo);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                    this.detailInfo = {};
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'error'
                    });
                }
            },

            sbumit() {
                // this.$router.go(-1);
                console.log(this.detailInfo)
                this.detailInfo.deviceID = this.currentEqu[0].deviceID.toString();
                this.detailInfo.deviceName = this.currentEqu[0].deviceName;
                this.detailInfo.deviceType = this.currentEqu[0].deviceType;
                this.detailInfo.deviceModel = this.currentEqu[0].deviceModel;
                this.detailInfo.deviceDepartment = this.currentEqu[0].deviceDepartment;
                this.detailInfo.deviceLocation = this.currentEqu[0].deviceLocation;

                this.repairPlanUpdate()
            },
            colsePage() {
                this.$router.go(-1);
                this.detailInfo = {};
            }
        }

    }
</script>
<!-- <style>
    .textarea .el-form-item__content {
        width: 84%
    }
</style> -->
<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-form {
        width: 100%;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>