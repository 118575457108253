// 维保配置-等级配置
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>等级配置</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <!-- <el-form-item label="等级分类："> -->
                    <div>等级分类选择：</div>
                    <el-select v-model="djflvalue" label="等级分类：" placeholder="请选择" class="width_150" clearable>
                        <el-option v-for="item in levelCategory" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- </el-form-item> -->
                    <!-- <div class="mg_left_20">XXX：</div> -->
                    <el-button type="primary" class="mg_left_10" size="medium" style=" background: #009BFD;border: none;"
                        @click="query">查询</el-button>
                </div>
            </div>
            <!-- mg_top_20 -->
            <div class="flex  pdl30 pdr30 pdt_20">
                <el-button type="primary" class="width_110" icon="el-icon-circle-plus-outline"
                    style=" background: #03CABE;border: none;" @click="add">新增
                </el-button>
                <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
            </div>
            <div class="pdl30 pdr30 hei80V">
                <el-table :data="tableData" style="width: 100%" class=" mg_top_20" height="100%"
                @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width="180">
                    </el-table-column> -->
                    <el-table-column prop="levelCategory" label="等级分类" width="180">
                    </el-table-column>
                    <el-table-column prop="value" label="等级" width="180">
                    </el-table-column>
                    <el-table-column prop="remarks" label="描述">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="gotoModify(scope.row)">
                                修改
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="新增" :visible.sync="dialogFormVisible" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="form">
                    <el-form-item label="等级：" :label-width="formLabelWidth">
                        <el-input v-model="form.levelSetting" autocomplete="off" class=""></el-input>
                    </el-form-item>
                     <el-form-item label="等级分类：" :label-width="formLabelWidth">
                        <el-select v-model="form.levelCategory" placeholder="请选择" class="">
                            <el-option v-for="item in levelCategory" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述：" :label-width="formLabelWidth">
                        <el-input type="textarea"  :rows="3" v-model="form.remarks" autocomplete="off" class=""></el-input>
                    </el-form-item>
                   
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改页面 -->
        <el-dialog title="修改" :visible.sync="updaeLevelInfo" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">修改</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="form">
                    <el-form-item label="等级：" :label-width="formLabelWidth">
                        <el-input v-model="form.value" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    <el-form-item label="等级分类：" :label-width="formLabelWidth">
                        <el-select v-model="form.levelCategory" placeholder="请选择" class="">
                            <el-option v-for="item in levelCategory" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述：" :label-width="formLabelWidth">
                        <el-input type="textarea"  :rows="3"  v-model="form.remarks" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="updateSubmit">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
    import {
        rMILevelSettingsInsert,
        rMILevelSettingsDelete,
        rMILevelSettingsUpdate,
        rMILevelSettingsQuery,
        rMILevelSettingsQueryLimit,
        rMILevelCategoryQuery
    } from '@/api/api_systemConfig.js'
    export default {
        data() {
            return {
                value: '',
                tableData: [],
                levelCategory: [],
                djflvalue: '',
                dialogFormVisible: false,
                updaeLevelInfo: false,
                form: {},
                formLabelWidth: '120px',
                user_ID: '',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.user_ID = userInfo.userID
            }
            this.rMILevelSettingsQueryLimit();
            this.selectLevelCategory();
        },
        methods: {
            //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '等级配置表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },

            // 等级筛选 所有数据
            async rMILevelSettingsQuery() {
                var param = {
                    // ID: 1,
                    levelCategory: this.djflvalue,
                    userID: this.user_ID,
                }
                const selectRes = await rMILevelSettingsQuery(param);
                this.tableData = selectRes.rows;
            },
            // 等级筛选 分页数据
            async rMILevelSettingsQueryLimit() {
                var param = {
                    // ID: 1,
                    levelCategory: this.djflvalue,
                    userID: this.user_ID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                const selectRes = await rMILevelSettingsQueryLimit(param);
                this.tableData = selectRes.rows;
                this.pageQuantity = selectRes.pageQuantity
            },
            // 等级新增
            async rMILevelSettingsInsert() {
                var param = {
                    levelSetting: this.form.levelSetting,
                    remarks: this.form.remarks,
                    levelCategory: this.form.levelCategory,
                    userID: this.user_ID
                }
                const insertRes = await rMILevelSettingsInsert(param);
                if (insertRes && insertRes.data && insertRes.data.result) {
                    this.dialogFormVisible = false;
                    this.$message({
                        message: insertRes.message,
                        type: 'success'
                    });
                    this.rMILevelSettingsQueryLimit();
                } else {
                    this.$message.error(insertRes.message);
                }
            },
            // 等级删除
            async rMILevelSettingsDelete(ID) {
                var param = {
                    ID: ID
                }
                const deleteRes = await rMILevelSettingsDelete(param);
                if (deleteRes && deleteRes.data && deleteRes.data.result) {
                    this.$message({
                        message: deleteRes.message,
                        type: 'success'
                    });
                    this.rMILevelSettingsQueryLimit();
                } else {
                    this.$message.error(deleteRes.message);
                }
            },
            // 等级修改
            async rMILevelSettingsUpdate() {
                var param = {
                    levelSetting: this.form.value,
                    remarks: this.form.remarks,
                    levelCategory: this.form.levelCategory,
                    ID: this.form.ID
                }
                const updateRes = await rMILevelSettingsUpdate(param);
                if (updateRes && updateRes.data && updateRes.data.result) {
                    this.updaeLevelInfo = false;
                    this.$message({
                        message: updateRes.message,
                        type: 'success'
                    });
                    this.rMILevelSettingsQueryLimit();
                } else {
                    this.$message.error(updateRes.message);
                }
            },
            query() {
                this.currentPage = 1;
                this.rMILevelSettingsQueryLimit();
            },
            // 点击“新增”按钮，弹出新增页面
            add() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            // 在新增页面点击“确定”，提交调用“等级新增”接口
            submit() {
                this.rMILevelSettingsInsert();
            },
            // 点击“修改”按钮
            gotoModify(val) {
                console.log(val)
                this.form = val;
                this.updaeLevelInfo = true;
                // this.form.levelSetting = val.value;
            },
            // 在修改页面点击“确定”，提交调用“等级修改”接口
            updateSubmit() {
                this.rMILevelSettingsUpdate();
            },
            cancel(){
                this.updaeLevelInfo = false;
                this.rMILevelSettingsQuery();
            },
            // 点击“删除”，弹框提示。若选择“确定”，调用“等级删除”接口
            delTableData(id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.tableData.length, this.pageQuantity,this.currentPage)
                    this.rMILevelSettingsDelete(id);
                }).catch(() => {});
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.rMILevelSettingsQueryLimit();
            },
            // 等级分类筛选
            async selectLevelCategory() {
                var param = {}
                const res = await rMILevelCategoryQuery(param);
                res.array.forEach(element => {
                    this.levelCategory.push({
                        value: element.value,
                        label: element.description
                    });
                })
            },
        }
    }
</script>
<style scoped>
    .hei80V {
        height: 78%;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 14px
    }
</style>