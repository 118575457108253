<!-- -故障报修--添加报修 -->
<template>
    <div class="page_container height_100" v-loading="pageLoad">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center bgc_FFFFFF_height" style="">
            <div class="box_head1 width_100">设备信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_10 mg_top_40 details_box"> -->
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 70%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备类型：" class=" ">
                     <el-input v-model="formInline.deviceTypeName" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-input v-model="formInline.deviceModel" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="所属部门：" class=" ">
                    <el-input v-model="formInline.deviceDepartment" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.deviceLocation" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item class=" ">
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;"
                        @click="changeDevice">
                        选择设备</el-button>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100 mg_top_10">故障信息</div>
            <el-form :inline="true" :model="formInline" label-width="130px" class="mg_top_30 flex flex_wrap "
                style="width: 70%;">
                <el-form-item label="故障等级：">
                    <el-select v-model="formInline.failureLevel" @change="selectLevelChange" placeholder="选择故障等级"
                        class="width_180 " clearable>
                        <el-option v-for="(item,index) in levelData" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="故障类别：">
                    <el-select v-model="formInline.failureType" @change="selectTypeChange" placeholder="选择故障类型"
                        class="width_180" size="medium " clearable>
                        <el-option v-for="(item,index) in typeData" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="故障时间：">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.failureTime" type="datetime"
                    value-format="timestamp" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否需要审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否停机：" class=" ">
                    <el-radio-group v-model="formInline.machineStop" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width: 70%;">
                <el-form-item label="故障描述：">
                    <el-input type="textarea" :rows="5" resize="none" v-model="formInline.failureDesc"
                        placeholder="故障描述"></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth"  style="width: 70%;">
                <el-form-item label="备 注：">
                    <el-input type="textarea" :rows="5" resize="none" v-model="formInline.remarks" placeholder="备注内容">
                    </el-input>
                </el-form-item>
            </el-form>


            <!-- <div class="box_head1 width_100 ">关联图片</div>
            <el-form :model="formInline" label-width="110px" style="width:65%; " class="mg_top_30">
                <el-form-item label="图片上传：" prop="user"> -->
            <!-- <el-input placeholder="请输入内容" v-model="input2">
                        <template slot="append">图片上传</template>
                    </el-input> -->
            <!-- <el-upload action="https://jsonplaceholder.typicode.com/posts/" :on-success="handleAvatarSuccess"
                        :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple
                        :limit="3" :on-exceed="handleExceed">
                        <el-input placeholder="请输入内容" v-model="fileList[0].name" disabled slot="trigger">
                            <template slot="append">图片上传</template>
                        </el-input>
                    </el-upload>
                </el-form-item> 
            </el-form>-->
            <el-dialog title="设备选择" :visible.sync="dialogTableVisible" top='10vh' width="40%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">设备选择</div> -->
                <div class="flex flex_column height_370 mg_top_10">
                    <div class="flex">
                        <el-cascader  size="medium" v-model="organizationValue" placeholder="请选择" :options="organization" ref="demoCascader" :props="defaultProps" class=" el_cascader_width" @change="handleChangeCascader" :show-all-levels="false" collapse-tags clearable filterable></el-cascader>
                        <el-button type="primary" class="mg_left_10" size="medium" @click="queryDevice">查询</el-button>
                    </div>
                    <el-table :data="equInfoList" style="width: 100%" class="mg_top_20" height="100%" v-loading="load">
                        <el-table-column type="index" label="序号" width="80">
                        </el-table-column>
                        <el-table-column prop="EquipmentID" label="设备编号" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentName" label="设备名称" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentModel" label="规格型号" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentTypeName" label="设备类型" width="">
                        </el-table-column>
                        <el-table-column prop="WorkshopSection" label="所属部门" width="">
                        </el-table-column>
                        <el-table-column prop="InstallationPosition" label="设备位置" width="">
                        </el-table-column>
                        <el-table-column label="操作" width="">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="choiceEquipment(scope.row)">选择</span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div>
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </el-dialog>
            <div class="flex mg_top_10 mg_bottom_20">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        failureReportInsert,
        faultTypeQuery,
        faultLevelQuery,
    } from '@/api/api_deviceManagement.js'
    import {
        equipmentLedgerOrganizationSelect,
        equipmentLedgerQueryLimit,
        repairGroupQuery,
        repairTypeQuery,
    } from "@/api/api_systemConfig";
import fastOrderVue from '../../maintainManagement/maintainOrder/fastOrder.vue';
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                formLabelWidth: '110px',
                value1: '',
                radio1: 1,
                formInline: {
                    deviceID: '',
                    deviceName: '',
                    deviceType: '',
                    deviceModel: '',
                    deviceDepartment: '',
                    deviceLocation: '',
                    failureTime: '',
                    // oprPerson: '',
                    // oprPhoneNumber: '',
                    failureLevel: '',
                    failureType: '',
                    // authSort: '',
                    machineStop: '',
                    failureDesc: '',
                    isAuth: '',
                    remarks: '',
                    userID: ''
                },
                userData: JSON.parse(localStorage.getItem('userInfo')),
                input2: '',
                fileList: [{
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }, ],
                insertResult: false,

                //等级下拉框
                levelData: [],
                flValue: "",
                //类型下拉框
                typeData: [],
                ftValue: "",
                //组织参数
                dialogTableVisible: false,
                organization: [],
                standardArr: [],
                checkData: {},
                equInfoList: [],
                currentEqu: [],
                organizationValue: '',
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                groupData: [],
                typeData: [],
                permission: {},
                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0,

                load:false,
                pageLoad:true,
                companyType:{}
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            // this.failureReportInsert();
            this.equipmentLedgerOrganizationSelect();
            this.faultTypeQuery();
            this.faultLevelQuery();

        },
        methods: {

            colsePage() {
                this.$router.go(-1);
            },
            handleRemove(file, fileList) { //文件列表移除文件时的钩子
                console.log(file, fileList);
            },
            handlePreview(file) { //点击文件列表中已上传的文件时的钩子
                console.log(file);
            },
            handleExceed(files, fileList) { //文件超出个数限制时的钩子
                this.$message.warning(
                    `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) { //删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            handleAvatarSuccess(res, file) {
                // this.imageUrl = URL.createObjectURL(file.raw);
            },
            //设备类型下拉框
            selectTypeChange() {

            },
            //设备等级下拉框
            selectLevelChange() {

            },
            //类型下拉框
            async faultTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultTypeQuery(param)
                this.typeData = selectRes.data


            },
            //等级下拉框
            async faultLevelQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultLevelQuery(param)
                this.levelData = selectRes.data
            },

            //新增
            async failureReportInsert() {
                this.formInline.userID = this.userID;
                delete this.formInline.deviceTypeName;
                const selectRes = await failureReportInsert(this.formInline);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'error'
                    });
                }
            },

            //选择设备按钮
            changeDevice() {
                this.equInfoList = [];
                this.pageQuantity = 0;
                // this.equipmentLedgerOrganizationSelect();
                this.equipmentLedgerQueryLimit();
                this.dialogTableVisible = true;
            },
            //组织选择
            async equipmentLedgerOrganizationSelect() {
                var param = {
                    permission: this.permission
                };
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organization = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.companyType = selectRes.data.organizationPermissionAll.filter(r=>r.Type=="C")[0];//type为c的organization
                this.checkData = this.companyType;
                this.pageLoad = false
                // this.equipmentLedgerQueryLimit();
            },
            async equipmentLedgerQueryLimit() {
                 this.load = true
                var param = {
                    organization: this.checkData,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.equInfoList = selectRes.data;
                this.pageQuantity = selectRes.pageQuantity;
                this.load = false;
                // console.log(this.equInfoList);
            },
            handleCurrentChange(val) {
               // this.currentPage = val;
                this.equipmentLedgerQueryLimit(); //查询table数据的方法
            },
            queryDevice() {
                // console.log(this.checkData)
                this.currentPage = 1;
                this.equipmentLedgerQueryLimit();
            },
            choiceEquipment(row) {
                this.$confirm('确认选择此设备', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.currentEqu = [];
                    this.formInline.deviceID = row.EquipmentID.toString();
                    this.formInline.deviceName = row.EquipmentName;
                    this.formInline.deviceType = row.EquipmentType.toString();
                    this.formInline.deviceModel = row.EquipmentModel;
                    this.formInline.deviceDepartment = row.WorkshopSection;
                    this.formInline.deviceLocation = row.InstallationPosition;
                    this.formInline.deviceTypeName = row.EquipmentTypeName;
                    this.dialogTableVisible = false;
                    // this.currentEqu.push(row);
                    // console.log(this.currentEqu)

                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },

            //选择组织
            handleChangeCascader() {
                // console.log(this.organizationValue)
                if (this.organizationValue.length == 0) {
                    this.checkData = this.companyType;
                    return
                }
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    // console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        // console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                // console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)

            },
            sbumit() {
                if (this.formInline.deviceID == '') {
                    this.$message({
                        message: "请选择设备",
                        type: 'warning'
                    })
                    return
                }
                this.failureReportInsert()
            },
        }

    }
</script>
<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>