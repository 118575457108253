<!-- A设备管理-备件管理-备件台账 -->
<template>
    <div class="page_container height_100">
        <!-- 标题 -->

        <!-- 主要页面 -->
        <div class="bgc_FFFFFF height_100">
                <div class="box_head1">
                    <span>备件台账</span>
                    <div class="flex align_center mg_left_50 fn_size14">
                        <div class="">备件类型：</div>
                        <el-select v-model="sparePartType" placeholder="全部" class="width_150" clearable>
                            <el-option v-for="item in sparePartTypeArray" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="mg_left_20">备件名称：</div>
                        <el-input v-model="sparePartName" class="width_150" clearable></el-input>
                        <el-button type="primary" size="medium" class="mg_left_10" @click="onQueryLimit">查询
                        </el-button>
                    </div>
                </div>
        <div class="bgc_FFFFFF bgc_FFFFFF_height pdl30 pdr30 ">
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_140 bgc_03CABE buttonBorder" style="background-color: #03CABE;"
                        @click="gotoFaultDetails">添加备件</el-button>
                         <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
                </div>
            </div>
            <!-- 查询 select下拉框 -->
            <!-- table -->
                <!-- @selection-change="handleSelectionChange"  -->
                <el-table ref="multipleTable" :data="sparePartStockTableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" height="80%" :default-sort="{prop: 'date', order: 'descending'}"
                    @selection-change="handleSelectionChange"
                     @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="equipmentID" label="设备编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="equipmentName" label="设备名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sparePartID" label="备件编码" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width=""></el-table-column>
                    <el-table-column prop="sparePartModel" label="规格型号" width=""></el-table-column>
                    <el-table-column prop="sparePartType" label="备件类型" width=""></el-table-column>
                    <el-table-column prop="companyName" label="所属公司" width="110"></el-table-column>
                    <el-table-column prop="manufacturer" label="生产厂商" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="supplier" label="供应商" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="stockQuan" label="备件库存"></el-table-column>
                    <el-table-column prop="replaceCycle" label="更换周期"></el-table-column>
                    <el-table-column prop="replaceCycleUnit_name" label="更换周期单位" width="110" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="referPrice" label="参考价（元）"></el-table-column>
                    <el-table-column prop="stockMax" label="库存上限"></el-table-column>
                    <el-table-column prop="stockMin" label="库存下限"></el-table-column>
                    <el-table-column prop="unit" label="备件单位"></el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoFaultDetails4(scope.row.ID)">详细</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="updateSparePartAccountFunction(scope.row)">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="deleteSparePartAccount(scope.row.ID)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
           
            <!-- 添加备件弹框 -->
            <el-dialog title="添加备件信息" :visible.sync="addSparePartAccount" width="50%">
                <el-form :inline="true" :model="addSparePartAccountForm" label-width="150px"
                    class="mg_top_20 flex flex_wrap  pdl30 pdr30">
                    <el-form-item label="设备名称：" class="">
                        <el-cascader class="width_180" ref="demoCascader" v-model="addSparePartAccountForm.equipmentID"
                            :options="equipmentList" :props="defaultProps" :show-all-levels=false collapse-tags
                            clearable @change="getEquipmentDetail">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="备件编号：">
                        <el-input v-model="addSparePartAccountForm.sparePartID" placeholder="" class="width_180" onkeyup="value=value.replace(/[^\w\u4E00-\u9FA5]/g, '')" >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备件名称：">
                        <el-input v-model="addSparePartAccountForm.sparePartName" placeholder="" class="width_180" onkeyup="value=value.replace(/[^\w\u4E00-\u9FA5]/g, '')" >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备件类型：">
                        <el-select v-model="addSparePartAccountForm.sparePartType" placeholder="" class="width_180">
                            <el-option v-for="item in sparePartTypeArray" :key="item.value" :label="item.description"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备件型号：">
                        <el-input v-model="addSparePartAccountForm.sparePartModel" placeholder="" class="width_180">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="生产厂商：">
                        <el-input v-model="addSparePartAccountForm.manufacturer" placeholder="" class="width_180" onkeyup="value=value.replace(/[^\w\u4E00-\u9FA5]/g, '')" >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="供应商：">
                        <el-input v-model="addSparePartAccountForm.supplier" placeholder="" class="width_180" onkeyup="value=value.replace(/[^\w\u4E00-\u9FA5]/g, '')">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="更换周期：">
                        <el-input v-model="addSparePartAccountForm.replaceCycle" placeholder="" class="width_180"
                            type="number"  oninput="if(value<0){value=0}">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="更换周期单位：">
                        <el-select v-model="addSparePartAccountForm.replaceCycleUnit" placeholder="" class="width_180">
                            <el-option v-for="item in sparePartStockReplaceCycleUnitArray" :key="item.value"
                                :label="item.description" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="参考价(元)：">
                        <el-input v-model="addSparePartAccountForm.referPrice" placeholder="" class="width_180"
                            type="number"  oninput="if(value<0){value=0}">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="单位：">
                        <el-select v-model="addSparePartAccountForm.unit" placeholder="" class="width_180">
                            <el-option v-for="item in sparePartUnitArray" :key="item.value" :label="item.description"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="库存下限：">
                        <el-input v-model="addSparePartAccountForm.stockMin" placeholder="" class="width_180"
                            type="number"  oninput="if(value<0){value=0}">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="库存上限：">
                        <el-input v-model="addSparePartAccountForm.stockMax" placeholder="" class="width_180"
                            type="number"  oninput="if(value<0){value=0}">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="库存数量：">
                        <el-input v-model="addSparePartAccountForm.stockQuan" placeholder="" class="width_180"
                            type="number"  oninput="if(value<0){value=0}">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input v-model="addSparePartAccountForm.remarks" placeholder="" class="width_180"></el-input>
                    </el-form-item>
                </el-form>

                <div class="flex justify_center pdb_20">
                    <div>
                        <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                    </div>
                    <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
                </div>
            </el-dialog>

            <!-- 修改备件弹框 -->
            <el-dialog title="修改备件信息" :visible.sync="updateSparePartAccountFlag" width="50%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">修改备件信息</div> -->
                <!-- <div slot="title" class="box_head1 width_100">备件信息</div> -->
                <div class="flex justify_center">
                    <el-form :inline="true" :model="updateSparePartAccountForm" label-width="150px"
                        class="mg_top_20 flex flex_wrap pdl30 pdr30">
                        <el-form-item label="设备编号：">
                            <el-input v-model="updateSparePartAccountForm.equipmentID" placeholder="" class="width_180"
                                :readonly="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备名称：">
                            <el-input v-model="updateSparePartAccountForm.equipmentName" placeholder=""
                                class="width_180" :readonly="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备件编号：">
                            <el-input v-model="updateSparePartAccountForm.sparePartID" placeholder="" class="width_180"
                                :readonly="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备件名称：">
                            <el-input v-model="updateSparePartAccountForm.sparePartName" placeholder=""
                                class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备件类型：">
                            <el-select v-model="updateSparePartAccountForm.sparePartType" placeholder=""
                                class="width_180">
                                <el-option v-for="item in sparePartTypeArray" :key="item.value"
                                    :label="item.description" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备件型号：">
                            <el-input v-model="updateSparePartAccountForm.sparePartModel" placeholder=""
                                class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="生产厂商：">
                            <el-input v-model="updateSparePartAccountForm.manufacturer" placeholder=""
                                class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="供应商：">
                            <el-input v-model="updateSparePartAccountForm.supplier" placeholder="" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="更换周期：">
                            <el-input v-model="updateSparePartAccountForm.replaceCycle" placeholder="" class="width_180"
                                type="number" oninput="if(value<0){value=0}">
                            </el-input>
                            <!-- <el-select v-model="updateSparePartAccountForm.replaceCycle" placeholder=""
                                class="width_180">
                                <el-option v-for="item in sparePartStockReplaceCycleUnitArray" :key="item.value"
                                    :label="item.description" :value="item.value"></el-option>
                            </el-select> -->
                        </el-form-item>
                        <el-form-item label="更换周期单位：">
                            <!-- sparePartStockReplaceCycleUnitArray -->
                            <!-- <el-input v-model="updateSparePartAccountForm.replaceCycleUnit" placeholder=""
                                class="width_180" type="number">
                            </el-input> -->
                            <el-select v-model="updateSparePartAccountForm.replaceCycleUnit" placeholder=""
                                class="width_180">
                                <el-option v-for="item in sparePartStockReplaceCycleUnitArray" :key="item.value"
                                    :label="item.description" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="参考价(元)：">
                            <el-input v-model="updateSparePartAccountForm.referPrice" placeholder="" class="width_180"
                                type="number" oninput="if(value<0){value=0}">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备件单位：">
                            <!-- <el-input v-model="updateSparePartAccountForm.unit" placeholder="" class="width_180">
                            </el-input> -->
                            <el-select v-model="updateSparePartAccountForm.unit" placeholder="" class="width_180">
                                <el-option v-for="item in sparePartUnitArray" :key="item.value"
                                    :label="item.description" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="库存下限：">
                            <el-input v-model="updateSparePartAccountForm.stockMin" placeholder="" class="width_180"
                                type="number" oninput="if(value<0){value=0}">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="库存上限：">
                            <el-input v-model="updateSparePartAccountForm.stockMax" placeholder="" class="width_180"
                                type="number" oninput="if(value<0){value=0}">
                            </el-input>
                        </el-form-item>

                        <!-- <el-form-item label="库存数量：">
                        <el-input v-model="updateSparePartAccountForm.stockQuan" placeholder="" class="width_180" type="number">
                        </el-input>
                    </el-form-item> -->
                        <!-- <el-form-item label="是否阈值报警：">
                            <el-select v-model="updateSparePartAccountForm.isThresholdWarn" placeholder=""
                                class="width_180">
                                <el-option v-for="item in thresholdWarn" :key="item.value" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="备注：">
                            <el-input v-model="updateSparePartAccountForm.remarks" placeholder="" class="width_180">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <div>
                        <el-button type="primary" class="width_140" @click="sbumitUpdate">确定</el-button>
                    </div>
                    <el-button type="primary" class="width_140 mg_left_50" @click="colseUpdatePage">关闭</el-button>
                </div>
            </el-dialog>


        </div>
    </div>
</template>

<script>
    import {
        sparePartStockQueryLimit,
        sparePartTypeQuery,
        sparePartUnitQuery,
        sparePartStockInsert,
        sparePartStockUpdate,
        sparePartStockDelete,
        sparePartStockReplaceCycleUnitQuery,
        productionInforEquipmentSelect, //设备级联选择下拉框 接口
    } from "@/api/api_deviceManagement";
    export default {
        data() {
            return {
                // tabIndex: 0,
                // value: '',
                // input: '',
                // options: [],
                sparePartStockTableData: [],
                multipleSelection: [],
                userID: '',
                sparePartTypeArray: [],
                sparePartType: '',
                sparePartName: '',
                sparePartUnitArray: [],
                addSparePartAccount: false, // 添加备件弹框
                addSparePartAccountForm: {},
                thresholdWarn: [{
                    name: '是',
                    value: true
                }, {
                    name: '否',
                    value: false
                }],
                updateSparePartAccountFlag: false,
                updateSparePartAccountForm: {},
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                sparePartStockReplaceCycleUnitArray: [],
                equipmentList: [], //选择设备-级联下拉框 所用的数组
                permission: {}, //权限
                defaultProps: {
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    emitPath: false
                },
                addEquipmentName: '', //
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            if (localStorage.getItem('permission') != null) {
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.sparePartStockQueryLimit();
            this.sparePartTypeQuery();
            this.sparePartUnitQuery();
            this.sparePartStockReplaceCycleUnitQuery();
            this.productionInforEquipmentSelect();
        },
        methods: {
//             check_num (){
//          let num = this.num;
//             num = num.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
//             if (num.indexOf('.') < 0 && num != '') {
//         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
//         num = parseInt(num);
//         }
//         this.num = num;
// },
      

              //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '备件表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            //
            getEquipmentDetail() {
                const obj = this.$refs['demoCascader'].getCheckedNodes();

                if (obj.length != 0) {
                    //console.log('这是选中的叶子')
                    //console.log(obj[0].label)
                    this.addEquipmentName = obj[0].label;
                }
                //console.log(obj[0].label)
            },
            // 选择设备级联下拉框
            async productionInforEquipmentSelect() {

                //封装参数
                var param = {
                    permission: this.permission,
                };

                //调用接口
                const res = await productionInforEquipmentSelect(param);

                //处理返回值
                if (res) { //如果有返回值
                    this.equipmentList = res.data.Tree;
                    console.log(this.equipmentList)
                }
            },

            // 备件类型下拉选
            async sparePartTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await sparePartTypeQuery(param);
                this.sparePartTypeArray = res.data;
            },
            // 备件单位下拉选
            async sparePartUnitQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await sparePartUnitQuery(param);
                this.sparePartUnitArray = res.data;
            },
            // 备件单位更换周期下拉选
            async sparePartStockReplaceCycleUnitQuery() {
                var param = {}
                const res = await sparePartStockReplaceCycleUnitQuery(param);
                this.sparePartStockReplaceCycleUnitArray = res.array;
            },
            // 备件台账-筛选分页数据
            async sparePartStockQueryLimit() {

                /*                 if ((this.sparePartType != '') || (this.sparePartName != '')) {
                                    this.currentPage = 1;
                                } */
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    userID:this.userID
                }
                if (this.sparePartType != '') {
                    param.sparePartType = this.sparePartType
                }
                if (this.sparePartName != '') {
                    param.sparePartName = this.sparePartName
                }


                const res = await sparePartStockQueryLimit(param);
                this.sparePartStockTableData = res.rows;
                this.pageQuantity = res.pageQuantity
            },
            // 备件台账 新增
            async sparePartStockInsert() {
                this.addSparePartAccountForm.userID = this.userID;
                this.addSparePartAccountForm.equipmentName = this.addEquipmentName;

                const res = await sparePartStockInsert(this.addSparePartAccountForm);
                if (res && res.data.result) {
                    this.addSparePartAccount = false;
                    this.addSparePartAccountForm = {}; // 新增成功后，清除表单信息
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.sparePartStockQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 备件台账 修改
            async sparePartStockUpdate() {
                console.log(this.updateSparePartAccountForm)
                var param = {
                    sparePartName: this.updateSparePartAccountForm.sparePartName,
                    sparePartType: this.updateSparePartAccountForm.sparePartType,
                    sparePartModel: this.updateSparePartAccountForm.sparePartModel,
                    manufacturer: this.updateSparePartAccountForm.manufacturer,
                    supplier: this.updateSparePartAccountForm.supplier,
                    stockQuan: this.updateSparePartAccountForm.stockQuan,
                    replaceCycle: this.updateSparePartAccountForm.replaceCycle,
                    replaceCycleUnit: this.updateSparePartAccountForm.replaceCycleUnit,
                    referPrice: this.updateSparePartAccountForm.referPrice,
                    unit: this.updateSparePartAccountForm.unit,
                    stockMax: this.updateSparePartAccountForm.stockMax,
                    stockMin: this.updateSparePartAccountForm.stockMin,
                    isThresholdWarn: this.updateSparePartAccountForm.isThresholdWarn,
                    remarks: this.updateSparePartAccountForm.remarks,
                    ID: this.updateSparePartAccountForm.ID,

                }
                const res = await sparePartStockUpdate(param);
                if (res && res.data.result) {
                    this.updateSparePartAccountFlag = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.updateSparePartAccountForm = {};
                    this.sparePartStockQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 备件台账 删除
            async sparePartStockDelete(ID) {
                console.log(ID)
                var param = {
                    ID: ID
                }
                const res = await sparePartStockDelete(param);
                if (res && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    this.sparePartStockQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 点击“删除”，弹框提示。若选择“确定”，调用“等级删除”接口
            deleteSparePartAccount(id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.sparePartStockTableData.length, this
                        .pageQuantity, this.currentPage);
                    this.sparePartStockDelete(id);
                }).catch(() => {});
            },
            // 备件新增页面点确定
            sbumit() {
                this.sparePartStockInsert();
            },
            // 备件新增页面点击关闭
            colsePage() {
                this.addSparePartAccount = false;
            },
            // 备件修改页面点确定
            sbumitUpdate() {
                this.sparePartStockUpdate();
            },
            // 备件修改页面点击关闭
            colseUpdatePage() {
                this.updateSparePartAccountFlag = false;
                this. sparePartStockQueryLimit();
            },
            onQueryLimit() {
                this.currentPage = 1;
                this.sparePartStockQueryLimit();
            },
            // handleSelectionChange(val) {
            //     this.multipleSelection = val;
            // },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.sparePartStockQueryLimit();
            },
            // handleChange(id) {
            //     this.tabIndex = id;
            // },
            // 添加备件
            gotoFaultDetails() {
                this.addSparePartAccountForm = {};
                this.addSparePartAccount = true;
                // this.$router.push({
                //     path: '/deviceManagement/sparePartManagement/sparePartAccount/addSparePartAccount',
                // })
            },
            // 添加入库
            // gotoFaultDetails2() {
            //     this.$router.push({
            //         path: '/deviceManagement/sparePartManagement/sparePartAccount/addSparePartAccountInStorage',
            //     })
            // },
            // 添加出库
            // gotoFaultDetails3() {
            //     this.$router.push({
            //         path: '/deviceManagement/sparePartManagement/sparePartAccount/addSparePartAccountOutStorage',
            //     })
            // },
            // 详情
            gotoFaultDetails4(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartAccount/sparePartAccountDetails',
                    // query: {
                    //     data: data
                    // }
                })
            },
            // 备件台账修改
            updateSparePartAccountFunction(data) {
                console.log(data)
                this.updateSparePartAccountFlag = true;
                this.updateSparePartAccountForm = data;
            },
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>