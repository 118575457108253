// 维保配置-紧急程度配置
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>紧急程度配置</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <div>紧急程度分类选择：</div>
                    <el-select v-model="queryCondition" placeholder="请选择" class=" width_160" clearable>
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="mg_left_10" size="medium" style=" background: #009BFD;border: none;"
                        @click="CategoryQuery">查询
                    </el-button>
                </div>
            </div>
            <!-- mg_top_20 -->
            <div class="flex  pdl30 pdr30 pdt_20">
                <el-button type="primary" class="width_110" icon="el-icon-circle-plus-outline"
                    style=" background: #03CABE;border: none;" @click="add">新增
                </el-button>
               <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>  
            </div>
            <div class="pdl30 pdr30 hei80V">
                <el-table :data="queryList" style="width: 100%" class=" mg_top_20" height="100%"
                @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <!-- <el-table-column prop="ID" label="id" width="80">
                    </el-table-column> -->
                    <!-- <el-table-column prop="projectID" label="项目编号" width="180">
                    </el-table-column> -->
                    <el-table-column prop="urgenLevelSetting" label="紧急程度" width="180">
                    </el-table-column>
                    <el-table-column prop="urgenLevelCategory" label="紧急程度分类">
                    </el-table-column>
                    <el-table-column prop="remarks" label="描述">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="gotoModify(scope.row)">
                                修改
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="新增" :visible.sync="dialogFormVisible" width="30%" top="20vh">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="addform">
                    <!-- <el-form-item label="用户编号: " :label-width="formLabelWidth">
                        <el-input v-model="addform.userID" autocomplete="off" class=""></el-input>
                    </el-form-item> -->
                    <el-form-item label="紧急程度: " :label-width="formLabelWidth">
                        <el-input v-model="addform.urgenLevelSetting" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    <el-form-item label="紧急程度分类：" :label-width="formLabelWidth">
                        <el-select v-model="addform.urgenLevelCategory" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in options" :key="item.value" :label="item.description"
                                :value="item.description">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述: " :label-width="formLabelWidth">
                        <el-input v-model="addform.remarks" type="textarea" :rows="3" autocomplete="off" class="">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="trueadd">确 定</el-button>
            </div>

            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div> -->
        </el-dialog>

        <!--修改小窗-->
        <el-dialog title="修改" :visible.sync="dialog2FormVisible" width="30%" top="20vh">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">修改</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="updateform">
                    <el-form-item label="紧急程度: " :label-width="formLabelWidth">
                        <el-input v-model="updateform.urgenLevelSetting" @keyup.native="number" autocomplete="off"
                            class=""></el-input>
                    </el-form-item>
                    <!--
                    <el-form-item label="评分等级分类：" :label-width="formLabelWidth">
                        <el-input v-model="form.gradeSettingsCategory" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    -->
                    <el-form-item label="紧急程度分类: " :label-width="formLabelWidth">
                        <el-select v-model="updateform.urgenLevelCategory" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in options" :key="item.value" :label="item.description"
                                :value="item.description">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述：" :label-width="formLabelWidth">
                        <el-input v-model="updateform.remarks" type="textarea" :rows="3" resize="none" autocomplete="off" class="">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialog2FormVisible = false">取 消</el-button>
                <el-button type="primary" @click="trueModify">确 定</el-button>
            </div>

            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div> -->
        </el-dialog>

    </div>
</template>
<script>
    import {
        rMIUrgenLevelSettingsInsert,
        rMIUrgenLevelSettingsDelete,
        rMIUrgenLevelSettingsUpdate,
        rMIUrgenLevelSettingsQuery,
        rMIUrgenLevelSettingsQueryLimit,
        rMIUrgenLevelCategoryQuery
    } from "@/api/api_systemConfig";
    export default {
        data() {
            return {
                value: '',
                options: [],
                queryList: [],
                dialogFormVisible: false,
                dialog2FormVisible: false,
                addform: {
                    userID: '',
                    urgenLevelSetting: '',
                    urgenLevelCategory: '',
                    remarks: ''
                },
                updateform: {
                    ID: '',
                    urgenLevelSetting: '',
                    urgenLevelCategory: '',
                    remarks: ''
                },
                formLabelWidth: '130px',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                queryCondition: '',
                deleteNum: '',
                userID: ''
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            };
            this.rMIUrgenLevelSettingsQueryLimit();
            this.rMIUrgenLevelCategoryQuery();
        },
        methods: {
             //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '紧急程度配置表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            //（1）新增
            //点击‘新增’按钮的方法
            add() {
                //将原有数据清空，以备用来新增 紧急程度
                this.addform = {};
                //打开新增页面dialog
                this.dialogFormVisible = true;
                //调用 紧急程度-分类筛选接口，初始化 紧急程度分类 下拉框
                this.rMIUrgenLevelCategoryQuery();
            },
            //点击dialog里‘确定’按钮的方法
            trueadd() {
                //调用 紧急程度-新增 接口，实现新增
                this.rMIUrgenLevelSettingsInsert();
            },
            async rMIUrgenLevelSettingsInsert() {
                //封装参数
                this.addform.userID = this.userID
                var param = {
                    userID: this.addform.userID,
                    urgenLevelSetting: this.addform.urgenLevelSetting,
                    urgenLevelCategory: this.addform.urgenLevelCategory,
                    remarks: this.addform.remarks
                }
                console.log(param)

                //调用 紧急程度-新增 接口
                const res = await rMIUrgenLevelSettingsInsert(param);
                console.log(res)

                if (res && res.data && res.data.result) {
                    this.dialogFormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    //插入后 调用 紧急程度筛选-分页数据 刷新页面
                    this.rMIUrgenLevelSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }

            },
            //（2）调用 紧急程度分类筛选接口，初始化 紧急程度分类 下拉框
            async rMIUrgenLevelCategoryQuery() {
                var param = {};
                const res = await rMIUrgenLevelCategoryQuery(param);
                console.log(res);
                this.options = res.array;
            },

            //（3）紧急程度筛选-分页数据
            async rMIUrgenLevelSettingsQueryLimit() {
                var param = {
                    userID: this.userID,
                    // urgenLevelCategory: this.queryCondition,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage
                }
                if (this.queryCondition != null && this.queryCondition != "") {
                    param.urgenLevelCategory = this.queryCondition;
                }
                const res = await rMIUrgenLevelSettingsQueryLimit(param);
                this.queryList = res.rows;
                this.pageQuantity = res.pageQuantity;


            },
            //（4）修改
            gotoModify(temp) {
                this.updateform.ID = temp.ID;
                this.updateform.urgenLevelSetting = temp.urgenLevelSetting;
                this.updateform.urgenLevelCategory = temp.urgenLevelCategory;
                this.updateform.remarks = temp.remarks;

                this.dialog2FormVisible = true;
                this.rMIUrgenLevelCategoryQuery();
            },
            trueModify() {
                this.rMIUrgenLevelSettingsUpdate();
            },
            async rMIUrgenLevelSettingsUpdate() {
                var param = {
                    ID: this.updateform.ID,
                    urgenLevelSetting: this.updateform.urgenLevelSetting,
                    urgenLevelCategory: this.updateform.urgenLevelCategory,
                    remarks: this.updateform.remarks
                }

                const res = await rMIUrgenLevelSettingsUpdate(param);

                if (res && res.data && res.data.result) {
                    this.dialog2FormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMIUrgenLevelSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //（5）删除
            delTableData(id) {
                this.deleteNum = id;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用删除接口
                    this.currentPage = this.delData.delData(this.queryList.length, this.pageQuantity,this.currentPage)
                    this.rMIUrgenLevelSettingsDelete();
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            //调用删除接口，实现删除
            async rMIUrgenLevelSettingsDelete() {
                var param = {
                    ID: this.deleteNum
                }
                console.log(param)
                const res = await rMIUrgenLevelSettingsDelete(param);
                console.log(res)
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMIUrgenLevelSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //（6）点击页码触发分页查询
            handleCurrentChange(val) {
                this.currentPage = val;
                //调用分页查询
                this.rMIUrgenLevelSettingsQueryLimit();
            },
            //（7）
            CategoryQuery() {
                this.currentPage = 1;
                this.rMIUrgenLevelSettingsQueryLimit();
            }
        }
    }
</script>
<style scoped>
    .hei80V {
        height: 78%;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 14px
    }
</style>