import { render, staticRenderFns } from "./KPIAlgorithmConfiguration.vue?vue&type=template&id=74e22341&scoped=true&"
import script from "./KPIAlgorithmConfiguration.vue?vue&type=script&lang=js&"
export * from "./KPIAlgorithmConfiguration.vue?vue&type=script&lang=js&"
import style0 from "./KPIAlgorithmConfiguration.vue?vue&type=style&index=0&id=74e22341&scoped=true&lang=css&"
import style1 from "./KPIAlgorithmConfiguration.vue?vue&type=style&index=1&id=74e22341&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e22341",
  null
  
)

export default component.exports