<!-- 设备管理——数据库驾驶舱——焊接站 -->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="pdt_20 pdb_10 flex justify_between align_center">
                <div class="fn_size22 color_00C6FF">
                    <span>接入设备：</span>
                    <span class="color_00FF00">{{equiStatusRobot}} </span>
                </div>
                <div class="topTitle fn_size28 color_FFFFFF">
                    {{equiDashBoardRobotGetTitle}}
                </div>
                <div class="fn_size22 color_00C6FF flex align_center ">
                    {{currentTime}}
                    <img src="../../../assets/images/comprehensive/full.png" class="mg_left_20" alt="">
                </div>
            </div>
            <!-- <div>

            </div> -->
            <el-row :gutter="5">
                <!-- 第1列 -->
                <el-col :span="6">
                    <el-row>
                        <el-col class="diy_col5">
                            <div class="diy_div">
                                <div class="">工件信息</div>
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size12" v-for="item in equiWorkpieceinforRobot"
                                        :key="item.name" :label="item.name" :value="item.value">
                                        <el-col :span="7">
                                            <div class="pdl40 flex">{{item.name}}</div>
                                        </el-col>
                                        <el-col :span="17">
                                            <div class="pdl40 flex">{{item.value}}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col6">
                            <div class="diy_div3">
                                <div class="">程序信息</div>
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size12" v-for="item in equiProgaminforRobot"
                                        :key="item.name" :label="item.name" :value="item.value">
                                        <el-col :span="7">
                                            <div class="pdl40 flex">{{item.name}}</div>
                                        </el-col>
                                        <el-col :span="17">
                                            <div class="pdl40 flex">{{item.value}}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div2">
                                <div class="">运行记录</div>
                                <div class="element">
                                    <el-row>
                                        <el-col :span="6">序号</el-col>
                                        <el-col :span="6">描述</el-col>
                                        <el-col :span="6">开始时间</el-col>
                                        <el-col :span="6">时长（分）</el-col>
                                    </el-row>
                                    <el-row class="myRow" v-for="(item,index) in equiRunRecordRobot" :key="index"
                                        :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                        <el-col :span="6">{{index+1}}</el-col>
                                        <el-col :span="6">{{item.desc}}</el-col>
                                        <el-col :span="6">{{item.time}}</el-col>
                                        <el-col :span="6">{{item.timeLength}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col4">
                            <div class="diy_div4">
                                <div class="">坐标数据</div>
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size12" v-for="(item,index) in equiCoordinateDataRobot"
                                        :key="item.name" :label="item.name" :value="item.value">
                                        <el-col :span="7">
                                            <div class="pdl40 flex">{{item.name}}</div>
                                        </el-col>
                                        <el-col :span="17">
                                            <div class="pdl40 flex">{{item.value}}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- 第2列 -->
                <el-col :span="12">
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div5">
                                <div class="">关键指标</div>
                                <div class="flex justify_end" style="height: 100%;width: 100%;">
                                    <div class="flex justify_end  " style="height: 100%;width: 110%;">
                                        <div id="ecartsGJZB1" style="width:190px;height: 190px;" class=""></div>
                                        <div id="ecartsGJZB2" style="width:230px;height: 230px;" class=""></div>
                                        <div id="ecartsGJZB3" style="width:190px;height: 190px;" class=""></div>
                                    </div>
                                    <div class="flex justify_end mg_right_30" style="width: 20%;">
                                        <div class=" flex flex_column align_start color_00C6FF ">
                                            <div class="  ">
                                                <span>{{dataCN.name}}</span>
                                                <span>：</span>
                                                <span>{{dataCN.value}}</span></div>
                                            <div class="  ">
                                                <span>{{dataDN.name}}</span>
                                                <span>：</span>
                                                <span>{{dataDN.value}}</span>
                                            </div>
                                            <div class="  ">
                                                <span>{{dataQT.name}}</span>
                                                <span>：</span>
                                                <span>{{dataQT.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col3">
                            <div class="diy_div6">
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size14">
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataHJSC.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataHJSC.value}}</span>
                                            <span class="color_00C6FF">分钟</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataXWSC.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataXWSC.value}}</span>
                                            <span class="color_00C6FF">分钟</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataQTCZ.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataQTCZ.value}}</span>
                                            <span class="color_00C6FF">分钟</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataDJSC.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataDJSC.value}}</span>
                                            <span class="color_00C6FF">分钟</span>
                                        </el-col>
                                    </el-row>
                                    <el-row class="pdt_10 fn_size12">
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataHJDY.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataHJDY.value}}</span>
                                            <span class="color_00C6FF">V</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataHJDL.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataHJDL.value}}</span>
                                            <span class="color_00C6FF">A</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataSSSD.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataSSSD.value}}</span>
                                            <span class="color_00C6FF">米/分</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataQTLL.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataQTLL.value}}</span>
                                            <span class="color_00C6FF">L/H</span>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col7">
                            <div class="diy_div5">
                                <div class="">焊接电流/焊接电压/送丝速度/气体流量</div>
                                <div id="ecartsZZFZ" style="width:100%;height: 100%;"></div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col5">
                            <div class="diy_div7">
                                <div class="">设备状态时间片</div>
                                <div id="ecartsSJP" style="width:100%;height: 100%;"></div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- 第3列 -->
                <el-col :span="6">
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div2">
                                <div class="">设备信息</div>
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size12" v-for="item in equiInformationRobot" :key="item.name"
                                    :label="item.name" :value="item.value">
                                        <el-col :span="7">
                                            <div class="pdl40 flex">{{item.name}}</div>
                                        </el-col>
                                        <el-col :span="17">
                                            <div class="pdl40 flex">{{item.value}}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div2">
                                <div class="">工艺数据</div>
                                <div class="element">
                                    <el-row class="myRow" v-for="(item,index) in equiCraftDataRobot" :key="index"
                                        :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                        <el-col :span="8">{{index+1}}</el-col>
                                        <el-col :span="8">{{item.name}}</el-col>
                                        <el-col :span="8">{{item.value}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div2">
                                <div class="">报警记录</div>
                                <div class="element">
                                    <el-row>
                                        <el-col :span="4">序号</el-col>
                                        <el-col :span="4">代码</el-col>
                                        <el-col :span="7">时间</el-col>
                                        <el-col :span="4">代码</el-col>
                                        <el-col :span="4">时长</el-col>
                                    </el-row>
                                    <el-row class="myRow" v-for="(item,index) in equiAralmDataRobot" :key="index"
                                        :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                        <el-col :span="4">{{index+1}}</el-col>
                                        <el-col :span="4">{{item.code}}</el-col>
                                        <el-col :span="7">{{item.time}}</el-col>
                                        <el-col :span="4" class="col_overflow">{{item.value}}</el-col>
                                        <el-col :span="4">{{item.timeLength}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

        </div>
    </div>

</template>
<script>
    // import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';
    import {
        equipmentDashBoardRobotGetTitle, //获取看板标题
        organizationSelectRobot, //组织架构选择
        equipmentStatusRobot, //设备状态
        equipmentWorkpieceinforRobot, //工件信息
        equipmentProgaminforRobot, //程序信息
        equipmentRunRecordRobot, //运行记录
        equipmentCoordinateDataRobot, //坐标数据
        equipmentDashBoardTimeSliceRobot, //设备状态时间片
        equipmentKPIRobot, //关键指标
        equipmentPropertyTrendRobot, //数据趋势
        equipmentInformationRobot, //设备信息
        equipmentCraftDataRobot, //工艺数据
        equipmentAralmDataRobot, //报警记录
    } from "@/api/api_deviceManagement";
    export default {
        // components: {
        //     innerTopNav
        // },
        data() {
            return {
                currentTime: new Date(),
                equiDashBoardRobotGetTitle: '', //获取看板标题
                equiStatusRobot: '', //设备状态
                equiWorkpieceinforRobot: [], //工件信息
                equiProgaminforRobot: [], //程序信息
                equiRunRecordRobot: [], //运行记录
                equiCoordinateDataRobot: [], //坐标数据
                equiInformationRobot: [], //设备信息
                equiCraftDataRobot: [], //工艺数据
                equiAralmDataRobot: [], //报警记录
                dataGJSJ: [],//关键数据
                dataCN: [],//关键数据-产能
                dataDN: [],//关键数据-电能
                dataQT: [],//关键数据-电气
                dataHJSC: [],//关键数据-焊接时长
                dataXWSC: [],//关键数据-寻位时长
                dataQTCZ: [],//关键数据-其他操作
                dataDJSC: [],//关键数据-待机时长
                dataHJDY: [],//关键数据-焊接电压
                dataHJDL: [],//关键数据-焊接电流
                dataSSSD: [],//关键数据-送丝速度
                dataQTLL: [],//关键数据-气体流量
                equiPropertyTrendRobotX: [],//数据趋势-X轴数据
                equiPropertyTrendRobotDL: [],//数据趋势-焊接电流
                equiPropertyTrendRobotDY: [],//数据趋势-焊接电压
                equiPropertyTrendRobotSD: [],//数据趋势-送丝速度
                equiPropertyTrendRobotLL: [],//数据趋势-气体流量
                permission: {},
                sliceTime: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()], //时间片-时间戳
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss')
            this.drawShap();
            this.equipmentDashBoardRobotGetTitle(); //获取看板标题
            this.organizationSelectRobot(); //组织架构选择
            this.equipmentStatusRobot(); //设备状态
            this.equipmentWorkpieceinforRobot(); //工件信息
            this.equipmentProgaminforRobot(); //程序信息
            this.equipmentRunRecordRobot(); //运行记录
            this.equipmentCoordinateDataRobot(); //坐标数据
            this.equipmentKPIRobot(); //关键指标
            this.equipmentPropertyTrendRobot(); //数据趋势
            this.equipmentDashBoardTimeSliceRobot(); //设备状态时间片
            this.equipmentInformationRobot(); //设备信息
            this.equipmentCraftDataRobot(); //工艺数据
            this.equipmentAralmDataRobot(); //报警记录
        },
        methods: {
            //接口-开始-------------------
            //1、获取看板标题
            async equipmentDashBoardRobotGetTitle() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentDashBoardRobotGetTitle(param);
                this.equiDashBoardRobotGetTitle = selectRes.data;
                // console.log(selectRes.data)
            },
            //2、组织架构选择
            async organizationSelectRobot() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await organizationSelectRobot(param);
                // this.equiDashBoardGetTitle = selectRes.data;
                // console.log(selectRes.data)
            },
            //3、设备状态
            async equipmentStatusRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentStatusRobot(param);
                this.equiStatusRobot = selectRes.data.value;
                // console.log(selectRes.data)
            },
            //4、工件信息
            async equipmentWorkpieceinforRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentWorkpieceinforRobot(param);
                this.equiWorkpieceinforRobot = selectRes.data;
                // console.log(selectRes.data)
            },
            //5、程序信息
            async equipmentProgaminforRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentProgaminforRobot(param);
                this.equiProgaminforRobot = selectRes.data;
                // console.log(selectRes.data)
            },
            //6、运行记录
            async equipmentRunRecordRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentRunRecordRobot(param);
                this.equiRunRecordRobot = selectRes.data;
                // console.log(selectRes.data)
            },
            //7、坐标数据
            async equipmentCoordinateDataRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentCoordinateDataRobot(param);
                this.equiCoordinateDataRobot = selectRes.data;
                // console.log(selectRes.data)
            },
            //8、关键数据
            async equipmentKPIRobot() {
                var param = {
                    equipmentID: 100001,
                    typeKpi: 0,
                    dateType: 1,
                }
                const selectRes = await equipmentKPIRobot(param);

                for (var i = 0; i < 3; i++) {
                    var temp = {}
                    temp.name = selectRes.data[i].name;
                    temp.value = selectRes.data[i].value;
                    this.dataGJSJ.push(temp)
                }
                this.dataHJSC = selectRes.data[3];//焊接时长
                this.dataXWSC = selectRes.data[4];//寻位时长
                this.dataQTCZ = selectRes.data[5];//其他操作
                this.dataDJSC = selectRes.data[6];//待机时长
                this.dataHJDY = selectRes.data[7];//焊接电压
                this.dataHJDL = selectRes.data[8];//焊接电流
                this.dataSSSD = selectRes.data[9];//送丝速度
                this.dataQTLL = selectRes.data[10];//气体流量
                this.dataCN = selectRes.data[11];//产能
                this.dataDN = selectRes.data[12];//电能
                this.dataQT = selectRes.data[13];//气体
                // console.log(data)
                this.drawKPI(this.dataGJSJ);
            },
            //9、设备状态时间片
            async equipmentDashBoardTimeSliceRobot() {
                var param = {
                    startDate: this.sliceTime[0],
                    endDate: this.sliceTime[1],
                    equipmentID: 100001,
                }
                const selectRes = await equipmentDashBoardTimeSliceRobot(param);

                var testData = selectRes.data;
                this.drawTimeSlice(testData);
            },
            //10、设备信息
            async equipmentInformationRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentInformationRobot(param);
                this.equiInformationRobot = selectRes.data;
                // console.log(selectRes.data)
            },
            //11、工艺数据
            async equipmentCraftDataRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentCraftDataRobot(param);
                this.equiCraftDataRobot = selectRes.data;
                // console.log(selectRes.data)
            },
            //12、报警记录
            async equipmentAralmDataRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentAralmDataRobot(param);
                this.equiAralmDataRobot = selectRes.data;
                // console.log(selectRes.data)
            },
            //13、数据趋势
            async equipmentPropertyTrendRobot() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentPropertyTrendRobot(param);
                this.equiPropertyTrendRobotX = selectRes.data[0].value;//x轴数据
                this.equiPropertyTrendRobotDL = selectRes.data[4].value;//焊接电流
                this.equiPropertyTrendRobotDY = selectRes.data[1].value;//焊接电压
                this.equiPropertyTrendRobotSD = selectRes.data[2].value;//送丝速度
                this.equiPropertyTrendRobotLL = selectRes.data[3].value;//气体流量
                // console.log(selectRes.data)
                this.drawShap(this.equiPropertyTrendRobotX,this.equiPropertyTrendRobotDL,this.equiPropertyTrendRobotDY,this.equiPropertyTrendRobotSD,this.equiPropertyTrendRobotLL);
            },
            //接口-结束-------------------

            //echarts
            drawShap(trendX,trendDL,trendDY,trendSD,trendLL) {

                //主轴负载
                this.ecartsZZFZ = echarts.init(document.getElementById('ecartsZZFZ'));
                this.ecartsZZFZ.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['焊接电流', '焊接电压', '送丝速度','气体流量'],
                        bottom: '5%',
                        textStyle: {
                            color: '#ffffff',
                            opacity: 0.5,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        data: trendX
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        interval: 10,
                        axisLabel: {
                            color: '#FFFFFF',
                            fontSize: 12,
                            // formatter: '{value} ml'
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    },
                    series: [{
                            name: '焊接电流',
                            type: 'line',
                            // stack: '总量',
                            color: '#00A8FF',
                            data: trendDL
                        },
                        {
                            name: '焊接电压',
                            type: 'line',
                            // stack: '总量',
                            color: '#00FF00',
                            data: trendDY
                        },
                        {
                            name: '送丝速度',
                            type: 'line',
                            // stack: '总量',
                            color: '#6C7BE8',
                            data: trendSD
                        },
                        {
                            name: '气体流量',
                            type: 'line',
                            // stack: '总量',
                            color: '#FDEC31',
                            data: trendLL
                        }
                    ]

                })



            },

            drawTimeSlice(data) {

                //设备状态时间片——开始-------------
                var that = this;
                var startTime = that.sliceTime[0];
                var categories = [''];

                function renderItem(params, api) {
                    // console.log(params, api)
                    // console.log(api.value(0), api.value(1), api.value(2))
                    var categoryIndex = api.value(0) //对应的三类中的一类
                    var start = api.coord([api.value(1), categoryIndex]) //起始点坐标
                    var end = api.coord([api.value(2), categoryIndex]) //结束点坐标
                    var height = api.size([0, 1])[1] * 0.9
                    // console.log(api.value(2) - api.value(1))
                    var rectShape = echarts.graphic.clipRectByRect({
                        x: start[0],
                        y: start[1] - height / 2,
                        width: end[0] - start[0],
                        height: height
                    }, {
                        x: params.coordSys.x,
                        y: params.coordSys.y,
                        width: params.coordSys.width,
                        height: params.coordSys.height
                    });

                    return rectShape && {
                        type: 'rect',
                        transition: ['shape'],
                        shape: rectShape,
                        style: api.style()
                    };
                }
                this.ecartsSJP = echarts.init(document.getElementById('ecartsSJP'));
                this.ecartsSJP.setOption({
                    tooltip: {
                        formatter: function (params) {
                            console.log(params)
                            // that.tools.formatDate(this.sliceTime[0], 'yyyy-MM-ddThh:mm:ss'),
                            return params.marker + params.name + ': ' + params.value[3] + ' s' + '<br/>' +
                                '开始时间：' + that.tools.formatDate(params.value[1], 'yyyy-MM-dd hh:mm:ss') +
                                '<br/>' + '结束时间：' + that.tools.formatDate(params.value[2],
                                    'yyyy-MM-dd hh:mm:ss')
                        },
                    },
                    grid: {
                        height: 50,
                        x: 50,
                        y: 25,
                        x2: 50,
                        y2: 35
                    },
                    // x轴会动态将日期格式化
                    xAxis: {
                        min: startTime,
                        scale: true,
                        axisLabel: {
                            formatter: function (val) {
                                console.log(val)
                                // console.log(startTime/1000, val/1000)
                                // 将日期进行整合处理
                                console.log(Math.max(0, val - startTime) / 1000)
                                // console.log(new Date(val))
                                // return val/1000 + ' s'

                                return Math.max(0, val - startTime) / 1000 + ' s'
                            },
                        },
                    },
                    yAxis: {
                        data: categories,
                    },
                    series: [{
                        type: 'custom',
                        // center: ['50%', '10%'],
                        renderItem: renderItem,
                        //指定x映射的是data数组中每一个元素中value的第几个值
                        //将值收集起来并且进行统一范围处理  比如上面的data中有30个值就会把30个值收集然后进行10s的一个间隔进行区间处理生成x轴
                        encode: {
                            x: [1, 2],
                            y: 0,
                        },
                        data: data,
                    }],
                })
                //设备状态时间片——结束--------------
            },

            drawKPI(data) {
                //关键指标1
                this.ecartsGJZB1 = echarts.init(document.getElementById('ecartsGJZB1'));
                this.ecartsGJZB1.setOption({
                    series: [{
                        type: 'gauge',
                        progress: {
                            show: true,
                            width: 12
                        },
                        grid: {

                        },
                        center: ['60%', '65%'],
                        axisLine: { // 坐标轴线，也就是圆圈的边框线
                            lineStyle: { // 属性lineStyle控制线条样式  
                                width: 12
                            },
                        },
                        axisTick: { // 坐标轴小标记
                            show: true, // 属性show控制显示与否，默认不显示
                            splitNumber: 5, // 每份split细分多少段
                            lineStyle: { // 属性lineStyle控制线条样式
                                color: '#ffffff',
                                width: 1,
                                type: 'solid'
                            },
                            distance: 0,
                        },
                        splitLine: {
                            length: 15,
                            lineStyle: {
                                width: 2,
                                color: '#ffffff'
                            },
                            distance: 0,
                        },
                        axisLabel: {
                            distance: 10,
                            color: '#ffffff',
                            fontSize: 12,
                            formatter: function (value) {
                                switch (value) {
                                    case 0:
                                        return 0 / 10; //首位刻度？其他都隐藏？反正记住return的是你所需要的label值

                                    case 100:
                                        return ''; //隐藏

                                    default:
                                        return value;

                                }

                            }
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 16,
                            itemStyle: {
                                borderWidth: 10,
                                borderColor: '#10DA4E'
                            },
                        },
                        pointer: {
                            icon: 'rect',
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 20,
                            offsetCenter: [0, '85%'],
                            textStyle: {
                                color: '#ffffff'
                            }
                        },
                        data: [{
                            value: data[0].value,
                            name: data[0].name,
                            title: {
                                show: true,
                                offsetCenter: [0, '40%'],
                                color: '#ffffff',
                                fontSize: 14,
                            }
                        }],
                        min: 0, // 配置仪表板的数值范围
                        max: 100,
                        splitNumber: 5,
                        //color:['仪表盘颜色1','仪表盘颜色2'],
                        color: ['#10DA4E'],
                    }]

                })

                //关键指标2
                this.ecartsGJZB2 = echarts.init(document.getElementById('ecartsGJZB2'));
                this.ecartsGJZB2.setOption({
                    series: [{
                        type: 'gauge',
                        progress: {
                            show: true,
                            width: 12
                        },
                        center: ['50%', '45%'],
                        axisLine: { // 坐标轴线，也就是圆圈的边框线
                            lineStyle: { // 属性lineStyle控制线条样式  
                                width: 12
                            },
                        },
                        axisTick: { // 坐标轴小标记
                            show: true, // 属性show控制显示与否，默认不显示
                            splitNumber: 5, // 每份split细分多少段
                            lineStyle: { // 属性lineStyle控制线条样式
                                color: '#ffffff',
                                width: 1,
                                type: 'solid'
                            },
                            distance: 0,
                        },
                        splitLine: {
                            length: 13,
                            lineStyle: {
                                width: 2,
                                color: '#ffffff'
                            },
                            distance: 0,
                        },
                        axisLabel: {
                            distance: 10,
                            color: '#ffffff',
                            fontSize: 12,
                            formatter: function (value) {
                                switch (value) {
                                    case 0:
                                        return 0 / 10; //首位刻度？其他都隐藏？反正记住return的是你所需要的label值

                                    case 100:
                                        return ''; //隐藏

                                    default:
                                        return value;

                                }

                            }
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 22,
                            itemStyle: {
                                borderWidth: 10,
                                borderColor: '#5B8FF9'
                            },
                        },
                        pointer: {
                            icon: 'rect',
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 20,
                            offsetCenter: [0, '85%'],
                            textStyle: {
                                color: '#ffffff'
                            }
                        },
                        data: [{
                            value: data[1].value,
                            name: data[1].name,
                            title: {
                                show: true,
                                offsetCenter: [0, '40%'],
                                color: '#ffffff',
                                fontSize: 14,
                            }
                        }],
                        min: 0, // 配置仪表板的数值范围
                        max: 100,
                        splitNumber: 5,
                        //color:['仪表盘颜色1','仪表盘颜色2'],
                        color: ['#5B8FF9'],
                    }]
                })


                //关键指标3
                this.ecartsGJZB3 = echarts.init(document.getElementById('ecartsGJZB3'));
                this.ecartsGJZB3.setOption({
                    series: [{
                        type: 'gauge',
                        progress: {
                            show: true,
                            width: 12
                        },
                        center: ['40%', '65%'],
                        axisLine: { // 坐标轴线，也就是圆圈的边框线
                            lineStyle: { // 属性lineStyle控制线条样式  
                                width: 12
                            },
                        },
                        axisTick: { // 坐标轴小标记
                            show: true, // 属性show控制显示与否，默认不显示
                            splitNumber: 5, // 每份split细分多少段
                            lineStyle: { // 属性lineStyle控制线条样式
                                color: '#ffffff',
                                width: 1,
                                type: 'solid'
                            },
                            distance: 0,
                        },
                        splitLine: {
                            length: 15,
                            lineStyle: {
                                width: 2,
                                color: '#ffffff'
                            },
                            distance: 0,
                        },
                        axisLabel: {
                            distance: 10,
                            color: '#ffffff',
                            fontSize: 12,
                            formatter: function (value) {
                                switch (value) {
                                    case 0:
                                        return 0 / 10; //首位刻度？其他都隐藏？反正记住return的是你所需要的label值

                                    case 100:
                                        return ''; //隐藏

                                    default:
                                        return value;

                                }

                            }
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 16,
                            itemStyle: {
                                borderWidth: 10,
                                borderColor: '#FDEC31'
                            },
                        },
                        pointer: {
                            icon: 'rect',
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 20,
                            offsetCenter: [0, '85%'],
                            textStyle: {
                                color: '#ffffff'
                            }
                        },
                        data: [{
                            value: data[2].value,
                            name: data[2].name,
                            title: {
                                show: true,
                                offsetCenter: [0, '40%'],
                                color: '#ffffff',
                                fontSize: 14,
                            }
                        }],
                        min: 0, // 配置仪表板的数值范围
                        max: 100,
                        splitNumber: 5,
                        //color:['仪表盘颜色1','仪表盘颜色2'],
                        color: ['#FDEC31'],
                    }]

                })
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },


        }

    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
    }

    .board_bgd {
        width: 100vw;
        height: 86vh;
        background-image: url('../../../assets/images/comprehensive/bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        padding: 0 20px;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        background-image: url('../../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 25vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col2 {
        height: 26vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col3 {
        height: 8vh;
        color: #FFFFFF;
        font-size: 14px;
        /* margin-bottom: 5px; */
    }

    .diy_col4 {
        height: 16vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col5 {
        height: 16vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 3px;
    }

    .diy_col6 {
        height: 18vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 3px;
    }

    .diy_col7 {
        height: 26vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_div {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box2.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div3 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .diy_div4 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box4.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .diy_div5 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .diy_div6 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/middle-box3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* padding-top: 8px; */
    }

    .diy_div7 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/middle-box4.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }


    .col_overflow {
        word-break: keep-all;
        /* 不换行 */
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 内容超出宽度时隐藏超出部分的内容 */
        text-overflow: ellipsis;
        /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
    }

    .text_span {
        font-size: 12px;
        padding-top: 10px;
    }


    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 78%;
        margin-top: 2px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 12px;
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    .element2 {
        width: 90%;
        height: 80%;
        /* margin-top: 2px; */
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        /* font-size: 12px; */
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    .element2::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    .element .el-row:first-child {
        background-color: #143765;
        /* border-top: 1px #ffffff solid; */
    }

    .element .el-row {
        /* border-bottom: 1px #ffffff solid;
    border-left: 1px #ffffff solid; */
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        /* border-right: 1px #ffffff solid; */
        padding: 10px 0px;
    }

    .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;
        /* color:#73f3ff; */
    }
</style>