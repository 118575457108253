<!-- 设备管理-预测性维护-->
<template>
    <!-- <div class="page_container">
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">设备台账</div>
        </div>
        <div class="flex flex_column bgc_FFFFFF pdl30 pdr30">
            <div>
                512323
            </div>
        </div>
    </div> -->
    <iframe ref="iframe" style="width:100%;height:98%;margin-top:10px;" src="http://47.104.99.213:8080/Thingworx/Mashups/TW.Analytics.ConsoleMashup?appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true">
    </iframe>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        
    } from '@/api/api_deviceManagement.js'
import { Table } from 'element-ui'
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return{
                List:[],
                Tabledata:[],
            }

        },
        created() {

        },
        mounted() {
         
        },

        methods: {
        },
    }
</script>

<style scoped>
   .box{
       width: 100%;
       height:500px;
   }
</style>
<style>
  
</style>