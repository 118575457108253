// 维保配置-评分等级配置
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>评分等级配置</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <div>评分等级分类选择：</div>
                    <el-select v-model="Category" placeholder="请选择" class=" width_160" clearable>
                        <el-option v-for="item in options" :key="item.value" :label="item.description" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class=" mg_left_10" size="medium" style="background: #009BFD;border: none;"
                        @click="CategoryQuery"> 查询
                    </el-button>
                </div>
            </div>
            <!-- mg_top_20 -->
            <div class="flex  pdl30 pdr30 pdt_20">
                <el-button type="primary" class="width_110 mg_left_10" icon="el-icon-circle-plus-outline"
                    style=" background: #03CABE;border: none;" @click="add">新增
                </el-button>
                <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
            </div>
            <div class="pdl30 pdr30 hei80V">
                <el-table :data="queryList" style="width: 100%" class=" mg_top_20" height="100%"
                 @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <!-- <el-table-column prop="ID" label="id" width="80">
                    </el-table-column> -->
                    <!-- <el-table-column prop="projectID" label="项目编号" width="180">
                    </el-table-column> -->
                    <el-table-column prop="value" label="评分等级" width="180">
                    </el-table-column>
                    <el-table-column prop="gradeSettingsCategory" label="评分等级分类" width="180">
                    </el-table-column>
                    <el-table-column prop="remarks" label="描述">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="gotoModify(scope.row)">
                                修改
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="新增" :visible.sync="dialogFormVisible" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="form">
                    <!-- <el-form-item label="用户编号：" :label-width="formLabelWidth">
                        <el-input v-model="form.userID" autocomplete="off" class=""></el-input>
                    </el-form-item> -->
                    <el-form-item label="评分等级：" :label-width="formLabelWidth">
                        <el-input v-model="form.gradeSettings" @keyup.native="number" autocomplete="off" class="">
                        </el-input>
                    </el-form-item>
                    <!--
                    <el-form-item label="评分等级分类：" :label-width="formLabelWidth">
                        <el-input v-model="form.gradeSettingsCategory" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    -->
                    <el-form-item label="评分等级分类：" :label-width="formLabelWidth">
                        <el-select v-model="form.gradeSettingsCategory" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in options" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述：" :label-width="formLabelWidth">
                        <el-input type="textarea" rows="3" v-model="form.remarks" autocomplete="off" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addTest">确 定</el-button>
            </div>

            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div> -->
        </el-dialog>

        <!--修改小窗-->
        <el-dialog title="修改" :visible.sync="dialog2FormVisible" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">修改</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="updateform">
                    <el-form-item label="评分等级：" :label-width="formLabelWidth">
                        <el-input v-model="updateform.gradeSettings" @keyup.native="number" autocomplete="off" class="">
                        </el-input>
                    </el-form-item>
                    <!--
                    <el-form-item label="评分等级分类：" :label-width="formLabelWidth">
                        <el-input v-model="form.gradeSettingsCategory" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    -->
                    <el-form-item label="评分等级分类：" :label-width="formLabelWidth">
                        <el-select v-model="updateform.gradeSettingsCategory" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in options" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述：" :label-width="formLabelWidth">
                        <el-input type="textarea" rows="3" v-model="updateform.remarks" autocomplete="off" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialog2FormVisible = false">取 消</el-button>
                <el-button type="primary" @click="trueModify">确 定</el-button>
            </div>

            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div> -->
        </el-dialog>

    </div>
</template>
<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        rMIGradeSettingsInsert,
        rMIGradeSettingsQuery,
        rMIGradeSettingsDelete,
        rMIGradeCategoryQuery,
        rMIGradeSettingsQueryLimit,
        rMIGradeSettingsUpdate
    } from "@/api/api_systemConfig";
    export default {
        data() {
            return {
                value: '',
                options: [],
                dialogFormVisible: false,
                dialog2FormVisible: false,
                //评分等级新增form
                form: {
                    userID: '',
                    gradeSettings: 0,
                    gradeSettingsCategory: '',
                    remarks: ''
                },
                updateid: '',
                updateform: {
                    id: '',
                    gradeSettings: '',
                    gradeSettingsCategory: '',
                    remarks: ''
                },
                queryList: [],
                //rMIGradeCategoryQuery:[],
                deleteNum: '',
                formLabelWidth: '150px',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                Category: '',
                userID: ''
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            };
            this.rMIGradeSettingsQueryLimit();
            this.rMIGradeCategoryQuery();
        },
        methods: {
//导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '评分等级配置表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },

            //新增
            //点击‘新增’按钮的方法
            add() {
                //将原有数据清空，以备用来新增评分等级
                this.form = {};
                //打开新增页面dialog
                this.dialogFormVisible = true;

                this.rMIGradeCategoryQuery();
            },
            //点击dialog里‘确定’按钮的方法
            addTest() {
                this.rMIGradeSettingsInsert();
                // this.form = {};
            },
            async rMIGradeSettingsInsert() {
                //封装参数
                var param = {
                    userID: this.userID,
                    gradeSettings: this.form.gradeSettings,
                    gradeSettingsCategory: this.form.gradeSettingsCategory,
                    remarks: this.form.remarks
                }
                console.log(param)
                //调用接口
                const res = await rMIGradeSettingsInsert(param);
                console.log(res)
                if (res && res.data && res.data.result) {
                    this.dialogFormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    // this.rMIGradeSettingsQuery();
                    this.rMIGradeSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }

            },
            async rMIGradeSettingsQuery() {
                //封装参数
                var param = {
                    userID: this.userID
                }

                const res = await rMIGradeSettingsQuery(param);
                console.log(res);

                this.queryList = res.rows;
            },
            //修改
            gotoModify(temp) {
                this.updateid = temp.ID;
                this.updateform.gradeSettings = temp.value;
                this.updateform.gradeSettingsCategory = temp.gradeSettingsCategory;
                this.updateform.remarks = temp.remarks;

                this.dialog2FormVisible = true;
                this.rMIGradeCategoryQuery();
            },
            async trueModify() {
                var param = {
                    ID: this.updateid,
                    gradeSettings: this.updateform.gradeSettings,
                    gradeSettingsCategory: this.updateform.gradeSettingsCategory,
                    remarks: this.updateform.remarks
                }
                //console.log(param);
                const res = await rMIGradeSettingsUpdate(param);
                //console.log(res);
                if (res && res.data && res.data.result) {
                    this.dialog2FormVisible = false;
                    this.dialogFormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMIGradeSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //删除
            async rMIGradeSettingsDelete() {
                var param = {
                    ID: this.deleteNum
                }
                console.log(param);
                const res = await rMIGradeSettingsDelete(param);
                console.log(res);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMIGradeSettingsQueryLimit();
                    // this.rMIGradeSettingsQuery();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            delTableData(id) {
                this.deleteNum = id;
                // this.rMIGradeSettingsDelete();
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.queryList.length, this.pageQuantity,this.currentPage)
                    this.rMIGradeSettingsDelete();
                }).catch(() => {

                });
            },
            //评分等级分类 筛选
            async rMIGradeCategoryQuery() {
                var param = {};
                const res = await rMIGradeCategoryQuery(param);
                console.log(res);
                this.options = res.array;
                //console.log(rMIGradeCategoryQueryList);
            },
            //评分等级筛选 分页数据
            async rMIGradeSettingsQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (this.Category != null && this.Category != "") {
                    param.gradeSettingsCategory = this.Category;
                }
                const res = await rMIGradeSettingsQueryLimit(param);
                this.queryList = res.rows;
                this.pageQuantity = res.pageQuantity;
            },
            handleCurrentChange(val) {
                // this.PageNumber = val;
                // this.selectOnDuty(); //查询table数据的方法
                this.currentPage = val;
                this.rMIGradeSettingsQueryLimit();
                // if (this.Category != '' ) {
                //     this.rMIGradeSettingsQueryLimit2();
                // } else {

                // }
            },
            number() {
                this.form.gradeSettings = this.form.gradeSettings.replace(/[^\.\d]/g, '');
                this.form.gradeSettings = this.form.gradeSettings.replace('.', '');
            },
            CategoryQuery() {
                this.currentPage = 1;
                this.rMIGradeSettingsQueryLimit();
            },
            // async rMIGradeSettingsQueryLimit2() {
            //     var param = {
            //         userID: this.userID,
            //         pageSize: this.pageSize,
            //         pageNumber: this.currentPage,
            //         gradeSettingsCategory: this.Category
            //     }
            //     const res = await rMIGradeSettingsQueryLimit(param);
            //     this.queryList = res.rows;
            //     this.pageQuantity = res.pageQuantity;
            // }
        }
    }
</script>
<style scoped>
    .hei80V {
        height: 78%;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 14px
    }
</style>