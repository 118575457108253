<!-- 分工厂长-综合看板 -->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="pdt_20 pdb_10 flex justify_between align_center">
                <div class="fn_size22 color_00C6FF">
                    <span>接入设备：</span>
                    <span class="color_F6FF00">69 </span>
                    <span>台</span>
                </div>
                <div class="topTitle fn_size28 color_FFFFFF">
                    {{this.boardTitle}}
                </div>
                <div class="fn_size22 color_00C6FF flex align_center ">
                    {{currentTime}}
                    <img src="../../assets/images/comprehensive/full.png" class="mg_left_20" alt="">
                </div>
            </div>

            <el-row :gutter="5">
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">工序产能</div>
                        <div id="ecartsGX" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div2">
                        <div class="">节拍分析</div>
                        <div id="ecartsJP" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">待机分析</div>
                        <div id="ecartsDJ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">开机率/开机利用率</div>
                        <div id="ecartsKJ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div2">
                        <div class="">故障分析(以设备型号统计分析)</div>
                        <div id="echartsGZ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">停机分析</div>
                        <div id="echartsTJ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">综合利用率</div>
                        <div id="echartsZHLY" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">维修产出比</div>
                        <div id="echartsWX" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">MTBF/MTTR/故障率</div>
                        <div id="echartsGMTBF" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">能耗/产值</div>
                        <div id="echartsNH" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>

</template>
<script>
    import * as echarts from 'echarts';
    import {
        workShopDashBoardGetTitle,
        workShopOrganizationSelect,
        workShopLineProductionComparison,
        workShopLineUseRatioComparison,
        workShopLineSyntheticalUseRatioComparison,
        workShopRhythmData,
        workShopFaultAnalysis,
        workShopStandbyAnalysis,
        workShopStopAnalysis,
        workShopLineEnergyData,
        workShopMaintainOutputProportion,
        workShopMttrMtbfFaultrateComparison
    } from "@/api/api_comprehensiveShow";
    export default {
        data() {
            return {
                currentTime: new Date(),
                permission: {},
                boardTitle: ''
            }
        },
        mounted() {
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss')
            this.workShopDashBoardGetTitle();
            // this.workShopOrganizationSelect();
            this.workShopLineProductionComparison();
            this.workShopLineUseRatioComparison();
            this.workShopLineSyntheticalUseRatioComparison();
            this.workShopRhythmData();
            this.workShopStandbyAnalysis();
            this.workShopStopAnalysis();
            this.workShopLineEnergyData();
            this.workShopFaultAnalysis();
            this.workShopMaintainOutputProportion();
            this.workShopMttrMtbfFaultrateComparison();
        },
        methods: {
            // 组织架构选择
            async workShopOrganizationSelect() {
                var param = {
                    permission: this.permission
                }
                const res = await workShopOrganizationSelect(param);
                console.log(res);
            },
            // 获取看板标题
            async workShopDashBoardGetTitle() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await workShopDashBoardGetTitle(param);
                if (res && res.data) {
                    this.boardTitle = res.data
                }
            },
            // 车间产线产能对比
            async workShopLineProductionComparison() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await workShopLineProductionComparison(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawLineProductionComparisonShape(line);
            },
            // 车间各产线开机率利用率对比
            async workShopLineUseRatioComparison() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await workShopLineUseRatioComparison(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawLineUseRatioComparisonShape(line)
            },
            // 车间各产线综合利用率
            async workShopLineSyntheticalUseRatioComparison() {
                var param = {
                    organization: {
                        "EquipmentID": ['123', '456']
                    }
                }
                const res = await workShopLineSyntheticalUseRatioComparison(param);
                var line = {
                    data: [],
                    oeeArray: []
                };
                for (var i = 0; i < res.data.list.length; i++) {
                    let testData = {
                        name: '',
                        max: 0
                    }
                    testData.name = res.data.list[i].name;
                    testData.max = res.data.max;
                    line.data.push(testData);
                    line.oeeArray.push(res.data.list[i].OEE)
                }
                this.drawLineSyntheticalUseRatioComparisonShap(line);
            },
            // 车间产线设备故障分析
            async workShopFaultAnalysis() {
                var param = {}
                const selectRes = await workShopFaultAnalysis(param);
                var testData = selectRes.data;
                var line = []
                var legend = [];
                for(var i=0;i<testData.length;i++) {
                    var dataA = testData[i];
                    var currentData = {
                        name: '',
                        data: testData[i],
                        type: 'scatter',
                        symbolSize: function (data) { //气泡的大小
                            return data[2];
                        },
                        emphasis: {
                            focus: 'series'
                        },
                    }
                    legend.push(dataA[0][3])
                    currentData.name = dataA[0][3]
                    line.push(currentData)
                }
                this.drawWorkShopFaultAnalysisShap(line, legend)
            },
            // 车间待机分析
            async workShopStandbyAnalysis() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await workShopStandbyAnalysis(param);
                if (res && res.data && res.data.dataList) {
                    this.drawWorkShopStandbyAnalysis(res.data);
                }
            },
            // 车间停机分析
            async workShopStopAnalysis() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await workShopStopAnalysis(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawWorkShopStopAnalysisShape(line)
            },
            // 车间各产线能耗数据
            async workShopLineEnergyData() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await workShopLineEnergyData(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawWorkShopLineEnergyDataShape(line)
            },
            // 车间产线节拍分析
            async workShopRhythmData() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await workShopRhythmData(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        type: 'line',
                            stack: '总量',
                            smooth: true, // 平滑曲线
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            showSymbol: false,
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawWorkShopRhythmDataShap(line);
            },
            // 车间维修产出比
            async workShopMaintainOutputProportion() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await workShopMaintainOutputProportion(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawWorkShopMaintainOutputProportionShape(line)
            },
            // 车间MTTR/MTBF/故障率比较
            async workShopMttrMtbfFaultrateComparison() {
                var param = {
                    organization: {
                        "EquipmentID": [123, 456]
                    }
                }
                const res = await workShopMttrMtbfFaultrateComparison(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.axis;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: []
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].value;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawWorkShopMttrMtbfFaultrateComparisonShape(line)
            },
            // 画工厂各车间MTTR/MTBF/故障率比较图
            drawWorkShopMttrMtbfFaultrateComparisonShape(data) {
                this.echartsGMTBF = echarts.init(document.getElementById('echartsGMTBF'));
                this.echartsGMTBF.setOption({
                    color: ['#2883FF', '#F8AF21', '#00FF00'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },{
                            type: 'value',
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                formatter: '{value} %'
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[0].data
                        },{
                            name: data.series[1].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[1].data
                        },{
                            name: data.series[2].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[2].data
                        }
                    ]
                });
            },
            // 画维修产出比图
            drawWorkShopMaintainOutputProportionShape(data) {
                this.echartsWX = echarts.init(document.getElementById('echartsWX'));
                this.echartsWX.setOption({
                    color: ['#2883FF', '#EE1C91'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            // min: 0,
                            // max: 250,
                            // interval: 50,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },
                        {
                            type: 'value',
                            // min: 0,
                            // max: 25,
                            // interval: 5,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[1].data
                        }
                    ]
                });
            },
            // 画车间产线节拍分析图
            drawWorkShopRhythmDataShap(data) {
                this.ecartsJP = echarts.init(document.getElementById('ecartsJP'));
                this.ecartsJP.setOption({
                    tooltip: {
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        icon: 'rect',
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                    },
                    xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        data: data.xAxis.data,
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        },
                        splitLine: { // 分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    }],
                    series: data.series
                })
            },
            // 画能耗产值对比图
            drawWorkShopLineEnergyDataShape(data) {
                this.echartsNH = echarts.init(document.getElementById('echartsNH'));
                this.echartsNH.setOption({
                    color: ['#1BD260', '#F8AF21'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },
                        {
                            type: 'value',
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[1].data
                        }
                    ]
                });
            },
            // 画停机分析图
            drawWorkShopStopAnalysisShape(data) {
                this.echartsTJ = echarts.init(document.getElementById('echartsTJ'));
                this.echartsTJ.setOption({
                    color: ['#28B1FF', '#1BD260'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        icon: 'rect',
                        itemHeight: 10, //修改icon图形大小
                        itemWidth: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'value',
                        show: false,
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            barWidth: '5',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'bar',
                            barWidth: '5',
                            data: data.series[1].data
                        }
                    ]
                });
            },
            // 画待机分析图
            drawWorkShopStandbyAnalysis(data) {
                this.ecartsDJ = echarts.init(document.getElementById('ecartsDJ'));
                this.ecartsDJ.setOption({
                    // color: ['#568AEF', '#50E4AC', '#2A3764', '#FFCA2B', '#FF6450', '#00C6FF'],
                    title: {
                        text: ['{name|总}', '{value|' + data.total + '}'].join('\n'),
                        top: 'center',
                        left: '39%',
                        textAlign: 'center',
                        textStyle: {
                            rich: {
                                value: {
                                    color: '#FFFFFF',
                                    fontSize: 24,
                                    lineHeight: 24,
                                },
                                name: {
                                    color: '#FFFFFF',
                                    fontSize: 14,
                                    lineHeight: 35,
                                },
                            },
                        },
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        // top: '5%',
                        // left: 'center',
                        x: 'right', //可设定图例在左、右、居中
                        y: 'center', //可设定图例在上、下、居中
                        orient: 'vertical',
                        icon: 'circle',
                        padding: [0, 40, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    series: [{
                        name: '访问来源',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['40%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            color: '#FFFFFF',
                            fontSize: 12,
                            formatter: '{d}%',
                            // formatter: '{d}%',
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: true
                        },
                        data: data.dataList
                    }]
                })
            },
            // 画故障分析图
            drawWorkShopFaultAnalysisShap(data,legend) {
                this.echartsGZ = echarts.init(document.getElementById('echartsGZ'));
                this.echartsGZ.setOption({
                    legend: {
                        bottom: '5%',
                        data: legend,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    tooltip: {},
                    grid: {
                        left: '8%',
                        top: '10%'
                    },
                    xAxis: {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        splitLine: { // 分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    },
                    series: data
                })
            },
            // 画综合利用率
            drawLineSyntheticalUseRatioComparisonShap(data) {
                this.echartsZHLY = echarts.init(document.getElementById('echartsZHLY'));
                this.echartsZHLY.setOption({
                    color: ['#00FF00'],
                    legend: {
                        data: ['利用率'],
                        bottom: '5%',
                        itemHeight: 4,
                        itemWidth: 20,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    radar: {
                        radius: 50,
                        shape: 'circle',
                        center: ['50%', '45%'],
                        indicator: data.data
                    },
                    series: [{
                        type: 'radar',
                        data: [{
                                value: data.oeeArray,
                                name: '利用率',
                                areaStyle: {
                                    color: '#00FF00'
                                }
                            }
                        ]
                    }]
                })
            },
            // 画设备开机率利用率对比图
            drawLineUseRatioComparisonShape(data) {
                this.ecartsKJ = echarts.init(document.getElementById('ecartsKJ'));
                this.ecartsKJ.setOption({
                    color: ['#288DFF', '#52FFFF'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        icon: 'rect',
                        itemHeight: 10, //修改icon图形大小
                        itemWidth: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'bar',
                            barWidth: '10',
                            data: data.series[1].data
                        }
                    ]
                });
            },
            // 画车间产线产能对比图
            drawLineProductionComparisonShape(data) {
                this.ecartsGX = echarts.init(document.getElementById('ecartsGX'));
                this.ecartsGX.setOption({
                    color: ['#2883FF', '#00FF00'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '5%',
                        itemHeight: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#E6E6E6'
                    },
                    xAxis: [{
                        type: 'category',
                        data: data.xAxis.data,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    dataZoom: [{
                        type: 'slider',
                        filterMode: 'weakFilter',
                        showDataShadow: false,
                        top: 400,
                        handleSize: 8,
                        labelFormatter: ''
                    }, {
                        type: 'inside',
                        filterMode: 'weakFilter'
                    }],
                    yAxis: [{
                            type: 'value',
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        },
                        {
                            type: 'value',
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                            },
                        }
                    ],
                    series: [{
                            name: data.series[0].name,
                            type: 'bar',
                            data: data.series[0].data
                        },
                        {
                            name: data.series[1].name,
                            type: 'line',
                            symbol: 'circle', // 拐点样式
                            symbolSize: 6, // 拐点大小
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#000000' // 拐点颜色
                            },
                            yAxisIndex: 1,
                            data: data.series[1].data
                        }
                    ]
                });
            },
            
            
            drawShap(data) {
                this.echartsGMTBF = echarts.init(document.getElementById('echartsGMTBF'));
                this.echartsGMTBF.setOption({
                    color: ['#2883FF', '#F8AF21', '#00FF00'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: ['企业', 'london', '行业'],
                        bottom: '5%',
                        itemHeight: 10, //修改icon图形大小
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月',
                            '12月'
                        ],
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            // name: '水量',
                            min: 0,
                            max: 250,
                            interval: 50,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                // formatter: '{value} ml'
                            },
                            splitLine: { //分割线配置
                                show: true,
                                lineStyle: {
                                    color: "#FFFFFF",
                                    opacity: 0.2
                                }
                            }
                        },
                        {
                            type: 'value',
                            min: 0,
                            max: 25,
                            interval: 5,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                formatter: '{value} %'
                            },
                            splitLine: { //分割线配置
                                show: true,
                                lineStyle: {
                                    color: "#FFFFFF",
                                    opacity: 0.2
                                }
                            }
                        }
                    ],
                    series: [{
                            name: '企业',
                            type: 'bar',
                            data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
                        },
                        {
                            name: 'london',
                            type: 'bar',
                            data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
                        },
                        {
                            name: '行业',
                            type: 'line',
                            yAxisIndex: 1,
                            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
                        }
                    ]
                })

            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }

    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
    }

    .board_bgd {
        width: 100vw;
        height: 86vh;
        background-image: url('../../assets/images/comprehensive/bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        padding: 0 20px;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        background-image: url('../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 25vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_div {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }
</style>