<!-- 设备经理 -->
<template>
    <div>
        <div class="page_container">
            <div class="bgc_FFFFFF flex flex_column" style="padding: 26px 40px;">
                <div class="fn_size18 flex" style="font-weight: 500;">XXXX指标</div>
                <div class="flex justify_between mg_top_24">
                    <div class="box_1 bgc_03CABE" @click="dialogTableVisible = true">
                        <div class="fn_size16">设备总数</div>
                        <div class="fn_size28 mg_top_17">123,456</div>   
                    </div>
                    <el-dialog :visible.sync="dialogTableVisible">
                        <div slot="title" class="fn_size18 flex justify_center color_black font_bold">设备总数</div>
                        <el-table :data="gridData"  class="dialog__body">
                            <el-table-column property="use_dept" label="使用部门"></el-table-column>
                            <el-table-column property="belong_dept" label="所属部门"></el-table-column>
                            <el-table-column property="equipment_model" label="设备型号"></el-table-column>
                            <el-table-column property="equipment_num" label="设备编号"></el-table-column>
                            <el-table-column property="equipment_name" label="设备名称"></el-table-column>
                            <el-table-column width="50">
                                <img src="../../assets/images/comprehensive/page_right.png"></img>
                            </el-table-column>
                        </el-table>
                        <div class="flex justify_between align_center">
                            <div class="color_808080">当前1-10 总记录：123,456</div>
                            <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
                        </div>
                    </el-dialog>
                    <div class="box_1 bgc_2891FC">
                        <div class="fn_size16">连接异常设备</div>
                        <div class="fn_size28 mg_top_17">123,456</div>
                    </div>
                    <div class="box_1 bgc_FFA847">
                        <div class="fn_size16"> 本月报修</div>
                        <div class="fn_size28 mg_top_17">123,456</div>
                    </div>
                    <div class="box_1 bgc_657180">
                        <div class="fn_size16"> 点检待办单</div>
                        <div class="fn_size28 mg_top_17">123,456</div>
                    </div>
                    <div class="box_1 bgc_F75753">
                        <div class="fn_size16"> 点检异常单</div>
                        <div class="fn_size28 mg_top_17">123,456</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- tab -->
        <div class="bgc_FFFFFF flex flex_column" style="padding: 26px 40px;">
            <el-tabs v-model="tabName" @tab-click="handleClick" style="height: 509px;">
                <el-tab-pane name="first"><el-badge slot="label" :value="23" class="badgeitem"><span>待办</span></el-badge>
                    <div class="flex align_center">
                        <div class="color_999999 fn_size16">待办总数：</div>
                        <div class="fn_size30">23</div>
                        <div class="color_999999 fn_size16 mg_left_50">今日已办：</div>
                        <div class="fn_size30">17</div>
                    </div>
                    <!-- 下拉框 -->
                    <div class="flex mg_top_15">
                        <el-select v-model="value" placeholder="多选执行人" style="width:140px" class="mg_right_10">
                            <el-option v-for="item in option_people" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <el-select v-model="value" placeholder="多选状态" style="width:140px" class="mg_right_10">
                            <el-option v-for="item in option_status" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                            <el-time-picker v-model="time" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" placeholder="选择时间"  style="width:140px" class="mg_right_10"></el-time-picker>
                            <el-date-picker v-model="date" type="date" placeholder="选择日期" style="width:140px" class="mg_right_10"></el-date-picker>
                            <el-button type="primary" size="mini" class="select_button "><i class="select_icon"></i></el-button>
                    </div>
                    <!-- 折叠面板 -->
                    <div class="mg_top_10" style="border: 0px;height: 360px;overflow-y: auto;">
                        <el-collapse v-model="collapseNames" @change="handleChange">
                            <el-collapse-item :name="index+1" v-for="(item,index) in collapse_items" :key="index">
                                <div class="flex fn_size16 color_1A1A1A width_100" slot="title">
                                    <span>{{item.execute_name}}</span>
                                    <span style="margin-left: 554px;">{{item.execute_people}}</span>
                                    <span class="mg_right_10" style="margin-left: 400px;">{{item.execute_status}}</span>
                                </div>
                                <div class="fn_size16 flex justify_between flex_wrap" flex>
                                    <div>{{item.name}}</div>
                                    <div>{{item.num}}</div>
                                    <div>{{item.createtime}}</div>
                                    <div>{{item.createpeople}}</div>
                                    <div>{{item.start}}</div>
                                    <div>{{item.end}}</div>
                                </div>
                                <div class="fn_size16 flex justify_start">{{item.content}}</div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="点检" name="second"></el-tab-pane>
                <el-tab-pane label="保养" name="third"></el-tab-pane>
                <el-tab-pane label="维修" name="fourth"></el-tab-pane>
                <el-tab-pane label="报警" name="fifth"></el-tab-pane>
                <el-tab-pane label="预留" name="sixth"></el-tab-pane>
                <el-tab-pane label="预留" name="seventh"></el-tab-pane>
            </el-tabs>
            
        </div>
    </div>
</template>
<script>
    //   import innerTopNav from './../../components/innerTopNav'
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabName: 'first',
                activeNames: ['1'],
                time: '',
                date: '',
                value: '',
                collapseNames: '',
                option_people: [{
                    value: '选项1',
                    label: '周建秋'
                    },
                ],
                option_status: [{
                    value: '选项1',
                    label: '待审批'
                    }
                ],
                collapse_items: [{
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },{
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },
                {
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },
                {
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },
                {
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },
                {
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },
                {
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },
                {
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },
                {
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                },
                {
                    execute_name: '1#点检计划',
                    execute_people: '执行人：周建秋',
                    execute_status: '执行状态：待审批',
                    name: '计划名称：点检计划',
                    num: '计划号：点检计划',
                    createtime: '创建时间：2021-12-02 15:23',
                    createpeople: '创建人：周建秋',
                    start: '计划开始：2021-12-02 15:23',
                    end: '计划结束：2021-12-02 15:23',
                    content: '计划内容：'
                }
                ],
                tab_items: [{
                    label:'待办',
                    name: 'first'
                },{
                    label:'点检',
                    name: 'second'
                },
                {
                    label:'保养',
                    name: 'third'
                },
                {
                    label:'维修',
                    name: 'fourth'
                },
                {
                    label:'报警',
                    name: 'fifth'
                },
                {
                    label:'预留',
                    name: 'sixth'
                },
                {
                    label:'预留',
                    name: 'seventh'
                },
                ],
                gridData:[{
                    use_dept: '零部件',
                    belong_dept: '设备部',
                    equipment_model: 'CN001',
                    equipment_num: '001-004',
                    equipment_name: '卧式机床'
                }, {
                    use_dept: '零部件',
                    belong_dept: '设备部',
                    equipment_model: 'CN001',
                    equipment_num: '001-004',
                    equipment_name: '卧式机床'
                }, {
                    use_dept: '零部件',
                    belong_dept: '设备部',
                    equipment_model: 'CN001',
                    equipment_num: '001-004',
                    equipment_name: '卧式机床'
                }, {
                    use_dept: '零部件',
                    belong_dept: '设备部',
                    equipment_model: 'CN001',
                    equipment_num: '001-004',
                    equipment_name: '卧式机床'
                }, {
                    use_dept: '零部件',
                    belong_dept: '设备部',
                    equipment_model: 'CN001',
                    equipment_num: '001-004',
                    equipment_name: '卧式机床'
                }, {
                    use_dept: '零部件',
                    belong_dept: '设备部',
                    equipment_model: 'CN001',
                    equipment_num: '001-004',
                    equipment_name: '卧式机床'
                }
                ],
                dialogTableVisible: false,
            }
        },
        mounted() {},
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            handleChange(val) {
                console.log(val);
            }
        }
    }
</script>

<style scoped>
    .page_container {
        /* background-color: #FFFFFF; */
        height: 100%;
        padding: 20px 0;
        color: #000000;
        font-size: 16px;
    }

    .box_1 {
        width: 278px;
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
    }

    .el-tabs__item.is-active{
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        /* color: #999999; */
        color: #54a2eb;
    }

    .dialog__body {
        height: 600px;
    }

    .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
    
</style>

<style lang="less" scoped>
    ::v-deep .el-badge__content.is-fixed{
        top: 8px;
        right: 8px;
    }

    ::v-deep .el-tabs__item {
        font-size: 16px !important;
    }
</style>