// 权限管理
<template>
    <div class="page_container">
        <div class="bgc_FFFFFF flex fn_size14 height_44">
            <div v-for="item in tabArray" :key="item.id" class="tab" :class="tabIndex==item.id?'tab_active':''"
                @click="changeTab(item.id)">{{item.label}}</div>
        </div>
        <!-- 用户管理--开始  tabIndex==0 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30" v-if="tabIndex==0">
            <div class="pdt_20 flex justify_between">
                <div class="flex">
                    <el-select v-model="value" placeholder="请选择用户部门" class="mg_right_10 width_170" multiple
                        collapse-tags>
                        <el-option v-for="item in departmentArray" :key="item.departmentID" :label="item.departmentName"
                            :value="item.departmentID">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="mg_left_10 select_btn" @click="queryUser">查询</el-button>
                </div>
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="mg_left_10 select_btn"
                    style="background: #03CABE;border:none;" @click="add">增加</el-button>
            </div>
            <el-table :data="queryList" class="mg_top_20" height="570" border>
                <el-table-column type="index" label="序号" width="55"></el-table-column>
                <el-table-column prop="userName" label="用户名" width="">
                </el-table-column>
                <el-table-column prop="userAccount" label="用户账号" width="">
                </el-table-column>
                <el-table-column prop="workNumber" label="用户工号" width="">
                </el-table-column>
                <el-table-column label="性别" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gender == 0">男</span>
                        <span v-if="scope.row.gender == 1">女</span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="用户电话" width="">
                </el-table-column>
                <el-table-column prop="email" label="邮箱" width="">
                </el-table-column>
                <el-table-column prop="departmentID" label="用户部门" width="">
                </el-table-column>
                <el-table-column prop="jobTitle" label="职位" width="">
                </el-table-column>
                <el-table-column prop="createDate" label="创建时间" :formatter="formatDate" width="">
                </el-table-column>
                <el-table-column label="是否离职" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.leaveFlag == 0">未离职</span>
                        <span v-if="scope.row.leaveFlag == 1">已离职</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lockFlag" label="是否锁定" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.lockFlag == 0">未锁定</span>
                        <span v-if="scope.row.lockFlag == 1">已锁定</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="220">
                    <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0" @click="gotoModify(scope.row)">
                            编辑
                            <!-- <span style="border-bottom: 1px solid #0384D5;">追踪</span> -->
                        </el-button>
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0" @click="look(scope.row)">查看
                        </el-button>
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0"
                            @click="lock(scope.row.userID)">锁定
                        </el-button>
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0"
                            @click="delTableData(scope.row.userID)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="flex justify_end  ">
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <span>这是一段信息</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog> -->
        <!-- 新增用户信息弹出框 -->
        <el-dialog :visible.sync="dialogVisible" top='18vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">用户信息配置</div>
            <div class="">
                <!-- pdl30 pdr30 -->
                <el-form :model="addFrom" :label-width="labelWidth" :rules="rulesFrom" class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户名：">
                        <el-input v-model="addFrom.userName" placeholder="用户名" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="性别：">
                        <el-select v-model="addFrom.gender" placeholder="性别" class="width_170 ">
                            <el-option v-for="item in sex" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位：">
                        <el-select v-model="addFrom.jobTitle" placeholder="职位" class="width_170 ">
                            <el-option v-for="item in jobArray" :key="item.jobID" :label="item.jobName"
                                :value="item.jobID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工号：">
                        <el-input v-model="addFrom.workNumber" placeholder="工号" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="账号：">
                        <el-input v-model="addFrom.userAccount " placeholder="账号" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="密码：">
                        <el-input v-model="addFrom.userPassword" placeholder="密码" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="addFrom.email" placeholder="邮箱" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="addFrom.phone" placeholder="电话" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="部门ID：">
                        <el-select v-model="addFrom.departmentID" placeholder="部门ID" class="width_170 ">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否在职：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="addFrom.leaveFlag" class="width_170 text_align_left">
                            <el-radio label=1>是</el-radio>
                            <el-radio label=0>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否锁定：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="addFrom.lockFlag" class=" text_align_left"
                            @change="changeLockDateReadOnly">
                            <el-radio label=1>是</el-radio>
                            <el-radio label=0>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="锁定日期：" :formatter="formatDate">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-date-picker v-model="addFrom.lockDate" type="datetime " :clearable="false"
                            placeholder="选择日期时间" :formatter="formatDate" class="width_170" :disabled="lockDateReadOnly">
                        </el-date-picker>
                    </el-form-item>

                </el-form>
                <el-form :model="addFrom" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="addFrom.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="trueadd">添加</el-button>
            </div>
        </el-dialog>
        <!--   用户信息--编辑弹出框 -->
        <el-dialog :visible.sync="dialog2Visible" top='3vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑用户信息</div>
            <div class="flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="updateForm" :label-width="labelWidth" :rules="rulesFrom"
                    class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <!--                     <el-form-item label="用户名：">
                        <el-input v-model="addFrom.userName" placeholder="用户名" class="width_170 " :readonly="true"></el-input>
                    </el-form-item> -->
                    <el-form-item label="性别：">
                        <el-select v-model="updateForm.gender" placeholder="性别" class="width_170 ">
                            <el-option v-for="item in sex" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位：">
                        <el-select v-model="updateForm.jobTitle" placeholder="职位" class="width_170 ">
                            <el-option v-for="item in jobArray" :key="item.jobID" :label="item.jobName"
                                :value="item.jobID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!--                     <el-form-item label="工号：">
                        <el-input v-model="addFrom.workNumber" placeholder="工号" class="width_170 " :readonly="true"></el-input>
                    </el-form-item> -->
                    <!--                     <el-form-item label="账号：">
                        <el-input v-model="addFrom.userAccount " placeholder="账号" class="width_170 " :readonly="true"></el-input>
                    </el-form-item> -->
                    <el-form-item label="密码：">
                        <el-input v-model="updateForm.userPassword" placeholder="密码" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="updateForm.email" placeholder="邮箱" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="updateForm.phone" placeholder="电话" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="部门ID：">
                        <el-select v-model="updateForm.departmentID" placeholder="部门ID" class="width_170 ">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否在职：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="updateForm.leaveFlag" class="width_170 text_align_left">
                            <el-radio :label='1'>是</el-radio>
                            <el-radio :label='0'>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否锁定：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="updateForm.lockFlag" class=" text_align_left"
                            @change="changeLockDateReadOnly">
                            <el-radio :label='1'>是</el-radio>
                            <el-radio :label='0'>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <el-form :model="updateForm" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="updateForm.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
                <div>
                    <div class="border_bottom_1_CCCCCC height_40 mg_bottom_20">
                        <div class="fn_size18 color_000000 flex justify_center font_bold">用户-角色配置</div>
                    </div>
                    <el-col :span="10" class="shuttleBox">
                        <el-table :data="userNotHaveRoleArray" border ref="selection" @selection-change="checkAll"
                            :header-cell-style="tableHeaderColor" height="100%" class="width_100"
                            style="overflow-y: auto;">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column prop="name" label="系统所有角色">
                                <template slot-scope="scope">
                                    <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                        style="border: 1px solid #E6E6E6;">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="4">
                        <div class="flex flex_column justify_center align_center shuttleBox">
                            <div @click="addUserRole()" class="mg_bottom_10">
                                <el-button :disabled="userNotHaveRoleArray.length?false:true" :size="buttonSize"
                                    style="background: #EBEBEB;border:none" type="primary">
                                    <i class="">
                                        <img src="../../assets/images/systemConfiguration/user4.png">
                                    </i>
                                    <!-- 添加 -->
                                </el-button>
                            </div>
                            <div class="spacing" @click="removeUserRole()">
                                <el-button :disabled="userHaveRoleArray.length?false:true" :size="buttonSize"
                                    style="background: #EBEBEB;border:none" type="primary">
                                    <i class="">
                                        <img src="../../assets/images/systemConfiguration/user5.png">
                                    </i>
                                    <!-- 添加 -->
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10" class="shuttleBox">
                        <el-table :data="userHaveRoleArray" border ref="selection" @selection-change="checkRightAll"
                            :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                            style="overflow-y: auto;">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column prop="name" label="当前用户角色">
                                <template slot-scope="scope">
                                    <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                        style="border: 1px solid #E6E6E6;">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </div>
            </div>

            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="trueModify">确定</el-button>
            </div>
        </el-dialog>

        <!-- 查看用户信息弹出框 -->
        <el-dialog :visible.sync="dialog3Visible" top='3vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">用户信息配置</div>
            <div class="">
                <!-- pdl30 pdr30 -->
                <el-form :model="detailForm" :label-width="labelWidth" :rules="rulesFrom"
                    class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户名：">
                        <el-input v-model="detailForm.userName" placeholder="用户名" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="性别：">
                        <!--  <el-input v-model="detailForm.gender" placeholder="性别" class="width_170 " :readonly="true"></el-input> -->
                        <el-input value="男" v-if="detailForm.gender == 0" placeholder="性别" class="width_170 "
                            :readonly="true"></el-input>
                        <el-input value="女" v-if="detailForm.gender == 1" placeholder="性别" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="职位：">
                        <el-select v-model="detailForm.jobTitle" placeholder="职位" class="width_170 ">
                            <el-option v-for="item in jobArray" :key="item.jobID" :label="item.jobName"
                                :value="item.jobID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工号：">
                        <el-input v-model="detailForm.workNumber" placeholder="工号" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="账号：">
                        <el-input v-model="detailForm.userAccount " placeholder="账号" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：">
                        <el-input v-model="detailForm.userPassword" placeholder="密码" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="detailForm.email" placeholder="邮箱" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="detailForm.phone" placeholder="电话" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="部门ID：">
                        <el-select v-model="detailForm.departmentID" placeholder="职位" class="width_170 ">
                            <el-option v-for="item in departmentArray" :key="item.departmentID"
                                :label="item.departmentName" :value="item.departmentID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否在职：">
                        <el-input value="未离职" v-if="detailForm.leaveFlag == 0" placeholder="是否在职" class="width_170 "
                            :readonly="true"></el-input>
                        <el-input value="已离职" v-if="detailForm.leaveFlag == 1" placeholder="是否在职" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="是否锁定：">
                        <el-input value="未锁定" v-if="detailForm.lockFlag == 0" placeholder="是否锁定" class="width_170 "
                            :readonly="true"></el-input>
                        <el-input value="已锁定" v-if="detailForm.lockFlag == 1" placeholder="是否锁定" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="锁定日期：" :formatter="formatDate">
                        <el-input v-model="detailForm.createDate" placeholder="锁定日期" :formatter="formatDate"
                            class="width_170 " :readonly="true"></el-input>
                    </el-form-item>
                </el-form>
                <el-form :model="detailForm" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="detailForm.description" resize="none"
                            placeholder="请输入介绍信息" class="" :readonly="true"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="closedialog3">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 用户管理--结束 -->


        <!-- 用户组--开始 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" v-if="tabIndex==1">
            <!-- 左侧 角色管理菜单 -->
            <div class=" width_346 ">
                <el-card class="width_346 flex flex_column" style="height: 600px; overflow-y: auto;">
                    <!-- 标题 -->
                    <div slot="header" class="fn_size14 flex">用户组管理</div>
                    <!-- 角色列表 -->
                    <div v-for="item in roleGroupArray" :key="item.id" :label="item.name" :value="item.id"
                        class="fn_size12">
                        <div class="flex align_center justify_between mg_bottom_10 pdl10 pdr10 height_30"
                            style="border: 1px solid #E6E6E6;">
                            {{item.name}}
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="200" trigger="hover">
                                    <div>创建信息：XXXXXX</div>
                                    <div>描述：XXXXXX</div>
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="roleGroupEdit">
                                <img src="../../assets/images/systemConfiguration/user13.png" class="mg_right_16"
                                    @click="roleLocking">
                                <img src="../../assets/images/systemConfiguration/user14.png" class=""
                                    @click="roleGroupDel">
                            </div>
                        </div>
                    </div>
                </el-card>
                <!-- 添加角色按钮 -->
                <el-button type="primary" class="width_100 height_40 flex justify_center align_center"
                    @click="addRoleGroup">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加用户组</span>
                </el-button>
            </div>
            <!-- 右侧 用户组配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 用户组配置 标题 -->
                <div class="fn_size20 width_826 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="roleGroupDetailsShow=!roleGroupDetailsShow"></el-button>
                    <span v-if="roleGroupDetailsShow" class="flex justify_center align_center"
                        style="font-weight: bold;">用户组-权限配置</span>
                    <span v-if="!roleGroupDetailsShow" class="flex justify_center align_center"
                        style="font-weight: bold;">用户组-页面配置</span>
                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleGroupDetailsShow=!roleGroupDetailsShow">
                    </el-button>
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div v-if="roleGroupDetailsShow">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有权限">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前权限">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="!roleGroupDetailsShow">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有页面">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组页面">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>

                <!-- 添加按钮 -->
                <!-- <el-button type="primary" class="width_110 height_36 mg_top_30 flex justify_center align_center">添加
                </el-button> -->
            </div>
        </div>

        <!-- 用户组--添加角色组弹框 roleGroup -->
        <el-dialog :visible.sync="dialogVisibleRoleGroupAdd" top='25vh' width="40%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加用户组</div>
            <div class="pdr20">
                <!-- pdl30 pdr30 -->
                <el-form :model="fromRoleGroup" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="角色组名：" prop="fileName">
                        <el-input v-model="fromRoleGroup.fileName" placeholder="请输入" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期：" prop="author">
                        <el-date-picker v-model="fromRoleGroup.value1" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="fromRoleGroup" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="fromRoleGroup.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 用户组-编辑 -->
        <el-dialog :visible.sync="dialogVisibleRoleGroupEdit" top='5vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">用户组信息配置</div>
            <div class="pdl20 pdr20 flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="fromRoleGroup" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="角色组名：" prop="fileName">
                        <el-input v-model="fromRoleGroup.fileName" placeholder="文档名称" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期：" prop="author">
                        <el-date-picker v-model="fromRoleGroup.value1" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="fromRoleGroup" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="fromRoleGroup.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- 角色-角色组配置--穿梭框 -->
                <div class="fn_size20 flex justify_between align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="roleStause=!roleStause"></el-button>
                    <div v-if="roleStause" class="fn_size18 color_000000 flex justify_center font_bold">角色组-权限配置</div>
                    <div v-if="!roleStause" class="fn_size18 color_000000 flex justify_center font_bold">角色组-页面配置</div>

                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleStause=!roleStause"></el-button>
                </div>
                <div class="mg_top_20">
                    <div v-if="roleStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有权限">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组权限">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="!roleStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有页面">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组页面">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 用户组--结束 -->

        <!-- 角色管理--开始   tabIndex==2-->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" v-if="tabIndex==2">
            <!-- 左侧 角色管理菜单 -->
            <div class=" width_346 ">
                <el-card class="width_346 flex flex_column" style="height: 600px; overflow-y: auto;">
                    <!-- 标题 -->
                    <div slot="header" class="fn_size14 flex">
                        <span>角色管理</span>
                    </div>
                    <!-- 角色列表 -->
                    <div v-for="item in roleArray" :key="item.id" :label="item.name" :value="item.id" class="fn_size12">
                        <div class="flex align_center justify_between mg_bottom_10 pdl10 pdr10 height_30"
                            style="border: 1px solid #E6E6E6;">
                            {{item.name}}
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="200" trigger="hover">
                                    <div>创建信息：</div>{{tools.formatDate(item.createDate, 'yyyy-MM-dd hh:mm:ss')}}
                                    <div>描述：</div>{{item.description}}
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="roleEdit">
                                <img src="../../assets/images/systemConfiguration/user13.png" class="mg_right_16"
                                    @click="roleLocking">
                                <img src="../../assets/images/systemConfiguration/user14.png" class="" @click="roleDel">
                            </div>
                        </div>
                    </div>
                </el-card>
                <!-- 添加角色按钮 -->
                <el-button type="primary" class="width_100 height_40 flex justify_center align_center" @click="addRole">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加角色</span>
                </el-button>
            </div>
            <!-- 右侧 角色组配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 角色组配置 标题 -->
                <div class="fn_size20 width_826 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="roleDetailsShow=!roleDetailsShow"></el-button>
                    <span v-if="roleDetailsShow" class="flex justify_center align_center"
                        style="font-weight: bold;">角色-角色组配置</span>
                    <span v-if="!roleDetailsShow" class="flex justify_center align_center"
                        style="font-weight: bold;">角色-组织配置</span>
                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleDetailsShow=!roleDetailsShow"></el-button>
                </div>
                <!-- 穿梭框 -->

                <div class="width_826 height_100 mg_top_22">
                    <div v-if="roleDetailsShow">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前用户角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="!roleDetailsShow">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前用户角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>

                <!-- 添加按钮 -->
                <!-- <el-button type="primary" class="width_110 height_36 mg_top_30 flex justify_center align_center">添加
                </el-button> -->
            </div>
        </div>

        <!-- 角色管理-弹窗 -->
        <!-- 角色管理--添加角色弹窗 -->
        <el-dialog :visible.sync="dialogVisibleRoleAdd" top='25vh' width="40%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加角色</div>
            <div class="pdr20">
                <!-- pdl30 pdr30 -->
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="角色名：" prop="fileName">
                        <el-input v-model="fromRole.fileName" placeholder="请输入" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期：" prop="author">
                        <el-date-picker v-model="fromRole.value1" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="fromRole.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 角色管理--编辑 -->
        <el-dialog :visible.sync="dialogVisibleRoleEdit" top='3vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">角色信息配置</div>
            <div class="pdl20 pdr20 flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="角色名：" prop="fileName">
                        <el-input v-model="fromRole.fileName" placeholder="文档名称" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期：" prop="author">
                        <el-date-picker v-model="fromRole.value1" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="fromRole.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- 角色-角色组配置--穿梭框 -->
                <div class="fn_size20 flex justify_between align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="roleStause=!roleStause"></el-button>
                    <div v-if="roleStause" class="fn_size18 color_000000 flex justify_center font_bold">角色-角色组配置</div>
                    <div v-if="!roleStause" class="fn_size18 color_000000 flex justify_center font_bold">角色-组织配置</div>

                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleStause=!roleStause"></el-button>
                </div>
                <div class="mg_top_20">
                    <div v-if="roleStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色组">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="!roleStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 角色管理--结束 -->

        <!-- 组织 --开始 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" v-if="tabIndex==3">
            <!-- 左侧 组织管理菜单 -->
            <div class=" width_346 ">
                <el-card class="width_346 flex flex_column" style="height: 600px; overflow-y: auto;">
                    <!-- 标题 -->
                    <div slot="header" class="fn_size14 flex">
                        <span>组织管理</span>
                    </div>
                    <!-- 角色列表 -->
                    <div v-for="item in roleArray" :key="item.id" :label="item.name" :value="item.id" class="fn_size12">
                        <div class="flex align_center justify_between mg_bottom_10 pdl10 pdr10 height_30"
                            style="border: 1px solid #E6E6E6;">
                            {{item.name}}
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="200" trigger="hover">
                                    <div>创建信息：</div>{{tools.formatDate(item.createDate, 'yyyy-MM-dd hh:mm:ss')}}
                                    <div>描述：</div>{{item.description}}
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="roleEdit">
                                <img src="../../assets/images/systemConfiguration/user13.png" class="mg_right_16"
                                    @click="roleLocking">
                                <img src="../../assets/images/systemConfiguration/user14.png" class="" @click="roleDel">
                            </div>
                        </div>
                    </div>
                </el-card>
                <!-- 添加组织按钮 -->
                <el-button type="primary" class="width_100 height_40 flex justify_center align_center" @click="addRole">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加组织</span>
                </el-button>
            </div>
            <!-- 右侧 组织配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 组织配置 标题 -->
                <div class="fn_size20 width_826 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="roleDetailsShow=!roleDetailsShow"></el-button>
                    <span v-if="roleDetailsShow" class="flex justify_center align_center"
                        style="font-weight: bold;">组织-角色配置</span>
                    <span v-if="!roleDetailsShow" class="flex justify_center align_center"
                        style="font-weight: bold;">部门职位-组织配置</span>
                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleDetailsShow=!roleDetailsShow"></el-button>
                </div>
                <!-- 穿梭框 -->

                <div class="width_826 height_100 mg_top_22">
                    <div v-if="roleDetailsShow">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前用户角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="!roleDetailsShow">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前用户角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>

                <!-- 添加按钮 -->
                <!-- <el-button type="primary" class="width_110 height_36 mg_top_30 flex justify_center align_center">添加
                </el-button> -->
            </div>
        </div>

        <!-- 组织管理-弹窗 -->
        <!-- 组织管理--添加组织弹窗 -->
        <el-dialog :visible.sync="dialogVisibleRoleAdd" top='25vh' width="40%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加组织</div>
            <div class="pdr20">
                <!-- pdl30 pdr30 -->
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="名：" prop="fileName">
                        <el-input v-model="fromRole.fileName" placeholder="请输入" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期：" prop="author">
                        <el-date-picker v-model="fromRole.value1" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="fromRole.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 组织管理--编辑 -->
        <el-dialog :visible.sync="dialogVisibleRoleEdit" top='3vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">组织信息配置</div>
            <div class="pdl20 pdr20 flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="角色名：" prop="fileName">
                        <el-input v-model="fromRole.fileName" placeholder="文档名称" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期：" prop="author">
                        <el-date-picker v-model="fromRole.value1" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="fromRole.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- --穿梭框 -->
                <div class="fn_size20 flex justify_between align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="roleStause=!roleStause"></el-button>
                    <div v-if="roleStause" class="fn_size18 color_000000 flex justify_center font_bold">组织-角色配置</div>
                    <div v-if="!roleStause" class="fn_size18 color_000000 flex justify_center font_bold">部门职位-组织配置</div>

                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleStause=!roleStause"></el-button>
                </div>
                <div class="mg_top_20">
                    <div v-if="roleStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色组">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="!roleStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 组织 --结束 -->


    </div>
</template>
<script>
    import {
        userConfigUserList,
        userConfigCreateUser,
        userConfigDeleteUser,
        userConfigUpdateUser,
        userConfigLockUser,
        userConfigQueryRole,
        userConfigQueryUserCorrelationRole,
        userConfigBandingUserAndRole,
        userConfigUnBandingUserAndRole,
        roleConfigCreateRole,
        roleConfigUpdateRole,
        roleConfigDeleteRole,
        userConfigQueryRoleGroup,
        userConfigQueryRoleCorrelationRoleGroup,
        roleConfigBandingRoleGroupAndRole,
        roleConfigUnBandingRoleGroupAndRole,
        userConfigQueryOrganization,
        userConfigQueryRoleCorrelationOrganization,
        roleConfigBandingOrganizationAndRole,
        roleConfigUnBandingOrganizationAndRole,
        roleConfigCreateRoleGroup,
        roleConfigUpdateRoleGroup,
        roleConfigDeleteRoleGroup,
        userConfigQueryPermission,
        userConfigQueryRoleGroupCorrelationPermission,
        roleConfigBandingPermissionAndRoleGroup,
        roleConfigUnBandingPermissionAndRoleGroup,
        userConfigQueryPage,
        userConfigQueryRoleGroupCorrelationPage,
        roleConfigBandingPageAndRoleGroup,
        roleConfigUnBandingPageAndRoleGroup,
        userConfigGenderSelect,
        userConfigJobSelect,
        userConfigDepartmentSelect
    } from "@/api/api_systemConfig";
    export default {
        data() {
            const generateData = _ => {
                const data = [];
                for (let i = 1; i <= 15; i++) {
                    data.push({
                        key: i,
                        label: `备选项 ${ i }`,
                        disabled: i % 4 === 0
                    });
                }
                return data;
            };
            return {
                // data: generateData(),
                value1: [],
                labelWidth: '90px',
                labelWidth2: '90px',
                tabIndex: 0,
                newFrom: {},
                fromRole: {}, //角色管理-from
                addFrom: {},
                updateForm: {
                    userID: '',
                    gender: '',
                    jobTitle: '',
                    phone: '',
                    email: '',
                    departmentID: '',
                    leaveFlag: '',
                    lockFlag: '',
                    userPassword: '',
                    description: ''
                },
                detailForm: {
                    userName: '',
                    gender: '',
                    jobTitle: '',
                    workNumber: '',
                    userAccount: '',
                    phone: '',
                    email: '',
                    departmentID: '',
                    leaveFlag: '',
                    lockFlag: '',
                    lockDate: '',
                    userPassword: '',
                    description: '',
                    userID: ''
                },
                lockDateReadOnly: false,
                dialogVisible: false, //新增用户弹出框 打开/关闭 控制标志
                dialog2Visible: false, //修改用户弹出框 打开/关闭 控制标志
                dialog3Visible: false, //查看用户弹出框 打开/关闭 控制标志
                dialogVisibleRoleAdd: false, //角色管理-新增角色弹窗
                dialogVisibleRoleEdit: false,
                dialogVisibleRoleGroupAdd: false,
                dialogVisibleRoleGroupEdit: false,
                tabArray: [{
                        id: 0,
                        label: '用户管理'
                    },
                    {
                        id: 1,
                        label: '用户组'
                    }, {
                        id: 2,
                        label: '角色管理'
                    },
                    //  {
                    //     id: 2,
                    //     label: '权限分配'
                    // },

                    //  {
                    //     id: 4,
                    //     label: '页面管理'
                    // },
                    {
                        id: 3,
                        label: '组织'
                    }
                ],
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                sex: [],
                jobArray: [],
                departmentArray: [],
                value: '',
                tableData: [],
                multipleSelection: [],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                deleteNum: '',
                rulesFrom: {},
                // 角色管理-开始
                roleArray: [], // 角色列表
                userHaveRoleArray: [], // 用户已有角色列表
                userNotHaveRoleArray: [], // 用户没有角色列表
                // 角色管理-结束
                // 角色组-开始
                roleGroupArray: [],
                // 角色组-结束
                // 穿梭框-开始
                buttonSize: 'large',
                nowSelectData: [], // 左边选中列表数据
                nowSelectRightData: [], // 右边选中列表数据    
                departmentAndMeterData: [{
                    meter_name: '系统管理员',
                }, {
                    meter_name: '设备经理',
                }, {
                    meter_name: '分厂厂长',
                }, {
                    meter_name: '集团',
                }, {
                    meter_name: '工段长',
                }, {
                    meter_name: '工艺技术部',
                }, {
                    meter_name: '维修班长',
                }], //系统所有角色（左侧）(数据格式自定义或者从后台获取)    
                otherMeterData: [], //当前用户角色（右侧）（数据格式自定义或者从后台获取）
                //穿梭框-结束
                query: {
                    "typeID": 1,
                    "text": ''
                },
                queryList: [],
                lockUserID: '',
                roleDetailsShow: true,
                roleStause: true,
                roleGroupDetailsShow: true,
                userID: '',
                fromRoleGroup: {},


                //用户管理 开始


                //用户管理 结束
            };
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            };
            this.userConfigUserList();
            // this.userConfigBandingUserAndRole(); // 已调通 绑定用户和角色
            // this.userConfigUnBandingUserAndRole(); // 已调通 解绑用户和角色
            // this.roleConfigCreateRole(); // 已调通 创建角色
            // this.roleConfigUpdateRole(); // 已调通 更新角色
            // this.roleConfigDeleteRole(); // 已调通 删除角色

            // this.userConfigQueryRoleCorrelationRoleGroup(); // 已调通 查询角色关联的角色组
            // this.roleConfigBandingRoleGroupAndRole(); // 已调通 绑定角色和角色组
            // this.roleConfigUnBandingRoleGroupAndRole(); // 已调通 解绑角色和角色组
            // this.userConfigQueryOrganization(); // 已调通 查询系统组织
            // this.userConfigQueryRoleCorrelationOrganization(); // 已调通 查询角色关联的组织
            // this.roleConfigBandingOrganizationAndRole(); // 已调通 绑定角色和组织
            // this.roleConfigUnBandingOrganizationAndRole(); // 已调通 解绑角色和组织
            // this.roleConfigCreateRoleGroup(); // 已调通 添加角色组
            // this.roleConfigUpdateRoleGroup(); // 已调通 更新角色组
            // this.roleConfigDeleteRoleGroup(); // 已调通 删除角色组
            // this.userConfigQueryPermission(); // 已调通 查询系统权限
            // this.userConfigQueryRoleGroupCorrelationPermission(); // 已调通 查询角色组关联的权限
            // this.roleConfigBandingPermissionAndRoleGroup(); // 已调通 绑定角色组和权限
            // this.roleConfigUnBandingPermissionAndRoleGroup(); // 已调通 解绑角色组和权限
            // this.userConfigQueryPage(); // 已调通 查询系统页面
            // this.userConfigQueryRoleGroupCorrelationPage(); // 已调通 查询角色组关联的页面
            // // this.roleConfigBandingPageAndRoleGroup(); // 已调通 绑定角色组和页面 接口调用成功但是实际并没有效果
            // // this.roleConfigUnBandingPageAndRoleGroup(); // 已调通 解绑角色组和页面
            this.userConfigGenderSelect(); // 已调通 性别选择
            this.userConfigJobSelect(); // 已调通 职位选择
            this.userConfigDepartmentSelect(); // 部门选择
        },
        methods: {
            // 用户管理接口--开始
            //时间格式转化显示
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            // 用户管理页面，点击“查询”按钮
            queryUser() {
                this.userConfigUserList();
            },
            // 部门选择
            async userConfigDepartmentSelect() {
                var param = {}
                const res = await userConfigDepartmentSelect(param);
                this.departmentArray = res.data.rows
            },
            // 用户信息清单-分页筛选
            async userConfigUserList() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (this.value != '') {
                    param.departmentID = this.value.join(',')
                }
                const res = await userConfigUserList(param);
                this.queryList = res.data.rows;
                this.pageQuantity = res.data.pageQuantity;
            },
            // 用户管理接口--结束

            // 用户组接口--开始
            // 用户组接口--结束

            // 角色管理接口--开始
            // 角色管理接口--结束

            // 组织接口--开始
            // 组织接口--结束




            //增加按钮
            add() {
                //将原有数据清空，以备用来新增 用户信息
                //this.addFrom = {};
                this.dialogVisible = true;
            },
            onAdd() {

            },
            // 增加--添加按钮
            trueadd() {
                this.userConfigCreateUser();
            },
            async userConfigCreateUser() {
                this.addFrom.userID = this.userID;
                var param = {
                    userName: this.addFrom.userName,
                    gender: this.addFrom.gender,
                    jobTitle: this.addFrom.jobTitle,
                    workNumber: this.addFrom.workNumber,
                    userAccount: this.addFrom.userAccount,
                    phone: this.addFrom.phone,
                    email: this.addFrom.email,
                    departmentID: this.addFrom.departmentID,
                    leaveFlag: this.addFrom.leaveFlag,
                    lockFlag: this.addFrom.lockFlag,
                    lockDate: this.addFrom.lockDate,
                    userPassword: this.addFrom.userPassword,
                    description: this.addFrom.description
                };
                const res = await userConfigCreateUser(param);
                if (res && res.data && res.data.result) {
                    this.dialogVisible = false;
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.userConfigUserList();
                    this.addFrom = {}; // 新增用户成功后清空表单，否则会有缓存
                } else {
                    this.$message({
                        showClose: true,
                        message: '添加失败',
                        type: 'error',
                    });
                }
            },
            // 删除
            delTableData(userID) {
                this.deleteNum = userID;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 调用删除接口
                    this.userConfigDeleteUser();
                }).catch(() => {});
            },
            // 调用删除接口，实现删除
            async userConfigDeleteUser() {
                var param = {
                    userID: this.deleteNum
                }
                const res = await userConfigDeleteUser(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserList();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //编辑（修改）
            //点击‘编辑’按钮 触发方法
            gotoModify(temp) {
                this.updateForm.userID = temp.userID;
                this.updateForm.gender = temp.gender;
                this.updateForm.jobTitle = temp.jobTitle;
                this.updateForm.phone = temp.phone;
                this.updateForm.email = temp.email;
                this.updateForm.departmentID = temp.departmentID;
                this.updateForm.leaveFlag = temp.leaveFlag;
                this.updateForm.lockFlag = temp.lockFlag;
                this.updateForm.userPassword = temp.userPassword;
                this.updateForm.description = temp.userPassword;

                // 打开编辑窗口
                this.dialog2Visible = true;
                this.userConfigQueryRole(this.updateForm.userID); // 已调通 查询角色列表
                this.userConfigQueryUserCorrelationRole(this.updateForm.userID); // 已调通 查询用户关联的角色
            },
            //点击编辑窗口中的 ‘确定’ 触发方法
            trueModify() {
                //调用 修改用户方法
                this.userConfigUpdateUser();
            },
            // 修改用户方法
            //调用 UserConfigUpdateUser 接口，实现修改用户功能 
            async userConfigUpdateUser() {
                //封装参数
                var param = {
                    userID: this.updateForm.userID,
                    gender: this.updateForm.gender,
                    jobTitle: this.updateForm.jobTitle,
                    phone: this.updateForm.phone,
                    email: this.updateForm.email,
                    departmentID: this.updateForm.departmentID,
                    leaveFlag: this.updateForm.leaveFlag,
                    lockFlag: this.updateForm.lockFlag,
                    userPassword: this.updateForm.userPassword,
                    description: this.updateForm.description
                }
                console.log(param);
                const res = await userConfigUpdateUser(param);
                console.log(param);

                if (res && res.data && res.data.result) {
                    this.dialog2Visible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserList();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //锁定
            //点击‘锁定’按钮时触发的方法
            lock(id) {
                this.lockUserID = id;
                this.$confirm('此操作为锁定用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用锁定方法
                    this.userConfigLockUser();
                }).catch(() => {});

            },
            // 锁定方法
            // 调用 锁定userConfigLockUser接口 实现锁定功能
            async userConfigLockUser() {
                // 封装参数
                var param = {
                    userID: this.lockUserID
                };
                const res = await userConfigLockUser(param);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                } else {
                    this.$message.error(res.message);
                }
                // 调用分页查询，刷新操作之后的页面
                this.userConfigUserList();
            },
            //查看
            //点击‘查看’按钮时触发的方法
            look(temp) {
                //更新查看数据
                console.log(temp)
                this.detailForm = temp;
                //打开查看弹窗
                this.dialog3Visible = true;
            },
            //点击查看dialog中的‘确定’按钮时触发的方法
            closedialog3() {
                this.dialog3Visible = false;
            },
            //添加
            /*             async userConfigCreateAndUpdateUser() {
                            var param = {
                                userName: this.newFrom.userName,
                                gender: this.newFrom.gender,
                                jobTitle: this.newFrom.jobTitle,
                                workNumber: this.newFrom.work_number,
                                userAccount: this.newFrom.user_account,
                                phone: this.newFrom.phone,
                                email: this.newFrom.email,
                                departmentID: this.newFrom.department_id,
                                leaveFlag: this.newFrom.leave_flag,
                                lockFlag: this.newFrom.lock_flag,
                                lockDate: this.newFrom.lock_date,
                                userPassword: this.newFrom.userPassword,
                                description: this.newFrom.description,
                                userID: this.newFrom.userID,
                            };
                            console.log(param);
                            const res = await userConfigCreateAndUpdateUser(param);
                            console.log(res);
                            if (res && res.data && res.data.result) {
                                this.dialogVisible = false;
                                // this.
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: '添加失败',
                                    type: 'error',
                                });
                            }
                         },
                         */
            changeTab(id) {
                this.tabIndex = id
                this.currentPage = 1;
                if (this.tabIndex == 1) { // 角色管理
                    this.userConfigQueryRole(); // 已调通 查询角色列表
                    this.userConfigQueryUserCorrelationRole(); // 已调通 查询用户关联的角色
                }
                if (this.tabIndex == 3) { // 角色组
                    this.userConfigQueryRoleGroup(); // 已调通 查询系统角色组
                }
            },
            handleSelectionChangeDay(val) {
                console.log(val)
                // console.log(val.join())
                this.multipleSelection = val;
                // console.log(this.multipleSelection.join(','))
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.userConfigUserList();
            },

            // 角色管理 -- 开始
            addRole() {
                this.dialogVisibleRoleAdd = true;
                // this.dialogVisibleRole = true
            },
            // 用户-角色配置，绑定
            addUserRole() {
                var id = [];
                for (let i = 0; i < this.nowSelectData.length; i++) {
                    id.push(this.nowSelectData[i].id)
                    var idStr = id.join(",");
                }
                this.userConfigBandingUserAndRole(this.updateForm.userID, idStr);
            },
            // 用户-角色配置，解绑
            removeUserRole() {
                var id = [];
                for (let i = 0; i < this.nowSelectRightData.length; i++) {
                    id.push(this.nowSelectRightData[i].id)
                    var idStr = id.join(",");
                    console.log(idStr)
                }
                this.userConfigUnBandingUserAndRole(this.updateForm.userID, idStr);
            },
            // 查询系统角色
            async userConfigQueryRole(userID) {
                var param = {
                    userID: userID,
                }
                const roleArrayRes = await userConfigQueryRole(param);
                if (roleArrayRes && roleArrayRes.data) {
                    this.userNotHaveRoleArray = roleArrayRes.data;
                } else {
                    // 若查询失败，将数组清空，页面展示“暂无数据”（防止查询失败时，展示的数据是上一个窗口的数据）
                    this.userNotHaveRoleArray = [];
                }
            },
            // 查询用户关联的角色
            async userConfigQueryUserCorrelationRole(userID) {
                var param = {
                    userID: userID,
                }
                const res = await userConfigQueryUserCorrelationRole(param);
                if (res && res.data) {
                    this.userHaveRoleArray = res.data;
                } else {
                    // 若查询失败，将数组清空，页面展示“暂无数据”（防止查询失败时，展示的数据是上一个窗口的数据）
                    this.userHaveRoleArray = [];
                }
            },
            // 绑定用户和角色
            async userConfigBandingUserAndRole(userID, id) {
                var param = {
                    userID: userID,
                    roleID: id
                }
                const res = await userConfigBandingUserAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRole(this.updateForm.userID);
                    this.userConfigQueryUserCorrelationRole(this.updateForm.userID);
                } else {
                    this.$message.error(res.message);
                }
            },
            // 解绑用户和角色
            async userConfigUnBandingUserAndRole(userID, id) {
                var param = {
                    userID: userID,
                    roleID: id
                }
                const res = await userConfigUnBandingUserAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRole(this.updateForm.userID);
                    this.userConfigQueryUserCorrelationRole(this.updateForm.userID);
                } else {
                    this.$message.error(res.message);
                }
            },
            // 添加角色
            async roleConfigCreateRole() {
                var param = {
                    roleName: 'lxs测试新增角色2',
                    description: 'lxs测试新增角色222',
                    userID: this.userID
                }
                const selectRes = await roleConfigCreateRole(param);
            },
            // 更新角色
            async roleConfigUpdateRole() {
                var param = {
                    roleID: 'role_f130dbc6-23e7',
                    roleName: 'lxs测试新增角色1-update',
                    description: 'lxs测试新增角色222-update'
                }
                const selectRes = await roleConfigUpdateRole(param);
            },
            // 删除角色
            async roleConfigDeleteRole() {
                var param = {
                    roleID: 'role_02d06039-ebd8'
                }
                const selectRes = await roleConfigDeleteRole(param);
            },
            // 查询系统角色组
            async userConfigQueryRoleGroup() {
                var param = {
                    roleID: 'role_zc',
                    // userID: this.userID
                }
                const roleGroupRes = await userConfigQueryRoleGroup(param);
                this.roleGroupArray = roleGroupRes.data;
            },
            // 查询角色关联的角色组
            async userConfigQueryRoleCorrelationRoleGroup() {
                var param = {
                    roleID: 'role_zc',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryRoleCorrelationRoleGroup(param);
            },
            // 绑定角色和角色组
            async roleConfigBandingRoleGroupAndRole() {
                var param = {
                    roleID: 'role_zc',
                    roleGroupID: 'rolegroup_7971-bb9f-31e5'
                }
                const selectRes = await roleConfigBandingRoleGroupAndRole(param);
            },
            // 解绑角色和角色组
            async roleConfigUnBandingRoleGroupAndRole() {
                var param = {
                    roleID: 'role_zc',
                    roleGroupID: 'rolegroup_aa'
                }
                const selectRes = await roleConfigUnBandingRoleGroupAndRole(param);
            },
            // 查询系统组织
            async userConfigQueryOrganization() {
                var param = {
                    roleID: 'role_zz',
                    // userID: 'rolegroup_aa'
                }
                const selectRes = await userConfigQueryOrganization(param);
            },
            // 查询角色关联的组织
            async userConfigQueryRoleCorrelationOrganization() {
                var param = {
                    roleID: 'role_zz',
                    // userID: 'rolegroup_aa'
                }
                const selectRes = await userConfigQueryRoleCorrelationOrganization(param);
            },
            // 绑定角色和组织
            async roleConfigBandingOrganizationAndRole() {
                var param = {
                    roleID: 'role_zz',
                    organizationID: 'P7'
                }
                const selectRes = await roleConfigBandingOrganizationAndRole(param);
            },
            // 解绑角色和组织
            async roleConfigUnBandingOrganizationAndRole() {
                var param = {
                    roleID: 'role_zz',
                    organizationID: 'WS16'
                }
                const selectRes = await roleConfigUnBandingOrganizationAndRole(param);
            },
            // 添加角色组
            async roleConfigCreateRoleGroup() {
                var param = {
                    roleGroupName: 'lxs测试新增角色组2',
                    description: 'lxs测试新增角色组2',
                    userID: this.userID
                }
                const selectRes = await roleConfigCreateRoleGroup(param);
            },
            // 更新角色组
            async roleConfigUpdateRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_2fa9-dc5d-ddf2',
                    roleGroupName: 'lxs测试新增角色组1-update',
                    description: 'lxs测试新增角色组1-update'
                }
                const selectRes = await roleConfigUpdateRoleGroup(param);
            },
            // 删除角色组
            async roleConfigDeleteRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_7bfb-91a0-c90c'
                }
                const selectRes = await roleConfigDeleteRoleGroup(param);
            },
            // 查询系统权限
            async userConfigQueryPermission() {
                var param = {
                    roleGroupID: 'rolegroup_zc',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryPermission(param);
            },
            // 查询角色组关联的权限
            async userConfigQueryRoleGroupCorrelationPermission() {
                var param = {
                    roleGroupID: 'rolegroup_zc',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryRoleGroupCorrelationPermission(param);
            },
            // 绑定角色组和权限
            async roleConfigBandingPermissionAndRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_zc',
                    permissionID: 'permission_724fcf22-121c'
                }
                const selectRes = await roleConfigBandingPermissionAndRoleGroup(param);
            },
            // 解绑角色组和权限
            async roleConfigUnBandingPermissionAndRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_zc',
                    permissionID: 'permission_zz'
                }
                const selectRes = await roleConfigUnBandingPermissionAndRoleGroup(param);
            },
            // 查询系统页面
            async userConfigQueryPage() {
                var param = {
                    roleGroupID: 'rolegroup_1',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryPage(param);
            },
            // 查询角色组关联的页面
            async userConfigQueryRoleGroupCorrelationPage() {
                var param = {
                    roleGroupID: 'rolegroup_1',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryRoleGroupCorrelationPage(param);
            },
            // 绑定角色组和页面
            async roleConfigBandingPageAndRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_1',
                    pageID: 'page_3'
                }
                const selectRes = await roleConfigBandingPageAndRoleGroup(param);
            },
            // 解绑角色组和页面
            async roleConfigUnBandingPageAndRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_1',
                    pageID: 'page_1'
                }
                const selectRes = await roleConfigUnBandingPageAndRoleGroup(param);
            },
            // 性别选择
            async userConfigGenderSelect() {
                var param = {}
                const res = await userConfigGenderSelect(param);
                this.sex = res.data;
            },
            // 职位选择
            async userConfigJobSelect() {
                var param = {}
                const res = await userConfigJobSelect(param);
                this.jobArray = res.data.rows
            },
            // 角色管理 -- 结束

            //穿梭框-开始-----------
            tableHeaderColor({
                row,
                column,
                rowIndex,
                columnIndex
            }) { //改表头颜色
                if (rowIndex === 0) {
                    // return 'background-color: #F3F3F3;color: #666;'
                    return 'background-color: #E2F0FE;'
                }
            },
            // 左侧数据全选
            checkAll(val) {
                console.log(val)
                this.nowSelectData = val;
            },
            // 右侧数据全选
            checkRightAll(val) {
                console.log(val)
                this.nowSelectRightData = val;
            },
            // 移除
            handelSelect() {
                this.otherMeterData = this.handleConcatArr(this.otherMeterData, this.nowSelectData); //右侧数据=右侧数据+左侧选中的数据
                this.handleRemoveTabList(this.nowSelectData, this.departmentAndMeterData); //移除左侧选中的数据（左侧选中的数据,左侧数据）
                this.nowSelectData = []; //左侧选中数据为空
            },
            // 添加
            handleRemoveSelect() {
                this.departmentAndMeterData = this.handleConcatArr(this.departmentAndMeterData, this
                    .nowSelectRightData);
                this.handleRemoveTabList(this.nowSelectRightData, this.otherMeterData);
                this.nowSelectRightData = [];
            },
            //右侧数据=右侧数据+左侧选中的数据
            handleConcatArr(a, b) {
                let arr = [];
                arr = arr.concat(a, b);
                return arr; //arr=右侧数据+左侧选中的数据
                // console.log(arr);
            },
            //移除左侧选中的数据
            handleRemoveTabList(isNeedArr, originalArr) { //isNeedArr为左侧选中的数据，originalArr为左侧数据
                if (isNeedArr.length && originalArr.length) { //数据不为空
                    for (let i = 0; i < isNeedArr.length; i++) { //遍历左侧选中的数据
                        for (let k = 0; k < originalArr.length; k++) { //遍历左侧所有的数据
                            // if (isNeedArr[i]["id"] === originalArr[k]["id"]) {//如果左侧选中的数据=左侧其中的一个数据
                            if (isNeedArr[i]["meter_name"] === originalArr[k]["meter_name"]) {
                                originalArr.splice(k, 1); //将左侧所有数据中选中的那个数据删除
                                //splice可删除从 index 处开始的零个或多个元素,并且用参数列表中声明的一个或多个值来替换那些被删除的元素
                            }
                        }
                    }
                }
            },
            //穿梭框-结束-------------


            changeLockDateReadOnly() {
                if (this.addFrom.lockFlag == 0) {
                    this.lockDateReadOnly = true;
                } else {
                    this.lockDateReadOnly = false;
                }
            },


            // 角色管理---开始
            roleEdit() {
                this.dialogVisibleRoleEdit = true
            },
            roleLocking() {
                this.$confirm('此操作为锁定该角色, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            roleDel() {
                this.$confirm('此操作将永久删除该角色组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 角色管理---结束
            // 
            addRoleGroup() {
                this.dialogVisibleRoleGroupAdd = true
            },
            roleGroupEdit() {
                this.dialogVisibleRoleGroupEdit = true;
            },

            roleGroupDel() {
                this.$confirm('此操作将永久删除该角色组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
        }
    }
</script>
<style scoped>
    .tab {
        width: 140px;
        background: #E6E6E6;
        color: #1A1A1A;
        margin-right: 2px;
    }

    .tab_active {
        background: #009BFD;
        color: #FFFFFF;
    }

    .select_btn {
        width: 120px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .check_btn {
        background-color: #EBEBEB;
        border-color: #EBEBEB;
    }

    .check_btn:hover {
        background-color: #009BFD;
        border-color: #009BFD;
        color: #FFFFFF;
    }

    .width_40 {
        width: 40px;
    }

    .addBtn {
        width: 114px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .shuttleBox {
        height: 320px;
    }
</style>
<style lang="less" scoped>
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 170px;
    }

    ::v-deep .el-input__suffix {
        display: flex;
        align-content: center;
    }

    ::v-deep .el-input__suffix-inner {
        display: flex;
        align-content: center;
    }

    ::v-deep .el-card__header {
        background-color: #E2F0FE;
        height: 40px;
        display: flex;
        align-items: center;
    }

    ::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label {
        display: flex;
    }

    /* // ::v-deep .el-checkbox__input {
    //     display: flex;
    // } */

    ::v-deep .el-transfer-panel {
        width: 250px;
    }

    ::v-deep .el-form-item {
        margin-bottom: 12px;
    }

    /* //  */
    ::v-deep .el-transfer {
        display: flex;
    }

    ::v-deep .el-transfer__buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    ::v-deep .el-button+.el-button {
        margin: 0px;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }

    ::v-deep .el-popover__reference-wrapper {
        display: flex;
        align-items: center;
    }
</style>