<!-- 分析报表-工段报表-KPI -->
<template>
    <div class="page_container ">
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">XXX公司XXX指标 XX度报表</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 pdt_10">
            <!-- select下拉框 -->
            <div class="fn_size16 flex pdt_20 flex justify_between">
                <div>
                    <el-select v-model="value" placeholder="OEE" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="value" placeholder="班组" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                 <el-date-picker v-model="value1" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" :picker-options="pickerOptions">
                    </el-date-picker>
                <!-- <el-select v-model="value" placeholder="时间：2021-12">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> -->
                </div>
                <div>
                    <el-button type="primary" class="alarmQuerySelectButton flex align_center justify_center">导出
                    </el-button>
                </div>
            </div>
            <!-- table -->
            <div class="height_500">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" height="100%"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="date" label="名称" width=""   show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="1月" width=""  ></el-table-column>
                    <el-table-column prop="date" label="2月" width=""  ></el-table-column>
                    <el-table-column prop="name" label="3月" width=""  ></el-table-column>
                    <el-table-column prop="address" label="4月" width="" show-overflow-tooltip  ></el-table-column>
                    <el-table-column prop="address" label="5月" show-overflow-tooltip  ></el-table-column>
                    <el-table-column prop="name" label="6月"  ></el-table-column>
                    <el-table-column prop="name" label="7月"  ></el-table-column>
                    <el-table-column prop="name" label="8月"  ></el-table-column>
                    <el-table-column prop="name" label="9月"  ></el-table-column>
                    <el-table-column prop="name" label="10月"  ></el-table-column>
                    <el-table-column prop="name" label="11月"  ></el-table-column>
                    <el-table-column prop="date" label="12月"  ></el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_between align_start mg_right_30 mg_left_30">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10  总记录：120</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div>

        </div>
        <!-- 图表 -->
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">图表</div>
        </div>
        <!-- echarts图 -->
        <div class="bgc_FFFFFF pdl30 pdr30 pdt_10" style="height: 500px;">
            <div id="chartColumn" class=" height_100 width_100"></div>
        </div>
    </div>
</template>
<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import * as echarts from 'echarts';
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                value: '',
                value1:null,
                options: [],
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }],
            }
        },
        mounted() {
            this.drawShape();
        },
        methods: {
            drawShape() {
                /**维度趋势选择 */
                this.chartColumn = echarts.init(document.getElementById('chartColumn'));
                this.chartColumn.setOption({
                    legend: {
                        x: 'center',
                        y: 'bottom',
                        bottom: '10%', //距离下边距
                    },
                    tooltip: {},
                    grid: { //设置中间内容距离div的距离，也就是内边距
                        left: '3%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true,
                        /* width: 'auto',
                        height: 'auto', */
                    },
                    dataset: {
                        source: [
                            ['product', '1#设备', '2#设备', '3#设备'],
                            ['1月', 220000, 160000, 180000],
                            ['2月', 220000, 160000, 150000],
                            ['3月', 360000, 220000, 200000],
                            ['4月', 480000, 280000, 200000],
                            ['5月', 600000, 400000, 300000],
                            ['6月', 730000, 580000, 390000],
                            ['7月', 820000, 580000, 500000],
                            ['8月', 890000, 520000, 590000],
                            ['9月', 790000, 430000, 500000],
                            ['10月', 600000, 370000, 580000],
                            ['11月', 460000, 210000, 230000],
                            ['12月', 360000, 180000, 490000],
                        ]
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        axisTick: {
                            alignWithLabel: true //x轴左右留白
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#E3E4E6',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#808080',
                            fontSize: 12,
                        },
                    },
                    yAxis: {
                        min: 0, //y轴的最小值
                        max: 1000000, //y轴最大值 
                        interval: 200000, //值之间的间隔
                    },
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [{
                            type: 'bar',
                            color: ['#5B8FF9'],
                        },
                        {
                            type: 'bar',
                            color: ['#5AD8A6'],
                        },
                        {
                            type: 'bar',
                            color: ['#5D7092'],
                        }
                    ]
                });
            }
                
        }
    }

    
</script>

<style scoped>
    .colHeight {
        height: 720px;
    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }
</style>