<!-- A设备管理-备件管理-备件领用-详情 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">领用信息</div>
            <el-form :inline="true" :model="changeRow" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="工单编号：">
                    <el-input v-model="changeRow.orderID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="申请时间：">
                    <!-- <el-input v-model="changeRow.applyTime" placeholder="" class="width_180" disabled></el-input> -->
                    <el-date-picker prefix-icon="el-icon-date" v-model="changeRow.applyTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="申请人：">
                    <el-input v-model="changeRow.applyPerson" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="申请仓库：" >
                            <!-- <el-input v-model="changeInline.warehouse" placeholder="申请仓库" class="width_180"></el-input> -->
                    <el-select v-model="changeRow.warehouse"  placeholder="" class="width_180">
                        <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申请部门：">
                    <el-input v-model="changeRow.department" placeholder="" class="width_180" ></el-input>
                </el-form-item>
                <el-form-item label="工单状态：">
                    <el-input v-model="changeRow.orderStatus" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="领用总价：">
                    <el-input v-model="changeRow.totalPrice" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="changeRow" label-width="110px" style="width:65%; ">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="3" v-model="changeRow.remarks" resize="none" ></el-input>
                </el-form-item>
            </el-form>

            <!-- 第二部分 -->
            <div class="box_head1 width_100">备件信息</div>
            <!-- table -->
            <div class="height_300" style="width: 65%;">
            <el-table ref="multipleTable" :data="formDetail" tooltip-effect="dark" class="mg_top_10 "
                @selection-change="handleSelectionChange" height="100%" border style="width:100%">
                <el-table-column type="index" label="序号" width="80"></el-table-column>
                <!-- <el-table-column prop="ID" label="id" width=""></el-table-column> -->
                <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sparePartID" label="备件编号" width=""></el-table-column>
                <el-table-column prop="sparePartName" label="备件名称" width=""></el-table-column>
                <el-table-column prop="sparePartModel" label="备件型号" width=""></el-table-column>
                <el-table-column prop="unit" label="单位" width=""></el-table-column>
                <el-table-column prop="applyQuan" label="申请数量" width=""></el-table-column>
                <el-table-column prop="unitPrice" label="单价" width=""></el-table-column>
                <el-table-column prop="totalPrice" label="总价" width=""></el-table-column>
                <el-table-column prop="remarks" label="备注" width=""></el-table-column>
            </el-table>
            </div>
            <!-- <div class="flex justify_between align_center" style="width: 65%;">
                <div class="color_808080">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->

            <div class="flex mg_top_30 mg_bottom_30">
                <el-button type="primary" class="width_140" @click="sbumit">修改</el-button>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        sparePartOutWarehouseQueryLimit,
        sparePartOutWarehouseMultiAuthQueryLimit,
        sparePartOutWarehouseDetailQuery,
        sparePartOutWarehouseDetailQueryLimit,
        sparePartOutWarehouseQuery,
        sparePartPickDetailQueryLimit,
        sparePartWarehouseQuery,
        sparePartPickUpdate
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                tableData: [],
                formInline: {},
                detailID: '',
                changeRow:{},
                formDetail:[],
                warehouseSelectList:[],
                updatedResult:'',
                

            }
        },
        mounted() {
            // this.formInline = this.$route.query.data;
            // if (localStorage.getItem('oderId')) {
            //     this.detailID = localStorage.getItem('oderId');
            //     this.sparePartOutWarehouseQuery();
            //     // this.sparePartOutWarehouseDetailQuery();
            // }
            this.sparePartWarehouseQuery();
             if (localStorage.getItem('spareEditRow')!=null) {
                this.changeRow = JSON.parse(localStorage.getItem('spareEditRow'));
                // this.formInline = this.spareDetailRow
                // this.sparePartOutWarehouseDetailQuery();
                console.log(this.changeRow,'aa');
                this.sparePartPickDetailQueryLimit()
            }
        },
        methods: {
            // sbumit() {
            //     this.$router.go(-1);
            // },
  
             //详细 分页
            async sparePartPickDetailQueryLimit(){
                var param = {
                    orderID:this.changeRow.orderID,
                    pageSize:this.pageSize1,
                    pageNumber:this.currentPage1,                  
                }
                const selectRes =await sparePartPickDetailQueryLimit(param)
                this.pageQuantity1=selectRes.pageQuantity
                this.formDetail= selectRes.rows
                // console.log(this.formDetail,"aa");

            },
             //仓库下拉框
            async sparePartWarehouseQuery(){
                //封装参数
                var param = {
                    userID:this.userID,
                };
                //调用接口
                const selectRes = await sparePartWarehouseQuery(param);

                //处理返回值
                if(selectRes){//如果有返回值
                    this.warehouseSelectList = selectRes.data;
                }
            },
            async sparePartPickUpdate(){
                var param = {
                    ID:this.changeRow.ID,
                    warehouse:this.changeRow.warehouse,
                    department:this.changeRow.department,
                    remarks:this.changeRow.remarks,
                    totalPrice:this.changeRow.totalPrice,
                }
                const selectRes =await sparePartPickUpdate(param)
                this.updatedResult = selectRes.data.result
                if(this.updatedResult){
                    this.$message({
                        message:selectRes.message,
                        type:"success"
                    })
                    this.dialogVisibleChange = false
                }else{
                    this.$message({
                        message:selectRes.message,
                        type:"warning"
                    })
                    this.dialogVisibleChange = false
                }
                this.multipleSelection = []
                this.sparePartPickMultiAuthQueryLimit()

            },
            sbumit(){
                this.sparePartPickUpdate();
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },
            // table选择
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
        }
    }
</script>
<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>