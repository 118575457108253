<!-- 维修管理-维修工单-详情 -->

<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">基本信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_10 mg_top_40 details_box"> -->
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_40 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="工单编号：">
                    <el-input v-model="formInline.orderID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item label="公司编号：" class=" ">
                    <el-input v-model="formInline.projectID" placeholder="公司编号" class="width_180" disabled></el-input>
                </el-form-item> -->
                <!-- </el-form>
            <el-form :inline="true" :model="formInline" label-width="90px" class=" flex"> -->
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备类型：" class=" ">
                    <el-input v-model="formInline.deviceType_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-input v-model="formInline.deviceModel" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备所属部门：" class=" ">
                    <el-input v-model="formInline.deviceDepartment" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>

                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.deviceLocation" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- </div> -->
            <div class="box_head1 width_100">故障信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_20 mg_top_40 details_box"> -->
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">


                <!-- </el-form>
                <el-form :inline="true" class="flex justify_start" label-width="90px"> -->
                <el-form-item label="记录人：" class="">
                    <el-input v-model="formInline.oprPerson" class="width_180" disabled></el-input>
                </el-form-item>

                <el-form-item label="故障代码：" class="">
                    <el-input v-model="formInline.failureCode" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="故障部位：" prop="user">
                    <el-input v-model="formInline.failureDevPart" class="width_180" disabled></el-input>
                </el-form-item>



                <el-form-item label="故障等级：">
                    <el-input v-model="formInline.failureLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.failureLevel" placeholder="" class="width_180 " disabled>
                        <el-option v-for="item in levelData" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="故障类别：">
                     <el-input v-model="formInline.failureType_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.failureType" placeholder="" class="width_180" size="medium "
                        disabled>
                        <el-option v-for="item in typeData" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <!-- <el-form-item label="故障等级：">
                    <el-input v-model="formInline.failureLevel" placeholder="故障等级" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="故障类型：" class=" ">
                    <el-input v-model="formInline.failureType" placeholder="故障类型" class="width_180" disabled></el-input>
                </el-form-item> -->
                <el-form-item label="记录时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.oprTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="故障时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.failureTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="故障原因：" prop="">
                    <el-input type="textarea" :rows="5" v-model="formInline.failureReason" resize="none" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="故障描述：" prop="">
                    <el-input type="textarea" :rows="5" v-model="formInline.failureDesc" resize="none" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- </div> -->
            <div class="box_head1 width_100">处理情况</div>
            <!-- <div class="flex flex_column align_center mg_bottom_20 mg_top_40 details_box"> -->
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="当前审批顺序：" class=" ">
                    <el-input v-model="formInline.authRoleName" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="维修组：">
                    <el-select v-model="formInline.execGroup" placeholder="" class="width_180" disabled>

                        <el-option v-for="(item,index) in ExecGroup" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维修人：" class=" ">
                    <el-input v-model="formInline.execPerson" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execPerson" placeholder="" class="width_180" disabled collapse-tags>
                        <el-option v-for="(item,index) in personList" :key="index" :label="item.userName"
                            :value="item.userID">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="维修类型：" class=" ">
                     <el-input v-model="formInline.execType_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execType" placeholder="" class="width_180" disabled>
                        <el-option v-for="(item,index) in Category" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>

                <el-form-item label="维修等级：" class=" ">
                     <el-input v-model="formInline.execLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execLevel" placeholder="" class="width_180" disabled>
                        <el-option v-for="item in execLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>

                <el-form-item label="工单状态：">
                    <el-input v-model="formInline.orderStatus_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.orderStatus" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in workOrderStatus" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>

                <el-form-item label="紧急程度：" class=" ">
                    <el-input v-model="formInline.urgenLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.urgenLevel" placeholder="" class="width_180" disabled>
                        <el-option v-for="item in UrgenLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>


                <!-- <el-form-item label="评分等级：" placeholder="">
                    <el-input v-model="formInline.execGrade_name" placeholder="" class="width_180" disabled>
                    </el-input> -->
                    <!-- <el-select v-model="formInline.execGrade" placeholder="" class="width_180" disabled>
                        <el-option v-for="(item,index) in gradingArr" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                <!-- </el-form-item> -->


                <el-form-item label="维修预估花费时间：" class="">
                    <el-input v-model="formInline.execEstimateLen" placeholder="" class="width_180"
                        disabled>
                        <template slot="append">分钟</template>
                    </el-input>
                </el-form-item>

                <el-form-item label="维修时长：" class="">
                    <el-input v-model="formInline.execLenM" placeholder="" class="width_180" disabled>
                       <template slot="append">分钟</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="维修费用：" class="">
                    <el-input v-model="formInline.execFee" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>

                <el-form-item label="派单时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.dispatchTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="指定接单时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.assignTakeTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="接单时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.takeTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="维修开始时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.startTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="维修结束时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.endTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="指定维修结单时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.assignEndTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>

                <!--  <el-form-item label="暂离时间列表：" class="">
                                        
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;"
                                                @click="showPauseTimeList">
                                                查看</el-button>
                                    </el-form-item> -->


                <el-form-item label="验证时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.verifyTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="验证不通过维修开始时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.execRejectStartTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证不通过维修结束时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.execRejectEndTime" type="datetime"
                        :clearable="false" placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否逾期结单：" class="">
                    <el-radio-group v-model="formInline.isEndOverdue" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否逾期接单：" class="">
                    <el-radio-group v-model="formInline.isTakeOverdue" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否停机：" class="">
                    <el-radio-group v-model="formInline.machineStop" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="停机时长：" class="">
                    <el-input type="number" v-model="formInline.stopLen" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否为经验性记录：" class="">
                    <el-radio-group v-model="formInline.isEmpirical" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否委外：" class="">
                    <el-radio-group v-model="formInline.isoOutEntrust" class="width_180 text_align_left" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>

                </el-form-item>
                <el-form-item label="暂离时间列表：" class="">
                    <el-button type="primary" size="medium" class=" mg_left_8"
                        style="background: #03CABE; border: none;" @click="showPauseTimeList">
                        查看</el-button>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="验证备注：" prop="">
                    <el-input type="textarea" :rows="5" v-model="formInline.verifyRemarks" resize="none" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="现场情况：" prop="">
                    <el-input type="textarea" :rows="5" v-model="formInline.spotCondition" resize="none" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="维修描述：" prop="">
                    <el-input type="textarea" :rows="5" v-model="formInline.execDesc" resize="none" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="备注：" prop="">
                    <el-input type="textarea" :rows="5" v-model="formInline.remarks" resize="none" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-dialog title="暂离时间列表" :visible.sync="dialogTableVisible" top='20vh' width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">暂离时间列表</div> -->
                <div class="flex flex_column height_370 pdb_20 mg_top_10">
                    <div class="element">
                        <el-row>
                            <el-col :span="12">暂离开始时间</el-col>
                            <el-col :span="12">暂离结束时间</el-col>
                        </el-row>
                        <el-row class="myRow" v-for="(item,index) in pauseTimeList.continueTime" :key="index">
                            <el-col :span="12">{{item}}</el-col>
                            <el-col :span="12">{{pauseTimeList.pauseTime[index]}}</el-col>
                        </el-row>
                    </div>
                </div>
            </el-dialog>
            <div class="box_head1 width_100 mg_top_20">更换备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30 height_370">
                <el-table :data="sparePartList" style="width: 100%" class="mg_top_20" height="100%">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="unit" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="applyQuan" label="领用数量" width="">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价" width="">
                    </el-table-column>
                    <el-table-column prop="totalPrice" label="总价" width="">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="">
                    </el-table-column>

                </el-table>
            </div>
            <div class="flex mg_top_20 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="colsePage">关闭</el-button>
                </div>
            </div>
        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        repairOrderMultiAuthQueryLimit,
        repairOrderStatusQuery,
        repairOrderExecGradeQuery,
        faultTypeQuery,
        faultLevelQuery,
        repairTypeQuery,
        repairUrgenLevel,
        repairGroupQuery,
        repairExecPersonQuery,
        repairOrderExecLevelQuery,
        repairOrderSparePartQuery,

        repairOrderDispatch,
        repairOrderTake,
        repairOrderExecute,
        repairOrderPause,
        repairOrderContinue,
        repairOrderRepairFinish,
        repairOrderVerify,
        repairOrderMarkEmpirical,
        repairOrderRepairGrade,
        repairOrderExecuteTypeQuery,
        repairOrderFinishTypeQuery,
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                formInline: {

                },
                formLabelWidth: '130px',
                dialogTableVisible: false,
                ID: '',
                pauseTimeList: {},
                ExecGroup: [],
                personList: [],
                UrgenLevel: [],
                Category: [],
                levelData: [],
                typeData: [],
                gradingArr: [],
                execLevel: [],
                sparePartList: [],
                workOrderStatus: [],



            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.repairOrderMultiAuthQueryLimit();
            this.faultTypeQuery();
            this.faultLevelQuery();
            this.repairExecPersonQuery();
            this.repairUrgenLevel();
            this.repairGroupQuery();
            this.repairTypeQuery();
            this.repairOrderExecGradeQuery();
            this.repairOrderExecLevelQuery();
            this.repairOrderStatusQuery();


        },
        methods: {
            async repairOrderMultiAuthQueryLimit() {
                this.ID = window.localStorage.getItem('deitailID')
                console.log("ID", this.ID);
                var parame = {
                    ID: this.ID,
                    userID: this.userID
                }
                const selectRes = await repairOrderMultiAuthQueryLimit(parame);
                this.formInline = selectRes.array[0]
                this.pauseTimeList = JSON.parse(this.formInline.pasueTimeList)
                console.log("11", this.formInline);
                this.repairOrderSparePartQuery()
            },
            // 工单状态
            async repairOrderStatusQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairOrderStatusQuery(param);
                this.workOrderStatus = selectRes.data
            },
            //故障类型下拉框
            async faultTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultTypeQuery(param)
                this.typeData = selectRes.data


            },
            //故障等级下拉框
            async faultLevelQuery() {
                var param = {
                    userID: this.userID
                }
                const selectRes = await faultLevelQuery(param)
                this.levelData = selectRes.data
            },
            // 评分等级
            async repairOrderExecGradeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairOrderExecGradeQuery(param);
                this.gradingArr = selectRes.data
            },

            //班组
            async repairGroupQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairGroupQuery(param);
                this.ExecGroup = selectRes.data;
            },
            //紧急程度
            async repairUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairUrgenLevel(param);
                this.UrgenLevel = selectRes.data;
            },
            //维修人
            async repairExecPersonQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairExecPersonQuery(param);
                this.personList = selectRes.data;
            },
            //维修类型
            async repairTypeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairTypeQuery(param);
                this.Category = selectRes.data
            },
            // 维修等级
            async repairOrderExecLevelQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairOrderExecLevelQuery(param);
                this.execLevel = selectRes.data
            },
            // 备件
            async repairOrderSparePartQuery() {
                console.log('22', this.formInline);
                var param = {
                    orderID: this.formInline.orderID
                }
                const selectRes = await repairOrderSparePartQuery(param);
                this.sparePartList = selectRes.rows;
            },
            confirm() {
                this.dialogTableVisible = true;
                this.$router - 1
            },
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },
            showPauseTimeList() {
                this.dialogTableVisible = true;
            },
        }

    }
</script>
<style scoped>
    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 100%;
        margin-top: 2px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 12px;
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    .element .el-row:first-child {
        background-color: #E2F0FE;
        border-top: 1px #E6E6E6 solid;
    }

    .element .el-row {
        border-bottom: 1px #E6E6E6 solid;
        border-left: 1px #E6E6E6 solid;
        /* color: #FFFFFF; */
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        border-right: 1px #E6E6E6 solid;
        padding: 10px 0px;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        /* // background-color: #FFFFFF;
        // border: none; */
    }

    ::v-deep .el-form-item__label {
        line-height: 24px;
    }

    /* .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;
        color:#73f3ff;
    } */
</style>