<!--  设备管理-备件管理-备件购置-详情 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <!-- 第一部分table -->
            <div class="box_head1 width_100 mg_top_20">保养部位</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" show-summary max-height="250" border
                    :summary-method="getSummaries">
                    <el-table-column type="selection" width="80"></el-table-column>
                    <el-table-column type="index" label="序号" width="80"></el-table-column>
                    <el-table-column prop="name" label="备件编号" width=""></el-table-column>
                    <el-table-column prop="name" label="备件名称" width=""></el-table-column>
                    <el-table-column prop="name" label="规格型号" width=""></el-table-column>
                    <el-table-column prop="amount2" label="库存数" width=""></el-table-column>
                    <el-table-column prop="amount1" label="参考价" width=""></el-table-column>
                    <el-table-column prop="amount2" label="计量单位" width=""></el-table-column>
                    <el-table-column prop="amount1" label="申请采购数量" width=""></el-table-column>
                    <el-table-column prop="amount2" label="预估总费用" width=""></el-table-column>
                    <el-table-column prop="amount1" label="实际采购数量" width=""></el-table-column>
                    <el-table-column prop="amount2" label="实际总费用" width=""></el-table-column>
                </el-table>
            </div>

            <!-- -------------------------------- -->
            <div class="box_head1 width_100 mg_top_20">保养信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="申请人：" class=" ">
                    <el-input v-model="formInline.user" placeholder="试用用户" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="申请部门：" class=" ">
                    <el-input v-model="formInline.user" placeholder="生产部" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="申请到货时间：" class=" ">
                    <el-date-picker v-model="value1" type="date" placeholder="" class="width_180"></el-date-picker>
                </el-form-item>
            </el-form>

                <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="预估总费用：" class=" ">
                    <el-input v-model="formInline.user" placeholder="0" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="实际总费用：" class=" ">
                    <el-input v-model="formInline.user" placeholder="3000" class="width_180"></el-input>
                </el-form-item>
            </el-form>

            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="申请原因：" prop="user">
                    <el-input type="textarea" :rows="3" placeholder="11" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="申请原因：" prop="user">
                    <el-input type="textarea" :rows="3" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form>

            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="状态：" class=" ">
                    <el-input v-model="formInline.user" placeholder="待入库" class="width_180"></el-input>
                </el-form-item>
            </el-form>

            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="意见：" prop="user">
                    <el-input type="textarea" :rows="3" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form>

            <div class="flex mg_top_20 mg_bottom_30">
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                formInline: {
                    user: '',
                    region: ''
                },
                value: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                formLabelWidth: '110px',
                tableData: [{
                    id: '12987122',
                    name: '王小虎',
                    amount1: '234',
                    amount2: '3.2',
                    amount3: 10
                }, {
                    id: '12987123',
                    name: '王小虎',
                    amount1: '165',
                    amount2: '4.43',
                    amount3: 12
                }, {
                    id: '12987124',
                    name: '王小虎',
                    amount1: '324',
                    amount2: '1.9',
                    amount3: 9
                }, {
                    id: '12987125',
                    name: '王小虎',
                    amount1: '621',
                    amount2: '2.2',
                    amount3: 17
                }, {
                    id: '12987126',
                    name: '王小虎',
                    amount1: '539',
                    amount2: '4.1',
                    amount3: 15
                }]
            }
        },
        mounted() {},
        methods: {
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },
            //table合计
            getSummaries(param) {
                const {
                    columns,
                    data
                } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 2) {
                        sums[index] = '总价';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    //console.log(values);//values是各组数据
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            console.log(sums[index]); //values是各组数据
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += ' ';
                    } else {
                        sums[index] = '';
                    }
                });
                return sums;
            }
        }

    }
</script>
<style scoped>
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }
</style>