<!-- 综合看板-集团-道路详情 -->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="pdt_20 pdb_10 flex justify_end align_center">
                <!-- flex justify_center align_center -->
                <!-- <div class="fn_size22 color_00C6FF">
                    <span>接入设备：</span>
                    <span class="color_F6FF00">69 </span>
                    <span>台</span>
                </div> -->
                <div class="flex justify_between" style="width:70%">
                    <div class="topTitle fn_size28 color_FFFFFF">
                        {{dashBoardTitleGroup2}}
                    </div>
                    <div class="fn_size22 color_00C6FF flex align_center">
                        {{currentTime}}
                        <img src="../../assets/images/comprehensive/full.png" class="mg_left_20" alt="">
                    </div>
                </div>

            </div>
            <!-- <div>

            </div> -->
            <el-row :gutter="5">
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">开机率</div>
                        <div id="ecartsGX" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div2">
                        <!-- <div class="">故障趋势(以故障等级)</div> -->
                        <el-carousel indicator-position="outside" :interval="8000" :autoplay="true" height="200px"
                            style="width: 100%;" @change="echartsChangeeJP">
                            <el-carousel-item v-for="(item,index) in dataGZQS" :key="index"
                                style="width: 100%;height: 100%;" class=" flex flex_column align_center">
                                <!-- -->
                                <span>故障趋势(以故障等级) - {{item.name}}</span>
                                <div class="flex justify_center" style="width: 100%;height: 100%;">
                                    <div :id="item.id" style="flex:1;" class="echartsSize"></div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>

                        <!-- <div id="ecartsJP" style="width:100%;height: 100%;"></div> -->
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <!-- <div class="">运维及时完成效率</div> -->
                        <el-carousel indicator-position="outside" :interval="8000" :autoplay="true" height="200px"
                            style="width: 100%;" @change="echartsChangeeMR">
                            <el-carousel-item v-for="(item,index) in dataOMRName" :key="index"
                                style="width: 100%;height: 100%;" class=" flex flex_column align_center">
                                <!-- -->
                                <span>运维及时完成效率 - {{item.name}}</span>
                                <div class="flex justify_center" style="width: 100%;height: 100%;">
                                    <div :id="item.id" style="flex:1;" class="echartsSize"></div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <!-- <div id="echartsOMR" style="width:100%;height: 100%;"></div> -->
                        <!-- dataOMRName -->
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">开机利用率</div>
                        <div id="ecartsKJ" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div2">
                        <!-- <div class="">故障分析(以设备型号统计分析)</div> -->
                        <el-carousel indicator-position="outside" :interval="8000" :autoplay="true" height="200px"
                            style="width: 100%;" @change="echartsChange">
                            <el-carousel-item v-for="(item,index) in echartsCarousel" :key="index"
                                style="width: 100%;height: 100%;" class=" flex flex_column align_center">
                                <!-- -->
                                <span>故障分析(以设备型号统计分析) - {{item.name}}</span>
                                <div class="flex justify_center" style="width: 100%;height: 100%;">
                                    <div :id="item.id" style="flex:1;" class="echartsSize"></div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <!-- 此处轮播 -->
                        <!-- <div id="echartsGZFX" style="width:100%;height: 100%;"></div> -->
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <!-- <div class="">设备类型统计</div> -->
                        <el-carousel indicator-position="outside" :interval="8000" :autoplay="true" height="200px"
                            style="width: 100%;" @change="echartsChangeLXTJ">
                            <el-carousel-item v-for="(item,index) in dataLXTJ" :key="index"
                                style="width: 100%;height: 100%;" class=" flex flex_column align_center">
                                <!-- -->
                                <span>设备类型统计 - {{item.name}}</span>
                                <div class="flex justify_center" style="width: 100%;height: 100%;">
                                    <div :id="item.id" style="flex:1;" class="echartsSize"></div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <!-- <div id="ecartsLX" style="width:100%;height: 100%;"></div> -->
                        <!-- dataLXTJ -->
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">综合效率</div>
                        <div id="echartsZHLY" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">停机分析</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in stopAnalysisData" :key="index"
                                :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                <el-col :span="8">{{item.deviceName}}</el-col>
                                <el-col :span="8">{{item.stopTime}}</el-col>
                                <el-col :span="8">{{item.stopCause}}</el-col>

                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">MTBF/MTTR/故障率</div>
                        <div id="echartsGMTBF" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">待机分析</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in standbyAnalysisData" :key="index"
                                :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                <el-col :span="8">{{item.deviceName}}</el-col>
                                <el-col :span="8">{{item.standbyTime}}</el-col>
                                <el-col :span="8">{{item.standbyCause}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>

</template>
<script>
    // import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';
    import {
        dashBoardGetTitleGroup2, //获取看板标题
        operatRateComparison, //各事业部开机对比
        operatUtilizationRateComparison, //各事业部开利用机率对比
        overallEfficiencyComparison, //各事业部综合率对比
        standbyAnalysis, //待机分析
        stopAnalysis, //停机分析
        mttrMtbfFaultrateComparison, //MTTR/MTBF/故障率比较
        faultTrendComparison, //各事业部故障趋势对比
        faultAnalysis, //故障分析
        equipmentTypeStatistics, //设备类型统计
        operationAndMaintenancJISHTimelyCompletionRate, //运维及时完成率
    } from "@/api/api_comprehensiveShow";
import { dispose } from 'echarts';

    export default {
        data() {
            return {
                currentTime: new Date(),
                dashBoardTitleGroup2: '', //获取看板标题
                operatRateComparisonX: [], //各事业部开机率对比  X轴
                operatRateComparisonData: [], //各事业部开机率对比  data
                operatUtilizationRateComparisonX: [], //各事业部开利用机率对比  X轴
                operatUtilizationRateComparisonData: [], //各事业部开利用机率对比  data
                dataZHL: [], //综合率
                dataZHLValue: [], //综合率value
                standbyAnalysisData: [], //待机分析
                stopAnalysisData: [], //停机分析
                mttrMtbfFaultrateComparisonX: [], //MTTR/MTBF/故障率比较  X轴
                mttrComparisonMTBF: [], //MTTR/MTBF/故障率比较  MTBF
                mtbfComparisonMTTR: [], //MTTR/MTBF/故障率比较  MTTR
                faultrateComparisonMTBF: [], //MTTR/MTBF/故障率比较  故障率
                faultTrendComparisonX: [], //各事业部故障趋势对比  X轴
                dataGZQS: [], //各事业部故障趋势对比  数据
                dataLXTJ: [], //设备类型统计  数据
                dataOMRName: [], //运维及时完成率
                dataOMRValue: [], //运维及时完成率
                echartsCarousel: [],
            }
        },
        mounted() {
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss')
            this.dashBoardGetTitleGroup2(); //获取看板标题
            this.operatRateComparison(); //各事业部开机对比
            this.operatUtilizationRateComparison(); //各事业部开利用机率对比
            this.overallEfficiencyComparison(); //各事业部综合率对比
            this.standbyAnalysis(); //待机分析
            this.stopAnalysis(); //停机分析
            this.mttrMtbfFaultrateComparison(); //MTTR/MTBF/故障率比较
            this.faultTrendComparison(); //各事业部故障趋势对比
            this.faultAnalysis(); //故障分析
            this.equipmentTypeStatistics(); //设备类型统计
            this.operationAndMaintenancJISHTimelyCompletionRate(); //运维及时完成率

        },
        methods: {
            //接口-开始-------------------
            //1、获取看板标题
            async dashBoardGetTitleGroup2() {
                var param = {

                }
                const selectRes = await dashBoardGetTitleGroup2(param);
                this.dashBoardTitleGroup2 = selectRes.data;
                // console.log(selectRes.data)
            },
            //2、各事业部开机率对比
            async operatRateComparison() {
                var param = {

                }
                const selectRes = await operatRateComparison(param);
                this.operatRateComparisonX = selectRes.data.axis; //各事业部开机率对比  X轴
                this.operatRateComparisonData = selectRes.data.value; //各事业部开机率对比  X轴
                // console.log(selectRes.data)
                this.draawKJL(this.operatRateComparisonX, this.operatRateComparisonData);
            },
            //3、各事业部开利用机率对比
            async operatUtilizationRateComparison() {
                var param = {

                }
                const selectRes = await operatUtilizationRateComparison(param);
                this.operatUtilizationRateComparisonX = selectRes.data.axis; //各事业部开利用机率对比  X轴
                this.operatUtilizationRateComparisonData = selectRes.data.value; //各事业部开利用机率对比  X轴
                // console.log(selectRes.data)
                this.drawKJLYL(this.operatRateComparisonX, this.operatRateComparisonData);
            },
            //4、各事业部综合率对比
            async overallEfficiencyComparison() {
                var param = {

                }
                const selectRes = await overallEfficiencyComparison(param);
                for (var i = 0; i < selectRes.data.length; i++) {
                    var temp = {}
                    temp.name = selectRes.data[i].name;
                    temp.max = 100;
                    this.dataZHL.push(temp)
                    this.dataZHLValue[i] = selectRes.data[i].OEE;
                }
                this.drawZHL(this.dataZHL, this.dataZHLValue);
            },
            //5、待机分析
            async standbyAnalysis() {
                var param = {

                }
                const selectRes = await standbyAnalysis(param);
                this.standbyAnalysisData = selectRes.data;
                // console.log(selectRes.data)
            },
            //5、停机分析
            async stopAnalysis() {
                var param = {

                }
                const selectRes = await stopAnalysis(param);
                this.stopAnalysisData = selectRes.data;
                // console.log(selectRes.data)
            },
            //6、MTTR/MTBF/故障率比较
            async mttrMtbfFaultrateComparison() {
                var param = {

                }
                const selectRes = await mttrMtbfFaultrateComparison(param);
                this.mttrMtbfFaultrateComparisonX = selectRes.data.axis; //MTTR/MTBF/故障率比较  X轴
                this.mttrComparisonMTBF = selectRes.data.dataList[0]; //MTTR/MTBF/故障率比较  MTBF
                this.mtbfComparisonMTTR = selectRes.data.dataList[1]; //MTTR/MTBF/故障率比较  MTTR
                this.faultrateComparisonMTBF = selectRes.data.dataList[2]; //MTTR/MTBF/故障率比较  MTBF
                // this.stopAnalysisData = selectRes.data;
                // console.log(selectRes.data)
                this.drawGZL(this.mttrMtbfFaultrateComparisonX, this.mttrComparisonMTBF, this.mtbfComparisonMTTR,
                    this.faultrateComparisonMTBF);
            },
            //7、各事业部故障趋势对比
            async faultTrendComparison() {
                var param = {}
                const selectRes = await faultTrendComparison(param);
                this.faultTrendComparisonX = selectRes.data.axis; //各事业部故障趋势对比  X轴


                for (var i = 0; i < selectRes.data.dataList.length; i++) {
                    var data = {
                        name: '',
                        list: [],
                        legend: [],
                        id: 'ecartsJP' + i
                    }

                    var crerrentData = selectRes.data.dataList[i];
                    data.name = crerrentData.plant
                    for (var j = 0; j < crerrentData.list.length; j++) {
                        var temp = {}
                        // temp.name = selectRes.data.dataList[i].name;
                        temp.name = crerrentData.list[j].name;
                        temp.data = crerrentData.list[j].value;
                        temp.type = 'line';
                        data.list.push(temp)
                        data.legend.push(crerrentData.list[j].name)
                    }
                    // var temp = {}
                    // // temp.name = selectRes.data.dataList[i].name;
                    // temp.name = selectRes.data.dataList[i].plant;
                    // temp.data = selectRes.data.dataList[i].value;
                    // temp.type = 'line';
                    this.dataGZQS.push(data)
                    // this.dataLXTJValue[i] = selectRes.data[i].value;
                }
                // console.log(this.dataGZQS)
                setTimeout(() => {
                    this.drawGZQS(this.faultTrendComparisonX, this.dataGZQS[0]);

                }, 100)
            },
            echartsChangeeJP(val) {
                this.drawGZQS(this.faultTrendComparisonX, this.dataGZQS[val]);
            },
            //8、故障分析
            async faultAnalysis() {
                var param = {

                }
                const selectRes = await faultAnalysis(param);
                this.echartsCarousel = [];

                var allData = [];
                for (var i = 0; i < selectRes.data.length; i++) {
                    var testData = selectRes.data[i].value;

                    var temp = {
                        name: '',
                        value: [],
                        legend: [],
                        id: 'echartsGZFX' + i
                    };
                    temp.name = selectRes.data[i].plant;
                    var line = []
                    var legend = [];
                    for (var j = 0; j < testData.length; j++) {
                        var dataA = testData[j];
                        var currentData = {
                            name: '',
                            data: testData[j],
                            type: 'scatter',
                            symbolSize: function (data) { //气泡的大小
                                return data[2];
                            },
                            emphasis: {
                                focus: 'series'
                            },
                        }
                        legend.push(dataA[0][3])
                        currentData.name = dataA[0][3]
                        line.push(currentData)
                        // console.log(line)
                    }
                    temp.value = line;
                    temp.legend = legend;
                    allData.push(temp)
                }
                this.echartsCarousel = allData;
                setTimeout(() => {
                    this.drawGZFX(allData[0]);
                }, 100)

            },
            echartsChange(val) {
                this.drawGZFX(this.echartsCarousel[val]);
                // this.drawShap(this.echartsCarousel[val]);
            },
            //9、设备类型统计
            async equipmentTypeStatistics() {
                var param = {}
                const selectRes = await equipmentTypeStatistics(param);
                // for (var i = 0; i < selectRes.data.length; i++) {
                //     var temp = {}
                //     temp.name = selectRes.data[i].name;
                //     temp.value = selectRes.data[i].value;
                //     this.dataLXTJ.push(temp)
                //     // this.dataLXTJValue[i] = selectRes.data[i].value;
                // }
                for (var i = 0; i < selectRes.data.length; i++) {
                    var data = {
                        name: '',
                        list: [],
                        legend: [],
                        id: 'ecartsLX' + i,
                        total: ''
                    }

                    var crerrentData = selectRes.data[i];
                    data.name = crerrentData.plant;
                    data.total = crerrentData.total
                    for (var j = 0; j < crerrentData.list.length; j++) {
                        //  var temp = {}
                        var temp = {
                            name: '',
                            value: ''
                        };
                        temp.name = crerrentData.list[j].name;
                        temp.value = crerrentData.list[j].value;
                        // temp.type = 'line';
                        data.list.push(temp)
                        data.legend.push(crerrentData.list[j].name)
                    }
                    // var temp = {}
                    // // temp.name = selectRes.data.dataList[i].name;
                    // temp.name = selectRes.data.dataList[i].plant;
                    // temp.data = selectRes.data.dataList[i].value;
                    // temp.type = 'line';
                    this.dataLXTJ.push(data)
                    // this.dataLXTJValue[i] = selectRes.data[i].value;
                }
                console.log(this.dataLXTJ)
                setTimeout(() => {
                    this.drawSBLX(this.dataLXTJ[0])
                }, 100)

            },
            echartsChangeLXTJ(val) {
                this.drawSBLX(this.dataLXTJ[val])
            },
            //9、运维及时完成率
            async operationAndMaintenancJISHTimelyCompletionRate() {
                var param = {}
                const selectRes = await operationAndMaintenancJISHTimelyCompletionRate(param);

                for (var i = 0; i < selectRes.data.length; i++) {
                    let listValue = selectRes.data[i].list;
                    var currentData = {
                        id: '',
                        name: '',
                        value: []
                    };
                    currentData.id = 'echartsOMR' + i;
                    currentData.name = selectRes.data[i].plant;
                    // var testData = {
                    //     value: listValue[0].value,
                    //     name: listValue[0].name, //title
                    //     title: { //仪表盘标题
                    //         offsetCenter: ['150%', '10%'],
                    //         // color: '#d464ff',
                    //     },
                    //     detail: {
                    //         offsetCenter: ['170%', '10%']
                    //     }
                    // };
                    var line = [{
                       value: listValue[0].value,
                        name: listValue[0].name, //title
                        title: {
                            offsetCenter: ['140%', '-50%']
                        },
                        detail: {
                            offsetCenter: ['190%', '-50%']
                        }
                    }, {
                      value: listValue[1].value,
                        name: listValue[1].name, //title
                        title: {
                            offsetCenter: ['140%', '-30%']
                        },
                        detail: {
                            offsetCenter: ['190%', '-30%']
                        }
                    }, {
                        value: listValue[2].value,
                        name: listValue[2].name, //title
                        title: {
                            offsetCenter: ['140%', '-10%']
                        },
                        detail: {
                            offsetCenter: ['190%', '-10%']
                        }
                    }, {
                     value: listValue[3].value,
                        name: listValue[3].name, //title
                        title: {
                            offsetCenter: ['140%', '10%']
                        },
                        detail: {
                            offsetCenter: ['190%', '10%']
                        }
                    }, {
                       value: listValue[4].value,
                        name: listValue[4].name, //title
                        title: {
                            offsetCenter: ['140%', '30%']
                        },
                        detail: {
                            offsetCenter: ['190%', '30%']
                        }
                    }]
                    // testData.value = selectRes.data[i].value;
                    // testData.name = selectRes.data[i].name;
                    currentData.value = line;
                    // currentData.value.push(line);
                    this.dataOMRName.push(currentData)
                    // this.dataOMRName[i] = selectRes.data[i].name;
                    // this.dataOMRValue[i] = selectRes.data[i].value;
                }
                console.log(this.dataOMRName)
                setTimeout(() => {
                    this.drawOMR(this.dataOMRName[0]);
                }, 100)

            },
            echartsChangeeMR(val) {
                this.drawOMR(this.dataOMRName[val]);
            },
            //接口-结束-------------------

            //运维及时完成效率
            drawOMR(dataOMRName) {
                // console.log(dataOMRName)
                  echarts.init(document.getElementById(dataOMRName.id)).dispose(); //销毁echarts
                this.echartsOMR = echarts.init(document.getElementById(dataOMRName.id));
                this.echartsOMR.setOption({
                    color: ['#DC6151', '#FFCA2B', '#4F648C', '#50E4AC', '#2A77FF'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        radius: '80%', //设置仪表盘的大小
                        pointer: {
                            show: false
                        },
                        progress: {
                            show: true,
                            overlap: false,
                            roundCap: false, //是否有弧度
                            clip: false,
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: 'transparent'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 40,
                                color: [ //设置仪表盘背景颜色
                                    [1, 'transparent']
                                ]
                            }
                        },
                        splitLine: {
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            distance: 50
                        },
                        data: dataOMRName.value,
                        title: {
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: {
                            width: 50,
                            height: 14,
                            fontSize: 14,
                            color: 'auto',
                            borderColor: 'auto',
                            borderRadius: 20,
                            borderWidth: 0,
                            formatter: '{value}%'
                        }
                    }]
                    // color: ['#F6C022', '#657798', '#62DAAB'],
                    // legend: {
                    //     itemGap: 10
                    // },
                    // series: [{
                    //     type: 'gauge',
                    //     startAngle: 90,
                    //     endAngle: -270,
                    //     // center: ['50%', '50%'],
                    //     radius: '80%', //设置仪表盘的大小
                    //     pointer: { //仪表盘指针
                    //         show: false
                    //     },
                    //     progress: { //展示当前进度
                    //         show: true,
                    //         overlap: false, //多组数据时进度条是否重叠
                    //         // roundCap: true, //显示进度是否有弧度
                    //         clip: false,
                    //         itemStyle: { //盘中进度样式
                    //             borderWidth: 0, //进度border-width
                    //             borderColor: 'rgba(255, 255, 255, 0)'
                    //         }
                    //     },
                    //     axisLine: { //圆环的样式
                    //         lineStyle: {
                    //             width: 20, //宽度
                    //             // color:"#FFFFFF"
                    //             color: [ //设置仪表盘背景颜色
                    //                 [1, 'rgba(255, 255, 255, 0)'] //透明
                    //             ]
                    //         }
                    //     },
                    //     splitLine: { //圆环上的大刻度
                    //         show: false,
                    //         distance: 0,
                    //         length: 10
                    //     },
                    //     axisTick: { //圆环上的小刻度
                    //         show: false
                    //     },
                    //     axisLabel: { //大的刻度值
                    //         show: false,
                    //         distance: 50
                    //     },
                    //     data: dataOMRName.value,
                    //     title: { //title样式
                    //         fontSize: 12,
                    //         color: '#808080'
                    //     },
                    //     detail: { //仪表详情，用于显示数据
                    //         width: 20,
                    //         height: 10,
                    //         fontSize: 12,
                    //         color: '#808080', //百分比的演的
                    //         borderColor: 'rgba(255, 255, 255, 0)', //显示的数值，boder颜色
                    //         borderRadius: 20, //borer的弧度
                    //         borderWidth: 1, //border宽度
                    //         formatter: '{value}%'
                    //     }
                    // }]
                })
            },

            //设备类型统计
            drawSBLX(dataLXTJ) {
                // console.log(dataLXTJ)
                this.ecartsLX = echarts.init(document.getElementById(dataLXTJ.id));
                this.ecartsLX.setOption({
                    // color: ['#FFCA2B', '#568AEF', '#50E4AC', '#4F648C'],
                    tooltip: {
                        trigger: 'item'
                    },
                    title: {
                        text: '总',
                        subtext: dataLXTJ.total,
                        left: '37%',
                        top: '34%',
                        textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            fontSize: 16,
                            color: "#FFFFFF"
                        },
                        subtextStyle: { //副标题文本样式{"color": "#aaa"}
                            fontSize: 14,
                            color: "#FFFFFF"
                        },
                    },
                    legend: {
                        // top: '5%',
                        // left: 'center',

                        // x: 'right', //可设定图例在左、右、居中
                        y: 'center', //可设定图例在上、下、居中
                        left: '73%',
                        orient: 'vertical',
                        icon: 'circle',
                        padding: [0, 0, 10, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        },
                        data: dataLXTJ.legend
                    },
                    series: [{
                        name: dataLXTJ.name,
                        type: 'pie',
                        radius: ['45%', '60%'],
                        center: ['40%', '45%'],
                        avoidLabelOverlap: false,
                        label: {
                            color: '#FFFFFF',
                            fontSize: 12,
                            formatter: '{d}%',
                            // formatter: '{d}%',
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: true
                        },
                        data: dataLXTJ.list
                    }]

                })
            },

            //故障分析
            // allData
            drawGZFX(data) {
                // console.log(data)
                this.echartsGZFX = echarts.init(document.getElementById(data.id));
                this.echartsGZFX.setOption({
                    legend: {
                        // right: '10%',
                        // top: '3%',
                        bottom: '8%',
                        data: data.legend,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    tooltip: {},
                    grid: {
                        left: '8%',
                        right: '5%',
                        top: '10%'
                    },
                    xAxis: {
                        type: 'category',
                        // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    },

                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    },
                    series: data.value
                })
            },

            //故障趋势
            drawGZQS(dataX, dataGZQS) {
                // console.log(dataGZQS)
                // this.ecartsJP = echarts.init(document.getElementById('ecartsJP'));
                this.ecartsJP = echarts.init(document.getElementById(dataGZQS.id));
                this.ecartsJP.setOption({
                    // color: ['#00EAFF', '#00FF00', '#FFC600'],
                    tooltip: {
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: dataGZQS.legend,
                        bottom: '5%',
                        icon: 'rect',
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '4%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                    },
                    xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        data: dataX,
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    }],
                    series: dataGZQS.list,

                })
            },

            //MTBF/MTTR/故障率
            drawGZL(mttrMtbfFaultrateComparisonX, dataMTBF, dataMTTR, dataFaultTrate) {
                this.echartsGMTBF = echarts.init(document.getElementById('echartsGMTBF'));
                this.echartsGMTBF.setOption({
                    color: ['#2883FF', '#F8AF21', '#00FF00'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: [dataMTBF.name, dataMTTR.name, dataFaultTrate.name],
                        bottom: '5%',
                        itemHeight: 10, //修改icon图形大小
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: mttrMtbfFaultrateComparisonX,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            // name: '水量',
                            min: 0,
                            max: 250,
                            interval: 50,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                // formatter: '{value} ml'
                            },
                            splitLine: { //分割线配置
                                show: true,
                                lineStyle: {
                                    color: "#FFFFFF",
                                    opacity: 0.2
                                }
                            }
                        },
                        {
                            type: 'value',
                            min: 0,
                            max: 25,
                            interval: 5,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                formatter: '{value} %'
                            },
                            splitLine: { //分割线配置
                                show: true,
                                lineStyle: {
                                    color: "#FFFFFF",
                                    opacity: 0.2
                                }
                            }
                        }
                    ],
                    series: [{
                            name: dataMTBF.name,
                            type: 'bar',
                            data: dataMTBF.value
                        },
                        {
                            name: dataMTTR.name,
                            type: 'bar',
                            data: dataMTTR.value
                        },
                        {
                            name: dataFaultTrate.name,
                            type: 'line',
                            yAxisIndex: 1,
                            data: dataFaultTrate.value
                        }
                    ]
                })
            },

            //各事业部综合率对比
            drawZHL(dataZHL, dataZHLValue) {
                this.echartsZHLY = echarts.init(document.getElementById('echartsZHLY'));
                this.echartsZHLY.setOption({
                    tooltip: {
                        show: true,
                    },
                    color: ['#00FF00'],
                    legend: {
                        data: ['OEE'],
                        bottom: '5%',
                        itemHeight: 4, //修改icon图形大小
                        itemWidth: 20,
                        // icon: 'pin',
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    radar: {
                        // shape: 'circle',
                        radius: 50,
                        shape: 'circle',
                        center: ['50%', '45%'],
                        indicator: dataZHL,
                    },
                    series: [{
                        name: '预算Name',
                        type: 'radar',
                        data: [{
                            value: dataZHLValue,
                            name: 'OEE',
                            areaStyle: {
                                color: '#00FF00'
                            }
                        }, ]
                    }]
                })
            },

            //各事业部开机利用率对比
            drawKJLYL(operatRateComparisonX, operatUtilizationRateComparisonData) {
                this.ecartsKJ = echarts.init(document.getElementById('ecartsKJ'));
                this.ecartsKJ.setOption({
                    color: ['#2883FF'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        data: ['开机利用率'],
                        bottom: '5%',
                        icon: 'rect',
                        itemHeight: 10, //修改icon图形大小
                        itemWidth: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                    },
                    xAxis: [{
                        type: 'category',
                        data: operatRateComparisonX,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    }],
                    series: [{
                        name: '开机利用率',
                        type: 'bar',
                        barWidth: '24',
                        data: operatUtilizationRateComparisonData
                    }]
                })
            },

            //各事业部开机率对比
            draawKJL(operatRateComparisonX, operatRateComparisonData) {
                this.ecartsGX = echarts.init(document.getElementById('ecartsGX'));
                this.ecartsGX.setOption({
                    color: ['#2883FF'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: ['开机率'],
                        bottom: '5%',
                        itemHeight: 10, //修改icon图形大小
                        itemWidth: 10,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#E6E6E6'
                    },
                    xAxis: [{
                        type: 'category',
                        data: operatRateComparisonX,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: '#FFFFFF',
                            fontSize: 12,
                            // formatter: '{value} ml'
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    }],
                    series: [{
                        name: '开机率',
                        type: 'bar',
                        barWidth: '24',
                        data: operatRateComparisonData
                    }]
                })

            },

            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }

    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
    }

    .board_bgd {
        width: 100vw;
        height: 86vh;
        background-image: url('../../assets/images/comprehensive/bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        padding: 0 20px;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        background-image: url('../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 25vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_div {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 78%;
        margin-top: 10px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 12px;
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    /* .element .el-row:first-child {
        background-color: #143765;
    } */

    .element .el-row {
        /* border-bottom: 1px #ffffff solid;
    border-left: 1px #ffffff solid; */
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        /* border-right: 1px #ffffff solid; */
        padding: 10px 0px;
    }

    .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;
        /* color:#73f3ff; */
    }

    .echartsSize {
        width: 190px;
        height: 200px;
    }
</style>