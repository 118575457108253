<!-- 设备管理——数据库驾驶舱——加工中心 -->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="pdt_20 pdb_10 flex justify_between align_center">
                <div class="fn_size22 color_00C6FF">
                    <span>接入设备：</span>
                    <span class="color_00FF00">{{equiStatus}} </span>
                </div>
                <div class="topTitle fn_size28 color_FFFFFF">
                    {{equiDashBoardGetTitle}}
                </div>
                <div class="fn_size22 color_00C6FF flex align_center ">
                    {{currentTime}}
                    <img src="../../../assets/images/comprehensive/full.png" class="mg_left_20" alt="">
                </div>
            </div>
            <!-- <div>

            </div> -->
            <el-row :gutter="5">
                <!-- 第1列 -->
                <el-col :span="6">
                    <el-row>
                        <el-col class="diy_col5">
                            <div class="diy_div">
                                <div class="">工件信息</div>
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size12" v-for="item in equiWorkpieceinfor" :key="item.name"
                                        :label="item.name" :value="item.value">
                                        <el-col :span="7">
                                            <div class="pdl40 flex">{{item.name}}</div>
                                        </el-col>
                                        <el-col :span="17">
                                            <div class="pdl40 flex">{{item.value}}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col6">
                            <div class="diy_div3">
                                <div class="">程序信息</div>
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size12" v-for="item in equiProgaminfor" :key="item.name"
                                        :label="item.name" :value="item.value">
                                        <el-col :span="7">
                                            <div class="pdl40 flex">{{item.name}}</div>
                                        </el-col>
                                        <el-col :span="17">
                                            <div class="pdl40 flex">{{item.value}}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div2">
                                <div class="">运行记录</div>
                                <div class="element">
                                    <el-row>
                                        <el-col :span="6">序号</el-col>
                                        <el-col :span="6">描述</el-col>
                                        <el-col :span="6">开始时间</el-col>
                                        <el-col :span="6">时长（分）</el-col>
                                    </el-row>
                                    <el-row class="myRow" v-for="(item,index) in equiRunRecord" :key="index"
                                        :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                        <el-col :span="6">{{index+1}}</el-col>
                                        <el-col :span="6">{{item.desc}}</el-col>
                                        <el-col :span="6">{{item.time}}</el-col>
                                        <el-col :span="6">{{item.timeLength}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col4">
                            <div class="diy_div4">
                                <div class="">坐标数据</div>
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size12" v-for="(item,index) in equiCoordinateData"
                                        :key="item.name" :label="item.name" :value="item.value">
                                        <el-col :span="7">
                                            <div class="pdl40 flex">{{item.name}}</div>
                                        </el-col>
                                        <el-col :span="17">
                                            <div class="pdl40 flex">{{item.value}}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- 第2列 -->
                <el-col :span="12">
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div5">
                                <div class="">关键指标</div>
                                <div class="flex justify_end" style="height: 100%;width: 100%;">
                                    <div class="flex justify_end  " style="height: 100%;width: 110%;">
                                        <div id="ecartsGJZB1" style="width:190px;height: 190px;" class=""></div>
                                        <div id="ecartsGJZB2" style="width:230px;height: 230px;" class=""></div>
                                        <div id="ecartsGJZB3" style="width:190px;height: 190px;" class=""></div>
                                    </div>
                                    <div class="flex justify_end mg_right_30" style="width: 20%;">
                                        <div class=" flex flex_column align_start color_00C6FF ">
                                            <div class="  ">
                                                <span>{{dataCN.name}}</span>
                                                <span>：</span>
                                                <span>{{dataCN.value}}</span>
                                            </div>
                                            <div class="  ">
                                                <span>{{dataDN.name}}</span>
                                                <span>：</span>
                                                <span>{{dataDN.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col3">
                            <div class="diy_div6">
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size14">
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataQXSC.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataQXSC.value}}</span>
                                            <span class="color_00C6FF">分钟</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataKZSC.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataKZSC.value}}</span>
                                            <span class="color_00C6FF">分钟</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataQTCZ.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataQTCZ.value}}</span>
                                            <span class="color_00C6FF">分钟</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataDJSC.name}}：</span>
                                            <span class="color_FFF600 mg_right_4">{{dataDJSC.value}}</span>
                                            <span class="color_00C6FF">分钟</span>
                                        </el-col>
                                    </el-row>
                                    <el-row class="pdt_10 fn_size12">
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataYDL.name}}：</span>
                                            <span class="color_FFF600">{{dataYDL.value}}</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataX.name}}：</span>
                                            <span class="color_FFF600">{{dataX.value}}</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataY.name}}：</span>
                                            <span class="color_FFF600">{{dataY.value}}</span>
                                        </el-col>
                                        <el-col :span="6" class="flex pdl30 align_center">
                                            <span class="color_00C6FF">{{dataZ.name}}：</span>
                                            <span class="color_FFF600">{{dataZ.value}}</span>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col7">
                            <div class="diy_div5">
                                <div class="">主轴负载/主轴进给/主轴速度</div>
                                <div id="ecartsZZFZ" style="width:100%;height: 100%;"></div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col5">
                            <div class="diy_div7">
                                <div class="">设备状态时间片</div>
                                <div id="ecartsSJP" style="width:100%;height: 100%;"></div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- 第3列 -->
                <el-col :span="6">
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div2">
                                <div class="">设备信息</div>
                                <div class="element2">
                                    <el-row class="pdt_10 fn_size12" v-for="item in equiInformation" :key="item.name"
                                        :label="item.name" :value="item.value">
                                        <el-col :span="7">
                                            <div class="pdl40 flex">{{item.name}}</div>
                                        </el-col>
                                        <el-col :span="17">
                                            <div class="pdl40 flex">{{item.value}}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div2">
                                <div class="">工艺数据</div>
                                <div class="element">
                                    <el-row class="myRow" v-for="(item,index) in equiCraftData" :key="index"
                                        :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                        <el-col :span="8">{{index+1}}</el-col>
                                        <el-col :span="8">{{item.name}}</el-col>
                                        <el-col :span="8">{{item.value}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div2">
                                <div class="">报警记录</div>
                                <div class="element">
                                    <el-row>
                                        <el-col :span="4">序号</el-col>
                                        <el-col :span="4">代码</el-col>
                                        <el-col :span="7">时间</el-col>
                                        <el-col :span="4">代码</el-col>
                                        <el-col :span="4">时长</el-col>
                                    </el-row>
                                    <el-row class="myRow" v-for="(item,index) in equiAralmData" :key="index"
                                        :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                        <el-col :span="4">{{index+1}}</el-col>
                                        <el-col :span="4">{{item.code}}</el-col>
                                        <el-col :span="7">{{item.time}}</el-col>
                                        <el-col :span="4" class="col_overflow">{{item.value}}</el-col>
                                        <el-col :span="4">{{item.timeLength}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

        </div>
    </div>

</template>
<script>
    // import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';
    import {
        equipmentDashBoardGetTitle, //获取看板标题
        organizationSelect, //组织架构选择
        equipmentStatus, //设备状态
        equipmentWorkpieceinfor, //工件信息
        equipmentProgaminfor, //程序信息
        equipmentRunRecord, //运行记录
        equipmentCoordinateData, //坐标数据
        equipmentDashBoardTimeSlice, //设备状态时间片
        equipmentKPI, //关键指标
        equipmentPropertyTrendCNC,//数据趋势
        equipmentInformation, //设备信息
        equipmentCraftData, //工艺数据
        equipmentAralmData, //报警记录
    } from "@/api/api_deviceManagement";

    export default {
        // components: {
        //     innerTopNav
        // },
        data() {
            return {
                currentTime: new Date(),
                equiDashBoardGetTitle: '', //看板标题
                equiStatus: '', //设备状态
                equiWorkpieceinfor: [], //工件信息
                equiProgaminfor: [], //程序信息
                equiRunRecord: [], //运行记录
                equiCoordinateData: [], //坐标数据
                equiInformation: [], //设备信息
                equiCraftData: [], //工艺数据
                equiAralmData: [], //报警记录
                dataGJSJ: [],//关键数据
                dataCN: [],//关键数据-产能
                dataDN: [],//关键数据-电能
                dataQXSC: [],//关键数据-切削时长
                dataKZSC: [],//关键数据-空走时长
                dataQTCZ: [],//关键数据-其他操作
                dataDJSC: [],//关键数据-待机时长
                dataYDL: [],//关键数据-剩余移动量
                dataX: [],//关键数据-X轴
                dataY: [],//关键数据-Y轴
                dataZ: [],//关键数据-Z轴
                equiPropertyTrendCNCX: [],//数据趋势-X轴数据
                equiPropertyTrendCNCFZ: [],//数据趋势-主轴负载
                equiPropertyTrendCNCJJ: [],//数据趋势-X轴进给
                equiPropertyTrendCNCSD: [],//数据趋势-X轴速度
                permission: {},
                sliceTime: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()], //时间片-时间戳
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss')
            this.equipmentDashBoardGetTitle(); //获取看板标题
            this.organizationSelect(); //组织架构选择
            this.equipmentStatus(); //设备状态
            this.equipmentWorkpieceinfor(); //工件信息
            this.equipmentProgaminfor(); //程序信息
            this.equipmentRunRecord(); //运行记录
            this.equipmentCoordinateData(); //坐标数据
            this.equipmentKPI(); //关键指标
            this.equipmentPropertyTrendCNC(); //数据趋势
            this.equipmentDashBoardTimeSlice(); //设备状态时间片
            this.equipmentInformation(); //设备信息
            this.equipmentCraftData(); //工艺数据
            this.equipmentAralmData(); //报警记录

        },
        methods: {
            //接口-开始-------------------
            //1、获取看板标题
            async equipmentDashBoardGetTitle() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentDashBoardGetTitle(param);
                this.equiDashBoardGetTitle = selectRes.data;
                // console.log(selectRes.data)
            },
            //2、组织架构选择
            async organizationSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await organizationSelect(param);
                // this.equiDashBoardGetTitle = selectRes.data;
                // console.log(selectRes.data)
            },
            //3、设备状态
            async equipmentStatus() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentStatus(param);
                this.equiStatus = selectRes.data.value;
                // console.log(selectRes.data)
            },
            //4、工件信息
            async equipmentWorkpieceinfor() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentWorkpieceinfor(param);
                this.equiWorkpieceinfor = selectRes.data;
                // console.log(selectRes.data)
            },
            //5、程序信息
            async equipmentProgaminfor() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentProgaminfor(param);
                this.equiProgaminfor = selectRes.data;
                // console.log(selectRes.data)
            },
            //6、运行记录
            async equipmentRunRecord() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentRunRecord(param);
                this.equiRunRecord = selectRes.data;
                // console.log(selectRes.data)
            },
            //7、坐标数据
            async equipmentCoordinateData() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentCoordinateData(param);
                this.equiCoordinateData = selectRes.data;
                // console.log(selectRes.data)
            },
            //8、关键数据
            async equipmentKPI() {
                var param = {
                    equipmentID: 100001,
                    typeKpi: 0,
                    dateType: 1,
                }
                const selectRes = await equipmentKPI(param);
                for (var i = 0; i < 3; i++) {
                    var temp = {}
                    temp.name = selectRes.data[i].name;
                    temp.value = selectRes.data[i].value;
                    this.dataGJSJ.push(temp)
                }
                this.dataQXSC = selectRes.data[3];//切削时长
                this.dataKZSC = selectRes.data[4];//空走时长
                this.dataQTCZ = selectRes.data[5];//其他操作
                this.dataDJSC = selectRes.data[6];//待机时长
                this.dataYDL = selectRes.data[7];//剩余移动量
                this.dataX = selectRes.data[8];//X轴
                this.dataY = selectRes.data[9];//Y轴
                this.dataZ = selectRes.data[10];//Z轴
                this.dataCN = selectRes.data[11];//产能
                this.dataDN = selectRes.data[12];//电能
                // console.log(this.dataGJSJ2)
                this.drawKPI(this.dataGJSJ);
            },
            //9、设备状态时间片
            async equipmentDashBoardTimeSlice() {
                var param = {
                    startDate: this.sliceTime[0],
                    endDate: this.sliceTime[1],
                    equipmentID: 100001,
                }
                const selectRes = await equipmentDashBoardTimeSlice(param);

                var testData = selectRes.data;
                this.drawTimeSlice(testData);
            },
            //10、设备信息
            async equipmentInformation() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentInformation(param);
                this.equiInformation = selectRes.data;
                // console.log(selectRes.data)
            },
            //11、工艺数据
            async equipmentCraftData() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentCraftData(param);
                this.equiCraftData = selectRes.data;
                // console.log(selectRes.data)
            },
            //12、报警记录
            async equipmentAralmData() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentAralmData(param);
                this.equiAralmData = selectRes.data;
                // console.log(selectRes.data)
            },
            //13、数据趋势
            async equipmentPropertyTrendCNC() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentPropertyTrendCNC(param);
                this.equiPropertyTrendCNCX = selectRes.data[0].value;//x轴数据
                this.equiPropertyTrendCNCFZ = selectRes.data[1].value;//主轴负载
                this.equiPropertyTrendCNCJJ = selectRes.data[2].value;//主轴进给
                this.equiPropertyTrendCNCSD = selectRes.data[3].value;//主轴速度
                // console.log(selectRes.data)
                this.drawShap(this.equiPropertyTrendCNCX,this.equiPropertyTrendCNCFZ,this.equiPropertyTrendCNCJJ,this.equiPropertyTrendCNCSD);
            },
            //接口-结束-------------------

            //echarts
            drawShap(trendX,trendFZ,trendJJ,trendSD) {
                //主轴负载
                this.ecartsZZFZ = echarts.init(document.getElementById('ecartsZZFZ'));
                this.ecartsZZFZ.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['主轴负载', '主轴进给', '主轴速度'],
                        bottom: '5%',
                        textStyle: {
                            color: '#ffffff',
                            opacity: 0.5,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        data: trendX
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        interval: 10,
                        axisLabel: {
                            color: '#FFFFFF',
                            fontSize: 12,
                            // formatter: '{value} ml'
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    },
                    series: [{
                            name: '主轴负载',
                            type: 'line',
                            // stack: '总量',
                            color: '#00A8FF',
                            data: trendFZ
                        },
                        {
                            name: '主轴进给',
                            type: 'line',
                            // stack: '总量',
                            color: '#6C7BE8',
                            data: trendJJ
                        },
                        {
                            name: '主轴速度',
                            type: 'line',
                            // stack: '总量',
                            color: '#00FF00',
                            data: trendSD
                        }
                    ]

                })



            },

            drawTimeSlice(data) {

                //设备状态时间片——开始-------------
                var that = this;
                var startTime = that.sliceTime[0];
                var categories = [''];

                function renderItem(params, api) {
                    // console.log(params, api)
                    // console.log(api.value(0), api.value(1), api.value(2))
                    var categoryIndex = api.value(0) //对应的三类中的一类
                    var start = api.coord([api.value(1), categoryIndex]) //起始点坐标
                    var end = api.coord([api.value(2), categoryIndex]) //结束点坐标
                    var height = api.size([0, 1])[1] * 0.9
                    // console.log(api.value(2) - api.value(1))
                    var rectShape = echarts.graphic.clipRectByRect({
                        x: start[0],
                        y: start[1] - height / 2,
                        width: end[0] - start[0],
                        height: height
                    }, {
                        x: params.coordSys.x,
                        y: params.coordSys.y,
                        width: params.coordSys.width,
                        height: params.coordSys.height
                    });

                    return rectShape && {
                        type: 'rect',
                        transition: ['shape'],
                        shape: rectShape,
                        style: api.style()
                    };
                }
                this.ecartsSJP = echarts.init(document.getElementById('ecartsSJP'));
                this.ecartsSJP.setOption({
                    tooltip: {
                        formatter: function (params) {
                            console.log(params)
                            // that.tools.formatDate(this.sliceTime[0], 'yyyy-MM-ddThh:mm:ss'),
                            return params.marker + params.name + ': ' + params.value[3] + ' s' + '<br/>' +
                                '开始时间：' + that.tools.formatDate(params.value[1], 'yyyy-MM-dd hh:mm:ss') +
                                '<br/>' + '结束时间：' + that.tools.formatDate(params.value[2],
                                    'yyyy-MM-dd hh:mm:ss')
                        },
                    },
                    grid: {
                        height: 50,
                        x: 50,
                        y: 25,
                        x2: 50,
                        y2: 35
                    },
                    // x轴会动态将日期格式化
                    xAxis: {
                        min: startTime,
                        scale: true,
                        axisLabel: {
                            formatter: function (val) {
                                console.log(val)
                                // console.log(startTime/1000, val/1000)
                                // 将日期进行整合处理
                                console.log(Math.max(0, val - startTime) / 1000)
                                // console.log(new Date(val))
                                // return val/1000 + ' s'

                                return Math.max(0, val - startTime) / 1000 + ' s'
                            },
                        },
                    },
                    yAxis: {
                        data: categories,
                    },
                    series: [{
                        type: 'custom',
                        // center: ['50%', '10%'],
                        renderItem: renderItem,
                        //指定x映射的是data数组中每一个元素中value的第几个值
                        //将值收集起来并且进行统一范围处理  比如上面的data中有30个值就会把30个值收集然后进行10s的一个间隔进行区间处理生成x轴
                        encode: {
                            x: [1, 2],
                            y: 0,
                        },
                        data: data,
                    }],
                })
                //设备状态时间片——结束--------------
            },

            drawKPI(data) {
                //关键指标1
                this.ecartsGJZB1 = echarts.init(document.getElementById('ecartsGJZB1'));
                this.ecartsGJZB1.setOption({
                    series: [{
                        type: 'gauge',
                        progress: {
                            show: true,
                            width: 12
                        },
                        grid: {

                        },
                        center: ['60%', '65%'],
                        axisLine: { // 坐标轴线，也就是圆圈的边框线
                            lineStyle: { // 属性lineStyle控制线条样式  
                                width: 12
                            },
                        },
                        axisTick: { // 坐标轴小标记
                            show: true, // 属性show控制显示与否，默认不显示
                            splitNumber: 5, // 每份split细分多少段
                            lineStyle: { // 属性lineStyle控制线条样式
                                color: '#ffffff',
                                width: 1,
                                type: 'solid'
                            },
                            distance: 0,
                        },
                        splitLine: {
                            length: 15,
                            lineStyle: {
                                width: 2,
                                color: '#ffffff'
                            },
                            distance: 0,
                        },
                        axisLabel: {
                            distance: 10,
                            color: '#ffffff',
                            fontSize: 12,
                            formatter: function (value) {
                                switch (value) {
                                    case 0:
                                        return 0 / 10; //首位刻度？其他都隐藏？反正记住return的是你所需要的label值

                                    case 100:
                                        return ''; //隐藏

                                    default:
                                        return value;

                                }

                            }
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 16,
                            itemStyle: {
                                borderWidth: 10,
                                borderColor: '#10DA4E'
                            },
                        },
                        pointer: {
                            icon: 'rect',
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 20,
                            offsetCenter: [0, '85%'],
                            textStyle: {
                                color: '#ffffff'
                            }
                        },
                        data: [{
                            value: data[0].value,
                            name: data[0].name,
                            title: {
                                show: true,
                                offsetCenter: [0, '40%'],
                                color: '#ffffff',
                                fontSize: 14,
                            }
                        }],
                        min: 0, // 配置仪表板的数值范围
                        max: 100,
                        splitNumber: 5,
                        //color:['仪表盘颜色1','仪表盘颜色2'],
                        color: ['#10DA4E'],
                    }]

                })

                //关键指标2
                this.ecartsGJZB2 = echarts.init(document.getElementById('ecartsGJZB2'));
                this.ecartsGJZB2.setOption({
                    series: [{
                        type: 'gauge',
                        progress: {
                            show: true,
                            width: 12
                        },
                        center: ['50%', '45%'],
                        axisLine: { // 坐标轴线，也就是圆圈的边框线
                            lineStyle: { // 属性lineStyle控制线条样式  
                                width: 12
                            },
                        },
                        axisTick: { // 坐标轴小标记
                            show: true, // 属性show控制显示与否，默认不显示
                            splitNumber: 5, // 每份split细分多少段
                            lineStyle: { // 属性lineStyle控制线条样式
                                color: '#ffffff',
                                width: 1,
                                type: 'solid'
                            },
                            distance: 0,
                        },
                        splitLine: {
                            length: 13,
                            lineStyle: {
                                width: 2,
                                color: '#ffffff'
                            },
                            distance: 0,
                        },
                        axisLabel: {
                            distance: 10,
                            color: '#ffffff',
                            fontSize: 12,
                            formatter: function (value) {
                                switch (value) {
                                    case 0:
                                        return 0 / 10; //首位刻度？其他都隐藏？反正记住return的是你所需要的label值

                                    case 100:
                                        return ''; //隐藏

                                    default:
                                        return value;

                                }

                            }
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 22,
                            itemStyle: {
                                borderWidth: 10,
                                borderColor: '#5B8FF9'
                            },
                        },
                        pointer: {
                            icon: 'rect',
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 20,
                            offsetCenter: [0, '85%'],
                            textStyle: {
                                color: '#ffffff'
                            }
                        },
                        data: [{
                            value: data[1].value,
                            name: data[1].name,
                            title: {
                                show: true,
                                offsetCenter: [0, '40%'],
                                color: '#ffffff',
                                fontSize: 14,
                            }
                        }],
                        min: 0, // 配置仪表板的数值范围
                        max: 100,
                        splitNumber: 5,
                        //color:['仪表盘颜色1','仪表盘颜色2'],
                        color: ['#5B8FF9'],
                    }]
                })


                //关键指标3
                this.ecartsGJZB3 = echarts.init(document.getElementById('ecartsGJZB3'));
                this.ecartsGJZB3.setOption({
                    series: [{
                        type: 'gauge',
                        progress: {
                            show: true,
                            width: 12
                        },
                        center: ['40%', '65%'],
                        axisLine: { // 坐标轴线，也就是圆圈的边框线
                            lineStyle: { // 属性lineStyle控制线条样式  
                                width: 12
                            },
                        },
                        axisTick: { // 坐标轴小标记
                            show: true, // 属性show控制显示与否，默认不显示
                            splitNumber: 5, // 每份split细分多少段
                            lineStyle: { // 属性lineStyle控制线条样式
                                color: '#ffffff',
                                width: 1,
                                type: 'solid'
                            },
                            distance: 0,
                        },
                        splitLine: {
                            length: 15,
                            lineStyle: {
                                width: 2,
                                color: '#ffffff'
                            },
                            distance: 0,
                        },
                        axisLabel: {
                            distance: 10,
                            color: '#ffffff',
                            fontSize: 12,
                            formatter: function (value) {
                                switch (value) {
                                    case 0:
                                        return 0 / 10; //首位刻度？其他都隐藏？反正记住return的是你所需要的label值

                                    case 100:
                                        return ''; //隐藏

                                    default:
                                        return value;

                                }

                            }
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 16,
                            itemStyle: {
                                borderWidth: 10,
                                borderColor: '#FDEC31'
                            },
                        },
                        pointer: {
                            icon: 'rect',
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 20,
                            offsetCenter: [0, '85%'],
                            textStyle: {
                                color: '#ffffff'
                            }
                        },
                        data: [{
                            value: data[2].value,
                            name: data[2].name,
                            title: {
                                show: true,
                                offsetCenter: [0, '40%'],
                                color: '#ffffff',
                                fontSize: 14,
                            }
                        }],
                        min: 0, // 配置仪表板的数值范围
                        max: 100,
                        splitNumber: 5,
                        //color:['仪表盘颜色1','仪表盘颜色2'],
                        color: ['#FDEC31'],
                    }]

                })
            },

            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },


        }

    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
    }

    .board_bgd {
        width: 100vw;
        height: 86vh;
        background-image: url('../../../assets/images/comprehensive/bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        padding: 0 20px;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        background-image: url('../../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 25vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col2 {
        height: 26vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col3 {
        height: 8vh;
        color: #FFFFFF;
        font-size: 14px;
        /* margin-bottom: 5px; */
    }

    .diy_col4 {
        height: 16vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col5 {
        height: 16vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 3px;
    }

    .diy_col6 {
        height: 18vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 3px;
    }

    .diy_col7 {
        height: 26vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }


    .diy_div {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box2.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div3 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .diy_div4 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box4.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .diy_div5 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .diy_div6 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/middle-box3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* padding-top: 8px; */
    }

    .diy_div7 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/middle-box4.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .col_overflow {
        word-break: keep-all;
        /* 不换行 */
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 内容超出宽度时隐藏超出部分的内容 */
        text-overflow: ellipsis;
        /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
    }

    .text_span {
        font-size: 12px;
        padding-top: 10px;
    }


    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 78%;
        margin-top: 2px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 12px;
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    .element2 {
        width: 90%;
        height: 80%;
        /* margin-top: 2px; */
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        /* font-size: 12px; */
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    .element2::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    .element .el-row:first-child {
        background-color: #143765;
        /* border-top: 1px #ffffff solid; */
    }

    .element .el-row {
        /* border-bottom: 1px #ffffff solid;
    border-left: 1px #ffffff solid; */
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        /* border-right: 1px #ffffff solid; */
        padding: 10px 0px;
    }

    .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;
        /* color:#73f3ff; */
    }
</style>