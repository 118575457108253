<!--  设备管理-维修管理-维修计划-详情 -->
<template>
    <div class="page_container height_100">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center bgc_FFFFFF_height ">
            <div class="box_head1 width_100">设备信息</div>
            <el-form :inline="true" :model="detailInfo" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="detailInfo.deviceID" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备名称：">
                    <el-input v-model="detailInfo.deviceName" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备类型：">
                    <el-input v-model="detailInfo.deviceType_name" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-input v-model="detailInfo.deviceModel" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="所属部门：">
                    <el-input v-model="detailInfo.deviceDepartment" placeholder="" class="width_180"
                        disabled></el-input>
                </el-form-item>
                <!-- <el-form-item label="操作人：">
                    <el-input v-model="detailInfo.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item> -->
                <el-form-item label="设备位置：">
                    <el-input v-model="detailInfo.deviceLocation" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">维修信息</div>
            <el-form :inline="true" :model="detailInfo" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="维修班组：">
                    <el-select v-model="detailInfo.execGroup" placeholder="请选择" class="width_180" disabled>
                        <!-- <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option> -->
                        <el-option v-for="(item,index) in repairExecGroup" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="紧急程度：" class=" ">
                      <el-input v-model="detailInfo.urgenLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="detailInfo.urgenLevel_name" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="(item,index) in repairUrgenLevel" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="维修人：" class=" ">
                    <el-input v-model="detailInfo.execPerson" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;">
                        选择人员</el-button>
                </el-form-item> -->
                <el-form-item label="维修类型：" class=" ">
                    <el-input v-model="detailInfo.execType_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="detailInfo.execType" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="(item,index) in repairCategory" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="记录人：" class=" ">
                    <el-input v-model="detailInfo.oprPerson" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="用户编号：" class=" ">
                    <el-input v-model="detailInfo.userID" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item> -->

                
                <el-form-item label="计划状态：" class=" ">
                    <el-input v-model="detailInfo.planStatus_name" placeholder="" class="width_180" disabled>
                        
                    </el-input>
                    <!-- <el-select v-model="detailInfo.planStatus" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="(item,index) in planStatusAr" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <!-- <el-form-item label="计划时间：" class=" "> -->
                    <!-- <el-input v-model="detailInfo.planTime" placeholder="审批人" class="width_180"></el-input> -->
                    <!-- <el-date-picker v-model="detailInfo.planTime" prefix-icon="el-icon-date" clear-icon="el-input-icon"
                        type="datetime" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item> -->

                <el-form-item label="提醒时间：" class=" ">
                    <el-date-picker v-model="detailInfo.remindTime" prefix-icon="el-icon-date"
                        clear-icon="el-input-icon" type="datetime" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="detailInfo.isAuth" class="width_180" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :model="detailInfo" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="detailInfo.remarks" resize="none" disabled>
                    </el-input>
                </el-form-item>
            </el-form>

            <!-- <div class="box_head1 width_100 mg_top_20">更换备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <div class="flex align_center mg_top_20">
                    <el-select v-model="value" placeholder="请选择仓库" class="width_150">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;">
                        选择备件</el-button>
                    <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;">
                        申购备件</el-button>
                </div>
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" max-height="250">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="date" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="更换数量" width="">
                    </el-table-column> -->
            <!-- <el-table-column prop="name" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="申请数量" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="出库数量" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="使用数量" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="还回数量" width="">
                    </el-table-column> -->
            <!-- <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div> -->

            <div class="flex mg_top_20 mg_bottom_30">
                <!-- <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div> -->
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        repairPlanQuery,
        repairGroupQuery,
        repairUrgenLevel,
        repairPlanStatusQuery,
        repairTypeQuery,

    } from "@/api/api_deviceManagement"
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                value: '',
                formLabelWidth: '110px',
                detailID: '',
                detailInfo: {},
                repairExecGroup: [],
                repairUrgenLevel: [],
                planStatusAr: [],
                repairCategory: [],
                loopUnitQuery: [],
                detailID:'',

            }
        },
        mounted() {
            // this.$route
            //  console.log(localStorage.getItem('oderId1'))
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.repairPlanQuery();
            }
            // if (this.$route.query.id) {
            //     this.detailID = this.$route.query.id
            //     this.repairPlanQuery();
            // }
            this.repairGroupQuery();
            this.getrepairUrgenLevel();
            this.repairPlanStatusQuery();
            this.repairTypeQuery();
        },
        methods: {
            async repairPlanQuery() {
                var param = {
                    ID: this.detailID
                }
                const selectRes = await repairPlanQuery(param);
                this.detailInfo = selectRes.data[0];
                this.detailInfo.deviceID=parseInt(this.detailInfo.deviceID)
            },
            async repairGroupQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairGroupQuery(param);
                this.repairExecGroup = selectRes.data;
            },
            async getrepairUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairUrgenLevel(param);
                this.repairUrgenLevel = selectRes.data;
            },
            async repairPlanStatusQuery() {
                const selectRes = await repairPlanStatusQuery();
                this.planStatusAr = selectRes.data;
            },
            async repairTypeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairTypeQuery(param);
                this.repairCategory = selectRes.data
            },
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            }
        }

    }
</script>
<style scoped>
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>