<!-- A工段长-生产管理 -->
<template>
    <div class="page_container ">
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">XXX工段各设备日产量明细表</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 pdt_10">
            <!-- select下拉框 -->
            <div class="fn_size16 flex pdt_20 flex justify_between">
                <div class="flex justify_center align_center">
                    <div>生产日期：</div>
                    <el-select v-model="value" placeholder="2021-12-12 12:00" class="mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <div>班组：</div>
                    <el-select v-model="value" placeholder="甲板" class="mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="alarmQuerySelectButton flex align_center justify_center">查询
                    </el-button>
                </div>
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140"
                        style=" background: #03CABE;border: none;" @click="dialogTableVisible = true">添加</el-button>
                    <el-button type="primary" class="width_140" style=" background: #FEA313;border: none;">修改
                    </el-button>
                    <el-button type="primary" icon="el-icon-delete" class="width_140 mg_left_10"
                        style=" background: #F75753;border: none;">删除</el-button>
                </div>
            </div>
            <!-- table -->
            <div class="height_500">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_20 "
                    @selection-change="handleSelectionChange" max-height="500" border>
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="name" label="生产日期" width=""></el-table-column>
                    <el-table-column prop="name" label="班次" width=""></el-table-column>
                    <el-table-column prop="name" label="设备名称" width=""></el-table-column>
                    <el-table-column prop="name" label="操作工" width=""></el-table-column>
                    <el-table-column prop="date" label="开始时间" width=""></el-table-column>
                    <el-table-column prop="date" label="结束时间" width=""></el-table-column>
                    <el-table-column prop="name" label="物料型号" width=""></el-table-column>
                    <el-table-column prop="name" label="今日计划"></el-table-column>
                    <el-table-column prop="name" label="今日实际"></el-table-column>
                    <el-table-column prop="name" label="合格数量"></el-table-column>
                    <el-table-column prop="name" label="录入日期"></el-table-column>
                    <el-table-column prop="name" label="备注"></el-table-column>
                </el-table>
            </div>
            <!-- dialog 上-->
            <el-dialog :visible.sync="dialogTableVisible" top='10vh' width="40%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">数据录入</div>
                <div class="flex align_center justify_center mg_top_6">
                    <div>

                    </div>
                    <el-form ref="form" :model="form" label-width="90px" class="flex_column">
                        <div class="flex">
                            <div>
                                <!-- 下拉 -->
                                <el-form-item label="设备名称：" class="mg_bottom_14">
                                    <el-select v-model="form.siteName" placeholder="1#设备">
                                        <el-option label="设备一" value="shanghai"></el-option>
                                        <el-option label="设备二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- input -->
                                <el-form-item label="计划停机1：" class="mg_bottom_14">
                                    <el-input v-model="form.faultCode1"></el-input>
                                </el-form-item>
                                <el-form-item label="计划停机2：" class="mg_bottom_14">
                                    <el-input v-model="form.faultCode2"></el-input>
                                </el-form-item>
                                <el-form-item label="计划停机3：" class="mg_bottom_14">
                                    <el-input v-model="form.faultCode3"></el-input>
                                </el-form-item>
                                <el-form-item label="计划停机4：" class="mg_bottom_14">
                                    <el-input v-model="form.faultCode4"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <!-- 下拉 -->
                                <el-form-item label="日期：" class="mg_bottom_14">
                                    <el-select v-model="form.sectionName" placeholder="2021-12-12 12:00">
                                        <el-option label="日期一" value="shanghai"></el-option>
                                        <el-option label="日期二" value="beijing"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- input -->
                                <el-form-item label="非计划1：" class="mg_bottom_14">
                                    <el-input v-model="form.faultCode11"></el-input>
                                </el-form-item>
                                <el-form-item label="非计划2：" class="mg_bottom_14">
                                    <el-input v-model="form.faultCode22"></el-input>
                                </el-form-item>
                                <el-form-item label="非计划3：" class="mg_bottom_14">
                                    <el-input v-model="form.faultCode33"></el-input>
                                </el-form-item>
                                <el-form-item label="非计划4：" class="mg_bottom_14">
                                    <el-input v-model="form.faultCode44"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>
                </div>
                <!-- 按钮 -->
                <div class="flex justify_end mg_right_50 pdb_20">
                    <el-button type="primary" @click="onSubmit">录入</el-button>
                </div>
            </el-dialog>
            <!-- 页码 -->
            <div class="flex justify_between align_start mg_right_30 mg_left_30">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div>
        </div>
        <!-- 下半段 -->
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">XXX工段各设备生产时间明细表</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 pdt_10">
            <!-- select下拉框 -->
            <div class="fn_size16 flex pdt_20 flex justify_between">
                <div class="flex justify_center align_center">
                    <div>生产日期：</div>
                    <el-select v-model="value" placeholder="2021-12-12 12:00" class="mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <div>班组：</div>
                    <el-select v-model="value" placeholder="甲板" class="mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="alarmQuerySelectButton flex align_center justify_center">查询
                    </el-button>
                </div>
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140"
                        style=" background: #03CABE;border: none;" @click="dialogTableVisible = true">添加</el-button>
                    <el-button type="primary" class="width_140" style=" background: #FEA313;border: none;">修改
                    </el-button>
                    <el-button type="primary" icon="el-icon-delete" class="width_140 mg_left_10"
                        style=" background: #F75753;border: none;">删除</el-button>
                </div>
            </div>
            <!-- table -->
            <div class="height_500">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_20 "
                    @selection-change="handleSelectionChange" max-height="500" border :header-cell-style="{background:'#E2F0FE'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="name" label="设备名称" width=""></el-table-column>
                    <el-table-column prop="name" label="日期" width=""></el-table-column>
                    <el-table-column prop="name" label="计划停机时间" width="">
                        <el-table-column prop="name" label="计划停机" width=""></el-table-column>
                        <el-table-column prop="date" label="计划停机" width=""></el-table-column>
                        <el-table-column prop="date" label="计划停机" width=""></el-table-column>
                        <el-table-column prop="name" label="计划停机" width=""></el-table-column>
                    </el-table-column>
                    <el-table-column prop="name" label="计划停机时间">
                        <el-table-column prop="name" label="非计划停机"></el-table-column>
                        <el-table-column prop="name" label="非计划停机"></el-table-column>
                        <el-table-column prop="name" label="非计划停机"></el-table-column>
                        <el-table-column prop="name" label="非计划停机"></el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_between align_start mg_right_30 mg_left_30">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div>

        </div>
    </div>
</template>
<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import * as echarts from 'echarts';
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                value: '',
                dialogTableVisible: false,
                form: {
                    siteName: '',
                    faultCode1: '',
                    faultCode2: '',
                    faultCode3: '',
                    faultCode4: '',
                    faultCode11: '',
                    faultCode22: '',
                    faultCode33: '',
                    faultCode44: '',
                },
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }],
            }
        },
        mounted() {},
        methods: {
            //table选择
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            //点击录入
            onSubmit() {
                console.log('submit!');
            },
        }
    }
</script>

<style scoped>
    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }
</style>