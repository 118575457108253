<!-- 设备管理-保养管理-保养计划-修改计划 -->
<template>
    <div class="page_container height_100">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center bgc_FFFFFF_height">
            <!-- <div class="box_head1 width_100">设备信息</div>
            <el-form :inline="true" :model="detailInfo" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="detailInfo.deviceID" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备名称：">
                    <el-input v-model="detailInfo.deviceName" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备类型：">
                    <el-select v-model="detailInfo.deviceType" placeholder="请选择" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-select v-model="detailInfo.deviceModel" placeholder="请选择" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="使用部门：">
                    <el-input v-model="detailInfo.deviceDepartment" placeholder="请输入" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备位置：">
                    <el-input v-model="detailInfo.deviceLocation" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
            </el-form> -->

            <div class="box_head1 width_100">设备信息</div>
                <el-form :inline="true" :model="detailInfo" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="detailInfo.deviceID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="detailInfo.deviceName" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备类型：" class=" ">
                    <el-input v-model="detailInfo.deviceType_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                 <el-form-item label="规格型号：">
                    <el-input v-model="detailInfo.deviceModel" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="使用部门：" class=" ">
                    <el-input v-model="detailInfo.deviceDepartment" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="detailInfo.deviceLocation" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                </el-form>

            

            <div class="box_head1 width_100">保养信息</div>
            <el-form :inline="true" :model="detailInfo" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="保养班组：">
                    <el-select v-model="detailInfo.execGroup" placeholder="请选择" class="width_180" disabled>
                        <!-- <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option> -->
                        <el-option v-for="item in maintainExecGroup" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="紧急程度：" class=" ">
                    <el-select v-model="detailInfo.urgenLevel" placeholder="请选择" class="width_180">
                        <el-option v-for="item in maintainUrgenLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保养人：" class=" ">
                     <!-- <el-select v-model="detailInfo.execPerson" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="(item,index) in maintenancePeople" :key="index" :label="item.userName"
                            :value="item.userID">
                        </el-option>
                    </el-select> -->
                    <el-input v-model="detailInfo.execPerson" class="width_180" disabled></el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;">
                        选择人员</el-button>
                </el-form-item> -->
                <el-form-item label="保养类型：" class=" ">
                    <!-- <el-input v-model="detailInfo.execType" placeholder="审批人" class="width_180"></el-input> -->
                    <el-select v-model="detailInfo.execType" placeholder="请选择" class="width_180">
                        <el-option v-for="item in maintainCategory" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="记录人：" class=" ">
                    <el-input v-model="detailInfo.oprPerson" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="用户编号：" class=" ">
                    <el-input v-model="detailInfo.userID" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item> -->

                <!-- <el-form-item label="是否循环：" class=" "> -->
                    <!-- <el-checkbox v-model="detailInfo.isLoop" class="width_180"></el-checkbox> -->
                    <!-- <el-radio-group v-model="detailInfo.isLoop" class="width_180" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="循环时间：" class=" " v-if="detailInfo.isLoop">
                    <el-input v-model="detailInfo.loopTime" placeholder="" type="number" class="width_100P"
                        ></el-input>
                    <el-select v-model="detailInfo.loopTimeUnit" placeholder="" class="width_70 mg_left_8" >
                        <el-option v-for="item in loopUnitQuery" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option> -->
                        <!-- <el-option label="年" value="shanghai"></el-option>
                        <el-option label="月" value="beijing"></el-option>
                        <el-option label="日" value="beijing1"></el-option> loopUnitQuery -->
                    <!-- </el-select>
                </el-form-item> -->
                <el-form-item label="计划状态：" class=" ">
                    <el-input v-model="detailInfo.planStatus_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="detailInfo.planStatus" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in planStatusAr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <!-- <el-form-item label="计划时间：" class=" "> -->
                    <!-- <el-input v-model="detailInfo.planTime" placeholder="审批人" class="width_180"></el-input> -->
                    <!-- <el-date-picker value-format="timestamp" v-model="detailInfo.planTime" prefix-icon="el-icon-date" clear-icon="el-input-icon"
                        type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item> -->

                <!-- <el-form-item label="提醒时间：" class=" ">
                    <el-date-picker value-format="timestamp" v-model="detailInfo.remindTime" prefix-icon="el-icon-date"
                        clear-icon="el-input-icon" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="detailInfo.isAuth" class="width_180" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :model="detailInfo" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="detailInfo.remarks" resize="none"></el-input>
                </el-form-item>
            </el-form>
            <!-- <div class="flex flex_column width_100 pdl30 pdr30 "> -->
                
                <!-- <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                    style=" background: #03CABE;border: none;" @click="changeDevice">选择设备</el-button> -->
                <!-- <el-table :data="currentEqu" style="width: 100%" class="mg_top_20"  height="100%">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <el-table-column prop="deviceID" label="设备编号" width="">
                    </el-table-column>
                    <el-table-column prop="deviceName" label="设备名称" width="">
                    </el-table-column>
                    <el-table-column prop="deviceType" label="设备类型" width="">
                    </el-table-column>
                    <el-table-column prop="deviceModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="deviceDepartment" label="使用部门" width="">
                    </el-table-column>
                    <el-table-column prop="deviceLocation" label="设备位置" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delCurrentEqu(scope.row)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table> -->
            <!-- </div> -->

            <div class="flex mg_top_40 ">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>

        <el-dialog :visible.sync="dialogTableVisible" top='10vh' width="40%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">设备选择</div>

            <!-- pdl30 pdr30 -->
            <div class="flex flex_column height_370">
                <div class="flex">
                    <el-cascader v-model="organizationValue" placeholder="请选择" :options="organization"
                        ref="demoCascader" :props="defaultProps" class="el_cascader_width " @change="handleChangeCascader"
                        :show-all-levels="false" collapse-tags></el-cascader>
                    <el-button type="primary" class="mg_left_10" @click="queryDevice">查询</el-button>
                </div>

                <!-- deviceID: row.equipmentID, //设备编号
                        deviceName: row.EquipmentName, //设备名称
                        deviceType: row.EquipmentType, //设备类型
                        deviceModel: row.EquipmentModel, //设备型号
                        deviceDepartment: row.Department, //设备所属部门
                        deviceLocation: row.InstallationPosition //设备位置 -->
                <el-table :data="equInfoList" style="width: 100%" class="mg_top_20">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <el-table-column prop="equipmentID" label="设备编号" width="">
                    </el-table-column>
                    <el-table-column prop="EquipmentName" label="设备名称" width="">
                    </el-table-column>
                    <el-table-column prop="EquipmentModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="EquipmentType" label="设备类型" width="">
                    </el-table-column>
                    <el-table-column prop="Department" label="使用部门" width="">
                    </el-table-column>
                    <el-table-column prop="InstallationPosition" label="设备位置" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="choiceEquipment(scope.row)">选择</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </el-dialog>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        maintainPlanQuery,
        getMaintainExecGroup,
        getMaintainUrgenLevel,
        getMaintainPlanStatus,
        getMaintainCategory,
        maintainPlanLoopUnitQuery,
        maintainPlanUpdate,
        maintainExecPersonQuery
    } from "@/api/api_deviceManagement";
    import {
        equipmentLedgerOrganizationSelect,
        equipmentLedgerQueryLimit,
    } from "@/api/api_systemConfig";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                value: '',
                formLabelWidth: '110px',
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }],
                detailID: '',
                detailInfo: {},
                maintainExecGroup: [],
                maintainUrgenLevel: [],
                planStatusAr: [],
                maintainCategory: [],
                loopUnitQuery: [],
                organizationValue: '',
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                dialogTableVisible: false,
                checkData: {},
                equInfoList: [],
                currentEqu: [],

                currentPage: 1,
                pageSize: 2,
                pageQuantity: 0,
                userID:'',
                permission:{},
                maintenancePeople:[]
            }
        },
        mounted() {

            console.log(localStorage.getItem('oderId'));
            // this.$route
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.maintainPlanQuery();
            }
             if(localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            // if (this.$route.query.id) {
            //     this.detailID = this.$route.query.id
            //     this.maintainPlanQuery();
            // }
            this.getMaintainExecGroup();
            this.getMaintainUrgenLevel();
            this.getMaintainPlanStatus();
            this.getMaintainCategory();
            this.maintainPlanLoopUnitQuery();
            // this.maintainExecPersonQuery();
            // if(this.$route.query.id)
            // this.
        },
        methods: {
            async maintainPlanQuery() {
                var param = {
                    ID: this.detailID
                }
                const selectRes = await maintainPlanQuery(param);
                this.detailInfo = selectRes.rows[0];
                this.currentEqu.push(this.detailInfo)
            },
            async getMaintainExecGroup() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainExecGroup(param);
                this.maintainExecGroup = selectRes.data;
            },
            async getMaintainUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainUrgenLevel(param);
                this.maintainUrgenLevel = selectRes.data;
            },
            async getMaintainPlanStatus() {
                const selectRes = await getMaintainPlanStatus();
                this.planStatusAr = selectRes.data;
            },
            async getMaintainCategory() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainCategory(param);
                this.maintainCategory = selectRes.data
            },
            async maintainPlanLoopUnitQuery() {
                const selectRes = await maintainPlanLoopUnitQuery();
                this.loopUnitQuery = selectRes.rows
            },
            async maintainExecPersonQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainExecPersonQuery(param);
                this.maintenancePeople = selectRes.data
            },
            delCurrentEqu() {
                this.$confirm('此操作将删除该设备, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentEqu = [];
                }).catch(() => {});

            },
            changeDevice() {
                this.equipmentLedgerOrganizationSelect();
                this.dialogTableVisible = true;
            },
            async equipmentLedgerOrganizationSelect() {
                var param = {
                    permission: this.permission
                };
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organization = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];
                this.equipmentLedgerQuery();
            },
            queryDevice() {
                // console.log(this.checkData)
                this.equipmentLedgerQuery();
            },
            async equipmentLedgerQuery() {
                var param = {
                    organization: this.checkData,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.equInfoList = selectRes.data;
                this.pageQuantity = selectRes.pageQuantity;
            },
            handleCurrentChange(val) {
                this.equipmentLedgerQuery(); //查询table数据的方法
            },
            choiceEquipment(row) {
                this.$confirm('确认选择此设备', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentEqu = [];
                    this.dialogTableVisible = false;
                    var currentInfo = {
                        deviceID: row.equipmentID, //设备编号
                        deviceName: row.EquipmentName, //设备名称
                        deviceType: row.EquipmentType, //设备类型
                        deviceModel: row.EquipmentModel, //设备型号
                        deviceDepartment: row.Department, //设备所属部门
                        deviceLocation: row.InstallationPosition //设备位置
                    }
                    this.currentEqu.push(currentInfo);
                    // console.log(row)

                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            handleChangeCascader() {
                // console.log(this.organizationValue)
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)

            },
            sbumit() {
                // this.$router.go(-1);
                console.log(this.detailInfo)
                this.detailInfo.deviceID = this.currentEqu[0].deviceID.toString();
                this.detailInfo.deviceName = this.currentEqu[0].deviceName;
                this.detailInfo.deviceType = this.currentEqu[0].deviceType;
                this.detailInfo.deviceModel = this.currentEqu[0].deviceModel;
                this.detailInfo.deviceDepartment = this.currentEqu[0].deviceDepartment;
                this.detailInfo.deviceLocation = this.currentEqu[0].deviceLocation;

                this.maintainPlanUpdate()
            },
            async maintainPlanUpdate() {
                const selectRes = await maintainPlanUpdate(this.detailInfo);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                    this.detailInfo = {};
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'error'
                    });
                }
            },
            colsePage() {
                this.$router.go(-1);
                this.detailInfo = {};
            }
        }

    }
</script>
<style scoped>
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>