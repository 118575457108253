<!-- A设备管理-备件管理-备件盘点 -->
<template>
    <div class="page_container height_100">
        <div class="box_head1">
            <span>备件盘点</span>
            <div class="flex align_center mg_left_50 fn_size14">
                <div class="spanWidth">是否审批：</div>
                 <el-select v-model="value" placeholder="请选择" clearable class="width_150">
                    <el-option label="是" value="true"></el-option>
                    <el-option label="否" value="false"></el-option>
                </el-select>
                <div class="mg_left_20">记录时间：</div>
                <!-- <el-date-picker v-model="statisticalDataTime" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10"
                        @change="changeBranchFactoryDateTime">
                    </el-date-picker> -->
                <el-date-picker value-format="timestamp" v-model="produceTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" class="" :picker-options="pickerOptions">
                </el-date-picker>
                <el-button type="primary" class=" mg_left_10" size="medium" @click="query">查询
                </el-button>
            </div>
        </div>

        <div class="bgc_FFFFFF bgc_FFFFFF_height pdl30 pdr30" style="height:95%">
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_140 bgc_03CABE buttonBorder" style="background-color: #03CABE;"
                        @click="gotoFaultDetails">添加盘点单</el-button>
                    <!-- <el-button type="primary" icon="el-icon-delete"
                        class="width_140 mg_left_10 bgc_F75753 buttonBorder" style="background-color: #F75753;">删除</el-button> -->
                </div>
                <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> -->
            </div>
            <!-- tableheight_500 -->
                <el-table ref="multipleTable" :data="SparePartInventoryArray" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @selection-change="handleSelectionChange" height="80%"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="index" label="序号" width="80"></el-table-column>
                    <!-- <el-table-column prop="ID" label="id" width="" ></el-table-column> -->
                    <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width="55"></el-table-column> -->
                    <!-- <el-table-column prop="authSort" label="当前审批顺序" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="isAuth" label="是否审批" width="">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isAuth == false">否</span>
                            <span v-if="scope.row.isAuth == true">是</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="oprTime" label="记录时间 " :formatter="formatDate" width=""></el-table-column>
                    <el-table-column prop="oprPerson" label="记录人" width=""></el-table-column>
                    <el-table-column prop="warehouse" label="仓库" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="orderStatus" label="工单状态" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="remarks" label="备注" width="" show-overflow-tooltip></el-table-column> -->
                    <el-table-column label="详细" width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoFaultDetails2(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="editInfo(scope.row)">编辑</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="deleteSparePartAccount(scope.row.ID)">删除</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.doAuth">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="approvalOperation(scope.row.ID)">审批</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>

            <el-dialog title="修改" :visible.sync="dialogEditVisible" width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">修改</div> -->
                <div class="flex justify_center mg_top_20">
                    <el-form :model="updateform">
                        <el-form-item label="仓库: " :label-width="formLabelWidth">
                            <!-- <el-input v-model="updateform.warehouse" autocomplete="off" class=""></el-input> -->
                            <el-select v-model="updateform.warehouse" placeholder="请选择仓库">
                                <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key"
                                    :value="item.value"> </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="审批类型" :label-width="formLabelWidth">
                        <el-select v-model="updateform.authType" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in options" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                        <el-form-item label="备注: " :label-width="formLabelWidth">
                            <el-input v-model="updateform.remarks" type="textarea" :rows="4" autocomplete="off"
                                class=""></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogEditVisible = false">取 消</el-button>
                    <el-button type="primary" @click="trueModify">确 定</el-button>
                    <!--  -->
                </div>
            </el-dialog>

            <!-- 切换框 -->
            <!-- <div class="bgc_E2F0FE flex align_end mg_top_24 height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">盘点明细</div>
            </div> -->
            <!-- table -->
            <!-- <div class="mg_top_22">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @selection-change="handleSelectionChange" max-height="500"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="date" label="备件编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="date" label="备件名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="规格型号" width=""></el-table-column>
                    <el-table-column prop="name" label="计量单位" width=""></el-table-column>
                    <el-table-column prop="name" label="账面数量" width="" show-overflow-tooltip  ></el-table-column>
                    <el-table-column prop="address" label="实际数量"  width="" show-overflow-tooltip  ></el-table-column>
                    <el-table-column prop="name" label="盘盈数量" width=""></el-table-column>
                    <el-table-column prop="name" label="盘亏数量" width=""></el-table-column>
                    <el-table-column prop="name" label="原位置编码" width=""></el-table-column>
                    <el-table-column prop="name" label="当前位置编码" width=""></el-table-column>
                    <el-table-column prop="name" label="备注" width=""></el-table-column>
                </el-table>
            </div> -->
            <!-- 审批弹框 -->
            <el-dialog :visible.sync="dialogVisible" width="30%" top="30vh">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">审批</div>
                <div class="height_110 flex justify_center align_center">
                    <el-radio-group v-model="approvalListValue" class="">
                        <el-radio v-for="item in approvalList" :key="item.value" :label="item.value">
                            {{item.description}}
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confrimApproval">确 定</el-button>
                </div>
            </el-dialog>

        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        sparePartInventoryMultiAuthQueryLimit,
        sparePartInventoryDelete,
        sparePartInventoryUpdate,
        multiAuthorizeResultQuery1,
        sparePartInventoryAuthorize,
        sparePartWarehouseQuery, // 仓库选择下拉框
    } from "@/api/api_deviceManagement";
    // import {
    //     indicatorData, //指标数据
    // } from "@/api/api_branchFactory";   

    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: null,
                pageNumber: 1,
                pageSize: 10,
                pageQuantity: 0,
                currentPage: 1,
                options: [],
                tableData: [],
                multipleSelection: [],
                SparePartInventoryArray: [],
                statisticalDataTime: null, //统计数据-选择时间范围,
                produceTime: null,
                dialogEditVisible: false,
                updateform: {},
                formLabelWidth: '90px',
                //审批所需的变量
                approvalID: '', //要审批的ID
                approvalList: [], //获取的审批结果列表内容
                approvalListValue: '', //初始时使用的值
                dialogVisible: false, //审批弹框显示控制标识符
                userID: '',
                warehouseSelectList: [], //仓库下拉框 使用的数组
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.sparePartInventoryMultiAuthQueryLimit();

            //页面一加载，就初始化仓库下拉框的数据
            this.sparePartWarehouseQuery();
        },
        methods: {

            //仓库下拉框
            async sparePartWarehouseQuery() {

                //封装参数
                var param = {
                    userID: this.userID,
                };

                //调用接口
                const selectRes = await sparePartWarehouseQuery(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.warehouseSelectList = selectRes.data;
                }
            },

            handleSelectionChange(val) {
                // console.log(val)
                this.multipleSelection = val;
            },
            handleChange(id) {
                this.tabIndex = id;
            },
            //添加盘点单
            gotoFaultDetails() {
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartInventory/addSparePartInventoryList',
                })
            },
            //详情
            gotoFaultDetails2(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartInventory/sparePartInventoryDetails',
                })
            },
            //时间范围选择器
            // changeBranchFactoryDateTime() {
            //     this.indicatorData()
            // },
            //点击页码触发分页查询
            handleCurrentChange(val) {
                this.currentPage = val;
                //调用分页查询
                this.sparePartInventoryMultiAuthQueryLimit();
            },

            // async indicatorData() {            
            //     var param = {
            //         userID: this.userID,
            //         startOprTime: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
            //         endOprTime: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
            //     }
            //     // console.log(param);
            //     const tableRes = await indicatorData(param);
            // },
            //备件盘点，查询分页数据
            async sparePartInventoryMultiAuthQueryLimit() {

                /*                 if ((this.produceTime != null)||(this.value != null && this.value != "")) {
                                    this.currentPage = 1;
                                } */
                var tableParam = {
                    userID: this.userID,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                }
                // if (this.statisticalDataTime != null) {
                //     tableParam.startOprTime = this.statisticalDataTime[0];
                //     tableParam.endOprTime = this.statisticalDataTime[1];
                // }
                if (this.produceTime != null) {
                    tableParam.startOprTime = this.produceTime[0];
                    tableParam.endOprTime = this.produceTime[1];
                }
                if (this.value != null && this.value != "") {
                    tableParam.isAuth = this.value;
                }
                //console.log(tableParam)
                const tableRes = await sparePartInventoryMultiAuthQueryLimit(tableParam);
                this.SparePartInventoryArray = tableRes.array
                this.pageQuantity = tableRes.pageQuantity
            },
            // 编辑
            editInfo(row) {
                console.log(row)
                this.updateform = row;
                this.dialogEditVisible = true;

            },
            async trueModify() {
                var param = {
                    warehouse: this.updateform.warehouse,
                    remarks: this.updateform.remarks,
                    ID: this.updateform.ID
                }
                const res = await sparePartInventoryUpdate(param);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.updateform = {};
                    this.dialogEditVisible = false;
                    this.sparePartInventoryMultiAuthQueryLimit();
                } else {
                    this.$message.error(res.message);
                }

            },
            // 备件盘点 删除
            async sparePartInventoryDelete(ID) {
                console.log(ID)
                var param = {
                    ID: ID
                }
                const res = await sparePartInventoryDelete(param);
                if (res && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.currentPage = this.delData.delData(this.SparePartInventoryArray.length, this.pageQuantity,
                        this.currentPage)
                    this.sparePartInventoryMultiAuthQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            //设备盘点审批
            //点击‘审批’按钮时触发的方法
            async approvalOperation(id) {

                this.approvalID = id;

                //调用接口，查询审批结果列表的内容
                const selectRes = await multiAuthorizeResultQuery1();

                //装载审批结果列表数据
                this.approvalList = selectRes.array;
                this.approvalListValue = selectRes.array[0].value;

                //打开审批弹框
                this.dialogVisible = true;
            },
            //点击审批弹框中的‘确定’按钮时触发的方法
            async confrimApproval() {

                //封装数据
                var param = {
                    userID: this.userID,
                    ID: this.approvalID,
                    authResult: this.approvalListValue,
                };

                //调用接口
                const selectRes = await sparePartInventoryAuthorize(param);

                //处理返回值
                if (selectRes.data) {
                    if (selectRes.data.result) { //如果有返回值的话
                        this.$message({
                            message: selectRes.message,
                            type: 'success'
                        });
                        this.dialogVisible = false;

                        //调用分页查询接口，刷新审批操作之后的数据
                        this.sparePartInventoryMultiAuthQueryLimit();
                    } else {
                        this.$message.error(selectRes.message);
                    }
                }
            },
            // 点击“删除”，弹框提示。若选择“确定”，调用“删除”接口
            deleteSparePartAccount(id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sparePartInventoryDelete(id);
                }).catch(() => {});
            },
            //时间格式转化显示
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            query() {
                this.currentPage = 1;
                this.sparePartInventoryMultiAuthQueryLimit();
            }
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>