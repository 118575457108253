<!-- 集团综合看板 -->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="pdt_20 pdb_10 flex justify_between align_center">
                <div class="fn_size22 color_00C6FF">
                    <span>接入设备：</span>
                    <span class="color_F6FF00">{{accessDevicesNum}} </span>
                    <span>台</span>
                    <span class="mg_left_30">预接入：</span>
                    <span class="color_F6FF00">{{estimatedAccessDevicesNum}} </span>
                    <span>台</span>
                </div>
                <div class="topTitle fn_size28 color_FFFFFF">
                    {{dashBoardTitle}}
                </div>
                <div class="fn_size22 color_00C6FF flex align_center ">
                    {{currentTime}}
                    <img src="../../assets/images/comprehensive/full.png" class="mg_left_20" alt="">
                </div>
            </div>
            <el-row :gutter="5">
                <el-col :span="6" class="diy_col">
                    <div class="diy_div" @click="gotoRoadDetails">
                        <div class="">道路</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in dataDL3" :key="index"
                                :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                <el-col :span="12">{{item.name}} </el-col>
                                <el-col :span="12">{{item.value}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div2">
                        <!--:interval="5000"  -->

                        <el-carousel indicator-position="outside" :interval="8000" :autoplay="true" height="200px"
                            style="width: 100%;" @change="echartsChange">
                            <el-carousel-item v-for="(item,index) in echartsCarousel" :key="index"
                                style="width: 100%;height: 100%;" class="flex flex_column align_center">
                                <span>{{item.echartsName}}</span>
                                <div class="flex " style="width: 80%;height: 100%;">
                                    <!-- <div id="ecartsDFH1" style="flex:1;" class="echartsSize"></div>
                                    <div id="ecartsDFH2" style="flex:1" class="echartsSize"></div>
                                    <div id="ecartsDFH3" style="flex:1" class="echartsSize"></div>
                                    <div id="ecartsDFH4" style="flex:1" class="echartsSize"></div> -->
                                    <div :id="item.id1" style="flex:1;" class="echartsSize"></div>
                                    <div :id="item.id2" style="flex:1" class="echartsSize"></div>
                                    <div :id="item.id3" style="flex:1" class="echartsSize"></div>
                                    <div :id="item.id4" style="flex:1" class="echartsSize"></div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>


                        <!-- <div class="home-right-carousel"> -->
                        <!-- <Carousel   class="items" v-model="value1"  :autoplay="setting.autoplay" :autoplay-speed="setting.autoplaySpeed" :dots="setting.dots" :radius-dot="setting.radiusDot" :trigger="setting.trigger" :arrow="setting.arrow">
                                <CarouselItem class="slide" :class="{blackbg:current==index}" v-for="(slide,index) in echartLineList" :key="index">
                                    <div class="demo-carousel">
                                        <echarts-line className="echart-line2" :code="slide.code1" :echartsData="testLineData1"></echarts-line>
                                        <echarts-line className="echart-line2" :code="slide.code2" :echartsData="testLineData2"></echarts-line1>
                                    </div>
                                </CarouselItem>
                            </Carousel> -->
                        <!-- </div> -->

                        <!-- <li v-for="(item ,index) in listcardData" :style=""> -->

                        <!-- <div> -->
                        <!-- <div class="doughnutChart" id="my_doughnut_chart_item"> -->
                        <!-- <div class="doughnutChart_center_w" :style="{'color':item.color }">{{item.val}}<img
                                            class="doughnutChart_center_w_img" :src="item.imgUrl_i"></img>
                                    </div>
                                    <div :id="item.id" class="doughnutChart_center"></div>
                                    {{mutation_listCardFn(item)}} -->
                        <!-- </div> -->
                        <!-- </div> -->
                        <!-- </li> -->

                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">随车</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in dataSC3" :key="index"
                                :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                <el-col :span="12">{{item.name}}</el-col>
                                <el-col :span="12">{{item.value}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">筑路</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in dataZL3" :key="index"
                                :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                <el-col :span="12">{{item.name}}</el-col>
                                <el-col :span="12">{{item.value}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div4 ">
                        <baidu-map class="map map_div" :center="{lng: 116.404, lat: 39.915}" :zoom="zoom"
                            @ready="handler" :mapStyle="mapStyle" @click="getClickInfo" :scroll-wheel-zoom='true'>
                        </baidu-map>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">铲运</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in dataCY3" :key="index"
                                :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                <el-col :span="12">{{item.name}}</el-col>
                                <el-col :span="12">{{item.value}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">矿机</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in dataKJ3" :key="index"
                                :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                <el-col :span="12">{{item.name}}</el-col>
                                <el-col :span="12">{{item.value}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">基础</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in dataJC3" :key="index"
                                :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                <el-col :span="12">{{item.name}}</el-col>
                                <el-col :span="12">{{item.value}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">液压</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in dataYY3" :key="index"
                                :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                <el-col :span="12">{{item.name}}</el-col>
                                <el-col :span="12">{{item.value}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">重型</div>
                        <div class="element">
                            <el-row class="myRow" v-for="(item,index) in dataZX3" :key="index"
                                :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                <el-col :span="12">{{item.name}}</el-col>
                                <el-col :span="12">{{item.value}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
            </el-row>

        </div>
    </div>

</template>
<script>
    // import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';
    import {
        equipmentKpi, //各事业部的设备指标
        dashBoardGetTitle1, //获取看板标题
        connectEquipmentTotal, //各事业部的设备指标
        equipmentStatus, //各事业部的设备状态
        mapData, //mapData

    } from "@/api/api_comprehensiveShow";


    export default {
        data() {
            return {
                currentTime: new Date(),
                dashBoardTitle: '', //获取看板标题
                accessDevicesNum: '', //接入设备
                estimatedAccessDevicesNum: '', //预接入设备
                dataDL: [], //道路
                dataDL2: [], //道路
                dataDL3: [], //道路
                dataZL: [], //筑路
                dataZL2: [], //筑路
                dataZL3: [], //筑路
                dataKJ: [], //矿机
                dataKJ2: [], //矿机
                dataKJ3: [], //矿机
                dataJC: [], //基础
                dataJC2: [], //基础
                dataJC3: [], //基础
                dataYY: [], //液压
                dataYY2: [], //液压
                dataYY3: [], //液压
                dataZX: [], //重型
                dataZX2: [], //重型
                dataZX3: [], //重型
                dataCY: [], //铲运
                dataCY2: [], //铲运
                dataCY3: [], //铲运
                dataSC: [], //随车
                dataSC2: [], //随车
                dataSC3: [], //随车
                equiKPIName: ["开机率", "时间稼动率", "综合效率", "故障率", "MTTR"], //KPI指标名称
                equiKPIValueDL: [], //KPI指标  道路
                equiKPIValueZL: [], //KPI指标  筑路
                equiKPIValueKJ: [], //KPI指标  矿机
                equiKPIValueJC: [], //KPI指标  基础
                equiKPIValueYY: [], //KPI指标  液压
                equiKPIValueZX: [], //KPI指标  重型
                equiKPIValueCY: [], //KPI指标  铲运
                equiKPIValueSC: [], //KPI指标  随车
                // statusNum: '',//轮播数量
                statusName: [], //轮播名称
                status: [{
                    name: '1'
                }, {
                    name: '2'
                }], //轮播
                mapStyle: {
                    styleJson: [{
                            "featureType": "all",
                            "elementType": "geometry",
                            "stylers": {
                                "hue": "#007fff",
                                "saturation": 89
                            }
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": {
                                "color": "#ffffff"
                            }
                        }
                    ]
                },
                //地图----------开始
                center: [],
                // center: "上海",
                // center:[{lng: 120.380319, lat: 36.08817},{lng: 120.582883, lat: 36.210184},{lng: 120.582883, lat: 36.210184},{lng: 120.582883, lat: 36.210184},{lng: 120.582883, lat: 36.210184},{lng: 120.582883, lat: 36.210184},{lng: 120.582883, lat: 36.210184},{lng: 120.582883, lat: 36.210184}],
                zoom: 15,
                show: false,
                // mapData2: [],
                // windowData: {}, //信息窗体数据
                // windowPosition: {}, //信息窗体位置坐标
                //地图----------结束
                echartsCarousel: [], //echarts
                data1: '',
                data2: '',
                data3: '',
                data4: '',
                // ecartsDFH1: null
            }
        },
        mounted() {
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss')
            // this.drawShap();
            this.equipmentKpi(); //各事业部的设备指标
            this.dashBoardGetTitle1(); //获取看板标题
            this.connectEquipmentTotal(); //各事业部的设备指标
            this.equipmentStatus(); //各事业部的设备状态
            this.mapData(); //mapData
        },
        methods: {
            //地图----------开始
            // infoWindowClose() {
            //     this.show = false;
            // },
            // infoWindowOpen() {
            //     this.show = true;
            // },
            // // 点击点标志
            // clickMarker(data) {
            //     this.show = true;
            //     this.windowData = data;
            //     this.windowPosition = {
            //         lng: data.longitude,
            //         lat: data.latitude,
            //     };
            // },
            //地图----------结束
            //接口-开始-------------------
            //1、各事业部的设备指标
            async equipmentKpi() {
                var param = {}
                const selectRes = await equipmentKpi(param);
                //道路
                this.dataDL = selectRes.data[0];
                this.equiKPIValueDL = [this.dataDL.OperationRate, this.dataDL.TimeMobility, this.dataDL.Efficency,
                    this.dataDL.FaultRate, this.dataDL.MTTR
                ];
                this.dataDL2.push(this.equiKPIName);
                this.dataDL2.push(this.equiKPIValueDL);
                for (var i = 0; i < this.equiKPIName.length; i++) {
                    var temp = {}
                    temp.name = this.dataDL2[0][i];
                    temp.value = this.dataDL2[1][i];
                    this.dataDL3.push(temp)
                }
                //筑路
                this.dataZL = selectRes.data[1];
                this.equiKPIValueZL = [this.dataZL.OperationRate, this.dataZL.TimeMobility, this.dataZL.Efficency,
                    this.dataZL.FaultRate, this.dataZL.MTTR
                ];
                this.dataZL2.push(this.equiKPIName);
                this.dataZL2.push(this.equiKPIValueZL);
                for (var i = 0; i < this.equiKPIName.length; i++) {
                    var temp = {}
                    temp.name = this.dataZL2[0][i];
                    temp.value = this.dataZL2[1][i];
                    this.dataZL3.push(temp)
                }
                //矿机
                this.dataKJ = selectRes.data[2];
                this.equiKPIValueKJ = [this.dataKJ.OperationRate, this.dataKJ.TimeMobility, this.dataKJ.Efficency,
                    this.dataKJ.FaultRate, this.dataKJ.MTTR
                ];
                this.dataKJ2.push(this.equiKPIName);
                this.dataKJ2.push(this.equiKPIValueKJ);
                for (var i = 0; i < this.equiKPIName.length; i++) {
                    var temp = {}
                    temp.name = this.dataKJ2[0][i];
                    temp.value = this.dataKJ2[1][i];
                    this.dataKJ3.push(temp)
                }
                //基础
                this.dataJC = selectRes.data[3];
                this.equiKPIValueJC = [this.dataJC.OperationRate, this.dataJC.TimeMobility, this.dataJC.Efficency,
                    this.dataJC.FaultRate, this.dataJC.MTTR
                ];
                this.dataJC2.push(this.equiKPIName);
                this.dataJC2.push(this.equiKPIValueJC);
                for (var i = 0; i < this.equiKPIName.length; i++) {
                    var temp = {}
                    temp.name = this.dataJC2[0][i];
                    temp.value = this.dataJC2[1][i];
                    this.dataJC3.push(temp)
                }
                //液压
                this.dataYY = selectRes.data[4];
                this.equiKPIValueYY = [this.dataYY.OperationRate, this.dataYY.TimeMobility, this.dataYY.Efficency,
                    this.dataYY.FaultRate, this.dataYY.MTTR
                ];
                this.dataYY2.push(this.equiKPIName);
                this.dataYY2.push(this.equiKPIValueYY);
                for (var i = 0; i < this.equiKPIName.length; i++) {
                    var temp = {}
                    temp.name = this.dataYY2[0][i];
                    temp.value = this.dataYY2[1][i];
                    this.dataYY3.push(temp)
                }
                //重型
                this.dataZX = selectRes.data[5];
                this.equiKPIValueZX = [this.dataZX.OperationRate, this.dataZX.TimeMobility, this.dataZX.Efficency,
                    this.dataZX.FaultRate, this.dataZX.MTTR
                ];
                this.dataZX2.push(this.equiKPIName);
                this.dataZX2.push(this.equiKPIValueZX);
                for (var i = 0; i < this.equiKPIName.length; i++) {
                    var temp = {}
                    temp.name = this.dataZX2[0][i];
                    temp.value = this.dataZX2[1][i];
                    this.dataZX3.push(temp)
                }
                //铲运
                this.dataCY = selectRes.data[6];
                this.equiKPIValueCY = [this.dataCY.OperationRate, this.dataCY.TimeMobility, this.dataCY.Efficency,
                    this.dataCY.FaultRate, this.dataCY.MTTR
                ];
                this.dataCY2.push(this.equiKPIName);
                this.dataCY2.push(this.equiKPIValueCY);
                for (var i = 0; i < this.equiKPIName.length; i++) {
                    var temp = {}
                    temp.name = this.dataCY2[0][i];
                    temp.value = this.dataCY2[1][i];
                    this.dataCY3.push(temp)
                }
                //随车
                this.dataSC = selectRes.data[7];
                this.equiKPIValueSC = [this.dataSC.OperationRate, this.dataSC.TimeMobility, this.dataSC.Efficency,
                    this.dataSC.FaultRate, this.dataSC.MTTR
                ];
                this.dataSC2.push(this.equiKPIName);
                this.dataSC2.push(this.equiKPIValueSC);
                for (var i = 0; i < this.equiKPIName.length; i++) {
                    var temp = {}
                    temp.name = this.dataSC2[0][i];
                    temp.value = this.dataSC2[1][i];
                    this.dataSC3.push(temp)
                }
                // console.log(this.dataSC);
                // console.log(this.equiKPIValueSC);
                // console.log(this.dataSC2);
                // console.log(this.dataSC3);
            },
            //2、获取看板标题
            async dashBoardGetTitle1() {
                var param = {}
                const selectRes = await dashBoardGetTitle1(param);
                this.dashBoardTitle = selectRes.data;
                // console.log(selectRes.data)
            },
            //3、各事业部的设备指标
            async connectEquipmentTotal() {
                var param = {}
                const selectRes = await connectEquipmentTotal(param);
                this.accessDevicesNum = selectRes.data.accessDevicesNum;
                this.estimatedAccessDevicesNum = selectRes.data.estimatedAccessDevicesNum;
                // console.log(selectRes.data)
            },
            //3、各事业部的设备状态
            async equipmentStatus() {
                var param = {}
                const selectRes = await equipmentStatus(param);
                // this.statusNum = selectRes.data.length;//轮播的数量
                // this.statusName = selectRes.data.length;//轮播的名称
                // this.statusNum.push(dataA[0][3])
                this.echartsCarousel = [];
                for (var i = 0; i < selectRes.data.length; i++) {
                    var temp = {};
                    temp.echartsName = selectRes.data[i].name;
                    temp.id1 = "ecartsDFH1" + i;
                    temp.id2 = "ecartsDFH2" + i;
                    temp.id3 = "ecartsDFH3" + i;
                    temp.id4 = "ecartsDFH4" + i;
                    // console.log(this.echartsCarousel[i])
                    temp.data1 = selectRes.data[i].OperatingDeviceNum;
                    temp.data2 = selectRes.data[i].errDeviceNum;
                    temp.data3 = selectRes.data[i].standbyDeviceNum;
                    temp.data4 = selectRes.data[i].stopDeviceNum;
                    this.echartsCarousel.push(temp);
                }
                // console.log(this.echartsCarousel);
                // for (var i = 0; i < selectRes.data.length; i++) {
                //     this.statusName +=  selectRes.data[i].name + ',';
                // }
                // this.statusName = this.statusName.substring(0, this.statusName.lastIndexOf(','));
                // this.dataGXName2 = this.dataGXName.split(",");
                // console.log(this.echartsCarousel)
                setTimeout(() => {
                    this.drawShap(this.echartsCarousel[0]);

                }, 100)
                // this.data1, this.data2, this.data3, this.data4

                // this.drawShap(this.data1,this.data2,this.data3,this.data4);
                // console.log(this.data1)
            },

            echartsChange(val) {
                this.drawShap(this.echartsCarousel[val]);
            },
            //4、mapData
            async mapData() {
                var param = {}
                const selectRes = await mapData(param);
                this.center = selectRes.data
                // console.log(this.center)
                //center格式:[{lng: 120.380319, lat: 36.08817},{lng: 120.582883, lat: 36.210184},{lng: 120.582883, lat: 36.210184}],
            },
            //接口-结束-------------------
            getClickInfo(e) {
                console.log(e.point.lng)
                console.log(e.point.lat)
            },
            handler({
                BMap,
                map
            }) {
                for (var i = 0; i < this.center.length; i++) {
                    // console.log(this.center[i])
                    var point = new BMap.Point(this.center[i].longitude, this.center[i].latitude)
                    // 经度,纬度
                    // console.log(point)
                    map.centerAndZoom(point, 13)
                    var marker = new BMap.Marker(point) // 创建标注
                    map.addOverlay(marker) // 将标注添加到地图中
                    var circle = new BMap.Circle(point, 6, {
                        strokeColor: 'Red',
                        strokeWeight: 6,
                        strokeOpacity: 1,
                        Color: 'Red',
                        fillColor: '#f03'
                    })
                    map.addOverlay(circle)

                    var content = "<table>";
                    // content = content + "<tr><td>" + i + " </td></tr>";
                    content = content + "<tr><td>" + this.center[i].name + " </td></tr>";
                    content = content + "<tr><td>" + this.center[i].value + " </td></tr>";
                    // content = content + "<tr><td> 时间：2018-1-3</td></tr>";
                    content += "</table>";
                    console.log(content)
                    var infowindow = new BMap.InfoWindow(content);
                    // marker.addEventListener("mouseover", function () { //鼠标悬浮窗
                    //     this.openInfoWindow(infowindow);
                    // });
                    // marker.addEventListener("mouseout", function () { //鼠标悬浮窗
                    //     this.closeInfoWindow(infowindow);
                    // });
                    addClickHandler(infowindow, marker)
                }
                function addClickHandler(sContent, marker) {
                    // marker.addEventListener("click", function (e) {
                    //     openInfo(sContent, e)
                    // });
                    marker.addEventListener("mouseover", function () { //鼠标悬浮窗
                        this.openInfoWindow(sContent);
                    });
                    marker.addEventListener("mouseout", function () { //鼠标悬浮窗
                        this.closeInfoWindow(sContent);
                    });
                }
            },

            drawShap(data) {
                // console.log(data)
                echarts.init(document.getElementById(data.id1)).dispose(); //销毁echarts
                echarts.init(document.getElementById(data.id2)).dispose(); //销毁echarts
                echarts.init(document.getElementById(data.id3)).dispose(); //销毁echarts
                echarts.init(document.getElementById(data.id4)).dispose(); //销毁echarts
                this.ecartsDFH1 = echarts.init(document.getElementById(data.id1));
                this.ecartsDFH1.setOption({
                    color: ['#ACACAC'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '45%'],
                        radius: '60%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#F5F5F5'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: data.data1,
                            name: '停机设备数量', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '120%']
                            },
                            detail: { //仪表数值
                                offsetCenter: ['0%', '0%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 24,
                            color: '#CECECE', //百分比的演的
                            borderShow: false,
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 0, //border宽度
                            formatter: '{value}'
                        }
                    }]
                })
                this.ecartsDFH2 = echarts.init(document.getElementById(data.id2));
                this.ecartsDFH2.setOption({
                    color: ['#00FF00'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '45%'],
                        radius: '60%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#F5F5F5'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: data.data2,
                            name: '运行设备数量', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '120%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 24,
                            color: '#CECECE', //百分比的演的
                            borderShow: false,
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 0, //border宽度
                            formatter: '{value}'
                        }
                    }]
                })
                this.ecartsDFH3 = echarts.init(document.getElementById(data.id3));
                this.ecartsDFH3.setOption({
                    color: ['#FF9C00'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '45%'],
                        radius: '60%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#F5F5F5'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: data.data3,
                            name: '待机设备数量', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '120%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 24,
                            color: '#CECECE', //百分比的演的
                            borderShow: false,
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 0, //border宽度
                            formatter: '{value}'
                        }
                    }]
                })
                this.ecartsDFH4 = echarts.init(document.getElementById(data.id4));
                this.ecartsDFH4.setOption({
                    color: ['#FD4444'],
                    series: [{
                        type: 'gauge',
                        startAngle: 90,
                        endAngle: -270,
                        center: ['50%', '45%'],
                        radius: '60%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#F5F5F5'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: data.data4,
                            name: '故障设备数量', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '120%']
                            },
                            detail: {
                                offsetCenter: ['0%', '0%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#FFFFFF'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 24,
                            color: '#CECECE', //百分比的演的
                            borderShow: false,
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 0, //border宽度
                            formatter: '{value}'
                        }
                    }]
                })

            },
            // 跳转至道路详情
            gotoRoadDetails() {
                this.$router.push({
                    path: '/comprehensive/roadDetails',
                })
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }

    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
    }

    .board_bgd {
        width: 100vw;
        height: 86vh;
        background-image: url('../../assets/images/comprehensive/bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        padding: 0 20px;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        background-image: url('../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 25vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col2 {
        height: 22vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_col3 {
        height: 28vh;
        color: #FFFFFF;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .diy_div {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div3 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        /* justify-content: space-between; */
    }

    .diy_div4 {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/comprehensive/jt_bgimg5@2x.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        /* justify-content: space-between; */
    }

    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 78%;
        margin-top: 2px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 12px;
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    .element .el-row:first-child {
        background-color: #143765;
    }

    .element .el-row {
        /* border-bottom: 1px #ffffff solid;
    border-left: 1px #ffffff solid; */
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        /* border-right: 1px #ffffff solid; */
        padding: 10px 0px;
    }

    .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;
        /* color:#73f3ff; */
    }

    /* echarts大小 */
    .echartsSize {
        width: 190px;
        height: 200px;
    }

    .map_div {
        height: 200px;
        width: 100%;
        margin-top: 10px;
        /* margin-left: 70px; */
        padding: 0px 20px;
    }
</style>