<!-- A设备管理-巡检管理-巡检计划--添加计划 -->
<template>
    <div class="page_container" v-loading="pageLoad">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">点检计划</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="计划类型：">
                    <el-select v-model="formInline.planType" placeholder="请选择" class="width_180 "
                        @change="changePlanType" :disabled="readOnly">
                        <el-option v-for="item in planTypeAr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="推送次数：">
                    <el-input v-model="formInline.pushTimes" type="number" placeholder="" class="width_180 "></el-input>
                </el-form-item>
                <el-form-item label="计划名称：">
                    <el-input v-model="formInline.planName" placeholder="" class="width_180 "></el-input>
                </el-form-item>
                <!-- <el-form-item label="计划状态：">
                    <el-select v-model="formInline.planStatus" placeholder="请选择" class="width_180 ">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="是否启用：" class=" ">
                    <el-radio-group v-model="formInline.enabled" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="计划位置范围：">
                    <el-input v-model="formInline.planLocScope" placeholder="" class="width_180 "></el-input>
                </el-form-item>
                <!-- <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item label="提醒时间：">
                    <el-date-picker value-format="timestamp" v-model="formInline.remindTime" type="datetime"
                        placeholder="选择日期时间" clear-icon="el-input-icon" class="width_180 "></el-date-picker>
                </el-form-item>
                <el-form-item label="点检人：">
                    <el-select v-model="formInline.execPerson" placeholder="" class="width_180 " multiple collapse-tags>
                        <el-option v-for="(item,index) in peopleArr" :key="index" :label="item.userName"
                            :value="item.userID">
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="formInline.execPerson" placeholder="" class="width_180 "></el-input> -->
                </el-form-item>
                <el-form-item label="计划时间：">
                    <el-date-picker value-format="timestamp" v-model="formInline.planTime" type="datetime"
                        placeholder="选择日期时间" clear-icon="el-input-icon" class="width_180 "></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="记录人：">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180 "></el-input>
                </el-form-item> -->
                <el-form-item label="是否循环：" class=" ">
                    <el-radio-group v-model="formInline.isLoop" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="循环周期：" v-if="formInline.isLoop == true">
                    <el-input v-model="formInline.loopTime" type="number" placeholder="" class="width_90 mg_right_10">
                    </el-input>
                    <el-select v-model="formInline.loopTimeUnit" placeholder="" class="width_80 ">
                        <el-option v-for="item in cyclePeriodAr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="循环单位周期：">
                    <el-input v-model="formInline.loopTimeUnit" type="number" placeholder="" class="width_180 "></el-input>
                </el-form-item> -->
                <el-form-item label="执行时间长度：">
                    <el-input v-model="formInline.execLenM" type="number" placeholder="" class="width_180 "></el-input>
                </el-form-item>
                <el-form-item label="指定结束时间：">
                    <el-date-picker value-format="timestamp" v-model="formInline.assignEndTime" type="datetime"
                        placeholder="选择日期时间" clear-icon="el-input-icon" class="width_180 "></el-date-picker>
                </el-form-item>
                <el-form-item label="点检班组：">
                    <el-select v-model="formInline.execGroup" placeholder="请选择" class="width_180 ">
                        <el-option v-for="(item,index) in inspectionGroupAr" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="循环周期：">
                    <el-input v-model="formInline.loopTime" placeholder="" class="width_180 "></el-input>
                </el-form-item> -->
            </el-form>
            <el-form :model="formInline" label-width="110px" class="flex flex_wrap " style="width: 65%;">
                <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- 第二部分 -->
            <div class="box_head1 width_100">巡检设备</div>
            <div class="flex mg_top_10" style="width: 90%;">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="changeDevice">选择设备</el-button>
                <!-- <el-button type="primary" icon="el-icon-top">上移</el-button>
                <el-button type="primary" icon="el-icon-bottom">下移</el-button> -->
            </div>
            <!-- table -->
            <div class="height_270" style="width: 90%;">
                <!-- currentEqu    @selection-change="handleSelectionChange"  -->
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_10 " height="100%"
                    border>
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column prop="inspDeviceID" label="设备编号" width=""></el-table-column>
                    <el-table-column prop="inspDeviceName" label="设备名称" width=""></el-table-column>
                    <el-table-column prop="inspDeviceLocation" label="位置" width=""></el-table-column>
                    <el-table-column prop="remarks" label="备注" width=""></el-table-column>
                    <el-table-column label="点检标准" width="">
                        <template slot-scope="scope">
                            <!-- <el-checkbox v-model="checked">测试</el-checkbox> -->
                            <!-- <el-checkbox prop="checked">测试</el-checkbox> -->
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="getStandard(scope.row)">更多</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"  width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delStandard(scope.$index)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex mg_top_30 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="confirm">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>

        <el-dialog title="巡检设备" :visible.sync="dialogTableVisible" top='15vh' width="40%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">巡检设备</div> -->
            <el-steps :active="active" align-center finish-status="success" class="mg_top_10">
                <el-step title="选择设备"></el-step>
                <el-step title="选择计划内容"></el-step>
            </el-steps>
            <div v-if="active==0" style="margin:10px auto auto auto;">
                <div class="flex flex_column height_444">
                    <div class="flex">
                        <el-cascader size="medium" v-model="organizationValue" placeholder="请选择" :options="organization"
                            ref="demoCascader" :props="defaultProps" class=" el_cascader_width"
                            @change="handleChangeCascader" :show-all-levels="false" collapse-tags filterable>
                        </el-cascader>
                        <el-button type="primary" size="medium" class="mg_left_10" @click="queryDevice">查询</el-button>
                    </div>
                    <el-table :data="equInfoList" style="width: 100%" class="mg_top_20" height="100%"
                        highlight-current-row @current-change="handleCurrentChangeData" v-loading="load">
                        <el-table-column type="index" label="序号" width="80">
                        </el-table-column>
                        <el-table-column prop="EquipmentID" label="设备编号" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentName" label="设备名称" width="">
                        </el-table-column>
                        <el-table-column prop="InstallationPosition" label="设备位置" width="">
                        </el-table-column>
                        <el-table-column prop="Remark" label="备注" width="">
                        </el-table-column>
                        <!-- <el-table-column label="操作" width="">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="choiceEquipment(scope.row)">选择</span>
                                </el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </div>
                <div>
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
                <div style="text-align:right;" class=" pdb_10">
                    <el-button type="primary" @click="nextStep" size="medium">
                        下一步
                    </el-button>
                </div>
            </div>
            <div v-if="active==1">
                <div style="margin:10px auto auto auto;width:100%;" class="height_444">
                    <!-- <div style="display: flex;justify-content: space-between;">
                        <div style="color: #1bb1d5;text-align: left;margin-bottom: 17px;font-size: 14px;"></div>
                        <div v-if="!readonly">
              <el-button size="mini" @click="addQdItem()" style="background-color:#259AF4;border:none;">添加</el-button>
              <el-button size="mini" @click="resetQdItem()" style="background-color:#FC8A69;border:none;">重置</el-button>
            </div>
                    </div> -->
                    <div style="height:80%;margin-top:5px;">
                        <!--   -->
                        <el-table :data="inspectionStandardDetail" style="width: 100%" class="mg_top_20" height="100%"
                            highlight-current-row @selection-change="handleCurrentChangeDetail">
                            <el-table-column type="selection" width="55">
                            </el-table-column>
                            <el-table-column type="index" label="序号" width="80">
                            </el-table-column>
                            <el-table-column prop="inspStdID" label="点检标准编号" width="" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="inspName" label="点检标准名称" width="" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="deviceID" label="设备编号" width="">
                            </el-table-column>
                            <el-table-column prop="inspTarget" label="点检目的" width="" show-overflow-tooltip>
                            </el-table-column>
                            <!-- <el-table-column label="操作" width="">
                                <template slot-scope="scope">
                                    <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                        <span style="border-bottom: 1px solid #009BFD;"
                                            @click="choiceEquipment(scope.row)">选择</span>
                                    </el-button>
                                </template>
                            </el-table-column> -->
                        </el-table>
                    </div>
                    <el-form :model="formDetail" class="mg_top_10">
                        <el-form-item label="备注：" label-width="90px" class="">
                            <el-input type="textarea" rows="3" v-model="formDetail.remarks"></el-input>
                        </el-form-item>
                    </el-form>
                </div>

                <div style="text-align:right;" class="mg_top_10 pdb_10">
                    <el-button size="medium" @click="lastStep">上一步
                    </el-button>
                    <el-button type="primary" size="medium" @click="submit">提 交
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 点检标准详情 -->
        <el-dialog title="点检标准详情" :visible.sync="inspectionStandardDialog" top='9vh'>
            <div class="flex mg_top_6 pdb_10 fn_size14 color_1A1A1A border_bottom_1_E6E6E6">
                <span class="height_30 ">设备名称：{{inspectionStandardInfo.inspDeviceName}}</span>
                <span class="height_30 mg_left_30">设备编号：{{inspectionStandardInfo.inspDeviceID}}</span>
            </div>
            <div class="mg_top_10  fn_size14 color_1A1A1A standardDialog_h">
                <div v-for="(item,index) in inspectionStandardInfoList.inspDeviceContent" :key="index">
                    <div class="flex font_bold mg_top_10">
                        <span>点检标准编号：</span>
                        <span class="color_007DF2">{{item.inspStandardID}}</span>
                    </div>
                    <el-table :data="item.inspStandardDetail" style="width: 100%;" height="200" class="mg_top_10">
                        <el-table-column type="index" label="序号" width="70">
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailID" label="点检标准编号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailType" label="点检标准类型" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailContent" label="点检标准内容" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="inspStandardDetailStandard" label="点检详情标准" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                    v-if="scope.row.inspStandardDetailType == 2">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="lookImg(scope.row.inspStandardDetailStandard)">查看</span>
                                </el-button>
                                <span v-else>{{scope.row.inspStandardDetailStandard}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="flex font_bold mg_top_20">
                    <div>描述</div>
                </div>
                <el-input class="mg_top_10" type="textarea" :rows="4" v-model="inspectionStandardInfoList.remarks"
                    disabled>
                </el-input>
            </div>
        </el-dialog>

        <!-- 查看图片 -->
        <el-dialog title="查看图片" :visible.sync="dialogLookImg" width="30%" top="20vh">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">查看图片</div> -->
            <img :src="imgUrl" alt="" class="imgLook">
        </el-dialog>

    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        equipmentLedgerOrganizationSelect,
        equipmentLedgerQueryLimit,
    } from "@/api/api_systemConfig";
    import {
        inspectionStandardQuery,
        inspectionStandardDetailQuery,
        inspectionPlanInsert,


        inspectionPlanTypeQuery,
        inspectionPlanLoopUnitQuery,
        inspectionGroupQuery,
        maintainExecPersonQuery,

        inspectionLoadImage
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                tableData: [],
                formInline: {},
                formDetail: {},
                input2: '',
                active: 0,

                dialogTableVisible: false,
                inspectionStandardDialog: false,
                organizationValue: '',
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                checkData: {},
                equInfoList: [],
                currentEqu: [],

                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                userID: '',
                permission: {},
                currentRow: null,
                currentRowDetail: null,
                inspectionStandardDetail: [],

                inspDeviceContent: [],
                endDetails: [],

                planTypeAr: [], //计划类型
                cyclePeriodAr: [], //循环周期
                inspectionGroupAr: [], //点检班组
                inspectionStandardInfo: {},
                inspectionStandardInfoList: [],
                readOnly: false,
                userName: '',
                peopleArr: [],

                load: false,
                pageLoad:true,

                dialogLookImg: false,
                imgUrl: ''
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
                this.userName = userInfo.userName;
            }
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.inspectionPlanTypeQuery();
            this.inspectionPlanLoopUnitQuery();
            this.inspectionGroupQuery();
            this.maintainExecPersonQuery()
            this.equipmentLedgerOrganizationSelect();
        },
        methods: {
            // 点检人
            async maintainExecPersonQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainExecPersonQuery(param);
                this.peopleArr = selectRes.data
            },
            async inspectionPlanTypeQuery() {
                var param = {};
                const selectRes = await inspectionPlanTypeQuery(param);
                this.planTypeAr = selectRes.rows
            },
            async inspectionPlanLoopUnitQuery() {
                var param = {};
                const selectRes = await inspectionPlanLoopUnitQuery(param);
                this.cyclePeriodAr = selectRes.rows;
            },
            // 点检班组
            async inspectionGroupQuery() {
                var param = {
                    userID: this.userID
                };
                const selectRes = await inspectionGroupQuery(param);
                this.inspectionGroupAr = selectRes.data;

            },
            async confirm() {
                this.formInline.userID = this.userID;
                this.formInline.oprPerson = this.userName;
                this.formInline.execPerson = this.formInline.execPerson.join(',')
                this.formInline.planDevList = {
                    array: this.tableData
                }
                this.formInline.planContList = {
                    array: this.endDetails
                }
                // this.formInline.remindTime = new Date().getTime();
                // this.formInline.planTime = new Date().getTime();
                // this.formInline.assignEndTime = new Date().getTime();
                // this.formInline.pushTimes = Number(this.formInline.pushTimes);
                // this.formInline.loopTime =  Number(this.formInline.loopTime);
                // this.formInline.execLenM = Number(this.formInline.execLenM);
                // console.log(JSON.stringify(this.formInline.planDevList))
                // console.log(JSON.stringify(this.formInline.planContList))
                if (this.formInline.isLoop == false) {
                    delete this.formInline.loopTime;
                    delete this.formInline.loopTimeUnit;
                    // this.formInline.loopTimeUnit = '';
                    // this.formInline.loopTime = '';
                }
                const selectRes = await inspectionPlanInsert(this.formInline);
                if (selectRes.data.result) {
                    if (selectRes.messageEnable) {
                        this.$message({
                            message: selectRes.message,
                            type: 'success'
                        });
                    }
                    this.$router.go(-1);
                } else {
                    if (selectRes.messageEnable) {
                        this.$message({
                            message: selectRes.message,
                            type: 'warning'
                        });
                    }
                }
                // console.log(this.formInline);
                // this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },
            delStandard(index) {
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // console.log(index)
                    for (var i = 0; i < this.endDetails.length; i++) {
                        if (this.endDetails[i].inspDeviceID == this.tableData[index].inspDeviceID) {
                            this.endDetails.splice(i, 1)
                            break
                        }
                    }
                    this.tableData.splice(index, 1)
                    console.log(this.tableData);
                    if (this.tableData.length == 0) {
                        this.readOnly = false;
                    }
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            getStandard(row) {
                // this
                console.log(row);
                // this.tableData;
                this.inspectionStandardInfo = row;
                this.inspectionStandardInfoList = row.inspDetailsArray;
                this.inspectionStandardDialog = true;
            },
            changePlanType() {
                // if(this.tableData.length==0) {
                //     this.readOnly = false
                // } else{
                //     this.readOnly = true
                // }
            },
            //table选择
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 选择设备
            async equipmentLedgerOrganizationSelect() {
                var param = {
                    permission: this.permission
                };
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organization = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];
                this.pageLoad = false;
                // this.equipmentLedgerQuery();
            },
            queryDevice() {
                // console.log(this.checkData)
                this.currentPage = 1;
                this.equipmentLedgerQuery();
            },
            async equipmentLedgerQuery() {
                this.load = true;
                var param = {
                    organization: this.checkData,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.equInfoList = selectRes.data;
                this.pageQuantity = selectRes.pageQuantity;
                this.load = false;
            },
            handleCurrentChange(val) {
                this.equipmentLedgerQuery(); //查询table数据的方法
            },

            // 选择设备
            changeDevice() {
                console.log(this.formInline)
                if (this.formInline.planType == undefined) {
                    this.$message({
                        message: '请优先选择计划类型',
                        type: 'warning'
                    });
                    return
                }
                this.formDetail = {};
                this.active = 0;
                this.endDetails = [];
                this.inspDeviceContent = [];

                this.equInfoList = [];
                this.pageQuantity = 0;
                this.dialogTableVisible = true;
                this.equipmentLedgerQuery();
                // this.equipmentLedgerOrganizationSelect();

                // this.equipmentLedgerQuery();
            },
            handleCurrentChangeData(val) {
                this.currentRow = val;
                // var testData = this.tableData.filter(itmer => itmer.inspDeviceID == val.EquipmentID);
                // console.log(testData)
                // if (testData.length != 0) {
                //     this.$message({
                //         message: '警告，此设备已添加',
                //         type: 'warning'
                //     });
                //     this.currentRow = null;
                // } else {
                //     this.currentRow = val;
                // }
            },
            nextStep() {
                if (this.currentRow == null) {
                    this.$message({
                        message: '警告，请选择设备',
                        type: 'warning'
                    });
                    return
                }
                var testData = this.tableData.filter(itmer => itmer.inspDeviceID == this.currentRow.EquipmentID);
                // console.log(testData)
                if (testData.length != 0) {
                    this.$message({
                        message: '警告，此设备已添加',
                        type: 'warning'
                    });
                    return
                }
                this.inspectionStandardDetail = [];
                this.inspectionStandardQuery();
                this.active = this.active + 1;
            },
            lastStep() {
                this.active = this.active - 1;
                this.currentRow = null
            },
            async inspectionStandardQuery() {
                var param = {
                    deviceID: this.currentRow.EquipmentID,
                    InspStdType: this.formInline.planType
                };
                const selectRes = await inspectionStandardQuery(param);
                this.inspectionStandardDetail = selectRes.rows;
                if (this.inspectionStandardDetail.length == 0) {
                    this.$message({
                        message: '警告，该设备没有点检标准详情',
                        type: 'warning'
                    });
                    //  this.active = this.active - 1;
                    //   this.currentRow = null
                }
            },
            handleCurrentChangeDetail(val) {
                this.currentRowDetail = val;
                console.log(val);
                // for(var i=0;i<val.length;i++) {
                // this.inspectionStandardDetailQuery(val[i].inspStdID);
                // }
            },
            // choiceEquipment(row) {
            //     // this.currentRowDetail = row;
            //     // this.$confirm('确认选择此设备标准', '提示', {
            //     //     confirmButtonText: '确定',
            //     //     cancelButtonText: '取消',
            //     //     type: 'warning'
            //     // }).then(() => {
            //     //     this.inspectionStandardDetailQuery();
            //     //     // this.currentEqu = [];
            //     //     // this.dialogTableVisible = false;
            //     //     // this.currentEqu.push(row);
            //     //     // console.log(this.currentEqu)

            //     // }).catch(() => {
            //     //     // this.$message({
            //     //     //     type: 'info',
            //     //     //     message: '已取消删除'
            //     //     // });
            //     // });
            // },
            submit() {
                // console.log(this.currentRowDetail)
                if (this.currentRowDetail == null) {
                    this.$message({
                        message: '警告哦，请选择点检详情',
                        type: 'warning'
                    });
                    // this.active = this.active - 1;
                    // this.currentRow = null
                    return
                }
                for (var i = 0; i < this.currentRowDetail.length; i++) {
                    this.inspectionStandardDetailQuery(this.currentRowDetail[i].inspStdID);
                }
                console.log(this.inspDeviceContent);
                this.formDetail.inspDeviceID = this.currentRow.EquipmentID;
                this.formDetail.inspDeviceContent = this.inspDeviceContent;
                var table = {
                    inspDeviceID: '',
                    inspDeviceName: '',
                    inspDeviceLocation: '',
                    remarks: '',
                    inspDetailsArray: {}
                }
                table.inspDeviceID = this.currentRow.EquipmentID
                table.inspDeviceName = this.currentRow.EquipmentName
                table.inspDeviceLocation = this.currentRow.InstallationPosition
                table.remarks = this.currentRow.Remark;
                table.inspDetailsArray = this.formDetail;
                console.log(this.formDetail)
                this.endDetails.push(this.formDetail);

                this.tableData.push(table)
                this.dialogTableVisible = false;
                this.currentRowDetail = null;
                this.readOnly = true
                // console.log(this.tableData)
            },
            async inspectionStandardDetailQuery(id) {
                console.log(this.inspDeviceContent)
                var param = {
                    // inspStdID: this.currentRowDetail.inspStdID,
                    inspStdID: id,
                };
                var inspDeviceContent = {
                    inspStandardID: '',
                    inspStandardDetail: []
                };
                const selectRes = await inspectionStandardDetailQuery(param);
                console.log(selectRes.rows)
                var currentDetail = selectRes.rows;
                inspDeviceContent.inspStandardID = id;

                var list = [];
                for (var i = 0; i < currentDetail.length; i++) {
                    var testDate = {
                        inspStandardDetailID: '',
                        inspStandardDetailType: '',
                        inspStandardDetailContent: '',
                        inspStandardDetailStandard: ''
                    };
                    testDate.inspStandardDetailID = currentDetail[i].inspStdId;
                    testDate.inspStandardDetailType = currentDetail[i].inspDetailType;
                    testDate.inspStandardDetailContent = currentDetail[i].inspDetailCont;
                    testDate.inspStandardDetailStandard = currentDetail[i].inspDetailStandard;
                    list.push(testDate);
                }
                inspDeviceContent.inspStandardDetail = list;
                // console.log(inspDeviceContent);
                this.inspDeviceContent.push(inspDeviceContent)
            },
            handleChangeCascader() {
                if (this.organizationValue.length == 0) {
                    this.checkData = this.standardArr[0];
                    return
                }
                // console.log(this.organizationValue)
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)

            },
            async lookImg(path) {
                var param = {
                    path: path
                }
                const res = await inspectionLoadImage(param);
                this.imgUrl = 'data:image/' + res.format + ';base64,' + res.content;
                this.dialogLookImg = true
            },

        }

    }
</script>
<style scoped>
    .standardDialog_h {
        height: 650px;
        overflow-y: auto;
        padding-bottom: 15px;
    }

    .imgLook {
        width: 300px;
        height: 300px;
        margin: 20px auto;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>