<!-- 设备管理-维修管理-维修记录-详情 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">基本信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_10 mg_top_40 details_box"> -->
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_40 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="维修单号：">
                    <el-input v-model="formInline.orderID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>

                <el-form-item label="设备编码：" class=" ">
                    <el-input v-model="formInline.deviceID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="设备类别：" class=" ">
                    <el-input v-model="formInline.deviceType_name" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <!-- </el-form>
            <el-form :inline="true" :model="formInline" label-width="90px" class=" flex"> -->
                <el-form-item label="规格型号：">
                    <el-input v-model="formInline.deviceModel" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="使用部门：" class=" ">
                    <el-input v-model="formInline.deviceDepartment" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.deviceLocation" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- </div> -->
            <div class="box_head1 width_100">故障信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_20 mg_top_40 details_box"> -->
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="故障代码：">
                    <el-input v-model="formInline.failureCode" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="报修人：" class=" ">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <!-- </el-form>
                <el-form :inline="true" class="flex justify_start" label-width="90px"> -->
                <el-form-item label="故障部位：" class="">
                    <el-input v-model="formInline.failureDevPart" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="故障等级：">
                    <el-input v-model="formInline.failureLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.failureLevel_name" placeholder="" class="width_180" size="medium"
                        disabled>
                        <el-option v-for="item in levelData" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="故障类型：" class=" ">
                    <el-input v-model="formInline.failureType_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.failureType_name" placeholder="" class="width_180" size="medium "
                        disabled>
                        <el-option v-for="item in typeData" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="发生时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.failureTime" type="datetime"
                        placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="报修时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.oprTime" type="datetime"
                        placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="故障描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.failureDesc" resize="none" disabled>
                    </el-input>
                </el-form-item>
            </el-form>

            <!-- <div class="fn_size14 flex mg_bottom_20" style="width:63%; ">
                故障语音：
            </div> -->
            <!-- </div> -->
            <div class="box_head1 width_100">处理情况</div>
            <!-- <div class="flex flex_column align_center mg_bottom_20 mg_top_40 details_box"> -->
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="故障原因：">
                    <el-input v-model="formInline.failureReason" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>

                <el-form-item label="维修组：" class="">
                    <el-select v-model="formInline.execGroup" placeholder="请选择维修班组" class="width_180" disabled>
                        <el-option v-for="(item,index) in repairExecGroup" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="维修人：" class="">
                    <el-input v-model="detailInfo.execPerson" placeholder="维修人员" class="width_180" disabled></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="维修组：" class=" ">
                    <el-input v-model="formInline.execGroup" placeholder="" class="width_180" disabled></el-input>
                </el-form-item> -->
                <el-form-item label="工单状态：">
                    <el-input v-model="formInline.orderStatus_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.orderStatus_name" placeholder="" class="width_180" disabled>
                        <el-option v-for="(item,index) in workOrderStatus" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>

                <el-form-item label="维修类型：" class=" ">
                    <el-input v-model="formInline.execType_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execType_name" placeholder="" class="width_180" disabled>
                        <el-option v-for="(item,index) in Category" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>

                <el-form-item label="维修等级：" class=" ">
                    <el-input v-model="formInline.execLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execLevel_name" placeholder="" class="width_180" disabled>
                        <el-option v-for="(item,index) in execLevel" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>

                <el-form-item label="紧急程度：" class=" ">
                    <el-input v-model="formInline.urgenLevel_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.urgenLevel_name" placeholder="" class="width_180" disabled>
                        <el-option v-for="(item,index) in UrgenLevel" :key="index" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>

                <el-form-item label="维修用时：" class="">
                    <el-input v-model="formInline.execLenM" placeholder="" class="width_180" disabled>
                        <template slot="append"></template>
                    </el-input>

                </el-form-item>
                <el-form-item label="维修费用：" class=" ">
                    <el-input v-model="formInline.execFee" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="停机时长：" class=" ">
                    <el-input v-model="formInline.stopLen" placeholder="" class="width_180" disabled>
                        <i slot="suffix" style=" color: #606266;" class="fn_size14">分钟
                        </i>
                    </el-input>
                </el-form-item>
                <!-- </el-form>
                <el-form :inline="true" class="flex justify_start data" :label-width="formLabelWidth"> -->
                <el-form-item label="开始时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.startTime" type="datetime"
                        placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.endTime" type="datetime"
                        placeholder="" style="width:180px" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否停机：" class=" ">
                    <el-radio-group v-model="formInline.machineStop" class="width_180" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%;" class="" disabled>
                <el-form-item label="工作描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.remarks" resize="none" disabled></el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">更换备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30 height_370">
                <el-table :data="sparePartList" style="width: 100%" class="mg_top_20" height="100%">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="unit" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="applyQuan" label="领用数量" width="">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价" width="">
                    </el-table-column>
                    <el-table-column prop="totalPrice" label="总价" width="">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="">
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex mg_top_20 mg_bottom_20">
                <!-- <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div> -->
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        repairOrderRecordQueryLimit,
        repairOrderStatusQuery,
        repairTypeQuery,
        repairOrderExecLevelQuery,
        repairUrgenLevel,
        repairOrderSparePartQuery



    } from "@/api/api_deviceManagement"
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                formIn: {},
                formInline: {
                    user: '',
                    region: ''
                },
                formLabelWidth: '110px',
                tableData: [],
                recordID: '',
                repairExecGroup: [],
                workOrderStatus: [],
                levelData: [],
                typeData: [],
                Category: [],
                execLevel: [],
                UrgenLevel: [],
                sparePartList: []
            }
        },
        mounted() {
            if (localStorage.getItem('recordID')) {
                this.recordID = localStorage.getItem('recordID');

            }
            this.repairOrderRecordQueryLimit();
            this.repairOrderStatusQuery();
            this.repairTypeQuery();
            this.repairOrderExecLevelQuery();
            this.repairUrgenLevel();


        },
        methods: {

            async repairOrderRecordQueryLimit() {
                var param = {
                    ID: this.recordID,
                }
                const selectRes = await repairOrderRecordQueryLimit(param)
                console.log(selectRes);
                this.formIn = {
                    ...selectRes.array
                }
                this.formInline = this.formIn[0]
                console.log("aa", this.formInline);
                this.repairOrderSparePartQuery();
            },
            //班组下拉框
            async repairGroupQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairGroupQuery(param);
                this.repairExecGroup = selectRes.data;
            },
            // 工单状态
            async repairOrderStatusQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairOrderStatusQuery(param);
                this.workOrderStatus = selectRes.data
            },
            //紧急程度
            async repairUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairUrgenLevel(param);
                this.UrgenLevel = selectRes.data;
            },
            //维修类型
            async repairTypeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairTypeQuery(param);
                this.Category = selectRes.data
            },
            //维修等级
            async repairOrderExecLevelQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await repairOrderExecLevelQuery(param);
                this.execLevel = selectRes.data
            },
            // 备件
            async repairOrderSparePartQuery() {
                var param = {
                    orderID: this.formInline.orderID
                }
                const selectRes = await repairOrderSparePartQuery(param);
                this.sparePartList = selectRes.rows;
            },
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },
            dateFormat(row, column) {
                var date = row[column.property];
                if (date == undefined) {
                    return "";
                }
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            },
        }

    }
</script>
<style scoped>
    /* .details_box {
        width: 70%;
    }

    .details_box .el-form {
        width: 100%;
    } */

    /* .textarea .el-form-item__content {
        width: 84%
    } */
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>