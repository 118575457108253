<!-- -故障报修--生成工单 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">基本信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_10 mg_top_40 details_box"> -->
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_40 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="维修单号：">
                    <el-input v-model="formInline.user" placeholder="请输入维修单号" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>

                <el-form-item label="设备编码：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备类别：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <!-- </el-form>
            <el-form :inline="true" :model="formInline" label-width="90px" class=" flex"> -->
                <el-form-item label="规格型号：">
                    <el-input v-model="formInline.user" placeholder="请输入维修单号" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="使用部门：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
            </el-form>
            <!-- </div> -->
            <div class="box_head1 width_100">故障信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_20 mg_top_40 details_box"> -->
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="报修单号：">
                    <el-input v-model="formInline.user" placeholder="请输入维修单号" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="发生时间：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>

                <el-form-item label="报修时间：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="报修人：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <!-- </el-form>
                <el-form :inline="true" class="flex justify_start" label-width="90px"> -->
                <el-form-item label="故障部位：" class="">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="故障类别：" class=" ">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="故障等级：" class="">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="工作描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form>

            <div class="fn_size14 flex mg_bottom_20" style="width:63%; ">
                故障语音：
            </div>
            <!-- </div> -->
            <div class="box_head1 width_100">处理情况</div>
            <!-- <div class="flex flex_column align_center mg_bottom_20 mg_top_40 details_box"> -->
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="故障原因：">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维修组：" class=" ">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="维修状态：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="维修级别：" class=" ">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <!-- </el-form>
                <el-form :inline="true" class="flex justify_start" :label-width="formLabelWidth"> -->
                <el-form-item label="维修类别：" class="">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="紧急程度：" class=" ">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否停机：" class="">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="停机时长：" class=" ">
                    <el-input v-model="formInline.user" placeholder="" class="width_180">
                        <i slot="suffix" style=" color: #606266;" class="fn_size14">min
                            <!-- <img style="width:16px;height:16px" src="../assets/img/XinqiaoHospitalLandinpage/yh.png"
                                    alt /> -->
                        </i>
                    </el-input>
                </el-form-item>
                <!-- </el-form>
                <el-form :inline="true" class="flex justify_start data" :label-width="formLabelWidth"> -->
                <el-form-item label="开始时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="value1" type="datetime"
                        format="yyyy-MM-dd HH:mm" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="value1" type="datetime"
                        format="yyyy-MM-dd HH:mm" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="维修用时：" class="">
                    <el-input v-model="formInline.user" placeholder="" class="width_90">
                        <template slot="append">小时</template>
                    </el-input>
                    <el-input v-model="formInline.user" placeholder="" class="width_90">
                        <template slot="append">分</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="维修费用：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%;" class="mg_bottom_20">
                <el-form-item label="工作描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form>


            <div class="box_head1 width_100">维修工作量</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                    style=" background: #03CABE;border: none;">添加人员</el-button>
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" max-height="250">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="date" label="姓名" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="报修开始时间" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="报修完成时间" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="维修用时" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="实际维修工时" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="内容或步骤" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="box_head1 width_100 mg_top_20">更换备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" max-height="250">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="date" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="申请数量" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="出库数量" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="使用数量" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="还回数量" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex mg_top_20 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                formInline: {
                    user: '',
                    region: ''
                },
                formLabelWidth: '110px',
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }]
            }
        },
        mounted() {},
        methods: {
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            }
        }

    }
</script>
<style>
    /* .details_box {
        width: 70%;
    }

    .details_box .el-form {
        width: 100%;
    } */

    /* .textarea .el-form-item__content {
        width: 84%
    } */
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }
</style>