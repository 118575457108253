<!-- A工段长-生产管理 -->
<template>
    <div class="page_container ">
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">各设备日产量明细表</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 pdt_10">
            <!-- select下拉框 -->
            <div class="fn_size14 flex pdt_20 flex justify_between">
                <div class="flex justify_center align_center">
                    <div>生产日期：</div>
                    <el-date-picker v-model="produceTime" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                    <div>班组：</div>
                    <el-select v-model="teamValue" placeholder="请选择班组" class="mg_right_10" clearable>
                        <el-option v-for="item in teamArray" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="alarmQuerySelectButton" @click="dayQuery">查询
                    </el-button>
                </div>
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_120"
                        style=" background: #03CABE;border: none;" @click="addDayQueryList">添加</el-button>
                    <el-button type="primary" class="width_120" icon="el-icon-edit"
                        style=" background: #FEA313;border: none;" @click="editdayQuery">修改
                    </el-button>
                    <el-button type="primary" icon="el-icon-delete" class="width_120 mg_left_10"
                        style=" background: #F75753;border: none;" @click="delDayQuery">删除</el-button>
                </div>
            </div>
            <!-- table -->
            <div class="">
                <el-table ref="multipleTable" :data="dayQueryList" tooltip-effect="dark" class="mg_top_20 "
                    @selection-change="handleSelectionChangeDay" height="450" border>
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="producedDay" label="生产日期" width="170" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="teamID" label="班次" width="100"></el-table-column>
                    <el-table-column prop="equipmentName" label="设备名称" width="120"></el-table-column>
                    <el-table-column prop="operatorName" label="操作工" width="120"></el-table-column>
                    <el-table-column prop="startTime" label="开始时间" width="170" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="endTime" label="结束时间" width="170" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="workpieceTypeName" label="物料型号" width="170"></el-table-column>
                    <el-table-column prop="currentPlanOutput" label="今日计划" width="80"></el-table-column>
                    <el-table-column prop="currentActualOutput" label="今日实际" width="80"></el-table-column>
                    <el-table-column prop="currentQualifiedOutput" label="合格数量" width="80"></el-table-column>
                    <el-table-column prop="recordTime" label="录入日期" width="170" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_end  ">
                <!-- <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div> -->
                <!-- <el-pagination layout="prev, pager, next" class="pull-right" :total="50"></el-pagination> -->
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <!-- 下半段 -->
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">各设备生产时间明细表</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 pdt_10">
            <!-- select下拉框 -->
            <div class="fn_size14 flex pdt_20 flex justify_between">
                <div class="flex justify_center align_center">
                    <div>生产日期：</div>
                    <el-date-picker v-model="produceTime2" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10">
                    </el-date-picker>
                    <div>设备名称：</div>
                    <el-cascader class="mg_right_10 width_190" ref="demoCascader" v-model="organizationValue"
                        :options="equipmentList" :props="defaultPropMore" @change="handleChange" :show-all-levels=false
                        collapse-tags clearable>
                    </el-cascader>
                    <el-button type="primary" class="alarmQuerySelectButton" @click="queryStopTimeList">查询
                    </el-button>
                </div>
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_120"
                        style=" background: #03CABE;border: none;" @click="addStopTime">添加</el-button>
                    <el-button type="primary" class="width_120" icon="el-icon-edit"
                        style=" background: #FEA313;border: none;" @click="editStopTime">修改
                    </el-button>
                    <el-button type="primary" icon="el-icon-delete" class="width_120 mg_left_10"
                        style=" background: #F75753;border: none;" @click="delstopTimeList">删除</el-button>
                </div>
            </div>
            <!-- table -->
            <div class="height_500">
                <el-table ref="multipleTable" :data="stopTimeList" tooltip-effect="dark" class="mg_top_20 "
                    @selection-change="handleSelectionChange" height="100%" border
                    :header-cell-style="{background:'#E2F0FE'}">
                     <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="equipmentID" label="设备名称" width=""></el-table-column>
                    <el-table-column prop="producedDay" label="生产日期" width="" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="name" label="计划停机时间" width="">
                        <el-table-column prop="PlannedStop1" label="计划停机" width=""></el-table-column>
                        <el-table-column prop="PlannedStop2" label="计划停机" width=""></el-table-column>
                        <el-table-column prop="PlannedStop3" label="计划停机" width=""></el-table-column>
                        <el-table-column prop="PlannedStop4" label="计划停机" width=""></el-table-column>
                    </el-table-column>
                    <el-table-column prop="name" label="计划停机时间">
                        <el-table-column prop="UnPlannedStop1" label="非计划停机"></el-table-column>
                        <el-table-column prop="UnPlannedStop2" label="非计划停机"></el-table-column>
                        <el-table-column prop="UnPlannedStop3" label="非计划停机"></el-table-column>
                        <el-table-column prop="UnPlannedStop4" label="非计划停机"></el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_end">
                <!-- <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
                <el-pagination layout="prev, pager, next" :total="pageQuantity2*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage2" @current-change="handleCurrentChangeTwo"></el-pagination>
            </div>
        </div>
        <!-- 编辑 -->
        <el-dialog :visible.sync="dialogTableVisibleEdit" top='8vh' width="40%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑</div>
            <el-form :inline="true" ref="form" :model="dayInfo" label-width="120px" class="mg_top_20">
                <el-form-item label="设备名称：" class="">
                    <el-input v-model="dayInfo.equipmentName" :readonly="true"></el-input>
                </el-form-item>
                <el-form-item label="操作工：" class="">
                    <el-input v-model="dayInfo.operatorName" :readonly="true"></el-input>
                </el-form-item>
                <el-form-item label="生产日期：" class="">
                    <!-- <el-input v-model="dayInfo.producedDay" :readonly="true"></el-input> -->
                    <el-date-picker v-model="dayInfo.producedDay" type="datetime" placeholder="选择日期时间" :readonly="true">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="班次：" class="">
                    <!-- <el-input v-model="dayInfo.teamID" :readonly="true"></el-input> -->
                    <el-select v-model="dayInfo.teamID" placeholder="请选择设备" disabled>
                        <el-option v-for="item in teamArray" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间：" class="">
                    <el-date-picker v-model="dayInfo.startTime" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间：" class="">
                    <el-date-picker v-model="dayInfo.endTime" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="物料型号：" class="">
                    <!-- <el-input v-model="dayInfo.workpieceTypeID" :readonly="true"></el-input> -->
                    <el-select v-model="dayInfo.workpieceTypeID" placeholder="" disabled>
                        <el-option v-for="item in workpieceTypeArray" :key="item.workpieceTypeID"
                            :label="item.workpieceTypeName" :value="item.workpieceTypeID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="今日计划：" class="">
                    <el-input v-model="dayInfo.currentPlanOutput" type="number"></el-input>
                </el-form-item>
                <el-form-item label="今日实际：" class="">
                    <el-input v-model="dayInfo.currentActualOutput" type="number"></el-input>
                </el-form-item>
                <el-form-item label="合格数量：" class="">
                    <el-input v-model="dayInfo.currentQualifiedOutput" type="number"></el-input>
                </el-form-item>
                <el-form-item label="录入日期：" class="">
                    <!-- <el-input v-model="dayInfo.recordTime"></el-input> -->
                    <el-date-picker v-model="dayInfo.recordTime" type="datetime" placeholder="选择日期时间" :readonly="true">
                    </el-date-picker>
                </el-form-item>
                <!-- </el-form>
            <el-form ref="dayInfo" :model="form" label-width="120px"> -->
                <el-form-item label="备注：" class="">
                    <el-input type="textarea" v-model="dayInfo.remarks" rows="4"></el-input>
                </el-form-item>
            </el-form>
            <div class="flex justify_end mg_right_50 pdb_20 mg_top_10">
                <el-button type="primary" @click="onEdit">确定</el-button>
            </div>
        </el-dialog>
        <!-- 添加 -->
        <el-dialog :visible.sync="dialogTableVisibleAdd" top='8vh' width="40%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加</div>
            <el-form :model="addDayForm" inline label-width="120px" class="mg_top_20">
                <el-form-item label="设备名称：" class="">
                    <el-cascader class="" ref="demoCascader" v-model="organizationValueSelect" :options="equipmentList"
                        :props="defaultProps" :show-all-levels=false collapse-tags clearable>
                    </el-cascader>
                </el-form-item>
                <el-form-item label="操作工：" class="">
                    <!-- <el-input v-model="dayInfo.operator" :readonly="true"></el-input> -->
                    <el-select v-model="addDayForm.operatorID" placeholder="请选择" class="">
                        <el-option v-for="item in operatorArray" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="生产日期：" class="">
                    <el-date-picker v-model="addDayForm.producedDay" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="班次：" class="">
                    <el-select v-model="addDayForm.teamID" placeholder="请选择">
                        <el-option v-for="item in teamArray" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间：" class="">
                    <el-date-picker v-model="addDayForm.startTime" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间：" class="">
                    <el-date-picker v-model="addDayForm.endTime" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="物料型号：" class="">
                    <el-select v-model="addDayForm.workpieceTypeID" placeholder="请选择">
                        <el-option v-for="item in workpieceTypeArray" :key="item.workpieceTypeID"
                            :label="item.workpieceTypeName" :value="item.workpieceTypeID"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="今日计划：" class="">
                    <el-input v-model="addDayForm.currentPlanOutput" type="number"></el-input>
                </el-form-item>
                <el-form-item label="今日实际：" class="">
                    <el-input v-model="addDayForm.currentActualOutput" type="number"></el-input>
                </el-form-item>
                <el-form-item label="合格数量：" class="">
                    <el-input v-model="addDayForm.currentQualifiedOutput" type="number"></el-input>
                </el-form-item>
                <el-form-item label="录入日期：" class="">
                    <el-date-picker v-model="addDayForm.recordTime" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注：" class="">
                    <el-input type="textarea" v-model="addDayForm.remarks" rows="4"></el-input>
                </el-form-item>
            </el-form>
            <div class="flex justify_end mg_right_50 pdb_20 mg_top_10">
                <el-button type="primary" @click="onAdd">确定</el-button>
            </div>
        </el-dialog>
        <!-- 数据录入 -->
        <el-dialog :visible.sync="dialogTableVisible" top='15vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">数据录入</div>
            <!-- <div class="flex align_center justify_center mg_top_6"> -->
            <el-form ref="form" :model="dataInfo" :label-width="labelWidth" class="mg_top_20">
                <div class="flex justify_center">
                    <div>
                        <el-form-item label="设备名称：" class="">
                            <!-- <el-input v-model="dataInfo.equipmentID"></el-input> -->
                            <el-select v-model="dataInfo.equipmentID" placeholder="请选择设备" disabled>
                                <el-option v-for="item in equipmentList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="计划停机1：" class="">
                            <el-input v-model="dataInfo.PlannedStop1" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="计划停机2：" class="">
                            <el-input v-model="dataInfo.PlannedStop2" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="计划停机3：" class="">
                            <el-input v-model="dataInfo.PlannedStop3" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="计划停机4：" class="">
                            <el-input v-model="dataInfo.PlannedStop4" type="number"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="生产日期：" class="">
                            <el-date-picker v-model="dataInfo.producedDay" type="datetime" placeholder="选择日期时间"
                                :readonly="true">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="非计划1：" class="">
                            <el-input v-model="dataInfo.UnPlannedStop1" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="非计划2：" class="">
                            <el-input v-model="dataInfo.UnPlannedStop2" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="非计划3：" class="">
                            <el-input v-model="dataInfo.UnPlannedStop3" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="非计划4：" class="">
                            <el-input v-model="dataInfo.UnPlannedStop4" type="number"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="flex justify_end mg_right_50 pdb_20 mg_top_10">
                <el-button type="primary" @click="onSubmit">录入</el-button>
            </div>
        </el-dialog>
        <!-- 数据添加 -->
        <el-dialog :visible.sync="showAddStopTimeFlag" top='15vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">数据录入</div>
            <!-- <div class="flex align_center justify_center mg_top_6"> -->
            <el-form ref="form" :model="addStopTimeData" :label-width="labelWidth" class="mg_top_20">
                <div class="flex justify_center">
                    <div>
                        <el-form-item label="设备名称：" class="">
                            <el-cascader class="" ref="demoCascader" v-model="organizationValueSelect2"
                                :options="equipmentList" :props="defaultProps" :show-all-levels=false collapse-tags
                                clearable>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="计划停机1：" class="">
                            <el-input v-model="addStopTimeData.PlannedStop1" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="计划停机2：" class="">
                            <el-input v-model="addStopTimeData.PlannedStop2" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="计划停机3：" class="">
                            <el-input v-model="addStopTimeData.PlannedStop3" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="计划停机4：" class="">
                            <el-input v-model="addStopTimeData.PlannedStop4" type="number"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="生产日期：" class="">
                            <el-date-picker v-model="addStopTimeData.producedDay" type="datetime" placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="非计划1：" class="">
                            <el-input v-model="addStopTimeData.UnPlannedStop1" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="非计划2：" class="">
                            <el-input v-model="addStopTimeData.UnPlannedStop2" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="非计划3：" class="">
                            <el-input v-model="addStopTimeData.UnPlannedStop3" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="非计划4：" class="">
                            <el-input v-model="addStopTimeData.UnPlannedStop4" type="number"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="flex justify_end mg_right_50 pdb_20 mg_top_10">
                <el-button type="primary" @click="addStopTimeSubmit">录入</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {
        productionInforTeamSelect,
        productionInforEquipmentOutputByDayQuery,
        productionInforEquipmentOutputByDayModification,
        productionInforEquipmentOutputByDayDelete,
        productionInforEquipmentProductionTimeQuery,
        productionInforEquipmentSelect,
        productionInforEquipmentProductionTimeModification,
        productionInforEquipmentProductionTimeDelete,
        productionInforOperatorSelect,
        productionInforWorkpieceTypeSelect,
        productionInforEquipmentOutputByDayAdd,
        productionInforEquipmentProductionTimeAdd
    } from "@/api/api_productionManagement";
    export default {
        data() {
            return {
                labelWidth: '100px',
                value: '',
                produceTime: null,
                produceTime2: null,
                teamValue: '',
                teamArray: [],
                operatorArray: [],
                workpieceTypeArray: [],
                dayQueryList: [],
                dialogTableVisibleEdit: false,
                dialogTableVisibleAdd: false,
                dialogTableVisible: false,
                showAddStopTimeFlag: false,
                dayInfo: {},
                form: {},
                addDayForm: {},
                equipmentValue: [],
                equipmentList: [],
                tableData: [],
                multipleSelection: [],
                multipleSelection2: [],
                currentPage: 1,
                currentPage2: 1,
                pageSize: 10,
                pageQuantity: 0,
                pageQuantity2: 0,
                stopTimeList: [],
                dataInfo: {},
                addStopTimeData: {},
                currentData: [],
                // standardData: [],
                defaultProps: {
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    emitPath: false
                },
                defaultPropMore: {
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true, //多选任一级
                    // emitPath: false
                },
                organizationValue: [],
                permission: {},
                currentType: '',

                organizationValueSelect: '',
                organizationValueSelect2: ''
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.productionInforEquipmentSelect();
            this.productionInforTeamSelect();
            this.productionInforOperatorSelect();
            this.productionInforWorkpieceTypeSelect();
            this.productionInforEquipmentOutputByDayQuery();
            // this.productionInforEquipmentProductionTimeQuery();
        },
        methods: {
            // 班组下拉选
            async productionInforTeamSelect() {
                var param = {}
                const selectRes = await productionInforTeamSelect(param);
                this.teamArray = selectRes.data.TeamID;
            },
            // 操作员下拉选
            async productionInforOperatorSelect() {
                var param = {
                    permission: this.permission
                }
                const selectRes = await productionInforOperatorSelect(param);
                this.operatorArray = selectRes.data;
            },
            // 物料型号下拉选
            async productionInforWorkpieceTypeSelect() {
                var param = {
                    permission: this.permission
                }
                const selectRes = await productionInforWorkpieceTypeSelect(param);
                this.workpieceTypeArray = selectRes.data.rows;
            },
            // 选择设备级联下拉框
            async productionInforEquipmentSelect() {
                var param = {
                    permission: this.permission,
                }
                const res = await productionInforEquipmentSelect(param);
                this.equipmentList = res.data.Tree;
                this.standardArr = res.data.organizationPermissionAll;
                this.checkData = res.data.organizationPermissionAll[0];
                // this.currentType = res.data.organizationPermission[0].Type;
                // this.currentData = res.data.organizationPermission[0].ID;
                // for (var i = 0; i < this.standardArr.length; i++) {
                //     if (this.standardArr[i].Type == 'E') {
                //         this.currentData = this.standardArr[i].ID;
                //         // this.standardData = this.standardArr[i].ID;
                //     }
                // }
                this.productionInforEquipmentProductionTimeQuery();
            },
            handleChange() {
                if (this.organizationValue.length == 0) {
                    this.checkData = this.standardArr[0];
                    return
                }
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                // console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                this.checkData = testData;
            },
            async productionInforEquipmentOutputByDayQuery() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage
                }
                if (this.produceTime != null) {
                    param.startProducedDay = this.produceTime[0];
                    param.endProducedDay = this.produceTime[1];
                }
                if (this.teamValue != null && this.teamValue != "") {
                    param.teamID = this.teamValue;
                }
                const selectRes = await productionInforEquipmentOutputByDayQuery(param);
                this.dayQueryList = selectRes.data.rows;
                this.pageQuantity = selectRes.data.pageQuantity;
            },
            handleCurrentChange(val) {
                // this.PageNumber = val;
                this.productionInforEquipmentOutputByDayQuery(); //查询table数据的方法
            },
            dayQuery() {
                this.productionInforEquipmentOutputByDayQuery();
            },
            editdayQuery() {
                if (this.multipleSelection.join(',') == '') {
                    this.$message({
                        message: '警告：请选择需要修改的数据！！！',
                        type: 'warning'
                    });
                    return
                }
                if (this.multipleSelection.length > 1) {
                    this.$message({
                        message: '警告：请选择一条需要修改的数据！！！',
                        type: 'warning'
                    });
                    return
                }
                this.dialogTableVisibleEdit = true;
                this.dayInfo = this.multipleSelection[0];
                // this.dayInfo.id = this.dayInfo.teamID

            },
            handleSelectionChangeDay(val) {
                this.multipleSelection = val;
            },
            addStopTime() {
                this.addStopTimeData = {};
                this.organizationValueSelect2 = '';
                this.showAddStopTimeFlag = true;
            },
            onEdit() {
                this.productionInforEquipmentOutputByDayModification()
            },
            onAdd() {
                var param = {
                    "EquipmentID": this.organizationValueSelect
                }
                this.addDayForm.organization = param;
                this.productionInforEquipmentOutputByDayAdd()
            },
            async productionInforEquipmentOutputByDayAdd() {
                const res = await productionInforEquipmentOutputByDayAdd(this.addDayForm);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleAdd = false;
                    this.addDayForm = {};
                    this.organizationValueSelect = '';
                    this.productionInforEquipmentOutputByDayQuery()
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            },

            async productionInforEquipmentOutputByDayModification() {
                const selectRes = await productionInforEquipmentOutputByDayModification(this.dayInfo);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleEdit = false;
                    this.productionInforEquipmentOutputByDayQuery()
                } else {
                   this.$message.error(selectRes.message);
                }

            },
            delDayQuery() {
                if (this.multipleSelection.join(',') == '') {
                    this.$message({
                        message: '警告：请选择需要删除的数据！！！',
                        type: 'warning'
                    });
                    return
                }
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var currentDelList = {
                        producedDay: [],
                        teamID: [],
                        operatorID: [],
                        equipmentID: [],
                        workpieceTypeID: []
                    }
                    for (var i = 0; i < this.multipleSelection.length; i++) {
                        currentDelList.producedDay.push(this.tools.formatDate(this.multipleSelection[i]
                            .producedDay, 'yyyy-MM-dd hh:mm:ss').toString())
                        currentDelList.teamID.push(this.multipleSelection[i].teamID)
                        currentDelList.operatorID.push(this.multipleSelection[i].operatorID)
                        currentDelList.equipmentID.push(this.multipleSelection[i].equipmentID)
                        currentDelList.workpieceTypeID.push(this.multipleSelection[i].workpieceTypeID)
                    }
                    currentDelList.producedDay = currentDelList.producedDay.join(',')
                    currentDelList.teamID = currentDelList.teamID.join(',')
                    currentDelList.operatorID = currentDelList.operatorID.join(',')
                    currentDelList.equipmentID = currentDelList.equipmentID.join(',')
                    currentDelList.workpieceTypeID = currentDelList.workpieceTypeID.join(',')


                    this.currentPage = this.delData.delData(this.dayQueryList.length, this.pageQuantity, this
                        .currentPage,this.multipleSelection.length)
                    // console.log(this.currentPage)
                    this.productionInforEquipmentOutputByDayDelete(currentDelList)

                }).catch(() => {

                });

            },
            async productionInforEquipmentOutputByDayDelete(param) {
                const selectRes = await productionInforEquipmentOutputByDayDelete(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.productionInforEquipmentOutputByDayQuery()
                } else {
                     this.$message.error(selectRes.message);
                }
            },

            // 生产时间明细表--开始
            async productionInforEquipmentProductionTimeQuery() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage2,
                    organization: this.checkData
                }
                if (this.produceTime2 != null) {
                    param.startProducedDay = this.produceTime2[0];
                    param.endProducedDay = this.produceTime2[1];
                }
                // if (this.currentData.length != 0) {
                //     var paramEquipmentID = {
                //         Type: this.currentType,
                //         ID: this.currentData
                //     }
                //     param.organization = paramEquipmentID;
                // }
                const selectRes = await productionInforEquipmentProductionTimeQuery(param);
                this.stopTimeList = selectRes.data;
                this.pageQuantity2 = selectRes.pageQuantity;
            },

            queryStopTimeList() {
                this.productionInforEquipmentProductionTimeQuery();
            },
            handleSelectionChange(val) {
                this.multipleSelection2 = val;
            },
            editStopTime() {
                if (this.multipleSelection2.join(',') == '') {
                    this.$message({
                        message: '警告：请选择需要修改的数据！！！',
                        type: 'warning'
                    });
                    return
                }
                if (this.multipleSelection2.length > 1) {
                    this.$message({
                        message: '警告：请选择一条需要修改的数据！！！',
                        type: 'warning'
                    });
                    return
                }

                this.dialogTableVisible = true;
                this.dataInfo = this.multipleSelection2[0];
            },
            // 点击录入，修改数据
            onSubmit() {
                var changeData = {};
                changeData.equipmentID = this.dataInfo.equipmentID;
                changeData.producedDay = this.dataInfo.producedDay;
                changeData.recordTime = this.dataInfo.recordTime;
                changeData.unplannedStop = {
                    UnPlannedStop1: this.dataInfo.UnPlannedStop1,
                    UnPlannedStop2: this.dataInfo.UnPlannedStop2,
                    UnPlannedStop3: this.dataInfo.UnPlannedStop3,
                    UnPlannedStop4: this.dataInfo.UnPlannedStop4
                }
                changeData.plannedStop = {
                    PlannedStop1: this.dataInfo.PlannedStop1,
                    PlannedStop2: this.dataInfo.PlannedStop2,
                    PlannedStop3: this.dataInfo.PlannedStop3,
                    PlannedStop4: this.dataInfo.PlannedStop4
                }
                this.productionInforEquipmentProductionTimeModification(changeData)
            },
            // 点击录入，添加数据
            addStopTimeSubmit() {
                var changeData = {};
                var param = {
                    "EquipmentID": this.organizationValueSelect2
                }
                changeData.organization = param;
                changeData.producedDay = this.addStopTimeData.producedDay;
                changeData.recordTime = this.addStopTimeData.recordTime;
                changeData.unplannedStop = {
                    UnPlannedStop1: this.addStopTimeData.UnPlannedStop1,
                    UnPlannedStop2: this.addStopTimeData.UnPlannedStop2,
                    UnPlannedStop3: this.addStopTimeData.UnPlannedStop3,
                    UnPlannedStop4: this.addStopTimeData.UnPlannedStop4
                }
                changeData.plannedStop = {
                    PlannedStop1: this.addStopTimeData.PlannedStop1,
                    PlannedStop2: this.addStopTimeData.PlannedStop2,
                    PlannedStop3: this.addStopTimeData.PlannedStop3,
                    PlannedStop4: this.addStopTimeData.PlannedStop4
                }
                this.productionInforEquipmentProductionTimeAdd(changeData)
            },
            async productionInforEquipmentProductionTimeAdd(param) {
                const selectRes = await productionInforEquipmentProductionTimeAdd(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.showAddStopTimeFlag = false;
                    this.productionInforEquipmentProductionTimeQuery()
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            async productionInforEquipmentProductionTimeModification(param) {
                // var param = {};
                const selectRes = await productionInforEquipmentProductionTimeModification(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisible = false;
                    this.productionInforEquipmentProductionTimeQuery()
                } else {
                   this.$message.error(selectRes.message);
                }

            },
            handleCurrentChangeTwo() {
                this.productionInforEquipmentProductionTimeQuery();
            },
            delstopTimeList() {
                if (this.multipleSelection2.join(',') == '') {
                    this.$message({
                        message: '警告：请选择需要删除的数据！！！',
                        type: 'warning'
                    });
                    return
                }

                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var param = {
                        equipmentID: [],
                        producedDay: []
                    }
                    for (var i = 0; i < this.multipleSelection2.length; i++) {
                        param.equipmentID.push(this.multipleSelection2[i].equipmentID)
                        param.producedDay.push(this.tools.formatDate(this.multipleSelection2[i].producedDay,
                            'yyyy-MM-dd hh:mm:ss').toString())
                    }
                    param.equipmentID = param.equipmentID.join(',');
                    param.producedDay = param.producedDay.join(',');
                    console.log(param)

                     this.currentPage2 = this.delData.delData(this.stopTimeList.length, this.pageQuantity2, this
                        .currentPage2,this.multipleSelection2.length)
                    this.productionInforEquipmentProductionTimeDelete(param);
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            async productionInforEquipmentProductionTimeDelete(param) {
                const selectRes = await productionInforEquipmentProductionTimeDelete(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.productionInforEquipmentProductionTimeQuery()
                } else {
                   this.$message.error(selectRes.message);
                }

            },
            addDayQueryList() {
                this.addDayForm = {};
                this.organizationValueSelect = '';
                this.dialogTableVisibleAdd = true;
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style scoped>
    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item__content {
        width: 220px;
    }
</style>