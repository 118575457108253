<!-- 故障分析 -->
<template>
    <div class="page_container ">
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">故障分析</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30">
            <!-- select下拉框 -->
            <div class="flex pdt_20 fn_size16 justify_between">
                <div class="flex">
                    <el-select v-model="value" placeholder="分厂选择" class="mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value" placeholder="工段选择" class="mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value" placeholder="设备选择" class="mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value" placeholder="设备名称" class="mg_right_10">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="alarmQuerySelectButton flex justify_center align_center">故障现象
                    </el-button>
                </div>
                <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出</el-button>
            </div>
            <!-- div文字 -->
            <div class="pdt_20 fn_size16 flex">可能的故障原因及解决措施</div>
            <!-- table表格 -->
            <div>
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" max-height="310">
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="date" label="可能性（%）" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="故障大类"></el-table-column>
                    <el-table-column prop="name" label="故障类别"></el-table-column>
                    <el-table-column prop="date" label="故障代码"></el-table-column>
                    <el-table-column prop="date" label="故障描述"></el-table-column>
                    <el-table-column prop="date" label="故障现象"></el-table-column>
                    <el-table-column prop="address" label="故障原因" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="address" label="故障解决措施" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="提交人"></el-table-column>
                    <el-table-column prop="date" label="更新时间"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD  pdt_0 pdb_0" @click="dialogTableVisible = true">
                                <span style="border-bottom: 1px solid #009BFD;">More</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- echarts -->
            <div class="mg_top_20" style="height: 600px;">
                <div id="chartColumn" class="width_100 height_100"></div>
            </div>
        </div>
        <!-- 弹框 -->
        <el-dialog :visible.sync="dialogTableVisible" top='10vh' width="25%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">设备连接状态</div>
            <div class="flex align_center justify_center mg_bottom_20 mg_top_6">
                <el-form ref="form" :model="form" label-width="90px">
                    <el-form-item label="站点名称：">
                        <el-select v-model="form.siteName" placeholder="选择分厂">
                            <el-option label="分厂一" value="shanghai"></el-option>
                            <el-option label="分厂二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工段名称：">
                        <el-select v-model="form.sectionName" placeholder="选择分段">
                            <el-option label="分段一" value="shanghai"></el-option>
                            <el-option label="分段二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="设备名称：">
                        <el-select v-model="form.equipmentName" placeholder="选择设备">
                            <el-option label="设备一" value="shanghai"></el-option>
                            <el-option label="设备二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="参数名称：">
                        <el-select v-model="form.parameterName" placeholder="选择参数">
                            <el-option label="参数一" value="shanghai"></el-option>
                            <el-option label="参数二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="故障大类：">
                        <el-select v-model="form.faultCategory" placeholder="选择大类">
                            <el-option label="大类一" value="shanghai"></el-option>
                            <el-option label="大类二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="故障类别：">
                        <el-select v-model="form.faultType" placeholder="选择类别">
                            <el-option label="类别一" value="shanghai"></el-option>
                            <el-option label="类别二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="故障代码：">
                        <el-input v-model="form.faultCode"></el-input>
                    </el-form-item>
                    <el-form-item label="故障现象：">
                        <el-input type="textarea" v-model="form.faultPhenomenon" placeholder="故障现象描述" :autosize="{ minRows: 5, maxRows: 5}"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">提交</el-button>
                        <el-button type="primary">取消</el-button>
                      </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import * as echarts from 'echarts';
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                input: '',
                dialogTableVisible: false,
                form: {
                    siteName: '',
                    sectionName: '',
                    equipmentName: '',
                    parameterName: '',
                    faultCategory: '',
                    faultType: '',
                    faultCode: '',
                    faultPhenomenon: ''
                },
                options: [],
                tableData: [{
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }],
                multipleSelection: [],
                chartData: [{
                    name: "flare",
                    children: [{
                            name: "analysis",
                            children: [{
                                    name: "cluster",
                                    children: [{
                                            name: "AgglomerativeCluster",
                                            value: 3938
                                        },
                                        {
                                            name: "CommunityStructure",
                                            value: 3812
                                        },
                                        {
                                            name: "HierarchicalCluster",
                                            value: 6714
                                        },
                                        {
                                            name: "MergeEdge",
                                            value: 743
                                        }
                                    ]
                                },
                                {
                                    name: "graph",
                                    children: [{
                                            name: "BetweennessCentrality",
                                            value: 3534
                                        },
                                        {
                                            name: "LinkDistance",
                                            value: 5713
                                        },
                                        {
                                            name: "MaxFlowMinCut",
                                            value: 7840
                                        },
                                        {
                                            name: "ShortestPaths",
                                            value: 5914
                                        },
                                        {
                                            name: "SpanningTree",
                                            value: 3416
                                        }
                                    ]
                                },
                                {
                                    name: "optimization",
                                    children: [{
                                        name: "AspectRatioBanker",
                                        value: 7074
                                    }]
                                }
                            ]
                        },
                        {
                            name: "animate",
                            children: [{
                                    name: "Easing",
                                    value: 17010
                                },
                                {
                                    name: "FunctionSequence",
                                    value: 5842
                                },
                                {
                                    name: "interpolate",
                                    children: [{
                                            name: "ArrayInterpolator",
                                            value: 1983
                                        },
                                        {
                                            name: "ColorInterpolator",
                                            value: 2047
                                        },
                                        {
                                            name: "DateInterpolator",
                                            value: 1375
                                        },
                                        {
                                            name: "Interpolator",
                                            value: 8746
                                        },
                                        {
                                            name: "MatrixInterpolator",
                                            value: 2202
                                        },
                                        {
                                            name: "NumberInterpolator",
                                            value: 1382
                                        },
                                        {
                                            name: "ObjectInterpolator",
                                            value: 1629
                                        },
                                        {
                                            name: "PointInterpolator",
                                            value: 1675
                                        },
                                        {
                                            name: "RectangleInterpolator",
                                            value: 2042
                                        }
                                    ]
                                },
                                {
                                    name: "ISchedulable",
                                    value: 1041
                                },
                                {
                                    name: "Parallel",
                                    value: 5176
                                },
                                {
                                    name: "Pause",
                                    value: 449
                                },
                                {
                                    name: "Scheduler",
                                    value: 5593
                                },
                                {
                                    name: "Sequence",
                                    value: 5534
                                },
                                {
                                    name: "Transition",
                                    value: 9201
                                },
                                {
                                    name: "Transitioner",
                                    value: 19975
                                },
                                {
                                    name: "TransitionEvent",
                                    value: 1116
                                },
                                {
                                    name: "Tween",
                                    value: 6006
                                }
                            ]
                        },
                        {
                            name: "data",
                            children: [{
                                    name: "converters",
                                    children: [{
                                            name: "Converters",
                                            value: 721
                                        },
                                        {
                                            name: "DelimitedTextConverter",
                                            value: 4294
                                        },
                                        {
                                            name: "GraphMLConverter",
                                            value: 9800
                                        },
                                        {
                                            name: "IDataConverter",
                                            value: 1314
                                        },
                                        {
                                            name: "JSONConverter",
                                            value: 2220
                                        }
                                    ]
                                },
                                {
                                    name: "DataField",
                                    value: 1759
                                },
                                {
                                    name: "DataSchema",
                                    value: 2165
                                },
                                {
                                    name: "DataSet",
                                    value: 586
                                },
                                {
                                    name: "DataSource",
                                    value: 3331
                                },
                                {
                                    name: "DataTable",
                                    value: 772
                                },
                                {
                                    name: "DataUtil",
                                    value: 3322
                                }
                            ]
                        },
                        {
                            name: "display",
                            children: [{
                                    name: "DirtySprite",
                                    value: 8833
                                },
                                {
                                    name: "LineSprite",
                                    value: 1732
                                },
                                {
                                    name: "RectSprite",
                                    value: 3623
                                },
                                {
                                    name: "TextSprite",
                                    value: 10066
                                }
                            ]
                        },
                        {
                            name: "flex",
                            children: [{
                                name: "FlareVis",
                                value: 4116
                            }]
                        },
                        {
                            name: "physics",
                            children: [{
                                    name: "DragForce",
                                    value: 1082
                                },
                                {
                                    name: "GravityForce",
                                    value: 1336
                                },
                                {
                                    name: "IForce",
                                    value: 319
                                },
                                {
                                    name: "NBodyForce",
                                    value: 10498
                                },
                                {
                                    name: "Particle",
                                    value: 2822
                                },
                                {
                                    name: "Simulation",
                                    value: 9983
                                },
                                {
                                    name: "Spring",
                                    value: 2213
                                },
                                {
                                    name: "SpringForce",
                                    value: 1681
                                }
                            ]
                        },
                        {
                            name: "query",
                            children: [{
                                    name: "AggregateExpression",
                                    value: 1616
                                },
                                {
                                    name: "And",
                                    value: 1027
                                },
                                {
                                    name: "Arithmetic",
                                    value: 3891
                                },
                                {
                                    name: "Average",
                                    value: 891
                                },
                                {
                                    name: "BinaryExpression",
                                    value: 2893
                                },
                                {
                                    name: "Comparison",
                                    value: 5103
                                },
                                {
                                    name: "CompositeExpression",
                                    value: 3677
                                },
                                {
                                    name: "Count",
                                    value: 781
                                },
                                {
                                    name: "DateUtil",
                                    value: 4141
                                },
                                {
                                    name: "Distinct",
                                    value: 933
                                },
                                {
                                    name: "Expression",
                                    value: 5130
                                },
                                {
                                    name: "ExpressionIterator",
                                    value: 3617
                                },
                                {
                                    name: "Fn",
                                    value: 3240
                                },
                                {
                                    name: "If",
                                    value: 2732
                                },
                                {
                                    name: "IsA",
                                    value: 2039
                                },
                                {
                                    name: "Literal",
                                    value: 1214
                                },
                                {
                                    name: "Match",
                                    value: 3748
                                },
                                {
                                    name: "Maximum",
                                    value: 843
                                },
                                {
                                    name: "methods",
                                    children: [{
                                            name: "add",
                                            value: 593
                                        },
                                        {
                                            name: "and",
                                            value: 330
                                        },
                                        {
                                            name: "average",
                                            value: 287
                                        },
                                        {
                                            name: "count",
                                            value: 277
                                        },
                                        {
                                            name: "distinct",
                                            value: 292
                                        },
                                        {
                                            name: "div",
                                            value: 595
                                        },
                                        {
                                            name: "eq",
                                            value: 594
                                        },
                                        {
                                            name: "fn",
                                            value: 460
                                        },
                                        {
                                            name: "gt",
                                            value: 603
                                        },
                                        {
                                            name: "gte",
                                            value: 625
                                        },
                                        {
                                            name: "iff",
                                            value: 748
                                        },
                                        {
                                            name: "isa",
                                            value: 461
                                        },
                                        {
                                            name: "lt",
                                            value: 597
                                        },
                                        {
                                            name: "lte",
                                            value: 619
                                        },
                                        {
                                            name: "max",
                                            value: 283
                                        },
                                        {
                                            name: "min",
                                            value: 283
                                        },
                                        {
                                            name: "mod",
                                            value: 591
                                        },
                                        {
                                            name: "mul",
                                            value: 603
                                        },
                                        {
                                            name: "neq",
                                            value: 599
                                        },
                                        {
                                            name: "not",
                                            value: 386
                                        },
                                        {
                                            name: "or",
                                            value: 323
                                        },
                                        {
                                            name: "orderby",
                                            value: 307
                                        },
                                        {
                                            name: "range",
                                            value: 772
                                        },
                                        {
                                            name: "select",
                                            value: 296
                                        },
                                        {
                                            name: "stddev",
                                            value: 363
                                        },
                                        {
                                            name: "sub",
                                            value: 600
                                        },
                                        {
                                            name: "sum",
                                            value: 280
                                        },
                                        {
                                            name: "update",
                                            value: 307
                                        },
                                        {
                                            name: "variance",
                                            value: 335
                                        },
                                        {
                                            name: "where",
                                            value: 299
                                        },
                                        {
                                            name: "xor",
                                            value: 354
                                        },
                                        {
                                            name: "-",
                                            value: 264
                                        }
                                    ]
                                },
                                {
                                    name: "Minimum",
                                    value: 843
                                },
                                {
                                    name: "Not",
                                    value: 1554
                                },
                                {
                                    name: "Or",
                                    value: 970
                                },
                                {
                                    name: "Query",
                                    value: 13896
                                },
                                {
                                    name: "Range",
                                    value: 1594
                                },
                                {
                                    name: "StringUtil",
                                    value: 4130
                                },
                                {
                                    name: "Sum",
                                    value: 791
                                },
                                {
                                    name: "Variable",
                                    value: 1124
                                },
                                {
                                    name: "Variance",
                                    value: 1876
                                },
                                {
                                    name: "Xor",
                                    value: 1101
                                }
                            ]
                        },
                        {
                            name: "scale",
                            children: [{
                                    name: "IScaleMap",
                                    value: 2105
                                },
                                {
                                    name: "LinearScale",
                                    value: 1316
                                },
                                {
                                    name: "LogScale",
                                    value: 3151
                                },
                                {
                                    name: "OrdinalScale",
                                    value: 3770
                                },
                                {
                                    name: "QuantileScale",
                                    value: 2435
                                },
                                {
                                    name: "QuantitativeScale",
                                    value: 4839
                                },
                                {
                                    name: "RootScale",
                                    value: 1756
                                },
                                {
                                    name: "Scale",
                                    value: 4268
                                },
                                {
                                    name: "ScaleType",
                                    value: 1821
                                },
                                {
                                    name: "TimeScale",
                                    value: 5833
                                }
                            ]
                        },
                        {
                            name: "util",
                            children: [{
                                    name: "Arrays",
                                    value: 8258
                                },
                                {
                                    name: "Colors",
                                    value: 10001
                                },
                                {
                                    name: "Dates",
                                    value: 8217
                                },
                                {
                                    name: "Displays",
                                    value: 12555
                                },
                                {
                                    name: "Filter",
                                    value: 2324
                                },
                                {
                                    name: "Geometry",
                                    value: 10993
                                },
                                {
                                    name: "heap",
                                    children: [{
                                        name: "FibonacciHeap",
                                        value: 9354
                                    }, {
                                        name: "HeapNode",
                                        value: 1233
                                    }]
                                },
                                {
                                    name: "IEvaluable",
                                    value: 335
                                },
                                {
                                    name: "IPredicate",
                                    value: 383
                                },
                                {
                                    name: "IValueProxy",
                                    value: 874
                                },
                                {
                                    name: "math",
                                    children: [{
                                            name: "DenseMatrix",
                                            value: 3165
                                        },
                                        {
                                            name: "IMatrix",
                                            value: 2815
                                        },
                                        {
                                            name: "SparseMatrix",
                                            value: 3366
                                        }
                                    ]
                                },
                                {
                                    name: "Maths",
                                    value: 17705
                                },
                                {
                                    name: "Orientation",
                                    value: 1486
                                },
                                {
                                    name: "palette",
                                    children: [{
                                            name: "ColorPalette",
                                            value: 6367
                                        },
                                        {
                                            name: "Palette",
                                            value: 1229
                                        },
                                        {
                                            name: "ShapePalette",
                                            value: 2059
                                        },
                                        {
                                            name: "SizePalette",
                                            value: 2291
                                        }
                                    ]
                                },
                                {
                                    name: "Property",
                                    value: 5559
                                },
                                {
                                    name: "Shapes",
                                    value: 19118
                                },
                                {
                                    name: "Sort",
                                    value: 6887
                                },
                                {
                                    name: "Stats",
                                    value: 6557
                                },
                                {
                                    name: "Strings",
                                    value: 22026
                                }
                            ]
                        },
                        {
                            name: "vis",
                            children: [{
                                    name: "axis",
                                    children: [{
                                            name: "Axes",
                                            value: 1302
                                        },
                                        {
                                            name: "Axis",
                                            value: 24593
                                        },
                                        {
                                            name: "AxisGridLine",
                                            value: 652
                                        },
                                        {
                                            name: "AxisLabel",
                                            value: 636
                                        },
                                        {
                                            name: "CartesianAxes",
                                            value: 6703
                                        }
                                    ]
                                },
                                {
                                    name: "controls",
                                    children: [{
                                            name: "AnchorControl",
                                            value: 2138
                                        },
                                        {
                                            name: "ClickControl",
                                            value: 3824
                                        },
                                        {
                                            name: "Control",
                                            value: 1353
                                        },
                                        {
                                            name: "ControlList",
                                            value: 4665
                                        },
                                        {
                                            name: "DragControl",
                                            value: 2649
                                        },
                                        {
                                            name: "ExpandControl",
                                            value: 2832
                                        },
                                        {
                                            name: "HoverControl",
                                            value: 4896
                                        },
                                        {
                                            name: "IControl",
                                            value: 763
                                        },
                                        {
                                            name: "PanZoomControl",
                                            value: 5222
                                        },
                                        {
                                            name: "SelectionControl",
                                            value: 7862
                                        },
                                        {
                                            name: "TooltipControl",
                                            value: 8435
                                        }
                                    ]
                                },
                                {
                                    name: "data",
                                    children: [{
                                            name: "Data",
                                            value: 20544
                                        },
                                        {
                                            name: "DataList",
                                            value: 19788
                                        },
                                        {
                                            name: "DataSprite",
                                            value: 10349
                                        },
                                        {
                                            name: "EdgeSprite",
                                            value: 3301
                                        },
                                        {
                                            name: "NodeSprite",
                                            value: 19382
                                        },
                                        {
                                            name: "render",
                                            children: [{
                                                    name: "ArrowType",
                                                    value: 698
                                                },
                                                {
                                                    name: "EdgeRenderer",
                                                    value: 5569
                                                },
                                                {
                                                    name: "IRenderer",
                                                    value: 353
                                                },
                                                {
                                                    name: "ShapeRenderer",
                                                    value: 2247
                                                }
                                            ]
                                        },
                                        {
                                            name: "ScaleBinding",
                                            value: 11275
                                        },
                                        {
                                            name: "Tree",
                                            value: 7147
                                        },
                                        {
                                            name: "TreeBuilder",
                                            value: 9930
                                        }
                                    ]
                                },
                                {
                                    name: "events",
                                    children: [{
                                            name: "DataEvent",
                                            value: 2313
                                        },
                                        {
                                            name: "SelectionEvent",
                                            value: 1880
                                        },
                                        {
                                            name: "TooltipEvent",
                                            value: 1701
                                        },
                                        {
                                            name: "VisualizationEvent",
                                            value: 1117
                                        }
                                    ]
                                },
                                {
                                    name: "legend",
                                    children: [{
                                            name: "Legend",
                                            value: 20859
                                        },
                                        {
                                            name: "LegendItem",
                                            value: 4614
                                        },
                                        {
                                            name: "LegendRange",
                                            value: 10530
                                        }
                                    ]
                                },
                                {
                                    name: "operate",
                                    children: [{
                                            name: "distortion",
                                            children: [{
                                                    name: "BifocalDistortion",
                                                    value: 4461
                                                },
                                                {
                                                    name: "Distortion",
                                                    value: 6314
                                                },
                                                {
                                                    name: "FisheyeDistortion",
                                                    value: 3444
                                                }
                                            ]
                                        },
                                        {
                                            name: "encoder",
                                            children: [{
                                                    name: "ColorEncoder",
                                                    value: 3179
                                                },
                                                {
                                                    name: "Encoder",
                                                    value: 4060
                                                },
                                                {
                                                    name: "PropertyEncoder",
                                                    value: 4138
                                                },
                                                {
                                                    name: "ShapeEncoder",
                                                    value: 1690
                                                },
                                                {
                                                    name: "SizeEncoder",
                                                    value: 1830
                                                }
                                            ]
                                        },
                                        {
                                            name: "filter",
                                            children: [{
                                                    name: "FisheyeTreeFilter",
                                                    value: 5219
                                                },
                                                {
                                                    name: "GraphDistanceFilter",
                                                    value: 3165
                                                },
                                                {
                                                    name: "VisibilityFilter",
                                                    value: 3509
                                                }
                                            ]
                                        },
                                        {
                                            name: "IOperator",
                                            value: 1286
                                        },
                                        {
                                            name: "label",
                                            children: [{
                                                    name: "Labeler",
                                                    value: 9956
                                                },
                                                {
                                                    name: "RadialLabeler",
                                                    value: 3899
                                                },
                                                {
                                                    name: "StackedAreaLabeler",
                                                    value: 3202
                                                }
                                            ]
                                        },
                                        {
                                            name: "layout",
                                            children: [{
                                                    name: "AxisLayout",
                                                    value: 6725
                                                },
                                                {
                                                    name: "BundledEdgeRouter",
                                                    value: 3727
                                                },
                                                {
                                                    name: "CircleLayout",
                                                    value: 9317
                                                },
                                                {
                                                    name: "CirclePackingLayout",
                                                    value: 12003
                                                },
                                                {
                                                    name: "DendrogramLayout",
                                                    value: 4853
                                                },
                                                {
                                                    name: "ForceDirectedLayout",
                                                    value: 8411
                                                },
                                                {
                                                    name: "IcicleTreeLayout",
                                                    value: 4864
                                                },
                                                {
                                                    name: "IndentedTreeLayout",
                                                    value: 3174
                                                },
                                                {
                                                    name: "Layout",
                                                    value: 7881
                                                },
                                                {
                                                    name: "NodeLinkTreeLayout",
                                                    value: 12870
                                                },
                                                {
                                                    name: "PieLayout",
                                                    value: 2728
                                                },
                                                {
                                                    name: "RadialTreeLayout",
                                                    value: 12348
                                                },
                                                {
                                                    name: "RandomLayout",
                                                    value: 870
                                                },
                                                {
                                                    name: "StackedAreaLayout",
                                                    value: 9121
                                                },
                                                {
                                                    name: "TreeMapLayout",
                                                    value: 9191
                                                }
                                            ]
                                        },
                                        {
                                            name: "Operator",
                                            value: 2490
                                        },
                                        {
                                            name: "OperatorList",
                                            value: 5248
                                        },
                                        {
                                            name: "OperatorSequence",
                                            value: 4190
                                        },
                                        {
                                            name: "OperatorSwitch",
                                            value: 2581
                                        },
                                        {
                                            name: "SortOperator",
                                            value: 2023
                                        }
                                    ]
                                },
                                {
                                    name: "Visualization",
                                    value: 16540
                                }
                            ]
                        }
                    ]
                }]
            }
        },
        mounted() {
            this.drawShape();
        },
        methods: {
            onSubmit() {
                console.log('submit!');
            },
            drawShape() {
                /**数据集柱状图 */
                this.chartColumn = echarts.init(document.getElementById('chartColumn'));
                this.chartColumn.setOption({
                    tooltip: {
                        trigger: 'item',
                        triggerOn: 'mousemove'
                    },
                    series: [{
                        type: 'tree',

                        data: this.chartData,

                        left: '2%',
                        right: '2%',
                        top: '8%',
                        bottom: '20%',

                        symbol: 'emptyCircle',

                        orient: 'vertical',

                        expandAndCollapse: true,

                        label: {
                            position: 'top',
                            rotate: -90,
                            verticalAlign: 'middle',
                            align: 'right',
                            fontSize: 9
                        },

                        leaves: {
                            label: {
                                position: 'bottom',
                                rotate: -90,
                                verticalAlign: 'middle',
                                align: 'left'
                            }
                        },

                        animationDurationUpdate: 750
                    }]
                });
            }
        }
    }
</script>

<style scoped>
    .alarmQuerySelectButton {
        width: 140px;
        height: 36px;
        background: #009BFD;
    }

</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>