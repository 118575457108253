<!-- 设备管理-备件管理-备件领用 -->
<template>
    <div class="page_container ">
        <div class="box_head1">
            <span>备件领用</span>
            <div class="flex  align_center mg_left_50 fn_size14">
                <div class="">仓库：</div>
                <!-- <el-input v-model="queryValue" style="width: 180px;" placeholder="请输入"></el-input> -->
                <el-select v-model="queryValue" clearable placeholder="请选择" class="width_150">
                    <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key"
                        :value="item.value"> </el-option>
                </el-select>
                <el-button type="primary" class="mg_left_10" size="medium" @click="queryTest">查询
                </el-button>
            </div>
        </div>

        <!-- <div class="bgc_FFFFFF "> -->
        <!-- <div class="flex divMargin align_center">
                    <div class="spanWidth">开始时间：</div>
                    <div>
                        <el-input v-model="input" style="width: 180px;"></el-input>
                    </div>
                </div> -->
        <!-- <div class="flex align_center">
                    <div class="spanWidth">结束时间：</div>
                    <div>
                        <el-input v-model="input" style="width: 150px;"></el-input>
                    </div>
                </div> -->
        <!-- </div> -->
        <div class="bgc_FFFFFF pdl30 pdr30">
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_140 bgc_03CABE buttonBorder" style="background-color: #03CABE;"
                        @click="gotoFaultDetails">添加</el-button>
                    <!-- <el-button type="primary" icon="el-icon-edit" class="width_140 bgc_FEA313 buttonBorder" style="background-color: #FEA313;" @click="gotoChangeData">编辑</el-button> -->
                    <!-- <el-button type="primary" icon="el-icon-delete"
                        class="width_140 mg_left_10 bgc_F75753 buttonBorder" style="background-color: #F75753;" @click="deleteNum">删除</el-button> -->
               <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
                </div>
                <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> -->
            </div>
            <!-- table -->
            <div class="height_500">
                <el-table ref="multipleTable" :data="queryList" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @row-click="rowClick" @selection-change="handleSelectionChange" height="409"
                    :default-sort="{prop: 'date', order: 'descending'}"
                     @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width=""  ></el-table-column> -->
                    <!-- <el-table-column prop="authSort" label="当前审批顺序" width=""  ></el-table-column> -->
                    <!-- <el-table-column prop="isAuth" label="是否审批" width="" :formatter="formatBoolean"></el-table-column> -->
                    <!-- <el-table-column prop="doAuth" label="是否可以进行审批" width="" :formatter="formatBoolean"></el-table-column> -->
                    <el-table-column prop="applyTime" label="申请时间" :formatter="formatDate" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="applyPerson" label="申请人" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="warehouse" label="申请仓库"></el-table-column>
                    <el-table-column prop="department" label="申请部门"></el-table-column>
                    <el-table-column prop="orderStatus_name" label="工单状态"></el-table-column>
                    <el-table-column prop="authRoleName" label="当前审批角色"></el-table-column>
                    <el-table-column prop="totalPrice" label="领用总价"></el-table-column>
                    <!-- <el-table-column prop="remarks" label="备注"  show-overflow-tooltip></el-table-column> -->
                    <el-table-column label="详情" width="150">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoDetails(scope.row)">详细</span>
                            </el-button>

                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.orderStatus = 0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoChangeData(scope.row)">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="deleteNum(scope.row.ID)">删除</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span v-if="scope.row.doAuth" style="border-bottom: 1px solid #009BFD;"
                                    @click="approvalOperation(scope.row)">审批</span>
                                <!-- <span v-else style="border-bottom: 1px solid #009BFD;" >已审批</span> -->
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="flex justify_end">
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>

            <!-- <el-dialog :visible.sync="dialogVisible" width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">领用明细</div>
                <div class="flex flex_column "> 
                <div class="flex justify_center mg_top_20">
                    <el-form :model="formDetail">
                        <el-form-item label="备件名称：" :label-width="formLabelWidth">
                            <el-input v-model="formDetail.orderID" type="number" autocomplete="off" class="">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备件名称：" :label-width="formLabelWidth">
                            <el-input v-model="formDetail.sparepartID" type="number" autocomplete="off" class="">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备件名称：" :label-width="formLabelWidth">
                            <el-input v-model="formDetail.sparepartName" type="number" autocomplete="off" class="">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备件型号：" :label-width="formLabelWidth">
                            <el-date-picker v-model="formDetail.sparepartModel" type="datetime" placeholder="选择日期时间" class="">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="单位：" :label-width="formLabelWidth">
                            <el-date-picker v-model="formDetail.unit" type="datetime" placeholder="选择日期时间" class="">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="申请数量" :label-width="formLabelWidth">
                            <el-date-picker v-model="formDetail.applyQuan" type="datetime" placeholder="选择日期时间" class="">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="单价：" :label-width="formLabelWidth">
                            <el-date-picker v-model="formDetail.unitPrice" type="datetime" placeholder="选择日期时间" class="">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="申请总价：" :label-width="formLabelWidth" class="">
                            <el-checkbox v-model="formDetail.totalPrice" class="width_300 text_align_left">
                            </el-checkbox>
                        </el-form-item>
                        <el-form-item label="备注" :label-width="formLabelWidth">
                            <el-input v-model="formDetail.remarks" type="number" autocomplete="off" class="">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleDispatch = false">取 消</el-button>
                    <el-button type="primary" @click="confirmChoicesEdit">确 定</el-button>
                </div>
            </el-dialog> -->
            <!-- 选择框 -->
            <div class="bgc_E2F0FE flex align_end  height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">领用明细
                </div>
            </div>

            <div class="mg_top_22">
                <el-table ref="multipleTable" :data="formDetail" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @selection-change="handleSelectionChange" height="242"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width=""></el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width=""></el-table-column>
                    <el-table-column prop="sparePartModel" label="备件型号" width=""></el-table-column>
                    <el-table-column prop="unit" label="单位" width=""></el-table-column>
                    <el-table-column prop="applyQuan" label="申请数量"></el-table-column>
                    <el-table-column prop="unitPrice" label="单价"></el-table-column>
                    <el-table-column prop="totalPrice" label="申请总价"></el-table-column>
                    <el-table-column prop="remarks" label="备注"></el-table-column>
                </el-table>
                <div class="flex justify_end">
                    <el-pagination layout="prev, pager, next" :total="pageQuantity1*pageSize1" :page-size="pageSize1"
                        :current-page.sync="currentPage1" @current-change="handleCurrentChange1"></el-pagination>
                </div>

            </div>
            <el-dialog :visible.sync="dialogVisibleAuth" width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">审批</div>
                <div class="height_110 flex justify_center align_center">
                    <el-radio-group v-model="approvalListValue" class="">
                        <el-radio v-for="item in approvalList" :key="item.value" :label="item.value">
                            {{item.description}}
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleAuth = false">取 消</el-button>
                    <el-button type="primary" @click="confirm">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="编辑" :visible.sync="dialogVisibleChange" width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑</div> -->
                <div class=" flex justify_center align_center mg_top_10">
                    <el-form :inline="true" :model="changeInline" label-width="110px" style="width: 65%;">
                        <el-form-item label="工单编号：">
                            <el-input v-model="changeInline.orderID" placeholder="工单编号" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="项目编号：" >
                            <el-input v-model="changeInline.projectID" placeholder="项目编号" class="width_180" disabled></el-input>
                        </el-form-item> -->
                        <el-form-item label="当前审批顺序：">
                            <el-input v-model="changeInline.authSort" placeholder="当前审批顺序" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="申请仓库：">
                            <!-- <el-input v-model="changeInline.warehouse" placeholder="申请仓库" class="width_180"></el-input> -->
                            <el-select v-model="changeInline.warehouse" placeholder="请选择仓库" class="width_180">
                                <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key"
                                    :value="item.value"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="申请部门：">
                            <el-input v-model="changeInline.department" placeholder="申请部门" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="领用总价：">
                            <el-input v-model="changeInline.totalPrice" placeholder="总价" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input type="textarea" v-model="changeInline.remarks" placeholder="备注区域"
                                class="width_180">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="changeConfirm">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="详情" :visible.sync="dialogVisibleDetail" width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">详情</div> -->
                <div class=" flex justify_center align_center mg_top_15">
                    <el-form :inline="true" :model="detailInline" label-width="140px" style="width: 65%;">
                        <el-form-item label="工单编号：">
                            <el-input v-model="detailInline.orderID" placeholder="工单编号" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="项目编号：" >
                            <el-input v-model="detailInline.projectID" placeholder="项目编号" class="width_180" disabled></el-input>
                        </el-form-item> -->
                        <el-form-item label="当前审批顺序：">
                            <el-input v-model="detailInline.authSort" placeholder="当前审批顺序" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="申请仓库：">
                            <el-input v-model="detailInline.warehouse" placeholder="申请仓库" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="申请部门：">
                            <el-input v-model="detailInline.department" placeholder="申请部门" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="领用总价：">
                            <el-input v-model="detailInline.totalPrice" placeholder="总价" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input type="textarea" v-model="detailInline.remarks" placeholder="备注区域"
                                class="width_180" disabled></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button type="primary" @click="dialogVisibleDetail = false">关闭</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        // sparePartPickInsert,
        sparePartWarehouseQuery, //仓库选择下拉框
        sparePartPickDelete,
        sparePartPickUpdate,
        sparePartPickQuery,
        multiAuthorizeResultQuery1,
        sparePartPickMultiAuthQueryLimit,
        sparePartPickAuthorize,
        sparePartPickDetailInsert,
        sparePartPickDetailDelete,
        sparePartPickDetailUpdate,
        sparePartPickDetailQuery,
        sparePartPickDetailQueryLimit,
    } from '@/api/api_deviceManagement.js'
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                userID: '',
                queryValue: '',
                warehouseSelectList: [], //仓库下拉框 使用的数组
                tabIndex: 0,
                value: '',
                input: '',
                options: [],
                multipleSelection: [],
                //页码
                currentPage: 1,
                currentPage1: 1,
                pageSize: 10,
                pageSize1: 5,
                pageQuantity: 0,
                pageQuantity1: 0,

                rowList: [],
                rowData: [],
                queryList: [],
                formDetail: [],
                dialogVisible: false,
                dialogVisibleAuth: false,
                dialogVisibleChange: false,
                dialogVisibleDetail: false,
                formLabelWidth: '120px',
                approvalListValue: undefined,
                authResult: '',
                approvalList: [],
                delResult: '',
                warehouse: '',
                detailInline: {},
                changeInline: {},

            }
        },
        created() {
            // this.changeInline.ID = this.multipleSelection[0].ID;
            // this.changeInline.warehouse = this.multipleSelection[0].warehouse;
            // this.changeInline.department = this.multipleSelection[0].department;
            // this.changeInline.remarks = this.multipleSelection[0].remarks;
            // this.changeInline.totalPrice = this.multipleSelection[0].totalPrice;
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.sparePartPickMultiAuthQueryLimit()

            //页面一加载，就初始化仓库下拉框的数据
            this.sparePartWarehouseQuery();
        },

        methods: {
 //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '备件领用')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            //仓库下拉框
            async sparePartWarehouseQuery() {

                //封装参数
                var param = {
                    userID: this.userID,
                };

                //调用接口
                const selectRes = await sparePartWarehouseQuery(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.warehouseSelectList = selectRes.data;
                }
            },

            //查询按钮
            queryTest() {
                this.warehouse = this.queryValue
                this.currentPage = 1;
                this.sparePartPickMultiAuthQueryLimit()
            },
            //领用分页查询
            async sparePartPickMultiAuthQueryLimit() {

                /*                 if (this.queryValue!='') {
                                    this.currentPage = 1;
                                } */
                var param = {
                    // ID
                    // orderID:this.queryValue,
                    // startApplyTime
                    // endApplyTime
                    // applyPerson
                    // warehouse:this.queryValue,
                    // department
                    // orderStatus
                    // authSort
                    // isAuth
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    userID: this.userID
                }
                if (this.queryValue != '') {
                    param.warehouse = this.queryValue
                }
                const selectRes = await sparePartPickMultiAuthQueryLimit(param)
                this.pageQuantity = selectRes.data.pageQuantity;
                this.queryList = selectRes.data.array
                // console.log(selectRes);
            },


            //领用新增
            async sparePartPickInsert() {
                var param = {
                    // applyPerson:,
                    // warehouse:,
                    // department:,
                    // totalPrice:,
                    // orderDetailJSON:,
                    // remarks:,
                    // isAuth:,
                    userID: this.userID,
                }
                const selectRes = sparePartPickInsert(param)
            },
            //审批

            async multiAuthorizeResultQuery1() {
                const selectRes = await multiAuthorizeResultQuery1();
                // console.log("22",selectRes);
                this.approvalList = selectRes.array;
                // this.approvalListValue = selectRes.array[0].value;
                this.dialogVisibleAuth = true;
            },
            async sparePartPickAuthorize() {

                var param = {
                    ID: this.rowList.ID,
                    userID: this.userID,
                    authResult: this.approvalListValue
                }
                const selectRes = await sparePartPickAuthorize(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisibleAuth = false;
                    this.sparePartPickMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                    this.dialogVisibleAuth = false
                };
                this.authResult = selectRes
                // console.log(this.authResult);
            },
            approvalOperation(row) {
                this.rowList = row
                this.multiAuthorizeResultQuery1()
                this.dialogVisibleAuth = true
            },
            confirm() {
                if(this.approvalListValue == undefined){
                    this.$message.error('请选择是否通过！');  
                }else{
                    this.sparePartPickAuthorize()
                    this.dialogVisibleAuth = false
                    this.approvalListValue = undefined
                }
            },


            //详细 分页
            async sparePartPickDetailQueryLimit() {
                var param = {
                    orderID: this.rowData.orderID,
                    pageSize: this.pageSize1,
                    pageNumber: this.currentPage1,
                }
                const selectRes = await sparePartPickDetailQueryLimit(param)
                this.pageQuantity1 = selectRes.pageQuantity
                this.formDetail = selectRes.rows
                // console.log(this.formDetail);

            },
            gotoDetails(row) {
                this.detailInline = row
                this.dialogVisibleDetail = true
            },
            // table 点击
            rowClick(row, event, column) {
                this.rowData = row

                this.sparePartPickDetailQueryLimit()
            },

            handleSelectionChange(val) {

                this.multipleSelection = val;
                console.log("id", this.multipleSelection[0])
            },
            handleChange(id) {
                this.tabIndex = id;
            },
            //添加申请
            gotoFaultDetails() {
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartCollection/addSparePartCollection',
                })
            },
            //修改申请
            gotoChangeData(row) {
                //  if(this.multipleSelection.length > 1){
                //      this.$message({
                //         message: '您只能选择一条数据',
                //         type: 'warning'
                //     });
                //     this.multipleSelection=[]
                // }else if(this.multipleSelection.length == 0){
                //     this.$message({
                //         message: '请选择一条数据',
                //         type: 'warning'
                //     });
                // }else
                // {

                this.changeInline = row;
                this.dialogVisibleChange = true;
                // this.$set(this.changeInline,ID,this.multipleSelection[0].ID)
                // this.$set(this.changeInline,warehouse,this.multipleSelection[0].warehouse)
                // this.$set(this.changeInline,department,this.multipleSelection[0].department)
                // this.$set(this.changeInline,remarks,this.multipleSelection[0].remarks)
                // this.$set(this.changeInline,totalPrice,this.multipleSelection[0].totalPrice)

                // };


                // this.$router.push({
                //     path: '/deviceManagement/sparePartManagement/sparePartCollection/addSparePartCollection',
                // })
            },
            async sparePartPickUpdate() {
                var param = {
                    ID: this.changeInline.ID,
                    warehouse: this.changeInline.warehouse,
                    department: this.changeInline.department,
                    remarks: this.changeInline.remarks,
                    totalPrice: this.changeInline.totalPrice,
                }
                const selectRes = await sparePartPickUpdate(param)
                this.updatedResult = selectRes.data.result
                if (this.updatedResult) {
                    this.$message({
                        message: selectRes.message,
                        type: "success"
                    })
                    this.dialogVisibleChange = false
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: "warning"
                    })
                    this.dialogVisibleChange = false
                }
                this.multipleSelection = []
                this.sparePartPickMultiAuthQueryLimit()

            },

            //删除
            deleteNum(id) {
                // if(this.multipleSelection.length>1){
                //     this.$message({
                //         message:"您只能选择一条数据",
                //         type:'warning'
                //     })
                // }else if(this.multipleSelection.length == 0){
                //     this.$message({
                //         message:"警告！请您选择一条数据",
                //         type:'warning'
                //     })
                // }else{
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sparePartPickDelete(id);
                    this.currentPage = this.delData.delData(this.queryList.length, this.pageQuantity, this
                        .currentPage);
                }).catch(() => {

                });
                // }

            },
            async sparePartPickDelete(id) {
                var param = {
                    ID: id
                }
                const selectRes = await sparePartPickDelete(param);
                this.delResult = selectRes.data.result
                if (this.delResult) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    })
                    this.sparePartPickMultiAuthQueryLimit()
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    })
                    this.sparePartPickMultiAuthQueryLimit()
                }
            },

            changeConfirm() {
                this.sparePartPickUpdate()
            },
            cancel(){
                this.dialogVisibleChange = false;
                this.sparePartPickMultiAuthQueryLimit();
            },

            //日期格式
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            //布尔值格式
            formatBoolean(row, column, cellValue) {
                var ret = '' //你想在页面展示的值
                if (cellValue) {
                    ret = "是" //根据自己的需求设定
                } else {
                    ret = "否"
                }
                return ret;
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.sparePartPickMultiAuthQueryLimit(); //查询table数据的方法
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.sparePartPickDetailQueryLimit(); //查询table数据的方法
            },
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }
</style>
<style lang="less" scoped>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
    ::v-deep .el-form-item {
    margin-bottom: 10px;
}
</style>