<!-- A设备管理-备件管理-备件台账-添加备件 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">备件信息</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="备件编号：">
                    <el-input v-model="formInline.user" placeholder="自动生成" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="备件名称：">
                    <el-input v-model="formInline.user" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="类型：">
                    <el-select v-model="formInline.region" placeholder="" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="规格型号：">
                    <el-input v-model="formInline.user" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="生产厂商：">
                    <el-select v-model="formInline.region" placeholder="" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="供应商：">
                    <el-select v-model="formInline.region" placeholder="" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="更换周期：">
                    <el-input v-model="formInline.user" placeholder="" class="width_80 mg_right_22"></el-input>
                    <el-select v-model="formInline.region" placeholder="" class="width_80">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="参考价：">
                    <el-input v-model="formInline.user" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="计量单位：">
                    <el-select v-model="formInline.region" placeholder="" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="库存下限：">
                    <el-input v-model="formInline.user" placeholder="" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="库存上限：">
                    <el-input v-model="formInline.user" placeholder="" class="width_180"></el-input>
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">关联文档</div>
            <el-form :model="formInline" label-width="110px" class="mg_top_30" style="width:65%; ">
                <el-form-item label="备件编号：">
                    <el-input placeholder="文件总大小不能超过30M" v-model="formInline.user">
                        <template slot="append">浏览</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="上传提示：">
                    <div></div>
                </el-form-item>
                <el-form-item label="文档类型：">
                    <el-select v-model="formInline.region" placeholder="设备台账" style="width: 100%;">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">关联图片</div>
            <el-form :model="formInline" label-width="110px" style="width:65%; " class="mg_top_30">
                <el-form-item label="图片上传：" prop="user">
                    <!-- <el-input placeholder="请输入内容" v-model="input2">
                        <template slot="append">图片上传</template>
                    </el-input> -->
                    <el-upload action="https://jsonplaceholder.typicode.com/posts/" :on-success="handleAvatarSuccess"
                        :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple
                        :limit="3" :on-exceed="handleExceed">
                        <el-input placeholder="请输入内容" v-model="fileList[0].name" disabled slot="trigger">
                            <template slot="append">图片上传</template>
                        </el-input>
                    </el-upload>
                </el-form-item>
                <!--    <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
                    :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple
                    :limit="3" :on-exceed="handleExceed" :file-list="fileList">
                    
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload> -->

            </el-form>
            <div class="flex mg_top_30 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                formInline: {
                    user: '',
                    region: ''
                },
                input2: '',
                fileList: [{
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }, ]
            }
        },
        mounted() {},
        methods: {
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },
            handleRemove(file, fileList) { //文件列表移除文件时的钩子
                console.log(file, fileList);
            },
            handlePreview(file) { //点击文件列表中已上传的文件时的钩子
                console.log(file);
            },
            handleExceed(files, fileList) { //文件超出个数限制时的钩子
                this.$message.warning(
                    `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) { //删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            handleAvatarSuccess(res, file) {
                // this.imageUrl = URL.createObjectURL(file.raw);
            },
        }

    }
</script>
<style scoped>
    
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    } 
</style>