<!-- A设备管理-巡检管理-巡检标准-添加报修 -->
<template>
    <div class="page_container" v-loading="pageLoad">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">点检标准</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="点检工具：">
                    <el-input v-model="formInline.inspTool" placeholder="" class="width_180 "></el-input>
                </el-form-item>
                <!-- <el-form-item label="记录人：">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180 "></el-input>
                </el-form-item> -->
                <el-form-item label="点检标准名称：">
                    <el-input v-model="formInline.inspName" placeholder="" class="width_180 "></el-input>
                </el-form-item>
                <el-form-item label="点检标准类型：">
                    <el-select v-model="formInline.InspStdType" placeholder="" class="width_180 "
                        @change="changeInspStdType" :disabled="disabled">
                        <el-option v-for="item in inspectionStandardType" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" label-width="110px" class="flex flex_wrap " style="width: 65%;">
                <el-form-item label="点检目的：">
                    <el-input v-model="formInline.inspTarget" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- 按钮 -->
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="请输入" class="width_180" :readonly="true">
                    </el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" placeholder="请输入" class="width_180" :readonly="true">
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="设备类型：" class=" ">
                    <el-input v-model="formInline.deviceType" placeholder="请输入" class="width_180" :readonly="true">
                    </el-input>
                </el-form-item> -->
                <el-form-item class=" ">
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;"
                        @click="changeDevice">
                        <!--   -->
                        选择设备</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="flex mg_top_10" style="width: 65%;">
                <el-button type="primary" icon="el-icon-circle-plus-outline" >选择设备</el-button>
            </div> -->
            <div class="flex mg_top_10" style="width: 65%;">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addStandard">添加标准</el-button>
            </div>
            <!-- table -->
            <div style="width: 65%;" class="height_270">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_10 " height="100%"
                    border style="width: 100%;">
                    <!-- <el-table-column type="index" width="55"></el-table-column> -->
                    <el-table-column label="删除"  width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="deltableData(scope.$index)">删除</span>
                            </el-button>
                            <!-- <el-button type="text" class="color_009BFD">
                            <span style="border-bottom: 1px solid #009BFD;">修改</span>
                        </el-button> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="inspDetailType" label="类型详情标准" width=""></el-table-column>
                    <el-table-column prop="inspDetailCont" label="标准详情内容" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="inspDetailStandard" label="标准详情标准" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.inspDetailType == 2">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="lookImg(scope.row.inspDetailStandard)">查看</span>
                            </el-button>
                            <span v-else>{{scope.row.inspDetailStandard}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width=""></el-table-column>
                    <!-- <el-table-column prop="name" label="上限" width=""></el-table-column>
                <el-table-column prop="name" label="默认值" width=""></el-table-column> -->
                </el-table>
            </div>


            <div class="flex mg_top_30 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="submit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>

        <!-- 添加标准--弹框 -->
        <el-dialog title="添加标准" :visible.sync="dialogVisibleAddStandard" top='10vh' width="30%">
            <!-- pdl30 pdr30 -->
            <div class="flex justify_center standard_h mg_top_10">
                <el-form :model="formStandard"  :label-width="formLabelWidth"> 
                    <el-form-item label="类型详情标准：">
                        <el-select v-model="formStandard.inspDetailType" placeholder="请选择" class=" "
                            @change="changeType" :disabled="readOnly">
                            <el-option v-for="item in standardTypeDetails" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="标准详情内容：">
                        <el-input v-model="formStandard.inspDetailCont" type="textarea" :row="4" class=""></el-input>
                    </el-form-item>
                    <!-- 布尔型 -->
                    <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspDetailType==0">
                        <el-checkbox v-model="formStandard.inspDetailStandard" class="width_180 text_align_left"
                            true-label='是' false-label="否">
                        </el-checkbox>
                        <!-- <el-radio-group v-model="formStandard.inspDetailStandard" class="width_180">
                            <el-radio :label="true">是</el-radio>
                            <el-radio :label="false">否</el-radio>
                        </el-radio-group> -->
                    </el-form-item>
                    <!-- 数值型 -->
                    <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspDetailType==1">
                        <el-input v-model="formStandard.inspDetailStandard" class=""></el-input>
                    </el-form-item>
                    <!-- 图片型 -->
                    <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspDetailType==2">
                        <el-upload class="avatar-uploader" action="666666" :before-upload="beforeAvatarUpload"
                            :show-file-list="false" :on-success="handleAvatarSuccess">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <el-button type="primary" class="mg_top_10" style="background: #2FB060;" @click="uplodeImg">上传
                        </el-button>
                    </el-form-item>
                    <!-- 勾选型 -->
                    <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspDetailType==3">
                        <div class="">
                            <el-button type="primary" icon="el-icon-plus" class="add_btn"
                                @click="addInspDetailStandard"></el-button>
                            <el-checkbox-group v-model="formStandard.inspDetailStandard"
                                class="flex flex_column align_start detailStandard_h mg_top_10">
                                <el-checkbox :label="item.standars" v-for="(item,index) in inspDetailStandardList"
                                    :key="index">
                                    <label>
                                        <el-input class="width_150" v-model="item.standars"></el-input>
                                    </label>
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input v-model="formStandard.remarks" type="textarea" :row="4" autocomplete="off" class="">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialogVisibleAddStandard = false">取 消</el-button>
                <el-button type="primary" @click="confirmAddStandard">确 定</el-button>
            </div>
            <!-- <div>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div> -->
        </el-dialog>
        <!-- 选择设备-弹框 -->
        <el-dialog title="设备选择" :visible.sync="dialogTableVisible" top='10vh' width="40%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">设备选择</div> -->
            <!-- pdl30 pdr30 -->
            <div class="flex flex_column height_370 mg_top_10">
                <div class="flex mg_top_10">
                    <el-cascader size="medium" v-model="organizationValue" placeholder="请选择" :options="organization"
                        ref="demoCascader" :props="defaultProps" class=" el_cascader_width"
                        @change="handleChangeCascader" :show-all-levels="false" collapse-tags filterable></el-cascader>
                    <el-button type="primary" class="mg_left_10" size="medium" @click="queryDevice">查询</el-button>
                </div>
                <el-table :data="equInfoList" style="width: 100%" class="mg_top_15" height="100%" v-loading="load">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <el-table-column prop="EquipmentID" label="设备编号" width="">
                    </el-table-column>
                    <el-table-column prop="EquipmentName" label="设备名称" width="">
                    </el-table-column>
                    <el-table-column prop="EquipmentModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="EquipmentTypeName" label="设备类型" width="">
                    </el-table-column>
                    <el-table-column prop="UserDepartmentName" label="所属部门" width="">
                    </el-table-column>
                    <el-table-column prop="InstallationPosition" label="设备位置" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="choiceEquipment(scope.row)">选择</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </el-dialog>
        <!-- 查看图片 -->
        <el-dialog title="查看图片" :visible.sync="dialogLookImg" width="30%" top="20vh">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">查看图片</div> -->
            <img :src="imgUrl" alt="" class="imgLook">
        </el-dialog>


    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    // 
    import {
        inspectionStandardInsert,
        inspectionStandardTypeQuery,
        uploadInspectionImage,
        inspectionLoadImage
    } from "@/api/api_deviceManagement";
    import {
        equipmentLedgerOrganizationSelect,
        equipmentLedgerQueryLimit,
    } from "@/api/api_systemConfig";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                checked: false,
                tableData: [],
                inspDetailJSON: {
                    "array": []
                },
                formInline: {
                    inspTool: '', //点检工具
                    //点检详情
                    inspName: '', //点检标准名称
                    inspTarget: '', //点检目的
                    oprPerson: '', //记录人
                    InspStdType: '', //点检标准类型
                    deviceID: '', //设备编号
                    deviceName: '', //设备名称
                    //用户编号
                    remarks: '', //备注
                },
                input2: '',
                inspectionStandardType: [],
                dialogVisibleAddStandard: false,
                formStandard: {
                    inspDetailType: '',
                    inspDetailCont: '',
                    inspDetailStandard: '',
                    remarks: '',
                },
                formLabelWidth: '120px',
                readOnly: false,
                standardTypeDetails: [{
                    value: '0',
                    label: '布尔型'
                }, {
                    value: '1',
                    label: '数值型'
                }, {
                    value: '2',
                    label: '图片型'
                }, {
                    value: '3',
                    label: '勾选型'
                }],
                imageUrl: '',
                permission: {},
                userID: '',
                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0,
                dialogTableVisible: false,


                organizationValue: [],
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                checkData: {},
                equInfoList: [],
                currentEqu: [],
                imgInfo: {
                    fileName: '',
                    format: '',
                    deviceID: '',
                    content: ''
                },
                disabled: false,
                inspDetailStandardList: [],

                dialogLookImg: false,
                imgUrl: '',
                load: false,
                pageLoad: true,
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
                this.userName = userInfo.userName;
            }
            this.equipmentLedgerOrganizationSelect();
            this.inspectionStandardTypeQuery();
        },
        methods: {
            // 点检标准类型
            async inspectionStandardTypeQuery() {
                const selectRes = await inspectionStandardTypeQuery();
                this.inspectionStandardType = selectRes.rows;
                this.formInline.InspStdType = selectRes.rows[0].value;
            },
            changeInspStdType() {
                //  var testData = this.tableData.filter(itmer => itmer.inspDeviceID == this.formInline.InspStdType);
                // console.log(testData)

            },
            submit() {

                this.inspectionStandardInsert();
                // this.$router.go(-1);
                // inspectionStandardInsert
            },
            async inspectionStandardInsert() {
                // this.tableData.inspDetailStandard = '是';
                console.log(this.formInline)
                console.log(this.tableData)
                console.log(this.userID)
                this.inspDetailJSON.array = this.tableData;
                var param = {
                    inspTool: this.formInline.inspTool,
                    inspDetailJSON: this.inspDetailJSON,
                    inspName: this.formInline.inspName,
                    inspTarget: this.formInline.inspTarget,
                    // formInline.oprPerson
                    oprPerson: this.userName,
                    // oprPerson: this.formInline.oprPerson,
                    InspStdType: this.formInline.InspStdType,
                    deviceID: this.formInline.deviceID,
                    userID: this.userID,
                    remarks: this.formInline.remarks
                }
                console.log(param)

                const res = await inspectionStandardInsert(param);

                console.log(res)

                if (res && res.data && res.data.result) {
                    //this.dialogVisible = false;
                    // this.
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.$router.go(-1);

                    //this.userConfigUserList();

                } else {
                    this.$message({
                        message: res.message,
                        type: 'error',
                    });
                }


            },
            colsePage() {
                this.$router.go(-1);
            },
            addStandard() {
                if (!this.formInline.deviceID) {
                    this.$message({
                        message: '警告哦，请选择设备',
                        type: 'warning'
                    });
                    return
                }
                // console.log(this.formInline.InspStdType)
                if (this.formInline.InspStdType == 0) {
                    this.readOnly = true;
                } else {
                    this.readOnly = false;
                }
                // this.formStandard.inspDetailType = this.standardTypeDetails[0].value;
                this.formStandard = {
                    inspDetailType: this.standardTypeDetails[0].value,
                    inspDetailStandard: false,
                    inspDetailCont: '',
                    remarks: ''
                }
                // this.formStandard.inspDetailStandard = false;
                // this.formStandard.inspDetailCont = '';
                // this.formStandard.remarks = '';
                this.imgInfo = {
                    fileName: '',
                    format: '',
                    deviceID: '',
                    content: ''
                }
                this.imageUrl = '';
                this.inspDetailStandardList = [];
                this.dialogVisibleAddStandard = true;
            },
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
            },
            // 
            changeType() {
                this.formStandard.inspDetailCont = '';
                this.formStandard.remarks = '';
                if (this.formStandard.inspDetailType == 0) {
                    this.formStandard.inspDetailStandard = false;
                } else if (this.formStandard.inspDetailType == 3) {
                    this.formStandard.inspDetailStandard = [];
                } else {
                    this.formStandard.inspDetailStandard = '';
                }
            },
            confirmAddStandard() {
                console.log(this.formStandard);
                if (this.formStandard.inspDetailType == 2 && this.formStandard.inspDetailStandard == '') {
                    this.$message({
                        message: '请上传图片',
                        type: 'warning'
                    });
                    return
                }
                if (this.formStandard.inspDetailType == 3) {
                    this.formStandard.inspDetailStandard = this.formStandard.inspDetailStandard.join(',')
                }
                this.tableData.push(this.formStandard);
                this.dialogVisibleAddStandard = false;
                // if (this.tableData.length != 0) {
                this.disabled = true;
                // }

                // this.formStandard = {}
            },
            // 选择设备
            changeDevice() {
                this.equInfoList = [];
                this.pageQuantity = 0;
                // this.equipmentLedgerOrganizationSelect();
                this.equipmentLedgerQuery();
                this.dialogTableVisible = true;
            },
            async equipmentLedgerOrganizationSelect() {
                this.load = true;
                var param = {
                    permission: this.permission
                };
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organization = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];
                this.pageLoad = false;
                // this.equipmentLedgerQuery();
            },
            choiceEquipment(row) {
                this.$confirm('确认选择此设备', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(row);
                    this.formInline.deviceID = row.EquipmentID;
                    this.formInline.deviceName = row.EquipmentName;
                    // this.formInline.deviceType = row.EquipmentType;
                    // this.formInline.deviceModel = row.EquipmentModel;
                    // this.formInline.deviceDepartment = row.Department;
                    // this.formInline.deviceLocation = row.InstallationPosition;
                    // this.formInline
                    // this.currentEqu = [];
                    this.dialogTableVisible = false;
                    // this.currentEqu.push(row);
                    // console.log(this.currentEqu)
                }).catch(() => {});
            },
            deltableData(row) {
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(row)
                    this.tableData.splice(row, 1)
                    console.log(this.tableData);
                    if (this.tableData.length == 0) {
                        this.disabled = false;
                    }
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            handleCurrentChange(val) {
                this.equipmentLedgerQuery(); //查询table数据的方法
            },
            queryDevice() {
                // console.log(this.organizationValue)
                // // if (this.organizationValue.join(',') == '') {
                // //     this.checkData = this.standardArr[0];
                // // }
                // console.log(this.checkData)
                this.currentPage = 1;
                this.equipmentLedgerQuery();
            },
            async equipmentLedgerQuery() {
                this.load = true;
                var param = {
                    organization: this.checkData,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.equInfoList = selectRes.data;
                this.pageQuantity = selectRes.pageQuantity;
                this.load = false;
            },
            handleChangeCascader() {
                if (this.organizationValue.length == 0) {
                    this.checkData = this.standardArr[0];
                    return
                }
                // console.log(this.organizationValue)
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)

            },
            addInspDetailStandard() {
                var test = {
                    standars: ''
                };
                this.inspDetailStandardList.push(test)
            },
            async lookImg(path) {
                var param = {
                    path: path
                }
                const res = await inspectionLoadImage(param);
                this.imgUrl = 'data:image/' + res.format + ';base64,' + res.content;
                this.dialogLookImg = true
            },
            // 上传图片
            async uplodeImg() {
                this.imgInfo.deviceID = this.formInline.deviceID;
                this.imgInfo.content = this.imgInfo.content.split(',').slice(-1)[0];
                const selectRes = await uploadInspectionImage(this.imgInfo);
                if (selectRes.data.result) {
                    this.formStandard.inspDetailStandard = selectRes.data.path;
                    if (selectRes.messageEnable) {
                        this.$message({
                            message: selectRes.message,
                            type: 'success'
                        });
                    }
                } else {
                    if (selectRes.messageEnable) {
                        this.$message.error(selectRes.message);
                    }
                }
            },
            beforeAvatarUpload(file) {
                console.log(file)
                let that = this;
                that.file = file;
                that.fileNameTest = file.name;
                var fileType = file.name.split('.').slice(-1);
                that.imgInfo.fileName = file.name.split('.').slice(0)[0];
                that.imgInfo.format = fileType[0];
                console.log(fileType);

                // const extension = file.name.split('.').slice(-1) == 'pdf';
                // 限制文件大小  小于6M
                var fileSize = file.size / 1024 / 1024;
                if (fileSize > 10) {
                    this.$confirm('文件大小须小于10M。', '提示', {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: 'warning'
                    }).then().catch()
                    this.fileNameTest = '';
                    this.imgInfo.fileName = '';
                    this.blobFile = '';
                    return false
                }
                // if (!extension) {
                //     that.$message.warning('上传模板只能是pdf格式!')
                //     that.fileName = '';
                //     return false
                // }
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    // that.blobFile = reader.result;
                    that.imageUrl = reader.result;
                    that.imgInfo.content = reader.result;
                    console.log(reader.result)
                };
                return false; // 返回false不会自动上传
            }

        }

    }
</script>
<style scoped>
    .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        /* width: 200px;
        height: 200px; */
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    .avatar {
        width: 150px;
        height: 150px;
        display: block;
    }

    .standard_h {
        height: 450px;
    }

    .detailStandard_h {
        height: 150px;
        overflow-y: overlay;
    }

    .add_btn {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .imgLook {
        width: 300px;
        height: 300px;
        margin: 20px auto;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    /* ::v-deep .el-upload {
        width: 100%;
    } */
</style>