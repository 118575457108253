<template>
    <div class="page_container ">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">详情</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30">
            <div class=" flex justify_center align_center pdt_20">
                    <el-form :inline="true" :model="detailInline" label-width="120px" style="width: 70%;">
                        <el-form-item label="机器名称:">
                            <el-input v-model="detailInline.machineID" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="项目名称:">
                            <el-input v-model="detailInline.projectID" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="健康状态:">
                            <el-input v-model="detailInline.healthStatus" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="健康等级:">                  
                            <el-input v-model="detailInline.healthRank" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="测点:">
                            <el-input v-model="detailInline.sensor" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="处理状态:">
                            <el-input v-model="detailInline.procStatus" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="创建时间:" >
                            <el-date-picker v-model="detailInline.gmtCreate" :formatter="formatDate" placeholder=""  disabled> </el-date-picker>
                            <!-- <el-input v-model="detailInline.gmtCreate" :formatter="formatDate" placeholder="" class="width_180" disabled>    
                                <template slot-scope="scope">
                                      <span style="margin-left: 10px">{{ timestampToTime(scope.row.gmtCreate) }}</span>
                                        </template>
                            </el-input> -->
                            <!-- <template slot-scope="scope">
                                      <span style="margin-left: 10px">{{ timestampToTime(scope.row.gmtCreate) }}</span>
                                        </template> -->
                        </el-form-item>
                        <el-form-item label="处理时间:">
                            <el-input v-model="detailInline.gmtProc" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="处理详情:">
                            <el-input v-model="detailInline.maxSeverityRelatedPartId" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="健康状态评估:">
                            <el-input v-model="detailInline.healthAssessment" placeholder="" class="width_180" disabled></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="零件健康状态信息">
                            <el-input v-model="detailInline.procDetail" placeholder="备注区域" class="width_180"></el-input>
                        </el-form-item> -->
                    </el-form>
                </div>
            <div class=" flex justify_center align_center" >
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_20" height="700" style="width: 55%;" :model="detailInline"  @selection-change="handleSelectionChange"
                    @row-click="click" :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="driveModel" label="驱动模型" width=""></el-table-column>
                    <el-table-column prop="partInstanceId" label="零部件实例id" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="componentName" label="部件名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="partName" label="零件名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="healthAssessed" label="零部件健康状态（0健康 1亚健康 2故障 3危险）" width="250" ></el-table-column>   
                    <el-table-column prop="wiki" label="建议" width=""></el-table-column>
                    <el-table-column prop="conclusion" label="结论" width="" ></el-table-column>
                    <!-- <el-table-column prop="procDetail" label="处理详情" ></el-table-column>
                    <el-table-column prop="imgs" label="处理"></el-table-column> -->
                </el-table>   
            </div>
            <el-dialog :visible.sync="dialogVisibleMaps" width="90%">
                <div class="flex ">
                    <el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" class="mg_top_20" height="336" style="width: 45%;" :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column prop="currentValue" label="当前值" width=""></el-table-column>
                    <el-table-column prop="name" label="名称" width="" ></el-table-column>
                    <el-table-column prop="trend" label="可忽略冗余字段" width="" ></el-table-column>
                    <el-table-column prop="weightProportion" label="权重" width="" ></el-table-column>
                </el-table>
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_20" height="336" style="width: 45%;" :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column prop="chartMaps.Suspected" label="索引" width=""></el-table-column>
                    <el-table-column prop="chartMaps.name" label="零部件关联对应测点id" width="" ></el-table-column>
                    <el-table-column prop="chartMaps.currentValue" label="零部件关联对应测点id" width="" ></el-table-column>
                    <el-table-column prop="chartMaps.trend" label="零部件关联对应测点id" width="" ></el-table-column>
                    <el-table-column prop="chartMaps.weightProportion" label="零部件关联对应测点id" width="" ></el-table-column>
                </el-table>
                </div>
                <div class="flex justify_center pdb_20 mg_top_20 ">
                    <el-button type="primary" @click="dialogVisibleMaps=false">确 定</el-button>
                </div>
            </el-dialog> 
        </div>
    </div>
</template>

<script>
    import innerTopNav from '../../../../components/innerTopNav'
   
    import {
        diagnosisDataQuery,
        
    } from '@/api/api_deviceManagement.js'
    export default {
        components: {
          innerTopNav
        },
        data() {
            return {
               tableData:[],
               ID:'',
               detailInline:{},
               dialogVisibleMaps:false,
                mapsData:[],
                tableList:[],
                detailStr:{},
                //detailInline1:{},
                multipleSelection:[]
            }
        },
        created(){

        },
        mounted() {
            // if (localStorage.getItem('userInfo') != null) {
            //     let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            //     this.userID = userInfo.userID;
            // }
            if (localStorage.getItem('rowDID') != null) {
                this.ID = localStorage.getItem('rowDID')
            }
            this.diagnosisDataQuery();
           
        },

        methods: {
        timestampToTime(date) {
        console.log("------------",date)
        var date = new Date(date);
        var YY = date.getFullYear() + '-';
        var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
         return YY + MM + DD +" "+hh + mm + ss;
     },
         //日期格式
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
             //处理结果
            formatResult(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                let ret = '';
                if (data == 0) {
                    ret = '未处理'
                };
                if(data == 1){
                    ret = '已处理'
                }
                return ret
            },
            async diagnosisDataQuery(){
                
                var param = {
                    ID:this.ID,
                    driveModel:this.driveModel,
                    partInstanceId:this.partInstanceId,
                    componentName:this.componentName,
                    partName:this.partName,
                    healthAssessed:this.healthAssessed,
                    wiki:this.wiki,
                    conclusion:this.conclusion
                }
                const selectRes =await diagnosisDataQuery(param)
                // this.tableData=selectRes.rows;
                this.tableData=selectRes.rows[0].diagnosisFactorDtos.array;
                this.detailInline=selectRes.rows[0]

                this.detailStr = selectRes.rows[0].diagnosisFactorDtos.array

                Object.assign(this.detailInline,this.detailStr)

                this.tableList =this.tableData[0].algorithmMaps
            },
            //详情表
         
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
             handleSelectionChange(val) {      
                this.multipleSelection = val;
                console.log("select",this.multipleSelection[0])
            },
            click(row){
                // // console.log(row);
                // if(row.chartMaps){
                //     this.dialogVisibleMaps = true
                // };
    
            },

            
            // //日期格式
            // formatDate(row, column) {
            //     // 获取单元格数据
            //     let data = row[column.property]
            //     if (data == undefined) {
            //         return ''
            //     };
            //     return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            // },
            //布尔值格式
            formatBoolean(row, column, cellValue) {
                var ret = ''  //你想在页面展示的值
                if (cellValue) {
                    ret = "是"  //根据自己的需求设定
                } else {
                    ret = "否"
                }
                return ret;
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.alarmDataQuery(); //查询table数据的方法
            },
        }
    }
</script>

<style scoped>
    .fixtable{
        width: 90%;
        height: auto;
        margin-bottom: 35px;
    }
    .fixtable > div{
        height: 40px;
        border: 1px red;
    }
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }
</style>
<style>
    .fixtable{
        border: 1px solid black;
    }
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>