// 审批顺序
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>审批顺序</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <div>审批类型选择：</div>
                    <el-select v-model="queryCondition" placeholder="请选择" class=" width_150" clearable>
                        <el-option v-for="item in options" :key="item.value" :label="item.description" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" size="medium" class="mg_left_10" style=" background: #009BFD;border: none;"
                        @click="CategoryQuery"> 查询
                    </el-button>
                </div>
            </div>
            <!-- mg_top_20 -->
            <div class="flex  pdl30 pdr30 pdt_20">
                <el-button type="primary" class="width_110" icon="el-icon-circle-plus-outline"
                    style=" background: #03CABE;border: none;" @click="add">新增
                </el-button>
                <el-button type="primary" class="mg_left_10" @click="exportRepairRecord" size="medium"
                            style=" background: #03CABE;border: none;">导出</el-button> 
            </div>
            <div class="pdl30 pdr30 hei80V">
                <el-table :data="queryList" style="width: 100%" class=" mg_top_20" height="100%"
                @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <!-- <el-table-column prop="ID" label="id">
                    </el-table-column> -->
                    <el-table-column prop="roleName" label="角色编号">
                    </el-table-column>
                    <el-table-column label="审批类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.authType == 1">故障保修</span>
                            <span v-if="scope.row.authType == 2">维修计划</span>
                            <span v-if="scope.row.authType == 3">维修工单</span>
                            <span v-if="scope.row.authType == 4">保养计划</span>
                            <span v-if="scope.row.authType == 5">保养工单</span>
                            <span v-if="scope.row.authType == 6">点检计划</span>
                            <span v-if="scope.row.authType == 7">备件领用</span>
                            <span v-if="scope.row.authType == 8">备件申购</span>
                            <span v-if="scope.row.authType == 9">备件出库</span>
                            <span v-if="scope.row.authType == 10">备件入库</span>
                            <span v-if="scope.row.authType == 11">备件盘点</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="authSort" label="审批顺序">
                    </el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号">
                    </el-table-column> -->

                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="gotoModify(scope.row)">
                                修改
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>

        <!-- 新增小窗 -->
        <el-dialog title="新增" :visible.sync="dialogFormVisible" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="addform">
                    <el-form-item label="角色编号: " :label-width="formLabelWidth">
                        <!-- <el-input v-model="addform.roleID" autocomplete="off" class=""></el-input> -->
                        <el-select v-model="addform.roleID" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in roleIDSelectList" :key="item.roleID" :label="item.roleName"
                                :value="item.roleID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审批类型" :label-width="formLabelWidth">
                        <el-select v-model="addform.authType" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in options" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审批顺序: " :label-width="formLabelWidth">
                        <el-input v-model="addform.authSort" autocomplete="off" class="" type="number" oninput="if(value<1)value=0"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="trueadd">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 修改小窗 -->
        <el-dialog title="修改" :visible.sync="dialog2FormVisible" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">修改</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="addform">
                    <el-form-item label="角色编号: " :label-width="formLabelWidth">
                        <!-- <el-input v-model="updateform.roleID" autocomplete="off" class=""></el-input> -->
                        <el-select v-model="updateform.roleID" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in roleIDSelectList" :key="item.roleID" :label="item.roleName"
                                :value="item.roleID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审批类型" :label-width="formLabelWidth">
                        <el-select v-model="updateform.authType" placeholder="请选择" class=" width_250">
                            <el-option v-for="item in options" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审批顺序: " :label-width="formLabelWidth">
                        <el-input v-model="updateform.authSort" autocomplete="off" class="" type="number" oninput="if(value<1)value=0"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialog2FormVisible = false">取 消</el-button>
                <el-button type="primary" @click="trueModify">确 定</el-button>
            </div>
        </el-dialog>



    </div>
</template>
<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        authoritymanagementAuthorizesequenceInsert,
        authoritymanagementAuthorizesequenceDelete,
        authoritymanagementAuthorizesequenceUpdate,
        authoritymanagementAuthorizesequenceQuery,
        authoritymanagementAuthorizesequenceQueryLimit,
        authoritymanagementAuthorizesequenceAuthType,
        authoritymanagementAuthorizesequenceRoleQuery,//新增弹框-角色编号下拉框 接口
    } from "@/api/api_systemConfig";
    export default {
        data() {
            return {
                value: '',
                options: [],
                //评分等级新增form
                form: {
                    userID: '',
                    gradeSettings: 0,
                    gradeSettingsCategory: '',
                    remarks: ''
                },

                //新增方法所需变量
                roleIDSelectList:[],//新增弹框-角色编号下拉框 使用的数组
                dialogFormVisible: false,
                addform: {
                    authSort: '',
                    authType: '',
                    roleID: '',
                    userID: ''
                },
                queryList: [],

                //修改方法所需变量
                dialog2FormVisible: false,
                updateform: {
                    ID: '',
                    roleID: '',
                    authSort: '',
                    authType: ''
                },

                //条件筛选方法所需变量
                queryCondition: '',
                //rMIGradeCategoryQuery:[],
                deleteNum: '',
                formLabelWidth: '120px',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                Category: '',
                userID: ''
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.addform.userID = userInfo.userID;
                this.userID = userInfo.userID;
            };

            //页面加载即调用分页筛选方法、刷新展示数据到页面
            this.authoritymanagementAuthorizesequenceQueryLimit();

            //页面加载即调用审批类型筛选方法、初始化筛选条件下拉框
            this.authoritymanagementAuthorizesequenceAuthType();

            //页面加载即调用角色编号筛选方法、初始化角色编号下拉框
            this.authoritymanagementAuthorizesequenceRoleQuery();
        },
        methods: {
              //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '审批顺序表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            //分页筛选
            //分页筛选方法
            async authoritymanagementAuthorizesequenceQueryLimit() {
                //封装参数
                if (this.queryCondition != '') {
                    var param = {
                        userID: this.userID,
                        pageNumber: this.currentPage,
                        pageSize: this.pageSize,
                        authType: this.queryCondition
                    };
                } else {
                    var param = {
                        userID: this.userID,
                        pageNumber: this.currentPage,
                        pageSize: this.pageSize,
                    }
                }
                // console.log(param)

                const res = await authoritymanagementAuthorizesequenceQueryLimit(param);
                // console.log(res)

                this.queryList = res.rows;
                this.pageQuantity = res.pageQuantity;

            },
            //点击页码触发分页查询
            handleCurrentChange(val) {
                this.currentPage = val;
                this.authoritymanagementAuthorizesequenceQueryLimit();
            },

            //审批类型筛选
            async authoritymanagementAuthorizesequenceAuthType() {
                var param = {};
                // console.log(param);

                const res = await authoritymanagementAuthorizesequenceAuthType(param);
                // console.log(res);

                this.options = res.rows;
            },

            //新增
            //点击‘新增’按钮触发的方法
            add() {
                //将原有数据清空，以备用来新增 审批顺序
                this.addform = {};

                //打开新增页面的dialog
                this.dialogFormVisible = true;

                //调用 审批类型筛选接口，初始化 审批类型 下拉框
                this.authoritymanagementAuthorizesequenceAuthType();
            },
            //新增弹框-角色编号下拉框
            async authoritymanagementAuthorizesequenceRoleQuery(){

                //封装参数
                var param = {
                    userID:this.userID,
                };

                //调用接口
                const selectRes = await authoritymanagementAuthorizesequenceRoleQuery(param);

                //处理返回值
                if(selectRes){//如果存在返回值
                    this.roleIDSelectList = selectRes.rows;
                }
            },
            //点击dialog里‘确定’按钮时触发的方法
            trueadd() {
                //调用 新增审批顺序配置 方法，实现审批顺序配置新增
                this.authoritymanagementAuthorizesequenceInsert();
            },
            //审批顺序配置新增方法
            async authoritymanagementAuthorizesequenceInsert() {
                //封装参数
                var param = {
                    userID: this.userID,
                    roleID: this.addform.roleID,
                    authSort: this.addform.authSort,
                    authType: this.addform.authType,
                };
                // console.log(param)

                const res = await authoritymanagementAuthorizesequenceInsert(param);
                // console.log(res)

                if (res && res.data && res.data.result) {
                    this.dialogFormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    //插入后 调用 审批顺序配置筛选-分页数据 方法，刷新页面
                    this.authoritymanagementAuthorizesequenceQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }

            },

            //限制输入框只能输入数字
            number() {
                this.addform.authSort = this.addform.authSort.replace(/[^\.\d]/g, '');
                this.addform.authSort = this.addform.authSort.replace('.', '');
            },

            //删除
            //点击‘删除’按钮时触发的方法
            delTableData(id) {
                this.deleteNum = id;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用审批顺序配置-删除方法
                    this.currentPage = this.delData.delData(this.queryList.length, this.pageQuantity,this.currentPage)
                    this.authoritymanagementAuthorizesequenceDelete();
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            //审批顺序配置-删除方法
            async authoritymanagementAuthorizesequenceDelete() {
                //封装参数
                var param = {
                    ID: this.deleteNum
                };
                // console.log(param)
                const res = await authoritymanagementAuthorizesequenceDelete(param);
                // console.log(res)
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.authoritymanagementAuthorizesequenceQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //修改
            //点击‘修改’按钮时 触发的方法
            gotoModify(temp) {
                //将要修改的数据导入到修改窗口中
                this.updateform.ID = temp.ID;
                this.updateform.roleID = temp.roleID;
                this.updateform.authSort = temp.authSort;
                this.updateform.authType = temp.authType;

                //打开修改窗口
                this.dialog2FormVisible = true;

                //调用 审批类型筛选接口，初始化 审批类型 下拉框
                this.authoritymanagementAuthorizesequenceAuthType();
            },
            //点击修改dialog中的的‘确定’按钮时 触发的方法
            trueModify() {
                //调用 审批顺序配置-修改 方法
                this.authoritymanagementAuthorizesequenceUpdate();
            },
            //审批顺序配置-修改 方法
            async authoritymanagementAuthorizesequenceUpdate() {
                //封装参数
                var param = {
                    ID: this.updateform.ID,
                    roleID: this.updateform.roleID,
                    authSort: this.updateform.authSort,
                    authType: this.updateform.authType
                };
                console.log(param)

                const res = await authoritymanagementAuthorizesequenceUpdate(param);
                console.log(res)

                if (res && res.data && res.data.result) {
                    this.dialog2FormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.authoritymanagementAuthorizesequenceQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //指定筛选条件的分页筛选
            //点击主页‘查询’按钮时触发的方法
            CategoryQuery() {
                this.currentPage = 1;
                this.authoritymanagementAuthorizesequenceQueryLimit();
            }
        }
    }
</script>
<style scoped>
    .hei80V {
        height: 78%;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 14px
    }
</style>