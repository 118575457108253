<!-- 系统配置-设备类型配置 -->
<template>
    <div class="page_container height_100">
        <div class="box_head1">
            <span>设备类型配置</span>
        </div>
        <div class="bgc_FFFFFF pdt_20 pdl30 pdr30 faultCodeAnalysisBody">
            <!-- <div class="flex pdt_20 fn_size16">
                <el-input v-model="input2" placeholder="输入类型编码或类型名称" class="mg_right_10 width_270"></el-input>
                <el-button type="primary" size="medium" class=""
                    @click="changedeviceType">查询</el-button> 
            </div>-->

            <!-- <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_120 bgc_03CABE buttonBorder" @click="addData" style="background-color: #03CABE;">增加
                    </el-button>
                    <el-button type="primary" icon="el-icon-delete" class="width_120 mg_left_10 bgc_F75753 buttonBorder"
                        style="background-color: #F75753;" @click="deletedeviceType">批量删除</el-button>
                </div>
                
            </div> -->

            <!-- class="height_500"  -->
           
            <el-table :data="deviceTypeList" tooltip-effect="dark" style="width: 100%;" class=""
                @selection-change="handleSelectionChange" height="93%"
                :default-sort="{prop: 'date', order: 'descending'}">
                <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                <el-table-column type="index" label="序号" width="55"></el-table-column>
                <el-table-column prop="equipmentCategoryName" label="类型名称" width="">
                </el-table-column>
                <el-table-column prop="equipmentCategoryID" label="类型编码" width=""></el-table-column>
                <el-table-column prop="equipmentcategoryNumber" label="设备数量" width=""></el-table-column>
                <el-table-column prop="recordTime" label="创建时间" width="" :formatter="formatDate"></el-table-column>
                <el-table-column prop="remark" label="备注" width="" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;"
                                @click="typeModification(scope.row)">修改</span>
                        </el-button>
                        <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;"
                                @click="deletedeviceType2(scope.row.equipmentCategoryID)">删除</span>
                        </el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <!-- 页码 -->
            
            <div class="flex justify_end align_start mg_bottom_10">
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChangeRepair">
                </el-pagination>
            </div>
            <!-- dialog 增加 -->
            <el-dialog :visible.sync="dialogTableVisible" top='10vh' width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增设备类型</div>
                <div class="pdl30 pdr30">
                    <el-form :model="currentInfo" ref="currentInfo" label-width="100px" class="mg_top_22"
                        :rules="rules">
                        <!-- 表单验证 :rules="rules" -->
                        <el-form-item label="类型名称：" prop="equipmentCategoryName">
                            <el-input v-model="currentInfo.equipmentCategoryName" placeholder="输入设备编码名称 不能重名"
                                class="width_270 flex">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="类型编码：" prop="equipmentCategoryID">
                            <el-input v-model="currentInfo.equipmentCategoryID" type="number"
                                placeholder="输入设备编码名称 不能重名" class="width_270 flex">
                            </el-input>
                        </el-form-item>
                        <!-- 上级类型 待定，不传数据 -->
                        <!-- <el-form-item label="上级类型：" prop="type">
                            <el-select v-model="currentInfo.type" placeholder="请选择" class="width_270 flex">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="描述：" prop="remark">
                            <el-input type="textarea" :rows="6" v-model="currentInfo.remark" resize="none"
                                placeholder="请输入介绍信息" class="width_270 flex"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button type="primary" @click="equiTypeAdd">增加</el-button>
                </div>
            </el-dialog>
            <!-- dialog 编辑 -->
            <el-dialog :visible.sync="dialogTableVisible2" top='10vh' width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑设备类型</div>
                <div class=" pdl30 pdr30">
                    <el-form :model="currentInfo" label-width="90px" class="mg_top_22">
                        <el-form-item label="类型名称：" prop="equipmentCategoryName">
                            <el-input v-model="currentInfo.equipmentCategoryName" placeholder="输入设备编码名称 不能重名"
                                class="width_270 flex">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="类型编码：" prop="equipmentCategoryID">
                            <el-input v-model="currentInfo.equipmentCategoryID" type="number"
                                placeholder="输入设备编码名称 不能重名" class="width_270 flex">
                            </el-input>
                        </el-form-item>
                        <!-- 上级类型 待定，不传数据 -->
                        <!-- <el-form-item label="上级类型：" prop="type">
                            <el-select v-model="currentInfo.type" placeholder="请选择" class="width_270 flex">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="描述：" prop="remark">
                            <el-input type="textarea" :rows="6" v-model="currentInfo.remark" resize="none"
                                placeholder="请输入介绍信息" class="width_270 flex"></el-input>
                        </el-form-item>
                    </el-form>
                </div>

                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button type="primary" @click="equiTypeModification">修改</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        equipmentTypeConfigList,
        equipmentTypeConfigModification,
        equipmentTypeConfigAdd,
        equipmentTypeConfigDelete
    } from "@/api/api_systemConfig";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                input: '',
                input2: '',
                pageQuantity: 0, //页码
                pageSize: 9, //一页显示数据的条数
                currentPage: 1,
                // equipmentCategoryNumber: '',
                dialogTableVisible: false,
                dialogTableVisible2: false,
                deviceTypeList: [],
                // deleteNum: [],
                deleteNum: '',
                deleteStatus: '',
                currentInfo: {},
                // currentInfo: {
                //     equipmentCategoryName:'',
                //     equipmentCategoryID:'',
                //     remark:'',
                //     type:'',
                // },
                multipleSelection: [],
                // dialog表单验证规则
                rules: {

                    equipmentCategoryName: [{
                        required: true,
                        message: '请输入类型名称',
                        trigger: 'blur',
                    }],
                    equipmentCategoryID: [{
                        required: true,
                        message: '请输入类型编码',
                        trigger: 'blur',
                    }],
                }
            }
        },
        mounted() {
            this.equipmentTypeConfigList();
        },
        methods: {
            handleSelectionChange(val) {
                this.multipleSelection = val;
                console.log(val)
                //要批量删除的
                this.deleteNum = ""; //数组赋值前，需要清空一下
                // console.log(val)
                var tastDate = [];
                for (var i = 0; i < val.length; i++) {
                    // equipmentCategoryID
                    tastDate.push(val[i].equipmentCategoryID)
                    // this.deleteNum += val[i].equipmentcategory_id + ",";
                }
                this.deleteNum = tastDate.join(',')
                // //去掉最后一个逗号(如果不需要去掉，就不用写)
                // if (this.deleteNum.length > 0) {
                //     this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                // }
                console.log(this.deleteNum)
            },
            //接口-开始
            //类型选择
            //table
            async equipmentTypeConfigList() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (this.input2 != null && this.input2 != "") {
                    param.equipmentcategoryIDOrName = this.input2;
                }
                // console.log(param);
                const selectRes = await equipmentTypeConfigList(param);
                this.deviceTypeList = selectRes.data.rows;
                this.pageQuantity = selectRes.data.pageQuantity;
                // console.log(selectRes.data)
            },
            //日期格式
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            //input框查询
            changedeviceType() {
                this.currentPage = 1;
                this.equipmentTypeConfigList()
            },
            addData() {
                this.currentInfo = {};
                this.dialogTableVisible = true;
            },
            handleCurrentChangeRepair(val) {
                this.pageNumber = val;
                this.equipmentTypeConfigList(); //查询table数据的方法
            },
            //增加
            async equipmentTypeConfigAdd() {
                // var param = {}
                // if (this.currentInfo.Name != null && this.currentInfo.Name != "") { //名称
                //     param.equipmentCategoryName = this.currentInfo.Name;
                // }
                // if (this.currentInfo.ID != null && this.currentInfo.ID != "") { //编码
                //     param.equipmentCategoryID = this.currentInfo.ID;
                // }
                // //上级类型 未传数据
                // if (this.currentInfo.remark != null && this.currentInfo.remark != "") { //备注
                //     param.remark = this.currentInfo.remark;
                // }
                // console.log(param);
                const selectRes = await equipmentTypeConfigAdd(this.currentInfo);
                //判断是否添加成功
                if (selectRes.data) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.equipmentTypeConfigList()
                } else {
                   this.$message.error(selectRes.message);
                    // this.equipmentTypeConfigList()
                }
                this.dialogTableVisible = false;
            },
            equiTypeAdd() {
                this.equipmentTypeConfigAdd()
                this.currentInfo = {};
            },
            //编辑
            async equipmentTypeConfigModification() {
                var param = {
                    equipmentCategoryName: this.currentInfo.equipmentCategoryName, //名称
                    equipmentCategoryID: this.currentInfo.equipmentCategoryID, //编码
                    remark: this.currentInfo.remark, //备注
                }
                // console.log(param);
                const selectRes = await equipmentTypeConfigModification(param);
                // console.log(selectRes.data)
                this.dialogTableVisible2 = false;
                //判断是否修改成功
                if (selectRes.data) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.equipmentTypeConfigList();
                    this.currentInfo = {};
                } else {
                    this.$message.error(selectRes.message);
                    // this.$message({
                    //     message: '修改失败',
                    //     type: 'warning'
                    // });
                    // this.equipmentTypeConfigList()
                }
            },
            equiTypeModification() { //点击dialog‘编辑’按钮的方法
                this.equipmentTypeConfigModification()
            },
            typeModification(row) { //点击‘修改’按钮的方法
                this.currentInfo = row;
                this.dialogTableVisible2 = true; //打开dialog
                // console.log(this.currentInfo)
            },
            //删除
            async equipmentTypeConfigDelete() {
                var param = {
                    // equipmentcategoryID: '4,5',
                    equipmentCategoryID: this.deleteNum,
                }
                console.log(param);

                const selectRes = await equipmentTypeConfigDelete(param);
                this.deleteStatus = selectRes.data;
                // console.log(selectRes.data)
                //判断是否删除成功
                if (selectRes.data) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.equipmentTypeConfigList()
                } else {
                   this.$message.error(selectRes.message);
                }

            },
            //批量删除
            deletedeviceType() {
                //在弹框弹出前，需要判断是否选中数据，进行赋值
                if (this.multipleSelection.length == 0) { //未选中数据
                    this.$message({
                        message: '未选中数据',
                        type: 'warning'
                    });
                    return
                }
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // todo
                    this.currentPage = this.delData.delData(this.deviceTypeList.length, this.pageQuantity, this
                        .currentPage, this.multipleSelection.length)
                    this.equipmentTypeConfigDelete();
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                })

            },
            //单个删除
            deletedeviceType2(id) {
                this.deleteNum = id; //要删除的行的类型编码
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.deviceTypeList.length, this.pageQuantity, this
                        .currentPage)
                    this.equipmentTypeConfigDelete()
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            //接口-结束
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }

    .faultCodeAnalysisBody {
        height: 95%;
    }

    .height_540 {
        height: 540px;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>