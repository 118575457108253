<!--  -->
<template>
    <div class="page_container height_100" v-loading="loading">
        <div class="flex flex_column bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>历史报警查询</span>
                <div class="flex align_center mg_left_50">
                    <!-- select下拉框 -->
                    <!-- <div class="flex "> -->
                        <el-cascader size="medium" v-model="organizationValue" placeholder="请选择组织维度" :options="organizationAr"
                            ref="demoCascader" :props="defaultProps" class="el_cascader_width mg_right_10"
                            @change="handleChangeCascader" :show-all-levels="false" collapse-tags filterable></el-cascader>
                        <!-- <el-select v-model="alarmTypeArValue" placeholder="报警类型" class="alarmQuerySelect mg_right_10">
                            <el-option v-for="item in alarmTypeAr" :key="item.alarm_category_id"
                                :label="item.alarm_category_name" :value="item.alarm_category_id">
                            </el-option>
                        </el-select> -->
                        <el-select v-model="alarmLevelArValue" placeholder="报警等级" class="alarmQuerySelect mg_right_10">
                            <el-option v-for="item in alarmLevelAr" :key="item.alarm_level_id"
                                :label="item.alarm_level_name" :value="item.alarm_level_id">
                            </el-option>
                        </el-select>
                        <!-- <el-select v-model="confirmationStatusValue" placeholder="确认状态选择"
                            class="alarmQuerySelect mg_right_10">
                            <el-option v-for="item in confirmationStatus" :key="item.ID" :label="item.Name"
                                :value="item.ID">
                            </el-option>
                        </el-select> -->
                        <el-date-picker value-format="timestamp" v-model="alarmTime" type="datetimerange" :picker-options="pickerOptions"
                            class="mg_right_10" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            align="right">
                        </el-date-picker>
                        <el-button type="primary" size="medium"
                            @click="alarmDataSearch">查询</el-button>
                        <el-button type="primary" size="medium" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #009bfd;">导出</el-button>
                    <!-- </div> -->
                </div>
            </div>


            <!-- <div class="alarmAnalysisHeader flex justify_start align_center"></div> -->
            <!-- <div class="flex flex_column bgc_FFFFFF"> -->
            <!-- select下拉框 -->
            <!-- <div class="flex alarmQueryMargin  alarmQuerySelectDiv">
                <el-cascader v-model="organizationValue" placeholder="请选择组织维度" :options="organizationAr"
                    ref="demoCascader" :props="defaultProps" class="dimensionTrendSelect3  mg_right_10"
                    @change="handleChangeCascader" :show-all-levels="false" collapse-tags></el-cascader>
                <el-select v-model="alarmTypeArValue" placeholder="报警类型" class="alarmQuerySelect mg_right_10">
                    <el-option v-for="item in alarmTypeAr" :key="item.alarm_category_id"
                        :label="item.alarm_category_name" :value="item.alarm_category_id">
                    </el-option>
                </el-select>
                <el-select v-model="alarmLevelArValue" placeholder="报警等级" class="alarmQuerySelect mg_right_10">
                    <el-option v-for="item in alarmLevelAr" :key="item.alarm_level_id" :label="item.alarm_level_name"
                        :value="item.alarm_level_id">
                    </el-option>
                </el-select>
                <el-select v-model="confirmationStatusValue" placeholder="确认状态选择" class="alarmQuerySelect mg_right_10">
                    <el-option v-for="item in confirmationStatus" :key="item.ID" :label="item.Name" :value="item.ID">
                    </el-option>
                </el-select>
                <el-date-picker v-model="alarmTime" type="datetimerange" :picker-options="pickerOptions"
                    class="mg_right_10" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    align="right">
                </el-date-picker>
                <el-button type="primary" class="alarmQuerySelectButton flex align_center justify_center"
                    @click="alarmDataSearch">查询</el-button>
            </div> -->
            <!-- select下拉框 -->
            <!-- <div class="flex alarmQueryMargin">
                <el-select v-model="eventTypeValue" placeholder="请选择事件类型" class="alarmQuerySelect mg_right_10">
                    <el-option v-for="item in eventType" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="handleTypeValue" placeholder="请选择处理状态" class="alarmQuerySelect mg_right_10">
                    <el-option v-for="item in handleType" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
               
            </div> -->
            <!-- table -->
            <div class="flex flex_column alarmQueryMargin fn_size14 tableHeight">
                <el-table :data="alarmData" :row-style="{height:40+'px'}" height="100%"
                @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="60"></el-table-column>
                    <el-table-column prop="companyName" label="公司" width=""></el-table-column>
                    <el-table-column prop="workshopName" label="分厂/车间" width=""></el-table-column>
                    <el-table-column prop="lineName" label="工段/产线" width=""></el-table-column>
                    <el-table-column prop="equipmentID" label="资产编号" width=""></el-table-column>
                    <el-table-column prop="equipmentName" label="设备名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="参数名称" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="sourceProperty" label="数据类型" width=""></el-table-column> -->
                    <el-table-column prop="description" label="报警描述" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="alarmLevelName" label="报警等级" width=""></el-table-column>
                    <el-table-column prop="alarmCategoryName" label="报警类型" width=""></el-table-column>
                    <el-table-column prop="message" label="报警内容" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="time" label="报警时间" width="" show-overflow-tooltip :formatter="formatDate">
                    </el-table-column>
                    <!-- <el-table-column label="确认状态" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.acknowledged}}</span>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="" label="确认人" width=""></el-table-column>
                    <el-table-column prop="" label="确认时间" width="" show-overflow-tooltip></el-table-column> -->
                </el-table>
            </div>
            <!-- <div class="flex justify_between align_start"> -->
            <!-- <div class="color_808080 fn_size14 alarmQueryMargin">当前1-15 总记录：123,456</div> -->
            <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import {
        alarmQueryList,
        EquipmentLevelConfigList,
        alarmQueryOrganizationSelect,
        alarmQueryAlarmTypeSelect,
        alarmQueryAlarmLevelSelect,
        alarmQueryAcknowledgeStatus
    } from "@/api/api_deviceManagement";
    export default {
        data() {
            return {
                value: '',
                alarmData: [],
                eventTypeValue: '',
                eventType: [{
                    value: '1',
                    label: '全部'
                }, {
                    value: '2',
                    label: '一般事件'
                }, {
                    value: '3',
                    label: '普通告警'
                }, {
                    value: '4',
                    label: '重要'
                }, {
                    value: '5',
                    label: '紧急'
                }],
                handleTypeValue: '',
                handleType: [{
                    value: '1',
                    label: '未确认'
                }, {
                    value: '2',
                    label: '已确认'
                }],
                alarmTime: null,
                // pickerOptions: {
                //     shortcuts: [{
                //         text: '最近一周',
                //         onClick(picker) {
                //             const end = new Date();
                //             const start = new Date();
                //             start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                //             picker.$emit('pick', [start, end]);
                //         }
                //     }, {
                //         text: '最近一个月',
                //         onClick(picker) {
                //             const end = new Date();
                //             const start = new Date();
                //             start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                //             picker.$emit('pick', [start, end]);
                //         }
                //     }, {
                //         text: '最近三个月',
                //         onClick(picker) {
                //             const end = new Date();
                //             const start = new Date();
                //             start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                //             picker.$emit('pick', [start, end]);
                //         }
                //     }]
                // },
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                permission: {},
                organizationValue: [],
                defaultExpandKeys: [],
                organizationAr: [],
                standardArr: [],
                standardData: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                alarmTypeArValue: '',
                alarmTypeAr: [],
                alarmLevelArValue: '',
                alarmLevelAr: [],
                confirmationStatusValue: '',
                confirmationStatus: [],

                checkData: {},
                loading: true
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.alarmQueryOrganizationSelect();
            this.alarmQueryAlarmTypeSelect();
            this.alarmQueryAlarmLevelSelect();
            this.alarmQueryAcknowledgeStatus()
            // this.alarmQueryList();
            // this.EquipmentLevelConfigList();
        },
        methods: {
        //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },

            async alarmQueryOrganizationSelect() {
                var param = {
                    permission: this.permission,
                };
                const selectRes = await alarmQueryOrganizationSelect(param);
                this.organizationAr = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                //循环,有的层级没有数据，需要判断到有数据的层级
                for(var i =0;i < selectRes.data.organizationPermissionAll.length;i++){
                        if(selectRes.data.organizationPermissionAll[i].ID.length > 0){
                            this.checkData = selectRes.data.organizationPermissionAll[i];
                            this.standardData = selectRes.data.organizationPermissionAll[i];    
                        }
                }
                this.alarmQueryList();
            },
            // 报警类型选择
            async alarmQueryAlarmTypeSelect() {
                const selectRes = await alarmQueryAlarmTypeSelect();
                this.alarmTypeAr = selectRes.data.rows
            },
            // 报警等级选择
            async alarmQueryAlarmLevelSelect() {
                const selectRes = await alarmQueryAlarmLevelSelect();
                this.alarmLevelAr = selectRes.data.rows
            },
            // 确认状态选择
            async alarmQueryAcknowledgeStatus() {
                const selectRes = await alarmQueryAcknowledgeStatus();
                this.confirmationStatus = selectRes.data
                // confirmationStatus
            },
            alarmDataSearch() {
                this.currentPage = 1;
                this.alarmQueryList();
            },
            async alarmQueryList() {

                /*                 if((this.alarmTypeArValue != null && this.alarmTypeArValue != "")||(this.alarmTypeArValue != null && this.alarmTypeArValue != "")||(this.confirmationStatusValue != null && this.confirmationStatusValue != "")||(this.alarmTime != null)){
                                    this.currentPage = 1;
                                } */
                var param = {
                    organization: this.checkData,
                    limitValue: this.pageSize,
                    offsetValue: this.currentPage,
                };
                if (this.alarmTypeArValue != null && this.alarmTypeArValue != "") {
                    param.alarmType = this.alarmTypeArValue;
                }
                if (this.alarmLevelArValue != null && this.alarmLevelArValue != "") {
                    param.alarmLevel = this.alarmLevelArValue;
                }
                if (this.confirmationStatusValue != null && this.confirmationStatusValue != "") {
                    param.AcknowledgeStatus = this.confirmationStatusValue;
                }
                if (this.alarmTime != null) {
                    param.startDate = this.alarmTime[0];
                    param.endDate = this.alarmTime[1]

                }
                const selectRes = await alarmQueryList(param);
                this.pageQuantity = selectRes.pageQuantity;
                if (selectRes.data) {
                    this.alarmData = selectRes.data.fileList
                }
                this.loading = false
            },
            handleChangeCascader() {
                // console.log(this.organizationValue)
                if (this.organizationValue.length == 0) {
                    this.checkData = this.standardData;
                    return
                }
                const obj = this.$refs['demoCascader'].getCheckedNodes();
                console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    // console.log(obj[i].data)
                    // if (obj[i].data.Type == 'E') {
                    //     var ceshi = {
                    //         Type: 'E',
                    //         ID: '',
                    //     }
                    //     ceshi.ID = obj[i].data.ID;
                    //     currentData.push(ceshi)

                    // } else {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)

                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)
                // this.alarmAnalysisEquipmentTypeSelect();
                // this.alarmAnalysisDimensionalitySelect();
            },
            handleCurrentChange(val) {
                // console.log()
                // this.PageNumber = val;
                this.alarmQueryList(); //查询table数据的方法
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }

    }
</script>

<style scoped>
    .alarmAnalysisHeader {
        /* width: 1862px; */
        height: 40px;
        padding-left: 20px;
        background-color: #E2F0FE;
        border: 1px solid #CCDBE5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .alarmAnalysisHeader2 {
        width: 1798px;
        height: 40px;
        padding-left: 20px;
        background-color: #E2F0FE;
        border: 1px solid #CCDBE5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .alarmQuerySelect {
        width: 150px;
        height: 36px;
    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }

    .alarmQueryMargin {
        margin-left: 31px;
        margin-top: 19px;
        margin-right: 31px;
    }

    .tableHeight {
        height: 90%;
    }
</style>