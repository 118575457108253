<!-- -故障报修--生成计划 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center ">
            <div class="pdl30 pdr30 width_100">
                <div class="flex justify_start mg_top_17 ">
                    <el-button type="primary" class="width_120 " style=" background: #03CABE;border: none;">选择设备
                    </el-button>
                </div>
                <div class="mg_top_17 height_280">
                    <el-table ref="multipleTable" :data="tableData" style="width: 100%" max-height="280" class="">
                        <el-table-column type="index" label="序号" width="100">
                        </el-table-column>
                        <el-table-column prop="date" label="设备编号" width="">
                        </el-table-column>
                        <el-table-column prop="name" label="设备名称" width="">
                        </el-table-column>
                        <el-table-column prop="date" label="规格型号" width="">
                        </el-table-column>
                        <el-table-column prop="name" label="设备类型" width="">
                        </el-table-column>
                        <el-table-column prop="name" label="使用部门" width="">
                        </el-table-column>
                        <el-table-column prop="name" label="设备位置" width="">
                        </el-table-column>
                        <el-table-column label="操作" width="180">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD">
                                    <span style="border-bottom: 1px solid #009BFD;">删除</span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>
            <div class="box_head1 width_100 mg_top_20">维修信息</div>
            <!-- <div class="flex flex_column align_center   details_box"> -->
                <el-form :inline="true" class="mg_top_15 flex flex_wrap" :label-width="formLabelWidth"  style="width: 65%;">
                    <!-- <el-form-item label="计划执行时间：" class=" ">
                        <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                    </el-form-item> -->
                    <el-form-item label="计划执行时间：" class=" ">
                        <el-date-picker v-model="value1" type="date" placeholder="选择日期时间" >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="提醒时间：" class="">
                        <el-date-picker v-model="value1" type="date" placeholder="选择日期时间" >
                        </el-date-picker>
                    </el-form-item>
                    <!-- <el-form-item label="提醒时间：" class=" ">
                        <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                    </el-form-item> -->
                    <el-form-item label="维修类别：" class=" ">
                        <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                    </el-form-item>
                    <el-form-item label="紧急程度：" class="">
                        <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                <!-- </el-form>
                <el-form :inline="true" class="flex justify_start" label-width="110px"> -->
                    <el-form-item label="维修组：" class="">
                        <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="维修人员：" class="">
                        <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-form  :model="formInline" :label-width="formLabelWidth"  style="width: 65%;">
                    <el-form-item label="维修描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="formInline.user" resize="none"></el-input>
                    </el-form-item>
                </el-form>
            <!-- </div> -->

            <div class="flex mg_bottom_10">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
     import {
        repairPlanGeneration
    } from '@/api/api_deviceManagement.js'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                formInline: {
                    user: '',
                    region: ''
                },
                formLabelWidth:'110px',
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }],
                resultData:[],
            }
        },
        mounted() { 
            this.repairPlanGeneration()
        },
        methods: {
            


            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            }
        }

    }
</script>
<style>
    .textarea .el-form-item__content {
        width: 84%
    }
</style>
<style scoped>
    .details_box {
        width: 70%;
    }

    /* .details_box .el-form {
        width: 100%;
    }

    .textarea .el-form-item__content {
        width: 84%
    } */

    .height_280 {
        height: 280px;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-form {
        width: 100%;
    }
</style>