// 审批记录
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>审批记录</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <div>角色名输入：</div>
                    <el-input v-model="roleName" placeholder="请输入角色名" class=" width_150"></el-input>
                     <div class="">审批结果：</div>
                        <el-select v-model="authResult" placeholder="全部" class="width_150" clearable>
                            <el-option v-for="item in authResultArray" :key="item.value" :label="item.name"
                                :value="item.value">
                                
                            </el-option>
                        </el-select>
                    <div class="mg_left_20">记录日期选择：</div>
                    <el-date-picker value-format="timestamp" v-model="recordTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" class="mg_left_10" :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-button type="primary" class="mg_left_10" size="medium" style=" background: #009BFD;border: none;"
                        @click="CategoryQuery"> 查询
                    </el-button>
 <el-button type="primary" class="mg_left_10" @click="exportRepairRecord" size="medium"
                            style=" background: #009BFD;border: none;">导出</el-button> 
                </div>
            </div>
            <div class="pdl30 pdr30 hei80V">
                <el-table :data="queryList" style="width: 100%" class=" mg_top_20" height="100%"
                @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <el-table-column type="index" label="序号" width="80">
                    </el-table-column>
                    <!-- <el-table-column prop="ID" label="id" width="80">
                    </el-table-column> -->
                    <!--                     <el-table-column prop="projectID" label="项目编号" width="180">
                    </el-table-column>
                    <el-table-column prop="value" label="评分等级" width="180">
                    </el-table-column>
                    <el-table-column prop="gradeSettingsCategory" label="评分等级分类" width="180">
                    </el-table-column>
                    <el-table-column prop="remarks" label="描述">
                    </el-table-column> -->
                    <!-- <el-table-column prop="ID" label="id">
                    </el-table-column> -->
                    <el-table-column prop="userName" label="用户名">
                    </el-table-column>
                    <el-table-column prop="roleName" label="角色名">
                    </el-table-column>
                    <!-- <el-table-column prop="authResult" label="审批结果">
                    </el-table-column> -->
                    <el-table-column  label="审批结果">
                        <template slot-scope="scope">
                            <span v-if="scope.row.authResult == 1">审批通过</span>
                            <span v-if="scope.row.authResult == 0">审批驳回</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="authSort" label="审批顺序">
                    </el-table-column>
                    <el-table-column prop="authType" label="审批类型">
                    </el-table-column>
                    <el-table-column prop="recordID" label="审批单号">
                    </el-table-column>
                    <el-table-column prop="recordTime" label="记录时间" :formatter="formatDate">
                    </el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号">
                    </el-table-column> -->


                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>



    </div>
</template>
<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        authoritymanagementAuthorizerecordQueryLimit,
        authoritymanagementAuthorizerecordQuery,
        authoritymanagementAuthorizerecordDelete
    } from "@/api/api_systemConfig";
    export default {
        data() {
            return {
                authResult:-1,
                authResultArray:[
                    {
                        name: '全部',
                        value: -1
                    },
                    {
                        name: '审批通过',
                        value: 1
                    },
                    {
                        name: '审批驳回',
                        value: 0
                    }                 
                ],
                value: '',
                options: [],
                dialogFormVisible: false,
                dialog2FormVisible: false,
                //评分等级新增form
                form: {
                    userID: '',
                    gradeSettings: 0,
                    gradeSettingsCategory: '',
                    remarks: ''
                },
                updateid: '',
                updateform: {
                    id: '',
                    gradeSettings: '',
                    gradeSettingsCategory: '',
                    remarks: ''
                },
                queryList: [],
                //rMIGradeCategoryQuery:[],
                deleteNum: '',
                formLabelWidth: '120px',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                roleName: '',
                recordTime: null,
                //Category: '',  
              userID: '',
              authType:'',

            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            };

            //页面加载即调用分页筛选方法、刷新展示数据到页面
            this.authoritymanagementAuthorizerecordQueryLimit();
        },
        methods: {
             //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '审批记录表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            //分页筛选
            //分页筛选方法
            async authoritymanagementAuthorizerecordQueryLimit() {
                //封装参数
                var param = {
                    userID: this.userID,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    // authType:this.authResult,
                    authResult:this.authResult,
                    recordID:this.recordID
                };
                if (this.roleName != null && this.roleName != "") {
                    param.roleName = this.roleName;
                }
                if (this.recordTime != null) {
                    param.recordStartTime = this.recordTime[0];
                    param.recordEndTime = this.recordTime[1];
                }
                 if (this.authResult != '') {
                    param.authResult = this.authResult
                }
               
                console.log(param);

                const res = await authoritymanagementAuthorizerecordQueryLimit(param);
                console.log(res);

                this.queryList = res.rows;
                this.pageQuantity = res.pageQuantity;
            },
            //点击页码触发分页查询
            handleCurrentChange(val) {
                this.currentPage = val;
                this.authoritymanagementAuthorizerecordQueryLimit();
            },

            //修改
            gotoModify(temp) {

            },


            //删除
            delTableData(id) {
                this.deleteNum = id;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用审批顺序配置-删除方法
                    this.currentPage = this.delData.delData(this.queryList.length, this.pageQuantity,this.currentPage)
                    this.authoritymanagementAuthorizerecordDelete();
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            async authoritymanagementAuthorizerecordDelete() {
                //封装参数
                var param = {
                    ID: this.deleteNum
                }
                console.log(param)

                const res = await authoritymanagementAuthorizerecordDelete(param);
                console.log(res)

                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.authoritymanagementAuthorizerecordQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //按条件筛选
            CategoryQuery() {
                this.currentPage = 1;
                this.authoritymanagementAuthorizerecordQueryLimit();
            },

            //时间戳显示转换
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            }
        }
    }
</script>
<style scoped>
    .hei80V {
        height: 85%;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 14px
    }
</style>