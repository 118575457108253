<!--  设备管理-备件管理-备件台账-详情 -->
<template>
    <div class="page_container height_100">
        <div class="height_100">
            <inner-top-nav></inner-top-nav>
            <div class="box_head1 width_100">备件信息</div>
            <div class="bgc_FFFFFF  flex flex_column align_center ">
                <el-form :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                    style="width: 65%;">
                    <!-- <el-form-item label="id：" class=" ">
                    <el-input v-model="formInline.ID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目编号：" class=" ">
                    <el-input v-model="formInline.projectID" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item> -->
                    <!--  sparePartTypeArray:[],
                sparePartUnitArray:[],
                sparePartStockReplaceCycleUnitArray:[] -->
                    <el-form-item label="设备编号：">
                            <el-input v-model="formInline.equipmentID" placeholder="" class="width_180"
                                disabled>
                            </el-input>
                    </el-form-item>
                    <el-form-item label="设备名称：">
                            <el-input v-model="formInline.equipmentName" placeholder="" class="width_180"
                                disabled>
                            </el-input>
                    </el-form-item>
                    <el-form-item label="备件编号：" class=" ">
                        <el-input v-model="formInline.sparePartID" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备件名称：" class=" ">
                        <el-input v-model="formInline.sparePartName" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备件类型：">
                        <!-- <el-input v-model="formInline.sparePartType" placeholder="" class="width_180" disabled>
                    </el-input> -->
                        <el-select v-model="formInline.sparePartType" placeholder="请选择" class="width_180" disabled>
                            <el-option v-for="item in sparePartTypeArray" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备件型号：" class=" ">
                        <el-input v-model="formInline.sparePartModel" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="生产厂商：">
                        <el-input v-model="formInline.manufacturer" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="供应商：">
                        <el-input v-model="formInline.supplier" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备件库存：" class=" ">
                        <el-input v-model="formInline.stockQuan" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="更换周期：" class=" ">
                        <el-input v-model="formInline.replaceCycle" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="更换周期单位：" class=" ">
                        <!-- <el-input v-model="formInline.replaceCycleUnit" placeholder="" class="width_180" disabled>
                    </el-input> -->
                        <el-select v-model="formInline.replaceCycleUnit" placeholder="" class="width_180" disabled>
                            <el-option v-for="item in sparePartStockReplaceCycleUnitArray" :key="item.value"
                                :label="item.description" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="参考价（元）：" class=" ">
                        <el-input v-model="formInline.referPrice" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="单位：">
                        <!-- <el-input v-model="formInline.unit" placeholder="" class="width_180" disabled></el-input> -->
                        <el-select v-model="formInline.unit" placeholder="" class="width_180" disabled>
                            <el-option v-for="item in sparePartUnitArray" :key="item.value" :label="item.description"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="库存上限：" class=" ">
                        <el-input v-model="formInline.stockMax" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="库存下限：" class=" ">
                        <el-input v-model="formInline.stockMin" placeholder="" class="width_180" disabled>
                        </el-input>
                    </el-form-item>

                </el-form>
                <el-form :model="formInline" :label-width="formLabelWidth" class="" style="width: 65%;">
                    <!-- remarks -->
                    <el-form-item label="备注：" class=" ">
                        <el-input v-model="formInline.remarks" type="textarea" rows="4" placeholder="" class="" disabled>
                        </el-input>
                    </el-form-item>
                </el-form>

                <div class="flex mg_top_30 mg_bottom_30">
                    <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        sparePartStockQuery,
        sparePartTypeQuery,
        sparePartUnitQuery,
        sparePartStockReplaceCycleUnitQuery
    } from "@/api/api_deviceManagement";
    // sparePartStockQuery
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                formInline: {},
                formLabelWidth: '150px',
                detailID: '',

                sparePartTypeArray: [],
                sparePartUnitArray: [],
                sparePartStockReplaceCycleUnitArray: []
            }
        },
        mounted() {
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.sparePartStockQuery();
                // this.sparePartInventoryQuery();
                // this.sparePartOutWarehouseDetailQuery();
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.sparePartTypeQuery();
            this.sparePartUnitQuery();
            this.sparePartStockReplaceCycleUnitQuery();
            // this.formInline = this.$route.query.data;

        },
        methods: {
            async sparePartStockQuery() {
                var param = {
                    ID: this.detailID
                }
                const res = await sparePartStockQuery(param);
                this.formInline = res.rows[0]
            },

            // 备件类型下拉选
            async sparePartTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await sparePartTypeQuery(param);
                this.sparePartTypeArray = res.data;
            },
            // 备件单位下拉选
            async sparePartUnitQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await sparePartUnitQuery(param);
                this.sparePartUnitArray = res.data;
            },
            // 备件单位更换周期下拉选
            async sparePartStockReplaceCycleUnitQuery() {
                var param = {}
                const res = await sparePartStockReplaceCycleUnitQuery(param);
                this.sparePartStockReplaceCycleUnitArray = res.array;
            },
            colsePage() {
                this.$router.go(-1);
            }
        }
    }
</script>
<style scoped>
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }
</style>