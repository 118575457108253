<!-- 系统配置-KPI算法配置 -->
<template>
    <div class="page_container height_100">

        <div class="bgc_FFFFFF faultCodeAnalysisBody height_100">
            <div class="box_head1">
                <span>KPI算法配置</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <div>类型选择：</div>
                    <el-select v-model="kpiAlgorithmType" placeholder="请选择" class="mg_left_8 width_150">
                        <el-option v-for="item in dataFrame" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="mg_left_20" size="medium" style=" background: #009BFD;border: none;"
                        @click="changeKpiAlgorith">查询
                    </el-button>
                    <!-- <el-button type="primary" class="width_110 mg_left_20" style=" background: #009BFD;border: none;"
                    @click="editVisible">编辑
                </el-button> -->
                </div>
            </div>

            <div class="pdl30 pdr30" style="height:89%">
                <!-- @selection-change="handleSelectionChange" -->
                <el-table ref="multipleTable" :data="kpiAlgorithmList" tooltip-effect="dark" style="width: 100%;"
                    class="mg_top_20" height="100%">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="name" label="名称" width=""></el-table-column>
                    <el-table-column prop="description" label="描述" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="typeName" label="类型" width=""></el-table-column>

                    <el-table-column label="激活状态" width="">
                        <template slot-scope="scope">
                            <span v-if='scope.row.status==true' class="color_00FF00">{{scope.row.statusName}}</span>
                            <span v-else-if="scope.row.status==false"
                                class="color_F12727">{{scope.row.statusName}}</span>
                            <span v-else-if="scope.row.status==undefined"
                                class="color_1A1A1A">{{scope.row.statusName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="expression" label="表达式" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="level1" label="等级1" width=""></el-table-column>
                    <el-table-column prop="level2" label="等级2" width=""></el-table-column>
                    <el-table-column prop="level3" label="等级3" width=""></el-table-column>
                    <el-table-column prop="level4" label="等级4" width=""></el-table-column>
                    <el-table-column prop="highLimit" label="高限" width=""></el-table-column>
                    <el-table-column prop="lowLimit" label="低限" width=""></el-table-column>
                    <el-table-column prop="expressionDescription" label="公式描述" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <!-- <el-button v-if="scope.row.type==1" type="text" class="color_0384D5 pd_0"
                                @click="editVisible(scope.row)">查看
                            </el-button> -->
                            <el-button type="text" class="color_0384D5 pd_0" @click="editVisible(scope.row)">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

            </div>
            <!-- 页码 -->
            <!-- <div class="flex justify_between align_start mg_bottom_10">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：120</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->
            <!-- dialog 编辑 -->
            <el-dialog title="公式表配置界面" :visible.sync="dialogTableVisible" top='5vh' width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold"></div> -->
                <div class="color_F12727">当类型为公式时，可配置表达式、状态；为因子时表达式、状态配置界面灰色</div>
                <div class="flex justify_between pdl30 pdr30">
                    <el-form :inline="true" :model="currentInfo" label-width="100px"
                        class="mg_top_20 flex justify_between flex_column align_center">
                        <el-form-item label="状态：" prop="status">
                            <!-- <el-radio v-model="currentInfo.status" >启用</el-radio> -->
                            <el-checkbox v-model="currentInfo.status" class="width_270"
                                :disabled="currentInfo.status == true ||currentInfo.type == 1">启用</el-checkbox>
                        </el-form-item>
                        <el-form-item label="名称：" prop="name">
                            <el-input v-model="currentInfo.name" placeholder="" class="width_270" :disabled="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="变量名：" prop="variable">
                            <el-input v-model="currentInfo.variable" placeholder="" class="width_270" :disabled="true">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="表达式：" prop="expression">
                            <el-input v-model="currentInfo.expression" placeholder="" class="width_270"
                                :disabled="currentInfo.status == null">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="等级1：" prop="level1" v-if="currentInfo.type == 2">
                            <el-input v-model="currentInfo.level1" type="number" placeholder="" class="width_270">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="等级2：" prop="level2" v-if="currentInfo.type == 2">
                            <el-input v-model="currentInfo.level2" type="number" placeholder="" class="width_270">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="等级3：" prop="level3" v-if="currentInfo.type == 2">
                            <el-input v-model="currentInfo.level3" type="number" placeholder="" class="width_270">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="等级4：" prop="level4" v-if="currentInfo.type == 2">
                            <el-input v-model="currentInfo.level4" type="number" placeholder="" class="width_270">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="高限：" prop="highLimit" v-if="currentInfo.type == 2">
                            <el-input v-model="currentInfo.highLimit" type="number" placeholder="" class="width_270">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="低限：" prop="lowLimit" v-if="currentInfo.type == 2">
                            <el-input v-model="currentInfo.lowLimit" type="number" placeholder="" class="width_270">
                            </el-input>
                        </el-form-item>
                        <!-- expressionDescription  公式描述 -->
                        <el-form-item label="公式描述：" prop="expressionDescription" v-if="currentInfo.type == 2">
                            <el-input type="textarea" :rows="3" v-model="currentInfo.expressionDescription"
                                resize="none" placeholder="请输入介绍信息" class="width_270 flex">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="描述：" prop="description">
                            <el-input type="textarea" :rows="3" v-model="currentInfo.description" resize="none"
                                placeholder="请输入介绍信息" class="width_270 flex">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- <el-form :model="currentInfo" label-width="100px" class=" pdl30 pdr30">
                    <el-form-item label="描述：" prop="description">
                        <el-input type="textarea" :rows="6" v-model="currentInfo.description" resize="none"
                            placeholder="请输入介绍信息" class="width_270 flex" :disabled="currentInfo.status == null">
                        </el-input>
                    </el-form-item>
                </el-form> -->
                <div class="flex justify_center align_center">
                    <div class="mg_right_10">注：</div>
                    <div style="width: 335px;">{{descriptionArr2}}</div>
                </div>
                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button type="primary">
                        <!-- <div v-if="currentInfo.type == 1" @click="dialogTableVisible = false">关闭</div> -->
                        <div @click="kpiConfigModification">修改</div>
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        kpiConfigExpressionQuery,
        kpiConfigExpressionModification,
    } from "@/api/api_systemConfig";

    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                input: '',
                dialogTableVisible: false,
                kpiAlgorithm: [],
                // kpiAlgorithmValue: '',
                kpiAlgorithmType: '2',
                kpiAlgorithmList: [],
                descriptionArr: [],
                descriptionArr2: '',
                dataFrame: [{
                    value: '1',
                    label: '因子'
                }, {
                    value: '2',
                    label: '公式'
                }],
                formInline: {
                    description: '',
                    variable: '',
                    name: '',
                    status: '',
                },
                multipleSelection: [],
                currentInfo: {},

                descriptionArrTest: []


            }
        },
        mounted() {
            
            this.kpiConfigExpressionQueryArr();
            this.kpiConfigExpressionQuery();
            // setTimeout(() => {
            //     this.descriptionArr = this.descriptionArrTest;
            //     console.log(this.descriptionArr)
            // }, 1000)
            // this.kpiConfigExpressionModification();
        },
        methods: {
            // handleSelectionChange(val) {
            //     // console.log(val)
            //     this.multipleSelection = val;
            //     // this.currentInfo = val[0];//显示第一条选中的数据
            //     // console.log(val)
            //     // 备注：在取消全选的时候 val[0]是undefined，currentInfo取不到值，报错 所以不要在此处对currentInfo赋值
            // },
            //接口-开始
            //类型选择
            //table
            async kpiConfigExpressionQueryArr() {
                const selectRes = await kpiConfigExpressionQuery();
                this.descriptionArr = selectRes.data.descriptionArr; //dialog中表达式的注释
            },
            async kpiConfigExpressionQuery() {
                var param = {
                    type: this.kpiAlgorithmType,
                }
                const selectRes = await kpiConfigExpressionQuery(param);
                this.kpiAlgorithmList = selectRes.data.dataList;
                // this.descriptionArrTest = selectRes.data.descriptionArr; //dialog中表达式的注释
                // console.log(this.descriptionArr)
            },
            changeKpiAlgorith() { //input查询
                this.kpiConfigExpressionQuery()
            },
            //dialog
            async kpiConfigExpressionModification() {
                var param = {};
                if (this.currentInfo.type == 1) { //因子是能已修改描述
                    param.ID = this.currentInfo.ID;
                    param.description = this.currentInfo.description;
                    param.type = Number(this.currentInfo.type)
                    // console.log(typeof Number(this.currentInfo.type))
                } else {
                    var paramCurrent = {
                        index: this.currentInfo.index,
                        ID: this.currentInfo.ID,
                        description: this.currentInfo.description,
                        expression: this.currentInfo.expression,
                        active: this.currentInfo.status,
                        highLimit: this.currentInfo.highLimit,
                        lowLimit: this.currentInfo.lowLimit,
                        level1: this.currentInfo.level1,
                        level2: this.currentInfo.level2,
                        level3: this.currentInfo.level3,
                        level4: this.currentInfo.level4,
                        expressionDescription: this.currentInfo.expressionDescription,
                        type: Number(this.currentInfo.type)
                    }
                    param = paramCurrent
                }
                // var param = {
                //     index: this.currentInfo.index,
                //     ID: this.currentInfo.ID,
                //     description: this.currentInfo.description,
                //     expression: this.currentInfo.expression,
                //     active: this.currentInfo.status,
                //     highLimit: this.currentInfo.highLimit,
                //     lowLimit: this.currentInfo.lowLimit,
                //     level1: this.currentInfo.level1,
                //     level2: this.currentInfo.level2,
                //     level3: this.currentInfo.level3,
                //     level4: this.currentInfo.level4,
                //     expressionDescription: this.currentInfo.expressionDescription
                // }
                const selectRes = await kpiConfigExpressionModification(param);
                // 此处修改成功的话，需要加个提示框提示修改成功失败
                //判断是否修改成功
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisible = false;
                    this.kpiConfigExpressionQuery();
                    this.currentInfo = {}
                } else {
                    // this.$message({
                    //     message: selectRes.message,
                    //     type: 'warning'
                    // });
                    this.$message.error(selectRes.message);
                    // this.kpiConfigExpressionQuery()
                }

                // console.log(selectRes.data)
                // console.log(selectRes.data.result)//true或false
            },
            kpiConfigModification() { //点击dialog中的‘修改’按钮
                console.log(this.currentInfo)
                //类型为因子（1）时，不调接口；类型为公式（2）时，调接口
                this.kpiConfigExpressionModification()
            },
            //接口-结束
            editVisible(row) { //点击‘编辑’按钮的方法
                this.currentInfo = row;
                this.descriptionArr2 = ""
                for (var i = 0; i < this.descriptionArr.length; i++) {
                    // this.descriptionArr2 += this.descriptionArr[i].expression + "为" + this.descriptionArr[i].name + "\n"
                    this.descriptionArr2 += this.descriptionArr[i].expression + "为" + this.descriptionArr[i].name +
                        ";"
                }
                // console.log(this.descriptionArr2)
                this.dialogTableVisible = true; //打开dialog
                // }

            },
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .alarmQuerySelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }

    .faultCodeAnalysisBody {
        /* height: 720px; */
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 6px;
    }
</style>