<!-- -故障报修--审核 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">基本信息</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_24 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备名称：">
                    <!-- <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select> -->
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备类别：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="规格型号：">
                    <!-- <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select> -->
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="所在部门：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                    <!-- <el-date-picker v-model="value1" type="date" placeholder="选择日期时间">
                    </el-date-picker> -->
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">报修信息</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_24 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="发生时间：">
                    <el-date-picker prefix-icon="el-icon-date" v-model="value1" type="datetime"
                        format="yyyy-MM-dd HH:mm" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="操作人：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="操作者电话：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="故障等级：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="使用状况：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="故障类别：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备位置：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="是否停机：">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报修时间：">
                    <el-date-picker prefix-icon="el-icon-date" v-model="value1" type="datetime"
                        format="yyyy-MM-dd HH:mm" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="报修人：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" label-width="90px" style="width:63%; ">
                <el-form-item label="故障描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form>

            <div class="box_head1 width_100">审核</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_24 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="审核人：">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="审核意见：">
                    <!-- <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                </el-select> -->
                    <el-radio-group v-model="radio1">
                        <el-radio :label="1">立即维修</el-radio>
                        <el-radio :label="2">驳回</el-radio>
                    </el-radio-group>
                    <!-- <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input> -->
                </el-form-item>
            </el-form>
            <el-form :model="formInline" label-width="110px" style="width:65%; ">
                <el-form-item label="处理意见：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form>



            <div class="flex mg_top_20 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        failureReportQuery,
        failureReportAuthorize
    } from '@/api/api_deviceManagement.js'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                formInline: {
                    user: '',
                    region: ''
                },
                ID:'',
                userID:'',
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.failureReportQuery()
        },
        methods: {
            sbumit() {
                this.$router.go(-1);
            },
            colsePage() {
                this.$router.go(-1);
            },
            //查询单条
            async failureReportQuery() {
                // this.ID=sessionStorage['delNum']
                console.log(this.ID)
                var param = {
                    ID:this.ID,
                }
                const selectRes = await failureReportQuery(param);
                this.formInline=selectRes.rows[0]
                console.log(this.formInline);
                this.failureReportAuthorize()
            },

             async failureReportAuthorize() {
                  this.ID=sessionStorage['delNum']
                //   this.userID=sessionStorage['userID']
                  this.authResult=sessionStorage['authResult']
                var param = {
                    userID:this.userID,
                    ID:this.ID,
                    authResult:this.authResult
                }
                const selectRes = await failureReportAuthorize(param);
                this.result=selectRes
                console.log(this.result);
                
            },
        }

    }
</script>
<style scoped>
  
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }
</style>