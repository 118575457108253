/**
 * 综合看板
 */ 
 import req from './index.js'

 // 综合看板-工段长（产线）
 // 产线-组织架构选择
 export const organizationSelect = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/OrganizationSelect',param) };
 // 产线-获取看板标题
 export const lineDashBoardGetTitle = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LineDashBoardGetTitle',param) };
 // 产线-设备产能对比
 export const lineProductionComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LineProductionComparison',param) };
 // 产线-设备开机率利用率对比
 export const lineUseRatioComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LineUseRatioComparison',param) };
 // 产线-设备综合利用率
 export const lineSyntheticalUseRatioComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LineSyntheticalUseRatioComparison',param) };
 // 产线-设备布局数据
 // export const lineLayoutData = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LineLayoutData',param) };
 // 产线-设备节拍分析
 export const lineRhythmData = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LineRhythmData',param) };
 // 产线-设备巡检情况
 export const linePointInspectioData = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LinePointInspectioData',param) };
 // 产线-设备保养维修计划
 export const lineMaintainPlanData = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LineMaintainPlanData',param) };
 // 产线-各设备能耗数据
 export const lineEnergyData = param => { return req.post('EMS.Thing.Api.Board.Organization.Line/Services/LineEnergyData',param) };
 
 // 数据驾驶舱-装配线-设备布局
 // 装配线-设备布局
 // export const equipmentLayoutData= param => { return req.post('EMS.Thing.Api.DashBoard.Fit/Services/EquipmentLayoutData',param) };
 
 // 生产综合看板-厂（制造）
 // 制造-获取看板标题
 export const plantDashBoardGetTitle = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantDashBoardGetTitle',param) };
 // 制造-组织架构选择
 export const plantOrganizationSelect = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/OrganizationSelect',param) };
 // 制造-工厂设备构成按设备类型统计
 export const plantEquipmentConstitute = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantEquipmentConstitute',param) };
 // 制造-工厂各成交设备利用率对比
 export const plantWorkShopUseRatioComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantWorkShopUseRatioComparison',param) };
 // 制造-工厂各车间综合利用率
 export const plantWorkShopSyntheticalUseRatioComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantWorkShopSyntheticalUseRatioComparison',param) };
 // 制造-工厂设备各状态统计
 export const plantEquipmentStatusStatistics = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantEquipmentStatusStatistics',param) };
 // 制造-工厂设备故障分析
 export const plantFaultAnalysis = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantFaultAnalysis',param) };
 // 制造-工厂各车间维修产出比
 export const plantMaintainOutputProportion = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantMaintainOutputProportion',param) };
 // 制造-工厂各车间MTTR/MTBF/故障率比较
 export const plantMttrMtbfFaultrateComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantMttrMtbfFaultrateComparison',param) };
 // 制造-工厂待机分析
 export const plantStandbyAnalysis = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantStandbyAnalysis',param) };
 // 制造-工厂停机分析
 export const plantStopAnalysis = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantStopAnalysis',param) };
 // 制造-工厂各车间能耗数据
 export const plantWorkShopEnergyData = param => { return req.post('EMS.Thing.Api.Board.Organization.Plant/Services/PlantWorkShopEnergyData',param) };
 
 
 // 生产综合看板-厂长（车间）
 // 车间-获取看板标题
 export const workShopDashBoardGetTitle = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopDashBoardGetTitle',param) };
 // 车间-组织架构选择
 export const workShopOrganizationSelect = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/OrganizationSelect',param) };
 // 车间-车间产线产能对比
 export const workShopLineProductionComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopLineProductionComparison',param) };
 // 车间-车间各产线开机率利用率对比
 export const workShopLineUseRatioComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopLineUseRatioComparison',param) };
 // 车间-车间各产线综合利用率
 export const workShopLineSyntheticalUseRatioComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopLineSyntheticalUseRatioComparison',param) };
 // 车间-车间产线节拍分析
 export const workShopRhythmData = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopRhythmData',param) };
 // 车间-车间产线设备故障分析
 export const workShopFaultAnalysis = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopFaultAnalysis',param) };
 // 车间-车间待机分析
 export const workShopStandbyAnalysis = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopStandbyAnalysis',param) };
 // 车间-车间停机分析
 export const workShopStopAnalysis = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopStopAnalysis',param) };
 // 车间-车间各产线能耗数据
 export const workShopLineEnergyData = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopLineEnergyData',param) };
 // 车间-车间维修产出比
 export const workShopMaintainOutputProportion = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopMaintainOutputProportion',param) };
 // 车间-车间MTBF/MTTR/故障率
 export const workShopMttrMtbfFaultrateComparison = param => { return req.post('EMS.Thing.Api.Board.Organization.WorkShop/Services/WorkShopMttrMtbfFaultrateComparison',param) };
 
 
 //运维综合看板
 export const dashBoardGetTitle = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/DashBoardGetTitle',param) }; //获取看板标题
 export const organizationSelectOperations = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/OrganizationSelect',param) }; //组织架构选择
 export const equipmentRealAlarmInfo = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/EquipmentRealAlarmInfo',param) }; //各组织级设备报警信息
 export const equipmentUnexecutedWorkOrderInfor = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/EquipmentUnexecutedWorkOrderInfor',param) }; //各组织级设备未执行的工单信息
 export const mTBF = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/MTBF',param) }; //MTBF
 export const equipmentMaintenanceData = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/EquipmentMaintenanceData',param) }; //设备维保数据
 export const useRatio = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/UseRatio',param) }; //各组织的开机率、故障率
 export const equipmentFaultInfor = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/EquipmentFaultInfor',param) }; //故障信息
 export const maintenanceWorkOrderInfor = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/MaintenanceWorkOrderInfor',param) }; //维修工单现状
 export const mTTR = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/MTTR',param) }; //MTTR
 export const equipmentFaultAnalysis = param => { return req.post('EMS.Thing.Api.Board.Operations/Services/EquipmentFaultAnalysis',param) }; //各组织设备故障分析
 
 
 //集团级综合看板2
 export const dashBoardGetTitleGroup2 = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/DashBoardGetTitle',param) }; //获取看板标题
 export const operatRateComparison = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/OperatRateComparison',param) }; //各事业部开机率对比
 export const operatUtilizationRateComparison = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/OperatUtilizationRateComparison',param) }; //各事业部开利用机率对比
 export const overallEfficiencyComparison = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/OverallEfficiencyComparison',param) }; //各事业部综合率对比
 export const standbyAnalysis = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/StandbyAnalysis',param) }; //待机分析
 export const stopAnalysis = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/StopAnalysis',param) }; //停机分析
 export const mttrMtbfFaultrateComparison = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/MttrMtbfFaultrateComparison',param) }; //MTTR/MTBF/故障率比较
 export const faultTrendComparison = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/FaultTrendComparison',param) }; //各事业部故障趋势对比
 export const faultAnalysis = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/FaultAnalysis',param) }; //故障分析
 export const equipmentTypeStatistics = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/EquipmentTypeStatistics',param) }; //设备类型统计
 export const operationAndMaintenancJISHTimelyCompletionRate = param => { return req.post('EMS.Thing.Api.Board.Group2/Services/OperationAndMaintenancJISHTimelyCompletionRate',param) }; //运维及时完成率
 
 
 //集团级综合看板1
 export const equipmentKpi = param => { return req.post('EMS.Thing.Api.Board.Group1/Services/EquipmentKpi',param) }; //各事业部的设备指标
 export const dashBoardGetTitle1 = param => { return req.post('EMS.Thing.Api.Board.Group1/Services/DashBoardGetTitle',param) }; //获取看板标题
 export const connectEquipmentTotal = param => { return req.post('EMS.Thing.Api.Board.Group1/Services/ConnectEquipmentTotal',param) }; //各事业部的设备指标
 export const equipmentStatus = param => { return req.post('EMS.Thing.Api.Board.Group1/Services/EquipmentStatus',param) }; //各事业部的设备状态
 export const mapData = param => { return req.post('EMS.Thing.Api.Board.Group1/Services/MapData',param) }; //各事业部的MapData
 
 
 
 // 山矿接口
 
 export const getMapKanbanData = param => { return req.post('EMS.Thing.Api.Kanban/Services/GetMapKanbanData',param) }; //接入公司数量
 export const getMainMapKanbanData = param => { return req.post('EMS.Thing.Api.Kanban/Services/GetMainMapKanbanData',param) }; //接入公司数量 
 export const getOprKanbanData = param => { return req.post('EMS.Thing.Api.Kanban/Services/GetOprKanbanData',param) }; 
 
 export const getKanBanDate = param => { return req.post('EMS.Thing.Api.Kanban/Services/GetKanBanDate',param) }; 
