<!-- 振动分析诊断 -->
<template>
    <div class="page_container ">
        <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">振动分析诊断</div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30">
            <div class="flex pdt_20 fn_size16">
                <div class="flex divMargin align_center">
                    <div class="spanWidth">机器名称:</div>
                    <el-input v-model="mIDValue" style="width: 180px;" placeholder="请输入"></el-input>
                    <!-- <el-button type="primary" class="width_110 mg_left_10" style=" background: #009BFD;border: none;"
                        @click="queryTest">查询
                    </el-button> -->                    
                </div> 
                <div class="flex divMargin align_center">
                    <div class="mg_left_20">创建时间：</div>
                    <el-date-picker v-model="timeValue" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
               <div class="flex justify_between" style="flex:1">
                    <el-button type="primary" class="width_110 mg_left_10" style=" background: #009BFD;border: none;"
                        @click="query">查询
                    </el-button>
                    <div>
                        <!-- <el-button type="primary" class="width_180 mg_left_10" style=" background: #009BFD;border: none;"
                            @click="linkMaintain()" v-if="this.machineLearning">预测性维护
                        </el-button> -->
                        <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #009BFD;border: none;">导出</el-button>
                        <el-button type="primary" class="width_180 mg_left_10" style=" background: #009BFD;border: none;"
                            @click="linkTo()">振动分析
                        </el-button>
                    </div>
               </div>
            </div>
            <div class="flex justify_between pdt_20 fn_size16">
                <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> -->
            </div>
            <!-- table -->
            <div>
                <el-table ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20"  @selection-change="handleSelectionChange" height='588'
                    :default-sort="{prop: 'date', order: 'descending'}" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="machineID" label="机器名称" width=""   show-overflow-tooltip></el-table-column>
                    <el-table-column prop="projectID" label="项目名称" width=""  ></el-table-column>
                    <el-table-column prop="healthStatus" label="健康状态" width=""  ></el-table-column>
                    <el-table-column prop="healthRank" label="健康等级" width="" ></el-table-column>
                    <el-table-column prop="sensor" label="测点"  ></el-table-column>
                    <el-table-column prop="procStatus" label="处理状态" width="" ></el-table-column>
                    <el-table-column prop="gmtCreate" label="创建时间" :formatter="formatDate" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="gmtProc" label="处理时间" :formatter="formatDate"  ></el-table-column>
                    <el-table-column prop="procDetail" label="处理详情"  show-overflow-tooltip></el-table-column>
                    <el-table-column prop="healthAssessment" label="健康状态评估" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="algorithmFactorDtos" label="算法指标原因明细"  show-overflow-tooltip></el-table-column> -->
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="check(scope.row)">详情</span>
                            </el-button>
                           
                        </template>
                    </el-table-column>
                </el-table>
                <div class="flex justify_end">
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>
            <!-- 选择框 -->
             <!-- <div class="bgc_E2F0FE flex align_end  height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">领用明细</div>
            </div>
            
            <div class="mg_top_22">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @selection-change="handleSelectionChange" height="242"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="" label="备件编号" width="" ></el-table-column>
                    <el-table-column prop="" label="备件名称" width=""></el-table-column>
                    <el-table-column prop="" label="备件型号" width=""></el-table-column>
                    <el-table-column prop="" label="单位" width="" ></el-table-column>
                    <el-table-column prop="" label="申请数量" ></el-table-column>
                    <el-table-column prop="" label="单价"></el-table-column>
                    <el-table-column prop="" label="申请总价"></el-table-column>
                    <el-table-column prop="" label="备注"></el-table-column>
                </el-table>
                <div class="flex justify_end">
                    <el-pagination layout="prev, pager, next" :total="pageQuantity1*pageSize1" :page-size="pageSize1"
                        :current-page.sync="currentPage1" @current-change="handleCurrentChange1"></el-pagination>
                </div>
            </div> -->
            <el-dialog :visible.sync="dialogVisibleDetail" width="70%">
                <div class=" flex justify_center align_center ">
                    <el-form :inline="true" :model="detailInline" label-width="110px" 
                        style="width: 70%;">
                        <el-form-item label="机器名称">
                            <el-input v-model="detailInline.machineID" placeholder="申请部门" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="项目名称">
                            <el-input v-model="detailInline.projectID" placeholder="申请仓库" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="健康状态">
                            <el-input v-model="detailInline.healthStatus" placeholder="备注区域" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="健康等级">                  
                            <el-input v-model="detailInline.healthRank" placeholder="总价" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="测点">
                            <el-input v-model="detailInline.sensor" placeholder="备注区域" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="处理状态">
                            <el-input v-model="detailInline.procStatus" placeholder="备注区域" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="创建时间">
                            <el-input v-model="detailInline.gmtCreate" placeholder="备注区域" :formatter="formatDate" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="处理时间">
                            <el-input v-model="detailInline.gmtProc" placeholder="备注区域" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="处理详情">
                            <el-input v-model="detailInline.procDetail" placeholder="备注区域" class=""></el-input>
                        </el-form-item>
                        <el-form-item label="健康状态评估">
                            <el-input v-model="detailInline.healthAssessment" placeholder="备注区域" class="width_180"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="零件健康状态信息">
                            <el-input v-model="detailInline.procDetail" placeholder="备注区域" class="width_180"></el-input>
                        </el-form-item> -->
                    </el-form>
                </div>
                <div class=" flex justify_center align_center" >
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" 
                        class="mg_top_20" height="320" style="width: 55%;"
                        :default-sort="{prop: 'date', order: 'descending'}">
                        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                        <el-table-column type="index" label="序号" width="55"></el-table-column>
                        <el-table-column prop="partInstanceId" label="零部件实例id" width="" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="componentName" label="部件名称" width="" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="partName" label="零件名称" width="" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="healthAssessed" label="零部件健康状态（0健康 1亚健康 2故障 3危险）" width="250" ></el-table-column>
                        <el-table-column prop="driveModel" label="驱动模型" width=""></el-table-column>
                        <el-table-column prop="wiki" label="建议" width=""></el-table-column>
                        <el-table-column prop="conclusion" label="结论" width="" ></el-table-column>
                        <!-- <el-table-column prop="procDetail" label="处理详情" ></el-table-column>
                        <el-table-column prop="imgs" label="处理"></el-table-column> -->
                    </el-table> 
                </div>
                <div class="flex justify_center pdb_20 mg_top_20 ">
                    
                    <el-button type="primary" @click="dialogVisibleDetail=false">确 定</el-button>
                </div>
            </el-dialog> 
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        diagnosisDataQueryLimit,
        diagnosisDataQuery,
        returnJumpURL
    } from '@/api/api_deviceManagement.js'
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tableData:[],
                multipleSelection: [],
                //页码
                currentPage: 1,
                currentPage1: 1,
                pageSize: 8,
                pageSize1: 5,
                pageQuantity: 0,
                pageQuantity1: 0,
                listData:[],
                input:'',
                timeValue:null,
                mIDValue:'',
                dialogVisibleDetail:false,
                detailInline:{},
                rowData:{},
                linkURL:'',
                machineLearning:'',
            }
        },
        created(){
            
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
                this.machineLearning = userInfo.machineLearning;
            }
            this.diagnosisDataQueryLimit(),
            this.returnJumpURL()
            
        },

        methods: {
              //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '振动分析诊断')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },
            //查询按钮
            query(){
                this.diagnosisDataQueryLimit()
            },
            async diagnosisDataQueryLimit(){
                
                var param = {
                    // startGmtCreate:this.timeValue[0],
                    // endGmtCreate:this.timeValue[1],
                    // healthStatus
                    // healthRank

                    // projectID
                    // machineID:this.mIDValue,
                    // ID
                    pageSize:this.pageSize,
                    pageNumber:this.currentPage,
                    // userID
                }
                console.log('a',this.timeValue);
                if(this.timeValue!=null){
                    param.startGmtCreate=this.timeValue[0]
                }
                if(this.timeValue!=null){
                    param.endGmtCreate=this.timeValue[1]
                }
                if(this.mIDValue!=''){
                    param.machineID=this.mIDValue
                }
                const selectRes = await diagnosisDataQueryLimit(param);
                this.listData =  selectRes.rows;
                this.pageQuantity = selectRes.pageQuantity;
               },
            async diagnosisDataQuery(){
                var param = {
                    ID:this.rowData.ID,
                  
                }
                const selectRes =await diagnosisDataQuery(param)
                this.detailInline=selectRes.rows[0]
                var str = selectRes.rows[0].diagnosisDataQuery
                this.tableData=JSON.parse(str).array
                console.log("table",this.tableData);
            },
            async returnJumpURL(){
                const res = await returnJumpURL();
                this.linkURL= res.URL
            },
            linkMaintain(){
                window.open('http://47.104.99.213:8080/Thingworx/Mashups/TW.Analytics.ConsoleMashup?appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true')
            },
            linkTo(){
                var link = this.linkURL
                window.open(link)
            },
            check(row){
                this.rowData = row;
                 localStorage.setItem("rowDID",this.rowData.ID)
                // this.dialogVisibleDetail = true
                 this.$router.push({
                    // query: {
                    //     id: id
                    // },
                    path: '/deviceManagement/vibrationAnalysisAlarmAndDiagnosis/alarm/detailDiagnosis',
                    // query:{setid:111222}
                    // params:{setid:111222}//地址栏中看不到参数的内容
                })
            },

            handleSelectionChange(val) {      
                this.multipleSelection = val;
                console.log("select",this.multipleSelection[0])
            },
            handleChange(id) {
                this.tabIndex = id;
                
            },         
            
            //日期格式
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            //处理结果
            formatResult(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                let ret = '';
                if (data == 0) {
                    ret = '未处理'
                };
                if(data == 1){
                    ret = '已处理'
                }
                return ret
            },
            //布尔值格式
            formatBoolean(row, column, cellValue) {
                var ret = ''  //你想在页面展示的值
                if (cellValue) {
                    ret = "是"  //根据自己的需求设定
                } else {
                    ret = "否"
                }
                return ret;
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.diagnosisDataQueryLimit(); //查询table数据的方法
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.sparePartPickDetailQueryLimit(); //查询table数据的方法
            },
            
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }
</style>
<style>

    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>