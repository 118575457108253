<!-- 系统配置-设备文档管理 -->
<template>
    <div class="page_container height_100">
        <inner-top-nav></inner-top-nav>
        <div class="height_100 bgc_FFFFFF">
            <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
                <div class="pdl20">{{this.RowEqName}}文档管理</div>
            </div>
            <div class=" pdl30 pdr30 pdt_20">
                <div class="flex justify_between">
                    <div class="box_1 bgc_2FB060">
                        <div class="fn_size16">{{quantityStatisticsInfo[0]&&quantityStatisticsInfo[0].FileName}}</div>
                        <div class="fn_size28 mg_top_17">{{quantityStatisticsInfo[0]&&quantityStatisticsInfo[0].Count}}
                        </div>
                    </div>
                    <div class="box_1 bgc_97AD01">
                        <div class="fn_size16">{{quantityStatisticsInfo[1]&&quantityStatisticsInfo[1].FileName}}</div>
                        <div class="fn_size28 mg_top_17">{{quantityStatisticsInfo[1]&&quantityStatisticsInfo[1].Count}}
                        </div>
                    </div>
                    <div class="box_1 bgc_926021">
                        <div class="fn_size16"> {{quantityStatisticsInfo[2]&&quantityStatisticsInfo[2].FileName}}</div>
                        <div class="fn_size28 mg_top_17">{{quantityStatisticsInfo[2]&&quantityStatisticsInfo[2].Count}}
                        </div>
                    </div>
                    <div class="box_1 bgc_166A8E">
                        <div class="fn_size16"> {{quantityStatisticsInfo[3]&&quantityStatisticsInfo[3].FileName}}</div>
                        <div class="fn_size28 mg_top_17">{{quantityStatisticsInfo[3]&&quantityStatisticsInfo[3].Count}}
                        </div>
                    </div>
                    <div class="box_1 bgc_657180">
                        <div class="fn_size16"> {{quantityStatisticsInfo[4]&&quantityStatisticsInfo[4].FileName}}</div>
                        <div class="fn_size28 mg_top_17">{{quantityStatisticsInfo[4]&&quantityStatisticsInfo[4].Count}}
                        </div>
                    </div>
                </div>

                <!-- 下半部分 -->
                <div class="flex mg_top_20">
                    <!-- 切换div -->
                    <div class="tab_horizontal2  mg_right_22 mg_bottom_20">
                        <div class="tab_box2 mg_top_60 " :class="tabIndex==1?'tab_box_active2':''"
                            @click="handleChange(1)">
                            说明书</div>
                        <div class="tab_box2" :class="tabIndex==2?'tab_box_active2':''" @click="handleChange(2)">电气文档
                        </div>
                        <div class="tab_box2" :class="tabIndex==3?'tab_box_active2':''" @click="handleChange(3)">机械文档
                        </div>
                        <div class="tab_box2" :class="tabIndex==4?'tab_box_active2':''" @click="handleChange(4)">维保说明
                        </div>
                        <div class="tab_box2" :class="tabIndex==5?'tab_box_active2':''" @click="handleChange(5)">其他文档
                        </div>
                    </div>
                    <!-- 按钮、table -->
                    <div class="flex flex_column " style="width: 100%;">
                        <!-- 按钮 -->
                        <div class="flex">
                            <el-button type="primary" class="width_120" style=" background: #03CABE;border: none;"
                                icon="el-icon-circle-plus-outline" @click="add">上传
                            </el-button>
                            <el-button type="primary" class="width_120" style=" background: #FEA313;border: none;"
                                icon="el-icon-circle-plus-outline" @click="editInfo">编辑
                            </el-button>
                            <el-button type="primary" icon="el-icon-delete" class="width_120 mg_left_10"
                                style=" background: #F75753;border: none;" @click="documentDelete">删除</el-button>
                        </div>
                        <!-- table -->
                        <div style="width: 100%" class="mg_top_20">
                            <el-table ref="multipleTable" :data="filesTable" tooltip-effect="dark" style="width: 100%"
                                @selection-change="handleSelectionChange" height="450">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column type="index" label="序号" width="55">
                                </el-table-column>
                                <el-table-column prop="fileName" label="文件名" width="">
                                </el-table-column>
                                <el-table-column prop="description" label="文件描述" width="" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="author" label="作者" width="">
                                </el-table-column>
                                <el-table-column prop="upLoader" label="上传人" width="">
                                </el-table-column>
                                <el-table-column prop="upLoadDate" label="上传日期" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="format" label="格式">
                                </el-table-column>
                                <!-- <el-table-column prop="" label="大小">
                            </el-table-column> -->
                                <!-- <el-table-column label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button type="text" class="color_0384D5 pd_0" @click="downloadFiles(scope.row)">
                                        <span style="border-bottom: 1px solid #0384D5;">下载</span>
                                    </el-button>
                                </template>
                            </el-table-column> -->
                            </el-table>
                        </div>
                        <!-- 页码 -->
                        <div class="flex justify_end align_start mg_bottom_10">
                            <!-- <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：120</div> -->
                            <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
                            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize"
                                :page-size="pageSize" :current-page.sync="currentPage"
                                @current-change="handleCurrentChangeRepair">
                            </el-pagination>
                        </div>
                    </div>
                </div>


                <!-- dialog 上传 -->
                <el-dialog :visible.sync="dialogTableVisible" top='10vh' width="30%">
                    <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">
                        {{this.RowEqName}}文档上传</div>
                    <div class="flex justify_between pdl30 pdr30">
                        <el-form :model="form" ref="formLine" label-width="100px" :rules="rulesFrom"
                            class="mg_top_30 flex justify_between flex_column align_center">
                            <el-form-item label="文档类型：" prop="fileType">
                                <el-select v-model="form.fileType" placeholder="请选择文档类型" class="width_270 ">
                                    <!-- <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option> -->
                                    <!-- documentTypeArray -->
                                    <el-option v-for="item in documentTypeArray" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="文档名称：" prop="fileName">
                                <el-input v-model="form.fileName" placeholder="请输入文档名称" class="width_270"></el-input>
                            </el-form-item>
                            <el-form-item label="作者：" prop="author">
                                <el-input v-model="form.author" placeholder="请输入文档作者" class="width_270"></el-input>
                            </el-form-item>
                            <!-- </el-form> -->
                            <!--
                <el-form :model="form" label-width="100px" class=" pdl30 pdr30"> -->
                            <el-form-item label="文档描述：" prop="user">
                                <el-input type="textarea" :rows="6" v-model="form.description" resize="none"
                                    placeholder="请输入文档描述" class="width_270 flex"></el-input>
                            </el-form-item>
                            <el-form-item label="文件上传：">
                                <span slot=""></span>
                                <el-upload action="string" :before-upload="beforeUpload" label-width="100px">
                                    <el-button size="small" type="primary">选择上传文件</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传txt/doc/docx/pdf文件，且不超过10M</div>
                                </el-upload>
                                <el-input v-model="fileNameTest" placeholder="请上传文件" class="width_270" :readonly="true">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!-- <div class="pdl30 pdr30 flex align_center mg_left_100">
                    <el-upload action="string" :before-upload="beforeUpload">
                        <el-button size="small" type="primary">选择上传文件</el-button>
                        <div slot="tip">只能上传rar/zip文件，且不超过500kb</div>
                        <el-input v-model="fileName" placeholder="文档作者" class="mg_left_10" :readonly="true"></el-input>
                    </el-upload>
                </div> -->
                    <!-- <div>
                    <input type="file" @change="handleFileChange" />
                    <el-button type="primary">上传</el-button>
                </div> -->

                    <!-- 按钮 -->
                    <div class="flex justify_center pdb_20 pdt_20">
                        <el-button type="primary" @click="submitBtn">确定</el-button>
                        <!--  -->
                    </div>
                </el-dialog>

                <!-- dialog 编辑 -->
                <el-dialog :visible.sync="dialogTableVisible2" top='10vh' width="30%">
                    <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">设备文档编辑</div>
                    <div class="pdl30 pdr30">
                        <el-form :model="newFrom" ref="formLine" label-width="100px" :rules="rulesFrom"
                            class="mg_top_30 flex justify_between flex_column align_center">
                            <el-form-item label="文档类型：" prop="fileType">
                                <el-select v-model="newFrom.fileType" placeholder="说明书" class="width_270 flex">
                                    <el-option v-for="item in documentTypeArray" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="文档名称：" prop="fileName">
                                <el-input v-model="newFrom.fileName" placeholder="文档名称" class="width_270 flex">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="作者：" prop="author">
                                <el-input v-model="newFrom.author" placeholder="文档作者" class="width_270 flex"></el-input>
                            </el-form-item>
                            <el-form-item label="文档描述：" prop="description">
                                <el-input type="textarea" :rows="6" v-model="newFrom.description" resize="none"
                                    placeholder="请输入介绍信息" class="width_270 flex"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!-- <div>
                    <input type="file" @change="handleFileChange" />
                </div> -->
                    <!-- <div class="pdl30 pdr30 flex flex_column justify_start mg_left_100">
                        <el-upload action="https://jsonplaceholder.typicode.com/posts/" :on-preview="handlePreview"
                            :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3"
                            :on-exceed="handleExceed" label-width="100px">
                            <el-button size="small" type="primary">选择上传文件</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传rar/zip文件，且不超过500kb</div>
                        </el-upload>
                    </div> -->

                    <div class="flex justify_center pdb_20 pdt_20">
                        <el-button type="primary" @click="uploadDocument">修改</el-button>
                    </div>
                </el-dialog>

            </div>
        </div>

    </div>
</template>

<script>
    import innerTopNav from '../../components/innerTopNav'
    import axios from 'axios'
    import {
        equipmentDocumentQuantityStatistics,
        equipmentDocumentList,
        equipmentDocumentUpload,
        equipmentDocumentDelete,
        equipmentDocumentModification,
    } from "@/api/api_systemConfig";

    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                fileNameTest: '',
                uploadUrl: '',
                tabIndex: 1,
                value: '',
                dialogTableVisible: false,
                dialogTableVisible2: false,
                input: '',
                multipleSelection: [],
                quantityStatisticsInfo: [],
                url: '',
                formInline: {
                    user: '',
                    region: ''
                },
                form: {},
                newFrom: {},
                documentTypeArray: [{
                    value: '1',
                    label: '说明书'
                }, {
                    value: '2',
                    label: '电气文档'
                }, {
                    value: '3',
                    label: '机械文档'
                }, {
                    value: '4',
                    label: '维保说明'
                }, {
                    value: '5',
                    label: '其他文档'
                }],
                filesTable: [],
                // dialog表单验证规则
                rulesFrom: {
                    fileType: [{
                        required: true,
                        message: '请选择活文档类型',
                        trigger: 'change'
                    }],
                    fileName: [{
                        required: true,
                        message: '请输入文档名称',
                        trigger: 'blur'
                    }],
                    author: [{
                        required: true,
                        message: '请输入作者',
                        trigger: 'blur'
                    }],
                },
                fileList: [],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                blobFile: '',
                userInfo: {},
                RowEqID: '',
                RowEqName: '',
                userName: ''
            }
        },
        mounted() {
            if (localStorage.getItem('RowEqID') != null) {
                this.RowEqID = localStorage.getItem('RowEqID');
                // this.RowEqID = this.RowEqID.replace(/\"/g, "");
            }
            if (localStorage.getItem('RowEqName') != null) {
                this.RowEqName = localStorage.getItem('RowEqName');
                // this.RowEqName = this.RowEqName.replace(/\"/g, "");
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                // console.log(userInfo)
                this.userID = userInfo.userID;
                this.userName = userInfo.userName;
            }
            this.equipmentDocumentQuantityStatistics();
            this.equipmentDocumentList();
            console.log('ID', this.RowEqID);
            console.log('Name', this.RowEqName);
        },
        methods: {
            //切换页面纵向导航
            handleChange(id) {
                this.tabIndex = id;
                this.equipmentDocumentList();
            },
            downloadFiles(row) {
                console.log(row)
                // var api = 'http://121.37.181.242:8080';
                // var kpi = '?appKey=e814dc0d-b4b1-4c15-ae72-a749413b1d93';
                var patha = this.httpUrl + row.path + row.fileName + '.' + row.format + this.appKey;
                //下载
                fetch(patha).then(res => res.blob().then(blob => {
                        console.log(blob)
                        // let blob1 = new Blob([blob], {
                        //     type: 'text/plain'
                        // })
                        var a = document.createElement('a');
                        var url = window.URL.createObjectURL(blob);
                        console.log(url)
                        var filename = row.fileName + '.' + row.format;
                        a.href = url;
                        a.download = filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }))
                    .catch(error => {
                        console.log('Request Failed', error)
                    });
            },

            add() {
                this.form = {};
                this.fileNameTest = '';
                this.blobFile = '';
                this.dialogTableVisible = true;
            },

            async equipmentDocumentQuantityStatistics() {
                var param = {
                    equipmentID: this.RowEqID,
                }
                const selectRes = await equipmentDocumentQuantityStatistics(param);
                this.quantityStatisticsInfo = selectRes.data.Total;
            },
            async equipmentDocumentList() {
                var param = {
                    equipmentID: this.RowEqID,
                    documentType: this.tabIndex,
                    limitValue: this.pageSize,
                    offsetValue: this.currentPage

                }
                const selectRes = await equipmentDocumentList(param);
                if (selectRes.data) {
                    this.filesTable = selectRes.data.documentList;
                    this.pageQuantity = selectRes.pageQuantity
                }
                // this.quantityStatisticsInfo = selectRes.data.Total;
            },
            beforeUpload(file) {
                console.log(file)
                let that = this;
                that.file = file;
                that.fileNameTest = file.name;
                var fileType = file.name.split('.').slice(-1)
                console.log(fileType);
                that.form.format = fileType[0];
                if (fileType[0] == 'txt' || fileType[0] == 'doc' || fileType[0] == 'docx' || fileType[0] == 'pdf') {

                    // const extension = file.name.split('.').slice(-1) == 'pdf';
                    // 限制文件大小  小于6M
                    var fileSize = file.size / 1024 / 1024;
                    if (fileSize > 10) {
                        that.$confirm('文件大小须小于10M。', '提示', {
                            confirmButtonText: "确认",
                            cancelButtonText: "取消",
                            type: 'warning'
                        }).then().catch()
                        that.fileNameTest = '';
                        that.blobFile = '';
                        return false
                    }
                    // if (!extension) {
                    //     that.$message.warning('上传模板只能是pdf格式!')
                    //     that.fileName = '';
                    //     return false
                    // }
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        that.blobFile = reader.result;
                        console.log(reader.result)
                    };
                    return false; // 返回false不会自动上传
                } else {
                    that.$confirm('请上传txt/doc/docx/pdf文件', '提示', {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: 'warning'
                    }).then().catch()
                    that.fileNameTest = '';
                    that.blobFile = '';
                    return false
                }
            },

            submitBtn() {
                // console.log(this.form)
                this.$refs['formLine'].validate((valid) => {
                    if (valid) { //验证通过
                        if (this.fileNameTest == '') {
                            this.$message({
                                message: '请上传文件',
                                type: 'warning'
                            });
                            return
                        }
                        this.equipmentDocumentUpload();
                    } else {
                        return false;
                    }
                });
                // this.equipmentDocumentUpload();
            },

            async equipmentDocumentUpload() {
                this.form.equipmentID = this.RowEqID;
                this.form.upLoader = this.userName
                this.form.upLoadDate = new Date().getTime();
                this.form.content = this.blobFile;
                // var param = {
                //     equipmentID: '100004',
                //     UpLoader: '刘'
                //     // documentType:this.tabIndex
                // }
                const selectRes = await equipmentDocumentUpload(this.form);
                if (selectRes.data.message) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.equipmentDocumentList();
                    this.equipmentDocumentQuantityStatistics();
                    this.dialogTableVisible = false;
                    this.form = {};
                    this.blobFile = '';
                    this.fileNameTest = '';
                } else {
                    this.$message.error(selectRes.message);
                    // this.$message({
                    //     message: 'message',
                    //     type: 'warning'
                    // });
                    this.dialogTableVisible = false;
                    this.form = {};
                    this.blobFile = '';
                    this.fileNameTest = '';
                }
                // 
            },
            handleCurrentChangeRepair(val) {
                this.PageNumber = val;
                this.equipmentDocumentList(); //查询table数据的方法
            },
            handleSelectionChange(val) {
                // console.log(val)
                this.multipleSelection = val;
            },
            editInfo() {
                if (this.multipleSelection.join(',') == '') {
                    this.$message({
                        message: '警告：请选择需要修改的数据！！！',
                        type: 'warning'
                    });
                    return
                }
                if (this.multipleSelection.length > 1) {
                    this.$message({
                        message: '警告：请选择1条需要修改的数据！！！',
                        type: 'warning'
                    });
                    return
                }
                this.form = JSON.stringify(this.multipleSelection[0]);
                // // JSON.parse(str)字符串转对象   JSON.stringify(this.form)对象——》字符串
                console.log(JSON.stringify(this.form))
                this.newFrom = this.multipleSelection[0];
                this.dialogTableVisible2 = true;
            },
            uploadDocument() {
                this.$refs['formLine'].validate((valid) => {
                    if (valid) { //验证通过
                        console.log(JSON.parse(this.form))
                        console.log(this.newFrom);
                        //  console.log(this.multipleSelection)
                        this.equipmentDocumentModification();
                    } else {
                        return false;
                    }
                });

            },
            async equipmentDocumentModification() {
                // console.log(this.multipleSelection)
                this.form = JSON.parse(this.form)
                var param = {
                    equipmentID: this.form.equipmentID,
                    fileType: this.form.fileType,
                    fileName: this.form.fileName,
                    newFileType: this.newFrom.fileType,
                    newAuthor: this.newFrom.author,
                    newDescription: this.newFrom.description,
                    newFileName: this.newFrom.fileName
                }
                // console.log(param)
                const selectRes = await equipmentDocumentModification(param);
                if (selectRes.data.message) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisible2 = false;
                    this.newFrom = {};
                    this.equipmentDocumentList();
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    });
                }
            },
            documentDelete() {
                console.log(this.multipleSelection)
                if (this.multipleSelection.join(',') == '') {
                    this.$message({
                        message: '警告：请选择需要删除的数据！！！',
                        type: 'warning'
                    });
                    return
                }
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.filesTable.length, this.pageQuantity, this
                        .currentPage, this.multipleSelection.length)
                    this.equipmentDocumentDelete();

                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            async equipmentDocumentDelete() {
                var param = {
                    returnData: this.multipleSelection
                }
                const selectRes = await equipmentDocumentDelete(param);
                if (selectRes.data.message) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.equipmentDocumentList();
                } else {
                    this.$message({
                        message: '删除失败',
                        type: 'warning'
                    });
                }
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            handleFileChange(e) {
                console.log(e)
                let that = this;
                // const blob1 = new Blob([e]);
                // console.log("blob");
                // console.log(blob1)
                // const [file] = e.target.files;
                // if (!file) return;
                // this.container.file = file;
                // console.log("file")
                // console.log(this.container.file)
                // this.form.content = blob1;
                // // 初始化状态
                // this.container.hash = '';
                // this.container.hashSample = '';

                var fileType = file.name.split('.').slice(-1)
                console.log(fileType);
                that.form.Format = fileType[0]
                let file = e.target.files[0];
                console.log(file);
                // var fileType = file.name.split('.').slice(-1)
                // that.form.format = fileType[0];

                // that.form.FileName = file.name;
                let reader = new FileReader();
                let rs = reader.readAsDataURL(file);
                // console.log(rs)
                let blob = null;
                reader.onloadend = function (e) {
                    console.log(e);
                    if (typeof e.target.result === "object") {
                        blob = new Blob([e.target.result]);
                    } else {
                        blob = e.target.result;
                    }
                    // that.form.content = blob;
                    //    blob = blob.split(',').slice(-1)
                    that.form.content = blob;
                    // that.form.content = blob[0];
                    console.log(typeof that.form.content)
                    // console.log(that.form)
                }

            },
            //
        }
    }
</script>

<style scoped>
    .box_1 {
        width: 278px;
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
    }

    /* 页面里导航--开始 */
    .tab_horizontal2 {
        /* height: 64px;
        line-height: 64px; */
        width: 190px;
        background: #E2F0FE;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .tab_box2 {
        width: 176px;
        height: 50px;
        margin-left: 20px;
        line-height: 50px;
        color: #1A1A1A;
    }

    .tab_box_active2 {
        color: #009BFD;
        width: 176px;
        margin-left: 20px;
        border-left: 3px solid #009BFD;
        background-color: #FFFFFF;
        /* border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6; */
    }

    /*  页面里横向导航--结束 */
</style>
<style lang="less" scoped>
    /* ::v-deep .el-form--inline .el-form-item {
        display: flex;
        justify-content: start;
    } */

    ::v-deep .el-upload {
        display: flex;
        justify-content: start;
    }

    ::v-deep .el-upload__tip {
        display: flex;
        justify-content: start;
    }
</style>