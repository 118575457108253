<!-- 设备管理-保养管理-保养工单-修改工单 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">设备信息</div>
            <!-- <div class="flex flex_column align_center mg_bottom_10 mg_top_40 details_box"> -->
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备类型：" class=" ">
                    <el-input v-model="formInline.deviceType_name" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-input v-model="formInline.deviceModel" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="所属部门：" class=" ">
                    <el-input v-model="formInline.deviceDepartment" placeholder="请输入" class="width_180"
                        disabled></el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.deviceLocation" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <!-- <el-form-item class=" ">
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;"
                        @click="changeDevice">
                        选择设备</el-button>
                </el-form-item> -->
            </el-form>

            <!-- <div class="box_head1 width_100">基本信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_40 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="维修单号：">
                    <el-input v-model="formInline.user" placeholder="请输入维修单号" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>

                <el-form-item label="设备编码：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备类别：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="规格型号：">
                    <el-input v-model="formInline.user" placeholder="请输入维修单号" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="使用部门：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">故障信息</div>
            <el-form :inline="true" class="mg_top_40 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="报修单号：">
                    <el-input v-model="formInline.user" placeholder="请输入维修单号" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="发生时间：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>

                <el-form-item label="报修时间：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="报修人：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item>
                <el-form-item label="故障部位：" class="">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="故障类别：" class=" ">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="故障等级：" class="">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                <el-form-item label="故障描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form> -->

            <!-- <div class="box_head1 width_100">处理情况</div> -->
            <div class="box_head1 width_100">基本信息</div>

            <!-- <div class="flex flex_column align_center mg_bottom_20 mg_top_40 details_box"> -->
            <el-form :inline="true" class="mg_top_30 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="记录人：" class=" ">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="保养类型：">
                    
                    <el-select v-model="formInline.execType" placeholder="请选择" class="width_180">
                        <el-option v-for="item in maintainCategory" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="工单编号：" class=" ">
                    <el-input v-model="formInline.orderID" placeholder="" class="width_180">
                    </el-input>
                </el-form-item> -->
                <el-form-item label="当前审批顺序：" class=" ">
                    <el-input v-model="formInline.authRoleName" type="number" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="工单状态：">
                      <el-input v-model="formInline.orderStatus_name" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.orderStatus" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in workOrderStatus" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="保养等级：">
                    <el-select v-model="formInline.execLevel_name" placeholder="请选择" class="width_180">
                        <el-option v-for="item in maintainLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保养预估时间：" class=" ">
                    <el-input v-model="formInline.execEstimateLen" type="number" placeholder="" class="width_180">
                    </el-input>
                </el-form-item>
                <el-form-item label="紧急程度：">
                    <el-select v-model="formInline.urgenLevel_name" placeholder="请选择" class="width_180">
                        <el-option v-for="item in maintainUrgenLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保养组：">
                    <el-select v-model="formInline.execGroup" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in maintainExecGroup" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否停机：" class="">
                    <el-checkbox v-model="formInline.machineStop" class="width_180 text_align_left"></el-checkbox>
                </el-form-item>
                <el-form-item label="停机时间：" class=" " v-if="formInline.machineStop">
                    <el-input v-model="formInline.stopLen" type="number" placeholder="" class="width_180">
                    </el-input>
                </el-form-item>
                <el-form-item label="保养人：" class=" ">
                    <el-input v-model="formInline.execPerson" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execPerson" placeholder="请选择" class="width_180" multiple collapse-tags disabled>
                        <el-option v-for="(item,index) in maintenancePeople" :key="index" :label="item.userName"
                            :value="item.userName">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="派单时间：" class=" ">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.dispatchTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="备注：" class=" ">
                    <el-input v-model="formInline.remarks" placeholder="" class="width_180">
                    </el-input>
                </el-form-item> -->

                <el-form-item label="指定接单时间：" class=" ">
                    <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.assignTakeTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否接单逾期：" class="">
                    <el-checkbox v-model="formInline.isTakeOverdue" class="width_180 text_align_left" disabled>
                    </el-checkbox>
                </el-form-item>

                <el-form-item label="接单时间：" class=" ">
                    <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.takeTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="保养开始时间：" class=" ">
                    <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.startTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="保养结束时间：" class=" ">
                    <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.endTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否逾期保养结束：" class="">
                    <el-checkbox v-model="formInline.isEndOverdue" class="width_180 text_align_left" disabled>
                    </el-checkbox>
                </el-form-item>
                <el-form-item label="指定保养结单日期：" class=" ">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.assignEndTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证时间：" class=" ">
                    <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.verifyTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证备注：" class=" ">
                    <el-input v-model="formInline.verifyRemarks" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="验证不通过保养开始时间：" class=" ">
                    <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.execRejectStartTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="验证不通过保养结束时间：" class=" ">
                    <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.execRejectEndTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="保养时长：" class=" ">
                    <el-input v-model="formInline.execLenM" type="number" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="保养花费：" class=" ">
                    <el-input v-model="formInline.execFee" type="number" placeholder="" class="width_180">
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="现场情况：" class=" ">
                    <el-input v-model="formInline.spotCondition" placeholder="" class="width_180">
                    </el-input>
                </el-form-item> -->
                <!-- <el-form-item label="保养描述：" class=" ">
                    <el-input v-model="formInline.execDesc" placeholder="" class="width_180">
                    </el-input>
                </el-form-item> -->
                <el-form-item label="评分等级：" class=" ">
                    <el-input v-model="formInline.execGrade_name" type="number" placeholder="" class="width_180" disabled>
                    </el-input>
                    <!-- <el-select v-model="formInline.execGrade" placeholder="请选择" class="width_180" disabled>
                        <el-option v-for="item in maintenanceLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="是否为经验性记录：" class="">
                    <el-checkbox v-model="formInline.isEmpirical" class="width_180 text_align_left" disabled>
                    </el-checkbox>
                </el-form-item>
                <!-- <el-form-item label="用户编号：" class=" ">
                    <el-input v-model="formInline.userID" placeholder="" class="width_180">
                    </el-input>
                </el-form-item> -->
                <!-- <el-form-item label="项目编号：" class=" ">
                    <el-input v-model="formInline.projectID" type="number" placeholder="" class="width_180"
                        :readonly="true">
                    </el-input>
                </el-form-item> -->
                <el-form-item label="是否审核：" class="">
                    <el-checkbox v-model="formInline.isAuth" class="width_180 text_align_left" disabled></el-checkbox>
                </el-form-item>
                <el-form-item label="是否委外：" class="">
                    <el-checkbox v-model="formInline.isOutEntrust" class="width_180 text_align_left"></el-checkbox>
                </el-form-item>


                <!-- <el-form-item label="保养班组：">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保养状态：" class=" ">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保养级别：" class=" ">
                    <el-select v-model="formInline.region" placeholder="活动区域" class="width_180">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :inline="true" class="flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="开始时间：" class="">
                    <el-date-picker prefix-icon="el-icon-date" v-model="value1" type="datetime"
                        format="yyyy-MM-dd HH:mm" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间：" class=" ">
                    <el-date-picker prefix-icon="el-icon-date" v-model="value1" type="datetime"
                        format="yyyy-MM-dd HH:mm" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="维修用时：" class="">
                    <el-input v-model="formInline.user" placeholder="" class="width_90">
                        <template slot="append">小时</template>
                    </el-input>
                    <el-input v-model="formInline.user" placeholder="" class="width_90">
                        <template slot="append">分</template>
                    </el-input>
                </el-form-item> -->
                <!-- <el-form-item label="维修费用：" class=" ">
                    <el-input v-model="formInline.user" placeholder="审批人" class="width_180"></el-input>
                </el-form-item> -->
            </el-form>

            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%;" class="mg_bottom_20">
                <el-form-item label="备注：" prop="">
                    <el-input type="textarea" :rows="3" v-model="formInline.remarks" resize="none"></el-input>
                </el-form-item>
                <el-form-item label="现场情况：" class=" ">
                    <el-input type="textarea" :rows="3" v-model="formInline.spotCondition" placeholder="">
                    </el-input>
                </el-form-item>
                <el-form-item label="保养描述：" class=" ">
                    <el-input type="textarea" :rows="3" v-model="formInline.execDesc" placeholder="">
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- <el-form :inline="true" class="flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="是否停机：" class="">
                    <el-checkbox v-model="value1" class="width_180 text_align_left"></el-checkbox>
                </el-form-item>
                <el-form-item label="停机时长：" class=" ">
                    <el-input v-model="formInline.user" placeholder="" class="width_180">
                        <i slot="suffix" style=" color: #606266;" class="fn_size14">min
                    </i>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%;" class="mg_bottom_20">
                <el-form-item label="工作描述：" prop="user">
                    <el-input type="textarea" :rows="5" v-model="formInline.user" resize="none"></el-input>
                </el-form-item>
            </el-form> -->


            <!-- <div class="box_head1 width_100">保养工作量</div>
            <div class="flex flex_column width_100 pdl30 pdr30">
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="width_140 mg_top_20"
                    style=" background: #03CABE;border: none;">添加人员</el-button>
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" max-height="250">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="date" label="姓名" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="保养开始时间" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="保养完成时间" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="保养用时" width="">
                    </el-table-column>
                    <el-table-column prop="name" label="内容或步骤" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div> -->

            <div class="box_head1 width_100">更换备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30 height_370">
                <div class="flex align_center mg_top_20">
                    <!-- <el-select v-model="value" placeholder="请选择仓库" class="width_150">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
                    <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;"
                        @click="selectSpare">
                        选择备件</el-button>
                    <!-- <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;">
                        申购备件</el-button> -->
                </div>
                <el-table :data="sparePartList" style="width: 100%" class="mg_top_20" height="100%">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="unit" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="applyQuan" label="领用数量" width="">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价" width="">
                    </el-table-column>
                    <el-table-column prop="totalPrice" label="总价" width="">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="editTable(scope.row)">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delTable(scope.row.ID)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex mg_top_20 mg_bottom_20">
                <div>
                    <el-button type="primary" class="width_140" @click="submit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>

            <el-dialog :visible.sync="dialogTableVisible" top='10vh' width="40%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">设备选择</div>
                <!-- pdl30 pdr30 -->
                <div class="flex flex_column height_370">
                    <div class="flex">
                        <el-cascader size="medium" class="el_cascader_width" v-model="organizationValue" placeholder="请选择" :options="organization"
                            ref="demoCascader" :props="defaultProps"  @change="handleChangeCascader"
                            :show-all-levels="false" collapse-tags></el-cascader>
                        <el-button type="primary" size="medium" class="mg_left_10" @click="queryDevice">查询</el-button>
                    </div>
                    <el-table :data="equInfoList" style="width: 100%" class="mg_top_20" height="100%">
                        <el-table-column type="index" label="序号" width="80">
                        </el-table-column>
                        <el-table-column prop="equipmentID" label="设备编号" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentName" label="设备名称" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentModel" label="规格型号" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentType" label="设备类型" width="">
                        </el-table-column>
                        <el-table-column prop="Department" label="使用部门" width="">
                        </el-table-column>
                        <el-table-column prop="InstallationPosition" label="设备位置" width="">
                        </el-table-column>
                        <el-table-column label="操作" width="">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="choiceEquipment(scope.row)">选择</span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div>
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </el-dialog>


            <el-dialog title="选择备件" :visible.sync="dialogTableVisibleSpare" top='15vh' width="60%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增备件</div> -->
                <!-- pdl30 pdr30  height_370 -->
                <div width="60%" style="margin-top:10px">
                    
                    <div class="flex align_center mg_left_50 fn_size14">
                        <div class="">备件型号：</div>
                         <el-input v-model="sparePartModel" class="width_150" clearable></el-input>
                        <!-- <el-select v-model="sparePartModel" placeholder="全部" class="width_150" clearable>
                            <el-option v-for="item in sparePartModelArray" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select> -->
                        <div class="mg_left_20">备件名称：</div>
                        <el-input v-model="sparePartName" class="width_150" clearable></el-input>
                        <el-button type="primary" size="medium" class="mg_left_10" @click="onQueryLimit">查询
                        </el-button>
                    </div>
                </div>
                <div class="flex flex_column ">
                    <div class="flex flex_column mg_top_15 height_444">
                        <el-form :model="formData" ref="formData" class=" height_340">
                            <el-table :data="formData.tableData" border stripe style="width: 100%;" height="100%"
                                @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column type="index" label="序号" width="70">
                                </el-table-column>
                                <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="referPrice" label="单价" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="applyQuan" label="领用数量" width="">
                                    <template slot-scope="scope">
                                        <el-form-item :prop="'tableData.' + scope.$index + '.applyQuan'"
                                            style="margin-bottom: 0px;">
                                            <el-input v-model="scope.row.applyQuan" placeholder="请输入"
                                                @input="changeInput(scope.$index)" type="number" oninput="if(value<0){value=0}"></el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="totalPrice" label="总价" width="">
                                    <template slot-scope="scope">
                                        <el-form-item :prop="'tableData.' + scope.$index + '.totalPrice'"
                                            style="margin-bottom: 0px;">
                                            <el-input v-model="scope.row.totalPrice" placeholder="请输入" readonly>
                                            </el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="Remarks" label="备注" width="">
                                    <template slot-scope="scope">
                                        <el-form-item :prop="'tableData.' + scope.$index + '.Remarks'"
                                            style="margin-bottom: 0px;">
                                            <el-input v-model="scope.row.Remarks" placeholder="请输入"></el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form>
                        <el-pagination layout="prev, pager, next" :total="pageQuantitySapre*pageSize"
                            :page-size="pageSize" :current-page.sync="currentPageSpare"
                            @current-change="handleCurrentChangeSpare">
                        </el-pagination>
                        <div class="flex justify_center pdb_10">
                            <el-button @click="dialogTableVisibleSpare = false">取 消</el-button>
                            <el-button type="primary" @click="confirmChoices">确 定</el-button>
                        </div>
                    </div>
                    <!-- <div class="flex justify_center  mg_top_20">
                        <el-form :model="formSpare">
                            <el-form-item label="备件编号：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartID" autocomplete="off" class=""></el-input>
                            </el-form-item>
                            <el-form-item label="备件名称：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartName" autocomplete="off" class=""></el-input>
                            </el-form-item>
                            <el-form-item label="规格型号：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartModel" autocomplete="off" class=""></el-input>
                            </el-form-item>
                            <el-form-item label="计量单位：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.unit" autocomplete="off" class=""></el-input>
                            </el-form-item>
                            <el-form-item label="领用数量：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.applyQuan" type="number" autocomplete="off" class="">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="单价：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.unitPrice" type="number" autocomplete="off" class="">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="总价：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.totalPrice" type="number" autocomplete="off" class="">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="备注：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.remarks" autocomplete="off" class=""></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="flex justify_center pdb_20 ">
                        <el-button @click="dialogTableVisibleSpare = false">取 消</el-button>
                        <el-button type="primary" @click="confirmChoices">确 定</el-button>
                    </div> -->
                </div>
                <!-- <div>
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div> -->
            </el-dialog>

            <el-dialog title="编辑备件" :visible.sync="dialogTableVisibleEditSpare" top='10vh' width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑备件</div> -->
                <!-- pdl30 pdr30  height_370 -->
                <div class="flex flex_column ">
                    <div class="flex justify_center mg_top_20">
                        <el-form :model="formSpare">
                            <el-form-item label="备件编号：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartID" autocomplete="off" class="" disabled>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="备件名称：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartName" autocomplete="off" class=""
                                    disabled></el-input>
                            </el-form-item>
                            <el-form-item label="规格型号：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.sparePartModel" autocomplete="off" class=""
                                    disabled></el-input>
                            </el-form-item>
                            <el-form-item label="计量单位：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.unit" autocomplete="off" class="" disabled>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="单价：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.unitPrice" type="number" autocomplete="off" class=""
                                    disabled>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="领用数量：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.applyQuan" type="number" autocomplete="off" class=""
                                    @input="changeInputEdit">
                                </el-input>
                            </el-form-item>

                            <el-form-item label="总价：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.totalPrice" type="number" autocomplete="off" class=""
                                    disabled>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="备注：" :label-width="formLabelWidth">
                                <el-input v-model="formSpare.remarks" autocomplete="off" class=""></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="flex justify_center pdb_20 ">
                        <el-button @click="dialogTableVisibleEditSpare = false">取 消</el-button>
                        <el-button type="primary" @click="confirmChoicesEdit">确 定</el-button>
                    </div>
                </div>
            </el-dialog>

        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        equipmentLedgerOrganizationSelect,
        equipmentLedgerQueryLimit,
    } from "@/api/api_systemConfig";
    import {
        getMaintainExecGroup,
        getMaintainCategory,
        maintainOrderStatusQuery,
        maintainOrderExecGradeQuery,
        getMaintainUrgenLevel,
        maintainOrderQuery,
        maintainOrderExecLevelQuery,
        maintainOrderUpdate,
        maintainExecPersonQuery,


        maintainOrderSparePartQuery,
        maintainOrderSparePartDelete,
        maintainOrderSparePartUpdate,
        maintainOrderSparePartInsert,

        sparePartStockQueryLimit,
         sparePartTypeQuery,
    } from "@/api/api_deviceManagement";
    // 
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                sparePartName: '',
                sparePartModel:'',
                sparePartModel:'',
                value1: '',
                formInline: {},
                value: '',
                options: [],
                formLabelWidth: '110px',
                tableData: [],
                dialogTableVisible: false,
                dialogTableVisibleSpare: false,
                dialogTableVisibleEditSpare: false,
                organizationValue: [],
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                checkData: {},
                equInfoList: [],
                currentEqu: [],
                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0,
                permission: {},
                userID: '',
                userName: '',
                maintainExecGroup: [],
                maintainUrgenLevel: [],
                planStatusAr: [],
                maintainCategory: [],
                loopUnitQuery: [],
                workOrderStatus: [],
                maintenanceLevel: [],
                maintainLevel: [],
                formSpare: {},
                detailID: '',
                sparePartList: [],
                maintenancePeople: [],

                formData: {
                    tableData: []
                },
                currentPageSpare: 1,
                pageQuantitySapre: 0,

                currentSpareData:[],
            }
        },
        mounted() {
            // this.$route
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.maintainOrderQuery();
            }
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
                this.userName = userInfo.userName;
            }
            this.getMaintainExecGroup();
            this.getMaintainUrgenLevel();
            this.getMaintainCategory();
            this.maintainOrderStatusQuery();
            this.maintainOrderExecGradeQuery();
            this.maintainOrderExecLevelQuery();
            // this.maintainExecPersonQuery();//人员
            this.sparePartTypeQuery();//备件类型
        },
        methods: {
             // 备件类型下拉选
            async sparePartTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await sparePartTypeQuery(param);
                this.sparePartModelArray = res.data;
            },
             onQueryLimit() {
                this.currentPage = 1;
                this.sparePartStockQueryLimit();
               
            },
            // 工单状态
            async maintainOrderStatusQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderStatusQuery(param);
                this.workOrderStatus = selectRes.data
            },
            // 保养等级
            async maintainOrderExecGradeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderExecGradeQuery(param);
                this.maintenanceLevel = selectRes.data
            },
            // 保养组
            async getMaintainExecGroup() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainExecGroup(param);
                this.maintainExecGroup = selectRes.data;
            },
            // 紧急程度
            async getMaintainUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainUrgenLevel(param);
                this.maintainUrgenLevel = selectRes.data;
            },
            // 保养等级
            async maintainOrderExecLevelQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderExecLevelQuery(param);
                this.maintainLevel = selectRes.data
            },
            // 保养类型
            async getMaintainCategory() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainCategory(param);
                this.maintainCategory = selectRes.data
            },

            async maintainOrderQuery() {
                var param = {
                    ID: this.detailID
                }
                const selectRes = await maintainOrderQuery(param);
                this.formInline = selectRes.rows[0];
                // if(this.formInline.execPerson) {
                // this.formInline.execPerson = this.formInline.execPerson.split(',')

                // }
                this.maintainOrderSparePartQuery();
            },
            // 备件
            async maintainOrderSparePartQuery() {
                var param = {
                    orderID: this.formInline.orderID
                }
                const selectRes = await maintainOrderSparePartQuery(param);
                this.sparePartList = selectRes.rows;
            },
            // 保养人员
            async maintainExecPersonQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainExecPersonQuery(param);
                this.maintenancePeople = selectRes.data
            },
            async submit() {
                // this.$router.go(-1);
                // this.formInline.userID = this.userID;
                // this.tableData
                // this.formInline.SparePartJSON.array = this.tableData;
                // this.formInline.SparePartJSON = {
                //     array: this.tableData
                // }
                // this.formInline.execPerson = this.formInline.execPerson.join(',')
                this.formInline.urgenLevel =this.formInline.urgenLevel_name
                this.formInline.execLevel =this.formInline.execLevel_name
                console.log(this.formInline)
                if(!this.formInline.machineStop) {
                    delete this.formInline.stopLen
                }
                const selectRes = await maintainOrderUpdate(this.formInline);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                    this.formInline = {}
                } else {
                    this.$message.error(selectRes.message);
                }


            },
            colsePage() {
                this.$router.go(-1);
            },
            changeDevice() {
                this.equipmentLedgerOrganizationSelect();
                this.dialogTableVisible = true;
            },
            async equipmentLedgerOrganizationSelect() {
                var param = {
                    permission: this.permission
                };
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organization = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];
                this.equipmentLedgerQuery();
            },
            queryDevice() {
                console.log(this.organizationValue)
                if (this.organizationValue.join(',') == '') {
                    this.checkData = this.standardArr[0];
                }
                // console.log(this.checkData)
                this.equipmentLedgerQuery();
            },
            async equipmentLedgerQuery() {
                var param = {
                    organization: this.checkData,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.equInfoList = selectRes.data;
                this.pageQuantity = selectRes.pageQuantity;
            },
            handleCurrentChange(val) {
                this.equipmentLedgerQuery(); //查询table数据的方法
            },
            choiceEquipment(row) {
                this.$confirm('确认选择此设备', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(row);
                    this.formInline.deviceID = row.equipmentID;
                    this.formInline.deviceName = row.EquipmentName;
                    this.formInline.deviceType = row.EquipmentType;
                    this.formInline.deviceModel = row.EquipmentModel;
                    this.formInline.deviceDepartment = row.Department;
                    this.formInline.deviceLocation = row.InstallationPosition;
                    // this.formInline
                    // this.currentEqu = [];
                    this.dialogTableVisible = false;
                    // this.currentEqu.push(row);
                    // console.log(this.currentEqu)
                }).catch(() => {});
            },
            handleChangeCascader() {
                // console.log(this.organizationValue)
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)

            },
            delTable(id) {
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(id)
                    // this.tableData.splice(row,1)
                    // console.log(this.tableData)
                    this.maintainOrderSparePartDelete(id);
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            async maintainOrderSparePartDelete(id) {
                var param = {
                    ID: id
                };
                const selectRes = await maintainOrderSparePartDelete(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.maintainOrderSparePartQuery();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            editTable(row) {
                this.formSpare = {};
                this.dialogTableVisibleEditSpare = true;
                this.formSpare = row;
            },
            async confirmChoicesEdit() {
                // maintainOrderSparePartUpdate
                this.formSpare.u
                const selectRes = await maintainOrderSparePartUpdate(this.formSpare);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleEditSpare = false;
                    this.formSpare = {};
                    this.maintainOrderSparePartQuery();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // 修改备件
            changeInputEdit(val) {
                this.formSpare.totalPrice = this.formSpare.unitPrice * this.formSpare.applyQuan
            },
            // 备件--开始
            // 选择备件
            selectSpare() {
                this.formSpare = {};
                this.sparePartStockQueryLimit();
                this.dialogTableVisibleSpare = true;
            },
            // 备件台账-筛选分页数据
            async sparePartStockQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageNumber: this.currentPageSpare,
                    pageSize: this.pageSize,
                    equipmentID:this.formInline.deviceID
                };
                 if (this.sparePartModel != '') {
                    param.sparePartModel = this.sparePartModel
                }
                if (this.sparePartName != '') {
                    param.sparePartName = this.sparePartName
                }
                const selectRes = await sparePartStockQueryLimit(param);
                // this.spareList = selectRes.rows;
                this.formData.tableData = selectRes.rows;
                this.pageQuantitySapre = selectRes.pageQuantity
            },
            handleCurrentChangeSpare() {
                this.sparePartStockQueryLimit()
            },
            handleSelectionChange(val) {
                // console.log(val);
                this.currentSpareData = val;
            },
            changeInput(index) {
                // console.log(this.formData)
                // console.log(this.formData.tableData[index])
                this.formData.tableData[index].totalPrice = this.formData.tableData[index].referPrice * this.formData
                    .tableData[index].applyQuan;
            },
            async confirmChoices() {
                var currentData = [];
                var price = 0;
                 if (this.currentSpareData.length == 0) {
                    this.$message({
                        message: '警告! 请选择备件',
                        type: 'warning'
                    });
                    return
                }
                for (var i = 0; i < this.currentSpareData.length; i++) {
                    if (!this.currentSpareData[i].applyQuan) {
                        this.$message({
                            message: '警告哦，“' + this.currentSpareData[i].sparePartName + '”请填写领用数量',
                            type: 'warning'
                        });
                        return
                    }
                    var testData = this.sparePartList.filter(itmer => itmer.sparePartID == this.currentSpareData[i]
                        .sparePartID);
                    console.log(testData)
                    if (testData.length != 0) {
                        var sparesName = []
                        for (var j = 0; j < testData.length; j++) {
                            sparesName.push(testData[i].sparePartName)
                        }
                        this.$message({
                            message: '警告!“' + sparesName.join('、') + '”已添加',
                            type: 'warning'
                        });
                        return
                    }
                    var current = {
                        sparePartID: this.currentSpareData[i].sparePartID,
                        sparePartName: this.currentSpareData[i].sparePartName,
                        sparePartModel: this.currentSpareData[i].sparePartModel,
                        unit: this.currentSpareData[i].unit,
                        unitPrice: this.currentSpareData[i].referPrice,
                        applyQuan: this.currentSpareData[i].applyQuan,
                        totalPrice: this.currentSpareData[i].totalPrice,
                        remarks: this.currentSpareData[i].Remarks,
                    }
                    currentData.push(current);
                    price += Number(this.currentSpareData[i].totalPrice)
                }
                // currentData.push(this.formSpare)
                // this.tableData.push(this.formSpare)
                // this.dialogTableVisibleSpare = false;
                // this.formSpare = {}
                var param = {
                    userName: this.userName,
                    orderID: this.formInline.orderID,
                    SparePartJSON: {
                        array: currentData
                    },
                    totalPrice: price
                }
                console.log(param)
                const selectRes = await maintainOrderSparePartInsert(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleSpare = false;
                    this.sparesName = [];
                    this.formData = {
                        tableData: []
                    }
                    this.maintainOrderSparePartQuery();
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            // 选择备件--结束
        }

    }
</script>
<style scoped>
    /* .details_box {
        width: 70%;
    }

    .details_box .el-form {
        width: 100%;
    } */

    /* .textarea .el-form-item__content {
        width: 84%
    } */
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }

    ::v-deep .el-form-item__label {
        line-height: 24px;
    }

</style>