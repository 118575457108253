// 设备报警配置
<template>
    <div class="page_container height_100" v-loading="loading">
        <!--         <div class="bgc_E2F0FE border_1_CCDBE5 flex justify_between height_40">
            <div class="pdl20">设备报警配置</div>
        </div> -->
        <div class="box_head1">
            <span>设备报警配置</span>
            <div class="flex align_center mg_left_50 fn_size14">
                <div>设备选择：</div>
                <el-cascader size="medium" class="mg_right_10 el_cascader_width" ref="demoCascader"
                    v-model="organizationValue" placeholder="设备选择" :options="organizationAr" :props="defaultProps"
                    @change="orgChange" :show-all-levels="false" collapse-tags clearable filterable>
                </el-cascader>
                <div class="mg_left_10">设备类型选择：</div>
                <el-select v-model="eqcValue" placeholder="设备类型" class="mg_left_10 width_150" clearable>
                    <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <div class="mg_left_20">设备等级选择：</div>
                <el-select v-model="eqlValue" placeholder="设备等级" class="mg_left_10 width_150" clearable>
                    <el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <el-button type="primary" class="mg_left_10" size="medium" @click="queryCheck">查询</el-button>
            </div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30 faultCodeAnalysisBody pdt_20 " style="height:95%">
            <div class="flex justify_end align_center fn_size14">
                <el-button type="primary" class="width_120" style=" background: #03CABE;border: none;" icon="el-icon-circle-plus-outline" @click="addData" >新加
                </el-button>
                <!-- <el-button type="primary" class="width_120" style=" background: #03CABE;border: none;"
                    icon="el-icon-circle-plus-outline" @click="addData" :disabled="this.disabled===true">新加
                </el-button> -->
                <!-- <el-button type="primary" class="width_120" style=" background: #FEA313;border: none;"
                        icon="el-icon-edit" @click="edit">编辑
                    </el-button> -->
                <!-- <el-button type="primary" class="width_120" style=" background: #F75753;border: none;"
                        icon="el-icon-delete" @click="delete">批量删除
                    </el-button> -->
            </div>
            <!--表格-->
            <el-row :gutter="20" class="mg_top_15" style="height:85%">
                <el-col :span="4" class="height_100">
                    <el-table ref="alarmListTable" :data="alarmList" tooltip-effect="dark" style="width:100%;" height="100%" @current-change="handleSelect" highlight-current-row>
                        <el-table-column type="index" label="序号" width=""></el-table-column>
                        <el-table-column prop="name" label="名称" width=""></el-table-column>
                        <el-table-column prop="id" label="设备编码" width="" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="7" class="height_100">
                    <el-table ref="alarmList1TableRef" :data="alarmList1" tooltip-effect="dark" style="width:100%;" height="100%" @current-change="handleSelect2" highlight-current-row>
                        <el-table-column type="index" label="序号" width=""></el-table-column>
                        <el-table-column prop="showName" label="属性名称" width=""></el-table-column>
                        <el-table-column prop="type" label="属性类型" width=""></el-table-column>
                        <el-table-column prop="desc" label="属性描述" width="" show-overflow-tooltip></el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="13" class="height_100">
                    <el-table ref="multipleTable" :data="alarmList2" tooltip-effect="dark" style="width:100%;" height="100%" highlight-current-row>
                        <el-table-column type="index" label="序号" width=""></el-table-column>
                        <el-table-column prop="alertTypeName" label="报警类型" width=""></el-table-column>
                        <el-table-column prop="alertLevel" label="报警等级" width=""></el-table-column>
                        <el-table-column prop="alertName" label="报警名字" width=""></el-table-column>
                        <el-table-column prop="alertDesc" label="报警描述" show-overflow-tooltip width=""></el-table-column>
                        <el-table-column prop="alertLimit" label="报警限值" width=""></el-table-column>

                        <el-table-column prop="alertEnabled" align="center" label="是否启用">
                            <template slot-scope="scope">
                                <el-switch active-color="#13ce66" inactive-color="#e0e0e0" v-model="scope.row.alertEnabled" @click="switchClick(scope.row)" @change="switchChange(scope.row)" :disabled="scope.row.switchDis">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="140">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD" @click="detail(scope.row)">
                                    <span style="border-bottom: 1px solid #009BFD;">详情</span>
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="140">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD" @click="edit(scope.row)">
                                    <span style="border-bottom: 1px solid #009BFD;">修改</span>
                                </el-button>
                                <el-button type="text" class="color_009BFD" @click="del(scope.row)">
                                    <span style="border-bottom: 1px solid #009BFD;">删除</span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>

            <!--分页-->
            <div class="pagesize">
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize" :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>

            <!--新增-->
            <el-dialog title="新增报警基本信息" :visible.sync="dialogTableVisibleAdd" top='10vh' width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增报警基本信息</div> -->
                <div class="flex justify_between pdl30 pdr30">
                    <el-form ref="alertRef" :inline="true" :model="formInline" label-width="180px" :rules="alertRules" class="mg_top_30 flex flex_column align_center">
                        <el-form-item label="报警名称：" prop="alertName">
                            <el-input v-model="formInline.alertName" placeholder="请输入报警名称" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="报警类型：" prop="alertType">
                            <el-select v-model="formInline.alertType" placeholder="请选择报警类型" class="width_180">
                                <el-option v-for="item in alertTypeData" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报警等级：">
                            <el-select v-model="formInline.alertLevel" placeholder="请选择报警等级" class="width_180">
                                <el-option v-for="(item,index) in alarmLevelAr" :key="index" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            <!-- <el-input v-model="formInline.alertLevel" placeholder="请输入报警等级" class="width_180">
                            </el-input> -->
                        </el-form-item>
                        <el-form-item label="是否启用：">
                            <el-radio-group v-model="formInline.alertEnabled" class="width_180 text_align_left">
                                <el-radio :label=true>启用</el-radio>
                                <el-radio :label=false>禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="报警限值：" prop="alertLimit">
                            <el-input v-model="formInline.alertLimit" placeholder="请输入报警限值" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="报警类别：" prop="alarmType">
                            <el-select v-model="formInline.alarmType" placeholder="请选择报警类别" class="width_180">
                                <el-option v-for="(item,index) in alarmTypeAr" :key="index" :label="item.name" :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报警备注：">
                            <el-input type="textarea" rows="3" v-model="formInline.alertDesc" placeholder="请输入报警备注" class="width_180"></el-input>
                        </el-form-item>
                    </el-form>
                </div>

                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button @click="dialogTableVisibleAdd = false">取 消</el-button>
                    <el-button type="primary" @click="addConfirm">确定</el-button>
                </div>

            </el-dialog>

            <!-- 修改 -->
            <el-dialog title="修改报警基本信息" :visible.sync="dialogTableVisibleEdit" top='10vh' width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">修改报警基本信息</div> -->
                <div class="flex justify_between pdl30 pdr30">
                    <el-form :inline="true" :model="formInline" label-width="180px" class="mg_top_30 flex flex_column align_center">
                        <el-form-item label="报警名称：">
                            <el-input v-model="formInline.alertName" placeholder="请输入报警名称" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="报警类型：">
                            <el-select v-model="formInline.alertType" placeholder="设备类型" class="width_180" disabled>
                                <el-option v-for="item in alertTypeData" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报警等级：">
                            <!-- <el-input v-model="formInline.alertLevel" placeholder="请输入报警等级" class="width_180">
                            </el-input> -->
                            <el-select v-model="formInline.alertLevel" placeholder="请选择报警等级" class="width_180">
                                <el-option v-for="(item,index) in alarmLevelAr" :key="index" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <!-- <el-form-item label="是否启用：">
                            <el-radio-group v-model="formInline.alertEnabled">
                                <el-radio :label=true>启用</el-radio>
                                <el-radio :label=false>禁用</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
                        <el-form-item label="报警限值：">
                            <el-input v-model="formInline.alertLimit" placeholder="请输入报警限值" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="报警类别：" prop="alarmType">
                            <el-select v-model="formInline.alarmType" placeholder="请选择报警类别" class="width_180">
                                <el-option v-for="(item,index) in alarmTypeAr" :key="index" :label="item.name" :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报警备注：">
                            <el-input type="textarea" rows="3" v-model="formInline.alertDesc" placeholder="请输入报警备注" class="width_180"></el-input>
                        </el-form-item>
                    </el-form>
                </div>

                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="editConfirm">确定</el-button>
                </div>
                <!-- 详情 -->
            </el-dialog>
            <el-dialog title="报警基本信息" :visible.sync="dialogTableVisibleDetail" top='10vh' width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold"></div> -->
                <div class="flex justify_between pdl30 pdr30">
                    <el-form :inline="true" :model="formInline" label-width="180px" disabled class="mg_top_30 flex flex_column align_center">
                        <el-form-item label="报警类型：">
                            <el-select v-model="formInline.alertType" placeholder="设备类型" class="width_180" clearable>
                                <el-option v-for="item in alertTypeData" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报警名称：">
                            <el-input v-model="formInline.alertName" placeholder="请输入报警名称" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="报警等级：">
                            <!-- <el-input v-model="formInline.alertLevel" placeholder="请输入报警等级" class="width_180" disabled>
                            </el-input> -->
                            <el-select v-model="formInline.alertLevel" placeholder="请选择报警等级" class="width_180" disabled>
                                <el-option v-for="(item,index) in alarmLevelAr" :key="index" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否启用：">
                            <el-radio-group v-model="formInline.alertEnabled" class="width_180 text_align_left">
                                <el-radio :label=true>启用</el-radio>
                                <el-radio :label=false>禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="报警限值：">
                            <el-input v-model="formInline.alertLimit" placeholder="请输入报警限值" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="报警备注：">
                            <el-input type="textarea" rows="3" v-model="formInline.alertDesc" placeholder="请输入报警备注" resize="none" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>

                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button type="primary" @click="confirm">确定</el-button>
                </div>

            </el-dialog>
        </div>
    </div>
</template>
<script>
    import {
        alarmConfigOrganizationSelect,
        alarmConfigEquipmentTypeSelect,
        alarmConfigEquipmentLevelSelect,
        alarmConfigGetEquipmentListByCategoryAndLevel,
        alarmConfigGetPropertyDefinitionByEuipmentID,
        alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName,
        alarmConfigAddOrUpdateAlerts,
        alarmConfigAlertTypeSelect,
        alarmConfigDeleteOrDisabledAlert,
        alarmConfigEnableOrDisableAlert,
        equipmentAlarmLevelSelect,
        equipmentAlarmTypeSelect
    } from "@/api/api_systemConfig";
    export default {
        data() {
            var validateAlertName = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入报警名称'));
                } else {
                    // console.log(String(Number(value[0])))
                    if (String(Number(value[0])) != 'NaN') {
                        callback(new Error('报警名称不能以数字开头'));
                    }
                    callback();
                }
            };
            return {
                //页码
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,

                //页面显现
                dialogTableVisibleAdd: false,
                dialogTableVisibleDetail: false,
                dialogTableVisibleEdit: false,

                //组织下拉框
                organizationAr: [],
                opData: [],
                orgSelect: [],
                checkData: [],
                permission: {},

                //下拉框
                typeList: [],
                levelList: [],

                alarmList: [],
                alarmList1: [],
                alarmList2: [],
                formInline: {
                    alertEnabled: true
                },
                detailFormInline: {},
                editFormInline: {},
                //table绑定值
                switchValue: true,

                eqcValue: null,
                eqlValue: null,
                rowData: {},
                tab2RowData: {},
                disabled: true,
                loading: true,
                defaultProps: {
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true, //多选
                    emitPath: false, //只返回选中节点的值
                    // checkStrictly: true //多选任一级
                },
                organizationValue: [],
                //是否启用
                radio: '1',
                currentRow: '',
                alertTypeData: [],
                multipleSelection: '',
                typeValue: "",
                switchDis: false,

                alarmLevelAr: [],
                alarmTypeAr: [],
                alertRules: {
                    alertName: [{
                        validator: validateAlertName,
                        trigger: 'blur'
                    }],
                    alertType: [{
                        required: true,
                        message: '请选择报警类型',
                        trigger: 'change'
                    }],
                    alertLimit: [{
                        required: true,
                        message: '请输入报警限值',
                        trigger: 'blur'
                    }],
                    alarmType: [{
                        required: true,
                        message: '请选择报警类别',
                        trigger: 'change'
                    }]

                }
            };
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            // this.alarmConfigGetEquipmentListByCategoryAndLevel()
            this.alarmConfigOrganizationSelect()
            this.alarmConfigEquipmentTypeSelect()
            this.alarmConfigEquipmentLevelSelect()
            this.alarmConfigAlertTypeSelect()
            // this.alarmConfigDeleteOrDisabledAlert()
            // this.alarmConfigGetPropertyDefinitionByEuipmentID()
            // this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName()
            this.equipmentAlarmLevelSelect();
            this.equipmentAlarmTypeSelect();
        },
        methods: {
            // 报警等级下拉框接口
            async equipmentAlarmLevelSelect() {
                const selectRes = await equipmentAlarmLevelSelect();
                this.alarmLevelAr = selectRes.data.alarmLevel
            },
            // 报警类别下拉框
            async equipmentAlarmTypeSelect() {
                const selectRes = await equipmentAlarmTypeSelect();
                this.alarmTypeAr = selectRes.data.alarmType;
            },
            //loading 
            getData() {
                const loading = this.$loading({
                    lock: true, //lock的修改符--默认是false
                    text: 'Loading', //显示在加载图标下方的加载文案
                    spinner: 'el-icon-loading', //自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
                    target: document.querySelector('#table') //loadin覆盖的dom元素节点
                });
                loading.close() //成功回调函数停止加载
            },
            alarmChange() {

            },
            alarmChange1() {

            },
            alarmChange2(val) {
                // console.log(val)
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.handleNum = "";
                for (var i = 0; i < val.length; i++) {
                    this.handleNum += val[i].EquipmentID + ",";
                }
                //去掉最后一个逗号
                if (this.handleNum.length > 0) {
                    this.handleNum = this.handleNum.substr(0, this.handleNum.length - 1);
                }
                console.log("checkbox", this.deleteNum);
            },

            addData() {
                this.formInline = {}
                // this.formInline.alertName = '';
                this.$set(this.formInline, 'alertName', '')
                this.dialogTableVisibleAdd = true;
            },
            detail(row) {
                console.log('rowdetail', row);
                this.formInline = row;
                this.dialogTableVisibleDetail = true;
                // console.log("form",this.detailFormInline);
            },
            edit(row) {
                // console.log(row);
                this.formInline = row;
                if (row.alertDesc.indexOf(',') > -1) {
                    let endIndex = row.alertDesc.indexOf(',')
                    this.$set(this.formInline, 'alarmType', row.alertDesc.slice(0, endIndex))
                    this.$set(this.formInline, 'alertDesc', row.alertDesc.slice(endIndex + 1))
                }
                // console.log(row.alertDesc.indexOf(','))


                this.dialogTableVisibleEdit = true;
                console.log("editRow", this.formInline);
            },
            //switch开关
            switchChange(row) {
                console.log('changeClick', row.alertEnabled);
                // if (row.alertEnabled == true) {
                //     row.switchDis = false
                // }
                // if (row.alertEnabled == false) {
                //     row.switchDis = true
                // }
                if (row.alertEnabled == true) this.typeValue = '2'
                if (row.alertEnabled == false) this.typeValue = '1'
                this.alarmConfigEnableOrDisableAlert(row)
            },
            switchClick(row) {
                console.log('click', row.alertEnabled);
                // this.alarmConfigDeleteOrDisabledAlert(row)
            },
            //表一点击每行
            handleSelect(row) {
                console.log('step1', row);
                this.alarmList1 = [];
                this.alarmList2 = [];
                this.disabled = true;
                // if (this.rowData != {}) {
                //     this.disabled = false
                // } else {
                //     this.disabled = true
                // }
                this.rowData = row
                // console.log('tab1', this.rowData);
                this.alarmConfigGetPropertyDefinitionByEuipmentID()
            },
            //表二点击每行
            handleSelect2(row) {
                this.alarmList2 = [];
                console.log('step2', row)
                this.tab2RowData = row
                if (row != null) {
                    this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName()
                }

            },


            //报警配置设备清单--1
            async alarmConfigGetEquipmentListByCategoryAndLevel() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    organization: {
                        ID: this.currentData,
                        Type: "E"
                    }

                    //     "organization" : {
                    //     equipmentID:'400064',
                    //     Type:"E"
                    // }
                }
                if (this.eqcValue != null) {
                    param.EquipmentCategoryID = this.eqcValue
                }
                if (this.eqlValue != null) {
                    param.EquipmentLevelID = this.eqlValue
                }
                const selectRes = await alarmConfigGetEquipmentListByCategoryAndLevel(param);
                this.pageQuantity = selectRes.pageQuantity;
                this.alarmList = selectRes.data
                // console.log(selectRes);
                if (this.alarmList.length != 0) {
                    this.$refs['alarmListTable'].setCurrentRow(this.alarmList[0]);
                }
                //  this.$refs['roleGroupRef'].setCurrentRow(this.roleGroupArray[0]);
            },
            handleCurrentChange(val) {
                this.PageNumber = val;
                this.alarmConfigGetEquipmentListByCategoryAndLevel(); //查询table数据的方法
            },

            //设备属性清单--2
            async alarmConfigGetPropertyDefinitionByEuipmentID() {
                // console.log('step2', this.rowData.id);
                var param = {
                    equipmentID: this.rowData.id,
                }

                const selectRes = await alarmConfigGetPropertyDefinitionByEuipmentID(param);
                if (selectRes.data) {
                    this.alarmList1 = selectRes.data;
                    // console.log('1111', this.alarmList1)
                    if (this.alarmList1.length != 0) {
                        this.$refs['alarmList1TableRef'].setCurrentRow(this.alarmList1[0]);
                        this.disabled = false;
                    }
                }


            },

            //设备属性报警配置清单--step3
            async alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName() {
                console.log('currentData', this.tab2RowData)
                var param = {
                    equipmentID: this.rowData.id,
                    propertyName: this.tab2RowData.sendName,
                    // alertType:this.tab2RowData
                }
                const selectRes = await alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName(param);
                if (selectRes.data) {
                    this.alarmList2 = selectRes.data
                }
                // if (this.alarmList2) {
                //     for (let i = 0; i < this.alarmList2.length; i++) {
                //         if (this.alarmList2[i].alertEnabled == true) {
                //             this.alarmList2[i].switchDis = false
                //         }
                //         if (this.alarmList2[i].alertEnabled == false) {
                //             this.alarmList2[i].switchDis = true
                //         }
                //     }
                // }

                //   console.log(this.alarmList2);
            },

            del(row) {

                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.alarmConfigDeleteOrDisabledAlert(row);
                }).catch(() => {

                });
            },
            //去活、激活
            async alarmConfigEnableOrDisableAlert(row) {
                var param = {
                    alertName: row.alertName,
                    equipmentID: this.rowData.id,
                    propertyName: this.tab2RowData.sendName,
                    type: this.typeValue,
                }
                const selectRes = await alarmConfigEnableOrDisableAlert(param);
                if (this.typeValue == 1) {
                    if (selectRes.data.result) {
                        this.$message({
                            message: selectRes.message,
                            type: "success"
                        })
                    } else {
                        this.$message({
                            message: selectRes.message,
                            type: "warning"
                        })
                    }
                }
                if (this.typeValue == 2) {
                    if (selectRes.data.result) {
                        this.$message({
                            message: selectRes.message,
                            type: "success"
                        })
                    }
                }

                //   console.log(this.alarmList2);
            },
            //删除
            async alarmConfigDeleteOrDisabledAlert(row) {
                var param = {
                    alertName: row.alertName,
                    equipmentID: this.rowData.id,
                    propertyName: this.tab2RowData.sendName,
                }
                const selectRes = await alarmConfigDeleteOrDisabledAlert(param);
                // if (this.typeValue == 1) {
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: "success"
                    })
                    this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName()
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: "warning"
                    })
                }
                // }
            },


            //组织选择
            async alarmConfigOrganizationSelect() {
                var param = {
                    permission: this.permission
                }
                const selectRes = await alarmConfigOrganizationSelect(param)
                this.organizationAr = selectRes.data.Tree
                this.equipmentArr = selectRes.data.organizationPermissionAll
                this.checkData = selectRes.data.organizationPermissionAll[0];

                for (var i = 0; i < this.equipmentArr.length; i++) {
                    if (this.equipmentArr[i].Type == 'E') {
                        this.currentData = this.equipmentArr[i].ID;
                        this.standardData = this.equipmentArr[i].ID;
                    }
                };
                this.alarmConfigGetEquipmentListByCategoryAndLevel()
                this.loading = false;
            },
            orgChange() {
                this.currentData = this.organizationValue
            },

            //设备类型
            async alarmConfigEquipmentTypeSelect() {
                var param = {
                    organization: {}
                }
                const selectRes = await alarmConfigEquipmentTypeSelect(param)
                this.typeList = selectRes.data;
            },
            //设备等级
            async alarmConfigEquipmentLevelSelect() {
                var param = {
                    organization: {}
                }
                const selectRes = await alarmConfigEquipmentLevelSelect(param)
                this.levelList = selectRes.data;
            },
            //报警类型
            async alarmConfigAlertTypeSelect() {
                var param = {
                    organization: {}
                }
                const selectRes = await alarmConfigAlertTypeSelect(param)
                this.alertTypeData = selectRes.data;
            },
            // alarmConfigAlertTypeSelect
            //新增 修改
            async alarmConfigAddOrUpdateAlerts() {
                // console.log("param",this.rowData.id,this.tab2RowData.sendName);
                //     var limit = parseInt(this.formInline.alertLimit)
                //     var level = parseInt(this.formInline.alertLevel)
                var param = {
                    equipmentID: this.rowData.id,
                    propertyName: this.tab2RowData.sendName,
                    alertType: this.formInline.alertType,
                    alertName: this.formInline.alertName,
                    alertDesc: this.formInline.alertDesc,
                    isEnabled: this.formInline.alertEnabled,
                    // isEnabled:true,
                    alertLevel: parseInt(this.formInline.alertLevel),
                    alertLimit: parseInt(this.formInline.alertLimit),
                    alarmType: this.formInline.alarmType,
                }
                const selectRes = await alarmConfigAddOrUpdateAlerts(param)
                this.addUpdateResult = selectRes.data;
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: "success"
                    })
                    this.dialogTableVisibleAdd = false;
                    this.dialogTableVisibleEdit = false;
                    this.formInline = {}
                    this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName()
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: "warning"
                    })
                    this.formInline={};
                    this.dialogTableVisibleAdd = false;
                    this.dialogTableVisibleEdit = false;
                    this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName()
                }

            },
            addConfirm() {
                this.$refs['alertRef'].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(1111);
                        this.alarmConfigAddOrUpdateAlerts()
                        // this.dialogTableVisibleAdd = false;
                        // this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName()
                    } else {
                        // console.log('error submit!!');
                        return false;
                    }
                });

            },
            editConfirm() {
                this.alarmConfigAddOrUpdateAlerts()
                // this.dialogTableVisibleEdit = false;
                // this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName()

            },
            cancel(){
                this.dialogTableVisibleEdit = false;
                this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName();
            },
            confirm() {
                this.dialogTableVisibleDetail = false;
            },
            queryCheck() {
                this.currentPage = 1;
                this.rowData = {}
                this.tab2RowData = {}
                if (this.organizationValue.length == 0) {
                    this.currentData = this.standardData;
                }
                this.alarmConfigGetEquipmentListByCategoryAndLevel()
                // this.alarmConfigGetPropertyDefinitionByEuipmentID()
                // this.alarmConfigGetAlertDefinitionByEquipmentIdAndPropertyName()
            }
        }
    }
</script>
<style scoped>
    .faultCodeAnalysisBody {
        height: 720px;
    }

    .el-pagination {
        padding: 12px 0;
        display: block;
        text-align: left;
    }

    .btn-prev {
        padding: 0px;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 15px;
    }
</style>