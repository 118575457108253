<!-- 设备详情---page_container-->
<template>
    <div class="height_100 fn_size16" style="" v-loading="loading">
        <el-row class="" style="height:98%">
            <el-col :span="3" class="height_100">
                <!-- tree_height -->
                <div class="pdt_10 bgc_FFFFFF height_100">
                    <!-- <div class="flex flex_column height_100 mg_left_10"> -->

                    <div class="scroll-x mg_top_10 " style="overflow-y:auto;height:99%">
                        <!-- accordion  手风琴 -->
                        <el-tree ref="tree" :data="treeData" :props="defaultProps" node-key="ID" class=""
                            @node-click="handleNodeClick" accordion :filter-node-method="filterNode"
                            :default-expanded-keys="expandedKeys">
                            <!-- 等待用黄色，运行用绿色、故障用蓝色 -->
                            <!-- <span class="fn_size14 color_FFF600" slot-scope="{ node,data }"
                                v-if="node.label.indexOf('等待') > -1">
                                <span>{{ node.label }}</span>
                                <span>
                                    <a v-if="data.EquipmentNum>-1">({{data.EquipmentNum}})</a>
                                </span>
                            </span>
                            <span class="fn_size14 color_00FF00" slot-scope="{ node,data }"
                                v-else-if="node.label.indexOf('运行') > -1">
                                <span>{{ node.label }}</span>
                                <span>
                                    <a v-if="data.EquipmentNum>-1">({{data.EquipmentNum}})</a>
                                </span>
                            </span>
                            <span class="fn_size14 color_00C6FF" slot-scope="{ node,data }"
                                v-else-if="node.label.indexOf('故障') > -1">
                                <span>{{ node.label }}</span>
                                <span>
                                    <a v-if="data.EquipmentNum>-1">({{data.EquipmentNum}})</a>
                                </span>
                            </span> -->
                            <span class="fn_size14" slot-scope="{ node,data }">
                                <span>{{ node.label }}</span>
                                <span>
                                    <a v-if="data.EquipmentNum > -1">({{ data.EquipmentNum }})</a>
                                </span>
                            </span>

                        </el-tree>
                    </div>

                    <!-- </div> -->

                </div>
            </el-col>
            <el-col :span="21" class="pdl10 pdr10 height_100" style="overflow-y:auto">
                <div class="">
                    <div class="box_head1 flex justify_between mg_top_10">
                        <!--   {{equInfo&&equInfo.EquipmentName}}({{equInfo&&equInfo.Equipmentmodel}}) -->
                        <span>{{ currentTreeName }}</span>
                        <!-- <div class="text_align_center bgc_009BFD color_FFFFFF w120_h40">驾驶舱模式</div> -->
                    </div>
                    <div class="flex justify_between  bgc_FFFFFF pd_20">
                        <div class="flex mg_left_20">
                            <div>
                                <div class="sb_img">
                                    <img v-if="equInfo.AvatarUrl1" :src="equInfo.AvatarUrl1" class="sb_img" alt="" />
                                </div>
                                <!-- <img class="sb_img" src="../../assets/images/deviceManagement/sb_pic.png" alt="" /> -->
                                <!-- <div class="color_FFFFFF fn_size14 flex align_center">
                                    <div class="tag_div" :class="equipmentStatus.fault?'bgc_F23434':'bgc_657180'">
                                        <img src="../../assets/images/deviceManagement/sb_icon1.png" id="imgbox1"
                                            alt="" />
                                        <span class="mg_left_8">故障</span>
                                    </div>
                                    <div class="tag_div" :class="equipmentStatus.run?'bgc_0BC359':'bgc_657180'">
                                        <img src="../../assets/images/deviceManagement/sb_icon2.png" id="imgbox"
                                            alt="" />
                                        <span class="mg_left_8">运行</span>
                                    </div>
                                    <div class=" tag_div" :class="equipmentStatus.stop?'bgc_FF9C00':'bgc_657180'">
                                        <img src="../../assets/images/deviceManagement/sb_icon3.png" id="imgbox2"
                                            alt="" />
                                        <span class="mg_left_8">停止</span>
                                    </div>
                                </div> -->
                            </div>
                            <div class="color_1A1A1A flex flex_column justify_around align_start mg_left_30">
                                <div>设备名称：{{ equInfo && equInfo.EquipmentName }}</div>
                                <div>设备编号：{{ equInfo && equInfo.EquipmentCode }}</div>
                                <div>设备等级：{{ equInfo && equInfo.EquipmentLevelName }}</div>
                                <div>设备型号：{{ equInfo && equInfo.EquipmentModel }}</div>
                                <div>控制系统：{{ equInfo && equInfo.ControlSystem }}</div>
                                <div>点检人：{{ equInfo && equInfo.InspectionPerson }}</div>
                                <div>保养人：{{ equInfo && equInfo.MaintainerPerson }}</div>
                                <div>管理员：{{ equInfo && equInfo.Administrators }}</div>
                                <div>所属部门：{{ equInfo && equInfo.WorkshopSection }}</div>
                                <div>使用部门：{{ equInfo && equInfo.UserDepartment }}</div>
                            </div>
                        </div>
                        <!-- <div class="echarts_div1">
                            <div id="echarts1" style="width: 100%; height: 100%;"></div>
                        </div> -->
                        <div style="width: 34%;" class="height_420">
                            <div id="pie_alert" style="width: 100%;height: 100%;"></div>
                        </div>
                        <div class="echarts_div2">
                            <!-- <div id="echarts2" style="width: 100%; height: 100%;"></div>  -->
                        </div>
                    </div>



                    <!-- 导航 -->
                    <div class="bgc_FFFFFF mg_top_15 flex fn_size14 height_44">
                        <div v-for="item in tabArray" :key="item.id" class="tab"
                            :class="tabIndex == item.id ? 'tab_active' : ''" @click="changeTab(item.id)">{{ item.label
                            }}</div>
                    </div>

                    <div class="mg_top_15  color_1A1A1A">
                        <!-- 基本信息 -->
                        <div v-if="tabIndex == 0" class="pd_20 bgc_FFFFFF height_300 flex"
                            style="border: 1px solid #DBDBDB;">
                            <div class="color_1A1A1A flex flex_column align_start  mg_left_30 pdr30"
                                style="width:20%;border-right: 1px solid #DBDBDB;">
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">设备名称：</span>{{ equInfo && equInfo.EquipmentName }}
                                </div>
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">设备编号：</span>{{ equInfo && equInfo.EquipmentCode }}
                                </div>
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">设备等级：</span>{{ equInfo && equInfo.EquipmentLevelName }}
                                </div>
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">设备型号：</span>{{ equInfo && equInfo.EquipmentModel }}
                                </div>
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">控制系统：</span>{{ equInfo && equInfo.ControlSystem }}
                                </div>
                            </div>
                            <div class="color_1A1A1A flex flex_column align_start mg_left_30" style="width:20%">
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">点检人：</span>{{ equInfo && equInfo.InspectionPerson }}
                                </div>
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">保养人：</span>{{ equInfo && equInfo.MaintainerPerson }}
                                </div>
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">管理员：</span>{{ equInfo && equInfo.Administrators }}
                                </div>
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">所属部门：</span>{{ equInfo && equInfo.WorkshopSectionName }}
                                </div>
                                <div class="border_bottom_1_E6E6E6 height_44 width_100 text_align_left">
                                    <span class="font_bold">使用部门：</span>{{ equInfo && equInfo.UserDepartmentName }}
                                </div>
                            </div>
                        </div>
                        <!-- 设备属性 -->
                        <div v-if="tabIndex == 1" class="pd_20 bgc_FFFFFF height_530">
                            <div class="box_head1 ">
                                <div class="fn_size14">类型选择：</div>
                                <el-select v-model="devicePropertyValue" placeholder="请选择" class="width_140"
                                    @change="changeDeviceProperty">
                                    <el-option v-for="(item, index) in deviceProperty" :key="index" :label="item.Name"
                                        :value="item.Category"></el-option>
                                </el-select>
                            </div>
                            <el-table :data="equPropertyList" style="width: 100%;" class="mg_top_10" height="90%">
                                <el-table-column type="index" label="序号" width="80">
                                </el-table-column>
                                <el-table-column prop="name" label="名称">
                                </el-table-column>
                                <el-table-column prop="category" label="类型">
                                </el-table-column>
                                <el-table-column prop="value" label="当前值">
                                </el-table-column>
                                <el-table-column prop="unit" label="单位">
                                </el-table-column>
                                <!-- alertStatus-false 正常   true 不正常  此处字体颜色为红色 -->
                                <!-- <el-table-column label="状态">
                                    <template slot-scope="scope">
                                        <span
                                            :class="scope.row.alertStatus?'color_FE4657':''">{{scope.row.alert}}</span>
                                    </template>
                                </el-table-column> -->
                                <el-table-column prop="description" label="描述" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column label="记录" width="100">
                                    <template slot-scope="scope">
                                        <el-button type="text" class="color_0384D5 pd_0" @click="deviceTrack(scope.row)"
                                            v-if="scope.row.propertyIsLoged">
                                            <span style="border-bottom: 1px solid #0384D5;">追踪</span>
                                        </el-button>
                                        <el-button type="text" class="color_999999 pd_0"
                                            v-if="!scope.row.propertyIsLoged" disabled>
                                            <span style="border-bottom: 1px solid #0384D5;">追踪</span>
                                        </el-button>
                                    </template>
                                </el-table-column>

                            </el-table>
                            <!-- 分页 -->
                            <!-- <div class="flex justify_between align_center fn_size14">
                            <div class="color_808080">当前1-10 总记录：123,456</div>
                            <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
                        </div> -->
                        </div>
                        <!-- 时间片 -->
                        <div v-if="tabIndex == 2" class="pd_20 bgc_FFFFFF height_580">
                            <div class=" box_head1 flex align_center">
                                <el-select v-model="timeStatusTypeValue" placeholder="请选择" class="width_170">
                                    <el-option v-for="item in timeStatusTypeAr" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                                <div class="fn_size14 mg_left_10">时间选择：</div>
                                <el-date-picker value-format="timestamp" v-model="sliceTime" type="datetimerange"
                                    range-separator="至" :picker-options="pickerOptions" start-placeholder="开始日期"
                                    end-placeholder="结束日期" class="mg_left_10 width_270">
                                </el-date-picker>
                                <el-cascader class="mg_left_10 el_cascader_width" ref="demoCascader" placeholder="选择设备"
                                    size="medium" v-model="timeSliceEquipmentValue" :options="timeSliceEquipmentArr"
                                    :props="defaultPropsTimeSlice" @change="changeTimeSliceEquipmentValue"
                                    :show-all-levels='false' collapse-tags clearable filterable>
                                </el-cascader>
                                <!--  -->
                                <!-- size="medium" -->
                                <el-button type="primary" class="mg_left_10 bgc_009BFD btn" @click="timeSliceQuery">查询
                                </el-button>
                                <el-button type="primary" class="mg_left_10 bgc_009BFD btn" @click="showDialog">状态分析
                                </el-button>
                            </div>
                            <!-- 时间片 -->
                            <!-- 统计 -->
                            <div v-if="timeStatusTypeValue == 1" id="timeSlice" style="width: 100%;height: 100%;"></div>
                            <!-- 实时 -->
                            <div v-if="timeStatusTypeValue == 2" id="timeSlice3" style="width: 100%;height: 100%;">
                            </div>
                        </div>
                        <!--  KPI--开始 -->
                        <div v-if="tabIndex == 3" class="bgc_FFFFFF">
                            <!-- <div class="bgc_E2F0FE border_1_CCDBE5  height_40 box_head1">
                            <div class="pdl20 pull-left">KPI查询</div>
                        </div> -->
                            <div class="box_head1 flex">
                                <span>KPI查询</span>
                                <div class="flex align_center mg_left_30">
                                    <el-date-picker value-format="timestamp" v-model="timeKPI" type="datetimerange"
                                        range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                                        class="width_270" @change="changeTimeKPI" :picker-options="pickerOptions">
                                    </el-date-picker>
                                    <el-select v-model="dataFrame2Value" placeholder="请选择" class="width_110 mg_left_10"
                                        @change="changeDataFrame2Value">
                                        <el-option v-for="item in dataFrame2" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <!-- 仪表盘 -->
                            <div class="flex height_220">
                                <!-- autoplay是否自动切换 -->
                                <el-carousel indicator-position="outside" :interval="8000" :autoplay="false"
                                    height="88%" style="width: 100%;" @change="echartsEquipmentKPI">
                                    <el-carousel-item v-for="(item, index) in Math.ceil(equipmentKPIList.length / 5)"
                                        :key="index" style="width: 100%;height: 100%;" class=" flex">
                                        <div class="flex justify_start" style="width:100%;height: 100%;">
                                            <!-- <div  style="width:35%;height: 100%;"> -->
                                            <div v-for="(items, index1) in equipmentKPIList.slice(index * 5, index * 5 + 5)"
                                                :key="index1" :id="items.id" style="width: 20%; height: 100%;"></div>
                                            <!-- </div> -->
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                            <div class="box_head1 flex">
                                <span>KPI分析</span>
                                <div class="flex align_center mg_left_30">
                                    <el-select v-model="dataFrame2Value2" placeholder="请选择" class="width_110 mg_left_10"
                                        @change="changeDataFrame2Value2">
                                        <el-option v-for="item in dataFrame2" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                    <el-date-picker value-format="timestamp" v-model="timeKPI2" type="datetimerange"
                                        range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                                        class="mg_left_10 width_270 bgc_FFFFFF" @change="changeTimeKPI2"
                                        :picker-options="pickerOptions">
                                    </el-date-picker>
                                    <!-- <el-select v-model="kpiArValue" placeholder="请选择" class="width_110 mg_left_10" @change="changeKpiArValue">
                                <el-option v-for="item in kpiArr" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select> -->
                                    <el-select v-model="kpiArValue" multiple placeholder="请选择"
                                        class="width_180 mg_left_10" collapse-tags @change="changeKpiArValue">
                                        <el-option v-for="item in kpiArr" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <!-- 柱状图KPI分析 -->
                            <!-- <div class="flex mg_top_20 mg_left_30">
                            <el-select v-model="dataFrame2Value2" placeholder="请选择" class="width_110 mg_left_10"
                                @change="changeDataFrame2Value2">
                                <el-option v-for="item in dataFrame" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                            <el-date-picker v-model="timeKPI2" type="datetimerange" range-separator="~"
                                start-placeholder="开始日期" end-placeholder="结束日期" class="mg_left_10 width_270"
                                @change="changeTimeKPI2">
                            </el-date-picker>
                            <el-select v-model="kpiArValue" multiple placeholder="请选择" class=" mg_left_10" collapse-tags
                                @change="changeKpiArValue">
                                <el-option v-for="item in kpiArr" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div> -->
                            <div class="height_340">
                                <div id="bar_KPI" style="width: 100%;height: 100%;"></div>
                            </div>
                        </div>
                        <!--  KPI--结束 -->
                        <!-- 报警信息--开始 -->
                        <div v-if="tabIndex == 4" class="pdt_30 pdl30 bgc_FFFFFF">
                            <!-- <el-scrollbar style="height:100%">
                        </el-scrollbar> -->
                            <div class="color_FFFFFF flex">
                                <el-carousel indicator-position="outside" :interval="8000" :autoplay="false"
                                    height="120px" style="width: 100%;" @change="changeAlarmInfo">
                                    <el-carousel-item v-for="(item, index) in Math.ceil(alarmInfo.length / 5)"
                                        :key="index" style="width: 100%;height: 100%;" class=" flex">
                                        <!-- <div class="flex"> -->
                                        <!-- Math.ceil返回大于等于参数x的最小整数,即对浮点数向上取整 -->
                                        <!-- slice() 方法可从已有的数组中返回选定的元素 -->
                                        <div class="box1 bgc_F75753 mg_right_30"
                                            v-for="(items, index1) in alarmInfo.slice(index * 5, index * 5 + 5)"
                                            :key="index1">
                                            <!-- *Math.ceil(alarmInfo.length/5) -->
                                            <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                            <div class="mg_left_30">
                                                <div>{{ items.name }}(条)</div>
                                                <div class="fn_size28 mg_top_10">
                                                    {{ items.value }}</div>
                                            </div>
                                        </div>
                                        <!-- </div> -->



                                        <!-- <div class="box1 bgc_F75753 mg_right_30" v-for="(item,index) in alarmInfo"
                                        :key="index">
                                        <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                        <div class="mg_left_30">
                                            <div>{{item.name}}(条)</div>
                                            <div class="fn_size28 mg_top_10">
                                                {{item.value}}</div>
                                        </div>
                                    </div> -->
                                        <!-- <div class="flex justify_center" style="width: 100%;height: 100%;">
                                    <div :id="item.id" style="flex:1;" class="echartsSize"></div>
                                </div> -->
                                    </el-carousel-item>
                                </el-carousel>

                                <!-- <div class="box1 bgc_F75753 mg_right_30" v-for="(item,index) in alarmInfo.realAlarmCount"
                                :key="index" v-if="alarmIndex==1">
                                <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                <div class="mg_left_30">
                                    <div>{{item.name}}(条)</div>
                                    <div class="fn_size28 mg_top_10">
                                        {{item.value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[3].value}}</div>
                                </div>
                            </div>
                            <div class="box1 bgc_F75753 mg_right_30" v-for="(item,index) in alarmInfo.historyAlarmCount"
                                :key="index" v-if="alarmIndex==2">
                                <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                <div class="mg_left_30">
                                    <div>{{item.name}}(条)</div>
                                    <div class="fn_size28 mg_top_10">
                                        {{item.value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[3].value}}</div>
                                </div>
                            </div> -->
                                <!-- flex flex_wrap -->
                                <!-- <div class="box1 bgc_F75753">
                                <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                <div class="mg_left_30">
                                    <div>一级报警(条)</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==1">
                                        {{alarmInfo && alarmInfo.alarmLevelCount[0].value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[3].value}}</div>
                                </div>
                            </div>
                            <div class="box1 bgc_F75753 mg_left_30">
                                <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                <div class="mg_left_30">
                                    <div>二级报警(条)</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==1">
                                        {{alarmInfo && alarmInfo.alarmLevelCount[1].value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[4].value}}</div>
                                </div>
                            </div>
                            <div class="box1 bgc_F75753 mg_left_30">
                                <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                <div class="mg_left_30">
                                    <div>三级报警(条)</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==1">
                                        {{alarmInfo && alarmInfo.alarmLevelCount[2].value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[5].value}}</div>
                                </div>
                            </div>
                            <div class="box1 bgc_F75753 mg_left_30">
                                <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                <div class="mg_left_30">
                                    <div>四级报警(条)</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==1">
                                        {{alarmInfo && alarmInfo.alarmLevelCount[3].value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[6].value}}</div>
                                </div>
                            </div>
                            <div class="box1 bgc_F75753 mg_left_30">
                                <img src="../../assets/images/deviceManagement/sb_icon4.png" alt="" />
                                <div class="mg_left_30">
                                    <div v-if="alarmIndex==1">实时报警(条)</div>
                                    <div v-if="alarmIndex==2">历史报警(条)</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==1">
                                        {{alarmInfo && alarmInfo.realAlarmCount.RealAlarm.value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[0].value}}</div>
                                </div>
                            </div>
                            <div class="box1 bgc_FFA847 mg_left_30">
                                <img src="../../assets/images/deviceManagement/sb_icon5.png" alt="" />
                                <div class="mg_left_30">
                                    <div>未确认报警(条)</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==1">
                                        {{alarmInfo && alarmInfo.realAlarmCount.UnacknowledgeAlarm.value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[2].value}}</div>
                                </div>
                            </div>
                            <div class="box1 bgc_03CABE mg_left_30">
                                <img src="../../assets/images/deviceManagement/sb_icon6.png" alt="" />
                                <div class="mg_left_30">
                                    <div>已确认报警(条)</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==1">
                                        {{alarmInfo && alarmInfo.realAlarmCount.AcknowledgeAlarm.value}}</div>
                                    <div class="fn_size28 mg_top_10" v-if="alarmIndex==2">
                                        {{alarmInfo && alarmInfo[1].value}}</div>
                                </div>
                            </div> -->
                            </div>
                            <div class=" flex">
                                <div class="fn_size14" :class="alarmIndex == 1 ? 'alarm_btn_active' : 'alarm_btn'"
                                    @click="changAlarm(1)">
                                    实时报警</div>
                                <div class="fn_size14 mg_left_20"
                                    :class="alarmIndex == 2 ? 'alarm_btn_active' : 'alarm_btn'" @click="changAlarm(2)">
                                    历史报警</div>
                            </div>
                            <div class="flex align_center fn_size14 mg_top_20">
                                <!-- <div>类型选择：</div>
                                <el-select v-model="alarmTypeValue" placeholder="请选择" class=" width_180" multiple
                                    collapse-tags>
                                    <el-option v-for="item in alarmType" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select> -->
                                <div class="mg_left_10">等级选择：</div>
                                <el-select v-model="alarmLevelValue" placeholder="请选择" class="width_180 " multiple
                                    collapse-tags>
                                    <el-option v-for="item in alarmLevelArray" :key="item.id" :label="item.name"
                                        :value="item.id"></el-option>
                                </el-select>
                                <!-- <div class="mg_left_10">是否确认：</div>
                                <el-select v-model="alarmAckValue" placeholder="请选择" class="width_180 " multiple
                                    collapse-tags>
                                    <el-option v-for="item in alarmAckArray" :key="item.id" :label="item.value"
                                        :value="item.id"></el-option>
                                </el-select> -->
                                <div class="mg_left_10" v-if="alarmIndex == 2"> 时间选择： </div>
                                <el-date-picker value-format="timestamp" v-model="alarmTime" type="datetimerange"
                                    range-separator="~" v-if="alarmIndex == 2" start-placeholder="开始日期"
                                    end-placeholder="结束日期" class="width_270 mg_left_10" :picker-options="pickerOptions">
                                </el-date-picker>
                                <el-button type="primary" class="mg_left_10 bgc_009BFD btn" @click="selectAlarm">查询
                                </el-button>
                                <el-button type="danger" @click="confirmALLAlarm" v-if="alarmIndex == 1">
                                    确认报警</el-button>
                            </div>
                            <div class="flex mg_top_20" style="width:100%">
                                <div class="flex" style="width: 64%;">
                                    <div style="width:100%">
                                        <!-- 实时报警 -->
                                        <el-table :data="alarmInfoList" height="400" v-if="alarmIndex == 1">
                                            <el-table-column type="index" label="序号" width="80">
                                            </el-table-column>
                                            <el-table-column prop="alarmTime" label="报警时间" :formatter="formatDate"
                                                width="180">
                                            </el-table-column>
                                            <el-table-column prop="propertyName" label="属性名称">
                                            </el-table-column>
                                            <!-- <el-table-column prop="alertType" label="报警类型">
                                        </el-table-column> -->
                                            <el-table-column prop="alarmLevel" label="报警等级">
                                            </el-table-column>
                                            <el-table-column prop="thresholdValue" label="报警阈值">
                                            </el-table-column>
                                            <el-table-column prop="value" label="当前报警值">
                                            </el-table-column>
                                            <el-table-column prop="duration" width="100" label="持续时间(秒)">
                                            </el-table-column>
                                            <el-table-column prop="descrition" label="报警描述" show-overflow-tooltip>
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.descrition }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="确认">
                                                <template slot-scope="scope">
                                                    <el-button v-if="scope.row.Ack" type="info" size="mini"
                                                        class="pd_6">
                                                        确认报警</el-button>
                                                    <el-button v-if="!scope.row.Ack" type="danger" size="mini"
                                                        class="pd_6" @click="confirmAlarm(scope.row)">确认报警</el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                        <!-- 历史报警 -->
                                        <el-table :data="alarmInfoList" height="400" v-if="alarmIndex == 2">
                                            <el-table-column type="index" label="序号" width="80">
                                            </el-table-column>
                                            <el-table-column prop="alarmTime" label="报警时间" :formatter="formatDate"
                                                width="180">
                                            </el-table-column>
                                            <el-table-column prop="propertyName" label="属性名称">
                                            </el-table-column>
                                            <el-table-column prop="alertType" label="报警类型">
                                            </el-table-column>
                                            <el-table-column prop="alarmLevel" label="报警等级">
                                            </el-table-column>
                                            <el-table-column prop="thresholdValue" label="报警阈值">
                                            </el-table-column>
                                            <el-table-column prop="value" label="当前报警值">
                                            </el-table-column>
                                            <!-- <el-table-column prop="duration" label="持续时间(秒)" v-if="alarmIndex==1">
                                    </el-table-column> -->
                                            <el-table-column prop="descrition" label="报警描述" show-overflow-tooltip>
                                            </el-table-column>

                                            <el-table-column label="确认状态">
                                                <template slot-scope="scope">
                                                    <span
                                                        :class="scope.row.Acknowledge ? 'color_0384D5' : 'color_F12727'">{{
                                                                scope.row.Acknowledge ? '已确认' : '未确认'
                                                        }}</span>

                                                </template>
                                            </el-table-column>

                                        </el-table>
                                        <el-pagination layout="prev, pager, next" :total="pageQuantity * pageSize"
                                            v-if="alarmIndex == 2" :page-size="pageSize"
                                            :current-page.sync="currentPage"
                                            @current-change="handleCurrentChangeHisAlarm"></el-pagination>
                                    </div>
                                </div>
                                <!-- 机器学习健康度预测结果 -->
                                <div class="reslearn" style="width: 34%;">
                                    <!-- <div id="pie_alert" style="width: 100%;height: 100%;"></div>s -->
                                    <!-- <el-table :data="alarmInfoList" height="400" v-if="alarmIndex==1">

                                    <el-table-column prop="alarmResult" label="机器学习健康度预测结果" width=" ">
                                      
                                     </el-table-column> -->

                                    <!-- <el-table-column type="">
                                          <template>{{this.alarmNum}}</template>
                                     </el-table-column> -->
                                    <div class="learn"><a
                                            style="  font-size: 14px;text-align:center;line-height:30px;color:">
                                            机器学习健康度预测结果 </a></div>
                                    <div class="alstyle">{{ this.alarmNum }}</div>

                                    <div class="perce">
                                        <el-progress :text-inside="true" :percentage="alarmNum" :stroke-width="22"
                                            :color="customColorMethod" status="warning"></el-progress>

                                    </div>
                                    <!-- </el-table>  -->


                                </div>
                            </div>

                        </div>
                        <!-- 报警信息--结束 -->
                        <!-- 点检信息--开始 -->
                        <div v-if="tabIndex == 5">
                            <div class="flex">
                                <div class="box2 bgc_03CABE color_FFFFFF">
                                    <div>上一次点检日期：</div>
                                    <div class="fn_size20" style="font-weight: bold;">{{ spotCheckInfo.lastNormalTime }}
                                    </div>
                                </div>
                                <div class="box2 bgc_F75753 color_FFFFFF mg_left_30" v-if="spotCheckInfo.normal"
                                    @click="showLastNormal">
                                    <div>上次一般点检异常：</div>
                                    <!-- 三层表达式，读取数据，渲染时 有时候会读不到属性值 在上面一个div中添加v-if判断条件，如果info.support取不到，则不加载该div即可解决。（注意，不能用v-show，v-show的机制是加载后，根据条件判断是否显示） -->
                                    <div class="fn_size20" style="font-weight: bold;">
                                        {{ spotCheckInfo.normal.abnormalNum }}项异常</div>
                                </div>
                                <div class="box2 bgc_657798 color_FFFFFF mg_left_30">
                                    <div>上次专业点检日期：</div>
                                    <div class="fn_size20" style="font-weight: bold;">
                                        {{ spotCheckInfo && spotCheckInfo.lastMajorTime }}</div>
                                </div>
                                <div class="box2 bgc_F75753 color_FFFFFF mg_left_30">
                                    <div>上次专业点检异常：</div>
                                    <!-- 也可这样解决spotCheckInfo.major&&spotCheckInfo.major.abnormalNum -->
                                    <div class="fn_size20" style="font-weight: bold;">
                                        {{ spotCheckInfo.major && spotCheckInfo.major.abnormalNum }}项异常</div>
                                </div>
                                <div class="box2 bgc_F75753 color_FFFFFF mg_left_30">
                                    <div>近三月点检异常单数：</div>
                                    <div class="fn_size20" style="font-weight: bold;">
                                        {{ spotCheckInfo && spotCheckInfo.threeMonthNum }}项异常单
                                    </div>
                                </div>
                            </div>
                            <div class="flex mg_top_20">
                                <div style="width: 55%;" class="bgc_FFFFFF height_530">
                                    <div class="box_head1 flex">
                                        <span>点检履历</span>
                                        <div class="flex align_center fn_size14 mg_left_30">
                                            <el-select v-model="spotCheckValue" placeholder="请选择" class="width_120"
                                                clearable>
                                                <el-option v-for="item in spotCheckArray" :key="item.id"
                                                    :label="item.name" :value="item.id"></el-option>
                                            </el-select>
                                            <div class="mg_left_10"> 时间选择： </div>
                                            <el-date-picker value-format="timestamp" v-model="spotCheckTime"
                                                type="datetimerange" range-separator="~" start-placeholder="开始日期"
                                                end-placeholder="结束日期" class="width_270 mg_left_10 bgc_FFFFFF"
                                                :picker-options="pickerOptions">
                                            </el-date-picker>
                                            <el-button type="primary" class="mg_left_10 bgc_009BFD btn"
                                                @click="selectSpotCheck">查询</el-button>
                                        </div>
                                    </div>
                                    <div class="pdl10 pdr10" style="height:88%">
                                        <el-table :data="spotCheckList" style="width: 100%;" height="100%"
                                            class="mg_top_10">
                                            <el-table-column prop="orderID" label="单号" width="">
                                            </el-table-column>
                                            <el-table-column prop="startTime" label="点检日期" width="">
                                            </el-table-column>
                                            <el-table-column prop="orderType" label="点检类型" width="">
                                            </el-table-column>
                                            <el-table-column prop="abnormalNum" label="异常数量" width="">
                                            </el-table-column>
                                            <el-table-column prop="oprPerson" label="点检人" width="">
                                            </el-table-column>
                                            <el-table-column prop="execLenM" label="持续时间(小时)" width="120">
                                            </el-table-column>
                                            <el-table-column prop="remarks" label="点检描述">
                                            </el-table-column>
                                            <el-table-column label="点击记录" width="90">
                                                <template slot-scope="scope">
                                                    <el-button type="text" class="color_0384D5 pd_0"
                                                        @click="recordDetail(scope.row.orderID)">
                                                        <span style="border-bottom: 1px solid #0384D5;">详情</span>
                                                    </el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                    <!-- 页码 -->
                                    <!-- <div class="flex justify_between align_start">
                                        <div class="color_808080 fn_size14 mg_top_19">
                                            当前{{this.currentPage*this.pageQuantity-this.pageQuantity+1}}-{{this.currentPage*this.pageQuantity}}
                                            总记录：{{this.pageQuantity*this.pageSize}}
                                        </div>
                                        <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize"
                                            :page-size="pageSize" :current-page.sync="currentPage"
                                            @current-change="handleCurrentChange"></el-pagination>
                                    </div> -->
                                </div>
                                <div style="width: 43%;" class="bgc_FFFFFF mg_left_20 height_530">
                                    <div class="box_head1">
                                        <span class="pdl20">本设备点检规范</span>
                                    </div>
                                    <!-- <div class="pd_20 bgc_FFFFFF flex align_center height_530">
                                    <el-input type="textarea" :autosize="{ minRows: 23, maxRows: 23}"
                                        placeholder="此处填写此设备有别于其他设备的点检规范和要求" v-model="textValue">
                                    </el-input>
                                </div> -->
                                    <el-table :data="standardList" style="width: 100%;" height="88%" class="mg_top_10">
                                        <el-table-column type="index" label="序号" width="80">
                                        </el-table-column>orderID
                                        <el-table-column prop="inspDetailCont" label="内容" width="">
                                        </el-table-column>
                                        <el-table-column prop="inspDetailStandard" label="标准" width="">
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>

                        </div>
                        <!-- 点检信息--结束 -->
                        <!-- 保养履历--开始 -->
                        <div v-if="tabIndex == 6">
                            <div class="flex">
                                <div class="box2 bgc_03CABE color_FFFFFF">
                                    <div>上次保养日期：</div>
                                    <div class="fn_size20" style="font-weight: bold;">
                                        {{ maintainInfo && maintainInfo.lastMaintenanceTime }}
                                    </div>
                                </div>
                                <!-- <div class="box2 bgc_F75753 color_FFFFFF mg_left_30">
                                <div>上次保养周期内设备异常项目：</div>
                                <div class="fn_size20" style="font-weight: bold;">9项</div>
                            </div> -->
                                <div class="box2 bgc_657798 color_FFFFFF mg_left_30">
                                    <div>下次保养日期：</div>
                                    <div class="fn_size20" style="font-weight: bold;">
                                        {{ maintainInfo && maintainInfo.nextMaintenanceTime }}
                                    </div>
                                </div>
                                <div class="box2 bgc_F69F22 color_FFFFFF mg_left_30">
                                    <div>本次保养日期：</div>
                                    <div class="fn_size20" style="font-weight: bold;">
                                        {{ maintainInfo && maintainInfo.nowMaintenanceTime }}
                                    </div>
                                </div>
                                <div class="box2 bgc_6395F9 color_FFFFFF mg_left_30">
                                    <div>设备总保养次数：</div>
                                    <div class="fn_size20" style="font-weight: bold;">
                                        {{ maintainInfo && maintainInfo.execGrade }}次</div>
                                </div>
                            </div>
                            <div class="height_530 bgc_FFFFFF">
                                <div class="box_head1 flex mg_top_20">
                                    <span>保养履历</span>
                                    <div class="flex align_center fn_size14 mg_left_30">
                                        <el-select v-model="maintainTypeValue" placeholder="请选择" class="width_120">
                                            <el-option v-for="item in maintainTypeArray" :key="item.id"
                                                :label="item.name" :value="item.id"></el-option>
                                        </el-select>
                                        <div class="mg_left_10"> 时间选择： </div>
                                        <el-date-picker value-format="timestamp" v-model="maintainTime"
                                            type="datetimerange" range-separator="~" start-placeholder="开始日期"
                                            end-placeholder="结束日期" class="width_270 mg_left_10 bgc_FFFFFF"
                                            :picker-options="pickerOptions">
                                        </el-date-picker>
                                        <el-button type="primary" class="mg_left_10 bgc_009BFD btn"
                                            @click="selectMaintain">
                                            查询
                                        </el-button>
                                    </div>
                                </div>
                                <!-- <div class="pdl10 pdr10" style=""> -->
                                <el-table :data="maintainInfoList" style="width: 100%;" height="80%"
                                    class="mg_top_10 mg_right_10 mg_left_10">
                                    <el-table-column prop="orderID" label="单号" width="">
                                    </el-table-column>
                                    <el-table-column prop="startTime" label="开始时间">
                                    </el-table-column>
                                    <el-table-column prop="endTime" label="结束时间">
                                    </el-table-column>
                                    <el-table-column prop="execType" label="保养类型">
                                    </el-table-column>
                                    <el-table-column prop="consumablesList" label="耗材清单">
                                    </el-table-column>
                                    <el-table-column prop="execPerson" label="保养负责人">
                                    </el-table-column>
                                    <el-table-column prop="execLenM" label="持续时间(小时)">
                                    </el-table-column>
                                    <el-table-column prop="orderStatus" label="工单状态">
                                    </el-table-column>
                                    <el-table-column label="保养详情" width="120">
                                        <template slot-scope="scope">
                                            <el-button type="text" class="color_0384D5 pd_0"
                                                @click="maintainDetail(scope.row.orderID)">
                                                <span style="border-bottom: 1px solid #0384D5;">详情</span>
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <!-- 页码 -->
                                <div class="flex justify_between align_start">
                                    <!-- <div class="color_808080 fn_size14 mg_top_19">
                                            当前{{this.currentPage*this.pageQuantity-this.pageQuantity+1}}-{{this.currentPage*this.pageQuantity}}
                                            总记录：{{this.pageQuantity*this.pageSize}}
                                        </div> -->
                                    <el-pagination layout="prev, pager, next" :total="pageQuantity * pageSize"
                                        :page-size="pageSize" :current-page.sync="currentPage"
                                        @current-change="handleCurrentChange"></el-pagination>
                                </div>
                                <!-- </div> -->
                            </div>

                        </div>
                        <!-- 保养履历--结束 -->
                        <!-- 维修履历--开始 -->
                        <div v-if="tabIndex == 7">
                            <div class="flex ">
                                <div class="box2 bgc_03CABE color_FFFFFF">
                                    <div>历史维修工单数：</div>
                                    <div class="fn_size20" style="font-weight: bold;">{{ repairInfo.orderNumber }}次
                                    </div>
                                </div>
                                <div class="box2 bgc_F75753 color_FFFFFF mg_left_30">
                                    <div>最近一次维修时间：</div>
                                    <div class="fn_size20" style="font-weight: bold;">{{ repairInfo.lastRepairOrderTime
                                    }}
                                    </div>
                                </div>
                            </div>
                            <div class="height_530 bgc_FFFFFF">
                                <div class="box_head1 flex mg_top_20">
                                    <span>维修履历</span>
                                    <div class="flex align_center fn_size14 mg_left_30">
                                        <el-select v-model="repairTypeValue" placeholder="请选择" class="width_120">
                                            <el-option v-for="item in repairTypeArray" :key="item.id" :label="item.name"
                                                :value="item.id"></el-option>
                                        </el-select>
                                        <div class="mg_left_10"> 时间选择： </div>
                                        <el-date-picker value-format="timestamp" v-model="repairTime"
                                            type="datetimerange" range-separator="~" start-placeholder="开始日期"
                                            end-placeholder="结束日期" class="width_270 mg_left_10 bgc_FFFFFF"
                                            :picker-options="pickerOptions">
                                        </el-date-picker>
                                        <el-button type="primary" class="mg_left_10 bgc_009BFD btn"
                                            @click="repairSelect">查询
                                        </el-button>
                                    </div>
                                </div>
                                <div class="flex mg_top_10 pdl10 pdr10">
                                    <div style="width: 60%;">
                                        <el-table :data="repairInfoList" height="430">
                                            <!-- <el-table-column type="index" label="序号" width="80">
                                        </el-table-column> -->
                                            <el-table-column prop="orderID" label="单号" show-overflow-tooltip>
                                            </el-table-column>
                                            <el-table-column prop="startTime" label="开始日期" show-overflow-tooltip>
                                            </el-table-column>
                                            <el-table-column prop="endTime" label="结束日期" show-overflow-tooltip>
                                            </el-table-column>
                                            <el-table-column prop="execLevel" label="维修级别">
                                            </el-table-column>
                                            <el-table-column prop="orderLength" label="更换备件量">
                                            </el-table-column>
                                            <el-table-column prop="execPerson" label="维修负责人">
                                            </el-table-column>
                                            <el-table-column prop="execLenM" label="持续时间(小时)">
                                            </el-table-column>
                                            <el-table-column label="维修记录" width="">
                                                <template slot-scope="scope">
                                                    <el-button type="text" class="color_0384D5 pd_0"
                                                        @click="repairDetail(scope.row.orderID)">
                                                        <span style="border-bottom: 1px solid #0384D5;">详情</span>
                                                    </el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                        <!-- 页码 -->
                                        <div class="flex justify_between align_start">
                                            <!-- <div class="color_808080 fn_size14 mg_top_19">
                                            当前{{this.currentPage*this.pageQuantity-this.pageQuantity+1}}-{{this.currentPage*this.pageQuantity}}
                                            总记录：{{this.pageQuantity*this.pageSize}}
                                        </div> -->
                                            <el-pagination layout="prev, pager, next" :total="pageQuantity * pageSize"
                                                :page-size="pageSize" :current-page.sync="currentPage"
                                                @current-change="handleCurrentChangeRepair"></el-pagination>
                                        </div>
                                    </div>

                                    <div class="height_420" style="width: 40%;">
                                        <div id="pie_repair" style="width: 100%;height: 100%;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 维修履历--结束 -->
                        <!-- 资料文档--开始 -->
                        <div v-if="tabIndex == 8" class=" pdl20 pdt_20 pdr20 bgc_FFFFFF flex height_530">
                            <div class="tab_left">
                                <div class="tab_left_box" :class="leftTabIndex == item.id ? 'tab_left_box_active' : ''"
                                    v-for="item in left_tab" :key="item.id" @click="leftTabChange(item.id)">
                                    {{ item.name }}</div>
                            </div>
                            <div class="height_100 flex flex_column" style="width: 87%;">
                                <el-table :data="documentList" style="width: 100%;" height="95%" class="mg_left_20">
                                    <el-table-column type="index" label="序号" width="80">
                                    </el-table-column>
                                    <el-table-column prop="fileType" label="文档类型" width="">
                                    </el-table-column>
                                    <el-table-column prop="fileName" label="文件名" width="">
                                    </el-table-column>
                                    <el-table-column prop="description" label="描述" width="" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="author" label="贡献人" width="">
                                    </el-table-column>
                                    <el-table-column prop="upLoader" label="上传人" width="">
                                    </el-table-column>
                                    <el-table-column label="上传日期" width="180">
                                        <template slot-scope="scope">
                                            <span style="margin-left: 10px">{{ timestampToTime(scope.row.upLoadDate)
                                            }}</span>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column :prop="timestampToTime(upLoadDate)" label="上传日期" width="" :formatter="formatDate"> -->
                                    <!-- </el-table-column> -->
                                    <el-table-column prop="format" label="格式" width="">
                                    </el-table-column>
                                    <!-- <el-table-column prop="address" label="大小" width="">
                                </el-table-column> -->
                                    <el-table-column label="操作" width="100">
                                        <template slot-scope="scope">
                                            <el-button type="text" class="color_0384D5 pd_0"
                                                @click="downloadFiles(scope.row)">
                                                <span style="border-bottom: 1px solid #0384D5;">下载</span>
                                            </el-button>
                                        </template>
                                    </el-table-column>

                                </el-table>
                                <!-- <div class="pull-right "> -->
                                <!-- <div class="color_808080">当前1-10 总记录：123,456</div> -->
                                <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
                                <el-pagination layout="prev, pager, next" :total="pageQuantity * pageSize"
                                    :page-size="pageSize" :current-page.sync="currentPage"
                                    @current-change="handleCurrentChangeDocument"></el-pagination>
                                <!-- </div> -->
                            </div>
                        </div>
                        <!-- 资料文档--结束 -->

                        <!-- 备件信息--开始 -->
                        <div v-if="tabIndex == 9" class="pd_20 bgc_FFFFFF height_530">
                            <div class="box_head1 flex align_center ">
                                <span class="fn_size14">备件类型：</span>
                                <el-select v-model="spareTypeValue" placeholder="请选择" class="width_140"
                                    @change="changeSpareTypeValue" clearable>
                                    <el-option v-for="(item, index) in spareTypeArray" :key="index"
                                        :label="item.description" :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <el-table :data="sparePartData" style="width: 100%;" class="mg_top_10" height="83%">
                                <el-table-column type="index" label="序号" width="80">
                                </el-table-column>
                                <el-table-column prop="sparePartID" label="备件编号" width="">
                                </el-table-column>
                                <el-table-column prop="sparePartName" label="备件名称" width="">
                                </el-table-column>
                                <el-table-column prop="sparePartType" label="备件类型" width="">
                                </el-table-column>
                                <el-table-column prop="sparePartModel" label="备件型号" width="">
                                </el-table-column>
                                <el-table-column prop="manufacturer" label="生产商" width="">
                                </el-table-column>
                                <el-table-column prop="supplier" label="供应商" width="">
                                </el-table-column>
                                <el-table-column prop="stockQuan" label="备件库存" width="">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.stockQuan }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="replaceCycle" label="更换周期" width="">
                                </el-table-column>
                                <el-table-column prop="referPrice" label="参考价（元）" width="">
                                </el-table-column>
                                <el-table-column prop="unit" label="单位" width="">
                                </el-table-column>
                                <el-table-column prop="stockMax" label="库存上限" width="">
                                </el-table-column>
                                <el-table-column prop="stockMin" label="库存下限" width="">
                                </el-table-column>
                                <el-table-column prop="replaceCycleUnit" label="更换周期单位" width="">
                                </el-table-column>
                            </el-table>
                            <!-- 分页 -->
                            <el-pagination layout="prev, pager, next" :total="pageQuantity * pageSize"
                                :page-size="pageSize" :current-page.sync="currentPage"
                                @current-change="handleCurrentChangeSpare"></el-pagination>
                            <!-- <div class="flex justify_between align_center fn_size14">
                            <div class="color_808080">当前1-10 总记录：123,456</div>
                            <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
                        </div> -->
                        </div>
                        <!-- 备件信息--结束 -->

                    </div>
                </div>

            </el-col>

        </el-row>
        <!-- 设备属性模块-追踪弹框 -->
        <el-dialog :title="currentTreeName + '设备点位趋势追踪'" :visible.sync="equPropertyDialog" top='15vh'>
            <div class="box_head1 mg_top_6 flex align_center ">
                <div class="fn_size14">类型筛选：</div>
                <el-cascader class="width_190" v-model="propertyValue" :options="propertyArray" :props="defaultCascader"
                    collapse-tags :show-all-levels="false" @change="handleChange"></el-cascader>
                <div class="fn_size14 mg_left_20">时间选择：</div>
                <el-date-picker value-format="timestamp" v-model="trackTime" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" class="mg_left_10 width_270"
                    :picker-options="pickerOptions" :clearable="false">
                </el-date-picker>
                <el-button type="primary" size="medium" class="mg_left_10" @click="trackQuery">查询</el-button>
            </div>
            <div class="mg_top_20 pdb_20 height_530">
                <div id="echartsLine" style="width:100%;height:100%;"></div>
            </div>
        </el-dialog>
        <!-- 状态时间片分析--弹框 -->
        <el-dialog title="状态时间片分析" :visible.sync="dialogTableVisible" top='20vh' width="65%">
            <div class="box_head1 flex align_center mg_bottom_20 mg_top_6">
                <el-select v-model="stateTimeRange" placeholder="请选择" class="width_110">
                    <el-option v-for="item in dataFrame" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="fn_size14 mg_left_10"> 时间选择： </div>
                <el-date-picker value-format="timestamp" v-model="stateTime" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" class="mg_left_10 width_270"
                    :picker-options="pickerOptions">
                </el-date-picker>
                <el-button type="primary" size="medium" class="mg_left_10" @click="timeSliceAnalysisQuery">查询
                </el-button>
            </div>
            <div style="height: 400px;">
                <div id="echartsBar" style="width: 100%;height: 100%;"></div>
            </div>
        </el-dialog>
        <!-- 时间片图点击弹框--开始 -->
        <el-dialog :title="timeSliceTitle" :visible.sync="timeSliceDialog" top='20vh' width="65%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">{{timeSliceTitle}}</div> -->
            <div class="box_head1 flex align_center mg_bottom_20 mg_top_6">
                <!-- <el-select v-model="stateTimeRange" placeholder="请选择" class="width_110">
                    <el-option v-for="item in dataFrame" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> -->
                <div class="fn_size14 mg_left_10">时间选择：</div>
                <el-date-picker value-format="timestamp" v-model="sliceTime2" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" class="mg_left_10 width_270"
                    :picker-options="pickerOptions">
                </el-date-picker>
                <el-button type="primary" class="mg_left_10" size="medium" @click="timeSliceQuery2">查询
                </el-button>
            </div>
            <div class="height_500">
                <div id="timeSlice2" style="width: 100%;height: 100%;"></div>
            </div>
        </el-dialog>
        <!-- 第二层弹窗 -->
        <el-dialog :title="timeSliceTitle2" :visible.sync="timeSliceDialog2" top='20vh' width="50%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">{{timeSliceTitle2}}</div> -->
            <!-- <div class="flex align_center mg_bottom_20 mg_top_6">
                <div class="fn_size14 mg_left_10"> 时间选择： </div>
                <el-date-picker v-model="sliceTime2" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" class="mg_left_10 width_270">
                </el-date-picker>
                <el-button type="primary" class="mg_left_10 " size="medium" @click="timeSliceQuery3">查询
                </el-button>
            </div> -->
            <div class="height_370">
                <div id="timeSlice3" style="width: 100%;height: 100%;"></div>
            </div>
        </el-dialog>
        <!--时间片图点击弹框--结束 -->
        <!-- 设备点检记录单--弹框spotCheckListInfo -->
        <el-dialog title="设备点检记录单" :visible.sync="recordDialog" top='18vh'>
            <div class="flex mg_top_6 fn_size14 color_1A1A1A">
                <div>点检时间：{{ spotCheckListInfo.startTime }}</div>
                <div class="mg_left_50"> 点检人：{{ spotCheckListInfo.oprPerson }}</div>
                <div class="mg_left_50">点检级别：{{ spotCheckListInfo.orderType }}</div>
            </div>
            <div class="mg_top_20 pdb_20 fn_size14">
                <div class=" bgc_F5F5F5 color_1A1A1A pd_20 height_550 text_align_left flex flex_column"
                    style="border: 1px solid #DBDBDB; overflow-y: auto;">
                    <!-- 本次点检共点检10项内容；发现8项正正常；2项异常；根据本次点检结果生产了单号为WX0001的设备保养单；
                    异常项目为：
                    1#液压油温度高
                    2#振动高 -->
                    <div v-for="(item, index) in spotCheckListInfo.abnormalContentList" :key="index">
                        <div>
                            {{ item }}
                        </div>
                        <!-- {{item}} -->
                    </div>
                </div>
                <!-- <el-input type="textarea" :autosize="{ minRows: 23, maxRows: 23}" placeholder="此处填写此设备有别于其他设备的点检规范和要求"
                    v-model="textValue" disabled>
                </el-input> -->
            </div>
        </el-dialog>
        <!-- 保养履历详情maintain--弹框maintainInfoDetail -->
        <el-dialog title="本次保养详情" :visible.sync="maintainDialog" top='9vh'>
            <div class="flex mg_top_6 pdb_10 fn_size14 color_1A1A1A border_bottom_1_E6E6E6">
                <el-row>
                    <el-col :span="24" class="height_30 text_align_left">单号：{{ maintainInfoDetail.orderID }}</el-col>
                    <el-col :span="7" class="height_30 text_align_left">保养开始时间：{{ maintainInfoDetail.startTime }}
                    </el-col>
                    <el-col :span="5" class="height_30 text_align_left">保养负责人：{{ maintainInfoDetail.execPerson }}
                    </el-col>
                    <el-col :span="5" class="height_30 text_align_left">设备管理员：{{ deviceManager }}</el-col>
                    <el-col :span="5" class="height_30 text_align_left">保养班组：{{ maintainInfoDetail.execGroup }}</el-col>
                    <el-col :span="7" class="height_30 text_align_left">保养结束时间：{{ maintainInfoDetail.endTime }}</el-col>
                    <el-col :span="5" class="height_30 text_align_left">保养类型 ：{{ maintainInfoDetail.execType }}</el-col>
                    <el-col :span="5" class="height_30 text_align_left">保养耗时 ：{{ maintainInfoDetail.execLenM }}</el-col>
                </el-row>
            </div>
            <div class="mg_top_20 pdb_20 fn_size14 color_1A1A1A">
                <div class="flex font_bold">
                    <div>保养耗材清单</div>
                    <div class="mg_left_30">本次保养消耗<span class="color_007DF2">{{ maintainInfoDetail.list &&
                            maintainInfoDetail.list.length
                    }}</span>项耗材
                    </div>
                </div>
                <el-table :data="maintainInfoDetail.list" style="width: 100%;" height="340" class="mg_top_20">
                    <el-table-column type="index" label="序号" width="70">
                    </el-table-column>
                    <el-table-column prop="sparepartID" label="耗材编号">
                    </el-table-column>
                    <el-table-column prop="sparepartName" label="耗材名称">
                    </el-table-column>
                    <el-table-column prop="sparepartModel" label="耗材型号">
                    </el-table-column>
                    <!-- <el-table-column prop="" label="耗材类型">
                    </el-table-column> -->
                    <el-table-column prop="applyQuan" label="耗材数量">
                    </el-table-column>
                    <el-table-column prop="unit" label="单位">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="180" show-overflow-tooltip>
                    </el-table-column>
                </el-table>
                <div class="flex font_bold mg_top_20">
                    <div>本次保养描述</div>
                </div>
                <el-input class="mg_top_10" type="textarea" :autosize="{ minRows: 7, maxRows: 7 }"
                    placeholder="此处填写此设备有别于其他设备的点检规范和要求" v-model="maintainInfoDetail.execDesc" disabled>
                </el-input>
                <!-- <div class=" bgc_F5F5F5 color_1A1A1A pd_20 flex height_170 text_align_left mg_top_10"
                    style="border: 1px solid #DBDBDB;">
                    此项内容填写后 经设备主管审核后，归档到保养经验库，对经验提供者给予一定的奖励！execDesc
                    此项内容填写后 经设备主管审核后，归档到保养经验库，对经验提供者给予一定的奖励！
                </div> -->
            </div>
        </el-dialog>
        <!-- 维修详情repairDialog -->
        <el-dialog title="本次维修详情" :visible.sync="repairDialog" top='9vh'>
            <div style="overflow-y: auto;" class="height_740 fn_size14">
                <div class="flex mg_top_6 pdb_10  color_1A1A1A border_bottom_1_E6E6E6">
                    <el-row>
                        <el-col :span="24" class="height_30 text_align_left">单号：{{ repairInfoDetail.orderID }}</el-col>
                        <el-col :span="7" class="height_30 text_align_left">维修开始时间：{{ repairInfoDetail.startTime }}
                        </el-col>
                        <el-col :span="5" class="height_30 text_align_left">维修负责人：{{ repairInfoDetail.execPerson }}
                        </el-col>
                        <el-col :span="5" class="height_30 text_align_left">设备管理员：{{ deviceManager }}</el-col>
                        <el-col :span="5" class="height_30 text_align_left">维修班组：{{ repairInfoDetail.execGroup }}
                        </el-col>
                        <el-col :span="7" class="height_30 text_align_left">维修结束时间：{{ repairInfoDetail.endTime }}
                        </el-col>
                        <el-col :span="5" class="height_30 text_align_left">维修类型 ：{{ repairInfoDetail.execType }}
                        </el-col>
                        <el-col :span="5" class="height_30 text_align_left">维修耗时 ：{{ repairInfoDetail.execLenM }}
                        </el-col>
                    </el-row>
                </div>
                <div class="mg_top_20 color_1A1A1A">
                    <div class="flex font_bold">
                        <div>维修耗材清单</div>
                        <div class="mg_left_30">本次维修消耗<span class="color_007DF2">{{ repairInfoDetail.list &&
                                repairInfoDetail.list.length
                        }}</span>项耗材
                        </div>
                    </div>
                    <el-table :data="repairInfoDetail.list" style="width: 100%;" height="340" class="mg_top_20">
                        <el-table-column type="index" label="序号" width="70">
                        </el-table-column>
                        <el-table-column prop="sparepartID" label="耗材编号">
                        </el-table-column>
                        <el-table-column prop="sparepartName" label="耗材名称">
                        </el-table-column>
                        <el-table-column prop="sparepartModel" label="耗材型号">
                        </el-table-column>
                        <!-- <el-table-column prop="address" label="耗材类型">
                        </el-table-column> -->
                        <el-table-column prop="applyQuan" label="耗材数量">
                        </el-table-column>
                        <el-table-column prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column prop="remarks" label="备注" width="180">
                        </el-table-column>
                    </el-table>
                    <div class="flex font_bold mg_top_20">
                        <div>本次维修说明</div>
                    </div>
                    <el-input class="mg_top_10" type="textarea" :autosize="{ minRows: 7, maxRows: 7 }"
                        placeholder="此项内容填写后 经设备主管审核后，归档到维修经验库，对经验提供者给予一定的奖励！" v-model="repairInfoDetail.execDesc"
                        disabled>
                    </el-input>
                    <!-- <div class=" bgc_F5F5F5 color_1A1A1A pd_20 flex height_170 text_align_left mg_top_10"
                        style="border: 1px solid #DBDBDB;">
                        
                    </div> -->
                </div>
                <!-- <div class="mg_top_20 pdb_20 flex">
                    <div style="width: 50%;">
                        <div class="flex font_bold ">
                            <div>本次维修经验总结</div>
                        </div>
                        <el-table :data="tableData" style="width: 100%;" height="340" class="mg_top_10">
                            <el-table-column prop="address" label="故障代码">
                            </el-table-column>
                            <el-table-column prop="name" label="故障类型">
                            </el-table-column>
                            <el-table-column prop="type" label="故障描述">
                            </el-table-column>
                            <el-table-column prop="num" label="解决方案">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="width: 50%;" class="mg_left_20">
                        <div class="flex font_bold ">
                            <div>故障解决方案</div>
                        </div>
                        <div class="bgc_F5F5F5 mg_top_10 pd_20 height_340 text_align_left">
                        </div>
                    </div>
                </div> -->
            </div>
        </el-dialog>
        <!-- 上次一般点检异常 -->
        <el-dialog title="上次一般点检异常" :visible.sync="lastNormalDialog" top='9vh'>

        </el-dialog>

    </div>
</template>
<script>
//   import innerTopNav from './../../components/innerTopNav'
import * as echarts from 'echarts';
import {
    equipmentOrganizationTree,
    equipmentStaticBasicInfo,
    equipmentRealStatus,
    equipmentStaticKPI,
    equipmentStaticTimeAnalysis,
    equipmentBasicInfo,
    equipmentPropertyType,
    equipmentProperty,
    equipmentPropertyTrend,
    equipmentTimeSlice,
    equipmentTimeSliceAnalysis,
    equipmentKpi,
    equipmentKpiAnalysis,
    equipmentAlarmInfoStatistics,
    equipmentAlarmInfoHistoryStatistics,
    equipmentAlarmInfoRealTable,
    equipmentAlarmInfoHistoryTable,
    equipmentAlarmInfoLevelStatistics,
    equipmentAlarmInfoAcknowledge,
    equipmentPointInspectioStatistics,
    equipmentPointInspectioRecordTable,
    equipmentPointInspectioDetailRecord,
    equipmentMaintainStatistics,
    equipmentMaintainRecordTable,
    equipmentMaintainDetailRecord,
    equipmentRepairStatistics,
    equipmentRepairRecordTable,
    equipmentRepairDetailRecord,
    equipmentDocumentRecord,
    equipmentSparePartTable,
    equipmentTimeStatus,
    equipmentPropertySelect,
    equipmentKpiSelect,
    equipmentPointInspectioTypeSelect,
    equipmentAlarmTypeSelect,
    equipmentHistoryAlarmLevelSelect,
    equipmentHistoryAlarmTypeSelect,
    equipmentAlarmLevelSelect,
    equipmentMaintainTypeSelect,
    equipmentRepairTypeSelect,
    equipmentDocumentTypeSelect,
    equipmentHistoryAlarmAcknowledgeStatus,
    equipmentAlarmAcknowledgeStatus,
    equipmentSparePartTypeSelect,

    equipmentTimeOrganizationSelect

} from "@/api/api_deviceManagement";

export default {
    // components: {
    //   innerTopNav
    // },
    data() {
        return {
            appKey: "23d7ee74-563a-4407-ba7d-24449064040e",
            alarmResult: [{}],
            treeType: 1,
            currentTreeId: '',
            tabIndex: '1',
            leftTabIndex: 1,
            value: '',
            value1: '',
            propertyNameCurrent: '',
            propertyTableName: '',
            propertyNameCurrentName: '',
            equPropertyDialog: false,
            dialogTableVisible: false,
            timeSliceDialog: false,
            recordDialog: false,
            maintainDialog: false,
            repairDialog: false,
            timeSliceDialog2: false,
            timeSlice: null,
            timeSlice3: null,
            echartsGauge1: null,
            pie_alert: null,
            pie_repair: null,
            textValue: '',
            treeData: [],
            equInfo: {
                AvatarUrl1: ''
            },
            deviceManager: '',
            alarmInfo: {},
            deviceProperty: [],
            devicePropertyValue: '',
            equPropertyList: [],
            defaultProps: {
                children: 'children',
                label: 'Name'
            },
            timeSliceType: '',
            timeStatusTypeValue: '1',
            timeSliceTitle: '',
            timeSliceTitle2: '',
            // start.setTime(start.getTime() - 6 * 60 * 60 * 1000);
            trackTime: [new Date().getTime() - 10 * 60 * 1000, new Date().getTime()], //默认前十分钟
            sliceTime: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
            sliceTime2: [],
            stateTime: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
            timeKPI: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
            timeKPI2: [new Date().getTime() - 3600 * 1000 * 24 * 7, new Date().getTime()], //默认是一周
            // .getTime()
            alarmTime: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
            maintainTime: null,
            spotCheckTime: null,
            expandedKeys: [],
            defaultID: '',
            defaultExpandKeys: [],
            statusTypeValue: '1',
            stateTimeRange: '1',
            dataFrame2Value: '1',
            dataFrame2Value2: '1',
            kpiArValue: [],
            spotCheckList: [],
            spotCheckListInfo: {},
            propertyValue: [],
            propertyArray: [],
            defaultCascader: {
                value: 'id',
                label: 'name',
                children: 'children',
                multiple: true,
                emitPath: false,
                expandTrigger: 'hover'

            },
            dataFrame: [{
                value: '1',
                label: '日'
            }, {
                value: '2',
                label: '月'
            }, {
                value: '3',
                label: '年'
            }],
            dataFrame2: [{
                value: '1',
                label: '日'
            }, {
                value: '2',
                label: '月'
            }],
            timeStatusTypeAr: [{
                value: '1',
                label: '状态统计分析'
            }, {
                value: '2',
                label: '状态时序分析'
            }],
            kpiArr: [],
            alarmTypeValue: [],
            alarmLevelValue: [],
            alarmAckValue: [],
            alarmInfoList: [],
            alarmIndex: '1',
            alarmType: [],
            alarmLevelArray: [],
            alarmAckArray: [],
            spotCheckValue: '',
            spotCheckInfo: {},
            standardList: [],
            spotCheckArray: [],
            maintainTypeValue: '',
            maintainInfo: {},
            maintainInfoList: [],
            maintainInfoDetail: {},
            maintainTypeArray: [],
            repairInfo: {},
            repairTypeValue: '',
            repairTime: null,
            // repairTime: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
            repairInfoList: [],
            repairInfoDetail: {},
            repairTypeArray: [],
            spareTypeValue: '',
            spareTypeArray: [],
            documentList: [],

            tabArray: [
                //     {
                //     id: 0,
                //     label: '基本信息'
                // }, 
                {
                    id: 1,
                    label: '设备属性'
                },
                // {
                //     id: 2,
                //     label: '时间片'
                // }, {
                //     id: 3,
                //     label: 'KPI'
                // }, 
                {
                    id: 4,
                    label: '报警信息'
                },
                // {
                //     id: 5,
                //     label: '点检信息'
                // }, 
                {
                    id: 6,
                    label: '保养履历'
                }, {
                    id: 7,
                    label: '维修履历'
                }, {
                    id: 8,
                    label: '资料文档'
                },
                // {
                //     id: 9,
                //     label: '备件信息'
                // }, 
            ],
            left_tab: [],
            tableData: [],
            currentPage: 1,
            pageSize: 10,
            pageQuantity: 0,
            loading: true,
            permission: {},
            pickerOptions1: {
                shortcuts: [{
                    text: '最近6个小时',
                    onClick(picker) {
                        const end = new Date().getTime();
                        const start = new Date();
                        start.setTime(start.getTime() - 6 * 60 * 60 * 1000);
                        // 时 分 秒 毫秒
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近12小时',
                    onClick(picker) {
                        const end = new Date().getTime();
                        const start = new Date();
                        start.setTime(start.getTime() - 12 * 60 * 60 * 1000);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近24小时',
                    onClick(picker) {
                        const end = new Date().getTime();
                        const start = new Date();
                        start.setTime(start.getTime() - 24 * 60 * 60 * 1000);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            equipmentStatus: {},
            timer: null,
            lastNormalDialog: false,
            sparePartData: [],

            tabelTimer: null,
            currentTreeName: '',

            equipmentKPIList: [],
            keywordTree: '',

            accordionStaues: false, //

            timeSliceEquipmentArr: [],
            timeSliceEquipmentValue: [],
            defaultPropsTimeSlice: {
                label: 'Name',
                value: 'ID',
                expandTrigger: 'hover',
                multiple: true, // 多选
                emitPath: false, // 只返回选中节点的值
                // checkStrictly: true,//选择任意一级选项
            }, //级联选择器绑定值
            alarmNum: 0
        }
    },
    watch: {
        keywordTree(val) {
            // console.log(val)
            // if (val != '') {
            this.$refs.tree.filter(val);
            // }else {
            // this.accordionStaues = false;
            // }
        }
    },
    beforeDestroy() {
        if (this.tabelTimer != null) {
            clearInterval(this.tabelTimer);
        }
        if (this.timer != null) {
            clearTimeout(this.timer);
            //  (this.timer);
        }
    },
    mounted() {
        // this.drawShape();
        if (localStorage.getItem('permission') != null) {
            // console.log(JSON.parse(localStorage.getItem('permission')));
            this.permission = JSON.parse(localStorage.getItem('permission'))
        }
        this.changeTreeType();

        // this.equipmentOrganizationTree();
        // this.equipmentTimeOrganizationSelect(); //时间片设备选择下拉框
        // this.equipmentStaticBasicInfo();
        //  setTimeout(() => { 
        // this.equipmentStaticKPI();
        // }, 200);
        // this.equipmentStaticKPI();
        // this.equipmentStaticTimeAnalysis();
        // this.equipmentBasicInfo();
        // this.equipmentPropertyType();
        // this.equipmentProperty();

    },
    methods: {
        timestampToTime(date) {
            console.log("------------", date)
            var date = new Date(date);
            var YY = date.getFullYear() + '-';
            var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            return YY + MM + DD + " " + hh + mm + ss;
        },
        //进度条  机器学习健康度预测结果 进度条
        customColorMethod(alarmNum) {
            if (this.alarmNum > 80) {
                return '#92E8A0';
            } else if (60 <= this.alarmNum <= 80) {
                return '#FFD700';
            } else if (this.alarmNum < 60) {
                return '#F75753';
            }
        },

        // 设备结构树
        async equipmentOrganizationTree() {
            this.treeData = [];
            var param = {
                treeType: this.treeType,
                permission: this.permission
            };
            const selectRes = await equipmentOrganizationTree(param);
            this.treeData = selectRes.data.Tree;
            this.defaultID = this.treeData[0].children[0].children[0].children[0].children[0].children[0].children[5].children[0].ID;
            this.currentTreeName = this.treeData[0].children[0].children[0].children[0].children[0].children[0].children[5].children[0].Name;
            this.expandedKeys.push(this.defaultID);
            console.log(this.expandedKeys);
            this.currentTreeId = this.defaultID;
            // var textData = selectRes.data.DefautEquipmentID; //默认展开的树的id;
            // this.$nextTick(() => {
            //     if (this.$refs['tree']) {
            //         // this.$refs['leftNavigation'].open(data[a].TreeID); // 打开子菜单
            //         this.$refs['tree'].setCurrentKey(textData);
            //         this.defaultExpandKeys = [];
            //         this.defaultExpandKeys.push(textData);
            //         // this.$refs['tree'].getNode(textData);
            //         this.currentTreeName = this.$refs['tree'].getNode(textData).data
            //             .Name; //根据 data 或者 key 拿到 Tree 组件中的 node
            //     }
            // });

            // this.equipmentStaticKPI();
            // this.equipmentStaticTimeAnalysis();
            this.equipmentBasicInfo();
            this.equipmentAlarmInfoLevelStatistics();
            this.equipmentPropertyType();
            // this.equipmentProperty();
            this.equipmentRealStatus();
            this.changeTab();
            this.loading = false;
        },
        // 改变树的状态type
        changeTreeType(id) {
            this.treeType = '2';
            this.equipmentOrganizationTree();
        },
        // 时间片设备选择下拉框
        async equipmentTimeOrganizationSelect() {
            var param = {
                permission: this.permission
            }
            const selectRes = await equipmentTimeOrganizationSelect(param);
            this.timeSliceEquipmentArr = selectRes.data.Tree
        },

        // 设备静态信息查询
        async equipmentStaticBasicInfo() {
            var param = {
                equipmentID: this.currentTreeId
            }
            const selectRes = await equipmentStaticBasicInfo(param);
        },
        // 设备玉珏三率图查询
        async equipmentStaticKPI() {
            // console.log(that.currentTreeId)
            var param = {
                equipmentID: this.currentTreeId
            }
            const selectRes = await equipmentStaticKPI(param);
            // console.log(selectRes.data)
            var data = [];
            for (var i = 0; i < selectRes.data.length; i++) {

                if (selectRes.data[i].name.indexOf('开机') > -1) {
                    var currenteData = {
                        name: '', //title
                        value: '',
                        title: { //仪表盘标题
                            offsetCenter: ['-10%', '-15%'],
                            // color: '#d464ff',
                        },
                        detail: {
                            offsetCenter: ['20%', '-15%']
                        }
                    }
                    currenteData.name = selectRes.data[i].name;
                    currenteData.value = selectRes.data[i].value;
                    data.push(currenteData)
                }
                if (selectRes.data[i].name.indexOf('综合') > -1) {
                    var currenteData = {
                        name: '', //title
                        value: '',
                        title: { //仪表盘标题
                            offsetCenter: ['-10%', '0%'],
                            // color: '#d464ff',
                        },
                        detail: {
                            offsetCenter: ['20%', '0%']
                        }
                    }
                    currenteData.name = selectRes.data[i].name;
                    currenteData.value = selectRes.data[i].value;
                    data.push(currenteData)
                }
                if (selectRes.data[i].name.indexOf('故障') > -1) {
                    var currenteData = {
                        name: '', //title
                        value: '',
                        title: { //仪表盘标题
                            offsetCenter: ['-10%', '15%'],
                            // color: '#d464ff',
                        },
                        detail: {
                            offsetCenter: ['20%', '15%']
                        }
                    }
                    currenteData.name = selectRes.data[i].name;
                    currenteData.value = selectRes.data[i].value;
                    data.push(currenteData)
                }

            }
            console.log(data)
            // this.drawShapeEcharts1(data);
        },
        // 设备时间分析柱图查询
        async equipmentStaticTimeAnalysis() {
            var param = {
                equipmentID: this.currentTreeId
            }
            const selectRes = await equipmentStaticTimeAnalysis(param);
            var currentData = {
                legend: [],
                xAxis: ['待机时间', '负载时间', '故障停机', '停机时间'],
                series: [{
                    data: [{
                        value: '',
                        itemStyle: {
                            color: '#F5BF21'
                        }
                    }, {
                        value: '',
                        itemStyle: {
                            color: '#61D9AA'
                        }
                    }, {
                        value: '',
                        itemStyle: {
                            color: '#E86B5A'
                        }
                    }, {
                        value: '',
                        itemStyle: {
                            color: '#647697'
                        }
                    },],
                    type: 'bar',
                    barWidth: 48, //柱的宽度
                }]
            }
            currentData.legend.push(selectRes.data.name)
            currentData.series[0].data[0].value = selectRes.data.WaitStatus; //待机
            currentData.series[0].data[1].value = selectRes.data.LoadingStatus //负载
            currentData.series[0].data[2].value = selectRes.data.FaultStatus //故障
            currentData.series[0].data[3].value = selectRes.data.StopStatus //停机
            // this.drawShapeEcharts2(currentData);
        },
        // 设备状态
        async equipmentRealStatus() {
            this.equipmentStatus = {};
            var param = {
                equipmentID: this.currentTreeId
            }
            const selectRes = await equipmentRealStatus(param);
            this.equipmentStatus = selectRes.data;
            // bgc_657180
            // if(stop){}
            if (this.timer != null) {
                clearTimeout(this.timer);
                this.imgbox = document.getElementById('imgbox1');
                this.imgbox.style.display = 'block';
                this.imgbox1 = document.getElementById('imgbox2');
                this.imgbox1.style.display = 'block';
            }
            this.img();
        },
        img() {
            if (this.equipmentStatus.fault) {
                //按钮闪烁
                //获取元素设置其ID
                this.imgbox = document.getElementById('imgbox1');
                this.imgbox.style.display = this.imgbox.style.display == "none" ? "" : "none";
                //定时器,定时调用函数
                this.timer = setTimeout(() => {
                    this.img()
                }, 800)
            }
            if (this.equipmentStatus.stop) {
                //按钮闪烁
                //获取元素设置其ID
                this.imgbox = document.getElementById('imgbox2');
                this.imgbox.style.display = this.imgbox.style.display == "none" ? "" : "none";
                //定时器,定时调用函数
                this.timer = setTimeout(() => {
                    this.img()
                }, 800)
            }
        },
        // 设备基本信息查询
        async equipmentBasicInfo() {
            var param = {
                // equipmentID: '100001'
                equipmentID: this.currentTreeId
            }
            // var api = 'http://121.37.181.242:8080';
            // // var kpi = '?appKey=e814dc0d-b4b1-4c15-ae72-a749413b1d93';
            // let appKey = window.localStorage.getItem('appKey');

            // data:image/jpeg;base64,/
            const selectRes = await equipmentBasicInfo(param);
            if (selectRes.data[0]) {
                this.equInfo = selectRes.data[0];
                if (this.equInfo.AvatarUrl.content != '') {
                    // console.log(8888)
                    this.equInfo.AvatarUrl1 = 'data:image/' + this.equInfo.AvatarUrl.format + ';base64,' + this
                        .equInfo.AvatarUrl.content;
                    // this.equInfo.AvatarUrl = api + this.equInfo.AvatarUrl + '?appKey=' + appKey;
                    this.deviceManager = this.equInfo.UpkeepWorker
                }

            }
            // this.deviceManager = this.equInfo.UpkeepWorker
        },
        // 设备属性接口--开始
        // 设备专用属性类型选择接口
        async equipmentPropertyType() {
            var param = {
                equipmentID: this.currentTreeId
                // equipmentID: '100001'
            }
            const selectRes = await equipmentPropertyType(param);
            this.deviceProperty = selectRes.data;
            for (var i = 0; i < this.deviceProperty.length; i++) {
                this.devicePropertyValue = this.deviceProperty[i].Category;
                break
            }
            if (this.tabelTimer != null) {
                clearInterval(this.tabelTimer);
            }
            //this.equipmentProperty();打开被隐藏的

            this.tabelTimer = setInterval(() => {
                //this.equipmentProperty();打开被隐藏的
            }, 2000);

            // console.log(this.deviceProperty)
        },
        async equipmentProperty() {
            this.equPropertyList = [];
            var param = {
                equipmentID: this.currentTreeId
                // equipmentID: '100001'
            }
            if (this.devicePropertyValue != null && this.devicePropertyValue != "") {
                param.propertyType = this.devicePropertyValue;
            }
            const selectRes = await equipmentProperty(param);
            this.equPropertyList = selectRes.data
        },
        changeDeviceProperty() {
            // console.log(this.devicePropertyValue);
            this.equipmentProperty()
        },
        deviceTrack(row) {
            // console.log(name)
            this.propertyValue = [];
            this.equPropertyDialog = true;
            this.propertyNameCurrent = row.propertyName;
            this.propertyTableName = row.tableNameSuffix;
            // this.propertyNameCurrentName = row.name;
            this.equipmentPropertySelect();
            setTimeout(() => {
                // this.echartsLine = echarts.init(document.getElementById('echartsLine'));
                // this.echartsLine.showLoading({
                //     text: '追踪中，请稍候...',
                //     // maskColor: 'rgba(3,3,8,0.5)',
                //     // textColor: '#fff600'
                // });
                this.equipmentPropertyTrend();
            }, 50)
        },
        trackQuery() {
            console.log(this.propertyValue);

            this.equipmentPropertyTrend();
        },
        async equipmentPropertySelect() {
            var param = {
                equipmentID: this.currentTreeId
                // equipmentID: '100001'
            };
            const selectRes = await equipmentPropertySelect(param);
            // this.propertyArray = selectRes.data;
            this.propertyArray = [];
            for (var i = 1; i < selectRes.data.length; i++) {
                this.propertyArray.push(selectRes.data[i])
            }
        },
        handleChange() {
            this.propertyNameCurrent = this.propertyValue.join(',')
        },

        // 设备专用属性历史趋势查询
        async equipmentPropertyTrend() {
            var that = this;
            var param = {
                equipmentID: this.currentTreeId,
                // equipmentID: '100001',
                propertyName: this.propertyNameCurrent,
                startDate: that.trackTime[0],
                endDate: that.trackTime[1],
                tableName: this.propertyTableName,
            }
            this.echartsLine = echarts.init(document.getElementById('echartsLine'));
            this.echartsLine.showLoading({
                text: '追踪中，请稍候...',
                // maskColor: 'rgba(3,3,8,0.5)',
                // textColor: '#fff600'
            });
            const selectRes = await equipmentPropertyTrend(param);
            var test = [];
            if (selectRes.data) {
                var LineC = {
                    legend: [],
                    xAxis: [],
                    series: []
                };
                LineC.xAxis = selectRes.data.recordTimeList;

                if (selectRes.data.trend) {
                    console.log(111111)
                    test = selectRes.data.trend;
                    for (var i = 0; i < test.length; i++) {
                        // test
                        var testData = {
                            name: '',
                            type: 'line',
                            // stack: '总量',
                            // areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        };
                        testData.name = test[i].name;
                        testData.data = test[i].value;
                        LineC.legend.push(test[i].name)
                        LineC.series.push(testData)
                    }
                    console.log(LineC)
                    that.drawShapeechartsLine(LineC);
                } else {
                    that.echartsLine.hideLoading()
                }
            }
        },
        // // 设备属性接口--结束

        // 设备时间片
        async equipmentTimeSlice() {
            var currentEqumentID = '';
            if (this.timeSliceEquipmentValue.length != 0) {
                currentEqumentID = this.currentTreeId + ',' + this.timeSliceEquipmentValue.join(',')
            } else {
                currentEqumentID = String(this.currentTreeId)
            }
            var param = {
                // equipmentID: this.currentTreeId,
                equipmentID: currentEqumentID,
                // equipmentID: '100001',
                endDate: this.sliceTime[1],
                startDate: this.sliceTime[0],
                timeStatusType: this.timeStatusTypeValue
            };
            const selectRes = await equipmentTimeSlice(param);
            var LineB = {
                legend: {
                    bottom: '6%', //距离下边距
                    data: []
                },
                yAxis: [], //y轴
                series: []
            }
            var dataList = selectRes.data.dataList;
            for (var i = 0; i < dataList.length; i++) {
                var testData = {
                    name: '', //AlarmStatus
                    type: 'bar',
                    // id: '666', //柱的id
                    stack: 'total',
                    // barWidth: 220, //柱的宽度
                    barMaxWidth: 220, //最大宽度
                    label: {
                        show: false
                    },
                    emphasis: { //高亮显示
                        focus: 'series'
                    },
                    itemStyle: {
                        color: ''
                    },
                    data: []
                }
                testData.name = dataList[i].name;
                // value
                LineB.legend.data[i] = dataList[i].name;
                testData.data = dataList[i].value;
                if (dataList[i].name.indexOf('负载') > -1) {
                    testData.itemStyle.color = '#61D9AA'
                }
                if (dataList[i].name.indexOf('待机') > -1) {
                    testData.itemStyle.color = '#F5BF21'
                }
                if (dataList[i].name.indexOf('故障') > -1) {
                    testData.itemStyle.color = '#E86B5A'
                }
                if (dataList[i].name.indexOf('停机') > -1) {
                    testData.itemStyle.color = '#647697'
                }
                if (dataList[i].timeStatusType) {
                    testData.id = dataList[i].timeStatusType
                }
                LineB.series[i] = testData;
            }
            var equipmentList = selectRes.data.equipmentList;
            for (var j = 0; j < equipmentList.length; j++) {
                LineB.yAxis[j] = equipmentList[j].equipmentName
            }
            // console.log(LineB)
            this.drawTimeSlice(LineB)
        },
        // 实时时间状态分析
        async equipmentTimeStatus() {
            var currentEqumentID = '';
            if (this.timeSliceEquipmentValue.length != 0) {
                currentEqumentID = this.currentTreeId + ',' + this.timeSliceEquipmentValue.join(',')
            } else {
                currentEqumentID = String(this.currentTreeId)
            }
            var param = {
                equipmentID: currentEqumentID,
                // equipmentID: '100001',
                endDate: this.sliceTime[1],
                startDate: this.sliceTime[0],
                // dateType: this.timeStatusTypeValue,
            };
            const selectRes = await equipmentTimeStatus(param);
            var testData = {
                data: [],
                yAxis: []
            }
            for (var i = 0; i < selectRes.data.length; i++) {
                var testArray = []
                testData.yAxis[i] = selectRes.data[i].equipmentName;
                testArray = selectRes.data[i].timeStatus;
                testData.data = testData.data.concat(testArray)
            }
            // console.log(testData)
            this.drawTimeSlice3(testData);
        },
        drawTimeSlice3(currentData) {
            // var startTime = 1608991598383;
            var that = this;
            var startTime = that.sliceTime[0]
            var categories = currentData.yAxis

            function renderItem(params, api) {
                // console.log(params, api)
                // console.log(api.value(0), api.value(1), api.value(2))
                var categoryIndex = api.value(0) //对应的三类中的一类
                var start = api.coord([api.value(1), categoryIndex]) //起始点坐标
                var end = api.coord([api.value(2), categoryIndex]) //结束点坐标
                var height = api.size([0, 1])[1] * 0.6
                // console.log(api.value(2) - api.value(1))
                var rectShape = echarts.graphic.clipRectByRect({
                    x: start[0],
                    y: start[1] - height / 2,
                    width: end[0] - start[0],
                    height: height
                }, {
                    x: params.coordSys.x,
                    y: params.coordSys.y,
                    width: params.coordSys.width,
                    height: params.coordSys.height
                });

                return rectShape && {
                    type: 'rect',
                    transition: ['shape'],
                    shape: rectShape,
                    style: api.style()
                };
            }
            echarts.init(document.getElementById('timeSlice3')).dispose(); //销毁echarts
            that.timeSlice3 = echarts.init(document.getElementById('timeSlice3'));
            that.timeSlice3.setOption({
                tooltip: {
                    // trigger: 'axis',
                    formatter: function (params) {
                        // console.log(params)
                        // for (var i = 0; i < params.length; i++) {
                        // console.log(params)
                        let h = Math.floor(params.value[3] / 3600);
                        let m = Math.floor(params.value[3] % 3600 / 60);
                        let s = params.value[3] % 60; //不足60的就是秒，够60就是分
                        return currentData.yAxis[params.value[0]] + '<br/>' + params.marker + params
                            .name + ': ' + h + 'h ' + m + 'm ' + s + 's' +
                            '<br/>' +
                            '开始时间：' + that.tools.formatDate(params.value[1],
                                'yyyy-MM-dd hh:mm:ss') +
                            '<br/>' + '结束时间：' + that.tools.formatDate(params.value[2],
                                'yyyy-MM-dd hh:mm:ss')

                    },
                    // int h = sec / 3600;
                    // int m = sec % 3600 / 60;
                    // int s = sec % 60; //不足60的就是秒，够60就是分
                    // System.out.println(h + "小时" + m + "分钟" + s + "秒");
                },
                dataZoom: [{
                    type: 'slider',
                    filterMode: 'weakFilter',
                    showDataShadow: false,
                    top: 400,
                    // start: 0,
                    // end: 50,
                    handleSize: 8,
                    labelFormatter: ''
                }, {
                    type: 'inside',
                    filterMode: 'weakFilter',
                    // start: 0,
                    // end: 50
                }],
                title: {
                    text: '灰色代表停机状态；绿色代表负载运行状态；红色代表故障状态；黄色代表待机状态',
                    left: 'center',
                    top: '10'
                },
                grid: {
                    height: 300,
                },
                // x轴会动态将日期格式化
                xAxis: {
                    min: startTime,
                    scale: true,
                    //  axisLabel: {
                    //     interval: 0,
                    //     color: '#869da7',
                    //     fontSize: 10,
                    //     rotate: 40,
                    // },
                    axisLabel: {
                        // rotate: 40,
                        formatter: function (val) {
                            console.log(that.tools.formatDate(val, 'yyyy-MM-dd hh:mm:ss'))
                            // console.log(startTime/1000, val/1000)
                            // 将日期进行整合处理
                            // console.log(Math.max(0, val - startTime) / 1000)
                            // console.log(new Date(val))
                            return that.tools.formatDate(val, 'MM-dd hh:mm')

                            // return Math.max(0, val - startTime) / 1000 + ' s'
                        },
                    },
                },
                yAxis: {
                    data: categories,
                    axisLabel: {
                        fontSize: 14,
                        // fontWeight: 'bolder' //字体加粗
                    }

                },
                series: [{
                    type: 'custom',
                    renderItem: renderItem,
                    //指定x映射的是data数组中每一个元素中value的第几个值
                    //将值收集起来并且进行统一范围处理  比如上面的data中有30个值就会把30个值收集然后进行10s的一个间隔进行区间处理生成x轴
                    encode: {
                        x: [1, 2],
                        y: 0,
                    },
                    data: currentData.data,
                }],
            })
        },
        changeTimeSliceEquipmentValue(val) {
            // console.log(val)
        },

        timeSliceQuery() {
            // if (this.timeSlice != null) {
            //     echarts.init(document.getElementById('timeSlice')).dispose(); //销毁echarts
            //     this.timeSlice = null
            // }
            // if (this.timeSlice3 != null) {
            //     echarts.init(document.getElementById('timeSlice3')).dispose(); //销毁echarts
            //     this.timeSlice3 = null;
            // }
            if (this.timeStatusTypeValue == 1) {
                echarts.init(document.getElementById('timeSlice')).dispose(); //销毁echarts
                // this.timeSlice = null;
                this.equipmentTimeSlice(); //统计时间状态分析
            } else {
                echarts.init(document.getElementById('timeSlice3')).dispose(); //销毁echarts
                // this.timeSlice3 = null;
                this.equipmentTimeStatus(); //实时报警

            }
        },
        timeSliceQuery2() {
            this.equipmentTimeSlice2();
        },
        async equipmentTimeSlice2() {
            var param = {
                equipmentID: String(this.currentTreeId),
                // equipmentID: '100001',
                endDate: this.sliceTime2[1],
                startDate: this.sliceTime2[0],
                timeStatusType: this.timeSliceType
            };
            const selectRes = await equipmentTimeSlice(param);
            this.timeSliceTitle = selectRes.menu;
            var LineB = {
                legend: {
                    bottom: '6%', //距离下边距
                    data: []
                },
                series: []
            }
            for (var i = 0; i < selectRes.data.length; i++) {
                var testData = {
                    name: '',
                    type: 'bar',
                    // id: '666', //柱的id
                    stack: 'total',
                    barWidth: 180, //柱的宽度
                    label: {
                        show: false
                    },
                    emphasis: { //高亮显示
                        focus: 'series'
                    },
                    data: []
                }
                testData.name = selectRes.data[i].name;
                // value
                LineB.legend.data[i] = selectRes.data[i].name;
                testData.data[0] = selectRes.data[i].value;
                if (selectRes.data[i].timeStatusType) {
                    testData.id = selectRes.data[i].timeStatusType
                }

                LineB.series[i] = testData;
                console.log(LineB)
            }
            // console.log(LineB)
            this.drawTimeSlice2(LineB)

        },
        async equipmentTimeSlice3() {
            var param = {
                equipmentID: String(this.currentTreeId),
                // equipmentID: '100001',
                endDate: this.sliceTime2[1],
                startDate: this.sliceTime2[0],
                timeStatusType: this.timeSliceType
            };
            const selectRes = await equipmentTimeSlice(param);
            // timeSliceTitle2
            this.timeSliceTitle2 = selectRes.menu;
            var LineB = {
                legend: {
                    bottom: '6%', //距离下边距
                    data: []
                },
                series: []
            }
            for (var i = 0; i < selectRes.data.length; i++) {
                var testData = {
                    name: '',
                    type: 'bar',
                    // id: '666', //柱的id
                    stack: 'total',
                    barWidth: 180, //柱的宽度
                    label: {
                        show: false
                    },
                    emphasis: { //高亮显示
                        focus: 'series'
                    },
                    data: []
                }
                testData.name = selectRes.data[i].name;
                // value
                LineB.legend.data[i] = selectRes.data[i].name;
                testData.data[0] = selectRes.data[i].value;
                // if (selectRes.data[i].timeStatusType) {
                //     testData.id = selectRes.data[i].timeStatusType
                // }
                LineB.series[i] = testData;
            }
            console.log(LineB)
            this.drawTimeSlice4(LineB)

        },
        showDialog() {
            this.dialogTableVisible = true;
            this.equipmentTimeSliceAnalysis();
        },
        async equipmentTimeSliceAnalysis() {
            var param = {
                equipmentID: this.currentTreeId,
                endDate: this.stateTime[1],
                startDate: this.stateTime[0],
                dateType: this.stateTimeRange
            };
            const selectRes = await equipmentTimeSliceAnalysis(param);
            var data = {
                legend: {
                    data: ['待机状态', '负载状态', '故障状态', '停机状态', '非计划停机状态', '计划停机状态'],
                    bottom: '3%',
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                series: [{
                    name: '待机状态',
                    type: 'line',
                    // stack: '总量',
                    data: []
                },
                {
                    name: '负载状态',
                    type: 'line',
                    // stack: '总量',
                    data: []
                },
                {
                    name: '故障状态',
                    type: 'line',
                    // stack: '总量',
                    data: []
                },
                {
                    name: '停机状态',
                    type: 'line',
                    // stack: '总量',
                    data: []
                },
                {
                    name: '非计划停机状态',
                    type: 'line',
                    // stack: '总量',
                    data: []
                },
                {
                    name: '计划停机状态',
                    type: 'line',
                    // stack: '总量',
                    data: []
                }
                ]

            }
            data.xAxis.data = selectRes.data.time;
            data.series[0].data = selectRes.data.WaitStatus
            data.series[1].data = selectRes.data.LoadingStatus
            data.series[2].data = selectRes.data.FaultStatus
            data.series[3].data = selectRes.data.StopStatus
            data.series[4].data = selectRes.data.PlannedStopStatus
            data.series[5].data = selectRes.data.UnplannedStopStatus
            this.drawShapeEchartsBar(data);
        },
        timeSliceAnalysisQuery() {
            this.equipmentTimeSliceAnalysis();
        },
        // KPI--开始
        async equipmentKpi() {
            this.equipmentKPIList = [];
            var param = {
                equipmentID: this.currentTreeId,
                // startDate: this.timeKPI[0].getTime(),
                // endDate: this.timeKPI[1].getTime(),
                startDate: this.timeKPI[0],
                endDate: this.timeKPI[1],
                dateType: this.dataFrame2Value,
                typeKpi: 0
            };
            const selectRes = await equipmentKpi(param);
            var data = []
            for (var i = 0; i < selectRes.data.length; i++) {
                var currentData = {
                    name: '',
                    min: '',
                    max: '',
                    value: '',
                    id: 'echartsGauge' + i
                }
                currentData.name = selectRes.data[i].name;
                currentData.min = selectRes.data[i].min;
                currentData.max = selectRes.data[i].max;
                currentData.value = selectRes.data[i].value;

                data.push(currentData)
            }
            this.equipmentKPIList = data
            setTimeout(() => {
                this.drawShapeEchartsGauge(data.slice(0, 6));
            }, 100)
        },
        echartsEquipmentKPI(val) {
            this.drawShapeEchartsGauge(this.equipmentKPIList.slice(val * 5, val * 5 + 5));
        },
        changeTimeKPI() {
            // console.log(this.timeKPI);
            this.equipmentKpi()
        },
        changeDataFrame2Value() {
            // console.log(this.dataFrame2Value);
            this.equipmentKpi()
        },
        async equipmentKpiAnalysis() {
            var param = {
                equipmentID: this.currentTreeId,
                startDate: this.timeKPI2[0],
                endDate: this.timeKPI2[1],
                startDate: this.tools.formatDate(this.timeKPI2[0], 'yyyy-MM-ddThh:mm:ss'),
                endDate: this.tools.formatDate(this.timeKPI2[1], 'yyyy-MM-ddThh:mm:ss'),
                dateType: this.dataFrame2Value2
            };
            if (this.kpiArValue.length != 0) {
                param.typeKpi = this.kpiArValue.join(',');
            }
            const selectRes = await equipmentKpiAnalysis(param);
            var testData = selectRes.data;
            var LineA = {
                legend: {
                    // data: ['oee', 'kjl', 'lyl', 'zhl'],
                    data: [],
                    top: '2%',
                    textStyle: {
                        color: '#808080',
                        fontSize: 12,

                    }
                },
                xAxis: {
                    type: 'category',
                    // data: ['1日', '2日', '3日', '4日', '5', '6', '7', '8'],
                    data: [],
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#869da7',
                        }
                    },
                    axisLabel: {
                        // interval: 'auto',//x轴间距自动
                        color: '#808080',
                        fontSize: 12,
                        rotate: 0,
                    },
                },
                series: []
            };
            LineA.xAxis.data = testData.time;
            for (var i = 0; i < testData.dataList.length; i++) {
                var curObj = {
                    // name: 'oee',
                    // data: [],
                    type: 'bar',
                    barGap: 0,
                    barMaxWidth: 15
                    // barWidth: 10, //柱的宽度
                };
                curObj.name = testData.dataList[i].name;
                curObj.data = testData.dataList[i].list;
                LineA.series[i] = curObj;
                LineA.legend.data[i] = testData.dataList[i].name
            }
            // console.log(LineA)
            this.drawShapeBar_KPI(LineA)
        },
        async equipmentKpiSelect() {
            var param = {
                // equipmentID: '100001'
                equipmentID: this.currentTreeId,
            }
            const selectRes = await equipmentKpiSelect(param);
            this.kpiArr = selectRes.data.KPI
        },
        changeDataFrame2Value2() {
            this.equipmentKpiAnalysis();
        },
        changeTimeKPI2() {
            this.equipmentKpiAnalysis();
        },
        changeKpiArValue() {
            // console.log(this.kpiArValue.join(','))
            this.equipmentKpiAnalysis();
        },

        // 报警信息--开始
        async equipmentAlarmInfoStatistics() {
            this.alarmInfo = []
            var param = {
                equipmentID: this.currentTreeId
            };
            if (this.alarmTypeValue.length != 0) {
                param.alarmType = this.alarmTypeValue.join(',');
            }
            if (this.alarmLevelValue.length != 0) {
                console.log(this.alarmLevelValue)
                param.alarmLevel = this.alarmLevelValue.join(',');
            }
            const selectRes = await equipmentAlarmInfoStatistics(param);
            this.alarmInfo = selectRes.data.list;

            this.alarmNum = selectRes.data.predictScore;
            console.log(this.alarmInfo, 'weq');
        },
        // 历史报警信息统计
        async equipmentAlarmInfoHistoryStatistics() {
            this.alarmInfo = []
            var param = {
                equipmentID: this.currentTreeId
            };
            if (this.alarmTime != null) {
                param.startDate = this.alarmTime[0];
                param.endDate = this.alarmTime[1];
            }
            if (this.alarmTypeValue.length != 0) {
                param.alarmType = this.alarmTypeValue.join(',');
            }
            if (this.alarmLevelValue.length != 0) {
                param.alarmLevel = this.alarmLevelValue.join(',');
            }
            const selectRes = await equipmentAlarmInfoHistoryStatistics(param);
            this.alarmInfo = selectRes.data.list;
        },
        changeAlarmInfo() { },
        async equipmentAlarmInfoRealTableApi() {
            this.alarmInfoList = [];
            var param = {
                equipmentID: this.currentTreeId,
                // equipmentID: '400064',
            };
            if (this.alarmTypeValue.length != 0) {
                param.alarmType = this.alarmTypeValue.join(',');
            }
            if (this.alarmLevelValue.length != 0) {
                param.alarmLevel = this.alarmLevelValue.join(',');
            }
            if (this.alarmAckValue.length != 0) {
                param.acknowledgeStatus = this.alarmAckValue.join(',');
            }
            const selectRes = await equipmentAlarmInfoRealTable(param);
            this.alarmInfoList = selectRes.data
        },
        // 实时报警确认选择框 
        async equipmentAlarmAcknowledgeStatus() {
            var param = {
                equipmentID: this.currentTreeId,
            };
            const selectRes = await equipmentAlarmAcknowledgeStatus(param);
            this.alarmAckArray = selectRes.data;
        },
        // 历史报警确认选择框 
        async equipmentHistoryAlarmAcknowledgeStatus() {
            var param = {
                equipmentID: this.currentTreeId,
            };
            const selectRes = await equipmentHistoryAlarmAcknowledgeStatus(param);
            this.alarmAckArray = selectRes.data;
        },
        async equipmentAlarmInfoHistoryTable() {
            this.alarmInfoList = [];
            var param = {
                equipmentID: this.currentTreeId,
                pageSize: this.pageSize,
                pageNumber: this.currentPage,
                // alarmType:'性能报警',
                // alarmLevel:'1'
            };
            if (this.alarmTime != null) {
                param.startDate = this.alarmTime[0];
                param.endDate = this.alarmTime[1];
            }
            if (this.alarmTypeValue.length != 0) {
                param.alarmType = this.alarmTypeValue.join(',');
            }
            if (this.alarmLevelValue.length != 0) {
                param.alarmLevel = this.alarmLevelValue.join(',');
            }
            if (this.alarmAckValue.length != 0) {
                param.acknowledgeStatus = this.alarmAckValue.join(',');
            }
            const selectRes = await equipmentAlarmInfoHistoryTable(param);
            this.alarmInfoList = selectRes.data;
            this.pageQuantity = selectRes.pageQuantity;
        },
        handleCurrentChangeHisAlarm() {
            this.equipmentAlarmInfoHistoryTable();
        },
        async equipmentAlarmInfoLevelStatistics() {
            var param = {
                equipmentID: this.currentTreeId,
                type: this.alarmIndex
            };
            if (this.alarmIndex == 2 && this.alarmTime != null) {
                param.startDate = this.alarmTime[0];
                param.endDate = this.alarmTime[1];
            }
            if (this.alarmTypeValue.length != 0) {
                param.alarmType = this.alarmTypeValue.join(',');
            }
            if (this.alarmLevelValue.length != 0) {
                param.alarmLevel = this.alarmLevelValue.join(',');
            }
            const selectRes = await equipmentAlarmInfoLevelStatistics(param);
            // console.log(selectRes.data)
            var data = selectRes.data;
            var totle = 0;
            for (var i = 0; i < selectRes.data.length; i++) {
                totle += data[i].value
            }
            console.log('this', this)
            this.drawShapePie_alert(data, totle);

        },
        async equipmentAlarmTypeSelect() {
            var param = {
                equipmentID: this.currentTreeId,
            };

            if (this.alarmIndex == 2) {
                const selectRes = await equipmentHistoryAlarmTypeSelect(param);
                this.alarmType = selectRes.data.alarmType;
            } else {
                const selectRes = await equipmentAlarmTypeSelect(param);
                this.alarmType = selectRes.data.alarmType;
            }

        },
        async equipmentAlarmLevelSelect() {
            var param = {
                equipmentID: this.currentTreeId,
            };
            if (this.alarmIndex == 2) {
                const selectRes = await equipmentHistoryAlarmLevelSelect(param);
                this.alarmLevelArray = selectRes.data.alarmLevel;
            } else {
                const selectRes = await equipmentAlarmLevelSelect(param);
                this.alarmLevelArray = selectRes.data.alarmLevel;
            }

        },
        async equipmentHistoryAlarmTypeSelect() {
            var param = {
                equipmentID: this.currentTreeId,
            };

            const selectRes = await equipmentHistoryAlarmTypeSelect(param);
            this.alarmType = selectRes.data.alarmType;
        },
        async equipmentHistoryAlarmLevelSelect() {
            var param = {
                equipmentID: this.currentTreeId,
            };
            const selectRes = await equipmentHistoryAlarmLevelSelect(param);
            this.alarmLevelArray = selectRes.data.alarmLevel;
        },
        selectAlarm() {
            this.currentPage = 1;
            if (this.alarmIndex == 1) {
                this.equipmentAlarmInfoStatistics();
                this.equipmentAlarmInfoRealTableApi();
                this.equipmentAlarmInfoLevelStatistics();
            } else {
                this.equipmentAlarmInfoHistoryStatistics();
                this.equipmentAlarmInfoHistoryTable();
                this.equipmentAlarmInfoLevelStatistics();
            }
        },
        changAlarm(index) {
            console.log(index)
            this.alarmInfoList = [];
            this.alarmIndex = index;
            this.alarmTypeValue = '';
            this.alarmLevelValue = '';
            this.equipmentAlarmTypeSelect();
            this.equipmentAlarmLevelSelect();
            if (index == 1) {
                this.equipmentAlarmInfoStatistics();
                this.equipmentAlarmInfoRealTableApi();
                this.equipmentAlarmInfoLevelStatistics();
                this.equipmentAlarmAcknowledgeStatus();
            } else {
                this.equipmentAlarmInfoHistoryStatistics();
                this.equipmentAlarmInfoHistoryTable();
                this.equipmentAlarmInfoLevelStatistics();
                this.equipmentHistoryAlarmAcknowledgeStatus();
            }
        },
        async confirmALLAlarm() {
            this.$confirm('此操作为确认全部报警, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.equipmentAlarmInfoAcknowledge(2);
                // this.$message({
                //     type: 'success',
                //     message: '报警成功!'
                // });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报警'
                });
            });
        },
        async confirmAlarm(row) {
            this.$confirm('此操作为确认报警, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.equipmentAlarmInfoAcknowledge(1, row);
                // this.$message({
                //     type: 'success',
                //     message: '报警成功!'
                // });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报警'
                });
            });

        },
        async equipmentAlarmInfoAcknowledge(id, row) {
            var param = {
                ackType: id,
                equipmentID: this.currentTreeId
            }
            param.message = "确认报警";
            if (id == 2) { //全部
                if (this.alarmTypeValue.length != 0) {
                    param.alarmType = this.alarmTypeValue.join(',');
                }
                if (this.alarmLevelValue.length != 0) {
                    param.alarmLevel = this.alarmLevelValue.join(',');
                }
            }
            if (id == 1) { //单个报警

                if (row != undefined) {
                    // param.propertyName = row.sourceProperty;
                    // param.alarmType = row.alarmType;
                    // param.alarmLevel = row.alarmLevel;
                    var acknowledgeCondition = {
                        alarmName: row.alarmName,
                        alarmTime: row.alarmTime,
                        sourceProperty: row.sourceProperty
                    };
                    param.AcknowledgeCondition = acknowledgeCondition;
                }
            }
            const selectRes = await equipmentAlarmInfoAcknowledge(param);
            if (selectRes.data.result) {
                this.$message({
                    type: 'success',
                    message: selectRes.message
                });
            } else {
                this.$message.error(selectRes.message);
            }

            this.equipmentAlarmInfoRealTableApi();
        },

        // 报警信息--结束

        // 点检信息--开始equipmentPointInspectioStatistics
        async equipmentPointInspectioStatistics() {
            var param = {
                equipmentID: this.currentTreeId
            }
            const selectRes = await equipmentPointInspectioStatistics(param);
            this.spotCheckInfo = selectRes.data;
            this.standardList = selectRes.data.standard;
        },
        async equipmentPointInspectioRecordTable() {
            var param = {
                equipmentID: this.currentTreeId,
                // pageSize: this.pageSize,
                // PageNumber: this.currentPage
                // pointInspectioType: '1',
                // startDate: this.spotCheckTime[0],
                // endDate: this.spotCheckTime[1]
            }
            if (this.spotCheckValue != null && this.spotCheckValue != "") {
                param.pointInspectioType = this.spotCheckValue;
            }
            if (this.spotCheckTime != null) {
                param.startDate = this.spotCheckTime[0];
                param.endDate = this.spotCheckTime[1];
            }
            const selectRes = await equipmentPointInspectioRecordTable(param);
            this.spotCheckList = selectRes.data;
            // this.pageQuantity = selectRes.data.pageQuantity;
        },
        async equipmentPointInspectioTypeSelect() {
            const selectRes = await equipmentPointInspectioTypeSelect();
            this.spotCheckArray = selectRes.data.PointInspectioType
        },
        selectSpotCheck() {
            this.equipmentPointInspectioRecordTable();
        },
        recordDetail(id) {
            // console.log(id)
            this.recordDialog = true;
            this.equipmentPointInspectioDetailRecord(id);
        },
        async equipmentPointInspectioDetailRecord(id) {
            var param = {
                equipmentID: this.currentTreeId,
                pointInspectioNumber: id
            }
            const selectRes = await equipmentPointInspectioDetailRecord(param);
            this.spotCheckListInfo = selectRes.data
        },
        showLastNormal() { //上次点检异常弹框
            // this.lastNormalDialog = true
        },
        // 点检信息--结束

        // 保养履历--开始
        async equipmentMaintainStatistics() {
            var param = {
                equipmentID: this.currentTreeId
            }
            const selectRes = await equipmentMaintainStatistics(param);
            this.maintainInfo = selectRes.data;
        },
        async equipmentMaintainRecordTable() {
            var param = {
                equipmentID: this.currentTreeId,
                pageSize: this.pageSize,
                pageNumber: this.currentPage
                // maintainType:'1',
                // startDate:'',
                // endDate:''
            }
            if (this.maintainTypeValue != null && this.maintainTypeValue != "") {
                param.maintainType = this.maintainTypeValue;
            }
            if (this.maintainTime != null) {
                param.startDate = this.maintainTime[0];
                param.endDate = this.maintainTime[1]
            }
            const selectRes = await equipmentMaintainRecordTable(param);
            this.maintainInfoList = selectRes.data.list;
            this.pageQuantity = selectRes.data.pageQuantity;
        },
        async equipmentMaintainTypeSelect() {
            const selectRes = await equipmentMaintainTypeSelect();
            this.maintainTypeArray = selectRes.data.MaintainType
        },
        handleCurrentChange(val) {
            this.PageNumber = val;
            this.equipmentMaintainRecordTable(); //查询table数据的方法
        },
        selectMaintain() {
            this.currentPage = 1;
            this.equipmentMaintainRecordTable();
        },
        maintainDetail(id) {
            this.maintainDialog = true;
            this.equipmentMaintainDetailRecord(id);
        },
        async equipmentMaintainDetailRecord(id) {
            var param = {
                maintainNumber: id
            }
            const selectRes = await equipmentMaintainDetailRecord(param);
            this.maintainInfoDetail = selectRes.data;
        },

        // 保养履历--结束

        // 维修履历-开始
        async equipmentRepairStatistics() {
            var param = {
                equipmentID: this.currentTreeId
            }
            const selectRes = await equipmentRepairStatistics(param);
            this.repairInfo = selectRes.data;
            this.drawPie_repair(selectRes.data.list);
        },
        async equipmentRepairRecordTable() {
            var param = {
                equipmentID: this.currentTreeId,
                // equipmentID: '100001',
                pageSize: this.pageSize,
                pageNumber: this.currentPage,
                //  repairType:'1'
                // startDate
                // endDate
            }
            if (this.repairTypeValue != null && this.repairTypeValue != "") {
                param.repairType = this.repairTypeValue;
            }
            if (this.repairTime != null) {
                param.startDate = this.repairTime[0];
                param.endDate = this.repairTime[1];
            }
            const selectRes = await equipmentRepairRecordTable(param);
            this.repairInfoList = selectRes.data.list;
        },
        async equipmentRepairTypeSelect() {
            const selectRes = await equipmentRepairTypeSelect();
            this.repairTypeArray = selectRes.data.RepairType;
            // this.repairTypeValue = selectRes.data.RepairType[0].id
        },
        repairSelect() {
            this.currentPage = 1;
            this.equipmentRepairRecordTable();
        },
        handleCurrentChangeRepair(val) {
            this.PageNumber = val;
            this.equipmentRepairRecordTable(); //查询table数据的方法
        },
        repairDetail(id) {
            this.repairDialog = true;
            this.equipmentRepairDetailRecord(id);
        },
        async equipmentRepairDetailRecord(id) {
            var param = {
                repairNumber: id
                // repairNumber: '2167293e-ca6b-af33-174a-391d27811f89'
            }
            const selectRes = await equipmentRepairDetailRecord(param);
            this.repairInfoDetail = selectRes.data;
        },
        // 维修履历-结束

        // 资料文档--开始
        async equipmentDocumentRecord() {
            var param = {
                equipmentID: this.currentTreeId,
                // equipmentID: '100004',
                documentType: this.leftTabIndex,
                limitValue: this.pageSize,
                offsetValue: this.currentPage
            }
            const selectRes = await equipmentDocumentRecord(param);
            this.pageQuantity = selectRes.pageQuantity;
            this.documentList = selectRes.data.documentList;
        },



        handleCurrentChangeDocument(val) {
            this.PageNumber = val;
            this.equipmentDocumentRecord(); //查询table数据的方法
        },
        async equipmentDocumentTypeSelect() {
            const selectRes = await equipmentDocumentTypeSelect();
            this.left_tab = selectRes.data.DocumentType;
        },
        downloadFiles(row) {
            // console.log(row)
            // var api = 'http://121.37.181.242:8080';
            // var kpi = '?appKey=e814dc0d-b4b1-4c15-ae72-a749413b1d93';
            var path = 'https://iot.sdkjiot.com' + row.path + '?appKey=' + this.appKey;
            console.log(path)
            var a = document.createElement('a');
            a.href = path;
            document.body.appendChild(a);
            // a.target = "_blank"
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(path);
            //下载
            // fetch(path).then(res => res.blob().then(blob => {
            //     console.log(blob)
            // let blob1 = new Blob([blob], {
            //     type: 'text/plain'
            // })
            // const link = document.createElement("a"); //自己创建的a标签
            // link.href = res.path;
            // link.click();
            // document.body.removeChild(link);
            // window.URL.revokeObjectURL(res.path);
            // var filename = row.fileName + '.' + row.format;
            // a.download = filename;
            // a.click();
            // window.URL.revokeObjectURL(url);
            // }))
            // .catch(error => {
            //     console.log('Request Failed', error)
            // });
        },

        leftTabChange(id) {
            this.leftTabIndex = id;
            this.currentPage = 1;
            this.equipmentDocumentRecord();
        },
        // 资料文档--结束

        // 备件信息--开始
        async equipmentSparePartTable() {
            var param = {
                equipmentID: this.currentTreeId,
                // equipmentID: '100001',
                pageNumber: this.currentPage,
                pageSize: this.pageSize
                // sparePartType:1
            }
            if (this.spareTypeValue != null && this.spareTypeValue != "") {
                param.sparePartType = this.spareTypeValue;
            }
            const selectRes = await equipmentSparePartTable(param);
            this.sparePartData = selectRes.rows
            this.pageQuantity = selectRes.pageQuantity

        },
        changeSpareTypeValue() {
            this.currentPage = 1,
                this.equipmentSparePartTable();
        },
        async equipmentSparePartTypeSelect() {
            var param = {
                userID: this.userID
            }
            const selectRes = await equipmentSparePartTypeSelect(param);
            this.spareTypeArray = selectRes.data;
        },
        handleCurrentChangeSpare() {
            this.equipmentSparePartTable()
        },
        // 备件信息--结束

        drawShapeechartsLine(data) {
            var that = this;
            echarts.init(document.getElementById('echartsLine')).dispose(); //销毁echarts
            that.echartsLine = echarts.init(document.getElementById('echartsLine'));
            that.echartsLine.setOption({
                title: {},
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                dataZoom: [{
                    type: 'slider',
                    filterMode: 'weakFilter',
                    showDataShadow: false,
                    handleSize: 8,
                    labelFormatter: ''
                }, {
                    type: 'inside',
                    filterMode: 'weakFilter',
                }],
                // 
                // legend: data.legend,
                legend: {
                    data: data.legend,
                    top: '0%',
                },
                toolbox: {
                    // feature: {
                    //     saveAsImage: {}
                    // }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '14%',
                    top: '5%',
                    containLabel: true
                },
                // xAxis: data.xAxis,
                xAxis: [{
                    type: "category",
                    data: data.xAxis,
                    // xAxis,
                    // axisLabel: {
                    //     formatter: function (value) {
                    //         console.log(value)
                    //         console.log(that.tools.formatDate(value,'hh:mm'))
                    //         return that.tools.formatDate(value, 'yyyy-MM-dd hh:mm:ss');
                    //     }
                    // }
                }],
                yAxis: [{
                    type: 'value'
                }],
                series: data.series

            });
            that.echartsLine.hideLoading()
        },
        drawShapeEchartsBar(data) {
            // // 状态分析--开始
            this.echartsBar = echarts.init(document.getElementById('echartsBar'));
            this.echartsBar.setOption({
                // title: {
                //     text: '折线图堆叠'
                // },
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        align: 'left'
                    },
                },
                legend: data.legend,
                // legend: {
                //     data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎'],
                //     bottom:'3%',
                // },
                grid: {
                    left: '3%',
                    right: '4%',
                    top: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                toolbox: {
                    // feature: {
                    //     saveAsImage: {}
                    // }
                },
                xAxis: data.xAxis,
                // xAxis: {
                //     type: 'category',
                //     boundaryGap: false,
                //     data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
                // },
                yAxis: {
                    type: 'value'
                },
                series: data.series,
            });
        },
        changeTab(id) {
            console.log(id)
            if (this.tabelTimer != null) {
                clearInterval(this.tabelTimer);
            }
            this.currentPage = 1;
            if (this.timeStatusTypeValue == 1) {
                if (this.timeSlice != null) {
                    echarts.init(document.getElementById('timeSlice')).dispose(); //销毁echarts
                    this.timeSlice = null
                }
            } else {
                //实时状态
                if (this.timeSlice3 != null) {
                    echarts.init(document.getElementById('timeSlice3')).dispose(); //销毁echarts
                    this.timeSlice3 = null;
                }
            }
            if (this.echartsGauge1 != null) {
                for (var i = 0; i < this.equipmentKPIList.length; i++) {
                    echarts.init(document.getElementById(this.equipmentKPIList[i].id)).dispose(); //销毁echarts
                }
                // echarts.init(document.getElementById('echartsGauge2')).dispose(); //销毁echarts
                // echarts.init(document.getElementById('echartsGauge3')).dispose(); //销毁echarts
                // echarts.init(document.getElementById('echartsGauge4')).dispose(); //销毁echarts
                // echarts.init(document.getElementById('echartsGauge5')).dispose(); //销毁echarts
                echarts.init(document.getElementById('bar_KPI')).dispose(); //销毁echarts
                this.echartsGauge1 = null;
                // this.echartsGauge2 = null;
                // this.echartsGauge3 = null;
                // this.echartsGauge4 = null;
                // this.echartsGauge5 = null;
            }
            if (this.pie_alert != null) {
                echarts.init(document.getElementById('pie_alert')).dispose(); //销毁echarts
                this.pie_alert = null
            }
            if (this.pie_repair != null) {
                echarts.init(document.getElementById('pie_repair')).dispose(); //销毁echarts
                this.pie_repair = null
            }
            if (typeof (id) == 'undefined') {
                id = 1
                console.log('我是1', id);
            }
            this.tabIndex = id;
            // if (this.tabIndex == 0) { //基本信息
            //     this.equipmentBasicInfo();
            // } else 
            if (this.tabIndex == 1) { //设备属性
                this.equipmentPropertyType();
                this.equipmentAlarmInfoLevelStatistics();
                this.equipmentProperty();
            }
            // else if (this.tabIndex == 2) { //时间片
            //     if (this.timeStatusTypeValue == 1) {
            //         this.equipmentTimeSlice();
            //     } else {
            //         this.equipmentTimeStatus(); //实时状态
            //     }
            // } else if (this.tabIndex == 3) { //KPI
            //     this.equipmentKpi();
            //     this.equipmentKpiAnalysis();
            //     this.equipmentKpiSelect();
            // } 
            else if (this.tabIndex == 4) { //报警信息
                this.equipmentAlarmInfoStatistics();
                this.equipmentAlarmInfoRealTableApi();
                this.equipmentAlarmInfoLevelStatistics();
                this.equipmentAlarmTypeSelect();
                this.equipmentAlarmLevelSelect();
                this.equipmentAlarmAcknowledgeStatus();
            }
            // else if (this.tabIndex == 5) { //点检信息
            //     this.equipmentPointInspectioStatistics();
            //     this.equipmentPointInspectioRecordTable();
            //     this.equipmentPointInspectioTypeSelect();
            // } 
            else if (this.tabIndex == 6) { //保养履历
                this.equipmentMaintainStatistics();
                this.equipmentMaintainRecordTable();
                this.equipmentMaintainTypeSelect();
                this.equipmentAlarmInfoLevelStatistics();
            } else if (this.tabIndex == 7) { //维修履历
                this.equipmentRepairTypeSelect();
                this.equipmentRepairStatistics();
                this.equipmentRepairRecordTable();
                this.equipmentAlarmInfoLevelStatistics();
            } else if (this.tabIndex == 8) { //资料文档
                this.equipmentDocumentRecord();
                this.equipmentDocumentTypeSelect();
                this.equipmentAlarmInfoLevelStatistics();
            }
            // else if (this.tabIndex == 9) {//备件
            //     this.equipmentSparePartTable();
            //     this.equipmentSparePartTypeSelect();
            // }
        },
        // 树--关键字搜索
        filterNode(value, data) {

            // if (!value) return true;
            // return data.Name.indexOf(value) !== -1;
            if (!value) {
                //  console.log(value, data)
                return true
            }
            if (data.Type == 'E') {
                return data.Name.indexOf(value) !== -1;
            }
        },
        handleNodeClick(node) {
            console.log(node.children)
            // this.equipmentProperty()
            if (!node.children) {
                if (this.tabelTimer != null) {
                    clearInterval(this.tabelTimer);
                }
                // 销毁echaers
                if (this.timeStatusTypeValue == 1) {
                    if (this.timeSlice != null) {
                        echarts.init(document.getElementById('timeSlice')).dispose(); //销毁echarts
                        this.timeSlice = null
                    }
                } else {
                    //实时状态
                    if (this.timeSlice3 != null) {
                        echarts.init(document.getElementById('timeSlice3')).dispose(); //销毁echarts
                        this.timeSlice3 = null;
                    }
                }
                if (this.echartsGauge1 != null) {
                    echarts.init(document.getElementById('echartsGauge1')).dispose(); //销毁echarts
                    // echarts.init(document.getElementById('echartsGauge2')).dispose(); //销毁echarts
                    // echarts.init(document.getElementById('echartsGauge3')).dispose(); //销毁echarts
                    // echarts.init(document.getElementById('echartsGauge4')).dispose(); //销毁echarts
                    // echarts.init(document.getElementById('echartsGauge5')).dispose(); //销毁echarts
                    echarts.init(document.getElementById('bar_KPI')).dispose(); //销毁echarts
                    this.echartsGauge1 = null;
                    this.echartsGauge2 = null;
                    this.echartsGauge3 = null;
                    this.echartsGauge4 = null;
                    this.echartsGauge5 = null;
                }
                if (this.pie_alert != null) {
                    echarts.init(document.getElementById('pie_alert')).dispose(); //销毁echarts
                    this.pie_alert = null
                }
                if (this.pie_repair != null) {
                    echarts.init(document.getElementById('pie_repair')).dispose(); //销毁echarts
                    this.pie_repair = null
                }
                this.propertyValue = [];
                this.currentTreeId = node.ID;
                this.currentTreeName = node.Name;
                this.equInfo = {
                    AvatarUrl1: ''
                };
                this.timeSliceEquipmentValue = [];
                this.equipmentStaticKPI();
                this.equipmentRealStatus();
                this.equipmentBasicInfo();
                this.equipmentAlarmInfoLevelStatistics();
                if (this.tabIndex == 4) { //报警信息
                    console.log("报警信息")
                    this.equipmentAlarmInfoStatistics();
                    this.equipmentAlarmInfoRealTableApi();
                    this.equipmentAlarmInfoLevelStatistics();
                    this.equipmentAlarmTypeSelect();
                    this.equipmentAlarmLevelSelect();
                    this.equipmentAlarmAcknowledgeStatus();
                }
                // else if (this.tabIndex == 5) { //点检信息
                //     this.equipmentPointInspectioStatistics();
                //     this.equipmentPointInspectioRecordTable();
                //     this.equipmentPointInspectioTypeSelect();
                // } 
                else if (this.tabIndex == 6) { //保养履历
                    this.equipmentMaintainStatistics();
                    this.equipmentMaintainRecordTable();
                    this.equipmentMaintainTypeSelect();
                } else if (this.tabIndex == 7) { //维修履历
                    this.equipmentRepairTypeSelect();
                    this.equipmentRepairStatistics();
                    this.equipmentRepairRecordTable();

                } else if (this.tabIndex == 8) { //资料文档
                    this.equipmentDocumentRecord();
                    this.equipmentDocumentTypeSelect();
                } else if (this.tabIndex == 1) {
                    this.equipmentProperty();
                }
            } else if (this.tabIndex == 4) { //报警信息
                console.log("报警信息")
                this.equipmentAlarmInfoStatistics();
                this.equipmentAlarmInfoRealTableApi();
                this.equipmentAlarmInfoLevelStatistics();
                this.equipmentAlarmTypeSelect();
                this.equipmentAlarmLevelSelect();
                this.equipmentAlarmAcknowledgeStatus();
            }
            // else if (this.tabIndex == 5) { //点检信息
            //     this.equipmentPointInspectioStatistics();
            //     this.equipmentPointInspectioRecordTable();
            //     this.equipmentPointInspectioTypeSelect();
            // } 
            else if (this.tabIndex == 6) { //保养履历
                this.equipmentMaintainStatistics();
                this.equipmentMaintainRecordTable();
                this.equipmentMaintainTypeSelect();
            } else if (this.tabIndex == 7) { //维修履历
                this.equipmentRepairTypeSelect();
                this.equipmentRepairStatistics();
                this.equipmentRepairRecordTable();

            } else if (this.tabIndex == 8) { //资料文档
                this.equipmentDocumentRecord();
                this.equipmentDocumentTypeSelect();
            }

            // else if (this.tabIndex == 9) {
            //     this.equipmentSparePartTable();
            //     this.equipmentSparePartTypeSelect();
            // }
            // this.equipmentPropertyType();
            // this.equipmentRealStatus();
        },
        drawShapeEcharts1(data) {
            this.echarts1 = echarts.init(document.getElementById('echarts1'));
            this.echarts1.showLoading({
                // text: '统计中，请稍候...',
                // maskColor: 'rgba(3,3,8,0.5)',
                // textColor: '#fff600'
            });
            this.echarts1.setOption({
                color: ['#F6C022', '#657798', '#62DAAB'],
                legend: {
                    itemGap: 10
                },
                series: [{
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    // center: ['50%', '50%'],
                    radius: '92%', //设置仪表盘的大小
                    pointer: { //仪表盘指针
                        show: false
                    },
                    progress: { //展示当前进度
                        show: true,
                        overlap: false, //多组数据时进度条是否重叠
                        roundCap: true, //显示进度是否有弧度
                        clip: false,
                        itemStyle: { //盘中进度样式
                            borderWidth: 2, //进度border-width
                            borderColor: '#E6E6E6'
                        }
                    },
                    axisLine: { //圆环的样式
                        lineStyle: {
                            width: 88, //宽度
                            // color:"#FFFFFF"
                            color: [ //设置仪表盘背景颜色
                                [1, '#E6E6E6']
                            ]
                        }
                    },
                    splitLine: { //圆环上的大刻度
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: { //圆环上的小刻度
                        show: false
                    },
                    axisLabel: { //大的刻度值
                        show: false,
                        distance: 50
                    },
                    data: data,
                    // [
                    //     // {
                    //     //     value: 80,
                    //     //     name: 'XXXX1:', //title
                    //     //     title: { //仪表盘标题
                    //     //         offsetCenter: ['-10%', '-15%'],
                    //     //         // color: '#d464ff',
                    //     //     },
                    //     //     detail: {
                    //     //         offsetCenter: ['20%', '-15%']
                    //     //     }
                    //     // },
                    //     // {
                    //     //     value: 75,
                    //     //     name: 'XXXX2:', //title
                    //     //     title: { //仪表盘标题
                    //     //         offsetCenter: ['-10%', '0%']
                    //     //     },
                    //     //     detail: {
                    //     //         offsetCenter: ['20%', '0%']
                    //     //     }
                    //     // },
                    //     // {
                    //     //     value: 90,
                    //     //     name: 'XXXX3:', //title
                    //     //     title: { //仪表盘标题
                    //     //         offsetCenter: ['-10%', '15%']
                    //     //     },
                    //     //     detail: {
                    //     //         offsetCenter: ['20%', '15%']
                    //     //     }
                    //     // }
                    // ],
                    title: { //title样式
                        fontSize: 12,
                        color: '#808080'
                    },
                    detail: { //仪表详情，用于显示数据
                        width: 20,
                        height: 10,
                        fontSize: 12,
                        color: 'auto', //百分比的演的
                        borderColor: '#FFFFFF', //显示的数值，boder颜色
                        borderRadius: 20, //borer的弧度
                        borderWidth: 1, //border宽度
                        formatter: '{value}%'
                    }
                }]
            });
            this.echarts1.hideLoading()

        },
        drawShapeEcharts2(data) {
            //柱状图--开始
            this.echarts2 = echarts.init(document.getElementById('echarts2'));
            this.echarts2.setOption({
                title: {},
                // 提示框
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: data.legend,
                    // top:'0%',
                    bottom: '2%',
                    textStyle: {
                        color: '#808080',
                        fontSize: 12,

                    }
                },
                color: ['#6395F9'],
                grid: {
                    left: '4%',
                    right: '6%',
                    bottom: '10%',
                    top: "10%",
                    containLabel: true
                },
                // dataset: {
                //     source: data
                // },
                xAxis: {
                    type: 'category',
                    data: data.xAxis
                },
                yAxis: {
                    type: 'value',
                    name: '秒',
                    nameRotate: '0.1',
                    splitLine: {
                        lineStyle: {
                            type: 'solid',
                            color: ['#E3E4E6']
                        }
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#869da7',
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        color: '#869da7',
                        fontSize: 12,
                        //  rotate:40  
                    }
                },
                // Declare several bar series, each will be mapped
                // to a column of dataset.source by default.
                series: data.series,
            });
            //  //柱状图--结束

        },

        drawShapeEchartsGauge(data) {
            //  仪表盘---开始
            for (var i = 0; i < data.length; i++) {
                this.echartsGauge1 = echarts.init(document.getElementById(data[i].id));
                this.echartsGauge1.setOption({
                    series: [{
                        type: 'gauge',
                        startAngle: 215, //表盘开始角度
                        endAngle: -35, //表盘结束角度
                        center: ['50%', '55%'],
                        radius: '78%', //设置仪表盘的大小
                        min: data[i].min,
                        max: data[i].max,
                        splitNumber: 8,
                        axisLine: {
                            lineStyle: { //表盘设置
                                width: 22,
                                color: [
                                    [0.25, '#6395F9'],
                                    [0.5, '#28D793'],
                                    [0.75, '#657798'],
                                    [1, '#F6BD16']
                                ]
                            }
                        },
                        pointer: {
                            length: '70%',
                            width: 10,
                            offsetCenter: [0, 0],
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisTick: { //表盘小刻度设置
                            show: false,
                            length: 0,
                            lineStyle: {
                                color: 'auto',
                                width: 2
                            }
                        },
                        splitLine: { //表盘大刻度设置
                            show: false,
                            length: 0,
                            lineStyle: {
                                color: 'auto',
                                width: 5
                            }
                        },
                        axisLabel: { //表盘文字
                            show: false,
                            color: '#808080',
                            fontSize: 14,
                            distance: -30, //文字距表盘的距离
                            formatter: function (value) {
                                if (value === 0.875) {
                                    return '优';
                                } else if (value === 0.625) {
                                    return '良';
                                } else if (value === 0.375) {
                                    return '中';
                                } else if (value === 0.125) {
                                    return '差';
                                }
                            }
                        },
                        title: { //表盘文字
                            offsetCenter: [0, '40%'],
                            fontSize: 14
                        },
                        detail: {
                            fontSize: 22,
                            offsetCenter: [0, '70%'],
                            valueAnimation: true,
                            formatter: '{value} %',
                            // formatter: function (value) {
                            //     // return Math.round(value * 100);
                            //     return value;
                            // },
                            // color: '#000000'
                            color: 'auto'
                        },
                        data: [{
                            value: data[i].value,
                            name: data[i].name,
                            // fontSize:20
                        }]
                    }]
                });

            }
            // this.echartsGauge2 = echarts.init(document.getElementById('echartsGauge2'));
            // this.echartsGauge2.setOption({
            //     series: [{
            //         type: 'gauge',
            //         startAngle: 215, //表盘开始角度
            //         endAngle: -35, //表盘结束角度
            //         center: ['50%', '55%'],
            //         radius: '78%', //设置仪表盘的大小
            //         min: data[1].min,
            //         max: data[1].max,
            //         splitNumber: 8,
            //         axisLine: {
            //             lineStyle: { //表盘设置
            //                 width: 22,
            //                 color: [
            //                     [0.25, '#6395F9'],
            //                     [0.5, '#28D793'],
            //                     [0.75, '#657798'],
            //                     [1, '#F6BD16']
            //                 ]
            //             }
            //         },
            //         pointer: {
            //             length: '70%',
            //             width: 10,
            //             offsetCenter: [0, 0],
            //             itemStyle: {
            //                 color: 'auto'
            //             }
            //         },
            //         axisTick: { //表盘小刻度设置
            //             length: 0,
            //             lineStyle: {
            //                 color: 'auto',
            //                 width: 2
            //             }
            //         },
            //         splitLine: { //表盘大刻度设置
            //             length: 0,
            //             lineStyle: {
            //                 color: 'auto',
            //                 width: 5
            //             }
            //         },
            //         axisLabel: { //表盘文字
            //             show: false,
            //             color: '#808080',
            //             fontSize: 14,
            //             distance: -30, //文字距表盘的距离
            //             formatter: function (value) {
            //                 if (value === 0.875) {
            //                     return '优';
            //                 } else if (value === 0.625) {
            //                     return '良';
            //                 } else if (value === 0.375) {
            //                     return '中';
            //                 } else if (value === 0.125) {
            //                     return '差';
            //                 }
            //             }
            //         },
            //         title: { //表盘文字
            //             offsetCenter: [0, '40%'],
            //             fontSize: 14
            //         },
            //         detail: {
            //             fontSize: 22,
            //             offsetCenter: [0, '70%'],
            //             valueAnimation: true,
            //             formatter: '{value} %',
            //             // formatter: function (value) {
            //             //     return value;
            //             //     // return Math.round(value * 100);
            //             // },
            //             color: '#000000'
            //         },
            //         data: [{
            //             value: data[1].value,
            //             name: data[1].name,
            //             // fontSize:20
            //         }]
            //     }]
            // });
            // this.echartsGauge3 = echarts.init(document.getElementById('echartsGauge3'));
            // this.echartsGauge3.setOption({
            //     series: [{
            //         type: 'gauge',
            //         startAngle: 215, //表盘开始角度
            //         endAngle: -35, //表盘结束角度
            //         center: ['50%', '55%'],
            //         radius: '78%', //设置仪表盘的大小
            //         min: data[2].min,
            //         max: data[2].max,
            //         splitNumber: 8,
            //         axisLine: {
            //             lineStyle: { //表盘设置
            //                 width: 22,
            //                 color: [
            //                     [0.25, '#6395F9'],
            //                     [0.5, '#28D793'],
            //                     [0.75, '#657798'],
            //                     [1, '#F6BD16']
            //                 ]
            //             }
            //         },
            //         pointer: {
            //             length: '70%',
            //             width: 10,
            //             offsetCenter: [0, 0],
            //             itemStyle: {
            //                 color: 'auto'
            //             }
            //         },
            //         axisTick: { //表盘小刻度设置
            //             length: 0,
            //             lineStyle: {
            //                 color: 'auto',
            //                 width: 2
            //             }
            //         },
            //         splitLine: { //表盘大刻度设置
            //             length: 0,
            //             lineStyle: {
            //                 color: 'auto',
            //                 width: 5
            //             }
            //         },
            //         axisLabel: { //表盘文字
            //             show: false,
            //             color: '#808080',
            //             fontSize: 14,
            //             distance: -30, //文字距表盘的距离
            //             formatter: function (value) {
            //                 if (value === 0.875) {
            //                     return '优';
            //                 } else if (value === 0.625) {
            //                     return '良';
            //                 } else if (value === 0.375) {
            //                     return '中';
            //                 } else if (value === 0.125) {
            //                     return '差';
            //                 }
            //             }
            //         },
            //         title: { //表盘文字
            //             offsetCenter: [0, '40%'],
            //             fontSize: 14
            //         },
            //         detail: {
            //             fontSize: 22,
            //             offsetCenter: [0, '70%'],
            //             valueAnimation: true,
            //             formatter: '{value} %',
            //             // formatter: function (value) {
            //             //     // return Math.round(value * 100);
            //             //     return value;
            //             // },
            //             color: '#000000'
            //         },
            //         data: [{
            //             value: data[2].value,
            //             name: data[2].name,
            //             // fontSize:20
            //         }]
            //     }]
            // });
            // this.echartsGauge4 = echarts.init(document.getElementById('echartsGauge4'));
            // this.echartsGauge4.setOption({
            //     series: [{
            //         type: 'gauge',
            //         startAngle: 215, //表盘开始角度
            //         endAngle: -35, //表盘结束角度
            //         center: ['50%', '55%'],
            //         radius: '78%', //设置仪表盘的大小
            //         min: data[3].min,
            //         max: data[3].max,
            //         splitNumber: 8,
            //         axisLine: {
            //             lineStyle: { //表盘设置
            //                 width: 22,
            //                 color: [
            //                     [0.25, '#6395F9'],
            //                     [0.5, '#28D793'],
            //                     [0.75, '#657798'],
            //                     [1, '#F6BD16']
            //                 ]
            //             }
            //         },
            //         pointer: {
            //             length: '70%',
            //             width: 10,
            //             offsetCenter: [0, 0],
            //             itemStyle: {
            //                 color: 'auto'
            //             }
            //         },
            //         axisTick: { //表盘小刻度设置
            //             length: 0,
            //             lineStyle: {
            //                 color: 'auto',
            //                 width: 2
            //             }
            //         },
            //         splitLine: { //表盘大刻度设置
            //             length: 0,
            //             lineStyle: {
            //                 color: 'auto',
            //                 width: 5
            //             }
            //         },
            //         axisLabel: { //表盘文字
            //             show: false,
            //             color: '#808080',
            //             fontSize: 14,
            //             distance: -30, //文字距表盘的距离
            //             formatter: function (value) {
            //                 if (value === 0.875) {
            //                     return '优';
            //                 } else if (value === 0.625) {
            //                     return '良';
            //                 } else if (value === 0.375) {
            //                     return '中';
            //                 } else if (value === 0.125) {
            //                     return '差';
            //                 }
            //             }
            //         },
            //         title: { //表盘文字
            //             offsetCenter: [0, '40%'],
            //             fontSize: 14
            //         },
            //         detail: {
            //             fontSize: 22,
            //             offsetCenter: [0, '70%'],
            //             valueAnimation: true,
            //             formatter: '{value} %',
            //             // formatter: function (value) {
            //             //     // return Math.round(value * 100);
            //             //     return value;
            //             // },
            //             color: '#000000'
            //         },
            //         data: [{
            //             value: data[3].value,
            //             name: data[3].name,
            //             // fontSize:20
            //         }]
            //     }]
            // });
            // this.echartsGauge5 = echarts.init(document.getElementById('echartsGauge5'));
            // this.echartsGauge5.setOption({
            //     series: [{
            //         type: 'gauge',
            //         startAngle: 215, //表盘开始角度
            //         endAngle: -35, //表盘结束角度
            //         center: ['50%', '55%'],
            //         radius: '78%', //设置仪表盘的大小
            //         min: data[4].min,
            //         max: data[4].max,
            //         splitNumber: 8,
            //         axisLine: {
            //             lineStyle: { //表盘设置
            //                 width: 22,
            //                 color: [
            //                     [0.25, '#6395F9'],
            //                     [0.5, '#28D793'],
            //                     [0.75, '#657798'],
            //                     [1, '#F6BD16']
            //                 ]
            //             }
            //         },
            //         pointer: {
            //             length: '70%',
            //             width: 10,
            //             offsetCenter: [0, 0],
            //             itemStyle: {
            //                 color: 'auto'
            //             }
            //         },
            //         axisTick: { //表盘小刻度设置
            //             length: 0,
            //             lineStyle: {
            //                 color: 'auto',
            //                 width: 2
            //             }
            //         },
            //         splitLine: { //表盘大刻度设置
            //             length: 0,
            //             lineStyle: {
            //                 color: 'auto',
            //                 width: 5
            //             }
            //         },
            //         axisLabel: { //表盘文字
            //             show: false,
            //             color: '#808080',
            //             fontSize: 14,
            //             distance: -30, //文字距表盘的距离
            //             formatter: function (value) {
            //                 if (value === 0.875) {
            //                     return '优';
            //                 } else if (value === 0.625) {
            //                     return '良';
            //                 } else if (value === 0.375) {
            //                     return '中';
            //                 } else if (value === 0.125) {
            //                     return '差';
            //                 }
            //             }
            //         },
            //         title: { //表盘文字
            //             offsetCenter: [0, '40%'],
            //             fontSize: 14
            //         },
            //         detail: {
            //             fontSize: 22,
            //             offsetCenter: [0, '70%'],
            //             valueAnimation: true,
            //             formatter: '{value} %',
            //             // formatter: function (value) {
            //             //     // return Math.round(value * 100);
            //             //     return value;
            //             // },
            //             color: '#000000'
            //         },
            //         data: [{
            //             value: data[4].value,
            //             name: data[4].name,
            //             // fontSize:20
            //         }]
            //     }]
            // });
        },
        drawShapeBar_KPI(lineA) {
            // KPI分析--开始
            echarts.init(document.getElementById('bar_KPI')).dispose(); //销毁echarts
            this.bar_KPI = echarts.init(document.getElementById('bar_KPI'));
            this.bar_KPI.setOption({
                title: {},
                // 提示框
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    textStyle: {
                        align: 'left'
                    },
                    // trigger: 'axis',
                    formatter: function (params) {
                        var relVal = params[0].name
                        for (var i = 0; i < params.length; i++) {
                            relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' +
                                params[i].value + '%'
                        }
                        return relVal
                    }
                },
                legend: lineA.legend,
                // legend: {
                //     data: ['oee', 'kjl', 'lyl', 'zhl'],
                //     bottom: '2%',
                //     textStyle: {
                //         color: '#808080',
                //         fontSize: 12,

                //     }
                // },
                // color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
                dataZoom: [{
                    show: true,
                    start: 1,
                    end: 100
                },
                {
                    type: 'inside',
                    // type: 'slider',
                    start: 94,
                    end: 100,
                },
                ],
                grid: {
                    left: '4%',
                    right: '6%',
                    bottom: '15%',
                    top: "10%",
                    containLabel: true
                },
                xAxis: lineA.xAxis,
                // xAxis: {
                //     type: 'category',
                //     data: ['1日', '2日', '3日', '4日', '5', '6', '7', '8'],
                //     axisTick: {
                //         alignWithLabel: true
                //     },
                //     axisLine: {
                //         lineStyle: {
                //             color: '#869da7',
                //         }
                //     },
                //     axisLabel: {
                //         interval: 0,
                //         color: '#808080',
                //         fontSize: 12,
                //         rotate: 0,
                //     },
                // },
                yAxis: {
                    type: 'value',
                    nameRotate: '0.1',
                    splitLine: {
                        lineStyle: {
                            type: 'solid',
                            color: ['#E3E4E6']
                        }
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#869da7',
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        color: '#869da7',
                        fontSize: 12,
                        //  rotate:40  
                    }
                },
                series: lineA.series
                // [{
                //     name: 'oee',
                //     data: [, 1, , ],
                //     type: 'bar',
                //     barGap: 0,
                //     barWidth: 10, //柱的宽度
                //     smooth: true,
                // }, {
                //     name: 'kjl',
                //     data: [4, , , ],
                //     type: 'bar',
                //     barGap: 0,
                //     barWidth: 10, //柱的宽度
                //     smooth: true,
                // }, {
                //     name: 'lyl',
                //     data: [7, , , ],
                //     type: 'bar',
                //     barGap: 0,
                //     barWidth: 10, //柱的宽度
                //     smooth: true,
                // }, {
                //     name: 'zhl',
                //     data: [10, , , ],
                //     type: 'bar',
                //     barGap: 0,
                //     barWidth: 10, //柱的宽度
                //     smooth: true,
                // }]
            })
            // KPI分析--结束
        },
        drawShapePie_alert(data, totle) {
            // debugger;
            // 报警信息
            this.pie_alert = echarts.init(document.getElementById('pie_alert'));
            this.pie_alert.setOption({
                title: {
                    text: totle,
                    subtext: '总',
                    subtextStyle: {
                        fontSize: 18
                    },
                    textStyle: {
                        fontSize: 24
                    },
                    left: 'center',
                    top: '35%'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: "{b} : {c} ({d}%)"
                },
                color: ['#E86B5A', '#6294F8', '#61D9AA', '#647697', '#F5BF21'],
                // graphic: { //图形中间文字
                //     type: "text",
                //     left: "center",
                //     top: 150,
                //     style: {
                //         text: "56.3",
                //         textAlign: "center",
                //         fill: "#000000",
                //         fontSize: 36,
                //     }
                // },
                legend: {
                    bottom: '8%',
                    left: 'center',
                    icon: 'circle',
                    textStyle: {
                        color: '#808080',
                        fontSize: '18px',
                    }
                },
                series: [{
                    name: '',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    center: ['50%', '40%'],
                    labelLine: {
                        show: true,
                        length: 20,
                        width: 3
                    },
                    label: {
                        // formatter: '{a}：{d}%',
                        formatter: '{d}%',
                        fontSize: '18px',
                    },

                    data: data
                }]
            })
        },
        drawTimeSlice(data) {
            const _this = this
            // 时间片--开始
            echarts.init(document.getElementById('timeSlice')).dispose();
            this.timeSlice = echarts.init(document.getElementById('timeSlice'));
            this.timeSlice.setOption({
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: { // Use axis to trigger tooltip
                //         type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                //     },
                //     // formatter: function (arg) {
                //     //     console.log(arg)
                //     //     return arg[0].name + '<br/>' + arg[0].data+'<br/>' 
                //     // }
                // },
                tooltip: {
                    // trigger: 'axis',
                    //        axisPointer: { // Use axis to trigger tooltip
                    //     type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    // },
                    textStyle: {
                        align: 'left'
                    },
                },
                color: ['#6294F8', '#61D9AA', '#647697', '#F5BF21', '#E86B5A'],
                legend: data.legend,
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    name: 's'
                },
                yAxis: {
                    type: 'category',
                    data: data.yAxis,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#E6E6E6',
                        }
                    },
                    axisLabel: {
                        // interval: 0,
                        color: '#808080',
                        fontSize: 14,
                        // fontWeight: 'bolder' //字体加粗
                        //  rotate:40  
                    }
                },
                series: data.series
                // series: [{
                //         name: 'Direct',
                //         type: 'bar',
                //         stack: 'total',
                //         barWidth: 280, //柱的宽度
                //         label: {
                //             show: false
                //         },
                //         emphasis: { //高亮显示
                //             focus: 'series'
                //         },
                //         data: [320]
                //     },
                // ]
            })
            if (this.timeSliceEquipmentValue.length == 0) {
                this.timeSlice.on('click', function (params) {
                    params.event.event.stopPropagation() //点击空白处阻止默认事件
                    // console.log(params);
                    // seriesId
                    if (params.componentType === 'series') {
                        console.log(Number(params.seriesId).toString())
                        if (Number(params.seriesId).toString() != 'NaN') {
                            _this.timeSliceDialog = true;
                            _this.timeSliceType = params.seriesId;
                            _this.sliceTime2 = _this.sliceTime;
                            _this.equipmentTimeSlice2();
                        }
                    }
                })
            }


        },
        drawTimeSlice2(data) {
            var _this = this;
            this.timeSlice2 = echarts.init(document.getElementById('timeSlice2'));
            this.timeSlice2.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    textStyle: {
                        align: 'left'
                    },
                },
                color: ['#6294F8', '#61D9AA', '#647697', '#F5BF21', '#E86B5A'],
                legend: data.legend,
                grid: {
                    left: '3%',
                    right: '4%',
                    top: '5%',
                    bottom: '15%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: ['状态时间'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#E6E6E6',
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        color: '#808080',
                        fontSize: 12,
                        //  rotate:40  
                    }
                },
                series: data.series
                // series: [{
                //         name: 'Direct',
                //         type: 'bar',
                //         stack: 'total',
                //         barWidth: 280, //柱的宽度
                //         label: {
                //             show: false
                //         },
                //         emphasis: { //高亮显示
                //             focus: 'series'
                //         },
                //         data: [320]
                //     },
                // ]
            })
            this.timeSlice2.on('click', function (params) {
                params.event.event.stopPropagation() //点击空白处阻止默认事件
                console.log(params);
                // seriesId
                if (params.componentType === 'series') {
                    console.log(Number(params.seriesId).toString())
                    if (Number(params.seriesId).toString() != 'NaN') {
                        _this.timeSliceDialog2 = true;
                        _this.timeSliceType = params.seriesId;
                        _this.sliceTime2 = _this.sliceTime;
                        _this.equipmentTimeSlice3();
                    }
                }

            })
        },
        drawTimeSlice4(data) {
            // var _this = this;
            this.timeSlice3 = echarts.init(document.getElementById('timeSlice3'));
            this.timeSlice3.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    textStyle: {
                        align: 'left'
                    },
                },
                color: ['#6294F8', '#61D9AA', '#647697', '#F5BF21', '#E86B5A'],
                legend: data.legend,
                grid: {
                    left: '3%',
                    right: '4%',
                    top: '5%',
                    bottom: '15%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: ['状态时间'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#E6E6E6',
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        color: '#808080',
                        fontSize: 12,
                        //  rotate:40  
                    }
                },
                series: data.series
                // series: [{
                //         name: 'Direct',
                //         type: 'bar',
                //         stack: 'total',
                //         barWidth: 280, //柱的宽度
                //         label: {
                //             show: false
                //         },
                //         emphasis: { //高亮显示
                //             focus: 'series'
                //         },
                //         data: [320]
                //     },
                // ]
            })
        },
        drawPie_repair(data) {
            // pie_repair维修履历--开始
            this.pie_repair = echarts.init(document.getElementById('pie_repair'));
            this.pie_repair.setOption({
                tooltip: {
                    trigger: 'item',
                    // formatter: '{a} :{d}%'
                },
                color: ['#E86B5A', '#62BEF8', '#6294F8', '#61D9AA', '#647697', '#F5BF21'],
                legend: { //图例
                    bottom: '4%',
                    left: 'center',
                    color: '#808080',
                    width: '400',
                    icon: 'circle',
                    textStyle: {
                        color: '#808080',
                    }
                },
                series: [{
                    name: '',
                    type: 'pie',
                    radius: '70%',
                    label: {
                        // formatter: '{a}：{d}%',
                        formatter: '{d}%',
                    },
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            })
            // 维修履历--结束
        },
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property]
            if (data == undefined) {
                return ''
            };
            return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
        },


    },


}

</script>

<style scoped>
/* .aaaa{
    background-image:url('../../assets/images/bulletinBoard/椭圆 5@2x.png'); 
     height: 200px;
     width: 200px;
      margin-left:auto;
    margin-right:auto;
    border: 3px solid red;
    border-radius: 50%;
}
.resl{
    border: 3px solid red;
    color: red;
    width:280px;
    height:60px;
    margin-left:auto;
    margin-right:auto; 
   margin-top:50px;
   font-size: 22px;
  
     margin-top:100px; 
} */
.reslearn {
    margin-left: 10px;
    /* color: #dff1fc; */
    border: 1px solid #d2d5d6;
}

.perce {
    margin-top: 100px;
    width: 450px;
    margin-left: 50px;
}

.learn {


    background-color: #dff1fc;
    height: 8.5%
}

.alstyle {
    margin-top: 100px;
    font-size: 55px;
}

.renum {
    margin-top: 50px;
    margin-left: auto;
    color: red;
    /* margin-right:auto; */
    margin-right: 70px;
    margin-top: 80px;
    text-align: center;
    float: right;
    font-size: 25px;
}

a .tree_height {
    height: 790px;
}

.w120_h40 {
    width: 120px;
    height: 40px;
}

.sb_img {
    width: 280px;
    height: 280px;
    border: 1px solid #CCDBE5;
}

.tag_div {
    width: 93px;
    line-height: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.echarts_div1 {
    width: 340px;
}

.echarts_div2 {
    width: 130px;
}

.tab {
    width: 140px;
    background: #E6E6E6;
    color: #1A1A1A;
    margin-right: 2px;
}

.tab_active {
    background: #009BFD;
    color: #FFFFFF;
}

.tab_left {
    width: 190px;
    height: 100%;
    background: #E7F2FC;
    border: 1px solid #E6E6E6;
    padding-top: 52px;
}

.tab_left_box {
    width: 176px;
    height: 50px;
    line-height: 50px;
    color: #1A1A1A;
    font-size: 14px;
    float: right;
}

.tab_left_box_active {
    background-color: #FFFFFF;
    border-left: 3px solid #009BFD;
    color: #009BFD;
    font-weight: 600;
}

.width_200 {
    width: 200px;
}

.btn {
    width: 110px;
    height: 36px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert_btn {
    width: 128px;
    height: 36px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.height_170 {
    height: 170px;
}

.height_220 {
    height: 220px;
}

.height_270 {
    height: 270px;
}

.height_340 {
    height: 340px;
}

.height_420 {
    height: 420px;
}

.height_530 {
    height: 530px;
}

.height_550 {
    height: 550px;
}

.height_680 {
    height: 680px;
}

.height_740 {
    height: 740px;
}

.box1 {
    width: 278px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.box2 {
    width: 287px;
    height: 98px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    border-radius: 6px;
}

.alarm_btn {
    color: #1A1A1A;
    width: 128px;
    height: 36px;
    line-height: 36px;
    border: 1px solid #CCDBE5;
    background: #E6E6E6;

}

.alarm_btn_active {
    color: #FFFFFF;
    width: 128px;
    height: 36px;
    line-height: 36px;
    background: #009BFD;
}

.treeTypeBox {
    width: 108px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .el-pagination {
        padding: 5px;
    } */
</style>

<style lang="less" scoped>
::v-deep .el-tree-node.is-current>.el-tree-node__content {
    color: #0071FD;
}

::v-deep .el-date-editor .el-range-separator {
    width: 9%;
}

::v-deep .el-textarea__inner {
    background-color: #F5F5F5;
}

::v-deep .el-tree__empty-block {
    width: 200px;
}
</style>
