<!-- A设备管理-巡检管理-巡检标准-修改详情   -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">点检标准</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_20 flex flex_wrap "
                style="width: 65%;">
               
                 <el-form-item label="记录人：">
                    <el-input v-model="formInline.oprPerson" placeholder="" class="width_180 " disabled></el-input>
                </el-form-item>
                <el-form-item label="点检标准名称：">
                    <el-input v-model="formInline.inspName" placeholder="" class="width_180 " disabled></el-input>
                </el-form-item>
                  <el-form-item label="点检标准类型：">
                    <el-select v-model="formInline.InspStdType" placeholder="" class="width_180 " disabled>
                        <el-option v-for="item in inspectionStandardType" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="点检标准类型：">
                    <el-select v-model="formInline.InspStdType" placeholder="" class="width_180 "
                        @change="changeInspStdType" :disabled="disabled">
                        <el-option v-for="item in inspectionStandardType" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                  <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                 <el-form-item label="点检工具：">
                    <el-input v-model="formInline.inspTool" placeholder="" class="width_180 "></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" label-width="110px" class="flex flex_wrap " style="width: 65%;">
                <el-form-item label="点检目的：">
                    <el-input v-model="formInline.inspTarget" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" class="width_1100" type="textarea" :rows="3">
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="请输入" class="width_180" disabled>
                    </el-input>
                </el-form-item>
            </el-form> -->

            <!-- table -->
            <div class="flex " style="width: 65%;">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addStandard">添加标准</el-button>
            </div>
            <div style="width: 65%;" class="height_270">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_10 " height="100%"
                    border style="width: 100%;">
                    <el-table-column label="操作"  width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="editTableData(scope.row)">编辑</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column type="index" width="55"></el-table-column> -->
                    <!-- <el-table-column prop="inspDetailType" label="类型详情标准" width=""></el-table-column> -->
                    <el-table-column label="类型详情标准">
                        <template slot-scope="scope">
                            <span v-if="scope.row.inspDetailType == 0">布尔型</span>
                            <span v-if="scope.row.inspDetailType == 1">数值型</span>
                            <span v-if="scope.row.inspDetailType == 2">图片型</span>
                            <span v-if="scope.row.inspDetailType == 3">勾选型</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="inspDetailCont" label="标准详情内容" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="inspDetailStandard" label="标准详情标准" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                v-if="scope.row.inspDetailType == 2">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="lookImg(scope.row.inspDetailStandard)">查看</span>
                            </el-button>
                            <span v-else>{{scope.row.inspDetailStandard}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width=""></el-table-column>
                    <!-- <el-table-column prop="name" label="上限" width=""></el-table-column>
                <el-table-column prop="name" label="默认值" width=""></el-table-column> -->
                </el-table>
            </div>

            <!--修改小窗-->
            <el-dialog title="编辑点检标准详情" :visible.sync="dialogFormVisible" width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑点检标准详情</div> -->
                <div class="flex justify_center mg_top_20">
                    <el-form :model="updateform">
                        <!-- 布尔型 -->
                        <el-form-item label="点检详情标准: " :label-width="formLabelWidth"
                            v-if="updateform.inspDetailType==0">
                            <el-checkbox v-model="formStandard.inspDetailStandard" class="width_180 text_align_left">
                            </el-checkbox>
                        </el-form-item>

                        <!-- 数值型 -->
                        <el-form-item label="点检详情标准: " :label-width="formLabelWidth"
                            v-if="updateform.inspDetailType==1">
                            <el-input v-model="updateform.inspDetailStandard" autocomplete="off" class=""></el-input>
                        </el-form-item>
                        <!-- 图片型 -->
                        <!-- 勾选形 -->

                        <el-form-item label="点检详情内容: " :label-width="formLabelWidth">
                            <el-input v-model="updateform.inspDetailCont" autocomplete="off" class=""></el-input>
                        </el-form-item>
                        <el-form-item label="备注: " :label-width="formLabelWidth">
                            <el-input v-model="updateform.remarks" autocomplete="off" class=""></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="inspDetailSubmit">保存</el-button>
                </div>

                <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div> -->
            </el-dialog>
            <!-- 查看图片 -->
            <el-dialog title="查看图片" :visible.sync="dialogLookImg" width="30%" top="20vh">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">查看图片</div> -->
                <img :src="imgUrl" alt="" class="imgLook">

            </el-dialog>
            <!-- 添加标准--弹框 -->
            <el-dialog title="添加标准" :visible.sync="dialogVisibleAddStandard" top='10vh' width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加标准</div> -->
                <!-- pdl30 pdr30 -->
                <div class="flex  justify_center standard_h mg_top_10">
                    <el-form :model="formStandard" :label-width="formLabelWidth">
                        <el-form-item label="类型详情标准：">
                            <el-select v-model="formStandard.inspDetailType" placeholder="请选择" class=""
                                :disabled="readOnly" @change="changeType">
                                <el-option v-for="item in standardTypeDetails" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="标准详情内容：">
                            <el-input v-model="formStandard.inspDetailCont" type="textarea" :row="4" class="">
                            </el-input>
                        </el-form-item>
                        <!-- 布尔型 -->
                        <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspDetailType==0">
                            <el-checkbox v-model="formStandard.inspDetailStandard" class="width_180 text_align_left"
                                true-label='是' false-label="否">
                            </el-checkbox>
                            <!-- <el-radio-group v-model="formStandard.inspDetailStandard" class="width_180">
                            <el-radio :label="true">是</el-radio>
                            <el-radio :label="false">否</el-radio>
                        </el-radio-group> -->
                        </el-form-item>
                        <!-- 数值型 -->
                        <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspDetailType==1">
                            <el-input v-model="formStandard.inspDetailStandard" class=""></el-input>
                        </el-form-item>
                        <!-- 图片型 -->
                        <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspDetailType==2">
                            <el-upload class="avatar-uploader" action="666666" :before-upload="beforeAvatarUpload"
                                :show-file-list="false">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <el-button type="primary" class="mg_top_10" style="background: #2FB060;" @click="uplodeImg">
                                上传
                            </el-button>
                        </el-form-item>
                        <!-- 勾选型 -->
                        <el-form-item label="标准详情标准：" class=" " v-if="formStandard.inspDetailType==3">
                             <div class="">
                            <el-button type="primary" icon="el-icon-plus" class="add_btn" @click="addInspDetailStandard"></el-button>
                            <el-checkbox-group v-model="formStandard.inspDetailStandard"
                                class="flex flex_column align_start detailStandard_h mg_top_10">
                                <el-checkbox :label="item.standars" v-for="(item,index) in inspDetailStandardList" :key="index">
                                    <label>
                                        <el-input class="width_150" v-model="item.standars" ></el-input>
                                    </label>
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                            <!-- <el-checkbox-group v-model="formStandard.inspDetailStandard"
                                class="flex flex_column align_start detailStandard_h">
                                <el-checkbox label="轴承处磨损"></el-checkbox>
                                <el-checkbox label="机械臂连接处磨损"></el-checkbox>
                                <el-checkbox label="液晶面板磨损"></el-checkbox>
                                <el-checkbox label="滑轮导轨磨损"></el-checkbox>
                                <el-checkbox label="轴承处磨损"></el-checkbox>
                                <el-checkbox label="机械臂连接处磨损"></el-checkbox>
                                <el-checkbox label="液晶面板磨损"></el-checkbox>
                                <el-checkbox label="滑轮导轨磨损"></el-checkbox>
                            </el-checkbox-group> -->
                        </el-form-item>

                        <el-form-item label="备注：">
                            <el-input v-model="formStandard.remarks" type="textarea" :row="4" autocomplete="off"
                                class="">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleAddStandard = false">取 消</el-button>
                    <el-button type="primary" @click="confirmAddStandard">确 定</el-button>
                </div>
                <!-- <div>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div> -->
            </el-dialog>


            <div class="flex mg_top_30 mg_bottom_20">
                <div>
                    <el-button type="primary" class="width_140" @click="submit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="cancel">取消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        inspectionStandardTypeQuery,
        inspectionStandardQuery,
        uploadInspectionImage,
        inspectionStandardDetailQuery,
        inspectionStandardDetailQueryLimit,
        inspectionStandardUpdate,
        inspectionStandardDetailUpdate,
        inspectionLoadImage,
        inspectionStandardDetailInsert
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                checked: false,
                tableData: [],
                inspDetailJSON: {
                    "array": []
                },
                formInline: {
                    inspTool: '', //点检工具
                    //点检详情
                    inspName: '', //点检标准名称
                    inspTarget: '', //点检目的
                    oprPerson: '', //记录人
                    InspStdType: '', //点检标准类型
                    deviceID: '', //设备编号
                    deviceName: '', //设备名称
                    //用户编号
                    remarks: '', //备注
                    inspStdID: ''
                },
                updateform: {
                    Id: '',
                    inspDetailCont: '',
                    inspDetailId: '',
                    inspDetailStandard: '',
                    inspDetailType: '',
                    inspStdId: '',
                    remarks: '',
                },
                xx: '',
                input2: '',
                detailID: '',
                inspectionStandardType: [],
                formStandard: {
                    inspDetailType: '',
                    inspDetailCont: '',
                    inspDetailStandard: '',
                    remarks: '',
                },
                formLabelWidth: '120px',
                readOnly: false,
                standardTypeDetails: [{
                    value: '0',
                    label: '布尔型'
                }, {
                    value: '1',
                    label: '数值型'
                }, {
                    value: '2',
                    label: '图片型'
                }, {
                    value: '3',
                    label: '勾选型'
                }],
                imageUrl: '',
                permission: {},
                userID: '',
                currentPage: 1,
                pageSize: 2,
                pageQuantity: 0,
                dialogTableVisible: false,
                dialogFormVisible: false,


                organizationValue: [],
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                checkData: {},
                equInfoList: [],
                currentEqu: [],
                imgUrl: '',
                dialogLookImg: false,
                dialogVisibleAddStandard: false,
                inspDetailStandardList:[]
            }
        },
        mounted() {
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                // this.maintainOrderQuery();//此处是根据ID查询数据详情
            }
            this.inspectionStandardTypeQuery()
            this.inspectionStandardQuery();
            // this.inspectionStandardDetailQuery();

        },
        methods: {
            // 点检标准类型
            async inspectionStandardTypeQuery() {
                const selectRes = await inspectionStandardTypeQuery();
                this.inspectionStandardType = selectRes.rows;
                // this.formInline.InspStdType = selectRes.rows[0].value;
            },

            addStandard() {
                // if (!this.formInline.deviceID) {
                //     this.$message({
                //         message: '警告哦，请选择设备',
                //         type: 'warning'
                //     });
                //     return
                // }
                // console.log(this.formInline.InspStdType)
                if (this.formInline.InspStdType == 0) {
                    this.readOnly = true;
                } else {
                    this.readOnly = false;
                }
                // this.formStandard.inspDetailType = this.standardTypeDetails[0].value;
                this.formStandard = {
                    inspDetailType: this.standardTypeDetails[0].value,
                    inspDetailStandard: false,
                    inspDetailCont: '',
                    remarks: ''
                }
                // this.formStandard.inspDetailStandard = false;
                // this.formStandard.inspDetailCont = '';
                // this.formStandard.remarks = '';
                this.imgInfo = {
                    fileName: '',
                    format: '',
                    deviceID: '',
                    content: ''
                }
                this.imageUrl = '';
                 this.inspDetailStandardList = [];
                this.dialogVisibleAddStandard = true;
            },
            // 新增确认
            confirmAddStandard() {
                console.log(this.formStandard);
                if (this.formStandard.inspDetailType == 2 && this.formStandard.inspDetailStandard == '') {
                    this.$message({
                        message: '请上传图片',
                        type: 'warning'
                    });
                    return
                }
                 if(this.formStandard.inspDetailType==3) {
                    this.formStandard.inspDetailStandard = this.formStandard.inspDetailStandard.join(',')
                }

                this.inspectionStandardDetailInsert();

            },
            async inspectionStandardDetailInsert() {
                var param = {
                    inspDetailJSON: {
                        array: []
                    },
                    inspStdID: this.formInline.inspStdID
                }
                param.inspDetailJSON.array.push(this.formStandard)
                // this.formStandard
                const selectRes = await inspectionStandardDetailInsert(param);
                if (selectRes.data.result) {
                    this.dialogVisibleAddStandard = false;
                    this.formStandard = {};
                    this.inspectionStandardDetailQuery();
                    if (selectRes.messageEnable) {
                        this.$message({
                            message: selectRes.message,
                            type: 'success'
                        });
                    }

                } else {
                    if (selectRes.messageEnable) {
                        this.$message.error(selectRes.message);
                    }
                }

                // this.dialogVisibleAddStandard = false;
            },

            //提交修改
            //整个修改分两部分进行修改，第一部分修改点检标准，第二部分修改点检标准详情。
            submit() {

                //（1）提交点检标准的修改
                this.inspectionStandardUpdate();
            },
            inspDetailSubmit() {
                //（2）提交点检标准详情的修改
                this.inspectionStandardDetailUpdate();
                console.log(this.updateform)
            },
            //修改
            //点击‘修改’之后触发的方法
            editTableData(temp) {
                this.dialogFormVisible = true;
                console.log('这是temp：')
                console.log(temp)
                this.updateform = temp;
                console.log('这是updateform：')
                console.log(this.updateform)
            },
            //点检标准的修改
            async inspectionStandardUpdate() {
                //封装参数
                var param = {
                    inspTool: this.formInline.inspTool,
                    inspName: this.formInline.inspName,
                    inspTarget: this.formInline.inspTarget,
                    ID: this.detailID,
                    deviceID: this.formInline.deviceID,
                    remarks: this.formInline.remarks
                }

                console.log(param)
                const res = await inspectionStandardUpdate(param);
                console.log(res)
                if (res.data.result) {
                    if (res.messageEnable) {
                        this.$message({
                            message: res.message,
                            type: 'success'
                        });
                    }
                     this.$router.go(-1);
                } else {
                    this.$message.error(selectRes.message);
                }

            },
            //点检标准详情的修改
            async inspectionStandardDetailUpdate() {
                //封装参数
                var param = {
                    ID: this.updateform.Id,
                    inspDetailID: this.updateform.inspDetailId,
                    inspDetailCont: this.updateform.inspDetailCont,
                    inspDetailType: this.updateform.inspDetailType,
                    inspDetailStandard: this.updateform.inspDetailStandard,
                    remarks: this.updateform.remarks
                };

                const res = await inspectionStandardDetailUpdate(param);

                if (res && res.data && res.data.result) {
                    //this.dialogVisible = false;
                    if (res.messageEnable) {
                        this.$message({
                            message: res.message,
                            type: 'success'
                        });
                    }
                    this.dialogFormVisible = false;
                    this.inspectionStandardDetailQuery();
                    //this.userConfigUserList();
                } else {
                    if (res.messageEnable) {
                        this.$message({
                            // showClose: true,
                            message: res.message,
                            type: 'error',
                        });
                    }

                }

            },
            //取消修改
            cancel() {
                //点击取消之后，返回上一页面
                this.$router.go(-1);
            },
            async inspectionStandardQuery() {
                var param = {
                    ID: this.detailID
                }
                //console.log(param)

                const res = await inspectionStandardQuery(param);
                // console.log(res)

                this.formInline = res.rows[0]
                //console.log(res.rows[0])
                console.log(this.formInline)

                this.inspectionStandardDetailQuery();
            },
            async inspectionStandardDetailQuery() {

                var param = {
                    //ID:this.detailID,
                    inspStdID: this.formInline.inspStdID
                }

                console.log(param)

                const res = await inspectionStandardDetailQuery(param);

                console.log(res)

                this.tableData = res.rows

                //this.formStandard = res.rows;

            },
            changeType() {
                this.formStandard.inspDetailCont = '';
                this.formStandard.remarks = '';
                if (this.formStandard.inspDetailType == 0) {
                    this.formStandard.inspDetailStandard = false;
                } else if (this.formStandard.inspDetailType == 3) {
                    this.formStandard.inspDetailStandard = [];
                } else {
                    this.formStandard.inspDetailStandard = '';
                }
            },
            addInspDetailStandard() {
                 var test = {standars:''};
                this.inspDetailStandardList.push(test)
            },
            async lookImg(path) {
                // inspectionLoadImage
                var param = {
                    path: path
                }
                const res = await inspectionLoadImage(param);
                this.imgUrl = 'data:image/' + res.format + ';base64,' + res.content;
                this.dialogLookImg = true
            },
            // 上传图片
            async uplodeImg() {
                this.imgInfo.deviceID = this.formInline.deviceID;
                this.imgInfo.content = this.imgInfo.content.split(',').slice(-1)[0];
                const selectRes = await uploadInspectionImage(this.imgInfo);
                if (selectRes.data.result) {
                    this.formStandard.inspDetailStandard = selectRes.data.path;
                    if (selectRes.messageEnable) {
                        this.$message({
                            message: selectRes.message,
                            type: 'success'
                        });
                    }
                } else {
                    if (selectRes.messageEnable) {
                        this.$message.error(selectRes.massage);
                    }
                }
            },
            beforeAvatarUpload(file) {
                console.log(file)
                let that = this;
                that.file = file;
                that.fileNameTest = file.name;
                var fileType = file.name.split('.').slice(-1);
                that.imgInfo.fileName = file.name.split('.').slice(0)[0];
                that.imgInfo.format = fileType[0];
                console.log(fileType);

                // const extension = file.name.split('.').slice(-1) == 'pdf';
                // 限制文件大小  小于6M
                var fileSize = file.size / 1024 / 1024;
                if (fileSize > 10) {
                    this.$confirm('文件大小须小于10M。', '提示', {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: 'warning'
                    }).then().catch()
                    this.fileNameTest = '';
                    this.imgInfo.fileName = '';
                    this.blobFile = '';
                    return false
                }
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    // that.blobFile = reader.result;
                    that.imageUrl = reader.result;
                    that.imgInfo.content = reader.result;
                    console.log(reader.result)
                };
                return false; // 返回false不会自动上传
            }
        }

    }
</script>
<style scoped>
    .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        /* width: 200px;
        height: 200px; */
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    .avatar {
        width: 150px;
        height: 150px;
        display: block;
    }

    .standard_h {
        height: 430px;
    }

    .detailStandard_h {
        height: 150px;
        overflow-y: overlay;
    }

    .imgLook {
        width: 300px;
        height: 300px;
        margin: 20px auto;
    }

    .standard_h {
        height: 450px;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    /* ::v-deep .el-upload {
        width: 100%;
    } */
</style>